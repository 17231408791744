import './styles.scss'

import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AppLogo from '../AppLogo'
import CustomDropdown from '../common/CustomDropdown'
import CustomNavigationDropdown from '../common/CustomNavigationDropdown'
import { ArrowShortUp, ArrowShortDown, MenuOpen, MenuClose, SearchIcon } from '../Icons'
import CustomButton from '../common/CustomButton'

import blogImgSrc from '../../assets/images/Navbar/navigaatio-blogi.jpg'
import referenceImgSrc from '../../assets/images/Navbar/navigaatio-referenssit.jpg'

import { setLanguage } from '../../store/userSlice'

const navigationLinks = [
  {
    name: 'common.products',
    href: '/tuotteet',
    href_en: '/products',
  },
  {
    name: 'common.services',
    href: null,
    type: 'services',
    links: [
      {
        links: [
          {
            name: 'common.greenService',
            href: '/vihreys_palveluna',
            href_en: '/green-as-a-service',
            type: 'text',
          },
          {
            name: 'common.greenInterior',
            href: '/vihersisustus',
            href_en: '/green-interior-design',
            type: 'text',
          },
          {
            name: 'common.outdoorSpaces',
            href: '/ulkoalueet',
            href_en: '/outdoor-areas',
            type: 'text',
          },
          {
            name: 'common.customSolutions',
            href: '/raataloidyt_ratkaisut',
            href_en: '/tailored-solutions',
            type: 'text',
          },
        ]
      }
    ],
  },
  {
    name: 'common.innoGreen',
    href: null,
    links: [
      {
        name: 'common.blog',
        imgSrc: blogImgSrc,
        type: 'image',
        href: '/blogi',
        href_en: '/blog',
      },
      {
        name: 'common.references',
        imgSrc: referenceImgSrc,
        type: 'image',
        href: '/referenssit',
        href_en: '/references',
      },
      {
        links: [
          {
            name: 'common.company',
            href: '/yritys',
            href_en: '/about-us',
            type: 'text',
          },
          {
            name: 'common.innovation',
            href: '/innovaatiot',
            href_en: '/innovations',
            type: 'text',
          },
          {
            name: 'common.wellBeing',
            href: '/vihreydesta_hyvinvointia',
            href_en: '/greenery-for-well-being',
            type: 'text',
          },
          {
            name: 'common.responsibility',
            href: '/vastuullisuus',
            href_en: '/sustainability',
            type: 'text',
          },
          {
            name: 'common.work',
            href: '/toihin-innoGreenille',
            href_en: '/work-for-innogreen',
            type: 'text',
          },
        ],
      },
    ],
  },
  {
    name: 'common.contact',
    href: '/yhteystiedot',
    href_en: '/contact-us',
  },
]


const mobileNavigationLinks = [
  {
    name: 'common.products',
    href: '/tuotteet',
    href_en: '/products',
  },
  {
    name: 'common.services',
    href: null,
    links: [
      {
        name: 'common.greenService',
        href: '/vihreys_palveluna',
        href_en: '/green-as-a-service',
        type: 'text',
      },
      {
        name: 'common.greenInterior',
        href: '/vihersisustus',
        href_en: '/green-interior-design',
        type: 'text',
      },
      {
        name: 'common.outdoorSpaces',
        href: '/ulkoalueet',
        href_en: '/outdoor-areas',
        type: 'text',
      },
      {
        name: 'common.customSolutions',
        href: '/raataloidyt_ratkaisut',
        href_en: '/tailored-solutions',
        type: 'text',
      },
    ],
  },
  {
    name: 'common.innoGreen',
    href: null,
    links: [
      {
        name: 'common.blog',
        href: '/blogi',
        href_en: '/blog',
      },
      {
        name: 'common.references',
        href: '/referenssit',
        href_en: '/references',
      },
      {
        name: 'common.company',
        href: '/yritys',
        href_en: '/about-us',
      },
      {
        name: 'common.innovation',
        href: '/innovaatiot',
        href_en: '/innovations',
      },
      {
        name: 'common.wellBeing',
        href: '/vihreydesta_hyvinvointia',
        href_en: '/greenery-for-well-being',
      },
      {
        name: 'common.responsibility',
        href: '/vastuullisuus',
        href_en: '/sustainability',
      },
      {
        name: 'common.work',
        href: '/toihin-innoGreenille',
        href_en: '/work-for-innogreen',
      },
    ],
  },
  {
    name: 'common.contact',
    href: '/yhteystiedot',
    href_en: '/contact-us',
  },
]

const language_options = [
  {
    name: 'FI',
    id: 1,
  },
  // {
  //   name: 'SE',
  //   id: 2,
  // },
  {
    name: 'EN',
    id: 3,
  },
]

const NavBar = () => {

  const navigate = useNavigate()
  const goToAccount = () => {
    navigate('/account')
  }
  const location = useLocation()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showSubMenu, setShowSubMenu] = useState([false, false, false, false])
  const current_user = useSelector((state) => state.user.current_user)
  const { t, i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [showMenu, setShowMenu] = useState([false, false, false, false])
  const [timer, setTimer] = useState(null)
  const mobileMenuRef = useRef(null)
  const dropdownRefs = useRef([])
  const menuRefs = useRef([])
  const dispatch = useDispatch()
  const [showNavbar, setShowNavbar] = useState(true)
  const [showLanguageMenu, setShowLanguageMenu] = useState(false)

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = Math.max(0, window.pageYOffset);

      // Close all dropdowns when scrolling
      setShowMenu([false, false, false, false])
      setShowMobileMenu(false)
      setShowSubMenu([false, false, false, false])
      setShowLanguageMenu(false)

      if (currentScrollPos <= 0) {
        // Always show navbar when at the very top for elastic scroll browsers
        setShowNavbar(true)
      } else if (currentScrollPos > prevScrollPos) {
        setShowNavbar(false)
      } else if (currentScrollPos < prevScrollPos) {
        setShowNavbar(true)
      }

      prevScrollPos = currentScrollPos
    }

    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleSignInClick = () => {
    if (i18n.language === 'FI')
      navigate('/kirjaudu_sisaan')
    else if (i18n.language === 'EN')
      navigate('/login')
  }

  const handleMobileMenuClick = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const handleShowSubMenu = (index) => {
    setShowSubMenu((prevState) =>
      prevState.map((isVisible, i) => (i === index ? !isVisible : false))
    )
  }

  const handleLanguageClick = (item) => {
    if (item) {
      i18n.changeLanguage(item.name)
    }
  }

  useEffect(() => {
    const defaultLanguage = language_options.find(option => {
      switch (option.name) {
        case 'FI':
          return i18n.language === 'FI'
        case 'SE':
          return i18n.language === 'SE'
        case 'EN':
          return i18n.language === 'EN'
        default:
          return false
      }
    })
    // Set FI as default language
    if (!defaultLanguage) {
      setSelectedLanguage({
        "name": 'FI',
        "id": 1
      })
      i18n.changeLanguage('FI')
      dispatch(setLanguage('FI'))
    } else {
      setSelectedLanguage(defaultLanguage)
      dispatch(setLanguage(defaultLanguage.name))
    }
  }, [i18n.language])

  useEffect(() => {
    setShowMobileMenu(false)
    setShowSubMenu([false, false, false, false])
  }, [location])

  let navigationContainerClass = 'app-navigation-container'
  let navigationLinkClass = 'app-navigation-link'
  let navigationUserButtonClass = 'app-navigation-user-btn'
  let navigationSignInButtonClass = 'app-navigation-sign-in'
  let searchIconColor = 'black'
  let inProductsPage = false
  let inContactPage = false
  let inHomePage = false

  if (location && location.pathname.includes('/yhteystiedot') || location.pathname.includes('/contact-us')) {
    inContactPage = true
  }
  if (location && location.pathname.includes('/tuotteet') || location.pathname.includes('/products')) {
    inProductsPage = true
  }
  if (location && location.pathname.includes('/tuotteet') || location.pathname.includes('/products')) {
    navigationContainerClass += ' beigeBg'
  }
  if (location && location.pathname.includes('/project')) {
    navigationContainerClass += ' beige-strong'
  }
  if (location && location.pathname.includes('/account')) {
    navigationContainerClass += ' in-account-page'
  }
  if (location && location.pathname === '/') {
    navigationContainerClass += ' in-home-page'
    navigationLinkClass += ' in-home-page'
    navigationUserButtonClass += ' in-home-page'
    navigationSignInButtonClass += ' in-home-page'
    searchIconColor = 'white'
    inHomePage = true
  }
  if (location && location.pathname === '/referenssit' || location.pathname === '/references') {
    navigationContainerClass += ' in-reference-page'
  }
  if (location && location.pathname === '/blogi' || location.pathname === '/blog') {
    navigationContainerClass += ' in-blogs-page'
  }
  const goToHomePage = () => {
    navigate('/')
  }

  const goToSearchPage = () => {
    navigate('hae')
  }
  // Used to reset mobile menu when width reaches width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth === 851) {
        setShowMobileMenu(false)
        setShowSubMenu(false)
      }
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  // Toggle dropdown visibility on click
  const handleDropDownClick = (index) => {
    setShowMenu((prevState) =>
      prevState.map((_, i) => (i === index ? !prevState[index] : false))
    )
  }

  const isTouchDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0
  }

  // Show dropdown on mouse enter
  const handleMouseEnter = (index) => {
    if (isTouchDevice()) return;
    if (timer) clearTimeout(timer)
    setShowMenu((prevState) =>
      prevState.map((_, i) => (i === index ? true : false))
    )
  }

  // Hide dropdown with grace period on mouse leave
  const handleMouseLeave = (index, event, dropdownRef, menuRef) => {
    const relatedTarget = event.relatedTarget
    if (
      relatedTarget instanceof Node &&
      (dropdownRef.current?.contains(relatedTarget) ||
        menuRef.current?.contains(relatedTarget))
    ) {
      return // Prevent closing if mouse enters dropdown or menu
    }

    const newTimer = setTimeout(() => {
      setShowMenu((prevState) =>
        prevState.map((_, i) => (i === index ? false : prevState[i]))
      )
    }, 500)

    setTimer(newTimer)
  }

  // Show dropdown on focus
  const handleFocus = (index) => {
    if (timer) clearTimeout(timer)
    setShowMenu((prevState) =>
      prevState.map((_, i) => (i === index ? true : false))
    )
  }

  // Hide dropdown on blur with grace period
  const handleBlur = (index, event) => {
    const newTimer = setTimeout(() => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        setShowMenu((prevState) =>
          prevState.map((_, i) => (i === index ? false : prevState[i]))
        )
      }
    }, 500)
    setTimer(newTimer)
  }

  const handleClickOutside = (event) => {
    const clickedInsideDropdown = dropdownRefs.current.some(
      (ref) => ref && ref.contains(event.target)
    )
  
    const clickedInsideMenu = menuRefs.current.some(
      (ref) => ref && ref.contains(event.target)
    )
  
    if (clickedInsideDropdown || clickedInsideMenu) {
      return // Do nothing if the click is inside the dropdown or menu
    }
  
    setShowMenu((prevState) => prevState.map(() => false)) // Close all dropdowns
  }

  const handleMobileMenuClickOutside = (event) => {
    if (mobileMenuRef.current && mobileMenuRef.current.contains(event.target)) {
      return // Clicked inside the menu, do nothing
    }
    setShowMobileMenu(false); // Clicked outside, close the menu
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("mousedown", handleMobileMenuClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("mousedown", handleMobileMenuClickOutside)
    }
  }, [])

  const btnStyle = {
    marginTop: '2rem',
    marginLeft: '1rem'
  }
  const btnStyleSmall = {
    padding: '0.5rem 1rem',
    color: 'black',
    height: '0.9rem'
  }

  return (
    <>
    {/* <div className={showMobileMenu ? navigationContainerClass + ' mobile' : navigationContainerClass}></div> */}
      <div className={`${showMobileMenu ? navigationContainerClass + ' mobile' : navigationContainerClass} ${showNavbar ? 'show' : 'hide'}`}>
        <div className='app-navigation-body-container'>
          <div 
            className='app-logo-container'
            onClick={() => goToHomePage()}
          >
            <AppLogo color='black' height={24} />
          </div>

          <div className='app-navigation-links'>
              {navigationLinks.map((link_obj, index) => {
                if (link_obj.href) {
                  if (link_obj.href === '/tuotteet') {
                    return (
                      <React.Fragment key={index}>
                        <Link
                          className={`${navigationLinkClass} ${inProductsPage ? 'purple' : ''}`}
                          to={i18n.language === 'FI' ? link_obj.href : link_obj.href_en}
                          key={link_obj.name}
                        >
                          {t(link_obj.name)}
                        </Link>
                      </React.Fragment>
                    )
                  } else if (link_obj.href === '/yhteystiedot') {
                    return (
                      <React.Fragment key={index}>
                        <Link
                          className={`${navigationLinkClass} ${inContactPage ? 'purple' : ''}`}
                          to={i18n.language === 'FI' ? link_obj.href : link_obj.href_en}
                          key={link_obj.name}
                        >
                          {t(link_obj.name)}
                        </Link>
                      </React.Fragment>
                    )
                  }
                } else if (link_obj.type === 'services') {
                    return (
                      <React.Fragment key={index}>
                        <CustomNavigationDropdown
                          links={link_obj.links}
                          text={t(link_obj.name)}
                          key={link_obj.name}
                          type='services'
                          index={index}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onToggle={handleDropDownClick}
                          showMenu={showMenu[index]}
                          setShowMenu={setShowMenu}
                          dropdownRef={(el) => (dropdownRefs.current[index] = el)}
                          menuRef={(el) => (menuRefs.current[index] = el)}
                        />
                      </React.Fragment>
                    )
                } else {
                  return (
                    <React.Fragment key={index}>
                      <CustomNavigationDropdown
                        links={link_obj.links}
                        text={t(link_obj.name)}
                        key={link_obj.name}
                        index={index}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onToggle={handleDropDownClick}
                        showMenu={showMenu[index]}
                        setShowMenu={setShowMenu}
                        dropdownRef={(el) => (dropdownRefs.current[index] = el)}
                        menuRef={(el) => (menuRefs.current[index] = el)}
                      />
                    </React.Fragment>
                  )
                }
              })}
          </div>

          {/* USER LINKS & LANGUAGE DROPDOWN */}
          <div className='app-user-links'>
            {current_user && (
              <CustomButton
                onClickHandler={goToAccount}
                button_text={t('common.myAccount').toUpperCase()}
                yellow={true}
                customStyles={btnStyleSmall}
              />
            )}
            {!current_user && (
              <div
                className={`${navigationSignInButtonClass} no-highlight`}
                onClick={() => handleSignInClick()}
              >
                {t('common.logIn').toUpperCase()}
              </div>
            )}
            {/* <div className='app-language-user-separator'>|</div> */}
            <div className='app-language-dropdown'>
              <CustomDropdown
                showLanguageMenu={showLanguageMenu}
                setShowLanguageMenu={setShowLanguageMenu}
                isLanguageMenu={true}
                options={language_options}
                selected={selectedLanguage}
                onClickHandler={(val) => handleLanguageClick(val)}
                showBorder={false}
                iconColor='black'
              />
            </div>
            {/* <div className='app-search-wrapper' onClick={() =>  goToSearchPage()}>
              <SearchIcon size={24} color={searchIconColor}/>
            </div> */}
          </div>
          {/* Mobile menu */}
          <div className='app-navigation-menu-container'>
            <div className='app-language-dropdown'>
              <CustomDropdown
                showLanguageMenu={showLanguageMenu}
                setShowLanguageMenu={setShowLanguageMenu}
                isLanguageMenu={true}
                options={language_options}
                selected={selectedLanguage}
                onClickHandler={(val) => handleLanguageClick(val)}
                showBorder={false}
                iconColor='black'
              />
            </div>
              {/* Hamburger menu */}
            <div className='app-navigation-menu-icon-container' onClick={() => handleMobileMenuClick()}>
              { showMobileMenu ? (
                  <MenuClose size={40}/>
                ) : (
                  <MenuOpen size={40}/>
                ) 
              }
            </div>
            <div
              className={`app-navigation-menu ${showMobileMenu ? ' show' : ''}`}
              ref={mobileMenuRef}
            >
              {mobileNavigationLinks.map((link_obj, index) => {
                if (link_obj?.links) {
                  return (
                    <React.Fragment key={index}>
                      <div className='app-navigation-menu-sub-container' onClick={() => handleShowSubMenu(index)}>
                        <div className='app-navigation-menu-sub-name-container'>
                          {t(link_obj.name)}
                          {showSubMenu[index] ? 
                            (
                              <ArrowShortUp size={10}/>
                            ) : (
                              <ArrowShortDown size={10}/>
                            )
                          }
                        </div>
                        <div className={showSubMenu[index] ? 'app-navigation-menu-sub-body show' : 'app-navigation-menu-sub-body'}>
                          {link_obj.links.map((sub_link_obj, idx) => {
                            return (
                              <Link
                                className='app-navigation-menu-sub-link'
                                to={i18n.language === 'FI' ? sub_link_obj.href : sub_link_obj.href_en}
                                key={sub_link_obj.name}
                                >
                                {t(sub_link_obj.name)}
                              </Link>
                            )
                          })}

                        </div>
                      </div>
                      { mobileNavigationLinks.length - 1 !== index ? 
                      (
                        <div className='app-navigation-menu-divider'></div>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  )
                }
                return (
                  <React.Fragment key={index}>
                    <Link
                      className='app-navigation-menu-link'
                      to={i18n.language === 'FI' ? link_obj.href : link_obj.href_en} 
                      key={link_obj.name}
                      >
                      {t(link_obj.name)}
                    </Link>
                    {/* <div className='app-navigation-menu-divider'></div> */}
                    { mobileNavigationLinks.length - 1 !== index ? 
                    (
                      <div className='app-navigation-menu-divider'></div>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                )
              })}
              {current_user && (
                <CustomButton
                  onClickHandler={goToAccount}
                  button_text={t('common.myAccount').toUpperCase()}
                  yellow={true}
                  customStyles={btnStyle}
                />
              )}
              {!current_user && (
                <CustomButton
                  onClickHandler={handleSignInClick}
                  button_text={t('common.logIn').toUpperCase()}
                  transparent={true}
                  customStyles={btnStyle}
                />
              )}
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default NavBar
