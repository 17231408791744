const blog = {
  headerTitle: 'Blog',
  filter: 'FILTER',
  clear: 'CLEAR',
  continue: 'Continue reading',
  readAlso: 'Read also',
  readAlsoLinkText: 'See all articles',
  biodiversiteetti: 'Biodiversity',
  biofilia: 'Biophilia',
  innogreen: 'InnoGreen',
  innovaatiot: 'Innovations',
  kaupunkisuunnittelu: 'City Planning',
  koivu: 'Koivu',
  pihasuunnittelu: 'Landscape Design',
  rekrytointi: 'Job At InnoGreen',
  tyohyvinvointi: 'Workplace Wellness',
  ulkoalueet: 'Outdoor Areas',
  vastuullisuus: 'Sustainability',
  viherkalusteet: 'Green Furniture',
  vihersisustus: 'Green Interior Design',
  vinkit: 'Tips',
  viherseina: 'Green Wall',
  page: 'Page',
  next: 'Next',
  previous: 'Previous',
}

export default blog