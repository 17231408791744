import './styles.scss'

import ContactCard from '../ContactCard'
import { useTranslation } from 'react-i18next'

const ContactCardGroup = (props) => {
  const { 
    scrollRefs,
    type
  } = props
  const { i18n } = useTranslation()
  var cards = []
  if (type === 'invoice') {
    cards = i18n.t('contact.invoiceCards', { returnObjects: true })
  } else {
    cards = i18n.t('contact.cards', { returnObjects: true })
  }

  return (
    <>
      <div className='contact-card-group-container'>
        { cards && cards.map((item, index) => {
          return (
            <div className={ item.type ? 'contact-card-container no-background' : 'contact-card-container'}  key={index}>
              <ContactCard { ...item } scrollRefs={scrollRefs}/>
            </div>
          )
        })
        }
      </div>
    </>
  )
}

export default ContactCardGroup
