import './styles.scss'

import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import CustomButton from '../../components/common/CustomButton'
import { PlusIcon } from '../../components/Icons'
import CreateProjectModal from '../../components/CreateProjectModal'
import EditUserInformationModal from '../../components/EditUserInformationModal'
import { ArrowShortUp, ArrowShortDown, Delete, Duplicate } from '../../components/Icons'
import DuplicateProjectModal from '../../components/DuplicateProjectModal'
import DeleteProjectModal from '../../components/DeleteProjectModal'

import { selectCurrentProject } from '../../store/projectSlice'

import { fetch_projects, log_out } from '../../store/asyncThunk'

const filterOptionsAdmin = [
  {
    name: 'Your projects',
    id: 1,
  },
  {
    name: 'Assigned to you',
    id: 2,
  },
  {
    name: 'Assigned to others',
    id: 3,
  },
  {
    name: 'Not assigned',
    id: 4,
  },
  {
    name: 'Inquiry not sent',
    id: 5,
  },
]

const filterOptions = [
  {
    name: 'Your projects',
    id: 1,
  },
  {
    name: 'Assigned to you',
    id: 2,
  },
]

const Account = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const projects = useSelector((state) => state.project.projects)
  const current_user = useSelector((state) => state.user.current_user)
  const [searchProjectKw, setSearchProjectKw] = useState('')
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false)
  const [showEditUserInformationModal, setShowEditUserInformationModal] = useState(false)
  const { t, i18n } = useTranslation()
  const language = useSelector((state) => state.user.language)
  const [selectedTab, setSelectedTab] = useState({ name: 'Your projects', id: 1 })
  const [sort, setSort] = useState('project_name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false)
  const [showDuplicateProjectModal, setShowDuplicateProjectModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)

  useEffect(() => {
    if (!current_user) {
      if (i18n.language === 'FI')
        navigate('/kirjaudu_sisaan')
      else if (i18n.language === 'EN')
        navigate('/login')
    }
  }, [current_user])

  useEffect(() => {
    dispatch(selectCurrentProject(null))
    dispatch(fetch_projects({selection: selectedTab.name}))
  }, [selectedTab])



  const updateProjectKw = (val) => {
    if (val) {
      setSearchProjectKw(val)
    } else {
      setSearchProjectKw('')
    }
  }

  const selectTab = (tab) => {
    setSelectedTab(tab)
  }

  const gotoProjectPage = (project) => {
    navigate(`/project/${project.id}`)
  }

  const getNumberOfProducts = (project) => {
    let result = 0
    project.spaces.forEach((space) => {
      space.assigned_products.forEach((p) => {
        result += p.quantity
      })
    })
    return result > 1 ? result + ' ' + t('account.products').toLowerCase() : result + ' ' + t('account.product').toLowerCase()
  }

  const getProjectStatus = (project) => {
    switch (project.status) {
      case 1:
        return t('account.open')
      case 2:
        return t('account.pending')
      case 3:
        return t('account.inquirySent')
      default:
        return t('account.unknown')
    }
  }

  const toggleCreateProjectModal = () => {
    setShowCreateProjectModal(!showCreateProjectModal)
  }

  const toggleShowEditUserInformationModal = () => {
    setShowEditUserInformationModal(!showEditUserInformationModal)
  }

  const toggleDeleteProjectModal = (project) => {
    setSelectedProject(project)
    setShowDeleteProjectModal(!showDeleteProjectModal)
  }

  const toggleDuplicateProjectModal = (project) => {
    setSelectedProject(project)
    setShowDuplicateProjectModal(!showDuplicateProjectModal)
  }

  const getModifiedDate = (date) => {
    if (date) {
      if (language) {
        moment.locale(language.toLowerCase())
      }
      return moment(date).format('DD.MM.YYYY')
    }
  }

  const handleSignOutClick = async () => {
    dispatch(log_out())
  }

  const navigateManagementPage = () => {
    navigate('/management')
  }

  const btnStyle= {
    padding: '0.5rem 1rem',
    height: '0.9rem',
    marginBottom: '2rem'
  }

  const getFilteredProjects = () => {
    let filtered = projects
  
    if (searchProjectKw.trim().length > 0) {
      filtered = projects.filter((p) =>
        p.name.trim().toLowerCase().includes(searchProjectKw.trim().toLowerCase())
      )
    }
  
    return filtered.slice().sort((a, b) => { // Creates a new array before sorting
      if (sort === "project_name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name, undefined, { sensitivity: "base" }) // Ignores case
          : b.name.localeCompare(a.name, undefined, { sensitivity: "base" })
      } else if (sort === "date_modified") {
        return sortOrder === "asc"
          ? new Date(a.date_modified).getTime() - new Date(b.date_modified).getTime()
          : new Date(b.date_modified).getTime() - new Date(a.date_modified).getTime()
      } else if (sort === "status") {
        return sortOrder === "asc" ? a.status - b.status : b.status - a.status
      }
      return 0
    })
  }

  const showDeleteBtn = (project) => {
    if (current_user && [1,3].includes(current_user.role))
      return true
    else if (selectedTab.name === 'Your projects')
      return true
    else
      return false
  }

  const btnStyleFilter = {
    padding: '0.5rem 1rem',
  }

  return (
    <>
      <div className='account-page-container'>
        <div className='account-page-body-container'>
          <div className='account-page-content-wrapper'>
            <div className='account-page-left-content'>
              { current_user && [1,3,4,5].includes(current_user.role) &&
                <CustomButton
                  button_text='MANAGEMENT PAGE'
                  customStyles={btnStyle}
                  primary
                  onClickHandler={() => navigateManagementPage()}
                />
              }
              
              <div className='account-page-left-content-title'>
                {t('account.hello')} {current_user ? current_user.name : ''}!
              </div>
              <div className='account-page-left-content-text-group'>
                <div className='account-page-left-content-text-entry'>
                  {current_user ? current_user.name : ''}
                </div>
                <div className='account-page-left-content-text-entry'>
                {current_user ? current_user.company : ''}
                </div>
              </div>
              <div className='account-page-left-content-text-group'>
                <div className='account-page-left-content-text-entry'>
                  {current_user ? current_user.email : ''}
                </div>
                <div className='account-page-left-content-text-entry'>
                {current_user ? current_user.phone_number : ''}
                </div>
              </div>

              <div
                className='account-page-left-content-update-link'
                onClick={() => toggleShowEditUserInformationModal()}
              >
                {t('account.updateUserInformation')}
              </div>
              <CustomButton
                button_text={t('account.logout').toUpperCase()}
                primary
                onClickHandler={() => handleSignOutClick()}
              />
            </div>

            <div className='account-page-right-content'>
              { current_user && [2,5].includes(current_user.role) && (
                  <div className='account-page-right-content-title'>
                    {t('account.yourProjects')}
                  </div>
                )
              }
              { current_user && [1,3,4].includes(current_user.role) && (
                  <div className='account-page-right-content-title'>
                    {selectedTab.name}
                  </div>
                )
              }
              <div
                className='account-page-right-content-create-btn'
                onClick={() => toggleCreateProjectModal()}
              >
                <PlusIcon size={16}/> {t('account.createNewProject')}
              </div>
              <div className='account-page-right-content-search-container'>
                <input
                  type='search'
                  className='account-page-right-content-search-input'
                  placeholder={t('account.searchProject')}
                  value={searchProjectKw}
                  onInput={(e) => updateProjectKw(e.target.value)}
                />
              </div>
              { current_user && [1,3,4].includes(current_user.role) && (
                <div className='account-page-right-content-filter-container'>
                  { current_user && [1, 3].includes(current_user.role) && (
                    filterOptionsAdmin.map(option => {
                      return (
                        <CustomButton
                          button_text={option.name}
                          primary={selectedTab.id === option.id}
                          onClickHandler={() => setSelectedTab(option)}
                          customStyles={btnStyleFilter}
                        />
                      )
                    })
                  )}
                  { current_user && [4].includes(current_user.role) && (
                    filterOptions.map(option => {
                      return (
                        <CustomButton
                          button_text={option.name}
                          primary={selectedTab.id === option.id}
                          onClickHandler={() => setSelectedTab(option)}
                          customStyles={btnStyleFilter}
                        />
                      )
                    })
                  )}
                </div>
              )}
              <div className='account-page-right-content-project-headers'>
                <div
                  className='account-page-right-content-project-header hover'
                  onClick={() => {
                      if (sort === 'project_name') {
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                      } else {
                        setSort('project_name')
                        setSortOrder('asc')
                      }
                    }
                  }
                >
                  {t('account.projectName')}
                  {sort === 'project_name' && sortOrder === 'asc' && <ArrowShortUp size={12} color='#8E5CD9' />}
                  {sort === 'project_name' && sortOrder === 'desc' && <ArrowShortDown size={12} color='#8E5CD9' />}
                </div>
                <div className='account-page-right-content-project-header'>
                  {t('account.numberOfProducts')}
                </div>
                <div
                  className='account-page-right-content-project-header hover'
                  onClick={() => {
                      if (sort === 'date_modified') {
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                      } else {
                        setSort('date_modified')
                        setSortOrder('asc')
                      }
                    }
                  }
                >
                  {t('account.dateModified')}
                  {sort === 'date_modified' && sortOrder === 'asc' && <ArrowShortUp size={12} color='#8E5CD9' />}
                  {sort === 'date_modified' && sortOrder === 'desc' && <ArrowShortDown size={12} color='#8E5CD9' />}
                </div>
                <div
                  className='account-page-right-content-project-header hover'
                  onClick={() => {
                      if (sort === 'status') {
                        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                      } else {
                        setSort('status')
                        setSortOrder('asc')
                      }
                    }
                  }
                >
                  {t('account.status')}
                  {sort === 'status' && sortOrder === 'asc' && <ArrowShortUp size={12} color='#8E5CD9' />}
                  {sort === 'status' && sortOrder === 'desc' && <ArrowShortDown size={12} color='#8E5CD9' />}
                </div>
              </div>
              <div className='account-page-right-content-project-entries'>
                { projects &&
                  getFilteredProjects().map((project, index) => (
                    <div
                      className='account-page-right-content-project-entry'
                      onClick={() => {
                        gotoProjectPage(project)
                      }}
                      key={index}
                    >
                      <div className='account-page-right-content-project-entry-cell'>
                        {project.name}
                      </div>
                      <div className='account-page-right-content-project-entry-cell'>
                        {getNumberOfProducts(project)}
                      </div>
                      <div className='account-page-right-content-project-entry-cell'>
                        {getModifiedDate(project.date_modified)}
                      </div>
                      <div className='account-page-right-content-project-entry-cell'>
                        {getProjectStatus(project)}
                      </div>
                        <div className='account-page-right-content-project-entry-icon-group'>
                          <div className='account-page-right-content-project-entry-icon'>
                            <Duplicate size={25}
                              onClick={(e) => {
                                e.stopPropagation()
                                toggleDuplicateProjectModal(project)
                              }}
                            />
                          </div>
                          { project.can_delete && (
                            <div className='account-page-right-content-project-entry-icon'>
                              <Delete size={25}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  toggleDeleteProjectModal(project)
                                }}
                              />
                            </div>
                            )
                          }
                        </div>
                    </div>
                  ))}
                {(!getFilteredProjects() || getFilteredProjects().length === 0) && (
                  <div className='account-page-right-content-project-no-entry'>
                    No product found
                  </div>
                )}
              </div>

          </div>
          </div>
        </div>
      </div>

      {showDuplicateProjectModal && (
        <DuplicateProjectModal
          showing={showDuplicateProjectModal}
          closeModal={toggleDuplicateProjectModal}
          project={selectedProject}
        />
      )}

      {showDeleteProjectModal && (
        <DeleteProjectModal
          showing={showDeleteProjectModal}
          closeModal={toggleDeleteProjectModal}
          project={selectedProject}
        />
      )}

      <EditUserInformationModal
        showing={showEditUserInformationModal}
        closeModal={setShowEditUserInformationModal}
      />

      <CreateProjectModal
        showing={showCreateProjectModal}
        closeModal={toggleCreateProjectModal}
      />
    </>
  )
}

export default Account
