import './styles.scss'
import React, { useState, useRef, useEffect } from 'react'
import { Close, ArrowLeft, ArrowRight } from '../Icons'


const ImageModal = (props) => {
    const {
      images,
      isModalOpen,
      setIsModalOpen
    } = props
    const [currentIndex, setCurrentIndex] = useState(0)
    const [fade, setFade] = useState(false)
    const imgRef = useRef(null);

    const updateMaxHeight = () => {
        if (imgRef.current) {
            imgRef.current.style.maxHeight = `${window.innerHeight * 0.85}px`;
        }
    };

    useEffect(() => {
        // Update max-height on window resize
        window.addEventListener("resize", updateMaxHeight);

        return () => {
            window.removeEventListener("resize", updateMaxHeight);
        };
    }, []);

    useEffect(() => {
        // Update max-height when modal is opened
        if (isModalOpen) {
            updateMaxHeight();
        }
    }, [isModalOpen]);

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const showNext = () => {
        setFade(true)
        setTimeout(() => {
            setCurrentIndex((currentIndex + 1) % images.length)
            setFade(false)
        }, 290)
    }

    const showPrevious = () => {
        setFade(true)
        setTimeout(() => {
            setCurrentIndex((currentIndex - 1 + images.length) % images.length)
            setFade(false)
        }, 290)
    }

    return (
      <>
        {isModalOpen && (
          <div className="modal-container" onClick={closeModal}>
            <div className="modal-close-button" onClick={closeModal}><Close color='white' size={30}/></div>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <div className='modal-image-wrapper'>
                <div
                  className="modal-image-wrapper-prev-button desktop"
                  onClick={(e) => {
                    e.stopPropagation();
                    showPrevious();
                  }}
                >
                  <ArrowLeft size={50} color='white'/>
                </div>
                <div className={`modal-image ${fade ? 'fade' : ''}`}>
                  <img
                    ref={imgRef}
                    src={images[currentIndex]}
                    alt={`Image ${currentIndex + 1}`} 
                  />
                </div>
                <div
                  className="modal-image-wrapper-next-button desktop"
                  onClick={(e) => {
                    e.stopPropagation();
                    showNext();
                  }}
                >
                  <ArrowRight size={50} color='white'/>
                </div>
              </div>
              <div className='modal-button-wrapper'>
                <div
                  className="modal-button-prev-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    showPrevious();
                  }}
                >
                  <ArrowLeft size={50} color='white'/>
                </div>
                <div
                  className="modal-button-next-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    showNext();
                  }}
                >
                  <ArrowRight size={50} color='white'/>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
  )
}

export default ImageModal