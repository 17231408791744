const greenFurniture = {
  productGroupLink: 'SE Lue lisää viherkalusteet',
  headerGroup: 'SE Viherkalusteet',
  headerTitle: 'SE Vehreyttä, viihtyisyyttä ja pörriäisiä keskellä kaupunkia.',
  infoCard1Title: 'SE Iso muutos, pieni vaiva',
  infoCard1Text1: 'SE Elämyksellisiä ulkotiloja palveluna',
  infoCard1Text2: 'SE Runsasta vehreyttä keskellä kaupunkia',
  infoCard1Text3: 'SE Viihtyisyyttä, varjoa ja rauhaa hengähtää',
  infoCard2Title: 'SE Huoletonta asiointia',
  infoCard2Text1: 'SE Muunneltavat moduulit erilaisiin tarpeisiin',
  infoCard2Text2: 'SE Kasvit, kalusteet, asennus, ylläpito ja varastointi yhdessä paketissa',
  infoCard2Text3: 'SE Palvelemme kaikkialla Suomessa',
  infoCard3Title: 'SE Ihmeteltävää ihmisille ja pörriäisille',
  infoCard3Text1: 'SE Tue kaupunkibiodiversiteettiä vehreillä viherkalusteilla',
  infoCard3Text2: 'SE Tarkkaile pörriäisten ja perhosten puuhia',
  infoCard3Text3: 'SE Ihastele, haistele ja maistele yrttejä sekä marjoja',
  textCard1: `SE Viherkalusteissa runsas vihreys yhdistyy kalusteisiin, kuten penkkeihin, tilanjakajiin 
      ja pöytiin. Modulaarisilla viherkalusteilla voidaan luoda varjoisia ja virkistäviä levähdyspaikkoja 
      ja samalla tukea kaupunkibiodiversiteettiä. `,
  infoBox1Title: 'SE Kasvit ja kalusteet yhdessä',
  infoBox1Text: `SE InnoGreenin modulaarisilla viherkalusteratkaisuilla luot helposti viihtyisiä ulkotiloja, 
      jotka tarjoavat levähdyspaikan, varjoa ja virkistystä urbaanissa ympäristössä. Viherkalusteemme on 
      suunniteltu sijoitettaviksi jo rakennetulle pinnalle, tarkoittaen, että ne ovat helppo ratkaisu 
      kaupunkiympäristöön väliaikaisesti tai pidempään tarpeeseen. Perustuksia tai muutakaan kallista 
      muutostyötä ei tarvita.
      \\n
      Modulaarisuuden ansiosta kalusteet ovat räätälöitävissä käyttäjiensä näköiseksi ja kokoiseksi. 
      Asiantuntijamme auttavat juuri sopivan koon sekä sijoituspaikan valinnassa. Luonnonmukaiset 
      kasvivalinnat tuovat paikalle perhoset ja pörriäiset, joita kaupunkilaiset tai asukkaat 
      voivat seurata puuhissaan.
      \\n
      Kun moduulit ja kasvit on valittu, toimitamme kalusteet paikoilleen kasveineen. Huollamme 
      ja vaihdamme kohteeseen toiveiden mukaiset kausikasvit ja talven tullen viemme moduulit 
      talvisäilöön odottamaan seuraavaa kautta. Kaikki viherkalusteemme ovat vuokrattavissa.`,
  infoBox2Title: 'SE Kutsuva viherpysäkki',
  infoBox2Text: `SE Monipuolinen viherpysäkkimme tukee aidosti kaupunkiympäristön biodiversiteettiä 
      tarjoten hyönteisille, kuten kimalaisille ja perhosille, runsaasti ruokaa ja suojaa sekä 
      elintärkeitä luontokäytäviä viheralueiden välille. Samalla viherpysäkki on lepopaikka 
      meille ihmisille. Viherpysäkillä sopii hengailla tai viettää aikaa vaikka shakkia pelaten. 
      Pysäkille on mahdollista istuttaa runsaasti kukkivia kasveja tai esimerkiksi syötäväksi 
      kelpaavia herkullisia yrttejä ja marjoja. Pihamyyntimme auttaa tarpeisiisi ja ulkotilaasi 
      sopivan pysäkin suunnittelussa.`,
  infoBox3Title: 'SE InnoGreen x Parkly -viherseinämoduuli',
  infoBox3Text: `SE Istahda viherseinän syleilyyn, nuuhki kukkien huumaavaa tuoksua tai ihmettele 
      pörriäisten touhuja. InnoGreen x Parkly -viherseinämoduulissa yhdistyvät mukavuus ja 
      viherseinän runsaus. Viherseinämoduuli ei ainoastaan lisää viihtyisyyttä, vaan tuo myös 
      rauhaa sillä se vaimentaa liikenteen ääntä jopa 7 db.`,
}

export default greenFurniture