import './styles.scss'

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  CircularProgress,
  Toolbar,
  Typography,
  Tooltip,
  Box,
} from "@mui/material"
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined'

import InquiryAssignUserModal from './InquiryAssignUserModal'

import {
  unassign_user_inquiry,
} from '../../../../store/asyncThunk'

const InquiryAssignedUser = (props) => {
  const {
    assignableUsers,
  } = props
  const inquiry = useSelector((state) => state.management.selected_inquiry)
  const user = useSelector((state) => state.user.current_user)
  const [isLoading, setIsLoading] = useState(false)
  const [stateHistory, setStateHistory] = useState([])
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('priority')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const dispatch = useDispatch()
  const [showInquiryAssignUser, setShowInquiryAssignUser] = useState(false)

  const toggleShowInquiryAssignUser = () => {
    setShowInquiryAssignUser(!showInquiryAssignUser)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const get_status = (status) => {
    switch (status) {
      case 1:
        return 'Open'
      case 2:
        return 'In Progress'
      case 3:
        return 'Offer Sent'
      case 4:
        return 'Offer Accepted'
      case 5:
        return 'Offer Rejected'
      case 6:
        return 'Guide Sent'
      case 7:
        return 'Closed'
      default:
        return 'Unknown'
    }
  }

  const format_date = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    }
  }

  const handleUnassignUser = async (assign_id) => {
    setIsLoading(true)
    await dispatch(unassign_user_inquiry({
      assign_id: assign_id
    }))
    setIsLoading(false)
  }

  return (
    <>
      <Paper sx={{ width: '100%', borderRadius: 0, position: 'relative' }}>
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 1000, display: isLoading ? 'flex' : 'none' }}>
          <CircularProgress />
        </div>
        <Toolbar>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Assigned Users
          </Typography>
          { [1, 3].includes(user.role) && (
              <Tooltip
                title='Assign users'
              >
                <PersonAddAltOutlinedIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={toggleShowInquiryAssignUser}
                />
              </Tooltip>
            )
          }
        </Toolbar>
        <TableContainer
          elevation={0}
          sx={{ height: "100%", overflow: "auto" }}
        >
          <Table
            size='small'
          >
            <TableHead>
              <TableRow
              sx={{ backgroundColor: '#f5f5f5' }}
              >
                <TableCell>
                  Date
                </TableCell>
                <TableCell>
                  Role
                </TableCell>
                <TableCell>
                  User
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { inquiry.assigned_users.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell >
                        {format_date(item.date_created)}
                      </TableCell>
                      <TableCell >
                        {item.role}
                      </TableCell>
                      <TableCell >
                        {item.name}
                      </TableCell>
                      <TableCell >
                        <Tooltip
                          title='Remove user'
                        >
                          <PersonRemoveOutlinedIcon 
                            sx={{ cursor: 'pointer', fontSize: '20px', color: 'black' }}
                            onClick={() => handleUnassignUser(item.id)}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={stateHistory.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <InquiryAssignUserModal
        assignableUsers={assignableUsers}
        showing={showInquiryAssignUser}
        closeModal={toggleShowInquiryAssignUser}
        inquiry={inquiry}
      />
    </>
  )
}

export default InquiryAssignedUser