const koivu_viherseina = {
  name: 'SE Koivu-viherseinä',
  longDescription: `SE The robust Koivu green wall can be placed on the wall as an eye-catcher, 
      or alternatively it can be used as a flexible double-sided room divider that can be 
      moved effortlessly from one space to another. Birch is sturdy and stable to move, 
      but fresh and modern in its design and nature-inspired look.
      \\n
      The birch green wall contains up to 48 real plants and is safely irrigated with a 
      sub-irrigation system. On the other side of the green wall, it is possible to have a 
      green moss or lichen wall. Real plants need light to thrive and we also supply suitable 
      plant lights for low-light areas.`,
      measurements: [
        {
          type: 'SE Room divider:',
          values: [
            {
              value: 'Width 1255 mm'
            },
            {
              value: 'Height 1432 mm'
            },
            {
              value: 'Depth 540 mm (depth of the frame 350 mm)'
            }
          ]
        },
        {
          type: 'SE Wall mounted:',
          values: [
            {
              value:'Width 1255 mm'
            },
            {
              value:'Height 1255 mm'
            },
            {
              value:'Depth 250 mm'
            }
          ]
        },
      ],
  materials: [
    {
      type: 'SE Frame:',
      value: 'Finnish birch plywood'
    },
    {
      type: 'SE Green wall modules:',
      value: 'Abs plastic, made in Kaarina'
    },
    {
      type: 'SE Plants:',
      value: 'Finland and EU'
    },
    {
      type: 'SE Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'SE Backside of space divider model:',
      value: 'Maintenance-free stabilized moss or lichen (EU)'
    },
    {
      type: 'SE Usage:',
      value: 'Indoor',
      icon: 'indoor'
    },
  ],
  wlt: {
    title: 'SE Tämä viherseinä suojelee 1000 neliötä sademetsää',
    text: `SE Valitsemalla InnoGreenin viherseinän suojelet kanssamme maailman 
      sademetsiä yhteistyössä World Land Trustin kanssa. Jokaista myytyä tai 
      vuokrattua kasviseinäneliömetriä kohden suojelemme 1000 neliömetriä sademetsää.`
  },
  malli: {
    description: `SE Valitse malli`
  },
  kehyksen_vari: {
    description: `SE Näkyvien osien viimeistely`
  },
  taustamateriaali: {
    description: `SE Valitse materiaali seinän toiselle puolelle`
  },
}

export default koivu_viherseina