import './styles.scss'
import { useSelector, useDispatch } from 'react-redux'
import { Close } from '../Icons'
import { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import moment from 'moment'
import { create_project } from '../../store/asyncThunk'
import { useTranslation } from 'react-i18next'

const newProjectInitialState = {
  name: '',
  description: '',
}

const CreateProjectModal = ({ showing, closeModal }) => {
  const dispatch = useDispatch()
  const projects = useSelector((state) => state.project.projects)
  const { t } = useTranslation()
  const [errorList, setErrorList] = useState([])
  const [requested_success, setRequestedSuccess] = useState(false)
  useEffect(() => {
    setErrorList([])
    setRequestedSuccess(false)
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const [newProject, setNewProject] = useState(newProjectInitialState)

  const updateNewProject = ({ name, description }) => {
    if (name !== undefined) {
      setNewProject({ ...newProject, name: name })
    }
    if (description !== undefined) {
      setNewProject({ ...newProject, description: description })
    }
  }

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const handleCreateNewProjectBtnClick = async () => {
    const res = await dispatch(create_project(newProject)).then((res) => {
      if (!res.error) {
        setErrorList([])
        setRequestedSuccess(true)
        setNewProject(newProjectInitialState)
      } else {
        setErrorList([t('account.genericCreateProjectError')])
      }
    })
  }

  return (
    showing && (
      <div className='create-project-modal-root'>
        <div className='create-project-modal-container'>
          <div className='create-project-modal-header'>
            <div className='create-project-modal-header-exit-btn-container'>
              <div
                className='create-project-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20} />
              </div>
            </div>
          </div>

          <div className='create-project-modal-content'>
            {requested_success && (
              <div>{t('account.createProjectSuccessMessage')}</div>
            )}
            {!requested_success && (
              <>
                <div className='create-project-modal-header-title'>
                  {t('account.createNewProject')}
                </div>
                {errorList.length > 0 && (
                  <div className='create-project-modal-error-container'>
                    {errorList.map((error) => {
                      return (
                        <div
                          className='create-project-modal-error-entry'
                          key={error}
                        >
                          - {error}
                        </div>
                      )
                    })}
                  </div>
                )}
                <div className='create-project-modal-input-group'>
                  <label className='create-project-modal-input-label'>
                    {t('account.projectName')}*
                  </label>
                  <input
                    type='text'
                    value={newProject.name}
                    className='create-project-modal-input-field'
                    onInput={(e) => updateNewProject({ name: e.target.value })}
                  ></input>
                </div>
                <div className='create-project-modal-input-group'>
                  <label className='create-project-modal-input-label'>
                    {t('account.projectDescription')}
                  </label>

                  <textarea
                    type='text'
                    value={newProject.description}
                    className='create-project-modal-input-field textarea'
                    onInput={(e) =>
                      updateNewProject({ description: e.target.value })
                    }
                  ></textarea>
                </div>
              </>
            )}
          </div>

          <div className='create-project-modal-btn-group'>
            {requested_success ? (
              <CustomButton
                button_text={t('account.close').toUpperCase()}
                onClickHandler={() => close()}
              />
            ) : (
              <>
                <CustomButton
                  button_text={t('account.create').toUpperCase()}
                  disabled={newProject.name.trim().length === 0}
                  onClickHandler={() => handleCreateNewProjectBtnClick()}
                />
                <CustomButton
                  button_text={t('account.cancel').toUpperCase()}
                  onClickHandler={() => close()}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default CreateProjectModal
