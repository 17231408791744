import './styles.scss'
import ReactPlayer from 'react-player/vimeo'
import { useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const HeaderBox = (props) => {
  const {
    titleText = '',
    pageText = '',
    imgSrc = '',
    titleBackgroundColor = '',
    type = '',
    videoSrc = '',
  } = props
  let typeClass = ''
  const [isLoading, setIsLoading] = useState(true)

  const handleVideoReady = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 650)
  }

  if (type === 'contactPage') {
    typeClass = 'contact-page'
  }

  if (type === 'workPage') {
    typeClass = 'work-page'
  }

  if (type === 'productPage') {
    typeClass = 'product-page'
  }

  if (imgSrc) {
      return (
        <div className='header-box-container'>
          <div className='header-box-body-container'>
            <div className={'header-box-title-container ' + titleBackgroundColor + ' ' + typeClass}>
              <div className='header-box-page-text'>
                {pageText}
              </div>
              <div className='header-box-title-text'>
                {titleText}
              </div>
            </div>
            <div className='header-box-image-container'>
              <img className={typeClass} src={imgSrc} alt="Header Image" />
            </div>
          </div>
        </div>
      )
  }
  if (videoSrc) {
    return (
      <div className='header-box-container'>
        <div className='header-box-body-container'>
          <div className={'header-box-title-container ' + titleBackgroundColor + ' ' + typeClass}>
            <div className='header-box-page-text'>
              {pageText}
            </div>
            <div className={'header-box-title-text header-text'} title={titleText}>
              {titleText}
            </div>
          </div>
          <div className='header-box-video-container'>
            <div className='header-box-video-wrapper'>
              {isLoading ?
                (
                  <div className='header-box-video-loader'>
                    <ClipLoader color='#1a73e8' />
                  </div>
                ) : (
                  <></>
                )
              }
              <ReactPlayer
                className='header-box-video-player'
                url={videoSrc}
                loop={true}
                playing={true}
                muted={true}
                volume={0}
                width='100%'
                height='100%'
                playsinline
                onReady={handleVideoReady}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderBox
