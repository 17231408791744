const outDoor = {
  headerGroup: 'Outdoor areas',
  headerTitle: 'Green solutions for challenging urban environments.',
  infoCard1Title: 'Flexible and versatile solutions',
  infoCard1Text1: 'Tailor-made long-term or seasonal solutions',
  infoCard1Text2: 'A comprehensive service covering everything from design to maintenance',
  infoCard1Text3: 'Easy-to-rent furniture and greenery',
  infoCard2Title: 'Natural greenery for city environment',
  infoCard2Text1: 'Native, wild and perennial plant species',
  infoCard2Text2: 'Cozy areas for pollinators as well',
  infoCard2Text3: 'Nature corridors in urban environments',
  infoBoxTitle1: 'Natural and functional outdoor spaces',
  infoBoxText1: `We specialise in natural outdoor areas, meadows, outdoor green walls and green 
      urban environments. We design and solve any outdoor space issues you may have and turn 
      your wishes into reality from start to finish. We service and maintain your outdoor 
      space year-round, and we deliver a report with every visit.
      \\n
      A safely and sensibly constructed outdoor space will provide long-lasting enjoyment, 
      and our aim is to create functional outdoor areas that are attractive, natural and 
      friendly to pollinators. A well-designed outdoor space is effortless to maintain and 
      will stand the test of time while retaining its splendour.
      \\n
      Outdoor areas can have different functions, such as areas for relaxing, working or just 
      hanging out. We consider materials, plant selection, flow management, grounds care and 
      maintenance, lighting and more. We strive to make your outdoor space safe, usable all 
      year round, welcoming and easy to maintain, so that it serves its users to the best of 
      its potential. Invite us for a visit!`,
  infoBoxTitle2: 'Inviting greenery',
  infoBoxText2: `Seasonal plants create spectacular and inviting entrances and make outdoor 
      spaces more attractive. We design, install and maintain customised seasonal plantings 
      to meet our customers' wishes and according to the environmental conditions. We bring 
      greenery and floral beauty to entrances, outdoor areas, terraces and courtyards, among 
      others. Investing in outdoor areas and courtyards makes buildings more approachable 
      and welcoming, whether they are companies or residences.
      \\n
      In seasonal planting, we combine annual and perennial plants to create a sustainable 
      and natural whole. We use domestic, locally produced seedlings and also take into 
      account the needs of pollinators in our plantings. We create beautiful and vibrant 
      environments that are enjoyable for people and pollinators alike. We also create 
      plantings that combine smells and sounds with visual sensations to create 
      comprehensive sensory experiences.`,
  infoBoxTitle3: 'Green interior design',
  infoBoxText3: 'Greenery, comfort and buzzing insects in the middle of the city.',
}

export default outDoor