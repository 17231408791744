import './styles.scss'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'
import { useAppSelector } from '../../store'
import {
  customized_product
} from '../../store/productSlice'
import AddProductToProjectModal from '../../components/AddProductToProjectModal'
import ProductInquiryModal from '../../components/SendInquiryModal'
import { useTranslation } from 'react-i18next'
import { get_decoded_string } from '../../plugins/helper'
import CustomButton from '../../components/common/CustomButton'
import LogInModal from '../../components/LogInModal'

const YourSelection = ({product, scrollRefs}) => {
  const [configuration, setConfiguration] = useState(null)
  const { t, i18n } = useTranslation()
  const current_user = useSelector((state) => state.user.current_user)
  const [loginSuccessful, setLoginSuccessful] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (product) {
      if(i18n.exists(`${get_decoded_string(product.name)}.configuration`)) {
        setConfiguration(i18n.t(`${get_decoded_string(product.name)}.configuration`, { returnObjects: true }))
      }
    }
    // Need to add language change to dependency array to update translation strings
  }, [product, i18n.language])

  const filtered_customizations = useSelector((state) =>
    state.product.product_customization
  )
  const selectedProduct = useAppSelector(customized_product)

  const saved_customizations = useSelector(
    (state) => state.product.selected_customized_product?.customizations ?? []
  )
  const [showAddToProjectState, setAddToProjectState] = useState(false)
  const [showProductInquiryModalState, setProductInquiryModalState] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)

  const get_selected_option = (customization, config=null, option=null) => {
    let saved_customization = saved_customizations.find(
      (c) => c.id === customization.id
    )
    if (selectedProduct && selectedProduct?.name === 'Viherpysäkki')  {
      if (option) {
        saved_customization = saved_customizations.find(
          (c) => c.selected_option_id === option.id
        )
      }
    }
    if (selectedProduct && selectedProduct?.name === 'Viherseinä')  {
      if (option) {
        saved_customization = saved_customizations.find(
          (c) => c.selected_option_id === option.id
        )
      }
    }
    if (saved_customization) {
      const foundOption = customization.options.find(
        (o) => saved_customization.selected_option_id === o.id
      )

      // for input field types
      if (config) {
        if (config.type === 'räätälöitävä_viherseinä') {
          if (config.configType === 'height') {
            return (saved_customization.value * config.value + 400) + ' mm'
          }
          return (saved_customization.value * config.value) + ' mm'
        } else if (config.type === 'räätälöitävä_sammalseinä') {
          if (config.configType === 'custom') {
            return (saved_customization.value)
          } else {
            return (saved_customization.value) + ' MM'
          }
        } else if (selectedProduct && selectedProduct?.name === 'Viherpysäkki') {
            return (saved_customization.value) + ' X ' + config.toUpperCase()
        } else if (selectedProduct && selectedProduct?.name === 'Viherseinä') {
          return (saved_customization.value) + ' X ' + config.toUpperCase()
      }
      }
      if (foundOption) {
        if (get_decoded_string(customization.name)=== 'korkeus') {
          return (t(`products.${get_decoded_string(customization.name)}_options.k${get_decoded_string(foundOption.name)}`))
        } 
        else if (get_decoded_string(customization.name) === 'leveys') {
          return (t(`products.${get_decoded_string(customization.name)}_options.l${get_decoded_string(foundOption.name)}`))
        }
        return (t(`products.${get_decoded_string(customization.name)}_options.${get_decoded_string(foundOption.name)}`))
      }
      return null
    }

    return null
  }

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal)
  }

  const toggleAddToProjectModal = () => {
    if (!current_user) {
      toggleLoginModal()
    } else {
      setAddToProjectState(!showAddToProjectState)
    }
  }

  useEffect(() => {
    if (loginSuccessful) {
      setAddToProjectState(!showAddToProjectState)
    }
  }, [loginSuccessful])

  const toggleProductInquiryModal = () => {
    setProductInquiryModalState(!showProductInquiryModalState)
  }

  const customizationSelected = (customization) => {
    const saved_customization = saved_customizations.find(
      (c) => c.id === customization.id
    )
    return saved_customization ? true : false
  }

  const allCustomizationsSelected = () => {
    return filtered_customizations.every((c) => customizationSelected(c))
  }

  const scrollToCustomization = (name) => {
    if (scrollRefs && scrollRefs.current[name]) {
      scrollRefs.current[name].scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const btnStyle = {
    padding: '1.25rem 2.75rem',
  }

  return (
    <>
      <div className='your-selection-page-container'>
        <div className='your-selection-page-body-container'>
          <div className='your-selection-info'>
            <div className='your-selection-info-title'>
              {t('common.yourSelection')}
            </div>
            {/* <p className='your-selection-info-description'>
              {t('common.yourSelectionText')}
            </p> */}
          </div>
          <div className='your-selection-cart'>
            <div className='your-selection-cart-title'>{t(`${get_decoded_string(product.name)}.name`)}</div>
            <div className='your-selection-cart-customization-entries'>
              {filtered_customizations.map((customization, index) => {
                // if (selectedProduct && selectedProduct.name === 'Viherpysäkki') {
                if (customization.type === 4) {
                  return (
                    <div
                      className='your-selection-cart-customization-entry'
                      key={index}
                    >
                      <div className='your-selection-customization-entry-name'>
                        {t(`${get_decoded_string(product.name)}.${get_decoded_string(customization.name)}.name`).toUpperCase()}:
                      </div>
                      {customizationSelected(customization) ?
                      (<div className='your-selection-customization-entry-container'>
                        {
                          customization.options.map((option, optionIndex) => {
                            let saved_customization = null
                            if (saved_customizations) {
                              saved_customization = saved_customizations.find(
                                (c) => c.selected_option_id === option.id
                              )
                            }
                            if (!saved_customization) {
                              return null
                            }
                            if (configuration) {
                              return (
                                <div className='your-selection-customization-entry-container-value' key={optionIndex}>
                                  { get_selected_option(customization, configuration[index]?.text[optionIndex], option) }
                                </div>
                              )
                            }
                          }).filter(e => e !== null)
                        }
                      </div>)
                      : 
                      (<div className='your-selection-customization-entry-value-no-selection' onClick={() => {scrollToCustomization(customization.name)}}>
                        No selection
                      </div>)}
                    </div>
                  )
                } else {
                  return (
                    <div
                      className='your-selection-cart-customization-entry'
                      key={index}
                    >
                      <div className='your-selection-customization-entry-name'>
                        {`${index+1}. ${t(`products.${get_decoded_string(customization.name)}`).toUpperCase()}`}:
                      </div>
                      {customizationSelected(customization) ?
                      (<div className='your-selection-customization-entry-value'>
                        { configuration !== null ? get_selected_option(customization, configuration[index]).toUpperCase() : get_selected_option(customization).toUpperCase() }
                      </div>) 
                      : 
                      (<div className='your-selection-customization-entry-value-no-selection' onClick={() => {scrollToCustomization(customization.name)}}>
                        No selection
                      </div>)}
                    </div>
                  )
                }
              })}
            </div>
            <div className='your-selection-cart-actions'>
              {/* <div>
                <p
                  className={'your-selection-cart-btn ' + (allCustomizationsSelected() ? 'add-btn' : ' add-btn-disabled')}
                  onClick={() => toggleAddToProjectModal()}
                >
                  {t('products.addToProject')}
                </p>
              </div> */}
              <CustomButton
                  button_text={t('products.addToProject')}
                  primary={true}
                  customStyles={btnStyle}
                  contentClass='send-btn'
                  onClickHandler={toggleAddToProjectModal}
                />
              <CustomButton
                  button_text={t('products.sendInquiry')}
                  purple={true}
                  customStyles={btnStyle}
                  contentClass='send-btn'
                  onClickHandler={toggleProductInquiryModal}
                />
              {/* <div
                className='your-selection-cart-btn inquiry-btn'
                onClick={() => toggleProductInquiryModal()}
              >
                {t('products.sendInquiry')}
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {showAddToProjectState && (
        <AddProductToProjectModal
          product={{
            ...selectedProduct,
            product_id: selectedProduct.id,
            id: null,
          }}
          showing={showAddToProjectState}
          closeModal={toggleAddToProjectModal}
        />
      )}

      {showProductInquiryModalState && (
        <ProductInquiryModal
          product_id={product.id}
          showing={showProductInquiryModalState}
          closeModal={toggleProductInquiryModal}
        />
      )}

      {showLoginModal && (
        <LogInModal
          showing={showLoginModal}
          closeModal={toggleLoginModal}
          setLoginSuccessful={setLoginSuccessful}
        />
      )}
    </>
  )
}

export default YourSelection
