import './styles.scss'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import ReferenceFilters from './ReferenceFilters'

import moment from 'moment'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  TableHead,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  FormControl,
  FilledInput,
  InputAdornment,
  Box,
  Button,
  Popper,
  MenuItem,
  ClickAwayListener,
} from "@mui/material"
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SearchIcon from '@mui/icons-material/Search'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { setSelectedReference} from '../../../../store/managementSlice'

import {
  fetch_management_references,
  fetch_reference_groups,
  update_reference_state,
} from '../../../../store/asyncThunk'

const dropdownOptions = [
  {
    id: 1,
    name: 'DRAFT',
  },
  {
    id: 2,
    name: 'PUBLISHED',
  }
]

const ReferenceTable = (props) => {
  const {
    handleSetSelectedSelection,
    handleSetSelectedTab,
  } = props
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  const [filter, setFilter] = useState(null)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { references, referenceGroup, recordsFiltered } = useSelector((state) => state.reference)
  const inputRef = useRef(null)
  const [filterOptions, setFilterOptions] = useState([])
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null) // To track which dropdown is open
  const anchorRefs = useRef({})

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search)
    }, 500)
  
    return () => clearTimeout(handler)
  }, [search])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = (id) => {
    setOpenDropdown(openDropdown === id ? null : id) // Toggle dropdown visibility
  }

  const handleClose = () => {
    setOpenDropdown(null) // Close the dropdown
  }

  useEffect(() => {
    (async () => {
      await dispatch(fetch_reference_groups())
      .then((res) => {
        const {productGroup, referenceGroup} = res.payload
        const cleanProductGroup = productGroup.map(({ id, ...rest }) => rest)
        const cleanReferenceGroup = referenceGroup.map(({ id, ...rest }) => rest)
        setFilterOptions([...cleanProductGroup, ...cleanReferenceGroup])
      })
    })()
  }, [])

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      await dispatch(fetch_management_references({page: page + 1, per_page: rowsPerPage, search: debouncedSearch, filters: filter}))
      setIsLoading(false)
    })()
  }, [page, rowsPerPage, debouncedSearch, filter])

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const handleEditClick = (reference) => {
    dispatch(setSelectedReference(reference))
    handleSetSelectedSelection('Reference Editor', reference.id)
  }

  const handleAddClick = () => {
    dispatch(setSelectedReference({}))
    handleSetSelectedTab('Reference Editor')
  }

  const getReferenceState = (state) => {
    switch (state) {
      case 1:
        return 'Draft'
      case 2:
        return 'Pending'
      case 3:
        return 'Published'
      case 4:
        return 'Archived'
      default:
        return ''
    }
  }

  const update_state = async (reference, option) => {
    const payload = {
      state: option.id
    }
    setOpenDropdown(null)
    await dispatch(update_reference_state({reference_id: reference.id, payload: payload}))
  }
  

  const format_date = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY')
    }
  }

  return (
      <div className='inquiries-container'>
        <div className='inquiries-filter-container'>
          <ReferenceFilters
            filterOptions={filterOptions}
            stateFilter={filter}
            setStateFilter={setFilter}
          />
        </div>

        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0  }}>
          {/* Enhanced Toolbar */}
            <Toolbar>
              <Typography
                sx={{ flex: '1 1 10%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                References
              </Typography>
              <Tooltip
                title="Search Title"
              >
                <FormControl sx={{ m: 1, width: '30ch' }}>
                  <FilledInput
                    id='search'
                    placeholder='Search'
                    disableUnderline
                    inputRef={inputRef}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    startAdornment={
                      <InputAdornment
                        position="start"
                      >
                        <SearchIcon
                          sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                          onClick={handleIconClick}
                        />
                      </InputAdornment>
                    }
                    sx={{
                      paddingTop: 0, // Removes extra top/bottom padding
                    }}
                  />
                </FormControl>
              </Tooltip>
              <Tooltip
                title="Add Reference"
              >
                <IconButton
                  onClick={handleAddClick}
                  disableRipple={true}
                >
                  <PostAddOutlinedIcon 
                    fontSize="large"
                    htmlColor="#8E5CD9"
                  />
                </IconButton>
              </Tooltip>
            </Toolbar>
          <TableContainer
            elevation={0}
          >
            <Table
              // size={size}
            >
              <TableHead
                sx={{ backgroundColor: '#f5f5f5' }}
              >
                <TableRow>
                  <TableCell sx={{ fontFamily: 'Libre Franklin', fontWeight: 600 }}>Title</TableCell>
                  <TableCell sx={{ fontFamily: 'Libre Franklin', fontWeight: 600 }}>Tags</TableCell>
                  <TableCell sx={{ fontFamily: 'Libre Franklin', fontWeight: 600 }}>State</TableCell>
                  <TableCell sx={{ fontFamily: 'Libre Franklin', fontWeight: 600 }}>Published Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ?
                  (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <div style={{ height: rowsPerPage * 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    references &&
                      references.map((reference, index) => (
                        <TableRow
                          key={reference.id} 
                          hover
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleEditClick(reference)}
                        >
                          <TableCell>{reference.title}</TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              { reference.productGroup.map((group, index) => (
                                  <div key={index}>{group.tag.toUpperCase()}</div>
                                ))
                              }
                              { reference.referenceGroup.map((group, index) => (
                                  <div key={index}>{group.tag.toUpperCase()}</div>
                                ))
                              }
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Button
                              ref={(el) => (anchorRefs.current[index] = el)}
                              disableRipple
                              onClick={(e) => {
                                e.stopPropagation()
                                handleClick(index)
                              }}
                              sx={{ textAlign: 'left' }}
                              className='inquiries-row-state-button'
                              style={{ backgroundColor: 'transparent' }}
                            >
                              {getReferenceState(reference.state)}
                            </Button>
                            <Popper
                              open={openDropdown === index}
                              anchorEl={anchorRefs.current[index]}
                              placement="bottom-end"
                            >
                              <ClickAwayListener
                                onClickAway={handleClose}
                              >
                                <Paper sx={{ p: 1 }}>
                                  { dropdownOptions.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          update_state(reference, option)
                                        }}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))
                                  }
                                </Paper>
                              </ClickAwayListener>
                            </Popper>
                          </TableCell>
                          <TableCell>
                            { reference.state === 1 ? 
                              (
                                'N/A'
                              ) : (
                                format_date(reference.publish_date)
                              )
                            }
                          </TableCell>
                        </TableRow>
                      ))
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={recordsFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
  )
}

export default ReferenceTable
