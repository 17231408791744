import './styles.scss'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import moment from 'moment'

import CustomButton from '../../../../components/common/CustomButton'
import CustomDropdown from '../../../../components/common/CustomDropdown'
import ProductSummaryList from '../../../../components/ProductSummaryList'
import DeleteAssignedProductModal from '../../../../components/DeleteAssignedProductModal'
import AddProductToProjectModal from '../../../../components/AddProductToProjectModal'
import EditSpaceModal from '../../../../components/EditSpaceModal'
import LoadingSpinner from '../../../../components/common/LoadingSpinner'
import ProductEntry from '../../../../components/ProductEntry'
import { PlusIcon } from '../../../../components/Icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

import { selectCurrentProject,} from '../../../../store/projectSlice'

import { fetch_project, fetch_product_customizations } from '../../../../store/asyncThunk'

const sorters = [
  {
    id: 1,
    name: 'DATE ADDED',
    translation: 'project.dateAdded',
    direction: true,
    icon: 'arrow-up',
  },
  {
    id: 2,
    name: 'DATE ADDED',
    translation: 'project.dateAdded',
    direction: false,
    icon: 'arrow-down',
  },
  {
    id: 3,
    name: 'PRODUCT NAME',
    translation: 'project.productName',
    direction: true,
    icon: 'arrow-up',
  },
  {
    id: 4,
    name: 'PRODUCT NAME',
    translation: 'project.productName',
    direction: false,
    icon: 'arrow-down',
  },
]

const InquiryProjectEdit = (props) => {
  const {
    projectView,
    current_project
  } = props
  const [selectedViewFilter, setSelectedViewFilter] = useState(null)
  const [productGroups, setProductGroups] = useState({})
  // const inquiry = useSelector((state) => state.management.selected_inquiry)
  const contentRef = useRef(null)
  const dispatch = useDispatch()
  const [view_filters, setViewFilters] = useState([])
  const [sortBy, setSortBy] = useState(sorters[0])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showAddProductToProjectModal, setShowAddProductToProjectModal] = useState(false)
  const [assignSpaceOnly, setAssignSpaceOnly] = useState(false)
  const [filteredProducts, setfilteredProducts] = useState([])
  const customizations = useSelector((state) => state.product.customizations)
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [errorList, setErrorList] = useState([])
  const navigate = useNavigate()
  const [showEditSpacesState, setEditSpacesState] = useState(false)

  const toggleEditSpacesModal = () => {
    setEditSpacesState(!showEditSpacesState)
  }

  const selectSortBy = (val) => {
    setSortBy(val)
  }

  const [
    showDeleteAssignedProductProductModal,
    setShowDeleteAssignedProductProductModal,
  ] = useState(false)
  useEffect(() => {
    if (view_filters) {
      if (!selectedViewFilter) {
        setSelectedViewFilter(view_filters[0])
      }
    }
  }, [view_filters, selectedViewFilter])

  const toggleConfirmRemoveProductModal = (product) => {
    if (showDeleteAssignedProductProductModal) {
      setSelectedProduct(null)
      setShowDeleteAssignedProductProductModal(false)
    } else {
      setSelectedProduct({ ...product, project_id: current_project.id })
      setShowDeleteAssignedProductProductModal(true)
    }
  }

  const toggleAddProductToProjectModal = (product, assign_space_only) => {
    if (showAddProductToProjectModal) {
      setSelectedProduct(null)
      setAssignSpaceOnly(false)
      setShowAddProductToProjectModal(false)
    } else {
      setSelectedProduct(product)
      setAssignSpaceOnly(assign_space_only)
      setShowAddProductToProjectModal(true)
    }
  }

  const handleViewFilterClick = (item) => {
    if (item && item.id !== 6) {
      setSelectedViewFilter(item)
    }
  }

  useEffect(() => {
    if (current_project) {
      const space_list = []
      //Check and add customed space from the selected project
      if (current_project) {
        //Add all space and default space first
        space_list.push({
          id: 'all',
          name: 'ALL SPACES',
          translation: 'project.allSpaces',
          customStyles: {
            backgroundColor: '#85E8E5',
            borderColor: '#85E8E5',
          },
        })

        current_project.spaces.forEach((s) => {
          const index = space_list.findIndex((space) => space.id === s.id)
          if (index === -1) {
            space_list.push({
              ...s,
              id: s.id,
              name: s.name,
              is_default: s.is_default ?? false,
              translation: s.is_default ? 'project.defaultSpace' : null,
              customStyles: {
                backgroundColor: s.is_default ? '#85E8E5' : '#FCEA19',
                borderColor: s.is_default ? '#85E8E5' : '#FCEA19',
              },
            })
          }
        })
      }

      setViewFilters(space_list)
    }
  }, [current_project])

    useEffect(() => {
      if (selectedViewFilter && current_project) {
        let filtered_list = []
        current_project.spaces
          .filter((space) => {
            if (selectedViewFilter) {
              if (['all'].includes(selectedViewFilter.id)) {
                return true
              } else if (['default'].includes(selectedViewFilter.id)) {
                return space.is_default
              } else {
                const view = view_filters.find(
                  (v) => v.id === selectedViewFilter.id
                )
  
                return view.id === space.id
              }
            }
            return true
          })
          .forEach((space) => {
            space.assigned_products.forEach((p) => {
              const product = current_project.products.find(
                (pro) => pro.id === p.id
              )
  
              if (product) {
                filtered_list.push({
                  ...product,
                  space_id: space.id,
                  quantity: p.quantity,
                })
              }
            })
          })

        setfilteredProducts(filtered_list)
      }
    }, [selectedViewFilter, current_project, view_filters])
  

  const customStyles = {
    backgroundColor: 'white',
  }

  const customStylesFilter = {
    backgroundColor: '#FCEA19',
  }

  return (
    <div className={projectView === 'edit' ? 'inquiry-project-show' : 'inquiry-project-hide'}>
      {/* PRODUCT FILTER GROUP */}
      <div className='inquiry-filter-groups-container'>
        <div className='inquiry-filter-groups-wrapper'>
          <div className='inquiry-filter-container'>
            <div className='inquiry-filter-label'>{t('project.view').toUpperCase()}</div>
            <div className='inquiry-filter-selection'>
              <div className='inquiry-filter-selection-buttons'>
                {view_filters.map((filter) => {
                  if (filter.id === 'all') {
                    return (
                      <CustomButton
                      key={filter.id}
                      button_text={filter.translation ? t(filter.translation).toUpperCase() : filter.name.toUpperCase()}
                      turquoise={true}
                      selected={
                        selectedViewFilter &&
                        selectedViewFilter.id === filter.id
                      }
                      onClickHandler={() => handleViewFilterClick(filter)}
                      contentClass='project-filter'
                    />
                    )
                    
                  } else {
                    return (
                      <CustomButton
                        key={filter.id}
                        button_text={filter.translation ? t(filter.translation).toUpperCase() : filter.name.toUpperCase()}
                        yellow={true}
                        selected={
                          selectedViewFilter &&
                          selectedViewFilter.id === filter.id
                        }
                        onClickHandler={() => handleViewFilterClick(filter)}
                        contentClass='project-filter'
                      />
                    )

                  }
                })}
                <CustomButton
                  button_text={t('project.editSpaces').toUpperCase()}
                  onClickHandler={() => toggleEditSpacesModal()}
                  transparent={true}
                  contentClass='project-filter'
                  disabled={current_project.can_edit === false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PRODUCT LIST  */}
      <div className='inquiry-summary-list-container'>
        <div className='inquiry-summary-list-wrapper column'>
          { isLoading ? 
           (
              <LoadingSpinner /> 
           ) : (
              filteredProducts && (
                filteredProducts.map((product, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ProductEntry
                        product={product}
                        toggleConfirmRemoveProductModal={toggleConfirmRemoveProductModal}
                        toggleAddProductToProjectModal={toggleAddProductToProjectModal}
                        current_project={current_project}
                        project_id={current_project.id}
                        customizations={customizations}
                        page='inquiry'
                        showProductOverViewBtn={false}
                      />
                    </React.Fragment>
                  )
                })
              ) 
            )

          }
        </div>
      </div>

      {showEditSpacesState && (
        <EditSpaceModal
          showing={showEditSpacesState}
          closeModal={toggleEditSpacesModal}
          spaces={view_filters.filter(
            (v) => v.id !== 'default' && v.id !== 'all'
          )}
        />
      )}

      {showDeleteAssignedProductProductModal && (
        <DeleteAssignedProductModal
          showing={showDeleteAssignedProductProductModal}
          closeModal={toggleConfirmRemoveProductModal}
          product={selectedProduct}
        />
      )}

      {showAddProductToProjectModal && (
        <AddProductToProjectModal
          presetProject={assignSpaceOnly ? current_project : null}
          assignSpaceOnly={assignSpaceOnly}
          product={selectedProduct}
          showing={showAddProductToProjectModal}
          closeModal={toggleAddProductToProjectModal}
          keepCurrentProject={true}
        />
      )}
    </div>
  )
}

export default InquiryProjectEdit