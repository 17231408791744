import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const CustomHeader = (props) => {
  const {
    id,
    type,
    referenceGroup,
    productGroup,
    imgSrc,
    text,
    title,
    text_en = '',
    title_en = '',
    date,
    blogGroup,
  } = props
  const { t } = useTranslation()
  const language = useSelector((state) => state.user.language)

  const joinReferenceGroups = () => {
    let reference = ''
    let product = ''

    reference = referenceGroup.map(item => t(item.translate)).join(' | ').toUpperCase()
    product = productGroup.map(item => t(item.translate)).join(' | ').toUpperCase()

    if (reference && product) {
      return reference + ' | ' + product
    }
    if (reference && !product) {
      return reference
    }
    if (!reference && product) {
      return product
    }
  }

  const getTranslatedText = () => {
    if (language) {
      if (language === 'FI') {
        return text
      } else if (language === 'EN') {
        if (text_en) {
          return text_en
        } else {
          return text
        }
      }
    } else {
      return text
    }
  }

  const getTranslatedTitle = () => {
    if (language) {
      if (language === 'FI') {
        return title
      } else if (language === 'EN') {
        if (title_en) {
          return title_en
        } else {
          return title
        }
      }
    } else {
      return title
    }
  }

  const joinBlogGroups = () => {
    let tags = ''
    tags = blogGroup.map(item => t(item.translate)).join(' | ').toUpperCase()

    return tags
  }

  if (type === 'reference') {
    return (
      <>
        <div className='custom-header-reference-container'>
          <div className='custom-header-reference-text-container'>
            <div className='custom-header-reference-left-container'>
              <div className='custom-header-reference-text'>{t('reference.headerTitleSingular')}</div>
              <div className='custom-header-reference-title-text'>{getTranslatedTitle()}</div>
              <div className='custom-header-reference-type-text'>{joinReferenceGroups()}</div>
            </div>
            <div className='custom-header-reference-right-container'>
              <div className='custom-header-reference-body-text' dangerouslySetInnerHTML={{ __html: getTranslatedText() }}></div>
            </div>
          </div>
          <div className='custom-header-reference-image-container'>
            <img src={imgSrc} alt="" />
          </div>
        </div>
      </>
    )
  } else if (type === 'blog') {
    return (
      <>
        <div className='custom-header-blog-container'>
          <div className='custom-header-blog-text-container'>
              <div className='custom-header-blog-type-date'>{date}</div>
              <div className='custom-header-blog-title-text'>{getTranslatedTitle()}</div>
              <div className='custom-header-blog-type-filter'>{joinBlogGroups()}</div>
          </div>
          <div className='custom-header-blog-image-container'>
            <img src={imgSrc} alt="" />
          </div>
        </div>
      </>
    )
  }

}


export default CustomHeader
