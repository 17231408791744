import './styles.scss'

import React, { useState, useEffect, useRef } from 'react'
import { Link, useSearchParams, useMatches } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-multi-carousel'

import ProductGroup from '../ProductGroup'
import LoadingScreen from '../../components/common/LoadingScreen'
import HeaderBox from '../../components/HeaderBox'
import { InDoor, OutDoor } from '../../components/Icons'
import CustomButton from '../../components/common/CustomButton'
import InfoBox from '../../components/InfoBox'
import { AccordionClose } from '../../components/Icons'

import imgSrc1 from '../../assets/images/image-product-page-header.jpg'
import imgSrc2 from '../../assets/images/Products/tuotteet-5.jpg'
import vihreysPalvelunaImgSrc from '../../assets/images/Products/tuotteet-1.png'
import vihersisustusImgSrc from '../../assets/images/Products/tuotteet-2.png'
import ulkoalueetImgSrc from '../../assets/images/Products/tuotteet-3.png'
import RaataloidytRatkaisutImgSrc from '../../assets/images/Products/tuotteet-4.png'

import { useAppSelector } from '../../store'
import { setGlobalLoading } from '../../store/uiSlice'
import { get_product_product_group_combined } from '../../store/productSlice'

import { fetch_products } from '../../store/asyncThunk'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 1450 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1450, min: 1110 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1110, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}
const cardLinks = [
  {
    name: 'common.greenService',
    link: '/vihreys_palveluna',
    link_en: '/green-as-a-service',
    imgSrc: vihreysPalvelunaImgSrc,
    id: 1
  },
  {
    name: 'common.greenInterior',
    link: '/vihersisustus',
    link_en: '/green-interior-design',
    imgSrc: vihersisustusImgSrc,
    id: 2
  },
  {
    name: 'common.outdoorSpaces',
    link: '/ulkoalueet',
    link_en: '/outdoor-areas',
    imgSrc: ulkoalueetImgSrc,
    id: 3
  },
  {
    name: 'common.customSolutions',
    link: '/raataloidyt_ratkaisut',
    link_en: '/tailored-solutions',
    imgSrc: RaataloidytRatkaisutImgSrc,
    id: 4
  }
]

const filterBtns = [
  {
    name: 'Uutuudet ja ajankohtaiset',
    type: 'group',
    btnName: 'products.new',
    id: 1
  },
  {
    name: 'Koivu-ruukut',
    type: 'group',
    btnName: 'products.pots',
    id: 2
  },
  {
    name: 'Viherseinät',
    type: 'group',
    btnName: 'products.greenWalls',
    id: 3
  },
  {
    name: 'Sammal & jäkälä',
    type: 'group',
    btnName: 'products.mossAndLichen',
    id: 4
  },
  {
    name: 'Viherkalusteet',
    type: 'group',
    btnName: 'products.greenFurniture',
    id: 5
  },
  {
    name: 'indoor',
    type: 'usage',
    btnName: 'products.inDoor',
    icon: InDoor,
    id: 6,
  },
  {
    name: 'outdoor',
    type: 'usage',
    btnName: 'products.outDoor',
    icon: OutDoor,
    id: 7,
  }
]

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [searchParams] = useSearchParams()
  const scroll = searchParams.get("scroll");
  const filterRef = useRef(null)
  const dispatch = useDispatch()
  const product_groups = useAppSelector(get_product_product_group_combined)
  const global_loading = useSelector((state) => state.ui.global_loading)
  const [selectedGroupFilter, setSelectedGroupFilter] = useState([])
  const [selectedUsageFilter, setSelectedUsageFilter] = useState([])
  const { t, i18n} = useTranslation()

  const matches = useMatches()
  // Get the language from the route metadata
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      await dispatch(fetch_products({small_images: true}))
      dispatch(setGlobalLoading(false))
    })()
  }, [])

  const filterProducts = () => {
    if (product_groups) {
      const filtered_products = product_groups
      .filter(group => {
        // If no group is selected, show all groups
        if (selectedGroupFilter.length === 0) {
          return true // Show all groups
        }
        return selectedGroupFilter.some((filter) => filter.name === group.name) // Filter by selected group names
      })
      .map(group => ({
        ...group, // Keep the group structure
        products: group.products.filter(product => {
          // If no usage filter is selected, show all products
          if (selectedUsageFilter.length === 0) {
            return true // Show all products
          }
          // Check if the product's usage matches any of the selected usage filters
          return product.product_usage.some(usage => 
            selectedUsageFilter.some(filter => filter.name === usage.name)
          )
        })
      }))
      .filter(group => group.products.length > 0)

      if (filtered_products.length > 0) {
        return [filtered_products[filtered_products.length - 1], ...filtered_products.slice(0, filtered_products.length - 1)]
      }
      return []
    } else {
      return []
    }
  }

  const filteredProducts = filterProducts()

  if (scroll === "true") {
    // Use a timeout to ensure the DOM is fully rendered
    setTimeout(() => {
      if (filterRef.current) {
        filterRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 0);
  }
  
  const handleProductFilterClick = (group) => {
    if(group.type === 'group') {
      setSelectedGroupFilter((prevFilters) => {
        const isAlreadySelected = prevFilters.some((filter) => filter.name === group.name)
  
        if (isAlreadySelected) {
          return prevFilters.filter((filter) => filter.name !== group.name)
        } else {
          return [...prevFilters, group]
        }
      })
    } else if(group.type === 'usage') {
      setSelectedUsageFilter((prevFilters) => {
        const isAlreadySelected = prevFilters.some((filter) => filter.name === group.name)
  
        if (isAlreadySelected) {
          return prevFilters.filter((filter) => filter.name !== group.name)
        } else {
          return [...prevFilters, group]
        }
      })
    }
  }

  const clearProductFilter = () => {
    setSelectedGroupFilter([])
    setSelectedUsageFilter([])
  }

  const showClearBtn = () => {
    if(selectedGroupFilter && selectedGroupFilter.length > 0) {
      return true
    } else if(selectedUsageFilter && selectedUsageFilter.length > 0) {
      return true
    } else {
      return false
    }
  }

  const btnStyle = {
    // backgroundColor: 'white',
    padding: '0.15rem 1rem',
  }

  if (global_loading) {
    return (
      <>
        <div className='products-page-container'></div>
        <LoadingScreen />
      </>
    )
  }

  return (
    <div className='products-page-container'>
      <div className='products-page-body-container'>
        <HeaderBox
          titleText={t('common.products')}
          imgSrc={imgSrc1}
          type='productPage'
        />
        <div className='products-page-filter-groups' ref={filterRef}>
          <div className='products-page-filter-text'>{t('products.filter')}</div>
          <div className='products-page-filter-btn-container'>
            {filterBtns.map((group, index) => {
              if (group.type === 'group') {
                return (
                  <React.Fragment key={group.id}>
                  {index === 5 ? (
                    <div className='products-page-filter-separator'></div>
                  ) : null}
                  <div className='products-page-filter-container'>
                    <CustomButton
                      button_text={t(group.btnName)}
                      customStyles={btnStyle}
                      white={true}
                      onClickHandler={() => handleProductFilterClick(group)}
                      selected={selectedGroupFilter.some((filter) => filter.name === group.name)}
                    />
                  </div>
                </React.Fragment>
                )
              } else {
                return (
                  <React.Fragment key={group.id}>
                    {index === 5 ? (
                      <div className='products-page-filter-separator'></div>
                    ) : null}
                    <div className='products-page-filter-container no-select'>
                      <div
                        className={
                          'products-page-filter no-select' +
                          `${
                            selectedUsageFilter && Array.isArray(selectedUsageFilter) && selectedUsageFilter.some((filter) => filter.name === group.name)
                              ? ' selected'
                              : ''
                          }`
                        }
                        onClick={() => handleProductFilterClick(group)}
                      >
                        { group && group.icon ? 
                          (
                            <div className='products-page-filter-btn-with-icon-container'>
                              <group.icon
                                color={
                                  selectedUsageFilter && Array.isArray(selectedUsageFilter) && selectedUsageFilter.some((filter) => filter.name === group.name)
                                    ? 'white'
                                    : 'black'
                                }
                              />
                              {t(group.btnName)}
                            </div>
                          ) : (
                            t(group.btnName)
                          )
                        }
                      </div>

                    </div>
                  </React.Fragment>
                )
              }
            })}
            <div className='products-page-filter-container'>
              { showClearBtn() ? (
                <CustomButton
                  button_text={t('products.clear')}
                  customStyles={btnStyle}
                  transparent
                  icon={<AccordionClose/>}
                  stacked
                  contentClass='blogs-filter-btn'
                  onClickHandler={() => clearProductFilter()}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
            {filteredProducts ? (
                filteredProducts.map((group) => {
                    return <ProductGroup group={group} key={group.id} responsive={responsive} />
                  }
                )
              ) : (
                <></>
              )
            
            }
        {product_groups.length === 0 && (
          <div className='products-page-no-product-text'>{t('products.noProductsFound')}</div>
        )}
        <InfoBox
          title={t('products.infoBoxTitle')}
          imgSrc={imgSrc2}
          type='2'
          text={t('products.infoBoxText')}
          buttonText={t('products.infoBoxBtnText').toUpperCase()}
          link={i18n.language === 'FI' ? 'raataloidyt_ratkaisut' : 'tailored-solutions'}
          backgroundColor='purple'
        />

        <div className='products-page-service-group-container'>
          <div className='products-page-service-group-title'>
            {t('products.ourSolutions')}
          </div>
          <Carousel
            responsive={responsive}
            containerClass='products-page-service-group-carousel'
            itemClass='products-page-service-group-container-items'
          >
            {cardLinks && cardLinks.map(item => {
                return (
                  <React.Fragment key={item.id}>
                    <Link
                      className='products-page-service-group-custom-carousel-entry'
                      to={i18n.language === 'FI' ? item.link : item.link_en}
                    >
                      <div className='products-page-service-group-custom-carousel-entry-image-container'>
                        <img src={item.imgSrc} alt=''/>
                      </div>
                      <div className='products-page-service-group-custom-carousel-entry-title-container'>
                        <div className='products-page-service-group-custom-carousel-entry-title hover-underline-service-group'>
                          {t(item.name)}
                        </div>
                      </div>
                    </Link>
                  </React.Fragment>
                )
            })}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Products
