const privacy = {
  GDPRcookiesShortDescription:
    'Verkkosivustomme käyttää evästeitä. Tallennamme henkilökohtaisia tietoja, kun rekisteröidyt, ja tietoja voidaan käyttää muun muassa tuotteiden tilaamiseen ja uutiskirjeen lähettämiseen. Voit halutessasi kieltäytyä evästeiden käytöstä.',
  GDPRcookiesLongDescription:
    'Verkkosivustomme käyttää evästeitä. Voit halutessasi hylätä evästeiden käytön.\n\nEväste on pieni tekstitiedosto, joka lähetetään käyttäjälle ja tallennetaan käyttäjän laitteeseen. Evästeet eivät ole haitallisia laitteelle tai sen muille tiedostoille.\n\nVerkkosivustomme käyttää sekä pysyviä että istuntoevästeitä. Istuntoevästeet säilyvät verkkoselaimen muistissa niin kauan kuin verkkoselain on auki, mutta ne tuhoutuvat automaattisesti, kun selain suljetaan. Evästeet, joita käytetään palaavien rekisteröityneiden käyttäjien havaitsemiseen, pysyvät.\n\nTallennamme myös henkilökohtaisia tietoja, kuten sähköpostiosoitteen, nimen, puhelinnumeron ja yrityksen nimen, rekisteröityneiltä käyttäjiltä. Näitä tietoja voidaan käyttää tuotteiden tilaamiseen ja uutiskirjeen lähettämiseen.',
  readOurPrivacy: 'Tutustu tietoturvaselosteeseemme.',
  labelLearnMore: 'Lue lisää',
  labelShowLess: 'Näytä vähemmän',
  acceptCookiesBtn: 'Hyväksy',
  rejectBtn: 'Hylkää',
  cookiesTitle: 'Evästeet',
  privacyTitle: 'Tietosuoja- ja henkilötietorekisteriseloste',
  closeBtn: 'Sulje',
  privacyDescription: 'Privacy description here',
  link: 'Tutustu tietoturvaselosteeseemme',
  privacySubTitle: 'Rekisterinpitäjä ja yhteystiedot',
  labelController: 'Rekisterinpitäjä',
  labelContactPerson: 'Yhteyshenkilö',
  labelEmail: 'Sähköposti',
  registerNameAndPurpose: 'Rekisterin nimi ja käyttötarkoitus',
  registerName: 'Rekisterin nimi',
  purpose: 'Käyttötarkoitus',
  registerNameDescription: 'InnoGreenin asiakas- ja markkinointirekisteri.',
  purposeDescription:
    'Henkilötietoja kerätään ja käsitellään seuraaviin tarkoituksiin:',
  purposeItem1: 'Palveluiden tuottaminen ja asiakassuhteiden hallinta.',
  purposeItem2:
    'Markkinointi, kuten somemainonta, uutiskirjeiden lähettäminen ja tapahtumakutsut.',
  purposeItem3: 'Palveluiden kehittäminen.',
  purposeItem4:
    'Verkkosivuston käytön analysointi ja markkinoinnin kohdentaminen evästeiden avulla.',
  collectedData: 'Kerättävät tiedot',
  collectedDataDescription: 'Rekisteriin voidaan tallentaa seuraavia tietoja:',
  collectedDataItem1: 'Etu- ja sukunimi.',
  collectedDataItem2: 'Yhteystiedot (puhelinnumero, sähköpostiosoite, osoite).',
  collectedDataItem3: 'Työnantaja/yritys.',
  collectedDataItem4: 'Työtehtävä/asema yrityksessä.',
  collectedDataItem5:
    'Muut tiedot, joita käyttäjä itse antaa esimerkiksi yhteydenottolomakkeella.',
  collectedDataItem6:
    'Verkkosivuston käytöstä kerätyt tiedot: Selaushistoria, IP-osoite, evästeiden avulla kerätyt tiedot (esimerkiksi käyttäjän kiinnostuksen kohteet ja käyttäytyminen verkkosivuilla).',
  sourcesOfInformation: 'Tietojen lähteet:',
  sourcesOfInformationItem1:
    'Tiedot kerätään pääsääntöisesti henkilöltä itseltään (esimerkiksi yhteydenottolomakkeiden kautta).',
  sourcesOfInformationItem2:
    'Tietoja voidaan kerätä julkisista lähteistä (esimerkiksi yrityksen verkkosivuilta tai sosiaalisen median profiileista).',
  sourcesOfInformationItem3:
    'Verkkosivustolla vierailun yhteydessä selaushistoriaa ja käyttäytymistietoja tallennetaan evästeiden avulla, joista ilmoitetaan erillisellä evästeikkunalla.',
  useOfCookies: 'Evästeiden käyttö',
  useOfCookiesDescription1: 'Evästeitä käytetään seuraaviin tarkoituksiin:',
  useOfCookiesItem1: 'Parantaa verkkosivuston käyttökokemusta.',
  useOfCookiesItem2:
    'Analysoida verkkosivuston käyttöä ja parantaa sen toimivuutta.',
  useOfCookiesItem3:
    'Kohdentaa markkinointia ja mainontaa käyttäjän mielenkiinnon kohteiden mukaisesti.',
  useOfCookiesDescription2:
    'Käyttäjä voi hallita evästeasetuksia verkkosivuston evästeikkunan kautta. Evästeiden keräämistä koskevat tiedot yhdistetään henkilötietoihin vain, jos käyttäjä antaa siihen erillisen suostumuksen.',
  dataDisclosureInternationalTransfers:
    'Tietojen luovutus ja kansainväliset siirrot',
  dataDisclosureInternationalTransfersDescription:
    'Henkilötietoja voidaan luovuttaa kolmansille osapuolille, kun se on välttämätöntä palveluiden toteuttamiseksi tai markkinoinnin tueksi. Tällaisia tilanteita voivat olla esimerkiksi:',
  dataDisclosureInternationalTransfersItem1:
    'Uutiskirjeiden lähettäminen (esimerkiksi Mailchimp).',
  dataDisclosureInternationalTransfersItem2: 'Asiakaspalvelun toteuttaminen.',
  dataDisclosureInternationalTransfersItem3: 'Markkinoinnin kohdentaminen.',
  transfersOutside: 'Tietojen siirrot ETA-alueen ulkopuolelle:',
  transfersOutsideDescription:
    'Joissain tapauksissa henkilötietoja voidaan siirtää Euroopan talousalueen (ETA) ulkopuolelle, esimerkiksi Yhdysvaltoihin (esim. Mailchimp-palveluiden käyttö). Tällöin varmistamme, että tietojen siirrossa noudatetaan GDPR:n vaatimuksia, kuten:',
  transfersOutsideItem1:
    'Käytämme Euroopan komission hyväksymiä mallisopimuslausekkeita (SCC).',
  transfersOutsideItem2:
    'Varmistamme lisäsuojatoimenpiteet, kuten tietojen salauksen ja pääsynhallinnan.',
  transfersOutsideItem3:
    'Vain välttämättömät tiedot luovutetaan: Kolmansille osapuolille luovutetaan ainoastaan sellaisia tietoja, jotka ovat välttämättömiä palvelun tai markkinoinnin toteuttamiseksi.',
  dataRetentionSecurity: 'Tietojen säilytys ja tietoturva',
  dataRetentionSecurityDescription1:
    'Henkilötietoja säilytetään vain niin kauan kuin se on tarpeen niiden käyttötarkoituksen toteuttamiseksi tai lakisääteisten velvoitteiden täyttämiseksi.',
  dataRetentionSecurityDescription2:
    'InnoGreen käyttää teknisiä ja organisatorisia keinoja henkilötietojen suojaamiseen, kuten:',
  dataRetentionSecurityItem1: 'Tietojen salaaminen ja pääsynhallinta.',
  dataRetentionSecurityItem2:
    'Tietoturvamenettelyt estääkseen tietojen häviämisen, väärinkäytön ja muuttamisen.',
  rightsOfDataSubject: 'Rekisteröidyn oikeudet',
  rightsOfDataSubjectDescription: 'Rekisteröidyllä on seuraavat oikeudet:',
  rightsOfDataSubjectItem1: 'Voit pyytää kopion henkilötiedoistasi.',
  rightsOfDataSubjectItemLabel1: 'Oikeus saada pääsy tietoihin:',
  rightsOfDataSubjectItem2:
    'Voit korjata virheelliset tai puutteelliset tiedot.',
  rightsOfDataSubjectItemLabel2: 'Oikeus tietojen oikaisemiseen:',
  rightsOfDataSubjectItem3:
    'Voit pyytää tietojen poistamista, ellei laki tai oikeudellinen velvoite edellytä säilyttämistä.',
  rightsOfDataSubjectItemLabel3: 'Oikeus tietojen poistamiseen:',
  rightsOfDataSubjectItem4:
    'Voit vastustaa tietojen käyttöä suoramarkkinointiin.',
  rightsOfDataSubjectItemLabel4: 'Oikeus vastustaa tietojen käsittelyä:',
  rightsOfDataSubjectItemLabel5:
    'Oikeus rajoittaa tietojen käsittelyä ja oikeus siirtää tiedot.',
  rightsOfDataSubjectItem6:
    'Jos koet, että tietosuojasääntöjä on rikottu, voit tehdä valituksen tietosuojavaltuutetulle.',
  rightsOfDataSubjectItemLabel6: 'Oikeus tehdä valitus valvontaviranomaiselle:',
  changesPrivacy: 'Muutokset tietosuojaselosteeseen',
  changesPrivacyDescription1:
    'Tätä tietosuojaselostetta voidaan päivittää, esimerkiksi lainsäädännön tai liiketoiminnan muutosten vuoksi.',
  changesPrivacyDescription2:
    'Ajantasainen versio löytyy verkkosivuiltamme osoitteessa https://innogreen.fi.',
}

export default privacy
