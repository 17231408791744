const viherpysakki = {
  name: 'Green Stop',
  longDescription: `InnoGreen’s green stop brings greenery and comfort 
      to the heart of the city. Our green stop was born out of a desire 
      to develop an urban green space that enhances urban biodiversity, 
      and where every resident can enjoy the comfort, peace and shade of 
      lush greenery. In an urban environment, the green stop provides a 
      place to get in touch with nature, a place to rest and a sense of 
      community, all in one package.
      \\n
      The green stop can provide comfort and shelter in big open squares 
      or create much-needed activity and greenery in small courtyards. 
      It can also be placed indoors as an eye-catching element, for 
      example in a large lobby, where it can be used as a meeting point 
      or waiting area. The lush greenery provides shelter and food for 
      birds and pollinators. It also serves as a vital migration route 
      and as a resting place between green areas for pollinators.
      \\n
      Thanks to its modular design, each green stop can be customised 
      to its users. Would you like benches for lounging and enjoying 
      the summer, or your own fragrant herb garden? Natural plant 
      choices will attract butterflies and pollinators, and residents 
      can watch the hustle and bustle of bees and other insects. 
      The green stop lets the light through and looks fresh even when 
      there’s an abundance of plants.
      \\n
      The green stop is a quick, park-like solution that requires no 
      foundations or expensive modifications. What makes it special 
      is its modularity and the possibility of placing it almost 
      anywhere in the city on an already built surface, such as asphalt 
      or paving. As the green stop was originally designed for hard 
      surfaces, it is easy to move and manoeuvre.
      \\n
      The green stop along with plants and maintenance is available as a 
      service. Our all-inclusive rental service includes the 
      construction, maintenance and winter storage of the stop. Our 
      experts will help you choose the right size and location. All 
      parts of the green stop are made in Finland, from Finnish wood.`,
  measurements: [
    {
      type: 'Basic module:',
      values: [
        {
          value: 'Curved module with a bench that lets light through'
        },
        {
          value: 'Height 1950 mm'
        },
        {
          value: 'Width 1000 mm'
        },
        {
          value: 'Depth 950 mm'
        }
      ]
    },
    {
      type: 'Green wall module:',
      values: [
        {
          value: 'Curved green wall module that can also be used as a double-sided green wall'
        },
        {
          value: 'Height 1950 mm'
        },
        {
          value: 'Width 1900 mm'
        },
        {
          value: 'Depth 540 mm (without accessories)'
        }
      ]
    },
    {
      type: 'Additional parts of the green wall module:',
      bullet: true,
      values: [
        {
          value: 'Insect hotel integrated into the green wall'
        },
        {
          value: 'Birdhouse combined with the green wall'
        },
        {
          value: 'Games on the opposite side of the green wall'
        },
        {
          value: 'Chalkboard on the opposite side of the green wall'
        }
      ]
    },
  ],
  materials: [
    {
      type: 'Frame:',
      value: 'Finnish pine, spruce and coated plywood'
    },
    {
      type: 'Green wall modules:',
      value: 'Abs plastic, made in Kaarina'
    },
    {
      type: 'Plants:',
      value: 'Finland and EU'
    },
    {
      type: 'Other materials:',
      value: 'Felt, lake reed, metal'
    },
    {
      type: 'Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'Usage:',
      value: 'Indoor and outdoor',
      icon: 'both'
    },
  ],
  wlt: {},
  configuration: [
    {
      type: 'viherpysäkki',
      text: ['Basic module'],
    },
    {
      type: 'viherpysäkki',
      text: ['One sided Green wall module', 'Two-sided Green wall module'],
    },
    {
      type: 'viherpysäkki',
      text: ['Insect hotel', 'Birdhouse', 'Games'],
    }
  ],
  perusmoduulit: {
    name: 'Basic module',
    description: `Curved module with a bench that lets light through`
  },
  viherseinamoduulit: {
    name: 'Green wall module',
    description: `Curved green wall module that can also be used as a double-sided green wall`
  }, 
  viherseinamoduulin_lisaosat: {
    name: 'Green wall module additional parts',
    description: `Green wall module additional parts`
  },
}

export default viherpysakki