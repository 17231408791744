import './styles.scss'
import { Close } from '../Icons'
import { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { delete_project } from '../../store/asyncThunk'
import { useTranslation } from 'react-i18next'

const DeleteProjectModal = ({ showing, closeModal, project }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [errorList, setErrorList] = useState([])
  useEffect(() => {
    setErrorList([])
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const handleDeleteClick = async () => {
    await dispatch(delete_project({ project_id: project.id })).then((res) => {
      if (res.error) {
        setErrorList([t('project.genericDeleteProjectError')])
      } else {
        close()
        navigate(`/account`)
      }
    })
  }

  return (
    showing &&
    project && (
      <div className='delete-project-modal-root'>
        <div className='delete-project-modal-container'>
          <div className='delete-project-modal-header'>
            <div className='delete-project-modal-header-exit-btn-container'>
              <div
                className='delete-project-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20} />
              </div>
            </div>
          </div>

          <div className='delete-project-modal-content'>
            <div className='delete-project-modal-header-title'>
              {t('project.deleteConfirmation')}
            </div>
            {errorList.length > 0 && (
              <div className='delete-project-error-container'>
                {errorList.map((error) => {
                  return (
                    <div className='delete-project-error-entry' key={error}>
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='delete-project-modal-text'>
              {t('project.confirmDelete')} {project.name}!
            </div>
          </div>

          <div className='delete-project-modal-btn-group'>
            <CustomButton
              button_text={t('project.delete').toUpperCase()}
              danger
              onClickHandler={() => handleDeleteClick()}
            />
            <CustomButton
              button_text={t('project.cancel').toUpperCase()}
              onClickHandler={() => close()}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default DeleteProjectModal
