import './styles.scss'

import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Close, PlusIcon } from '../Icons'
import CustomButton from '../common/CustomButton'

import {
  create_project,
  create_space,
  fetch_projects,
  add_product_to_space,
} from '../../store/asyncThunk'

const newProjectInitialState = {
  name: '',
  description: '',
}

const newSpaceInitialState = {
  name: '',
}

const AddProductToProjectModal = ({
  product,
  showing,
  closeModal,
  presetProject,
  assignSpaceOnly,
  keepCurrentProject,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const projects = useSelector((state) => state.project.projects)

  useEffect(() => {
    dispatch(fetch_projects({selection:null, modal:true}))
  }, [])

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const [step, setStep] = useState(1)

  const [filteredProjects, setFilteredProjects] = useState(projects)
  const [showAddNewProject, setShowAddNewProject] = useState(false)
  const [newProject, setNewProject] = useState(newProjectInitialState)
  const [selectedProject, setSelectedProject] = useState(null)
  const [searchProjectKw, setSearchProjectKw] = useState('')

  useEffect(() => {
    if (presetProject) {
      setSelectedProject(presetProject)
    }
  }, [presetProject])

  useEffect(() => {
    if (assignSpaceOnly) {
      setStep(2)
    } else {
      setStep(1)
    }
  }, [assignSpaceOnly])

  const getProjectSpaces = () => {
    const space_list = []
    //Check and add customed space from the selected project
    if (selectedProject) {
      selectedProject.spaces
        .filter((s) => !s.is_default)
        .forEach((s) => {
          const index = space_list.findIndex((space) => space.id === s.id)
          if (index === -1) {
            space_list.push({
              id: s.id,
              name: s.name,
              is_default: s.is_default ?? false,
            })
          }
        })
    }

    setFilteredSpaces(space_list)
  }

  useEffect(() => {
    if (selectedProject) {
      getProjectSpaces()
    }
  }, [selectedProject])

  const [filteredSpaces, setFilteredSpaces] = useState([])
  const [showAddNewSpace, setShowAddNewSpace] = useState(false)
  const [newSpace, setNewSpace] = useState(newSpaceInitialState)
  const [selectedSpace, setSelectedSpace] = useState(null)
  const { t, i18n } = useTranslation()
  const [addSpacerrorList, setAddSpaceErrorList] = useState([])
  const [addProjectrrorList, setAddProjectErrorList] = useState([])
  
  const default_space = useSelector((state) => {
    if (selectedProject) {
      return selectedProject.spaces.find((s) => s.is_default)
    } else {
      return null
    }
  })

  //RESET STEP WHENENVER STEP SWITCHED
  useEffect(() => {
    setSearchProjectKw('')
    setShowAddNewProject(false)
    setShowAddNewSpace(false)
  }, [step])

  useEffect(() => {
    if (searchProjectKw.trim().length === 0) {
      setFilteredProjects([...projects])
    } else {
      const updated_list = projects.filter((p) =>
        p.name
          .trim()
          .toLowerCase()
          .includes(searchProjectKw.trim().toLowerCase())
      )
      setFilteredProjects([...updated_list])
    }
  }, [projects, searchProjectKw])

  const toggleAddNewProject = () => {
    setAddProjectErrorList([])
    if (!showAddNewProject) {
      if (!newProject.description && !newProject.name) {
        setNewProject(newProjectInitialState)
      }
    }
    setShowAddNewProject(!showAddNewProject)
  }

  const toggleAddNewSpace = () => {
    setAddSpaceErrorList([])
    if (!showAddNewSpace) {
      if (!newSpace.description && !newSpace.name) {
        setNewSpace(newSpaceInitialState)
      }
    }
    setShowAddNewSpace(!showAddNewSpace)
  }

  const updateNewProject = ({ name, description }) => {
    if (name !== undefined) {
      setNewProject({ ...newProject, name: name })
    }
    if (description !== undefined) {
      setNewProject({ ...newProject, description: description })
    }
  }

  const updateNewSpace = ({ name }) => {
    if (name !== undefined) {
      setNewSpace({ ...newSpace, name: name })
    }
  }

  const getNewProjectProperty = (key) => {
    if (key && newProject) {
      return newProject[key]
    }
  }

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const updateProjectKw = (val) => {
    if (val) {
      setSearchProjectKw(val)
    } else {
      setSearchProjectKw('')
    }
  }

  const handleCreateNewProjectBtnClick = async () => {
    //Every new project has a DEFAULT SPACE
    const new_project = {
      ...newProject,
      // spaces: [
      //   {
      //     id: 'default',
      //     name: 'DEFAULT SPACE',
      //     is_default: true,
      //     assigned_products: [],
      //   },
      // ],
      // id: filteredProjects.length + 1,
      // products: [],
      // status: 1,
      // date_created: moment().format('DD.MM.YYYY'),
      // date_modified: moment().format('DD.MM.YYYY'),
      // description:
      //   'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
    }
    try {
      const res = await dispatch(create_project(new_project)).unwrap().then((res) => {
        if (!res.error) {
          setNewProject(newProjectInitialState)
          toggleAddNewProject()
          selectProject(res)
          getProjectSpaces()
        } else {
          setAddProjectErrorList([t('project.genericAddProjectError')])
        }
      })
      
    } catch (err) {
      setAddProjectErrorList([t('project.genericAddProjectError')])
    }
  }

  const handleCreateNewSpaceBtnClick = async () => {
    const new_space = {
      ...newSpace,
      assigned_products: [
        {
          ...product,
        },
      ],
    }

    try {
      const res = await dispatch(
        create_space({
          space: new_space,
          project_id: selectedProject.id,
        })
      ).unwrap().then((res) => {
        if (!res.error) {
          setNewSpace(newSpaceInitialState)
          toggleAddNewSpace()
    
          if (!assignSpaceOnly) {
            setSelectedProject({
              ...selectedProject,
              spaces: [...selectedProject.spaces, res],
            })
            setSelectedSpace(res)
            getProjectSpaces()
            setStep(3)
          } else {
            close()
          }
        } else {
          setAddSpaceErrorList([t('project.genericAddSpaceError')])
        }
      })

      
    } catch (err) {
      setAddSpaceErrorList([t('project.genericAddSpaceError')])
    }
  }

  const selectProject = (project) => {
    if (project) {
      setSelectedProject(project)
      setStep(2)
    } else {
      setSelectedProject(null)
      setStep(1)
    }
  }

  const addToSpace = async (space) => {
    if (space) {
      try {
        const res = await dispatch(
          add_product_to_space({
            product: {
              product_id: product.product_id,
              quantity: 1,
              space_id: space.id,
              customizations: product.customizations,
            },
            project_id: selectedProject.id,
          })
        )
        setSelectedSpace(space)
        if (assignSpaceOnly) {
          close()
        } else {
          setStep(3)
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  const goToStep = (step) => {
    setStep(step)
  }

  const goToProjectPage = () => {
    close()
    navigate(`/project/${selectedProject.id}`)
    window.location.reload()
  }

  const handleContinue = () => {
    if (keepCurrentProject) {
      close()
    } else {
      close()
      if (i18n.language === 'FI') {
        navigate(`/tuotteet?scroll=true`)
      } else if (i18n.language === 'EN') {
        navigate(`/products?scroll=true`)
      }
    }
  }

  const stepOneView = () => {
    return (
      <div className='add-to-project-modal-step-container'>
        <div className='add-to-project-modal-step-title'>{t('project.addToProject')}</div>
        <div
          onClick={() => toggleAddNewProject()}
          className='add-to-project-modal-add-new-resource-btn'
        >
          <PlusIcon size={16}/> {t('project.addToNewProject')}
        </div>

        <div
          className={`add-to-project-modal-new-resource-container ${
            showAddNewProject ? ' show' : ''
          }`}
        >
          <div className='add-to-project-modal-new-resource-content'>
            {addProjectrrorList.length > 0 && (
              <div className='add-to-project-modal-error-container'>
                {addProjectrrorList.map((error) => {
                  return (
                    <div className='add-to-project-modal-error-entry' key={error}>
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='add-to-project-modal-new-resource-input-group'>
              <label className='add-to-project-modal-new-resource-input-label'>
                {t('project.projectName')}*
              </label>
              <input
                type='text'
                value={newProject.name}
                className='add-to-project-modal-new-resource-input-field'
                onInput={(e) => updateNewProject({ name: e.target.value })}
              ></input>
            </div>
            <div className='add-to-project-modal-new-resource-input-group'>
              <label className='add-to-project-modal-new-resource-input-label'>
                {t('account.projectDescription')}
              </label>

              <textarea
                type='text'
                value={newProject.description}
                className='add-to-project-modal-new-resource-input-field textarea'
                onInput={(e) =>
                  updateNewProject({ description: e.target.value })
                }
              ></textarea>
            </div>
          </div>

          <div className='add-to-project-modal-new-resource-btn-group'>
            <CustomButton
              button_text={t('project.addToProject').toUpperCase()}
              disabled={newProject.name.trim().length === 0}
              onClickHandler={() => handleCreateNewProjectBtnClick()}
            />
            <CustomButton
              button_text={t('project.cancel').toUpperCase()}
              onClickHandler={() => toggleAddNewProject()}
            />
          </div>
        </div>

        <div className='add-to-project-modal-project-list'>
          <div className='add-to-project-modal-project-list-search-container'>
            <input
              type='search'
              className='add-to-project-modal-project-list-search-input'
              placeholder={t('project.searchForProject')}
              value={searchProjectKw}
              onInput={(e) => updateProjectKw(e.target.value)}
            />
          </div>

          <div className='add-to-project-modal-project-list-project-entries'>
            {filteredProjects &&
              filteredProjects.map((project, index) => (
                <div
                  className='add-to-project-modal-project-list-project-entry'
                  onClick={() => {
                    selectProject(project)
                  }}
                  key={index}
                >
                  {project.name}
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  }

  const stepTwoView = () => {
    return (
      <div className='add-to-project-modal-step-container'>
        {assignSpaceOnly ? null : (
          <div className='add-to-project-modal-selected-project-name'>
            {selectedProject.name}
          </div>
        )}
        <div className='add-to-project-modal-step-title'>{t('project.assignToSpace')}</div>
        <div className='add-to-project-modal-step-description'>
         {t('project.chooseSpace')}
        </div>
        <div
          className={`add-to-project-modal-new-resource-container ${
            showAddNewSpace ? ' space-show' : ''
          }`}
        >
          <div className='add-to-project-modal-new-resource-content'>
            {addSpacerrorList.length > 0 && (
              <div className='add-to-project-modal-error-container'>
                {addSpacerrorList.map((error) => {
                  return (
                    <div className='add-to-project-modal-error-entry' key={error}>
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='add-to-project-modal-new-resource-input-group'>
              <label className='add-to-project-modal-new-resource-input-label'>
                {t('project.nameOfSpace')}*
              </label>
              <input
                type='text'
                value={newSpace.name}
                className='add-to-project-modal-new-resource-input-field'
                onInput={(e) => updateNewSpace({ name: e.target.value })}
              ></input>
            </div>
          </div>

          <div className='add-to-project-modal-new-resource-btn-group'>
            <CustomButton
              button_text={t('project.assignToSpace').toUpperCase()}
              disabled={newSpace.name.trim().length === 0}
              onClickHandler={() => handleCreateNewSpaceBtnClick()}
            />
            <CustomButton
              button_text={t('project.cancel').toUpperCase()}
              onClickHandler={() => toggleAddNewSpace()}
            />
          </div>
        </div>

        <div className='add-to-project-modal-space-list'>
          <div className='add-to-project-modal-space-list-entries'>
            {default_space && (
              <div
                className='add-to-project-modal-space-list-entry default'
                onClick={() => {
                  addToSpace(default_space)
                }}
              >
                {t('project.defaultSpace').toUpperCase()}
              </div>
            )}
            {filteredSpaces &&
              filteredSpaces.map((space, index) => (
                <div
                  className='add-to-project-modal-space-list-entry'
                  onClick={() => {
                    addToSpace(space)
                  }}
                  key={index}
                >
                  {space.name.toUpperCase()}
                </div>
              ))}
          </div>
        </div>
        <div
          onClick={() => toggleAddNewSpace()}
          className='add-to-project-modal-add-new-resource-btn'
        >
          <PlusIcon size={16}/> {t('project.createNewSpace')}
        </div>
      </div>
    )
  }

  const stepThreeView = () => {
    return (
      <div className='add-to-project-modal-step-container'>
        <div className='add-to-project-modal-step-title normal'>
          {t('project.productAddedTo')}
        </div>
        <div className='add-to-project-modal-step-title normal'>
          {selectedProject.name}
        </div>
        <div className='add-to-project-modal-step-sub-title'>
          {t('project.selectedSpace')}:
          <span className='add-to-project-modal-step-selected-space'>
            {selectedSpace.name === 'DEFAULT SPACE' ? (t('project.defaultSpace')) : (selectedSpace.name)}
          </span>
        </div>
        <div className='add-to-project-modal-step-description normal'>
          {keepCurrentProject
            ? t('project.continueText1')
            : t('project.continueText2')}
        </div>
        <div className='add-to-project-modal-step-description normal'>
          {t('project.goToProjectText')}
        </div>
        <div className='add-to-project-modal-btn-group'>
          <CustomButton
            button_text={t('project.continue').toUpperCase()}
            onClickHandler={() => handleContinue()}
          />
          <CustomButton
            button_text={t('project.goToProject').toUpperCase()}
            onClickHandler={() => goToProjectPage()}
          />
          <div
            className='add-to-project-modal-text-btn'
            onClick={() => goToStep(1)}
          >
            <PlusIcon size={16}/> {t('project.addToOtherProject')}
          </div>
        </div>
      </div>
    )
  }

  return (
    showing && (
      <div className='add-product-to-project-modal-root'>
        <div className='add-product-to-project-modal-container'>
          <div className='add-product-to-project-modal-header'>
            <div className='add-product-to-project-modal-header-title'>
              {assignSpaceOnly ? '' : `${t('project.step')} ` + step + '/ 3'}
            </div>
            {!assignSpaceOnly && step === 2 && (
              <div
                className='add-product-to-project-modal-header-back-btn'
                onClick={() => goToStep(step - 1)}
              >
                {'< '} {t('project.backtoStep') + ' '} {step - 1}
              </div>
            )}
            <div className='add-product-to-project-modal-header-exit-btn-container'>
              <div
                className='add-product-to-project-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={24}/>
              </div>
            </div>
          </div>
          <div className='add-product-to-project-modal-content-scrollable'>
            {step === 1 && !assignSpaceOnly && stepOneView()}
            {step === 2 && stepTwoView()}
            {step === 3 && stepThreeView()}
          </div>
        </div>
      </div>
    )
  )
}

export default AddProductToProjectModal
