const reference = {
  headerTitle: 'References',
  headerTitleSingular: 'REFERENCE',
  filter: 'FILTER',
  clear: 'CLEAR',
  toimisto: 'Office space',
  julkinenTila: 'Public Space',
  ulkoalue: 'Outdoor',
  koivuTuotteet: 'Koivu Products',
  viherseinat: 'Green Walls',
  viherkalusteet: 'Urban Green Furniture',
  vihersisustus: 'Green Interior',
  huoltovapaat: 'Maintenance Free',
  page: 'Page',
  next: 'Next',
  previous: 'Previous',
  noReferenceFound: 'No References found',
  noReferenceFoundWithFilter: 'No References found with selected filters',
  filterBtns: [
    {
      tag: 'TOIMISTO',
      text: 'OFFICE',
      type: 'referenceGroup',
    },
    {
      tag: 'JULKINEN TILA',
      text: 'PUBLIC SPACES',
      type: 'referenceGroup',
    },
    {
      tag: 'ULKOALUE',
      text: 'OUTDOOR AREAS',
      type: 'referenceGroup',
    },
    {
      tag: 'KOIVU-TUOTTEET',
      text: 'KOIVU PRODUCTS',
      type: 'productGroup',
    },
    {
      tag: 'VIHERSEINÄT',
      text: 'GREEN WALLS',
      type: 'productGroup',
    },
    {
      tag: 'VIHERSISUSTUS',
      text: 'GREEN INTERIOR DESIGN',
      type: 'productGroup',
    },
    {
      tag: 'VIHERKALUSTEET',
      text: 'GREEN FURNITURE',
      type: 'productGroup',
    },
  ],
}

export default reference