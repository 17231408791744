const raataloitava_viherseina = {
  name: 'Räätälöitävä Viherseinä',
  longDescription: `Räätälöitävä viherseinämme on markkinoiden monipuolisin 
      kasviseinä koon, muodon ja kasvivalikoimansa puolesta. Runsaan vehreä 
      viherseinämme on korkealuokkainen, kotimainen ja kestävä valinta, joka 
      on sijoitettavissa sekä sisä- että ulkotiloihin.
      \\n
      Viherseinän rakenne ei ole kiinteä, vaan se koostuu seinään 
      kiinnitettävistä moduuleista, minkä vuoksi viherseinä voidaan suunnitella 
      mihin tahansa muotoon. Moduuleja voidaan pinota, asettaa vierekkäin tai 
      mukailla kehikon päälle erilaisiin muotoihin, kuten vaikka torniksi. 
      Kuitenkin tyyppillisin toteuttamamme viherseinä rakentuu suorakaiteen 
      muotoon tautaseinää mukaillen.
      \\n
      Käytämme viherseinissämme vain aitoja kasveja ja yhdessä seinässä on 
      tyypillisesti useita eri kasvilajeja. Useammasta lajista koostuva seinä on 
      näyttävä, ja onnistuneilla kasvivalinnoilla voidaan luoda tilaan juuri 
      toivotunlaista tunnelmaa. Seinä voi olla ilmeeltään leikkisä, dramaattinen 
      tai vaikkapa rauhoittava.
      \\n
      Sisällä viherseinään on mahdollista yhdistää myös sammalta ja stabiloituja 
      kasveja. Ulkoviherseinään voidaan toiveiden ja kauden mukaan valita 
      värikkäitä kukkia, tuoksuvia yrttejä tai havuja tunnelmallisten valojen 
      kera. Seiniin voidaan integroida myös muita elementtejä, kuten logo.
      \\n
      Pienet viherseinät voidaan kastella käsin. Suurissa viherseinissä 
      käytetään patentoitua kastelujärjestelmäämme. `,
  measurements: [
    {
      type: 'Alaosan korkeus:',
      value: '400 mm'
    },
    {
      type: 'Yhden moduulin koko:',
      values: [
        {
          value: 'Leveys 600 mm'
        },
        {
          value: 'Korkeus 165 mm'
        },
        {
          value: 'Syvyys 200 mm'
        }
      ]
    },
    {
      type: 'Kasvien määrä:',
      value: 'Yhdessä kasviseinäneliössä on n. 40 kasvia'
    }
  ],
  materials: [
    {
      type: 'Reunakatteet:',
      value: 'Puuta, terästä tai muuta vastaavaa materiaalia sopimuksen mukaan'
    },
    {
      type: 'Viherseinän moduulit:',
      value: 'Abs-muovi, valmistettu Kaarinassa'
    },
    {
      type: 'Kasvit:',
      value: 'Suomi ja EU'
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi'
    },
    {
      type: 'Kasvivalot:',
      value: `Käytämme uusimman teknologian LED-valaisimia. 
          Polttimon spektri vastaa päivänvalon spektriä, 
          jolloin kasvit saavat tarvitsemaansa oikeanlaista valoa 
          samalla kun valo korostaa kasvien näyttävyyttä.`
    },
    {
      type: 'Käyttö:',
      value: 'Sisä- ja ulkotilat',
      icon: 'both'
    },
  ],
  wlt: {
    title: 'Tämä viherseinä suojelee 1000 neliötä sademetsää',
    text: `Valitsemalla InnoGreenin viherseinän suojelet kanssamme maailman 
      sademetsiä yhteistyössä World Land Trustin kanssa. Jokaista myytyä tai 
      vuokrattua kasviseinäneliömetriä kohden suojelemme 1000 neliömetriä sademetsää.`
  },
  configuration: [
    {
      type: 'räätälöitävä_viherseinä',
      configType:'width',
      text: 'Laarien määrä leveyssuunnassa',
      valueText: 'Leveys',
      value: 600,
    },
    {
      type: 'räätälöitävä_viherseinä',
      configType:'height',
      showExtra: true,
      text: 'Laarien määrä korkeussuunnassa',
      valueText: 'Korkeus',
      value: 165,
    }
  ],
  leveys: {
    description: `Leveys määrittyy vierekkäin valittavien kasvilaarien määrästä, laarin leveys 600 mm.`
  },
  korkeus: {
    description: `Korkeus määrittyy päällekkäin valittavien kasvilaarien määrästä, laarin korkeus 165 mm. (Alaosan/perusosan korkeus 400 mm)`
  },
}

export default raataloitava_viherseina