import './styles.scss'
import { Close } from '../Icons'
import { useState, useEffect } from 'react'
import CustomButton from '../common/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { sign_up } from '../../store/asyncThunk'
import { useTranslation } from 'react-i18next'
import ClipLoader from 'react-spinners/ClipLoader'
import CustomPrivacyModal from '../common/CustomPrivacyModal'

const SignUpModal = ({ showing, closeModal }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [signUpPayload, setSignUpPayload] = useState({
    email: '',
    password: '',
    confirm_password: '',
    name: '',
    company: '',
    role: '',
    phone_number: '',
    email_subscribe: false,
  })

  const [errorList, setErrorList] = useState([])
  const [checkError, setCheckError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [requested_success, setRequestedSuccess] = useState(false)
  const [showCustomPrivacyModal, setShowCustomPrivacyModal] = useState(false)
  const language = useSelector((state) => state.user.language)

  const toggleCustomPrivacyModal = () => {
    setShowCustomPrivacyModal(!showCustomPrivacyModal)
  }

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  useEffect(() => {
    if (checkError) {
      const updated_error_list = []
      if (!signUpPayload.email || signUpPayload.email.trim().length === 0) {
        updated_error_list.push(t('login.errorEmailRequired'))
      }
      if (
        signUpPayload.email &&
        signUpPayload.email.trim().length > 0 &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpPayload.email)
      ) {
        updated_error_list.push(t('login.errorEmailInvalid'))
      }
      if (
        !signUpPayload.password ||
        signUpPayload.password.trim().length === 0
      ) {
        updated_error_list.push(t('login.errorPasswordRequired'))
      }
      if (signUpPayload.password && signUpPayload.password.trim().length < 6) {
        updated_error_list.push(t('login.errorPasswordLength'))
      }
      if (
        !signUpPayload.confirm_password ||
        signUpPayload.confirm_password.trim().length === 0
      ) {
        updated_error_list.push(t('login.errorConfirmPasswordRequired'))
      }
      if (
        signUpPayload.password &&
        signUpPayload.confirm_password &&
        signUpPayload.password.trim().length > 0 &&
        signUpPayload.confirm_password.trim().length > 0 &&
        signUpPayload.password !== signUpPayload.confirm_password
      ) {
        updated_error_list.push(t('login.errorPasswordNotMatch'))
      }
      if (!signUpPayload.name || signUpPayload.name.trim().length === 0) {
        updated_error_list.push(t('login.errorNameRequired'))
      }
      if (!signUpPayload.company || signUpPayload.company.trim().length === 0) {
        updated_error_list.push(t('login.errorCompanyRequired'))
      }

      setErrorList(updated_error_list)
    } else {
      setErrorList([])
    }
  }, [checkError, signUpPayload])

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const updateSignUpPayload = ({
    email,
    password,
    confirm_password,
    name,
    company,
    role,
    phone_number,
    email_subscribe,
  }) => {
    if (email !== undefined) {
      setSignUpPayload({ ...signUpPayload, email: email })
    }
    if (password !== undefined) {
      setSignUpPayload({ ...signUpPayload, password: password })
    }
    if (confirm_password !== undefined) {
      setSignUpPayload({ ...signUpPayload, confirm_password: confirm_password })
    }
    if (name !== undefined) {
      setSignUpPayload({ ...signUpPayload, name: name })
    }
    if (company !== undefined) {
      setSignUpPayload({ ...signUpPayload, company: company })
    }
    if (role !== undefined) {
      setSignUpPayload({ ...signUpPayload, role: role })
    }
    if (phone_number !== undefined) {
      setSignUpPayload({ ...signUpPayload, phone_number: phone_number })
    }
    if (email_subscribe !== undefined) {
      setSignUpPayload({ ...signUpPayload, email_subscribe: email_subscribe })
    }
  }

  const checkForErrors = () => {
    const updated_error_list = []
    if (!signUpPayload.email || signUpPayload.email.trim().length === 0) {
      updated_error_list.push(t('login.errorEmailRequired'))
    }
    if (
      signUpPayload.email &&
      signUpPayload.email.trim().length > 0 &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpPayload.email)
    ) {
      updated_error_list.push(t('login.errorEmailInvalid'))
    }
    if (!signUpPayload.password || signUpPayload.password.trim().length === 0) {
      updated_error_list.push(t('login.errorPasswordRequired'))
    }
    if (signUpPayload.password && signUpPayload.password.trim().length < 6) {
      updated_error_list.push(t('login.errorPasswordLength'))
    }
    if (
      !signUpPayload.confirm_password ||
      signUpPayload.confirm_password.trim().length === 0
    ) {
      updated_error_list.push(t('login.errorConfirmPasswordRequired'))
    }
    if (
      signUpPayload.password &&
      signUpPayload.confirm_password &&
      signUpPayload.password.trim().length > 0 &&
      signUpPayload.confirm_password.trim().length > 0 &&
      signUpPayload.password !== signUpPayload.confirm_password
    ) {
      updated_error_list.push(t('login.errorPasswordNotMatch'))
    }
    if (!signUpPayload.name || signUpPayload.name.trim().length === 0) {
      updated_error_list.push(t('login.errorNameRequired'))
    }
    if (!signUpPayload.company || signUpPayload.company.trim().length === 0) {
      updated_error_list.push(t('login.errorCompanyRequired'))
    }
    setErrorList(updated_error_list)
    setCheckError(true)
    if (updated_error_list.length > 0) {
      return true
    }
    return false
  }

  const handleSignUp = async () => {
    const has_errors = checkForErrors()
    if (!has_errors) {
      setIsLoading(true)
      dispatch(sign_up({...signUpPayload, language: language}))
        .then((res) => {
          setIsLoading(false)
          if (res.error) {
            if (res.payload && res.payload.data && res.payload.data.error) {
              if (res.payload.data.error.description) {
                setErrorList([res.payload.data.error.description])
              }
              setErrorList([t('login.genericSignUpError')])
            } else {
              setErrorList([t('login.genericSignUpError')])
            }
          } else {
            setRequestedSuccess(true)
          }
        })
        .catch((res) => {
          setErrorList([t('login.genericSignUpError')])
        })
    }
  }

  return (
    showing && (
      <div className='sign-up-modal-root'>
        <div className='sign-up-modal-container'>
          {isLoading ? (
            <div className='sign-up-modal-loader'>
              <ClipLoader color='#1a73e8' />
            </div>
          ) : (
            <></>
          )}
          <div className='sign-up-modal-header'>
            <div className='sign-up-modal-header-exit-btn-container'>
              <div
                className='sign-up-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20} />
              </div>
            </div>
          </div>
          { !requested_success && (
          <div className='sign-up-modal-header-title'>
            {t('login.createUserAccount')}
          </div>
          )}
          {!requested_success && (
            <p className='sign-up-modal-terms'>{t('login.signUpTerms')}
              <span 
                className='sign-up-modal-terms-link'
                onClick={toggleCustomPrivacyModal}
              >
                {t('login.signUpTermsLink')}
              </span>
            </p>
          )}
          {errorList.length > 0 && (
            <div className='sign-up-modal-error-container'>
              {errorList.map((error) => {
                return (
                  <div className='sign-up-modal-error-entry' key={error}>
                    - {error}
                  </div>
                )
              })}
            </div>
          )}
          {requested_success && (
            <div className='sign-up-modal-content'>
              {t('login.signUpSuccessMessage')}
            </div>
          )}
          {!requested_success && (
            <div className='sign-up-modal-content'>
              <div className='sign-up-modal-input-container'>
                <label htmlFor='email'>{t('login.labelEmail')}*</label>
                <input
                  type='text'
                  id='email'
                  value={signUpPayload.email}
                  onInput={(e) =>
                    updateSignUpPayload({ email: e.target.value })
                  }
                />
              </div>
              <div className='sign-up-modal-input-container'>
                <label htmlFor='password'>{t('login.labelPassword')}*</label>
                <input
                  type='password'
                  id='password'
                  value={signUpPayload.password}
                  onInput={(e) =>
                    updateSignUpPayload({ password: e.target.value })
                  }
                />
              </div>
              <div className='sign-up-modal-input-container'>
                <label htmlFor='confirm_password'>
                  {t('login.labelConfirmPassword')}*
                </label>
                <input
                  type='password'
                  id='confirm_password'
                  value={signUpPayload.confirm_password}
                  onInput={(e) =>
                    updateSignUpPayload({ confirm_password: e.target.value })
                  }
                />
              </div>
              <div className='sign-up-modal-input-container'>
                <label htmlFor='name'>{t('login.labelName')}*</label>
                <input
                  id='name'
                  value={signUpPayload.name}
                  onInput={(e) => updateSignUpPayload({ name: e.target.value })}
                />
              </div>
              <div className='sign-up-modal-input-container'>
                <label htmlFor='company'>{t('login.labelCompany')}*</label>
                <input
                  id='company'
                  value={signUpPayload.company}
                  onInput={(e) =>
                    updateSignUpPayload({ company: e.target.value })
                  }
                />
              </div>
              {/* <div className='sign-up-modal-input-container'>
              <label htmlFor='role'>{t('login.labelRole')}</label>
              <input
                id='role'
                value={signUpPayload.role}
                onInput={(e) => updateSignUpPayload({ role: e.target.value })}
              />
            </div> */}
              <div className='sign-up-modal-input-container'>
                <label htmlFor='phone_number'>
                  {t('login.labelPhoneNumber')}
                </label>
                <input
                  id='phone_number'
                  value={signUpPayload.phone_number}
                  onInput={(e) =>
                    updateSignUpPayload({ phone_number: e.target.value })
                  }
                />
              </div>
              <div className='sign-up-modal-checkbox-container'>
                <input
                  type='checkBox'
                  id='email_subscribe'
                  value={signUpPayload.email_subscribe}
                  onChange={(e) =>
                    updateSignUpPayload({ email_subscribe: e.target.checked })
                  }
                />
                <label htmlFor='email_subscribe'>
                  {t('login.labelSubscribeEmail')}
                </label>
              </div>
            </div>
          )}

          <div className='sign-up-modal-btn-group'>
            {requested_success ? (
              <CustomButton
                button_text={t('login.closeBtn')}
                black
                onClickHandler={() => close()}
              />
            ) : (
              <CustomButton
                button_text={t('login.requestNewAccountBtn')}
                yellow
                onClickHandler={() => handleSignUp()}
              />
            )}
          </div>
        </div>
      {/* Privacy */}
      {showCustomPrivacyModal && (
        <CustomPrivacyModal
          showing={showCustomPrivacyModal}
          closeModal={toggleCustomPrivacyModal}
        />
      )}
      </div>
    )
  )
}

export default SignUpModal
