import { createSelector, createSlice } from '@reduxjs/toolkit'
import {
  fetch_references,
  fetch_reference,
  fetch_reference_groups,
  fetch_management_references,
  fetch_management_reference,
  update_reference_state,
} from './asyncThunk'

export const referenceSlice = createSlice({
  name: 'reference',
  initialState: {
    references: [],
    reference: null,
    referenceGroup: {
      productGroup: [],
      referenceGroup: [],
    },
    recordsTotal: 0,
    recordsFiltered: 0,
    currentPage: 1,
    pages: 1,
    selectedFilters: [],
    referenceGroupFetched: false,
    homeReferences: []
  },
  reducers: {
    setFilters(state, action) {
      state.selectedFilters = action.payload
    },
    setPage(state, action) {
      state.currentPage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch_references.fulfilled, (state, action) => {
      const { data, recordsTotal, recordsFiltered, forPage } = action.payload
      if (forPage) {
        if (forPage === 'home') {
          state.homeReferences = data
        }
      } else {
        state.references = data
        state.recordsTotal = recordsTotal
        state.recordsFiltered = recordsFiltered
        state.pages  = Math.ceil(recordsFiltered / 9)
      }
    })
    builder.addCase(fetch_management_references.fulfilled, (state, action) => {
      const { data, recordsTotal, recordsFiltered, forPage } = action.payload
      if (forPage) {
        if (forPage === 'home') {
          state.homeReferences = data
        }
      } else {
        state.references = data
        state.recordsTotal = recordsTotal
        state.recordsFiltered = recordsFiltered
        state.pages  = Math.ceil(recordsFiltered / 9)
      }
    })
    builder.addCase(fetch_reference.fulfilled, (state, action) => {
      state.reference = action.payload.reference
    })
    builder.addCase(update_reference_state.fulfilled, (state, action) => {
      const { payload } = action
      const index = state.references.findIndex(reference => reference.id === payload.id)
      if (index !== -1) {
        state.references[index] = payload
      } else {
        state.references.push(payload)
      }
    })
    builder.addCase(fetch_management_reference.fulfilled, (state, action) => {
      state.reference = action.payload.reference
    })
    builder.addCase(fetch_reference_groups.fulfilled, (state, action) => {
      state.referenceGroup = action.payload
      state.referenceGroupFetched = true
    })
  },
})

export const selectReferences = (state) => state.reference.references;
export const selectReferenceById = (id) => createSelector(
  [selectReferences],
  (references) => {
    return references.find(reference => reference.id === id)
  }
);

// Create selector for random References for Home page
export const createDemoReferencesSelector = (numReferences) =>
  createSelector(
    [(state) => state.reference.references],
    (references) => {
      let shuffledReferences = references.slice();

      // Shuffle References array
      for (let i = shuffledReferences.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [shuffledReferences[i], shuffledReferences[randomIndex]] = [
          shuffledReferences[randomIndex],
          shuffledReferences[i]
        ];
      }

      const referenceGroup = shuffledReferences.slice(0, numReferences).map(item => (item));
      
      return referenceGroup;
    }
  );

export const { setFilters, setPage } = referenceSlice.actions

export default referenceSlice.reducer
