import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate, useMatches } from 'react-router-dom'
import { add_inquiry } from '../../store/asyncThunk'
import { useDispatch } from 'react-redux'

import HeaderBox from '../../components/HeaderBox'
import InfoCardGroup from '../../components/InfoCardGroup'
import InfoBox from '../../components/InfoBox'
import TextCardGroup from '../../components/TextCardGroup'
import { Heart, Future } from '../../components/CardIcon'
import CustomButton from '../../components/common/CustomButton'
import CustomPrivacyModal from '../../components/common/CustomPrivacyModal'
import LoadingSpinner from '../../components/common/LoadingSpinner'

import headerImgSrc from '../../assets/images/Research/vihreydesta-hyvinvointia-1.jpg'
import infoBox1ImgSrc from '../../assets/images/Research/vihreydesta-hyvinvointia-2.jpg'
import infoBox2ImgSrc from '../../assets/images/Research/vihreydesta-hyvinvointia-3.jpg'
import infoBox3ImgSrc from '../../assets/images/Research/vihreydesta-hyvinvointia-4.jpg'
import infoBox4ImgSrc from '../../assets/images/Research/vihreydesta-hyvinvointia-5.jpg'
import appImgSrc1 from '../../assets/images/Research/opas-1.jpg'
import appImgSrc2 from '../../assets/images/Research/opas-2.jpg'

import { get_guide_download } from '../../store/asyncThunk'

const cards = [
  {
    title: 'wellBeing.infoCard1Title',
    body: [
      { text: 'wellBeing.infoCard1Text1' },
      { text: 'wellBeing.infoCard1Text2' },
      { text: 'wellBeing.infoCard1Text3' },
    ],
    icon: <Heart />,
  },
  {
    title: 'wellBeing.infoCard2Title',
    body: [
      { text: 'wellBeing.infoCard2Text1' },
      { text: 'wellBeing.infoCard2Text2' },
      { text: 'wellBeing.infoCard2Text3' },
    ],
    icon: <Future />,
  },
]

const bullet = [
  {
    title: 'wellBeing.appBodyBullet1Title',
    text: [
      'wellBeing.appBodyBullet1Text1',
      'wellBeing.appBodyBullet1Text2',
      'wellBeing.appBodyBullet1Text3',
      'wellBeing.appBodyBullet1Text4',
    ],
  },
  {
    title: 'wellBeing.appBodyBullet2Title',
    text: [
      'wellBeing.appBodyBullet2Text1',
      'wellBeing.appBodyBullet2Text2',
      'wellBeing.appBodyBullet2Text3',
      'wellBeing.appBodyBullet2Text4',
    ],
  },
]

const newInquiryInitialState = {
  name: '',
  company: '',
  email: '',
  phone_number: '',
  email_list: 0,
  type: 'GUIDE',
}

const WellBeing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { t, i18n } = useTranslation()
  const [newInquiry, setNewInquiry] = useState(newInquiryInitialState)
  const cardGroup = i18n.t('wellBeing.cardGroup', { returnObjects: true })
  const [showText, setShowText] = useState(cardGroup.map(() => false))
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const [showCustomPrivacyModal, setShowCustomPrivacyModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const toggleCustomPrivacyModal = () => {
    setShowCustomPrivacyModal(!showCustomPrivacyModal)
  }

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    const download = searchParams.get('download')
    if (download) {
      dispatch(get_guide_download({ download }))
      .then((res) => {
        if (!res.error) {
          const blob = res.payload
  
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement("a")
          a.href = url
          a.download = download
          document.body.appendChild(a)
          a.click()
    
          // Cleanup
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }
      })
      // Remove download query param from url
      navigate("", { replace: true })
    } 
  }, [])

  const updateNewInquiry = ({
    name,
    company,
    email,
    phone_number,
    email_list,
  }) => {
    if (name !== undefined) {
      setNewInquiry({ ...newInquiry, name: name })
    }
    if (company !== undefined) {
      setNewInquiry({ ...newInquiry, company: company })
    }
    if (email !== undefined) {
      setNewInquiry({ ...newInquiry, email: email })
    }
    if (phone_number !== undefined) {
      setNewInquiry({ ...newInquiry, phone_number: phone_number })
    }
    if (email_list !== undefined) {
      setNewInquiry({ ...newInquiry, email_list: email_list })
    }
  }

  const [errorList, setErrorList] = useState([])

  const handleSendBtnClick = async () => {
    setRequestedSuccess(false)
    setErrorList([])
    setIsLoading(true)
    // check email input
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(newInquiry.email)) {
      setErrorList([t('error.emailInvalid')])
      setIsLoading(false)
      return
    }
    await dispatch(add_inquiry({ ...newInquiry })).then((res) => {
      if (res.error) {
        setErrorList([t('wellBeing.genericDownloadRequestError')])
      } else {
        setNewInquiry(newInquiryInitialState)
        setRequestedSuccess(true)
      }
    })
    setIsLoading(false)
  }

  const [requested_success, setRequestedSuccess] = useState(false)

  const btnStyle = {
    border: 'none',
    padding: '26px 50px',
  }

  return (
    <div className='well-being-container'>
      <div className='well-being-body-container'>
        <HeaderBox
          titleText={t('wellBeing.headerTitle')}
          pageText={t('wellBeing.headerGroup').toLocaleUpperCase()}
          imgSrc={headerImgSrc}
          titleBackgroundColor='yellow'
        />

        <InfoCardGroup cards={cards} />

        <InfoBox
          title={t('wellBeing.infoBox1Title')}
          imgSrc={infoBox1ImgSrc}
          text={t('wellBeing.infoBox1Text')}
        />

        <InfoBox
          imgSrc={infoBox2ImgSrc}
          text={t('wellBeing.infoBox2Text')}
          reverse={true}
        />

        <div className='well-being-app-container'>
          <div className='well-being-app-title'>{t('wellBeing.appTitle')}</div>
          <div className='well-being-app-text'>{t('wellBeing.appText')}</div>
          <div className='well-being-app-body-container'>
            { isLoading && (
              <LoadingSpinner
                border
              />
              )
            }
            <div className='well-being-app-body-left-container'>
              <div className='well-being-app-body-left-image-container'>
                <div className='well-being-app-body-left-image-wrapper'>
                  <img src={appImgSrc1} alt='app-image-1' />
                </div>
                <div className='well-being-app-body-left-image-wrapper'>
                  <img src={appImgSrc2} alt='app-image-2' />
                </div>
              </div>
              <div className='well-being-app-body-left-bullet-container'>
                {bullet.map((item, idx) => (
                  <React.Fragment key={idx}>
                    <div className='well-being-app-body-left-bullet-title'>
                      {t(`${item.title}`)}
                    </div>
                    {item.text.map((textItem, index) => (
                      <div
                        className='well-being-app-body-left-bullet-text-container'
                        key={index}
                      >
                        <div className='well-being-app-body-left-bullet-text-bullet'></div>
                        <div className='well-being-app-body-left-bullet-text'>
                          {t(`${textItem}`)}
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className='well-being-app-body-right-container'>
              <div className='well-being-app-body-right-title'>
                {t('wellBeing.appBodyTitle')}
              </div>
              {errorList.length > 0 && (
                <div className='well-being-app-body-error-container'>
                  {errorList.map((error) => {
                    return (
                      <div className='well-being-app-body-error-entry' key={error}>
                        - {error}
                      </div>
                    )
                  })}
                </div>
              )}
              {!requested_success && (
                <>
                  <div className='well-being-app-body-right-text'>
                    {t('wellBeing.appBodyText')}
                    <span> </span>
                    <div
                      className='well-being-app-body-right-text-link'
                      onClick={() => toggleCustomPrivacyModal()}
                    >
                      {t('wellBeing.appBodyLink')}
                    </div>
                  </div>
                  <div className='well-being-app-body-right-input-container'>
                    <label htmlFor='name'>{t('wellBeing.inputName')}*</label>
                    <input
                      type='text'
                      id='name'
                      onInput={(e) =>
                        updateNewInquiry({ name: e.target.value })
                      }
                      value={newInquiry.name}
                    />
                  </div>
                  <div className='well-being-app-body-right-input-container'>
                    <label htmlFor='company'>
                      {t('wellBeing.inputCompany')}*
                    </label>
                    <input
                      type='text'
                      id='company'
                      onInput={(e) =>
                        updateNewInquiry({ company: e.target.value })
                      }
                      value={newInquiry.company}
                    />
                  </div>
                  <div className='well-being-app-body-right-input-container'>
                    <label htmlFor='email'>{t('wellBeing.inputEmail')}*</label>
                    <input
                      type='text'
                      id='email'
                      onInput={(e) =>
                        updateNewInquiry({ email: e.target.value })
                      }
                      value={newInquiry.email}
                    />
                  </div>
                  <div className='well-being-app-body-right-input-container'>
                    <label htmlFor='phone'>{t('wellBeing.inputPhone')}</label>
                    <input
                      type='text'
                      id='phone'
                      onInput={(e) =>
                        updateNewInquiry({ phone_number: e.target.value })
                      }
                      value={newInquiry.phone_number}
                    />
                  </div>
                  <div className='well-being-app-body-right-input-container-row'>
                    <input
                      type='checkBox'
                      id='email_list'
                      checked={newInquiry.email_list === 1} // Ensure controlled component
                      onChange={(e) =>
                        updateNewInquiry({
                          email_list: e.target.checked ? 1 : 0,
                        })
                      }
                    />
                    <label className='no-select' htmlFor='email_list'>
                      {t('wellBeing.emailList')}
                    </label>
                  </div>
                  <div className='well-being-app-body-right-btn-container'>
                    <CustomButton
                      button_text={t('wellBeing.buttonText').toUpperCase()}
                      onClickHandler={() => handleSendBtnClick()}
                      yellow={true}
                      customStyles={btnStyle}
                      contentClass='send-btn'
                      disabled={
                        newInquiry.name.trim().length === 0 ||
                        newInquiry.email.trim().length === 0 ||
                        newInquiry.company.trim().length === 0
                      }
                    />
                  </div>
                </>
              )}
              {requested_success && (
                <div className='well-being-app-body-right-success-message'>
                  {t('wellBeing.guideDownloadSuccessMessage')}
                </div>
              )}
            </div>
          </div>
        </div>

        <InfoBox
          title={t('wellBeing.infoBox3Title')}
          imgSrc={infoBox3ImgSrc}
          text={t('wellBeing.infoBox3Text')}
        />

        <InfoBox
          imgSrc={infoBox4ImgSrc}
          text={t('wellBeing.infoBox4Text')}
          reverse={true}
        />

        <TextCardGroup
          cardGroup={cardGroup}
          showText={showText}
          setShowText={setShowText}
        />
      </div>
      {/* Privacy */}
      {showCustomPrivacyModal && (
        <CustomPrivacyModal
          showing={showCustomPrivacyModal}
          closeModal={toggleCustomPrivacyModal}
        />
      )}
    </div>
  )
}

export default WellBeing
