const innovation = {
  headerGroup: 'Innovations',
  headerTitle: 'Improving quality of life through nature',
  textCard: `We at InnoGreen see a world full of possibilities. Together with our customers, we want 
      to build ever more sustainable, pleasant and green cities, which is why we are constantly 
      developing new green solutions that are material-smart and support the circular economy.`,
  infoBoxTitle1: `Our innovations aim to solve the challenges of urban environments through greenery`,
  infoBoxText1: `We develop solutions to cool hot streets, manage stormwater, support urban 
      biodiversity and bring more nature where it's most needed. We seek to create environments 
      where greenery both looks good and supports well-being, a sense of community and recovery.
      \\n
      Curiosity is one of our core values. We foster inventiveness and creativity, and are 
      constantly looking for new ways to combine research, human-centred design and agile 
      experiments. Working closely with multidisciplinary experts, universities, other 
      businesses and NGOs gives us a strong foundation on which to build more functional, 
      sustainable and impactful green solutions.`,
  partnersTitle: 'Our partners',
  moreAbout: 'More about our innovations',
  seeAllArticles: 'See all articles',
  mediaTitle: 'Our innovations in the media',
  innovationItems: [
    {
      link: 'https://www.hs.fi/helsinki/art-2000009637533.html',
      linkText: 'Helsingin Sanomat',
      text: 'Helsinki wants to reduce the noise in the city centre: the solution is a special "green wall"',
    },
    {
      link: 'https://yle.fi/a/74-20102344',
      linkText: 'YLE',
      text: 'A tram stop dressed in nature - green structures have a cooling effect and bring in pollinators',
    },
    {
      link: 'https://www.mtvuutiset.fi/artikkeli/voiko-viherseina-estaa-mikromuovien-paatymisen-itamereen-pilottiprojekti-kaynnistyy/7939240#gs.i1hjcf',
      linkText: 'MTV',
      text: 'Can a green wall prevent microplastics from ending up in the Baltic Sea? Pilot project starts',
    },
    {
      link: 'https://www.hbl.fi/2024-08-02/vaxter-ger-svalka-pa-hallplatser-i-fiskehamnen/',
      linkText: 'HBL',
      text: 'Plants provide cooling at bus stops in Kalasatama',
    },
  ]
}

export default innovation