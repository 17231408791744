const viherseina = {
  name: 'Viherseinä',
  longDescription: `Kevytrakenteinen, metallirunkoinen viherseinämme soveltuu 
      sekä sisä- että ulkotiloihin. Metallirunkoinen viherseinä on itsellään 
      seisova eikä se vaadi vesiliitäntää tai seinäkiinnitystä, mikä 
      mahdollistaa viherseinän sijoittamisen mihin tahansa. Metallirunkoisen 
      viherseinän kastelusta vastaa ammattitaitoinen huoltomme.
      \\n
      Metallirunkoinen viherseinämme on kompakti vaihtoehto, joka sopii myös 
      pienempään tilaan ja joka on mahdollista vuokrata esimerkiksi vain 
      tapahtuman ajaksi. Vuosien saatossa metallirunkoista viherseinää on 
      näkynyt muun muassa aulatiloissa tilanjakajina, virkistävinä 
      sisustuselementteinä toimistojen kuntosaleilla ja festivaaleilla 
      kausikasvien väreissä.
      \\n
      Ulkoviherseinään valitaan monivuotisia kasveja tai toiveiden mukaan 
      näyttäviä kauden kasveja tuoden viihtyisyyttä ulkotiloihin vuoden ympäri. 
      Tyypillisesti kevät- ja kesäaikaan toivotaan kukkaloistoa 
      sisäänkäynneille ja sisäpihoille sekä virkistysalueille yrttitarhaa. 
      Pimeämpänä vuodenaikana seinään voidaan sijoittaa havukasveja sekä 
      tunnelmallisia kausivaloja.`,
  measurements: [
    {
      type: 'Leveys:',
      value: '1200 mm tai 1800 mm'
    },
    {
      type: 'Korkeus:',
      value: '2100 mm, 2270 mm tai 2430 mm'
    }
  ],
  materials: [
    {
      type: 'Runko:',
      value: 'Metalli'
    },
    {
      type: 'Viherseinän moduulit:',
      value: 'Abs-muovi, valmistettu Kaarinassa'
    },
    {
      type: 'Kasvit:',
      value: 'Suomi ja EU'
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi'
    },
    {
      type: 'Kasvivalot:',
      value: `Käytämme uusimman teknologian LED-valaisimia. 
          Polttimon spektri vastaa päivänvalon spektriä, 
          jolloin kasvit saavat tarvitsemaansa oikeanlaista valoa 
          samalla kun valo korostaa kasvien näyttävyyttä.`
    },
    {
      type: 'Käyttö:',
      value: 'Sisä- ja ulkotilat',
      icon: 'both'
    },
  ],
  wlt: {
    title: 'Tämä viherseinä suojelee 1000 neliötä sademetsää',
    text: `Valitsemalla InnoGreenin viherseinän suojelet kanssamme maailman 
      sademetsiä yhteistyössä World Land Trustin kanssa. Jokaista myytyä tai 
      vuokrattua kasviseinäneliömetriä kohden suojelemme 1000 neliömetriä sademetsää.`
  },
  configuration: [
    {
      type: 'viherseinä',
      text: ['1200 mm leveitä moduuleja', '1800 mm leveitä moduuleja'],
      valueText: 'Kokonaisleveys',
    },
  ],
  configurationProject: [
    {
      name: 'viherseinä',
      type: 'width',
      text: ['1200 mm', '1800 mm'],
    },
  ],
  leveys: {
    description: `Valitse leveys`,
    l120_cm: '1200 mm',
    l180_cm: '1800 mm',
    name: '1. Leveys'
  },
  kehyksen_vari: {
    description: `Näkyvien osien viimeistely`
  },
  korkeus: {
    description: `Valitse korkeus`
  },
}

export default viherseina