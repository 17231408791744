const customSolution = {
  headerGroup: 'Räätälöidyt ratkaisut',
  headerTitle: 'Persoonallisia ratkaisuja vaativiin kohteisiin.',
  infoBoxTitle1: 'Tutustu tilausruukkujen valikoimaan',
  infoBoxText1: `Kasvien lisäksi tärkeä osa vihersisustuksen suunnittelua on ruukkujen 
      valinta. Ruukut voivat olla näyttäviä tai elekieleltään neutraaleja toivotun 
      ilmeen mukaisesti. Ruukun tehtävänä on ennen kaikkea olla kasville pitkäaikainen 
      ja turvallinen koti.
      \\n
      Ruukkujen suhteen olemme yhtä tarkkoja kuin kasvienkin. Sopiva ruukku on 
      asianmukaisesti vesieristetty, tukeva ja riittävän suuri kasville olla ja 
      kukoistaa. Lisäksi haluamme varmistaa, että käyttämämme ruukut täyttävät 
      korkean laadun kriteerit ja ovat nopeasti sekä varmasti saatavilla.
      \\n
      Suosimme toteutuksissamme ensisijaisesti kotimaisia Koivu-ruukkuja, jotka 
      ovat kestävämpi vaihtoehto ulkomailla valmistetuille ruukuille. Mikäli 
      Koivu-ruukkujen muotokieli ei juuri tällä kertaa vastaa visiotasi, voit 
      tutustua yhteystyökumppanimme ruukkuvalikoimaan. 
      \\n
      Kyseessä on pohjoismaisessa yhteistyössä koottu valikoima ruukkuja, 
      joita yhdistää pohjoismainen design, turvallisuus ja kestävyys. 
      Suurin osa ruukuista sijaitsee välivarastossa Tanskassa, josta toimitus 
      Suomeen käy nopeimmillaan parissa viikossa.`,
  infoBoxButtonText1: 'Lataa ruukkukuvasto',
  textCard1: `Vuosien saatossa olemme toteuttaneet satoja upean uniikkeja kohteita 
      ja tartumme mielellämme kaikenlaisiin luovuutta vaativiin haasteisiin. 
      Jokaisen erikoiskohteen vihertoteutus alkaa vihersuunnitelmasta.`,
  infoBoxTitle2: `Vihersuunnittelussa sparraamme, ideoimme yhdessä ja hiomme vision 
      huippuunsa. Vihersuunnittelun ammattilaisemme huolehtivat, että lopputulos on 
      huippuluokkaa ja kustannuslaskelma kunnossa.`,
  infoBoxText2: `Vihersuunnitelma räätälöidään asiakkaan toiveiden ja tilan mukaan. 
      Otamme huomioon lukuisat eri seikat arkkitehtuurista sisustusvalintoihin sekä 
      valon määrään ja suuntaan. Ratkaisusta luodaan persoonallinen ja tilaan 
      täydellisesti istuva, johon ei internetin ilmaissuunnitelmilla päästä. 
      On esimerkiksi tärkeää huomioida, millaiset kasvit tilassa menestyvät. 
      Huolellisesta vihersuunnitelmasta hyötyvät itse asiakkaan lisäksi myös 
      rakennuttajat ja arkkitehdit. Samalla huomioidaan yhdessä huoltomahdollisuudet, 
      jotka pitävät kustannukset kurissa ja toteutus kestää aikaa.
      \\n
      Mahdollisimman näyttävä ja viimeistelty lopputulos saadaan, kun vihersuunnitelma 
      laaditaan hyvissä ajoin suunnittelutyön edetessä.`,
  infoBoxTitle3: `Vihersuunnittelu sisältää:`,
  infoBoxBullet1: 'Suositukset kasvivalinnoista tila, huolto ja ylläpito huomioiden',
  infoBoxBullet2: 'Kasvien sijoittelu: tilan käyttö, brändi, ekologisuus ja viihtyisyys',
  infoBoxBullet3: 'Ruukkujen ja viherkasvien oikeanlainen suhde, kuten ruukkujen sekä kasvien oikea koko, turvallisuus ja tilaan sopivuus',
  infoBoxBullet4: 'Toteutussuunnitelma, työohje ja tarvittaessa piirustukset mittatilausratkaisuille',
  infoBoxBullet5: 'Lisäksi saat uusia ideoita sekä sparrausta ja tukea visioillesi',
  infoBoxBullet6: 'Vihersuunnitteluun sisältyy alkukartoitus, luonnosehdotus, lopullinen toteutussuunnitelma sekä työselostus toteutettavalle kokonaisuudelle. Kokonaisvaltaisen suunnitelman avulla saat pyydettyä tarkan kustannuslaskelman vihersisustuksen toteuttamiselle.',
}

export default customSolution