import './styles.scss'

import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Typography
} from "@mui/material"
import { ArrowShortUp, ArrowShortDown } from '../Icons'
import moment from 'moment'
import CustomButton from '../common/CustomButton'
import EditMetaTagsModal from '../EditMetaTagsModal'

const MetaManagementRow = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [showEditMetaTagsModal, setShowEditMetaTagsModal] = useState(false)

  const toggleShowEditMetaTagsModal = () => {
    setShowEditMetaTagsModal(!showEditMetaTagsModal)
  }

  const getModifiedDate = (date) => {
    if (date) {
      return moment(date).format('DD-MMM-YYYY')
    }
  }

  const btnStyles = {
    'padding': '0.5rem 1rem'
  }

  return (
    <React.Fragment>
      <TableRow
        hover
        sx={{ '& > *': { borderBottom: 'unset' } }}
        className='meta-management-row'
        onClick={() => setOpen(!open)}
      >
        <TableCell align="left" sx={{ width: '3%' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowShortUp /> : <ArrowShortDown />}
          </IconButton>
        </TableCell >
        <TableCell className='meta-management-cell-text' align="left" sx={{ width: '15%' }}>{getModifiedDate(row.date_modified)}</TableCell>
        <TableCell className='meta-management-cell-text' align="left" sx={{ width: '20%' }}>{row.route}</TableCell>
        <TableCell className='meta-management-cell-text' align="left" sx={{ width: '62%' }}>{row.url}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <div>TEST</div> */}
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom component="div" className='meta-management-row-typography-text'>
                Meta Tags
                <CustomButton 
                  button_text='Edit Meta Tags'
                  customStyles={btnStyles}
                  transparent
                  onClickHandler={() => toggleShowEditMetaTagsModal()}
                />
              </Typography>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell className='meta-management-cell-inner-header' sx={{ width: '50%' }}>og:title</TableCell>
                    <TableCell className='meta-management-cell-inner-header' sx={{ width: '15%' }}>og:type</TableCell>
                    <TableCell className='meta-management-cell-inner-header'sx={{ width: '15%' }}>og:site_name</TableCell>
                    <TableCell className='meta-management-cell-inner-header' sx={{ width: '20%' }}>robots</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                  <TableCell className='meta-management-cell-text'>{row.title}</TableCell>
                  <TableCell className='meta-management-cell-text'>{row.type}</TableCell>
                  <TableCell className='meta-management-cell-text' align="left">{row.site_name}</TableCell>
                  <TableCell className='meta-management-cell-text' align="left">{row.robots}</TableCell>
                </TableRow>
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell className='meta-management-cell-inner-header' colSpan={2}>og:description</TableCell>
                    <TableCell className='meta-management-cell-inner-header' colSpan={2}>og:image</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                  <TableCell className='meta-management-cell-text description' colSpan={2}>{row.description}</TableCell>
                  <TableCell className='meta-management-cell-text' colSpan={2}>
                    <div className='meta-management-row-cell-image-wrapper'>
                      <img src={row.imgSrc} alt="" />
                    </div>
                    {/* {row.image_src} */}
                  </TableCell>
                </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <EditMetaTagsModal 
        showing={showEditMetaTagsModal}
        closeModal={toggleShowEditMetaTagsModal}
        row={row}
      />
    </React.Fragment>
  )
}

export default MetaManagementRow
