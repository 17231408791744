import './styles.scss'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import AppLogo from '../AppLogo'
import FooterLink from '../FooterLink'
import SocialIconGroup from '../SocialIconGroup'
import CustomCookiesBanner from '../common/CustomCookiesBanner'
import CustomPrivacyModal from '../common/CustomPrivacyModal'

const Footer = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const current_user = useSelector((state) => state.user.current_user)

  const goToHomePage = () => {
    navigate('/')
  }

  const [showCookiesBanner, setShowCookiesBanner] = useState(false)
  const toggleCookiesBanner = () => {
    setShowCookiesBanner(!showCookiesBanner)
  }

  const [showCustomPrivacyModal, setShowCustomPrivacyModal] = useState(false)
  const toggleCustomPrivacyModal = () => {
    setShowCustomPrivacyModal(!showCustomPrivacyModal)
  }

  return (
    <>
      <div className='app-footer-container'>
        <div className='app-footer-body-container'>
          <div className='app-footer-first-col'>
            <div
              className='app-footer-first-col-logo'
              onClick={() => goToHomePage()}
            >
              <AppLogo color='white' type='footer' />
            </div>
            <div className='app-footer-first-col-text'>
              {t('common.footerText')}
            </div>
            <SocialIconGroup />
            <div className='app-footer-first-col-link-container mobile'>
              <FooterLink
                action={() => toggleCustomPrivacyModal()}
                name={t('common.privacy')}
              />
              <FooterLink
                action={() => toggleCookiesBanner()}
                name={t('common.cookies')}
              />
            </div>
          </div>
          <div className='app-footer-second-col'>
            <div className='app-footer-second-col-child'>
              <div className='app-footer-column-title'>
                {t('common.products')}
              </div>
              <span className='app-footer-divider'></span>
              <FooterLink link={i18n.language === 'FI' ? 'tuotteet' : 'products'} name={t('common.products')} />
              <FooterLink link={i18n.language === 'FI' ? 'koivu' : 'koivu/en'}  name={t('common.koivu')} />
              <FooterLink link={i18n.language === 'FI' ? 'viherseina' : 'green-walls'}  name={t('common.greenWall')} />
              <FooterLink link={i18n.language === 'FI' ? 'viherkalusteet' : 'green-furniture'} name={t('common.greenFurniture')} />
            </div>
            <div className='app-footer-second-col-child'>
              <div className='app-footer-column-title'>
                {t('common.services')}
              </div>
              <span className='app-footer-divider'></span>
              <FooterLink
                link={i18n.language === 'FI' ? 'vihreys_palveluna' : 'green-as-a-service'}
                name={t('common.greenService')}
              />
              <FooterLink
                link={i18n.language === 'FI' ? 'vihersisustus' : 'green-interior-design'}
                name={t('common.greenInterior')}
              />
              <FooterLink link={i18n.language === 'FI' ? 'ulkoalueet' : 'outdoor-areas'} name={t('common.outdoorSpaces')} />
              <FooterLink
                link={i18n.language === 'FI' ? 'raataloidyt_ratkaisut' : 'tailored-solutions'}
                name={t('common.customSolutions')}
              />
            </div>
            <div className='app-footer-second-col-child'>
              <div className='app-footer-column-title'>
                {t('common.innoGreen')}
              </div>
              <span className='app-footer-divider'></span>
              <FooterLink link={i18n.language === 'FI' ? 'yritys' : 'about-us'} name={t('common.company')} />
              <FooterLink link={i18n.language === 'FI' ? 'blogi' : 'blog'} name={t('common.blog')} />
              <FooterLink link={i18n.language === 'FI' ? 'referenssit' : 'references'} name={t('common.references')} />
              <FooterLink link={i18n.language === 'FI' ? 'innovaatiot' : 'innovations'} name={t('common.innovation')} />
              <FooterLink link={i18n.language === 'FI' ? 'vihreydesta_hyvinvointia' : 'greenery-for-well-being'} name={t('common.wellBeing')}/>
              <FooterLink link={i18n.language === 'FI' ? 'vastuullisuus' : 'sustainability'} name={t('common.responsibility')}/>
              <FooterLink link={i18n.language === 'FI' ? 'toihin-innoGreenille' : 'work-for-innogreen'} name={t('common.work')} />
            </div>
            <div className='app-footer-second-col-child'>
              <div className='app-footer-column-title'>
                {t('common.contactTitle')}
              </div>
              <span className='app-footer-divider'></span>
              <FooterLink link={i18n.language === 'FI' ? 'yhteystiedot' : 'contact-us'} name={t('common.contact')} />
              {current_user ? (
                ''
              ) : (
                <FooterLink link={i18n.language === 'FI' ? 'kirjaudu_sisaan' : 'login'} name={t('common.logIn')} />
              )}
            </div>
            <div className='app-footer-second-col-child mobile'>
              <FooterLink
                action={() => toggleCustomPrivacyModal()}
                name={t('common.privacy')}
              />
              <FooterLink
                action={() => toggleCookiesBanner()}
                name={t('common.cookies')}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Cookies */}
      {showCookiesBanner && (
        <CustomCookiesBanner
          showing={showCookiesBanner}
          closeModal={toggleCookiesBanner}
          togglePrivacyModal={toggleCustomPrivacyModal}
        />
      )}

      {/* Privacy */}
      {showCustomPrivacyModal && (
        <CustomPrivacyModal
          showing={showCustomPrivacyModal}
          closeModal={toggleCustomPrivacyModal}
        />
      )}
    </>
  )
}

export default Footer
