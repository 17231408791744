const contact = {
  headerTitle: 'Contact us',
  inquirySentSuccess: `Thank you, form received. We will contact you as soon as possible!`,
  cards: [
    {
      title: 'Green Decor',
      id: 'Vihersisustus',
      phone: '010 583 3595',
      email: 'myynti@innogreen.fi',
      buttonText: 'Show people',
    },
    {
      title: 'Outdoor areas',
      id: 'Ulkoalueet',
      phone: '010 583 3598',
      email: 'piha@innogreen.fi',
      buttonText: 'Show people',
    },
    {
      title: 'Installation and maintenance',
      id: 'Huolto',
      phone: '010 583 3590',
      email: 'huolto@innogreen.fi',
      buttonText: 'Show people',
    },
    {
      title: 'Administration',
      id: 'Hallinto',
      phone: '010 583 3594',
      email: 'laskut@innogreen.fi',
      buttonText: 'Show people',
    },
  ],
  invoiceCards: [
    {
      title: 'Green House Effect Oy',
      id: 'Placeholder',
      type: 'companyInfo',
      address: ['Yrityspiha 6, 00390 Helsinki', 'Mäntyhaantie 4 A 12, 33800 Tampere', 'Itäkaari 28, 20750 Turku (Halli 58)'],
      identityText: 'Business ID',
      identityCode: '2413133-9',
    },
    {
      title: 'Billing information',
      id: 'Laskutustiedot',
      type: 'invoiceInfo',
      invoiceText: 'Please send all our invoices to us as e-invoices or by e-mail.',
      eInvoiceAddressText: 'E-invoicing address',
      operatorText: 'Operator',
      pdfInvoiceText: 'PDF-invoices',
      eInvoiceAddress: '003724131339',
      operator: 'Maventa (003721291126), or DABAFIHH',
      pdfInvoice: '24131339@scan.netvisor.fi',
      pdf: 'Billing information in pdf-file:',
      downloadText: 'Download here',
    },
  ],
  inquiryTitle: 'Leave us a message, and we’ll be in touch!',
  email: 'Email',
  phone: 'Phone',
  company: 'Company',
  city: 'City',
  name: 'Name',
  message: 'Message',
  sendBtn: 'Send message',
  contactInfo: [
    {
      title: 'Green decor',
      refId: 'Vihersisustus',
      email: 'myynti@innogreen.fi',
      phoneNumber: '010 583 3595',
      employee: [
        {
          name: 'Kirsi Hänninen',
          id: 'kirsih',
          department: ['Green decor'],
          email: 'kirsi.hanninen@innogreen.fi',
          phoneNumber: '040 451 6565',
        },
        {
          name: 'Liivika Korkalo',
          id: 'liivikak',
          department: ['Green design', 'Green decor'],
          email: 'liivika.korkalo@innogreen.fi',
          phoneNumber: '040 838 3223',
        },
        {
          name: 'Anne Ollila',
          id: 'anneo',
          department: ['Green decor', 'Tampere and rest of Finland'],
          email: 'anne.ollila@innogreen.fi',
          phoneNumber: '050 569 5262',
        },
        {
          name: 'Katja Kankkunen',
          id: 'katjak',
          department: ['Green decor', 'Eastern Finland'],
          email: 'katja.kankkunen@innogreen.fi',
          phoneNumber: '050 472 2986',
        },
        {
          name: 'Leena Kinnunen',
          id: 'leenak',
          department: ['Green design', 'Green decor'],
        },
      ]
    },
    {
      title: 'Outdoor area',
      refId: 'Ulkoalueet',
      email: 'piha@innogreen.fi',
      phoneNumber: '010 583 3598',
      employee: [
        {
          name: 'Miia Kujala',
          id: 'miiak',
          department: ['Sales'],
          email: 'miia.kujala@innogreen.fi',
          phoneNumber: '050 401 7891',
        },
        {
          name: 'Johanna Murto',
          id: 'johannam',
          department: ['Sales & administration'],
          email: 'johanna.murto@innogreen.fi',
          phoneNumber: '050 431 5422',
        },
        {
          name: 'Kristina Hulkkonen',
          id: 'kristinah',
          department: ['Maintenance manager'],
          email: 'kristina.hulkkonen@innogreen.fi',
          phoneNumber: '050 463 1657',
        },
      ]
    },
    {
      title: 'Installation and maintenance',
      refId: 'Huolto',
      email: 'huolto@innogreen.fi',
      phoneNumber: '010 583 3590',
      employee: [
        {
          name: 'Sari Räty',
          id: 'sarir',
          department: ['Maintenance manager (Helsinki and Turku)'],
          email: 'sari.raty@innogreen.fi',
        },
        {
          name: 'Raisa Koiranen',
          id: 'raisak',
          department: ['Maintenance (Tampere)'],
          email: 'raisa.koiranen@innogreen.fi',
        },
        {
          name: 'Teijo Tiainen',
          id: 'teijot',
          department: ['Warehouse manager'],
          email: 'teijo.tiainen@innogreen.fi',
          phoneNumber: '050 443 5561',
        },
        {
          name: 'Laura Tubal',
          id: 'laurat',
          department: ['Purchases and orders'],
          email: 'laura.tubal@innogreen.fi',
          phoneNumber: '050 472 2356',
        },
      ]
    },
    {
      title: 'Administration',
      refId: 'Hallinto',
      phoneNumber: '010 583 3594',
      email: 'laskut@innogreen.fi',
      employee: [
        {
          name: 'Raisa Koiranen',
          id: 'raisak',
          department: ['Sales invoices and contracts'],
          email: 'raisa.koiranen@innogreen.fi',
        },
        {
          name: 'Pia Paajanen',
          id: 'piap',
          department: ['Human Resources Manager'],
          email: 'pia.paajanen@innogreen.fi',
        },
        {
          name: 'Oula Harjula',
          id: 'oulah',
          department: ['Development Director'],
          email: 'oula@innogreen.fi',
        },
        {
          name: 'Mikko Sonninen',
          id: 'mikkos',
          department: ['Managing Director', 'Product development'],
          email: 'mikko@innogreen.fi',
        },
        {
          name: 'Jaakko Pesonen',
          id: 'jaakkop',
          department: ['CFO'],
          email: 'jaakko@innogreen.fi',
        },
        {
          name: 'Nora Tåg',
          id: 'norat',
          department: ['Communications'],
          email: 'nora.tag@innogreen.fi',
        },
      ]
    },
  ],
  genericContactRequestError: 'Failed to request message, please try again!',
}

export default contact