import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { ArrowSimple } from '../../components/Icons'
import ClipLoader from 'react-spinners/ClipLoader'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState, useCallback } from 'react'
import './styles.scss'
import { selectCurrentProject } from '../../store/projectSlice'
import { setGlobalLoading } from '../../store/uiSlice'
import CustomButton from '../../components/common/CustomButton'
import CustomDropdown from '../../components/common/CustomDropdown'
import EditSpaceModal from '../../components/EditSpaceModal'
import AddProductToProjectModal from '../../components/AddProductToProjectModal'
import ProjectInquiryModal from '../../components/SendProjectInquiryModal'
import DeleteProjectModal from '../../components/DeleteProjectModal'
import EditProjectModal from '../../components/EditProjectModal'
import DuplicateProjectModal from '../../components/DuplicateProjectModal'
import DeleteAssignedProductModal from '../../components/DeleteAssignedProductModal'
import { fetch_project, fetch_product_customizations } from '../../store/asyncThunk'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'
import { ArrowLeftLong, PlusIcon } from '../../components/Icons'
import ProductEntry from '../../components/ProductEntry'
import { useTranslation } from 'react-i18next'

const sorters = [
  {
    id: 1,
    name: 'DATE ADDED',
    translation: 'project.dateAdded',
    direction: true,
    icon: 'arrow-up',
  },
  {
    id: 2,
    name: 'DATE ADDED',
    translation: 'project.dateAdded',
    direction: false,
    icon: 'arrow-down',
  },
  {
    id: 3,
    name: 'PRODUCT NAME',
    translation: 'project.productName',
    direction: true,
    icon: 'arrow-up',
  },
  {
    id: 4,
    name: 'PRODUCT NAME',
    translation: 'project.productName',
    direction: false,
    icon: 'arrow-down',
  },
]

const Project = () => {
  const { project_id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const current_project = useSelector((state) => state.project.current_project)
  const global_loading = useSelector((state) => state.ui.global_loading)
  const { t, i18n } = useTranslation()
  const customizations = useSelector((state) => state.product.customizations)
  const [view_filters, setViewFilters] = useState([])
  const [showAddProductToProjectModal, setShowAddProductToProjectModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [assignSpaceOnly, setAssignSpaceOnly] = useState(false)
  const language = useSelector((state) => state.user.language)
  const [errorList, setErrorList] = useState([])

  const get_resources = useCallback(async () => {
    if (project_id) {
      dispatch(setGlobalLoading(true))
      await dispatch(
        fetch_project({
          project_id: parseInt(project_id)
        })
      ).then((res) => {
        if (res.error) {
          if (res.payload.error.type === 'access') {
            setErrorList([t('error.noAccessToProject')])
          }
        }
      })
      dispatch(selectCurrentProject(parseInt(project_id)))
      await dispatch(fetch_product_customizations(true))
      dispatch(setGlobalLoading(false))
    }
  }, [project_id, dispatch])

  useEffect(() => {
    if (!current_project) {
      get_resources()
    }
    
  }, [get_resources, project_id])

  useEffect(() => {
    if (current_project) {
      const space_list = []
      //Check and add customed space from the selected project
      if (current_project) {
        //Add all space and default space first
        space_list.push({
          id: 'all',
          name: 'ALL SPACES',
          translation: 'project.allSpaces',
          customStyles: {
            backgroundColor: '#85E8E5',
            borderColor: '#85E8E5',
          },
        })

        current_project.spaces.forEach((s) => {
          const index = space_list.findIndex((space) => space.id === s.id)
          if (index === -1) {
            space_list.push({
              ...s,
              id: s.id,
              name: s.name,
              is_default: s.is_default ?? false,
              translation: s.is_default ? 'project.defaultSpace' : null,
              customStyles: {
                backgroundColor: s.is_default ? '#85E8E5' : '#FCEA19',
                borderColor: s.is_default ? '#85E8E5' : '#FCEA19',
              },
            })
          }
        })
      }

      setViewFilters(space_list)
    }
  }, [current_project])

  const [selectedViewFilter, setSelectedViewFilter] = useState(null)
  const [filteredProducts, setfilteredProducts] = useState([])
  const [sortBy, setSortBy] = useState(sorters[0])
  const [showProjectInquiryModalState, setProjectInquiryModalState] =
    useState(false)
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false)
  const [showEditProjectModal, setShowEditProjectModal] = useState(false)
  const [showDuplicateProjectModal, setShowDuplicateProjectModal] = useState(false)
  const [
    showDeleteAssignedProductProductModal,
    setShowDeleteAssignedProductProductModal,
  ] = useState(false)
  useEffect(() => {
    if (view_filters) {
      if (!selectedViewFilter) {
        setSelectedViewFilter(view_filters[0])
      }
    }
  }, [view_filters, selectedViewFilter])

  const selectSortBy = (val) => {
    setSortBy(val)
  }

  const [showEditSpacesState, setEditSpacesState] = useState(false)

  const handleViewFilterClick = (item) => {
    if (item) {
      setSelectedViewFilter(item)
    }
  }

  const toggleDeleteProjectModal = () => {
    setShowDeleteProjectModal(!showDeleteProjectModal)
  }

  const toggleEditProjectModal = () => {
    setShowEditProjectModal(!showEditProjectModal)
  }
  
  const toggleDuplicateProjectModal = () => {
    setShowDuplicateProjectModal(!showDuplicateProjectModal)
  }

  useEffect(() => {
    if (selectedViewFilter && current_project) {
      let filtered_list = []
      current_project.spaces
        .filter((space) => {
          if (selectedViewFilter) {
            if (['all'].includes(selectedViewFilter.id)) {
              return true
            } else if (['default'].includes(selectedViewFilter.id)) {
              return space.is_default
            } else {
              const view = view_filters.find(
                (v) => v.id === selectedViewFilter.id
              )

              return view.id === space.id
            }
          }
          return true
        })
        .forEach((space) => {
          space.assigned_products.forEach((p) => {
            const product = current_project.products.find(
              (pro) => pro.id === p.id
            )

            if (product) {
              filtered_list.push({
                ...product,
                space_id: space.id,
                quantity: p.quantity,
              })
            }
          })
        })

      filtered_list = filtered_list.sort((a, b) => {
        if (sortBy.id === 1) {
          return moment(a.date_created).isSameOrBefore(b.date_created) ? -1 : 1
        }
        if (sortBy.id === 2) {
          return moment(a.date_created).isSameOrAfter(b.date_created) ? -1 : 1
        }
        if (sortBy.id === 3) {
          return a.name.localeCompare(b.name)
        }
        if (sortBy.id === 4) {
          return -a.name.localeCompare(b.name)
        }
        return a.name.localeCompare(b.name)
      })
      setfilteredProducts(filtered_list)
    }
  }, [selectedViewFilter, current_project, sortBy, view_filters])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const toggleEditSpacesModal = () => {
    setEditSpacesState(!showEditSpacesState)
  }

  const goToAccount = () => {
    navigate('/account')
  }

  const get_total_product_quantity = () => {
    //Depends on the current filter
    let result = 0
    filteredProducts.forEach((p) => {
      result += p.quantity
    })

    return result
  }

  const get_project_status = (status) => {
    switch (status) {
      case 1:
        return t('project.open')
      case 2:
        return t('project.pending')
      case 3:
        return t('project.inquirySent')
      case 4:
        return t('project.closed')
      case 5:
        return t('project.cancelled')
      default:
        return t('project.unknown')
    }
  }

  const goToProductSummary = () => {
    navigate(`/project/${current_project.id}/product-summary`)
  }

  const toggleAddProductToProjectModal = (product, assign_space_only) => {
    if (showAddProductToProjectModal) {
      setSelectedProduct(null)
      setAssignSpaceOnly(false)
      setShowAddProductToProjectModal(false)
    } else {
      setSelectedProduct(product)
      setAssignSpaceOnly(assign_space_only)
      setShowAddProductToProjectModal(true)
    }
  }

  const toggleProjectInquiryModal = () => {
    setProjectInquiryModalState(!showProjectInquiryModalState)
  }

  const toggleConfirmRemoveProductModal = (product) => {
    if (showDeleteAssignedProductProductModal) {
      setSelectedProduct(null)
      setShowDeleteAssignedProductProductModal(false)
    } else {
      setSelectedProduct({ ...product, project_id: project_id })
      setShowDeleteAssignedProductProductModal(true)
    }
  }

  const handleGoToProductsPage = () => {
    if (i18n.language ===  'FI') {
      navigate(`/tuotteet?scroll=true`)
    } else if (i18n.language === 'EN') {
      navigate(`/products?scroll=true`)
    }
  }

  const getModifiedDate = (date) => {
    if (date) {
      if (language) {
        moment.locale(language.toLowerCase())
      }
      return moment(date).format('DD.MM.YYYY')
    }
  }

  const customStyles = {
    backgroundColor: 'white',
  }

  const customStylesFilter = {
    backgroundColor: '#FCEA19',
  }

  return (
    <>
      <div className='project-page-container'>
        <div className='project-page-header-link-container'>
          <div className='project-page-header-link-wrapper'>
            <div className='project-page-header-link' onClick={() => goToAccount()}>
              <ArrowLeftLong /> <p>{t('project.backToMyAccount').toUpperCase()}</p>
            </div>
          </div>
        </div>

        {errorList.length > 0 && (
          <div className='project-page-header-container'>
            <div className='project-page-header-wrapper'>
              {errorList.map((error) => {
                return (
                  <div className='project-page-header-error' key={error}>
                    - {error}
                  </div>
                )
              })}
            </div>
          </div>
        )}

        {/* PAGE HEADER */}
        {current_project && (
          <>
            <div className='project-page-header-container'>
              <div className='project-page-header-wrapper'>
                <div className='project-page-header'>
                  <div className='project-page-header-left-content'>
                    <div className='project-page-project-name'>
                      {current_project.name}
                    </div>
                    <div className='project-page-project-description'>
                      {current_project.description}
                    </div>
                    <div className='project-page-project-date-status'>
                      <div className='project-page-project-date-status-item'>
                        <div className='project-page-project-date-status-label'>
                          {t('project.dateCreated')}
                        </div>
                        <div className='project-page-project-date-status-value'>
                          {getModifiedDate(current_project.date_created)}
                        </div>
                      </div>
                      <div className='project-page-project-date-status-item'>
                        <div className='project-page-project-date-status-label'>
                          {t('project.dateModified')}
                        </div>
                        <div className='project-page-project-date-status-value'>
                          {getModifiedDate(current_project.date_modified)}
                        </div>
                      </div>
                      <div className='project-page-project-date-status-item'>
                        <div className='project-page-project-date-status-label'>
                          {t('project.status')}
                        </div>
                        <div className='project-page-project-date-status-value'>
                          {get_project_status(current_project.status)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='project-page-header-right-content'>
                    <CustomButton
                      button_text={t('project.editProjectInformation').toUpperCase()}
                      onClickHandler={() => toggleEditProjectModal()}
                      transparent={true}
                      contentClass='project-header'
                      disabled={current_project.can_edit === false}
                    />
                    <CustomButton
                      button_text={t('project.duplicateProject').toUpperCase()}
                      onClickHandler={() => toggleDuplicateProjectModal()}
                      transparent={true}
                      contentClass='project-header'
                    />
                    <CustomButton
                      button_text={t('project.deleteProject').toUpperCase()}
                      onClickHandler={() => toggleDeleteProjectModal()}
                      transparent={true}
                      contentClass='project-header'
                      disabled={current_project.can_delete === false}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* SUMMARY LINK  */}
            {global_loading ?
              (
                <></>
              ) : (
                <div className='project-page-product-summary-link-container'>
                  <div className='project-page-product-summary-link-wrapper'>
                    <div className='project-page-product-summary-link'>
                      <div
                        className='project-page-product-summary-link-text'
                        onClick={() => goToProductSummary()}
                      >
                        {t('project.openProductSummary')}
                        <ArrowSimple size={22} strokeWidth={0.1}/>
                      </div>
                      <div className='project-page-product-summary-link-text-small'>
                      {t('project.printProjectSummary')}
                      </div>
                    </div>

                  </div>
                </div>
              )
            }


            {/* PRODUCT FILTER GROUP */}
            {global_loading ?
              (
                <></>
              ) : (
                <div className='project-page-product-filter-groups-container'>
                  <div className='project-page-product-filter-groups-wrapper'>
                    <div className='project-page-product-filter-container'>
                      <div className='project-page-product-filter-label'>{t('project.view').toUpperCase()}</div>
                      <div className='project-page-product-filter-selection'>
                        <div className='project-page-product-filter-selection-buttons'>
                          {view_filters.map((filter) => {
                            if (filter.id === 'all') {
                              return (
                                <CustomButton
                                key={filter.id}
                                button_text={filter.translation ? t(filter.translation).toUpperCase() : filter.name.toUpperCase()}
                                turquoise={true}
                                selected={
                                  selectedViewFilter &&
                                  selectedViewFilter.id === filter.id
                                }
                                onClickHandler={() => handleViewFilterClick(filter)}
                                contentClass='project-filter'
                              />
                              )
                              
                            } else {
                              return (
                                <CustomButton
                                  key={filter.id}
                                  button_text={filter.translation ? t(filter.translation).toUpperCase() : filter.name.toUpperCase()}
                                  yellow={true}
                                  selected={
                                    selectedViewFilter &&
                                    selectedViewFilter.id === filter.id
                                  }
                                  onClickHandler={() => handleViewFilterClick(filter)}
                                  contentClass='project-filter'
                                />
                              )

                            }
                          })}
                          <CustomButton
                            button_text={t('project.editSpaces').toUpperCase()}
                            onClickHandler={() => toggleEditSpacesModal()}
                            transparent={true}
                            contentClass='project-filter'
                            disabled={current_project.can_edit === false}
                          />
                        </div>
                        <div className='project-page-product-filter-selection-dropdown mobile'>
                          <CustomDropdown
                            showBorder={false}
                            customClass='project-dropdown'
                            options={view_filters}
                            selected={selectedViewFilter}
                            onClickHandler={(val) => handleViewFilterClick(val)}
                            useUpperCase
                            iconType={2}
                            customStyles={customStylesFilter}
                          />
                          <FontAwesomeIcon
                            className='project-page-product-filter-selection edit-icon'
                            icon={faPencil}
                            onClick={() => toggleEditSpacesModal()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='project-page-product-filter-container'>
                      <div className='project-page-product-filter-label'>{t('project.sort').toUpperCase()}</div>
                      <div className='project-page-product-filter-selection'>
                        <div className='project-page-product-filter-selection-dropdown'>
                          <CustomDropdown
                            customClass='project-dropdown'
                            showBorder={false}
                            iconType={2}
                            customStyles={customStyles}
                            options={sorters}
                            selected={sortBy}
                            useUpperCase
                            onClickHandler={(val) => selectSortBy(val)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            {/* PRODUCT LIST  */}
            {global_loading ?
              (
                <div className='project-page-product-list-loader'>
                    <ClipLoader color='#1a73e8' />
                </div>
              ) : (
                <div className='project-page-product-list-container'>
                  <div className='project-page-product-list-wrapper'>
                    <div className='project-page-product-list-header'>
                      <div className='project-page-product-list-header-cell'>
                        {t('project.product')}
                      </div>
                      <div className='project-page-product-list-header-cell'>
                        {t('project.quantity')}
                      </div>
                    </div>
                    <div
                      className={`project-page-product-list-product-new-btn ${current_project.can_edit === false ? 'disabled' : ''}`}
                      onClick={handleGoToProductsPage}
                    >
                      <PlusIcon
                        color={current_project.can_edit === false ? '#a3a3a3' : 'black'}
                      />
                      <span>{t('project.addNewProduct').toUpperCase()}</span>
                    </div>
                    {filteredProducts ? (
                      filteredProducts.map((product, index) => {
                        if (index === filteredProducts.length - 1) {
                          return (
                            <React.Fragment key={index}>
                              <ProductEntry
                                product={product}
                                toggleConfirmRemoveProductModal={toggleConfirmRemoveProductModal}
                                toggleAddProductToProjectModal={toggleAddProductToProjectModal}
                                current_project={current_project}
                                project_id={project_id}
                                customizations={customizations}
                              />
                              <div
                                className={`project-page-product-list-product-new-btn mobile ${current_project.can_edit === false ? 'disabled' : ''}`}
                                onClick={handleGoToProductsPage}
                              >
                                <PlusIcon
                                  color={current_project.can_edit === false ? '#a3a3a3' : 'black'}
                                />
                                <span>{t('project.addNewProduct').toUpperCase()}</span>
                              </div>
                            </React.Fragment>
                          )
                        }
                        return (
                          <React.Fragment key={index}>
                            <ProductEntry
                              product={product}
                              toggleConfirmRemoveProductModal={toggleConfirmRemoveProductModal}
                              toggleAddProductToProjectModal={toggleAddProductToProjectModal}
                              current_project={current_project}
                              project_id={project_id}
                              customizations={customizations}
                            />
                          
                          </React.Fragment>
                        )
                      })
                    ) : (
                      <div className='project-page-product-list-no-entry'>
                        {t('project.noProductFound').toUpperCase()}
                      </div>
                    )}
                  </div>
                </div>
              )
            }
            

            {/* PAGE FOOTER */}
            {global_loading ?
              (
                <></>
              ) : (
                <div className='project-page-footer-container'>
                  <div className='project-page-footer-wrapper'>
                    <div className='project-page-footer-left-content'>
                      <CustomButton
                        button_text={current_project && current_project.status === 3 ? t('project.offerInquired').toUpperCase() : t('project.sendInquiry').toUpperCase()}
                        selected
                        onClickHandler={() => toggleProjectInquiryModal()}
                        disabled={current_project && current_project.status === 3}
                      />
                      <div
                        className='project-page-footer-left-content-link'
                        onClick={() => goToProductSummary()}
                      >
                        {t('project.openProductSummary')}
                        <ArrowSimple size={22} strokeWidth={0.1}/>
                      </div>
                    </div>
                    <div className='project-page-footer-right-content'>
                      <div className='project-page-footer-right-content-title'>
                      {t('project.total')}: {get_total_product_quantity()} {t('project.products').toLowerCase()}
                      </div>
                    </div>
                  </div>
                </div>
              )
            
            }

            {showProjectInquiryModalState && (
              <ProjectInquiryModal
                showing={showProjectInquiryModalState}
                closeModal={toggleProjectInquiryModal}
                project_id={project_id}
              />
            )}
          </>
        )}
      </div>
      {showEditSpacesState && (
        <EditSpaceModal
          showing={showEditSpacesState}
          closeModal={toggleEditSpacesModal}
          spaces={view_filters.filter(
            (v) => v.id !== 'default' && v.id !== 'all'
          )}
        />
      )}

      {showAddProductToProjectModal && (
        <AddProductToProjectModal
          presetProject={assignSpaceOnly ? current_project : null}
          assignSpaceOnly={assignSpaceOnly}
          product={selectedProduct}
          showing={showAddProductToProjectModal}
          closeModal={toggleAddProductToProjectModal}
          keepCurrentProject={true}
        />
      )}

      {showDeleteProjectModal && (
        <DeleteProjectModal
          showing={showDeleteProjectModal}
          closeModal={toggleDeleteProjectModal}
          project={current_project}
        />
      )}

      {showEditProjectModal && (
        <EditProjectModal
          showing={showEditProjectModal}
          closeModal={toggleEditProjectModal}
          project={current_project}
        />
      )}

    {showDuplicateProjectModal && (
        <DuplicateProjectModal
          showing={showDuplicateProjectModal}
          closeModal={toggleDuplicateProjectModal}
          project={current_project}
        />
      )}

      {showDeleteAssignedProductProductModal && (
        <DeleteAssignedProductModal
          showing={showDeleteAssignedProductProductModal}
          closeModal={toggleConfirmRemoveProductModal}
          product={selectedProduct}
        />
      )}
    </>
  )
}

export default Project
