import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import productSlice from './productSlice'
import projectSlice from './projectSlice'
import uiSlice from './uiSlice'
import userSlice from './userSlice'
import referenceSlice from './referenceSlice'
import blogSlice from './blogSlice'
import managementSlice from './managementSlice'
import { applyMiddleware, compose } from 'redux'

const rootReducer = combineReducers({
  product: productSlice,
  project: projectSlice,
  ui: uiSlice,
  user: userSlice,
  reference: referenceSlice,
  blog: blogSlice,
  management: managementSlice
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default configureStore({
  reducer: rootReducer,

  devTools: composeEnhancers(applyMiddleware()),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export const useAppSelector = useSelector
