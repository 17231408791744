import './styles.scss'
import { Close } from '../Icons'
import { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { delete_blog, delete_reference } from '../../store/asyncThunk'
import { useTranslation } from 'react-i18next'

const DeleteContentModal = (props) => {
  const { 
    showing,
    closeModal,
    project,
    type,
    setIsLoading,
    setErrorList,
    setDisableDelete,
    setDeleteSuccess
  } = props
  const { slug, year, month  } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  // const [disableDelete, setDisableDelete] = useState(false)

  useEffect(() => {
    setErrorList([])
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const handleDeleteClick = async () => {
    if (type === 'reference') {
      setDisableDelete(true)
      setIsLoading(true)
      await dispatch(delete_reference({slug:slug}))
      .then((res) => {
        if (!res.error) {
          setErrorList([])
          setDeleteSuccess(true)
          setTimeout(() => {
            navigate("/referenssit", { replace: true })
          }, 2000);
        } else {
          setErrorList([t('error.noAccess')])
        }
      })
      close()
      setIsLoading(false)
    }
    if (type === 'blog') {
      setDisableDelete(true)
      setIsLoading(true)
      await dispatch(delete_blog({year: year, month: month, slug: slug}))
      .then((res) => {
        if (!res.error) {
          setErrorList([])
          setDeleteSuccess(true)
          setTimeout(() => {
            navigate("/blogi", { replace: true })
          }, 2000);
        } else {
          setErrorList([t('error.noAccess')])
        }
      })
      close()
      setIsLoading(false)
    }
  }

  return (
    showing &&
    (
      <div className='delete-project-modal-root'>
        <div className='delete-project-modal-container'>
          <div className='delete-project-modal-header'>
            <div className='delete-project-modal-header-exit-btn-container'>
              <div
                className='delete-project-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20} />
              </div>
            </div>
          </div>

          <div className='delete-project-modal-content'>
            <div className='delete-project-modal-header-title'>
              {type === 'reference' ? 'Delete Reference' : 'Delete Blog'}
            </div>
            <div className='delete-project-modal-text'>
              Are you sure you want to delete this {type === 'reference' ? 'reference' : 'blog'}?
            </div>
          </div>

          <div className='delete-project-modal-btn-group'>
            <CustomButton
              button_text='Delete'
              danger
              onClickHandler={() => handleDeleteClick()}
            />
            <CustomButton
              button_text='Cancel'
              onClickHandler={() => close()}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default DeleteContentModal
