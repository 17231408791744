import './styles.scss'

import { useNavigate, Link, useMatches } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { signInWithRedirect, signOut } from 'aws-amplify/auth'

import CustomButton from '../../components/common/CustomButton'
import { SearchIcon } from '../../components/Icons'
import loginImgSrc from '../../assets/images/Login/kirjaudu-1.jpg'
import SignUpModal from '../../components/SignUpModal'
import CustomCookiesBanner from '../../components/common/CustomCookiesBanner'
import CustomForgotPasswordModal from '../../components/common/CustomForgotPasswordModal'

import { log_in } from '../../store/asyncThunk'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [logInPayload, setLogInPayload] = useState({
    email: '',
    password: '',
  })
  const current_user = useSelector((state) => state.user.current_user)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    if (current_user) {
      navigate('/account')
    }
  }, [current_user])

  const updateLogInPayload = ({ email, password }) => {
    if (email !== undefined) {
      setLogInPayload({ ...logInPayload, email: email })
    }
    if (password !== undefined) {
      setLogInPayload({ ...logInPayload, password: password })
    }
  }
  const [errorList, setErrorList] = useState([])
  const handleLogInClick = async () => {
    if (
      logInPayload.email &&
      logInPayload.email.trim().length > 0 &&
      logInPayload.password &&
      logInPayload.password.trim().length > 0
    ) {
      setErrorList([])
      dispatch(log_in(logInPayload))
      .then((res) => {
        if (res.error) {
          if (res.payload && res.payload.data.errorCode) {
            setErrorList([t(res.payload.data.errorCode)])
          }
          else {
            setErrorList([t('login.genericLogInError')])
          }
        }
      })
    }
  }

  const disable_log_in = () => {
    // If cookies are opted out, disable login
    if (
      !localStorage.getItem('innogreen_cookies_approved') ||
      localStorage.getItem('innogreen_cookies_approved') === '0'
    ) {
      return true
    }
    if (logInPayload) {
      return !(
        logInPayload.email &&
        logInPayload.email.trim().length > 0 &&
        logInPayload.password &&
        logInPayload.password.trim().length > 0
      )
    }
    return true
  }

  const disable_sign_up = () => {
    // If cookies are opted out, disable login
    if (
      !localStorage.getItem('innogreen_cookies_approved') ||
      localStorage.getItem('innogreen_cookies_approved') === '0'
    ) {
      return true
    }
  }

  const handleGoogleLogIn = async () => {
    await signOut()
    signInWithRedirect({ provider: 'Google' })
  }

  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const toggleSignUpModal = () => {
    setShowSignUpModal(!showSignUpModal)
  }

  const [showCookiesWarning, setShowCookiesWarning] = useState(false)

  //For first refresh on page
  useEffect(() => {
    if (
      !localStorage.getItem('innogreen_cookies_approved') ||
      localStorage.getItem('innogreen_cookies_approved') === '0'
    ) {
      setShowCookiesWarning(true)
    }
  }, [])

  const [showCookiesBanner, setShowCookiesBanner] = useState(false)
  const toggleCookiesBanner = () => {
    setShowCookiesBanner(!showCookiesBanner)
  }

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const toggleForgotPasswordModal = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
  }

  return (
    <div className='login-page-container'>
      <div className='login-page-body-container'>
        <div className='login-page-left-container'>
          <img src={loginImgSrc} alt='' />
        </div>
        <div className='login-page-right-container'>
          <div className='login-page-right-login-container'>
            <div className='login-page-right-login-title'>
              {t('login.loginTitle')}
            </div>
            {errorList.length > 0 && (
              <div className='login-page-right-error-container'>
                {errorList.map((error) => {
                  return (
                    <div className='login-page-right-error-entry' key={error}>
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='login-page-right-login-input-container'>
              <label htmlFor='email'>{t('login.labelEmail')}</label>
              <input
                type='text'
                id='email'
                value={logInPayload.email}
                onInput={(e) => updateLogInPayload({ email: e.target.value })}
              />
            </div>
            <div className='login-page-right-login-input-container'>
              <label htmlFor='password'>{t('login.labelPassword')}</label>
              <input
                type='password'
                id='password'
                value={logInPayload.password}
                onInput={(e) =>
                  updateLogInPayload({ password: e.target.value })
                }
                onKeyDown={(e) => (e.key === 'Enter' ? handleLogInClick() : '')}
              />
            </div>
            <div className='login-page-right-login-btn-container'>
              <CustomButton
                button_text={t('login.loginBtn')}
                primary={true}
                onClickHandler={() => handleLogInClick()}
                contentClass='login'
                disabled={disable_log_in()}
              />
              <Link
                onClick={() => toggleForgotPasswordModal()}
                className='login-page-right-login-btn-link'
              >
                {t('login.forgotPassword')}
              </Link>
            </div>

            {showCookiesWarning && (
              <div className='login-page-right-login-cookies-warning'>
                *{t('login.logInCookiesWarning')}
                <span
                  className='login-page-right-login-cookies-warning-link'
                  onClick={() => toggleCookiesBanner()}
                >
                  {t('login.cookiesChangeLink')}
                </span>
              </div>
            )}

            {/* GOOGLE LOG IN  */}
            {/* <CustomButton
              turquoise
              button_text={t('common.logInWithGoogle')}
              onClickHandler={() => handleGoogleLogIn()}
            /> */}
          </div>
          <div className='login-page-right-signup-container'>
            <div className='login-page-right-signup-title'>
              {t('login.newAccountTitle')}
            </div>
            <div className='login-page-right-signup-text'>
              {t('login.newAccountText')}
            </div>
            <div className='login-page-right-signup-button-wrapper'>
              <CustomButton
                button_text={t('login.newAccountBtn')}
                purple={true}
                onClickHandler={() => toggleSignUpModal()}
                contentClass='login'
                disabled={disable_sign_up()}
              />
            </div>
            {showCookiesWarning && (
              <div className='login-page-right-login-cookies-warning'>
                *{t('login.signUpCookiesWarning')}
                <span
                  className='login-page-right-login-cookies-warning-link'
                  onClick={() => toggleCookiesBanner()}
                >
                  {t('login.cookiesChangeLink')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {showSignUpModal && (
        <SignUpModal showing={showSignUpModal} closeModal={toggleSignUpModal} />
      )}

      {/* Cookies */}
      {showCookiesBanner && (
        <CustomCookiesBanner
          showing={showCookiesBanner}
          closeModal={toggleCookiesBanner}
        />
      )}

      {/* Forgot password modal */}
      {showForgotPasswordModal && (
        <CustomForgotPasswordModal
          showing={showForgotPasswordModal}
          closeModal={toggleForgotPasswordModal}
        />
      )}
    </div>
  )
}

export default Login
