import { createSelector, createSlice } from '@reduxjs/toolkit'
import {
  fetch_products,
  fetch_product,
  fetch_related_products,
  fetch_product_groups,
  fetch_related_products_by_group,
  fetch_product_customizations,
} from './asyncThunk'

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    product_groups: [],
    products: [],
    related_products: [],
    customizations: [],
    product_customization: [],
    selected_customized_product: null,
  },
  reducers: {
    selectCustomizedProduct: (state, action) => {
      state.selected_customized_product = action.payload
    },
    updateCustomizedOption: (state, action) => {
      const { value=null, option, customization, isGreenStop=false } = action.payload
      if (isGreenStop) {
        if (state.selected_customized_product) {
          const index =
            state.selected_customized_product.customizations.findIndex(
              (c) => c.selected_option_id === option.id
            )
          if (index > -1) {
            state.selected_customized_product.customizations.splice(index, 1, {
              id: customization.id,
              selected_option_id: option.id,
              name: customization.name,
              value: value,
              calculation_value: option.name === '120 cm' ? 120 : 180
            })
          } else {
            state.selected_customized_product.customizations.push({
              id: customization.id,
              selected_option_id: option.id,
              name: customization.name,
              value: value,
              calculation_value: option.name === '120 cm' ? 120 : 180
            })
          }
        }
      } else {
        if (state.selected_customized_product) {
          const index =
            state.selected_customized_product.customizations.findIndex(
              (c) => c.id === customization.id
            )
          if (index > -1) {
            state.selected_customized_product.customizations.splice(index, 1, {
              id: customization.id,
              selected_option_id: option.id,
              name: customization.name,
              value: value
            })
          } else {
            state.selected_customized_product.customizations.push({
              id: customization.id,
              selected_option_id: option.id,
              name: customization.name,
              value: value
            })
          }
        }
      }
    },
    removeCustomizedOption: (state, action) => {
      const { option } = action.payload
      if (state.selected_customized_product) {
        const index = state.selected_customized_product.customizations.findIndex((c) => c.id === option.id)
        state.selected_customized_product.customizations.splice(index, 1,)
      }
    },
    preselectCustomization: (state, action) => {
      const { value, width, height, isGreenStop, isMossWall, isCustomGreenWall, isGreenWall } = action.payload
      if (state.product_customization) {
        if (isGreenStop) {
          state.product_customization.forEach(item => {
            item.options.forEach(option => {
              state.selected_customized_product.customizations.push({
                id: item.id,
                name: item.name,
                selected_option_id: option.id,
                value: value
              })
            })
          })
        } else if (isMossWall) {
          state.product_customization.forEach(item => {
            state.selected_customized_product.customizations.push({
              id: item.id,
              name: item.name,
              selected_option_id: item.options[0].id,
              value: item.name === '3. Kustomointi' ? '' : value
            })
          })
        } else if (isCustomGreenWall) {
          state.product_customization.forEach(item => {
            state.selected_customized_product.customizations.push({
              id: item.id,
              name: item.name,
              selected_option_id: item.options[0].id,
              value: item.name === '1. Leveys' ? width : height
            })
          })
        } else if (isGreenWall) {
          state.product_customization.forEach(item => {
            if (item.name === '1. Leveys') {
              
              item.options.forEach(option => {
                state.selected_customized_product.customizations.push({
                  id: item.id,
                  name: item.name,
                  selected_option_id: option.id,
                  value: value,
                  calculation_value: option.name === '120 cm' ? 120 : 180
                })
              })
            } else {
              state.selected_customized_product.customizations.push({
                id: item.id,
                name: item.name,
                selected_option_id: item.options[0].id,
                value: value
              })
            }
          })
        } else {
          state.product_customization.forEach(item => {
            state.selected_customized_product.customizations.push({
              id: item.id,
              name: item.name,
              selected_option_id: item.options[0].id,
              value: value
            })
          })
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetch_products.fulfilled, (state, action) => {
      const { payload } = action
      state.products = payload
    })
    builder.addCase(fetch_product.fulfilled, (state, action) => {
      const { payload } = action
      state.selected_customized_product = {
        ...payload.product,
        customizations: payload.preload_customizations
          ? state.selected_customized_product
            ? state.selected_customized_product.customizations ?? []
            : []
          : [],
      }
      state.product_customization = payload.product.customizations
      // update products list for product overview page
      const index = state.products.findIndex(
        (p) => p.id === state.selected_customized_product.id
      )
      if (index > -1) {
        state.products.splice(index, 1, state.selected_customized_product)
      } else {
        state.products.push(state.selected_customized_product)
      }
    })
    builder.addCase(fetch_product_groups.fulfilled, (state, action) => {
      const { payload } = action
      state.product_groups = payload
    })
    builder.addCase(fetch_product_customizations.fulfilled, (state, action) => {
      const { payload } = action
      state.customizations = payload
    })
    builder.addCase(fetch_related_products.fulfilled, (state, action) => {
      const { payload } = action
      state.related_products = payload
    })
    builder.addCase(fetch_related_products_by_group.fulfilled, (state, action) => {
      const { payload } = action
      state.related_products = payload
    })
  },
})

// Action creators are generated for each case reducer function
export const { selectCustomizedProduct, updateCustomizedOption, preselectCustomization, removeCustomizedOption } =
  productSlice.actions

const product_groups = (state) => state.product.product_groups
const products = (state) => state.product.products
export const get_product_product_group_combined = createSelector(
  [product_groups, products],
  (product_groups, products) => {
    const groups = []
    product_groups.forEach((g) => {
      groups.push({ ...g, products: [] })
    })
    products.forEach((p) => {
      groups.forEach((g) => {
        if (
          p.product_groups.some((group) => group.id === g.id) &&
          !g.products.some((pro) => pro.id === p.id)
        ) {
          g.products.push(p)
        }
      })
    })

    return groups
  }
)
export const customized_product = createSelector(
  [(state) => state.product.selected_customized_product],
  (selected_customized_product) => {
    return selected_customized_product
  }
)

// Create selector for random products for product carousel
export const createDemoProductGroupSelector = (numProducts, name) =>
  createSelector(
    [(state) => state.product.products],
    (products) => {
      let shuffledProducts = products.slice();

      // Shuffle products array
      for (let i = shuffledProducts.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1));
        [shuffledProducts[i], shuffledProducts[randomIndex]] = [
          shuffledProducts[randomIndex],
          shuffledProducts[i]
        ];
      }

      const group = {
        id: 1,
        name: name,
        products: shuffledProducts.slice(0, numProducts)
      };
      
      return group;
    }
  );


export default productSlice.reducer
