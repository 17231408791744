import './styles.scss'

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatches } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import HeaderBox from '../../components/HeaderBox'
import InfoBox from '../../components/InfoBox'
import BlogGroup from '../../components/BlogGroup'
import LoadingScreen from '../../components/common/LoadingScreen'

import headerImgSrc from '../../assets/images/Innovation/innovaatiot-1.jpg'
import infoBox1ImgSrc from '../../assets/images/Innovation/innovaatiot-2.jpg'
import InnovationMedia from '../../components/InnovationMedia'

import { setGlobalLoading } from '../../store/uiSlice'

import { fetch_blogs } from '../../store/asyncThunk'

const Innovation = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const global_loading = useSelector((state) => state.ui.global_loading)
  const innovationBlogs = useSelector((state) => state.blog.innovationBlogs)
  const innovationItems = i18n.t('innovation.innovationItems', { returnObjects: true })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      // Fetch 3 latest blogs with 'Innovaatiot' tag
      await dispatch(fetch_blogs({ for_page: 'innovatiot', tags: 'Innovaatiot', quantity: 3 }))
      dispatch(setGlobalLoading(false))
    })()
  }, [])

  if (global_loading) {
    return (
      <>
        <div className='innovation-container'></div>
        <LoadingScreen />
      </>
    )
  }

  return (
    <>
      <div className='innovation-container'>
        <div className='innovation-body-container'>
          <HeaderBox
            titleText={t('innovation.headerTitle')}
            pageText={t('innovation.headerGroup').toUpperCase()}
            imgSrc={headerImgSrc}
            titleBackgroundColor='yellow'
          />

          <div className='innovation-text-card'>{t('innovation.textCard')}</div>

          <InfoBox
            title={t('innovation.infoBoxTitle1')}
            text={t('innovation.infoBoxText1')}
            imgSrc={infoBox1ImgSrc}
            titleSize='sm'
          />

          <InnovationMedia innovationItems={innovationItems} />

          <BlogGroup
            blogs={innovationBlogs}
            title={t('innovation.moreAbout')}
            linkText={t('innovation.seeAllArticles')}
            noBgColor={true}
          />
        </div>
      </div>
    </>
  )
}

export default Innovation
