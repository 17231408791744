const koivu_p_sarja = {
  name: 'Koivu P-sarja',
  longDescription: `Kauniit Koivu-ruukut ja -tilanjakajat sulautuvat monenlaiseen 
      sisustukseen ja tuovat tilaan luonnon rauhaa ja harmonista tunnelmaa. 
      Näyttävimmän asetelman luot yhdistelemällä erimuotoisia ja -kokoisia 
      Koivu-tuotteita.
      \\n
      Koivu P-sarjan keskikokoiset ja pienet ruukut toimivat osana sisustusta 
      yksittäin sekä asetelmina. Ruukun voi valita tassuilla, renkailla tai 
      ilmavuutta tuovilla korkeilla jaloilla.
      \\n
      Koivuvanerin joustavuuden ansiosta ruukut ovat miltei saumattomia, ja 
      luonnollinen puunsyy tekee jokaiseen valmistettuun ruukkuun uniikin 
      pinnan. Koivuvaneri tekee ruukuista kevyitä, mutta myös vakaita ja lujia, 
      pitkäaikaista käyttöä ja siirtoa kestäviä.
      \\n
      Koivu-ruukut toimitetaan yhdessä 100 % kierrätysmuovista valmistettujen 
      Orthex-sisäruukkujen kanssa. Asiantuntijamme auttavat mielellään ruukkujen 
      koon, muodon, sävyn ja jalkavaihtoehtojen valinnan kanssa. Paikallinen 
      tuotantomme Suomessa mahdollistaa nopean toimituksen, joka on tilauksesta 
      n. 1-2 kuukautta.`,
  measurements: [
    {
      type: 'P-550:',
      values: [
        {
          value: 'Halkaisija 550 mm'
        },
        {
          value: 'Korkeus 400 mm (ilman jalkoja)'
        },
      ]
    },
    {
      type: 'P-445:',
      values: [
        {
          value: 'Halkaisija 445 mm'
        },
        {
          value: 'Korkeus 325 mm (ilman jalkoja)'
        },
      ]
    },
    {
      type: 'P-380:',
      values: [
        {
          value: 'Halkaisija 380 mm'
        },
        {
          value: 'Korkeus 270 mm (ilman jalkoja)'
        },
      ]
    },
    {
      type: 'P-380XL:',
      values: [
        {
          value: 'Halkaisija 380 mm'
        },
        {
          value: 'Korkeus 585 mm (ilman jalkoja)'
        },
      ]
    }
  ],
  materials: [
    {
      type: 'Runko:',
      value: 'Suomalainen koivuvaneri'
    },
    {
      type: 'Sisäruukku:',
      value: 'Orthex, 100 % kierrätysmuovi '
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi'
    },
    {
      type: 'Käyttö:',
      value: 'Sisätilat',
      icon: 'indoor'
    },
  ],
  wlt: {},
  malli: {
    description: `Valitse malli`
  },
  ruukun_vari: {
    description: `Näkyvien osien viimeistely`
  },
  jalat: {
    description: `Jalkavaihtoehdot`
  },
  kasviryhma: {
    description: `Ruukulle soveltuvat kasvit.
S ≈ 500 - 1000 mm korkeat kasvit.
M ≈ 1100 - 1600 mm korkeat kasvit.
L ≈ 1300 - 2000 mm korkeat kasvit.`
  },
}

export default koivu_p_sarja