const vertical_garden = {
  name: 'Vertical Garden',
  longDescription: `InnoGreen's outdoor green wall, the Vertical Garden, which uses rainwater, 
      represents a new kind of urban design that aims to increase well-being and provide sustainable 
      solutions for managing increasing stormwater runoff and supporting urban biodiversity. 
      It also helps to mitigate the heat island effect by cooling buildings and the immediate 
      surroundings of the green wall.
      \\n
      The Vertical Garden integrates into existing buildings and structures and its unique 
      irrigation technology collects and utilises rainwater from the site. The use of rainwater 
      for irrigation reduces the load on the sewage system, prevents the accumulation of 
      environmentally harmful substances in rainwater and saves potable water that would otherwise 
      be used for irrigation.
      \\n
      We plan the Vertical Garden's greenery together according to the client's wishes and the site's 
      characteristics. For outdoor green walls, we generally use both perennial and seasonal plants, 
      so that the overall effect is striking. We always offer a comprehensive maintenance service to 
      maintain the overall result. The maintenance service also includes any alterations required 
      during the winter and summer seasons.
      `,
  measurements: [
    {
      type: 'Fully customisable product.'
    },
  ],
  materials: [
    {
      type: 'Base:',
      value: 'Steel and concrete'
    },
    {
      type: 'Frame:',
      value: 'Pressure-treated wood'
    },
    {
      type: 'Connections:',
      value: 'Metal'
    },
    {
      type: 'Planting boxes:',
      value: 'Polyethylene'
    },
    {
      type: 'Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'Usage:',
      value: 'Outdoor',
      icon: 'outdoor'
    },
  ],
  wlt: {},
}

export default vertical_garden