import './styles.scss'

import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useMatches } from 'react-router-dom'

import HeaderBox from '../../components/HeaderBox'
import InfoCardGroup from '../../components/InfoCardGroup'
import InfoBox from '../../components/InfoBox'
import WLTCard from '../../components/WLTCard'
import ImageCard from '../../components/ImageCard'
import ProductGroup from '../ProductGroup'
import { Dashing, Sustainable, CheckMark } from '../../components/CardIcon'
import LoadingScreen from '../../components/common/LoadingScreen'

import infoBox1ImgSrc from '../../assets/images/GreenWall/viherseinat-1.jpg'
import infoBox2ImgSrc from '../../assets/images/GreenWall/viherseinat-2.jpg'
import imageCardImgSrc from '../../assets/images/GreenWall/viherseinat-3.jpg'
import infoBox3ImgSrc from '../../assets/images/GreenWall/viherseinat-4.jpg'
import infoBox4ImgSrc from '../../assets/images/GreenWall/viherseinat-5.jpg'

import { setGlobalLoading } from '../../store/uiSlice'

import { fetch_related_products_by_group } from '../../store/asyncThunk'

const cards = [
  {
    title: 'greenWall.infoCard1Title',
    body: [
      { text: 'greenWall.infoCard1Text1' },
      { text: 'greenWall.infoCard1Text2' },
      { text: 'greenWall.infoCard1Text3' },
    ],
    icon: <Dashing/>
  },
  {
    title: 'greenWall.infoCard2Title',
    body: [
      { text: 'greenWall.infoCard2Text1' },
      { text: 'greenWall.infoCard2Text2' },
      { text: 'greenWall.infoCard2Text3' },
    ],
    icon: <Sustainable/>
  },
  {
    title: 'greenWall.infoCard3Title',
    body: [
      { text: 'greenWall.infoCard3Text1' },
      { text: 'greenWall.infoCard3Text2' },
      { text: 'greenWall.infoCard3Text3' },
    ],
    icon: <CheckMark/>
  },
]

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 1450 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1450, min: 1110 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1110, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const GreenWall = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const dispatch = useDispatch()
  const global_loading = useSelector((state) => state.ui.global_loading)
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()

  const matches = useMatches()
  // Get the language from the route metadata
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  const related_products = {
    products: useSelector(
      (state) => state.product.related_products
    ),
    name: 'green_wall_page_related_products'
  }

  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      await dispatch(fetch_related_products_by_group({group_name:'Viherseinät'}))
      dispatch(setGlobalLoading(false))
    })()
  }, [pathname])

  if (global_loading) {
    return (
      <>
        <div className='green-wall-container'></div>
        <LoadingScreen />
      </>
    )
  }

  return (
    <div className='green-wall-container'>
      <div className='green-wall-body-container'>
        <HeaderBox
          titleText={t('greenWall.headerTitle')}
          pageText={t('greenWall.headerGroup').toUpperCase()}
          videoSrc='https://vimeo.com/1046355668'
          titleBackgroundColor='turquoise'
        />

        <InfoCardGroup cards={cards} />

        <InfoBox
          title={t('greenWall.infoBox1Title')}
          imgSrc={infoBox1ImgSrc}
          text={t('greenWall.infoBox1Text')}
        />

        <InfoBox
          imgSrc={infoBox2ImgSrc}
          reverse={true}
          text={t('greenWall.infoBox2Text')}
        />

        <WLTCard />

        <ImageCard imgSrc={imageCardImgSrc} alt='Green Wall Image'/>

        <InfoBox
          title={t('greenWall.infoBox3Title')}
          imgSrc={infoBox3ImgSrc}
          text={t('greenWall.infoBox3Text')}
        />
        
        <InfoBox
          imgSrc={infoBox4ImgSrc}
          reverse={true}
          text={t('greenWall.infoBox4Text')}
        />
        
        <ProductGroup
          group={related_products}
          responsive={responsive}
          link='products'
          linkText='greenWallPageLinkText'
        />
      </div>
    </div>
  )
}

export default GreenWall
