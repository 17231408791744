import React from "react"
import './styles.scss'

export const ArrowLeft = ({size = 16, color='black'}) => (
  <svg 
    id="uuid-031c3e3e-6507-4e1e-a5d7-d9d97c9120b8" 
    data-name="Layer 2" 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 41 41" 
    width={size} 
    height={size}
    stroke={color}
  >
    <g id="uuid-0819d5cb-5743-4d2e-b924-024a6a937318" data-name="Layer 1">
      <circle className="uuid-3cedb777-c9e8-4dfa-8258-3488c171c433" cx="20.5" cy="20.5" r="20"/>
      <polyline className="uuid-3cedb777-c9e8-4dfa-8258-3488c171c433" points="23.26 28.03 15.73 20.5 23.26 12.97"/>
    </g>
  </svg>
)

export const ArrowRight = ({size = 16, color='black'}) => (
  <svg 
    id="uuid-031c3e3e-6507-4e1e-a5d7-d9d97c9120b8" 
    xmlns="http://www.w3.org/2000/svg" 
    version="1.1" 
    viewBox="0 0 41 41" 
    width={size} 
    height={size}
    stroke={color}
  >
    <g id="uuid-0819d5cb-5743-4d2e-b924-024a6a937318">
      <circle className="cls-1" cx="20.5" cy="20.5" r="20"/>
      <polyline className="cls-1" points="17.7 13 25.3 20.5 17.7 28"/>
    </g>
  </svg>
)

export const ArrowShortUp = ({size = 16, color='black', className=''}) => (
  <svg 
    id="uuid-52f2ba7e-2e47-4a8d-9828-295bf35ad45f"
    data-name="Layer 2" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13.41 8.12"
    transform="rotate(180)"
    width={size}
    height={size}
    stroke={color}
    className={className}
  >
    <g id="uuid-2761a53f-2a80-4c6e-850c-fdd34393af64" data-name="Layer 1">
      <polyline className="uuid-6d95e68e-e3c6-4ec2-a88b-e14e0afaa392" points="12.71 .71 6.71 6.71 .71 .71"/>
    </g>
  </svg>
)

export const ArrowShortDown = ({size = 16, color='black', className=''}) => (
  <svg
    id="uuid-52f2ba7e-2e47-4a8d-9828-295bf35ad45f"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13.41 8.12"
    width={size}
    height={size}
    stroke={color}
    className={className}
  >
    <g id="uuid-2761a53f-2a80-4c6e-850c-fdd34393af64" data-name="Layer 1">
      <polyline className="uuid-6d95e68e-e3c6-4ec2-a88b-e14e0afaa392" points="12.71 .71 6.71 6.71 .71 .71"/>
    </g>
  </svg>
)

export const ArrowSimple = ({size = 16, color='black', className='', strokeWidth = 1}) => (
  <svg
    id="uuid-b272373a-cdc1-4e88-957b-c4c589e80b57"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12.42 11.27"
    width={size}
    height={size}
    stroke={color}
    className={className}
    strokeWidth={strokeWidth}
  >
    <g id="uuid-91e3073f-dfeb-4ffa-94c6-aeb5964ee56b" data-name="Layer 1">
      <path d="M6.8,11.27l-1.09-1.07,3.77-3.79H0v-1.56h9.48l-3.77-3.77,1.09-1.09,5.62,5.64-5.62,5.64Z"/>
    </g>
  </svg>
)

export const CheckMark = ({size = 16}) => (
  <svg
    id="uuid-ccbbfe69-68ea-4938-801d-acfa9fd2e9c8"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width={size}
    height={size}
  >
    <g id="uuid-c35e3e1f-54da-43ea-9a0a-889c97f10786" data-name="Layer 1">
      <circle className="uuid-9b7cecff-674a-4ce0-acd7-c4363cf24697" cx="15" cy="15" r="15"/>
      <polyline className="uuid-385ea7f3-55b0-486e-a606-f4cf88e2c573" points="21 11.15 13.3 18.85 9 14.56"/>
    </g>
  </svg>
)

export const CircleSelected = ({size = 16}) => (
  <svg
    id="uuid-6e293daf-97df-4855-8d08-9b8b6dd71b82"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
    width={size}
    height={size}
  >
    <g id="uuid-fe19224a-f640-4328-ad08-7194237564f9" data-name="Layer 1">
      <circle className="uuid-2f7585f9-4bb5-443d-a8f4-b5c6209c5ef2" cx="7.5" cy="7.5" r="7"/>
    </g>
  </svg>
)

export const CircleUnselected = ({size = 16}) => (
  <svg
    id="uuid-241f4ddc-b3db-4214-bf4f-cf62aaed44f7"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
    width={size}
    height={size}
  >
    <g id="uuid-81cd29e8-2f1b-4f47-a39a-e181654b4f39" data-name="Layer 1">
      <circle className="uuid-84958daf-d199-4d03-a234-6066a9888e8a" cx="7.5" cy="7.5" r="7"/>
    </g>
  </svg>
)

export const Close = ({size = 16, color='black'}) => (
  <svg
    id="uuid-ec241fdf-6af2-4998-bee0-28175a74a3ec"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26.12 26.12"
    width={size}
    height={size}
    stroke={color}
  >
    <g id="uuid-31fe6a6e-4787-47bd-b59e-d73ab9ae89cf" data-name="Layer 1">
      <g>
        <line className="uuid-e1e7784a-cefc-485f-aa47-5b2454343819" x1="25.06" y1="1.06" x2="1.06" y2="25.06"/>
        <line className="uuid-e1e7784a-cefc-485f-aa47-5b2454343819" x1="1.06" y1="1.06" x2="25.06" y2="25.06"/>
      </g>
    </g>
  </svg>
)

export const Download = ({size = 16}) => (
  <svg
    id="uuid-aa22d71d-ce44-472f-90fa-593cc5883e17"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9 13.4"
    width={size}
    height={size}
  >
    <g id="uuid-c46f5cfd-00a0-47bf-b5e1-1f292e75bbc7" data-name="Layer 1">
      <g>
        <g>
          <line className="uuid-025a2db4-e358-4596-a946-927174209af8" x1="4.5" y1="9.72" x2="4.5"/>
          <polygon points="8.59 6.41 7.86 5.72 4.5 9.34 1.14 5.72 .41 6.41 4.5 10.8 8.59 6.41"/>
        </g>
        <line className="uuid-025a2db4-e358-4596-a946-927174209af8" y1="12.9" x2="9" y2="12.9"/>
      </g>
    </g>
  </svg>
)

export const InDoor = ({size = 16, color='black'}) => (
  <svg
    id="uuid-e737360f-c1b3-48d6-9f13-4fc559bffba3"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 18.38"
    width={size}
    height={size}
    stroke={color}
  >
    <g id="uuid-c558566e-1757-49f2-bd0d-bd89c059e8f9" data-name="Layer 1">
      <g>
        <line className="uuid-fb2a9905-7405-466d-9f6c-52d77a992fff" x1="2.95" y1="17.88" x2="20.05" y2="17.88"/>
        <line className="uuid-fb2a9905-7405-466d-9f6c-52d77a992fff" x1="11.5" y1=".5" x2=".5" y2="8.45"/>
        <line className="uuid-fb2a9905-7405-466d-9f6c-52d77a992fff" x1="2.95" y1="6.68" x2="2.95" y2="17.88"/>
        <line className="uuid-fb2a9905-7405-466d-9f6c-52d77a992fff" x1="11.5" y1=".5" x2="22.5" y2="8.45"/>
        <line className="uuid-fb2a9905-7405-466d-9f6c-52d77a992fff" x1="20.05" y1="6.7" x2="20.05" y2="17.88"/>
        <polyline className="uuid-fb2a9905-7405-466d-9f6c-52d77a992fff" points="13.86 17.88 13.86 9.52 9.14 9.52 9.14 17.88"/>
      </g>
    </g>
  </svg>
)

export const OutDoor = ({size = 16, color='black'}) => (
  <svg
    id="uuid-cc8c26f8-eb35-42dd-8897-9874ad174ad0"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    width={size}
    height={size}
    stroke={color}
  >
    <g id="uuid-25883ede-a4d6-4332-bd1a-56e186333aff" data-name="Layer 1">
      <g>
        <circle className="uuid-abbaa7c6-fb04-4d40-b651-63e75638a760" cx="11.5" cy="11.5" r="5.04"/>
        <line className="uuid-abbaa7c6-fb04-4d40-b651-63e75638a760" x1="11.5" y1="4.6" x2="11.5" y2=".5"/>
        <line className="uuid-abbaa7c6-fb04-4d40-b651-63e75638a760" x1="6.62" y1="6.62" x2="3.72" y2="3.72"/>
        <line className="uuid-abbaa7c6-fb04-4d40-b651-63e75638a760" x1="4.6" y1="11.5" x2=".5" y2="11.5"/>
        <line className="uuid-abbaa7c6-fb04-4d40-b651-63e75638a760" x1="6.62" y1="16.38" x2="3.72" y2="19.28"/>
        <line className="uuid-abbaa7c6-fb04-4d40-b651-63e75638a760" x1="11.5" y1="18.4" x2="11.5" y2="22.5"/>
        <line className="uuid-abbaa7c6-fb04-4d40-b651-63e75638a760" x1="16.38" y1="16.38" x2="19.28" y2="19.28"/>
        <line className="uuid-abbaa7c6-fb04-4d40-b651-63e75638a760" x1="18.4" y1="11.5" x2="22.5" y2="11.5"/>
        <line className="uuid-abbaa7c6-fb04-4d40-b651-63e75638a760" x1="16.38" y1="6.62" x2="19.28" y2="3.72"/>
      </g>
    </g>
  </svg>
)

export const MenuClose = ({size = 16, className='', onClick}) => (
  <svg 
    id="uuid-810de1e1-5588-4ed8-a4d2-cc8eb510d7c1"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={size}
    height={size}
    className={className}
    onClick={onClick}
  >
    <g id="uuid-5fc302fe-7bbe-4f5b-9d73-ff908ded0b6e" data-name="Layer 1">
      <circle className="uuid-9fb96526-a90f-4c91-a4bd-b697f94aa5eb" cx="24" cy="24" r="24"/>
      <g>
        <line className="uuid-836f8830-3055-466d-b989-5aa47950b845" x1="32.5" y1="15.5" x2="15.5" y2="32.5"/>
        <line className="uuid-836f8830-3055-466d-b989-5aa47950b845" x1="15.5" y1="15.5" x2="32.5" y2="32.5"/>
      </g>
    </g>
  </svg>
)

export const MenuOpen = ({size = 16, className='', onClick}) => (
  <svg 
    id="uuid-f4e5a1e1-13f6-46c1-bf7a-8ec6a2b65180"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={size}
    height={size}
    className={className}
    onClick={onClick}
  >
    <g id="uuid-d7942a87-0898-4d04-95fd-9e02b91dfd52" data-name="Layer 1">
      <circle className="uuid-45ccc717-72ad-47ff-a228-76dfe8f5c088" cx="24" cy="24" r="24"/>
      <g>
        <line className="uuid-edaa6f02-a9a0-4724-8ea8-896b08e91864" x1="34" y1="30.1" x2="14" y2="30.1"/>
        <line className="uuid-edaa6f02-a9a0-4724-8ea8-896b08e91864" x1="34" y1="24" x2="14" y2="24"/>
        <line className="uuid-edaa6f02-a9a0-4724-8ea8-896b08e91864" x1="34" y1="17.9" x2="14" y2="17.9"/>
      </g>
    </g>
  </svg>
)

export const SearchIcon = ({size = 16, color='black'}) => (
  <svg
    id="uuid-a9eb7d52-99b1-4b8b-a387-3b3d78f401be"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.71 24.71"
    width={size}
    height={size}
    stroke={color}
  >
    <g id="uuid-deb291d3-a880-409c-bd4d-184e98d0c8b2" data-name="Layer 1">
      <circle className="uuid-2eb59806-933c-4c7f-8d05-576bf4f50202" cx="10" cy="10" r="9"/>
      <line className="uuid-2eb59806-933c-4c7f-8d05-576bf4f50202" x1="16.36" y1="16.36" x2="24" y2="24"/>
    </g>
  </svg>
)

export const CirclePlusIcon = ({size = 41, color='black', onClick}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 41 41"
    fill="none"
    stroke={color}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <g>
      <path className='uuid-dff81ba5-6e00-4f4c-8d0d-b2c0951c82c8' d="M20.5 40.5C31.5457 40.5 40.5 31.5457 40.5 20.5C40.5 9.4543 31.5457 0.5 20.5 0.5C9.4543 0.5 0.5 9.4543 0.5 20.5C0.5 31.5457 9.4543 40.5 20.5 40.5Z" />
      <path className='uuid-dff81ba5-6e00-4f4c-8d0d-b2c0951c82c8' d="M20.5 12.9697V28.0297" />
      <path className='uuid-dff81ba5-6e00-4f4c-8d0d-b2c0951c82c8' d="M12.9688 20.5H28.0288" />
    </g>

  </svg>
)

export const PlusIcon = ({size = 15, color='black', onClick}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path className="uuid-ca23c1eb-6c64-4323-8e5e-41533f116f39" d="M7 0.5V14.5" />
      <path className="uuid-ca23c1eb-6c64-4323-8e5e-41533f116f39" d="M0 7.5H14" />
    </g>
  </svg>
)

export const CircleMinusIcon = ({size = 41, color='black', onClick}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 41 41"
    fill="none"
    stroke={color}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <g>
      <path className='uuid-9de4684c-3072-41ce-b801-6bf4543c01a6' d="M20.5 40.5C31.5457 40.5 40.5 31.5457 40.5 20.5C40.5 9.4543 31.5457 0.5 20.5 0.5C9.4543 0.5 0.5 9.4543 0.5 20.5C0.5 31.5457 9.4543 40.5 20.5 40.5Z" />
      <path className='uuid-9de4684c-3072-41ce-b801-6bf4543c01a6' d="M12.9688 20.5H28.0288" />
    </g>
  </svg>
)

export const MinusIcon = ({size = 15, color='black', onClick}) => (
  <svg
    width={size}
    height={size}
    stroke={color}
    viewBox="0 0 14 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g >
      <path className='uuid-29ce893d-7e86-4032-adcb-8a13c8c5bf72' d="M0 1.5H14"/>
    </g>
  </svg>
)

export const AccordionClose = ({size = 16, color='white'}) => (
  <svg
    id="uuid-0b8c1e3e-ff89-497c-b23d-23fab003879b"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 44"
    width={size}
    height={size}
    stroke={color}
  >
    <g id="uuid-10531344-e5ec-4416-8e74-2aab3cc17f9e" data-name="Layer 1">
      {/* <circle cx="12.7285" cy="12.9999" r="9" transform="rotate(45 12.7285 12.9999)" fill="white"/>
      <g>
        <line x1="16.375" y1="16.6827" x2="9.17493" y2="9.48263" stroke="black"/>
        <line x1="16.2813" y1="9.48246" x2="9.08126" y2="16.6825" stroke="black"/>
      </g> */}
      <circle cx="22" cy="22" r="22" fill="white"/>
      <g>
      <line className="uuid-942eb191-d864-4b53-b1a6-e4dd28479ef4" x1="33.02" y1="22" x2="10.98" y2="22" transform="rotate(45 22 22)" stroke="black"/>
      <line className="uuid-942eb191-d864-4b53-b1a6-e4dd28479ef4" x1="22" y1="10.98" x2="22" y2="33.02" transform="rotate(45 22 22)" stroke="black"/>
      </g>
    </g>
  </svg>
)

export const AccordionOpen = ({size = 16, color='white'}) => (
  <svg
    id="uuid-39acb2c2-f5d4-4170-ba97-a8f73fc8f912"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 44"
    width={size}
    height={size}
    stroke={color}
  >
  <g id="uuid-ebc99bc7-b098-4b67-ac22-f723246e284f" data-name="Layer 1">
    <circle cx="22" cy="22" r="22"/>
    <g>
      <line className="uuid-942eb191-d864-4b53-b1a6-e4dd28479ef4" x1="33.02" y1="22" x2="10.98" y2="22"/>
      <line className="uuid-942eb191-d864-4b53-b1a6-e4dd28479ef4" x1="22" y1="10.98" x2="22" y2="33.02"/>
    </g>
  </g>
  </svg>
)

export const ArrowLeftLong = () => (
  <svg
    width="21"
    height="11"
    viewBox="0 0 21 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path className="uuid-e335c038-86f6-4ae0-8ac1-036cbce0d78e" d="M1.41016 5.50488H20.5202"/>
      <path className="uuid-d668e60e-3b33-48b7-a094-8fe6e3453c8f" d="M5.72 10.8151L6.6 9.86506L1.91 5.50506L6.6 1.13506L5.72 0.185059L0 5.50506L5.72 10.8151Z"/>
    </g>
  </svg>
)

export const ArrowShortCirleDown = ({size = 22}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect className="uuid-ba00ff20-5a40-4f81-8e6f-9adb66dec385" width={size} height={size} rx={size/2} />
    <path className="uuid-119d77b1-d611-4033-a510-d968db189f09" d={`M${size * 0.71} ${size * 0.42} L${size * 0.5} ${size * 0.64} L${size * 0.29} ${size * 0.42}`}/>
  </svg>
)

export const ArrowShortCirleUp = ({size = 22}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect className="uuid-7fc5d055-fb76-4cd3-9ede-c736af04752f" width={size} height={size} rx={size/2} />
    <path className="uuid-8e3a3b9e-f4d2-40ab-9a0c-9275c06a6972" d={`M${size * 0.29} ${size * 0.58} L${size * 0.5} ${size * 0.36} L${size * 0.71} ${size * 0.58}`}/>
  </svg>
)

export const Duplicate = ({size = 22, color='black',  onClick}) => (
  <svg
    id="uuid-cbbd31e7-10de-429a-88f4-82b63a513369"
    data-name="Layer 2"
    viewBox="0 0 25 25"
    width={size}
    height={size}
    onClick={onClick}
    stroke={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="uuid-13ba1c32-ca58-4ceb-a5b3-3a57dd61020e" data-name="Layer 1">
      <g>
        <line className="uuid-f4346bb8-dcb3-40c9-9974-2e59d3177c98" x1="10.3" y1="9.49" x2="10.3" y2="19.92"/>
        <line className="uuid-f4346bb8-dcb3-40c9-9974-2e59d3177c98" x1="5.08" y1="14.7" x2="15.51" y2="14.7"/>
      </g>
      <rect className="uuid-f4346bb8-dcb3-40c9-9974-2e59d3177c98" x=".5" y="4.91" width="19.59" height="19.59" rx="2.41" ry="2.41"/>
      <path className="uuid-f4346bb8-dcb3-40c9-9974-2e59d3177c98" d="M4.91,4.91v-2c0-1.33,1.08-2.41,2.41-2.41h14.78c1.33,0,2.41,1.08,2.41,2.41v14.78c0,1.33-1.08,2.41-2.41,2.41h-2"/>
    </g>
  </svg>
)

export const Delete = ({size = 22, color='black',  onClick}) => (
  <svg 
    id="uuid-ebf5d8b0-1389-49f6-b6d5-bad9f708869b"
    data-name="Layer 2"
    viewBox="0 0 24 25"
    width={size}
    height={size}
    onClick={onClick}
    stroke={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="uuid-8c4c8e06-5f10-4df2-a249-0f7b836e45a6" data-name="Layer 1">
      <path className="uuid-cf7ba262-25c1-49a2-bb9a-5789b4be8e05" d="M17.19,24.5H6.81c-1.03,0-1.88-.78-1.99-1.8L3,4.99h18l-1.82,17.71c-.1,1.02-.96,1.8-1.99,1.8Z"/>
      <line className="uuid-cf7ba262-25c1-49a2-bb9a-5789b4be8e05" y1="4.99" x2="24" y2="4.99"/>
      <path className="uuid-cf7ba262-25c1-49a2-bb9a-5789b4be8e05" d="M9.5.5h5c1.1,0,2,.9,2,2v2.49H7.5v-2.49c0-1.1.9-2,2-2Z"/>
      <line className="uuid-cf7ba262-25c1-49a2-bb9a-5789b4be8e05" x1="12" y1="8.53" x2="12" y2="20.28"/>
      <line className="uuid-cf7ba262-25c1-49a2-bb9a-5789b4be8e05" x1="7.5" y1="8.53" x2="8.41" y2="20.28"/>
      <line className="uuid-cf7ba262-25c1-49a2-bb9a-5789b4be8e05" x1="16.5" y1="8.53" x2="15.59" y2="20.28"/>
    </g>
  </svg>
)
