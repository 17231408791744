const sustainability = {
  headerGroup: 'Sustainability',
  headerTitle: 'InnoGreen and sustainability',
  textCard: `Our mission is to develop green solutions that improve the quality of life 
      in urban environments. Therefore, sustainable development principles and values 
      that emphasise nature and humaneness are the starting point for our business.`,
  infoBoxTitle1: `For us, sustainability is not just a choice, it is a way to fulfil 
      our mission. Our goal is not only to be the market leader in green services, 
      but also the most responsible partner and employer.`,
  infoBoxText1: `We do not strive for perfection, but to go one step further every 
      day. It is important to us that our big ambitions are translated into concrete 
      actions that are seen both by our employees and our customers.`,
  infoCard1Text: `Sustainability is at the core of our business, and it is our starting 
      point for developing products and services that meet the challenges of today and 
      tomorrow.`,
  infoCard2Text: `Together with our customers, we are building more sustainable, greener 
      and more welcoming cities.`,
  infoBoxTitle2: `InnoGreen's innovative green solutions bring nature into the urban 
      landscape and create tangible benefits for people and the environment. Sustainability 
      is reflected in our products and services - we develop solutions that help cities 
      adapt to climate change, improve the quality of life and support biodiversity.`,
  infoBoxText2: `We strive to ensure that the overall impact of our activities is 
      positive, i.e. that they bring more benefits than harm.
      \\n
      We use the Upright Project metrics to assess the social, environmental and economic 
      impacts of our business. For us, net positive means constantly striving to reduce 
      our environmental footprint and innovating new, more sustainable solutions.`,
  infoBoxTitle3: `Our activities are guided by the UN Sustainable Development Goals:`,
  infoBoxLink3: `https://www.ykliitto.fi/kestava-kehitys`,
  infoBoxLinkText3: `Learn about the UN's Sustainable Development Goals.`,
  infoBoxBullet1Title: `Decent work and economic growth (Goal 8)`,
  infoBoxBullet1Text: `We provide fair and meaningful jobs and create sustainable growth.`,
  infoBoxBullet2Title: `Sustainable cities and communities (Goal 11)`,
  infoBoxBullet2Text: `We help cities become more sustainable by adding more green spaces.`,
  infoBoxBullet3Title: `Life on land (Goal 15)`,
  infoBoxBullet3Text: `Protect rainforests and promote biodiversity through our products and services.`,
  cards: [
    {
      title: '38%',
      text: 'InnoGreen’s net impact ratio',
      linkSourceText: 'Source:',
      linkText: 'Upright Project',
      link: 'https://uprightplatform.com/company/8459bf39-ef8f-4875-bcce-161a950bda38/InnoGreen/?utm_source=profile_share_link&utm_medium=referral&utm_campaign=share',
    },
    {
      title: '2 000 000 m²',
      text: 'of protected rainforest together with <link>',
      linkText: 'World Land Trust',
      link: 'https://www.worldlandtrust.org/',
    },
    {
      title: '25+',
      text: 'Own product innovation',
    },
  ],
  ourSustainabilityText: 'Our acts for sustainability',
  ourSustainabilityCards: [
    {
      title: 'Sustainable green solutions',
      text: [
        'We mitigate the heat island effect, reduce noise and manage stormwater with the help of green walls and green furniture.',
        'We improve urban well-being and foster a sense of community by creating green meeting places.',
        'We design and manufacture most of the pots and green furniture we use ourselves, to ensure the origin and sustainability of our products.'
      ]
    },
    {
      title: 'We care for the nature',
      text: [
        "We protect the world's rainforests in partnership with the World Land Trust.",
        'We create green oases in the built environment to support the important work of pollinators.',
        'We choose the materials we use carefully to ensure our products are sustainable and avoid unnecessary waste. We only use real plants.'
      ]
    },
    {
      title: 'We promote the circular economy',
      text: [
        'We care for our plants regularly so that greenery can be enjoyed for years to come. We reinvest plants returned from rent to new locations.',
        'All our products, such as pots and planters, are available as a rental service, without the need for ownership.',
        'We take care of the maintenance and reuse of products and furniture throughout their life cycle. We recycle parts that cannot be reused.'
      ]
    }
  ],
  cardGroup: [
    {
      title: 'Carbon Footprint',
      text: [
        {
          text: `A carbon footprint measures the greenhouse gas emissions generated during a product’s lifecycle. It serves as an indicator of the product’s 
              impact on the climate and climate change.`
        },
        {
          text: `In carbon footprint assessments, the main sources of emissions are identified, and strategies to reduce them are explored, such as material 
              choices or alternative production methods. This information is used to further develop products.`
        },
        {
          text: `InnoGreen has calculated the carbon footprints of the Koivu products. Product-specific information is available in the downloadable product 
              sheets. Please visit the <Link> for more information.`,
          link: 'products',
          linkText: 'Products page',
          type: 1
        },
        {
          text: `The carbon footprints of the products were assessed using a calculation method compiled by <Link> in accordance with the Greenhouse 
              Gas Protocol Product Standard. The assessment considered the most common greenhouse gases generated during the upstream production of 
              product components and materials, assembly and installation, transportation, and waste management. Excluded from the calculation were 
              emissions related to the plants included in the products, their growing medium, maintenance, end-of-life disposal or recycling, carbon 
              sequestration, and potential water use.`,
          link: 'https://thirdrock.fi/en/',
          linkText: 'Third Rock',
          type: 2
        },
      ],
      show: 'Show more',
      hide: 'Show less',
      type: 3,
      title2: 'Comparison points:',
      text2: `The average daily carbon footprint of a Finnish individual: 26.3 kgCO2e`,
      text3: 'Sitra: <Link>, Updated December 22, 2023',
      link: 'https://www.sitra.fi/artikkelit/keskivertosuomalaisen-hiilijalanjalki/',
      linkText: 'Average Carbon Footprint of a Finn'
    },
  ]
}

export default sustainability