import './styles.scss'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ArrowSimple } from '../../Icons'
import { useSelector } from 'react-redux'

const CustomCard = (props) => {
  const {
    id,
    type,
    referenceGroup,
    productGroup,
    imgSrc,
    text,
    title,
    text_en = '',
    title_en = '',
    date,
    url,
    onClickHandler
  } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const language = useSelector((state) => state.user.language)

  const formatBlogCardText = (text) => {
    // Update the string
    let updatedText = text
      .replace(/<strong(.*?)>/, '') // Remove opening <strong> tag
      .replace(/<\/strong>/, '')   // Remove closing </strong> tag
      .replace(/<span(.*?)>/, '') // Remove opening <span> tag
      .replace(/<\/span>/, '')   // Remove closing </span> tag
      .replace(/<blockquote(.*?)>/, '') // Remove opening <blockquote> tag
      .replace(/<\/blockquote>/, '')   // Remove closing </blockquote> tag
      .replace(/<a [^>]*>(.*?)<\/a>/g, '$1'); // remove all <a> tags
    return updatedText
  }

  const joinReferenceGroups = () => {
    let reference = ''
    let product = ''

    reference = referenceGroup.map(item => t(item.translate)).join(' | ').toUpperCase()
    product = productGroup.map(item => t(item.translate)).join(' | ').toUpperCase()

    if (reference && product) {
      return reference + ' | ' + product
    }
    if (reference && !product) {
      return reference
    }
    if (!reference && product) {
      return product
    }
  }

  const getTranslatedTitle = () => {
    if (language) {
      if (language === 'FI') {
        return title
      } else if (language === 'EN') {
        if (title_en) {
          return title_en
        } else {
          return title
        }
      }
    } else {
      return title
    }
  }

  const getTranslatedText = () => {
    if (language) {
      if (language === 'FI') {
        return text
      } else if (language === 'EN') {
        if (text_en) {
          return text_en
        } else {
          return text
        }
      }
    } else {
      return text
    }
  }

  if (type === 'reference') {
    return (
      <div className='custom-card-reference-container' onClick={onClickHandler}>
        <div className='custom-card-reference-image'>
          <img src={imgSrc} alt={title + ' Image'} />
        </div>
        <div
          className='custom-card-reference-text-container'
        >
          <div
            className='custom-card-reference-text-type'
          >
            {joinReferenceGroups()}
          </div>
          <div
            className='custom-card-reference-text-title'
            title={getTranslatedTitle()}
          >
            {getTranslatedTitle()}
          </div>
        </div>
      </div>
    )
 } else if (type === 'blog') {
    return (
      <div className='custom-card-blog-container' onClick={onClickHandler}>
        <div className='custom-card-image'>
          <img src={imgSrc} alt={title + ' Image'} />
        </div>
        <div className='custom-card-blog-text-container'>
          <div className='custom-card-blog-text-wrapper'>
            <div className='custom-card-blog-text-date'>{date}</div>
            <div className='custom-card-blog-text-title'>{getTranslatedTitle()}</div>
            <div className='custom-card-blog-text' dangerouslySetInnerHTML={{ __html: formatBlogCardText(getTranslatedText()) }}></div>
          </div>
          <Link className='custom-card-blog-link no-select'
            to={url}
          >
            {t('blog.continue')} &nbsp; <ArrowSimple size={14} strokeWidth={0.1}/>
          </Link>
        </div>
      </div>
    )
 }
}


export default CustomCard
