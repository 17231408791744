const home = {
  videoText: 'Green is Good.',
  infoBoxTitle1: 'More nature!',
  infoBoxText1: `With InnoGreen's products, it is easy to create green 
      spaces that support well-being, community and urban nature. In our 
      collection you'll find Finnish, versatile pots, space dividers, 
      green walls, green panels and green furniture. Start designing 
      your new space with our tool!`,
  infoBoxTitle2: 'Rent greenery without compromises',
  infoBoxText2: `We want to make your comfort as easy and sustainable 
      as possible. Greenery as a service includes everything you need 
      for a green space: design, products, plants, installation and 
      maintenance.`,
  referenceTitle: 'References',
  infoBoxTitle3: 'InnoGreen and sustainability',
  infoBoxText3: `Our mission is to develop green solutions that improve 
      the quality of life in urban environments. Therefore, sustainable 
      development principles and values that highlight both nature and 
      humaneness are the starting point for our business.`,
  infoBoxTitle4: 'Personalised solutions for demanding applications.',
  infoBoxText4: `Over the years, we have created hundreds of stunning, unique projects 
      and are happy to take on any creative challenge. The green implementation of 
      every special project starts with a green design.`,
  infoBoxTitle5: 'How can we help you?',
  infoBoxText5: `We provide expert help in designing and implementing green solutions.`,
}

export default home