import './styles.scss'
import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle, faCircle } from '@fortawesome/free-solid-svg-icons'

const CustomHeaderImageInput = (props) => {
  const {
    label,
    id,
    onContentChange,
    imageSrc = null
  } = props
  const [image, setImage] = useState(imageSrc)
  const inputRef = useRef(null)
  const fileInputRef = useRef(null)
  const [initialImageLoaded, setInitialImageLoaded] = useState(false)

  const handleClick = () => {
    fileInputRef.current.click()
  }

  useEffect(() => {
    if (!initialImageLoaded && imageSrc) {
      setImage(imageSrc);
      setInitialImageLoaded(true);
    }
  }, [imageSrc]) // Depend on selected_options

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      setInitialImageLoaded(true)
      const previewUrl = URL.createObjectURL(file) // Create a preview URL for the uploaded file
      setImage(previewUrl)
      onContentChange('imgSrc', file, 'headerImg')
    }
  }

  const handleRemoveImage = (e) => {
    e.stopPropagation()
    setImage(null)
    onContentChange('imgSrc', null)
  }

  return (
    <div
      className={image ? 'custom-image-header-input-has-image' : 'custom-image-header-input-container'}
    >
      {image ? (
        <div className='custom-image-header-input-image-preview' onClick={() => handleClick()}>
          <img src={image} alt='Uploaded' className='custom-image-header-input-image-uploaded' />
          <div className='custom-image-header-input-image-remove-btn fa-layers fa-fw' onClick={(e) => handleRemoveImage(e)}>
            <FontAwesomeIcon size='xl' icon={faCircle} className='custom-image-header-input-icon-circle'/>
            <FontAwesomeIcon size='2xl' icon={faXmarkCircle} className='custom-image-header-input-icon-x' />
          </div>
        </div>
      ) : (
        <div className='custom-image-header-input-image-placeholder' onClick={() => handleClick()}>
          {label}
        </div>
      )}
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => handleFileChange(e)}
        accept='image/*'
      />
    </div>
  )
}

export default CustomHeaderImageInput