const customSolution = {
  headerGroup: 'Tailored solutions',
  headerTitle: 'Unique solutions for demanding locations',
  infoBoxTitle1: 'Check out the range of pots produced on-demand',
  infoBoxText1: `In addition to the plants, an important part of planning your 
      greenery is the choice of pots. The pots can be spectacular or elegantly 
      neutral to match the desired look. Above all, the pot's role is to 
      provide a long-term and safe home for the plant.
      \\n
      We are as particular about the pots as we are about the plants. A suitable 
      pot is properly waterproofed, sturdy and large enough for the plant to 
      live and flourish. We also want to ensure that the pots we use meet high 
      quality criteria and are available quickly and reliably.
      \\n
      In our implementations, we prefer to use primarily domestic Koivu pots, 
      which are a more durable alternative to pots made abroad. If the style of 
      our Koivu pots does not match your vision at the moment, you can 
      take a look at the range of pots available from our partners.
      \\n
      It is a range of pots put together in a Nordic collaboration, combining 
      Nordic design, safety and durability. Most of the pots are in temporary 
      storage in Denmark, from where delivery to Finland takes a couple of 
      weeks at the quickest.`,
  infoBoxButtonText1: 'Download the pots catalogue',
  textCard1: `Over the years, we have created hundreds of stunning, unique projects 
      and are happy to take on any creative challenge. The green implementation of 
      every special project starts with a green design.`,
  infoBoxTitle2: `In the green design process, we inspire and brainstorm together, 
      and refine the vision to its perfection. Our green design professionals will 
      ensure that the end result is top-notch and on budget.`,
  infoBoxText2: `The green design is tailored to the client's wishes and space. We 
      take into account a wide range of factors, from architecture to interior 
      design choices to the amount and direction of the light. A solution is 
      created that is personal and fits the space perfectly, something that cannot 
      be achieved with free plans on the internet. For example, it is important to 
      consider which plants will thrive on the premises. A careful green design 
      not only benefits the client, but also the contractors and architects. At 
      the same time, maintenance options are considered together, which keeps 
      costs down and results in longlasting greenery.
      \\n
      To achieve the most impressive and refined result possible, a green plan 
      should be drawn up well ahead as the design work progresses. This will also 
      benefit the architects and contractors.`,
  infoBoxTitle3: `Green design includes:`,
  infoBoxBullet1: 'Recommendations for plant selection, taking into consideration the space, care and maintenance',
  infoBoxBullet2: 'Plant placement: use of space, branding, ecology and atmosphere',
  infoBoxBullet3: 'The right balance of pots and green plants, including the suitable size of pots and plants, safety and suitability for the space',
  infoBoxBullet4: 'Implementation plan, work instructions and, if necessary, drawings for tailor-made solutions',
  infoBoxBullet5: 'You will also get new ideas, feedback and support for your vision.',
  infoBoxBullet6: `The green design includes an initial mapping, a draft proposal, a 
      final implementation plan and a work description of the project to be carried out. 
      With a comprehensive plan, you are able to ask for an accurate cost estimate for 
      the implementation of the green interior.`,
}

export default customSolution