const raataloitava_sammalseina = {
  name: 'Räätälöitävä Sammalseinä',
  longDescription: `Huolto- ja kasteluvapaa sammal ja jäkälä ovat 
      vaativiinkiin tiloihin sopiva luontoa ja metsän tunnelmaa 
      huokuva sisustuselementti. Käytämme tauluissa, installaatioissa 
      ja muissa räätälöidyissä toteutuksissamme stabiloitua, 
      biohajoavaa sammalta ja jäkälää. 
      \\n
      Stabiloiduilla kasveilla tarkoitetaan preservoituja, eli ikään 
      kuin säilöttyjä, kasveja. Stabiloinnissa hyödynnetään glyseriiniä, 
      ja käsittelystä huolimatta kasvit ovat kierrätettäviä ja 
      biohajoavia. Sen avulla voidaan säilöä aitoja kasveja ja tuoda 
      ne vihertämään olosuhteiltaan haastavampia tiloja. Stabiloidut 
      sammaleet, jäkälät ja kasvit säilyvät muodossaan ja kauniin 
      värisinä jopa vuosia..
      \\n
      Sammalseinään voidaan kiinnittää stabiloituja kasveja tai lehtiä 
      ja muita luonnon elementtejä tuomaan kolmiulotteisuutta. Sammaleen 
      alle voidaan leikata minkä muotoinen pohja tahansa, mikä 
      mahdollistaa mm. kirjaimien, logon tai vaikkapa maailmankartan 
      piirtämisen seinälle. Aitojen kasvien vaatimaa valoa voidaan 
      tuoda pimeäänkin tilaan, mutta sammaleesta ja stabiloiduista 
      kasveista tehdyt seinät ovat hyvä vaihtoehto, kun vihreyttä 
      halutaan esimerkiksi kattoon tai muutoin hyvin korkealle niin, 
      että viherkasvihuollon järjestäminen turvallisesti olisi hankalaa. 
      Sammalseinät eivät vaadi valonlähdettä, mutta oikein valaistuina 
      ne nousevat oikeuksiinsa.`,
  measurements: [
    {
      type: 'Räätälöidyt seinät voidaan toteuttaa täysin asiakkaan toivomien mittojen ja muotojen mukaisesti',
      value: ''
    }
  ],
  materials: [
    {
      type: 'Seinän sisältö:',
      value: 'Huoltovapaa stabiloitu sammal tai jäkälä (EU)'
    },
    {
      type: 'Pohjamateriaali:',
      value: 'Puristettu puukuitulevy '
    },
    {
      type: 'Kasvit:',
      value: 'Suomi ja EU'
    },
    {
      type: 'Käyttö:',
      value: 'Sisätilat',
      icon: 'indoor'
    },
  ],
  wlt: {},
  configuration: [
    {
      type: 'räätälöitävä_sammalseinä',
      configType:'width',
      text: 'Sammalseinän leveys (mm)',
      valueText: 'Leveys',
    },
    {
      type: 'räätälöitävä_sammalseinä',
      configType:'height',
      text: 'Sammalseinän korkeus (mm)',
      valueText: 'Korkeus',
    },
    {
      type: 'räätälöitävä_sammalseinä',
      configType:'custom',
      text: '',
      valueText: '',
    }
  ],
  leveys: {
    description: `Sammalseinän leveys`
  },
  korkeus: {
    description: `Sammalseinän korkeus`
  }, 
  kustomointi: {
    description: `Tarkempi kuvaus muodosta ja käytettävistä materiaaleista.`
  },
}

export default raataloitava_sammalseina