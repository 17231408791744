const wellBeing = {
  headerGroup: 'SE Vihreydestä hyvinvointia',
  headerTitle: 'SE Kasvien avulla vehreämpiä, kestävämpiä ja hyvinvoivempia kaupunkeja.',
  infoCard1Title: 'SE Parempi elämänlaatu',
  infoCard1Text1: 'SE Vähemmän stressiä, enemmän palautumista',
  infoCard1Text2: 'SE Lisää luovuutta ja tuottavuutta',
  infoCard1Text3: 'SE Viihtyisiä tiloja ja vahvempi luontoyhteys',
  infoCard2Title: 'SE Tulevaisuuden ratkaisuja',
  infoCard2Text1: 'SE Hulevesien hallintaa kasvillisuudella',
  infoCard2Text2: 'SE Viilentävää vehreyttä kaupunkiympäristöön',
  infoCard2Text3: 'SE Kukkia ja luontokäytäviä pölyttäjille',

  infoBox1Title: 'SE Vihreys vaikuttaa hyvinvointiimme',
  infoBox1Text: `SE Kasvit parantavat tilan esteettistä vetovoimaa, oli kyse sitten kodista, 
      työpaikasta tai kaupungista. Tutkimukset osoittavat, että vehreät ympäristöt, kuten 
      viheralueet tai kasvien läsnäolo sisätiloissa, parantavat mielialaa ja ylläpitävät 
      luontoyhteyttä. Kasvit lisäävät tilojen viihtyisyyttä ja parantavat merkittävästi 
      hyvinvointiamme vähentämällä stressiä ja lisäämällä keskittymiskykyä ja tuottavuutta.
      \\n
      Tutkimuksen mukaan kasvien läsnäolo työtiloissa lisää työntekijöiden luovuutta ja 
      keskittymiskykyä. Tämä johtaa parempaan työtyytyväisyyteen ja vähäisempään 
      työntekijöiden vaihtuvuuteen. Luonnonläheiset ympäristöt luovat miellyttävän tunnelman, 
      mikä kohentaa tutkitusti työntekijöiden mielialaa ja motivaatiota sekä antaa 
      asiakkaille positiivisen ensivaikutelman. Näin ollen vihersisustuksen tuomat hyödyt 
      näkyvät suoraan yrityksen toiminnassa ja tuloksissa.`,
  infoBox2Text: `SE Urbaani vihreys, kuten viherkasvit sisätiloissa tai kutsuva kausikasveissa 
      hehkuva sisäänkäynti voivat edistää myyntiä. Tutkimukset osoittavat, että asiakkaat 
      viihtyvät paremmin ja tuntevat olonsa rentoutuneemmaksi ympäristöissä, joissa on 
      kasveja ja luonnonelementtejä. Tämä lisää halukkuutta viipyä pidempään ja tutustua 
      laajemmin palvelu- tai tuotevalikoimaan, mikä voi johtaa suurempiin ostoksiin.
      \\n
      Vehreät ja houkuttelevat sisäänkäynnit ja ulkoalueet voivat lisätä kävijämääriä ja 
      pidentää asiakkaiden vierailuaikaa esimerkiksi kauppakeskuksissa. Lisäksi asiakkaat, 
      jotka kokevat myymälän tai kauppakeskuksen ympäristön miellyttäväksi ja rentouttavaksi, 
      palaavat todennäköisemmin uudelleen. Vehreys voi luoda positiivisia mielikuvia ja 
      parantaa sitoutumista brändiin. Hyvin hoidettu ja viihtyisä ympäristö viestii myös 
      yrityksen arvoista, mikä vahvistaa luottamusta sekä työntekijöiden että asiakkaiden 
      keskuudessa.`,
  textCard1Title: 'SE Lataa Minä Viihdyn -oppaamme!',
  textCard1Text: 'SE Tutustu tarkemmin vihreyden hyötyihin ilmaisten oppaidemme avulla.',

  infoBox3Title: 'SE Urbaani vihreys on yhteinen velvollisuus',
  infoBox3Text: `SE Vehreät kaupunkiympäristöt eivät ole vain esteettisesti miellyttäviä; 
      ne ovat myös tärkeitä ekologisesti, sosiaalisesti ja taloudellisesti.
      \\n
      Hyvin suunniteltu urbaani vihreys tarjoaa luonnollisia elinympäristöjä monille 
      lajeille, kuten linnuille ja pölyttäjille. Tämä tukee kaupunkibiodiversiteettiä 
      ja edistää ekosysteemien tasapainoa. Luonnon monimuotoisuus voi parantaa 
      viheralueiden kestävyyttä ja vähentää tarvetta kemiallisille torjunta-aineille 
      ja lannoitteille.
      \\n
      Kasvit myös sitovat hiilidioksidia ja muita ilmansaasteita, mikä parantaa 
      kaupunkien ilmanlaatua ja vähentää kasvihuonekaasujen määrää ilmakehässä. 
      Lisäksi modernit, ekologisesti suunnitellut viherratkaisut, kuten hulevettä 
      hyödyntävät viherseinät, voivat parantaa hulevesien hallintaa, mikä vähentää 
      tulvariskiä ja parantaa vesistöjen laatua. Hulevesien hallinta on erityisen 
      tärkeää kaupungeissa, joissa kovat pinnat estävät veden luonnollisen imeytymisen 
      maaperään. Kasvillisuus ja viheralueet voivat auttaa suodattamaan ja varastoimaan 
      sadevettä, mikä vähentää viemäriverkoston kuormitusta.`,
  infoBox4Text: `SE Viherratkaisujen avulla voidaan myös hillitä kaupunkien lämpösaarekeilmiötä. 
      Lämpösaarekeilmiö johtuu pääosin rakennusten, teiden ja muiden pintojen lämpöä 
      varastoivista ominaisuuksista, jotka nostavat kaupunkien lämpötilaa. Kasvillisuuden 
      lisääminen kaupunkialueille voi auttaa viilentämään ilmaa ja vähentämään 
      energiankulutusta, sillä viheralueet tarjoavat varjoa ja viilentävät ympäristöään 
      haihduttamalla vettä.
      \\n
      Yritykset, yhteisöt ja kaupungit, jotka investoivat vihreyteen, viestivät vahvasti 
      ympäristövastuustaan. Vehreät tilat, olivat ne sitten sisällä tai ulkona, ovat 
      näkyvä osoitus sitoutumisesta kestävään kehitykseen ja ympäristönsuojeluun. 
      Esimerkiksi yritykset, jotka hyödyntävät moderneja viherratkaisuja, kuten viherkattoja 
      ja hulevesiseiniä, voivat osaltaan vaikuttaa ympäristön kuormittamisen 
      vähentämiseen sekä edistää biodiversiteettiä kaupunkiympäristössä. Nämä viherratkaisut 
      eivät ainoastaan paranna ympäristön tilaa, vaan ne voivat myös tuoda taloudellisia 
      hyötyjä, kuten säästää energiaa ja juomakelpoista vettä. Lisäksi vihreät tilat voivat 
      lisätä kiinteistöjen arvoa ja houkutella uusia asiakkaita ja työntekijöitä, mikä 
      parantaa yritysten kilpailukykyä. Ulkotilat, joissa on miellyttäviä istuinalueita j
      a varjoisia paikkoja, tarjoavat paikkoja kohtaamiseen ja rentoutumiseen, mikä parantaa 
      kokonaisvaltaista hyvinvointia ja tukee yhteisöllisyyttä urbaanissa ympäristössä.`,
  appTitle: 'SE Lataa Minä Viihdyn -oppaamme!',
  appText: 'SE Tutustu tarkemmin vihreyden hyötyihin ilmaisten oppaidemme avulla.',
  appBodyTitle: 'SE Lataa oppaat täyttämällä lomake!',
  appBodyText: 'SE Opas lähetetään antamaasi sähköpostiin. Täyttämällä lomakkeen hyväksyt InnoGreenin markkinointirekisterin',
  appBodyLink: 'SE ehdot.',
  appBodyBullet1Title: 'SE Osa 1: Työhyvinvoinnilla lisää tehoa arkeen:',
  appBodyBullet1Text1: 'SE Miksi työhyvinvointiin tulisi investoida',
  appBodyBullet1Text2: 'SE Mitä tarkoittaa työntekijäkokemus',
  appBodyBullet1Text3: 'SE Millainen vaikutus tilasuunnittelulla ja vihersisustuksella on työntekijöihin',
  appBodyBullet1Text4: 'SE Ensimmäiset 5 askelta kohti parempaa työviihtyvyyttä',
  appBodyBullet2Title: 'SE Osa 2: Vihreydellä lisää myyntiä:',
  appBodyBullet2Text1: 'SE Miten hyvinvointi vaikuttaa yrityksesi tulokseen',
  appBodyBullet2Text2: 'SE Miten suunnittelet myyntiä tukevan tilan',
  appBodyBullet2Text3: 'SE Miten saat parhaimmat hyödyt vihersisustuksesta',
  appBodyBullet2Text4: 'SE Mikä merkitys viihtyisyydellä on ostokäyttäytymisessä',
  cardGroup: [
    {
      title: 'SE Hulevesi',
      text: `SE Hulevedellä tarkoitetaan sade- ja sulamisvesiä, jotka virtaavat kaduilta 
          viemäreihin. Hulevesi on ilmiö erityisesti kaupungeissa, joissa kovia pintoja on 
          runsaasti vettä imevien pintojen sijasta. Riippuen viemäriverkoston iästä, liittyvät 
          hulevedet joko jätevesiin tai niille on omat hulevesiverkostot, joilla pyritään 
          vähentämään jätevedenpuhdistamoiden kuormaa. Hulevesi ei ole ongelmaton ilmiö, 
          sillä veteen kertyy ainesta, kuten tiesuolaa, raskasmetalleja sekä mikromuovia. 
          Huleveden viemäristöön päätymiseen halutaan kiinnittää huomiota, koska osa aineksesta 
          päätyy puhdistuksesta huolimatta vesistöihin. Hulevedessä on kuitenkin valtavasti 
          potentiaalia hyötykäyttöön. Kasvien kastelussa se säästää vettä, vähentää 
          viemäristön kuormitusta ja estää vesistöjen saastumista.`,
      show: 'SE Näytä lisää',
      hide: 'SE Näytä vähemmän',
      type: 1
    },
    {
      title: 'SE Lämpösaarekeilmiö',
      text: `SE Kaupunkien rakennukset, tiet ja muut kovat pinnat varastoivat lämpöä, mikä nostaa 
          kaupunkien lämpötilaa ympäröiviä maaseutualueita korkeammaksi. Tämä ilmiö tunnetaan 
          lämpösaarekeilmiönä ja se pahenee erityisesti helleaaltojen aikana. Kasvillisuus 
          kaupungeissa voi auttaa viilentämään ilmaa haihduttamalla vettä ja tarjoamalla 
          varjoa, mikä vähentää ilmastointikustannuksia. Viherkatot, puistot ja muut 
          viherratkaisut ovat tehokkaita keinoja lämpösaarekeilmiön vaikutusten 
          lieventämisessä. Lisäämällä vihreyttä kaupunkialueilla voidaan parantaa kaupunkien 
          asukkaiden elämänlaatua ja vähentää ilmastonmuutoksen vaikutuksia.`,
      linkText: 'SE Näytä lisää',
      hide: 'SE Näytä vähemmän',
      type: 1
    },
    {
      title: 'SE Kaupunkibiodiversiteetti',
      text: `SE Kaupunkibiodiversiteetti tarkoittaa eri eliölajien monimuotoisuutta kaupunkialueilla, 
          kuten puistoissa, viherkatoilla ja muilla vihreillä alueilla. Monimuotoiset 
          viherratkaisut tarjoavat elinympäristöjä pölyttäjille, linnuille ja muille eläimille, 
          mikä tukee ekosysteemien tasapainoa. Kaupunkibiodiversiteetti parantaa myös kaupunkien 
          kestävyyttä vähentämällä kemikaalien tarvetta ja parantamalla hulevesien hallintaa. 
          Ekologisesti suunnitellut viherratkaisut lisäävät asukkaiden yhteyttä luontoon ja parantavat 
          heidän hyvinvointiaan. Kaupunkibiodiversiteetti on tärkeä osa kestävää kaupunkikehitystä 
          ja ympäristönsuojelua.`,
      show: 'SE Näytä lisää',
      hide: 'SE Näytä vähemmän',
      type: 1
    },
    {
      title: 'SE Lähteet',
      text: `Berland, A., Shiflett, S. A., Shuster, W. D., Garmestani, A. S., Goddard, H. C., Herrmann, D. 
          L., & Hopton, M. E. (2017). The role of trees in urban stormwater management. 
          **Landscape and Urban Planning, 162**, 167-177.
          \\n
          Bianchini, F., & Hewage, K. (2012). How “green” are the green roofs? Lifecycle analysis of green 
          roof materials. **Building and Environment, 48**, 57-65.
          \\n
          Bringslimark, T., Hartig, T., & Patil, G. G. (2007). Psychological benefits of indoor plants in 
          workplaces: Putting experimental results into context. **HortScience, 42**(3), 581-587.
          \\n
          Elings, M. (2006). People-plant interaction: The physiological, psychological, and sociological 
          effects of plants on people. In **Hassink, J., & Van Dijk, M. (Eds.), Farming for Health** (pp. 43-55). Springer.
          \\n
          Elmqvist, T., et al. (2013). Urbanization, Biodiversity and Ecosystem Services: Challenges and Opportunities. Springer.
          \\n
          Getter, K. L., & Rowe, D. B. (2006). The role of extensive green roofs in sustainable development. **HortScience, 41**(5), 1276-1285.
          \\n
          Hall, C., & Dickson, M. (2011). Economic, environmental, and health/well-being benefits associated 
          with green industry products and services: A review. **Journal of Environmental Horticulture, 29**(2), 96-103.
          \\n
          Lohr, V. I., Pearson-Mims, C. H., & Goodwin, G. K. (1996). Interior plants may improve worker productivity and 
          reduce stress in a windowless environment. **Journal of Environmental Horticulture, 14**(2), 97-100.
          \\n
          McDonald, R. I., Marcotullio, P. J., & Güneralp, B. (2013). Urbanization and global trends in biodiversity and 
          ecosystem services. In **Elmqvist, T. et al. (Eds.), Urbanization, Biodiversity and Ecosystem Services: Challenges 
          and Opportunities** (pp. 31-52). Springer.
          \\n
          Nowak, D. J., Crane, D. E., & Stevens, J. C. (2010). Air pollution removal by urban trees and shrubs in the United 
          States. **Urban Forestry & Urban Greening, 4**(3-4), 115-123.
          \\n
          Ulrich, R. S. (1984). View through a window may influence recovery from surgery. **Science, 224**(4647), 420-421.
          \\n
          Wolf, K. L. (2005). Business district streetscapes, trees, and consumer response. **Journal of Forestry, 103**(8), 396-400.`,
      show: 'SE Näytä lisää',
      hide: 'SE Näytä vähemmän',
      type: 2
    },
  ],
  guideDownloadSuccessMessage: 'Thank you, form received. The guide download links can be found in your email. Please also check your spam folder.',
  genericDownloadRequestError: 'Failed to request, please try again!',
}

export default wellBeing