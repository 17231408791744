const outDoor = {
  headerGroup: 'SE Ulkoalueet',
  headerTitle: 'SE Vehreitä ratkaisuja haastavaan kaupunkiympäristöön.',
  infoCard1Title: 'SE Joustavat ja monipuoliset ratkaisut',
  infoCard1Text1: 'SE Räätälöidyt monivuotiset tai kausiluontoiset ratkaisut',
  infoCard1Text2: 'SE Kokonaisvaltainen palvelu sisältää kaiken suunnittelusta ylläpitoon',
  infoCard1Text3: 'SE Helposti vuokrattavat kalusteet ja vihreys',
  infoCard2Title: 'SE Luonnonmukaista vehreyttä kaupunkiin',
  infoCard2Text1: 'SE Kotimaiset, luonnonvaraiset ja monivuotiset kasvilajit',
  infoCard2Text2: 'SE Viihtyisiä alueita myös pölyttäjille',
  infoCard2Text3: 'SE Luontokäytävät urbaanissa ympäristössä',
  infoBoxTitle1: 'SE Luonnonmukaisia ja toimivia ulkoalueita',
  infoBoxText1: `SE Luonnonmukaiset ulkoalueet, niityt, ulkoviherseinät sekä urbaanin ympäristön 
      vihreys ovat erikoisosaamistamme. Suunnittelemme ja ratkaisemme mahdolliset ulkotilan 
      pulmat ja toteutamme toiveet alusta loppuun. Huollamme ja ylläpidämme ulkoalueita 
      ympärivuotisesti, ja palveluumme kuuluu jokaisen käynnin yhteydessä toimitettava raportti.
      \\n
      Turvallisesti ja järkevästi rakennetusta pihasta on iloa pitkään, ja tavoitteenamme on 
      luoda toimivia ulkoalueita, jotka ovat näyttäviä sekä luonnonmukaisia ja ystävällisiä 
      pölyttäjille. Hyvin suunniteltu piha on vaivaton ylläpitää ja se kestää aikaa säilyttäen 
      loistonsa.
      \\n
      Ulkoalueille voidaan luoda erilaisia funktioita, kuten alueita rauhoittumiselle, 
      työskentelylle tai ajanviettoon. Otamme huomioon muun muassa materiaalit, kasvivalinnat, 
      kulun ohjauksen, pihan hoidon ja huollon sekä valaistuksen. Pyrimme tekemään 
      ulkoalueesta turvallisen, ympärivuotisen, viihtyisän ja helppohoitoisen, jotta se 
      palvelee käyttäjiään parhaalla mahdollisella tavalla. Kutsu meidät tutustumiskäynnille!`,
  infoBoxTitle2: 'SE Kutsuvaa vehreyttä',
  infoBoxText2: `SE Kausikasvit luovat näyttäviä ja kutsuvia sisäänkäyntejä sekä parantavat 
      ulkotilojen viihtyisyyttä. Suunnittelemme, asennamme ja huollamme räätälöidyt 
      kausikasvi-istutukset asiakkaiden toiveiden ja ympäristön edellytysten mukaan. Tuomme 
      vehreyttä ja kukkaloistoa muun muassa sisäänkäynneille, ulkoalueille, taukotiloihin, 
      terasseille ja sisäpihoille. Panostus ulko- ja piha-alueisiin lisää julkisivujen 
      lähestyttävyyttä ja viihtyisyyttä, oli kyseessä sitten yritys tai taloyhtiö.
      \\n
      Kausikasvitoteutuksissa yhdistämme yksi- ja monivuotisia kasveja luodaksemme 
      kestävän ja luonnonmukaisen kokonaisuuden. Käytämme kotimaisia, lähellä 
      tuotettuja taimia ja huomioimme istutuksissamme myös pölyttäjien tarpeet. 
      Luomme kauniita ja elämyksellisiä ympäristöjä, jotka ovat viihtyisiä sekä 
      ihmisille että pölyttäjille. Toteutamme myös istutuksia, joissa yhdistyvät 
      näköaistin lisäksi tuoksut ja äänet, luoden kokonaisvaltaisia aistikokemuksia.`,
  infoBoxTitle3: 'SE Viherkalusteet',
  infoBoxText3: 'SE Vehreyttä, viihtyisyyttä ja pörriäisiä keskellä kaupunkia.',
}

export default outDoor