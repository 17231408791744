const viherseina = {
  name: 'Green Wall',
  longDescription: `Our lightweight, metal-framed green wall is suitable for 
      both indoor and outdoor use. The metal frame green wall is self-standing 
      and does not require a water connection or wall fixing, allowing it to 
      be placed anywhere. The metal frame green wall is watered by our 
      professional service team.
      \\n
      Our metal-framed green wall is a compact option that also fits into a 
      smaller space and can, for example, be rented for an event only. 
      Over the years, the metal frame green wall has been used as a partition 
      in lobbies, as a refreshing decorative element in office gyms and at 
      festivals with plants according to season.
      \\n
      For the outdoor green wall, perennial plants or seasonal plants are 
      selected according to your wishes, bringing enjoyment to your outdoor 
      space all year round. Typically, in spring and summer, flowers are 
      added at entrances and in courtyards, and herb gardens in 
      recrational areas. In the darker seasons, conifers and seasonal 
      lighting can be placed on the wall.`,
  measurements: [
    {
      type: 'Width:',
      value: '1200 mm or 1800 mm'
    },
    {
      type: 'Height:',
      value: '2100 mm, 2270 mm or 2430 mm'
    },
  ],
  materials: [
    {
      type: 'Frame:',
      value: 'Metal'
    },
    {
      type: 'Green wall modules:',
      value: 'Abs plastic, made in Kaarina'
    },
    {
      type: 'Plants:',
      value: 'Finland and EU'
    },
    {
      type: 'Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'Plant lights:',
      value: `We use the latest technology LED lights. 
          The spectrum of the bulb matches the spectrum of daylight, 
          highlighting the plants while giving them just the right 
          amount of light they need.`
    },
    {
      type: 'Usage:',
      value: 'Indoor and outdoor',
      icon: 'both'
    },
  ],
  wlt: {
    title: 'This green wall protects 1000 square metres of rainforest',
    text: `By choosing an InnoGreen green wall, you are joining us in 
      protecting the world's rainforests in partnership with the World 
      Land Trust. For every square metre of green wall sold or rented, 
      we protect 1000 square metres of rainforest. `
  },
  configuration: [
    {
      type: 'viherseinä',
      text: ['1200 mm wide modules', '1800 mm wide modules'],
      valueText: 'Total width',
    },
  ],
  leveys: {
    description: `Choose width`,
    l120_cm: '1200 mm',
    l180_cm: '1800 mm',
    name: '1. Width'
  },
  kehyksen_vari: {
    description: `Colour of the visible parts of the frame`
  },
  korkeus: {
    description: `Choose height`
  },
}

export default viherseina