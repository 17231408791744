const koivu_vihertaulu = {
  name: 'Koivu Green Panel',
  longDescription: `Koivu green panels are decorative elements that 
      evoke the tranquillity of the forest and that will last for years. 
      They are lightweight and quick and easy to hang, and the 
      maintenance-free design allows them to be arranged creatively. 
      They work as individual eye-catchers, but can also be combined 
      to create stunning ensembles and still lifes. 
      \\n
      Inside the frames made of Finnish birch, you can choose moss, 
      lichen or a combination of the two. The panels are available 
      in three different shapes; round, square and rectangle.
      \\n
      The Koivu green panels use maintenance-free and irrigation-free 
      stabilised moss and lichen. Stabilised plants are plants that 
      have been preserved. The stabilisation process uses glycerine 
      and, despite the treatment, the plants are recyclable and 
      biodegradable. The process can be used to preserve real plants 
      and bring them into more challenging environments. Stabilised 
      mosses, lichens and plants will keep their shape and beautiful 
      colour for years.`,
  measurements: [
    {
      type: 'Round (diameter):',
      values: [
        {
          value: 'S 600 mm'
        },
        {
          value: 'M 900 mm'
        },
        {
          value: 'L 1200 mm'
        }
      ]
    },
    {
      type: 'Square:',
      values: [
        {
          value:'S 600x600 mm'
        },
        {
          value:'M 900x900 mm'
        },
        {
          value:'L 1200x1200 mm'
        }
      ]
    },
    {
      type: 'Rectangular:',
      values: [
        {
          value:'L 1200x600 mm'
        },
        {
          value:'XL 1800x900 mm'
        }
      ]
    },
    {
      type: 'Depth of the frame:',
      value: '60 mm on all models'
    },
  ],
  materials: [
    {
      type: 'Frame:',
      value: 'Finnish birch plywood'
    },
    {
      type: 'Material inside the panel:',
      value: 'Maintenance-free stabilised moss or lichen (EU)'
    },
    {
      type: 'Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'Usage:',
      value: 'Indoor',
      icon: 'indoor'
    },
  ],
  wlt: {},
  muoto: {
    description: `Shape of the panel`,
  },
  koko: {
    description: `Size of the panel`
  }, 
  kehyksen_vari: {
    description: `Colour of the wood finnish`
  },
  taulun_sisalto: {
    description: `Choose material for the inside of the panel`,
    sammal: 'Moss',
    jakala: 'Lichen',
    mix: 'Mix'
  },
}

export default koivu_vihertaulu