import './styles.scss'

import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
  Link,
  useMatches
} from 'react-router-dom'
import { PlusIcon, ArrowLeftLong } from '../../components/Icons'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomizeProduct from '../CustomizeProduct'
import RelatedProducts from '../RelatedProducts'
import LoadingScreen from '../../components/common/LoadingScreen'
import wltLogo from '../../assets/images/logo-WLT.png'
import { InDoor, OutDoor } from '../../components/Icons'
import { get_decoded_string } from '../../plugins/helper'
import { Download } from '../../components/Icons'
import ImageModal from '../../components/ImageModal'

import { setGlobalLoading } from '../../store/uiSlice'

import { fetch_product, fetch_product_material } from '../../store/asyncThunk'


const productMaterials = [
  {
    name: 'Product material 1',
    id: 1,
  },
  {
    name: 'Product material 2',
    id: 2,
  },
  {
    name: 'Product material 3',
    id: 3,
  },
]

const Product = () => {
  const dispatch = useDispatch()
  const { slug } = useParams()
  const { pathname } = useLocation()
  let [searchParams] = useSearchParams()
  const { t, i18n } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const language = useSelector((state) => state.user.language)

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      const preload_customizations =
        searchParams.get('preload_customizations') === 'true'
      await dispatch(fetch_product({ slug, preload_customizations, get_images: true }))
      dispatch(setGlobalLoading(false))
    })()
  }, [slug])

  const global_loading = useSelector((state) => state.ui.global_loading)
  const product = useSelector(
    (state) => state.product.selected_customized_product
  )

  var measurements = null
  var materials = null
  var wlt = null

  if (product) {
    measurements = i18n.t(
      `${get_decoded_string(product.name)}.measurements`, { returnObjects: true }
    )
    materials = i18n.t(
      `${get_decoded_string(product.name)}.materials`, { returnObjects: true }
    )
    wlt = i18n.t(
      `${get_decoded_string(product.name)}.wlt`, { returnObjects: true }
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const navigate = useNavigate()

  const goToAllProducts= () => {
    navigate('/tuotteet')
  }

  const getUsageIcons = (item) => {
    if (item.icon) {
      if (item.icon === 'both') {
        return (
          <div className='product-page-materials-item-container'>
            <InDoor size={24}/>
            <OutDoor size={24}/>
            <div className='product-page-materials-item-value'>{item.value}</div>
          </div>
        )
      } else if (item.icon === 'indoor') {
        return (
          <div className='product-page-materials-item-container'>
            <InDoor size={24}/>
            <div className='product-page-materials-item-value'>{item.value}</div>
          </div>
        )
      } else {
        return (
          <div className='product-page-materials-item-container'>
            <OutDoor size={24}/>
            <div className='product-page-materials-item-value'>{item.value}</div>
          </div>
        )
      }
    } else {
      return (
        <div className='product-page-materials-item-value'>{item.value}</div>
      )
    }
  }

  const getProductDescription = (product) => {
    if (product) {
      if (product.name === 'Parkly palveluna') {
        return (
          <>
            <div className='product-page-description-text'>
              {t(`${get_decoded_string(product.name)}.longDescription`).split('\\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                  <br />
                </React.Fragment>
              ))}
            </div>
            <a 
              href={t(`${get_decoded_string(product.name)}.link`)}
              target="_blank"
              rel="noopener noreferrer"
              className='product-page-description-link'
            >
            {t(`${get_decoded_string(product.name)}.linkText`)}
            </a>
            <br/>
            <br/>
            <div className='product-page-description-title'>
              {t(`${get_decoded_string(product.name)}.title`)}
            </div>
            <br/>
            <div className='product-page-description-text'>
              {t(`${get_decoded_string(product.name)}.longDescription2`).split('\\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                  <br />
                </React.Fragment>
              ))}
            </div>
          </>
        )

      } else {
        return (
         <div className='product-page-description-text'>
           {t(`${get_decoded_string(product.name)}.longDescription`).split('\\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
              <br />
            </React.Fragment>
          ))}
         </div>
        )
      }
    }
  }

  const getProductMaterialByLanguage = () => {
    if (product) {
      if (product.product_materials) {
        if (language === 'FI') {
          const product_materials = product.product_materials.filter(item => item.text_fi.includes("(FI)")).map(({ text_fi, ...rest }) => ({ ...rest, text: text_fi }))
          return product_materials
        } else if (language === 'EN') {
          const product_materials = product.product_materials.filter(item => item.text_en.includes("(EN)")).map(({ text_en, ...rest }) => ({ ...rest, text: text_en }))
          return product_materials
        }
      }
    }
  }

  const fetchProductMaterial = async (material) => {
    dispatch(fetch_product_material({ material_id: material.id }))
      .then((res) => {
        if (res.error) {
          throw new Error(res.error.message)
        }
        const blob = res.payload

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url;
        a.download = material.file_name || "download.pdf"
        document.body.appendChild(a);
        a.click();
  
        // Cleanup
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Download failed:", error)
      })
  }

  if (global_loading) {
    return (
      <>
        <div className='product-page-container'></div>
        <LoadingScreen />
      </>
    )
  }

  return (
    <div className='product-page-container'>
      <div className='product-page-body-container'>
        <div className='product-page-header-container'>
          <Link
            className='product-page-header-link no-select'
            to={language === 'FI' ? '/tuotteet' : '/products'}
          >
            <ArrowLeftLong />{t('common.products').toUpperCase()}
          </Link>
          <div className='product-page-header-product-name'>
            {t(`${get_decoded_string(product.name)}.name`)}
          </div>
          <div className='product-page-header-product-img-container'>
            <div className='product-page-header-product-img-boilerplate'>
              {product.imgSrc ? (
                <img src={product.imgSrc} alt={`product - ${product.id}`} />
              ) : null}
              <div
                className='product-page-header-product-img-boilerplate-more-btn no-select mobile'
                onClick={() => setIsModalOpen(true)}
              >
                <PlusIcon size={12} />&nbsp;<span>{t('common.showMorePictures').toUpperCase()}</span>
              </div>
            </div>
          </div>
          <div className='product-page-header-product-img-more-btn-wrapper mobile'>
            <div
              className='product-page-header-product-img-more-btn no-select'
              onClick={() => setIsModalOpen(true)}
            >
              <PlusIcon size={12} />&nbsp;<span>{t('common.showMorePictures').toUpperCase()}</span>
            </div>
          </div>
        </div>
        <div className='product-page-info'>
          <div className='product-page-description-container'>
            {getProductDescription(product)}
            <div className='product-page-measurements'>
              <div className='product-page-measurements-title'>{t('common.measurements')}</div>
              <div className='product-page-measurements-container'>
                { Array.isArray(measurements) ? measurements.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className='product-page-measurements-item'>
                          <div className={'product-page-measurements-item-type' + `${item?.value === '' ? ' full-width' : ''}`}>{item.type}</div>
                          { Array.isArray(item.values) ? 
                            (
                              <div className='product-page-measurements-item-value-container'>
                                { item?.bullet ? 
                                  (
                                    <>
                                      {item.values.map((val, index) => {
                                        return (
                                          <div className='product-page-measurements-item-value-bullet-container' key={index}>
                                            <div className='product-page-measurements-item-value-bullet'></div>
                                            <div className='product-page-measurements-item-value-array' >{val.value}</div>
                                          </div>
                                        )
                                      })}
                                    </>
                                  ) : (
                                    <>
                                      {item.values.map((val, index) => {
                                        return (
                                        
                                            <div className='product-page-measurements-item-value-array' key={index} >{val.value}</div>
                                          
                                        )
                                      })}
                                    </>
                                  ) 
                                }
                              </div>
                            ) : ( item?.value === '' ? 
                              (
                                <></>
                              ) : (
                                <div className='product-page-measurements-item-value'>{item.value}</div>
                              )
                              
                            )
                          }
                        </div>
                        {index !== measurements.length - 1 ?
                          (
                            <div className='product-page-measurements-item-divider'></div>
                          ) : (
                            <></>
                          )
                        }
                      </React.Fragment>
                    )
                  }) : <></>
                }
              </div>
            </div>
            <div className='product-page-materials'>
              <div className='product-page-materials-title'>{t('common.materials')}</div>
              <div className='product-page-materials-container'>
              { Array.isArray(materials) ? materials.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className='product-page-materials-item'>
                          <div className='product-page-materials-item-type'>{item.type}</div>
                          {getUsageIcons(item)}
                          {/* <div className='product-page-materials-item-value'>{item.value}</div>  */}
                        </div>
                        {index !== materials.length - 1 ?
                          (
                            <div className='product-page-materials-item-divider'></div>
                          ) : (
                            <></>
                          )
                        }
                      </React.Fragment>
                    )
                  }) : <></>
                }
              </div>
            </div>
          </div>
          <div className='product-page-material-container'>
              { product && product.product_materials.length > 0 ?
                (
                  <div className='product-page-material-card'>
                    <div className='product-page-material-title'>{t('common.downloadMaterials')}</div>
                    <div className='product-page-material-entries'>
                    {getProductMaterialByLanguage().map((material) => (
                      <div
                        key={material.id} 
                        className='product-page-material-entry'
                        onClick={() => fetchProductMaterial(material)}
                      >
                        <Download size={14}/>
                        {material.text}
                      </div>
                    ))}
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
            <div className='product-page-wlt-card'>
              {wlt !== null && Object.keys(wlt).length > 0 ?
              (
                <div className='product-page-wlt-card-container'>
                  <div className='product-page-wlt-card-title header-text'>{wlt.title}</div>
                  <div className='product-page-wlt-card-text'>{wlt.text}</div>
                  <div className='product-page-wlt-card-img'>
                    <img src={wltLogo} alt="WLT logo" />
                  </div>
                </div>
              ) : (
                <></>
              )
            }
            </div>
          </div>
        </div>
      </div>
      {/* <p
      className='product-page-product-details-link'
      onClick={() => goToProductDetails()}
    >
      VIEW PRODUCT DETAILS
      <FontAwesomeIcon icon={faArrowRightLong} />
    </p> */}
      {}
      <CustomizeProduct product={product} />
      <RelatedProducts product={product} />
      {/* Image Modal */}
      <ImageModal images={product.showMoreSrcs} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  )
}

export default Product
