import './styles.scss'

import React from 'react'

import {
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableSortLabel,
} from "@mui/material"
import { visuallyHidden } from '@mui/utils'

const InquiriesTableHeader = (props) => {
  const {
    order,
    orderBy,
    handleRequestSort,
    date,
    priority,
    name,
    email,
    phone,
    company,
    city,
    message,
    state,
    deadline,
  } = props

  return (
    <TableHead>
      <TableRow
        sx={{ backgroundColor: '#f5f5f5' }}
      >

        {/* PRIORITY COLUMN */}
        { priority && (
            <TableCell
              sortDirection={orderBy === 'priority' ? order : false}
            >
              <TableSortLabel
                active={orderBy ==='priority'}
                direction={orderBy === 'priority' ? order : 'asc'}
                onClick={(e) => handleRequestSort(e, 'priority')}
                sx={{
                  fontFamily: 'Libre Franklin',
                  fontWeight: 600,
                  '&.Mui-active': {
                    color: '#8E5CD9', // Color when active (sorted)
                  },
                  '& .MuiTableSortLabel-icon': {
                    color: '#8E5CD9 !important', // Change arrow color
                  },
                }}
              >
                Priority
                {orderBy === 'priority' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        }

        {/* DATE COLUMN */}
        { date && (
            <TableCell
              sortDirection={orderBy === 'date_created' ? order : false}
            >
              <TableSortLabel
                active={orderBy ==='date_created'}
                direction={orderBy === 'date_created' ? order : 'asc'}
                onClick={(e) => handleRequestSort(e, 'date_created')}
                className='table-date-header'
                sx={{
                  fontFamily: 'Libre Franklin',
                  fontWeight: 600,
                  '&.Mui-active': {
                    color: '#8E5CD9', // Color when active (sorted)
                  },
                  '& .MuiTableSortLabel-icon': {
                    color: '#8E5CD9 !important', // Change arrow color
                  },
                }}
              >
                Date received
                {orderBy === 'date_created' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        }

        {/* DEADLINE COLUMN */}
        { deadline && (
            <TableCell
              sortDirection={orderBy === 'deadline' ? order : false}
            >
              <TableSortLabel
                active={orderBy ==='deadline'}
                direction={orderBy === 'deadline' ? order : 'asc'}
                onClick={(e) => handleRequestSort(e, 'deadline')}
                sx={{
                  fontFamily: 'Libre Franklin',
                  fontWeight: 600,
                  '&.Mui-active': {
                    color: '#8E5CD9', // Color when active (sorted)
                  },
                  '& .MuiTableSortLabel-icon': {
                    color: '#8E5CD9 !important', // Change arrow color
                  },
                }}
              >
                Deadline
                {orderBy === 'deadline' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        }



        {/* NAME COLUMN */}
        { name && (
            <TableCell
              sortDirection={orderBy === 'name' ? order : false}
            >
              <TableSortLabel
                active={orderBy ==='name'}
                direction={orderBy === 'name' ? order : 'asc'}
                onClick={(e) => handleRequestSort(e, 'name')}
                sx={{
                  fontFamily: 'Libre Franklin',
                  fontWeight: 600,
                  '&.Mui-active': {
                    color: '#8E5CD9', // Color when active (sorted)
                  },
                  '& .MuiTableSortLabel-icon': {
                    color: '#8E5CD9 !important', // Change arrow color
                  },
                }}
              >
                Name
                {orderBy === 'name' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        }

        {/* EMAIL COLUMN */}
        {/* { email && (
            <TableCell
              sx={{ fontFamily: 'Libre Franklin', fontWeight: 600 }}
            >
              Email
            </TableCell>
          )
        } */}

        {/* MESSAGE COLUMN */}
        { message && (
            <TableCell
              sx={{fontFamily: 'Libre Franklin', fontWeight: 600 }}
            >
              Message
            </TableCell>
          )
        }

        {/* PHONE COLUMN */}
        {/* { phone && (
            <TableCell
              sx={{ width: '8%', fontFamily: 'Libre Franklin', fontWeight: 600 }}
            >
              Phone
            </TableCell>
          )
        } */}

        {/* COMPANY COLUMN */}
        { company && (
            <TableCell
              sortDirection={orderBy === 'company' ? order : false}
            >
              <TableSortLabel
                active={orderBy ==='company'}
                direction={orderBy === 'company' ? order : 'asc'}
                onClick={(e) => handleRequestSort(e, 'company')}
                sx={{
                  fontFamily: 'Libre Franklin',
                  fontWeight: 600,
                  '&.Mui-active': {
                    color: '#8E5CD9', // Color when active (sorted)
                  },
                  '& .MuiTableSortLabel-icon': {
                    color: '#8E5CD9 !important', // Change arrow color
                  },
                }}
              >
                Company
                {orderBy === 'company' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        }

        {/* CITY COLUMN */}
        { city && (
            <TableCell
              sortDirection={orderBy === 'city' ? order : false}
            >
              <TableSortLabel
                active={orderBy ==='city'}
                direction={orderBy === 'city' ? order : 'asc'}
                onClick={(e) => handleRequestSort(e, 'city')}
                sx={{
                  fontFamily: 'Libre Franklin',
                  fontWeight: 600,
                  '&.Mui-active': {
                    color: '#8E5CD9', // Color when active (sorted)
                  },
                  '& .MuiTableSortLabel-icon': {
                    color: '#8E5CD9 !important', // Change arrow color
                  },
                }}
              >
                City
                {orderBy === 'city' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        }

        {/* STATE COLUMN */}
        { state && (
            <TableCell
              onClick={(e) => handleRequestSort(e, 'state')}
            >
              <TableSortLabel
                active={orderBy ==='state'}
                direction={orderBy === 'state' ? order : 'asc'}
                onClick={(e) => handleRequestSort(e, 'state')}
                sx={{
                  fontFamily: 'Libre Franklin',
                  fontWeight: 600,
                  '&.Mui-active': {
                    color: '#8E5CD9', // Color when active (sorted)
                  },
                  '& .MuiTableSortLabel-icon': {
                    color: '#8E5CD9 !important', // Change arrow color
                  },
                }}
              >
                State
                {orderBy === 'state' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        }

        {/* ACTION COLUMN */}
        <TableCell sx={{ width: '2%' }}></TableCell>
      </TableRow>
    </TableHead>
  )
}

export default InquiriesTableHeader