const account = {
  hello: 'Hello',
  updateUserInformation: 'Update your user information',
  logout: 'Log out',
  yourProjects: 'Your projects',
  createNewProject: 'Create new project',
  searchProject: 'Search for a project',
  projectName: 'Project name',
  numberOfProducts: 'Number of products',
  dateModified: 'Date modified',
  status: 'Status',
  product: 'Product',
  products: 'Products',
  open: 'Open',
  pending: 'Pending',
  inquirySent: 'Inquiry sent',
  unknown: 'Unknown',
  projectName: 'Project name',
  projectDescription: 'Project description',
  create: 'Create',
  cancel: 'Cancel',
  save: 'Save',
  updatedUserInfo: 'User information updated!',
  emailList: 'InnoGreen email list',
  genericUserUpdateError: 'Failed to update user information, please try again!',
  genericCreateProjectError: 'Failed to create prject, please try again!',
  createProjectSuccessMessage: 'Created new project!',
  close: 'Close',
  userUpdateSuccessMessage: 'Updated user information!',
}

export default account