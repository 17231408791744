import './styles.scss'
import { Close } from '../Icons'
import { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import { useDispatch } from 'react-redux'
import { edit_project } from '../../store/asyncThunk'
import { useTranslation } from 'react-i18next'

const EditProjectModal = ({ showing, closeModal, project }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    setRequestedSuccess(false)
    setErrorList([])
    if (showing) {
      document.body.style.overflow = 'hidden'

      setUpdatedProject({
        name: project.name,
        description: project.description,
      })
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const [updatedProject, setUpdatedProject] = useState(null)
  const { t } = useTranslation()
  const [errorList, setErrorList] = useState([])
  const [requested_success, setRequestedSuccess] = useState(false)
  const updateProjectProperties = ({ name, description }) => {
    if (name !== undefined) {
      setUpdatedProject({ ...updatedProject, name: name })
    }
    if (description !== undefined) {
      setUpdatedProject({ ...updatedProject, description: description })
    }
  }

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const handleConfirmClick = async () => {
    dispatch(
      edit_project({ payload: updatedProject, project_id: project.id })
    ).then((res) => {
      if (!res.error) {
        setErrorList([])
        setRequestedSuccess(true)
      } else {
        setErrorList([t('project.genericEditProjectError')])
      }
    })
  }

  return (
    showing &&
    updatedProject && (
      <div className='edit-project-modal-root'>
        <div className='edit-project-modal-container'>
          <div className='edit-project-modal-header'>
            <div className='edit-project-modal-header-exit-btn-container'>
              <div
                className='edit-project-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20} />
              </div>
            </div>
          </div>

          <div className='edit-project-modal-content'>
            {requested_success ? (
              <div>{t('project.editProjectSuccessMessage')}</div>
            ) : (
              <>
                <div className='edit-project-modal-header-title'>
                  {t('project.editProject')}
                </div>
                {errorList.length > 0 && (
                  <div className='edit-project-error-container'>
                    {errorList.map((error) => {
                      return (
                        <div className='edit-project-error-entry' key={error}>
                          - {error}
                        </div>
                      )
                    })}
                  </div>
                )}
                <div className='edit-project-modal-input-group'>
                  <label className='edit-project-modal-input-label'>
                    {t('project.projectName')}
                  </label>
                  <input
                    type='text'
                    value={updatedProject.name}
                    className='edit-project-modal-input-field'
                    onInput={(e) =>
                      updateProjectProperties({ name: e.target.value })
                    }
                  ></input>
                </div>

                <div className='edit-project-modal-input-group'>
                  <label className='edit-project-modal-input-label'>
                    {t('project.description')}
                  </label>
                  <textarea
                    type='text'
                    value={updatedProject.description}
                    className='edit-project-modal-input-field textarea'
                    onInput={(e) =>
                      updateProjectProperties({ description: e.target.value })
                    }
                  ></textarea>
                </div>
              </>
            )}
          </div>

          <div className='edit-project-modal-btn-group'>
            {requested_success ? (
              <CustomButton
                button_text={t('project.close').toUpperCase()}
                onClickHandler={() => close()}
              />
            ) : (
              <>
                <CustomButton
                  button_text={t('project.confirm').toUpperCase()}
                  disabled={updatedProject.name.trim().length === 0}
                  onClickHandler={() => handleConfirmClick()}
                />
                <CustomButton
                  button_text={t('project.cancel').toUpperCase()}
                  onClickHandler={() => close()}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default EditProjectModal
