const contact = {
  headerTitle: 'SE Ota yhteyttä',
  cards: [
    {
      title: 'SE Vihersisustus',
      id: 'Vihersisustus',
      phone: '010 583 3595',
      email: 'myynti@innogreen.fi',
      buttonText: 'SE NÄYTÄ HENKILÖT',
    },
    {
      title: 'SE Ulkoalueet',
      id: 'Ulkoalueet',
      phone: '010 583 3598',
      email: 'piha@innogreen.fi',
      buttonText: 'SE NÄYTÄ HENKILÖT',
    },
    {
      title: 'SE Huolto',
      id: 'Huolto',
      phone: '010 583 3590',
      email: 'huolto@innogreen.fi',
      buttonText: 'SE NÄYTÄ HENKILÖT',
    },
    {
      title: 'SE Hallinto',
      id: 'Hallinto',
      phone: '010 583 3594',
      email: 'laskut@innogreen.fi',
      buttonText: 'SE NÄYTÄ HENKILÖT',
    },
  ],
  invoiceCards: [
    {
      title: 'Green House Effect Oy',
      id: 'Placeholder',
      type: 'companyInfo',
      address: ['Yrityspiha 6, 00390 Helsinki', 'Viinikankatu 55, 33800 Tampere', 'Itäkaari 28, 20750 Turku (Halli 58)'],
      identityText: 'SE Y-tunnus',
      identityCode: '2413133-9',
    },
    {
      title: 'SE Laskutustiedot',
      id: 'Laskutustiedot',
      type: 'invoiceInfo',
      invoiceText: 'SE Pyydämme toimittamaan kaikki meille osoitetut laskut verkkolaskuina tai sähköpostilla.',
      eInvoiceAddressText: 'SE Verkkolaskuosoite',
      operatorText: 'SE peraattori',
      pdfInvoiceText: 'SE PDF-laskut',
      eInvoiceAddress: '003724131339',
      operator: 'Maventa (003721291126), tai DABAFIHH',
      pdfInvoice: '24131339@scan.netvisor.fi',
      pdf: 'SE Laskutusohjeet pdf-tiedostossa:',
      downloadText: 'SE Lataa tästä',
    },
  ],
  inquiryTitle: 'SE Jätä yhteydenottopyyntö, niin otamme sinuun yhteyttä!',
  email: 'SE Sähköposti',
  phone: 'SE Puhelin',
  place: 'SE Paikkakunta',
  message: 'SE Viesti',
  sendBtn: 'SE Läheta lomake',
  contactInfo: [
    {
      title: 'SE Vihersisustus',
      refId: 'Vihersisustus',
      email: 'myynti@innogreen.fi',
      phoneNumber: '010 583 3595',
      employee: [
        {
          name: 'Kirsi Hänninen',
          id: 'kirsih',
          department: ['SE VIHERSISUSTUS'],
          email: 'kirsi.hanninen@innogreen.fi',
          phoneNumber: '040 451 6565',
        },
        {
          name: 'Leena Kinnunen',
          id: 'leenak',
          department: ['SE VIHERSUUNNITTELU', 'SE VIHERSISUSTUS'],
          email: 'leena.kinnunen@innogreen.fi',
          phoneNumber: '050 543 7035',
        },
        {
          name: 'Liivika Korkalo',
          id: 'liivikak',
          department: ['SE VIHERSUUNNITTELU', 'SE VIHERSISUSTUS'],
          email: 'liivika.korkalo@innogreen.fi',
          phoneNumber: '040 838 3223',
        },
        {
          name: 'Anne Ollila',
          id: 'anneo',
          department: ['SE VIHERSISUSTUS', 'SE TAMPERE JA MUU SUOMI'],
          email: 'anne.ollila@innogreen.fi',
          phoneNumber: '050 569 5262',
        },
        {
          name: 'Katja Kankkunen',
          id: 'katjak',
          department: ['SE ASIAKASVASTAAVA', 'SE VIHERSISUSTUS', 'SE ITÄ-SUOMI'],
          email: 'katja.kankkunen@innogreen.fi',
          phoneNumber: '050 472 2986',
        },
        {
          name: 'Sami Vainionpää',
          id: 'samiv',
          department: ['SE MYYNTIJOHTAJA'],
          email: 'sami.vainionpaa@innogreen.fi',
          phoneNumber: '050 566 6566',
        },
      ]
    },
    {
      title: 'SE Ulkoalueet',
      refId: 'Ulkoalueet',
      email: 'piha@innogreen.fi',
      phoneNumber: '010 583 3598',
      employee: [
        {
          name: 'Miia Kujala',
          id: 'miiak',
          department: ['SE MYYNTI'],
          email: 'miia.kujala@innogreen.fi',
          phoneNumber: '050 401 7891',
        },
        {
          name: 'Johanna Murto',
          id: 'johannam',
          department: ['SE MYYNTI & HALLINTO'],
          email: 'johanna.murto@innogreen.fi',
          phoneNumber: '050 431 5422',
        },
        {
          name: 'Kristina Hulkkonen',
          id: 'kristinah',
          department: ['SE YLLÄPIDON VASTAAVA'],
          email: 'kristina.hulkkonen@innogreen.fi',
          phoneNumber: '050 463 1657',
        },
      ]
    },
    {
      title: 'SE Asennus ja huolto',
      refId: 'Huolto',
      email: 'huolto@innogreen.fi',
      phoneNumber: '010 583 3590',
      employee: [
        {
          name: 'Sari Räty',
          id: 'sarir',
          department: ['SE HUOLTOPÄÄLLIKKÖ (HELSINKI JA TURKU)'],
          email: 'sari.raty@innogreen.fi',
        },
        {
          name: 'Raisa Koiranen',
          id: 'raisak',
          department: ['SE HUOLTO (TAMPERE)'],
          email: 'raisa.koiranen@innogreen.fi',
        },
        {
          name: 'Teijo Tiainen',
          id: 'teijot',
          department: ['SE VARASTOPÄÄLLIKKÖ'],
          email: 'teijo.tiainen@innogreen.fi',
          phoneNumber: '050 443 5561',
        },
        {
          name: 'Laura Tubal',
          id: 'laurat',
          department: ['SE OSTOT JA TILAUKSET'],
          email: 'laura.tubal@innogreen.fi',
          phoneNumber: '050 472 2356',
        },
      ]
    },
    {
      title: 'SE Hallinto',
      refId: 'Hallinto',
      email: 'laskut@innogreen.fi',
      phoneNumber: '010 583 3594',
      employee: [
        {
          name: 'Raisa Koiranen',
          id: 'raisak',
          department: ['SE MYYNTILASKUT JA SOPIMUKSET'],
          email: 'raisa.koiranen@innogreen.fi',
        },
        {
          name: 'Pia Paajanen',
          id: 'piap',
          department: ['SE HENKILÖSTÖPÄÄLLIKKÖ'],
          email: 'pia.paajanen@innogreen.fi',
        },
        {
          name: 'Oula Harjula',
          id: 'oulah',
          department: ['SE KEHITYSJOHTAJA'],
          email: 'oula@innogreen.fi',
        },
        {
          name: 'Mikko Sonninen',
          id: 'mikkos',
          department: ['SE TOIMITUSJOHTAJA', 'SE TUOTEKEHITYS'],
          email: 'mikko@innogreen.fi',
        },
        {
          name: 'Jaakko Pesonen',
          id: 'jaakkop',
          department: ['SE TALOUSJOHTAJA'],
          email: 'jaakko@innogreen.fi',
        },
        {
          name: 'Nora Tåg',
          id: 'norat',
          department: ['SE VIESTINTÄ'],
          email: 'nora.tag@innogreen.fi',
        },
      ]
    },
  ],
  contactInfoGreenDecor: 'SE Vihersisustus',
  contactInfoOutdoor: 'SE Ulkoalueet',
  contactInfoMaintenance: 'SE Asennus ja huolto',
  contactInfoAdministration: 'SE Hallinto',
  genericContactRequestError: 'Failed to request message, please try again!',
}

export default contact