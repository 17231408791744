import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { get_decoded_string } from '../../plugins/helper'

import CustomButton from '../common/CustomButton'
import CustomDropdown from '../common/CustomDropdown'
import { MinusIcon, PlusIcon } from '../Icons'
import { selectCustomizedProduct } from '../../store/productSlice'

import { edit_assigned_product, edit_assigned_product_customization } from '../../store/asyncThunk'

const ProductEntry = (props) => {
  const { t, i18n } = useTranslation()
  const {
    product,
    toggleConfirmRemoveProductModal,
    toggleAddProductToProjectModal,
    current_project,
    project_id,
    customizations,
    page,
    showProductOverViewBtn = true,
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isEditing, setIsEditing] = useState(false)
  const [text, setText] = useState('')
  const [debounceTimer, setDebounceTimer] = useState(null); // State to hold the timer
  const [spaces, setSpaces] = useState([]); // State to hold the timer

  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }

  useEffect(() => {
    if (product) {
      const text_customization = product.customizations.find(item => item.name === '3. Kustomointi')?.value
      setText(text_customization)
    }
  }, [product])

  useEffect(() => {
    if (current_project) {
      setSpaces(current_project.spaces.map(item => {
        if (item.is_default) {
          return ({...item, translation: 'project.defaultSpace'})
        } else {
          return {...item}
        }
      }))
    }
  }, [current_project])

  const goToProductDetails = (product) => {
    if (i18n.language === 'FI') {
      navigate(`/tuotteet/${product.slug}/details?project_id=${project_id}`)
    } else if (i18n.language === 'EN') {
      navigate(`/products/${product.en_slug}/details?project_id=${project_id}`)
    }
  }

  const get_selected_space = (product) => {
    return spaces.find((s) => s.id === product.space_id)
  }

  const update_product_space = (product, space) => {
    dispatch(
      edit_assigned_product({
        product_id: product.id,
        project_id: project_id,
        payload: { space_id: space.id },
      })
    )
  }

  const update_product_quantity = (product, value) => {
    dispatch(
      edit_assigned_product({
        product_id: product.id,
        project_id: project_id,
        payload: { quantity: product.quantity + value },
      })
    )
  }

  const get_option_by_index = (array, index) => {
    const start = index * 3
    const end = start + 3
    return array.slice(start, end)
  }

  const handleOptionChangeText = (event, product, customization) => {
    const value = event.target.value

    setText(value)

    // Clear the previous timer
    if (debounceTimer) {
      clearTimeout(debounceTimer)
    }

    // Set a new timer and save it in state
    const timer = setTimeout(() => {
      const assigned_customization = product.customizations.find(item => item.customization_id === customization.customization_id)
      dispatch(
        edit_assigned_product_customization({
          product_id: product.id,
          project_id: project_id,
          customization_id: assigned_customization.id,
          payload: {
            value: value,
            mossWall: 'text'
           },
        })
      )
    }, 1000)

    setDebounceTimer(timer) // Save the new timer in state
  }

  const update_product_customization = (product, value, type='dropdown') => {
    // Custom for Koivu-vihertaulu
    if (type === 'add') {
        const assigned_customization = product.customizations.find(item => item.customization_id === value.customization_id && item.selected_option_id === value.selected_option_id)

        dispatch(
          edit_assigned_product_customization({
            product_id: product.id,
            project_id: project_id,
            customization_id: assigned_customization.id,
            payload: { value: value.value + 1 },
          })
        )
    } else if (type === 'subtract') {
      const assigned_customization = product.customizations.find(item => item.customization_id === value.customization_id && item.selected_option_id === value.selected_option_id)

      dispatch(
        edit_assigned_product_customization({
          product_id: product.id,
          project_id: project_id,
          customization_id: assigned_customization.id,
          payload: { value: Math.max(0, value.value - 1)},
        })
      )
    } else {
      if (product?.name === 'Koivu-vihertaulu') {
        const assigned_customization = product.customizations.find(item => item.customization_id === value.customization_id)
        // Model changes we need to change Size and Contents also
        if (assigned_customization.name === '1. Muoto') {
          
          const selected_model_index = customizations.find(item => item.id === value.customization_id).options.findIndex(item => item.id === value.id)
          const selected_size = product.customizations.find(item => item.name === '2. Koko')
  
          const new_size = get_option_by_index(customizations.find(item => item.id === selected_size.customization_id).options, selected_model_index)[0]
          const new_size_index = customizations.find(item => item.id === selected_size.customization_id).options.findIndex(item => item.id === new_size.id)
          
          const selected_content = product.customizations.find(item => item.name === '4. Taulun sisältö')
          const new_content = get_option_by_index(customizations.find(item => item.id === selected_content.customization_id).options, new_size_index)[0]
  
          dispatch(
            edit_assigned_product_customization({
              product_id: product.id,
              project_id: project_id,
              customization_id: assigned_customization.id,
              payload: {
                vihertaulu: 'model',
                selected_customization: value.id,
                size_customization_id: selected_size.id,
                new_size_id: new_size.id,
                content_customization_id: selected_content.id,
                new_content_id: new_content.id
              },
            })
          )
  
        // Size is changed we need to change the Contents also
        } else if (assigned_customization.name === '2. Koko') {
          const selected_size_index = customizations.find(item => item.id === value.customization_id).options.findIndex(item => item.id === value.id)
          const selected_content = product.customizations.find(item => item.name === '4. Taulun sisältö')
          const new_content = get_option_by_index(customizations.find(item => item.id === selected_content.customization_id).options, selected_size_index)[0]
  
          dispatch(
            edit_assigned_product_customization({
              product_id: product.id,
              project_id: project_id,
              customization_id: assigned_customization.id,
              payload: {
                type: 'size',
                selected_customization: value.id,
                content_customization_id: selected_content.id,
                new_content_id: new_content.id
              },
            })
          )
  
        } else {
          dispatch(
            edit_assigned_product_customization({
              product_id: product.id,
              project_id: project_id,
              customization_id: assigned_customization.id,
              payload: { selected_customization: value.id },
            })
          )
        }
      } else if (product?.name === 'Koivu-viherseinä') {
        // Check if Model is changed as wall mounted does not have option for background material
        const assigned_customization = product.customizations.find(item => item.customization_id === value.customization_id)
        if (assigned_customization.name === '1. Malli') {
          if (value.name === 'Tilanjakaja') {
            // Get default customization for background material
            const new_background_customization = customizations.find(item => item.name === '3. Taustamateriaali')
            const new_background_option = new_background_customization.options[0]
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: { 
                  selected_customization: value.id,
                  koivuViherSeina: 'model',
                  model: 'tilanjakaja',
                  new_background_customization_id: new_background_customization.id,
                  new_background_option_id: new_background_option.id
                },
              })
            )
          } else {
            // Remove the default background material as wall mounted should not have that option
            const selected_background_customization = product.customizations.find(item => item.name === '3. Taustamateriaali')
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: { 
                  selected_customization: value.id,
                  koivuViherSeina: 'model',
                  model: 'seinalle_sijoitettava',
                  customization_id: selected_background_customization.id
                },
              })
            )
          }
        } else {
          dispatch(
            edit_assigned_product_customization({
              product_id: product.id,
              project_id: project_id,
              customization_id: assigned_customization.id,
              payload: { selected_customization: value.id },
            })
          )
        }
      } else if (product?.name === 'Koivu T-sarja') {
        // Custom for T-Series if model T-1020XL is selected and feet is 'korkeat jalat' 
        // We need to change it to first option as 'korkeat jalat' is not available on T-1020XL only on T-1020
        const assigned_customization = product.customizations.find(item => item.customization_id === value.customization_id)
        if (assigned_customization.name === '1. Malli' && value.name === 'T-1020XL') {
          // T-1020XL Check if 'korket jalat' is chosen
          const selected_feet_option = product.customizations.find(item => item.name === '3. Jalat')
          const feet_options = customizations.find(item => (item.id === selected_feet_option.customization_id)).options
          if (feet_options.find(item => (item.id === selected_feet_option.selected_option_id)).name === 'Korkeat jalat') {
            // Need to change to default feet selection
            const new_feet_option = feet_options[0]
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: {
                  selected_customization: value.id,
                  tSeries: 'feet',
                  new_feet_option_id: new_feet_option.id,
                  current_feet_customization_id: selected_feet_option.id
                },
              })
            )
          } else {
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: { selected_customization: value.id },
              })
            )
          }
        } else {
          dispatch(
            edit_assigned_product_customization({
              product_id: product.id,
              project_id: project_id,
              customization_id: assigned_customization.id,
              payload: { selected_customization: value.id },
            })
          )
        }
      } 
      else if (product?.name === 'Koivu P-sarja') {
        // if P380 or P380XL is selected and 'Kasviryhmä' is 'S' then we need to change it to default option
        const assigned_customization = product.customizations.find(item => item.customization_id === value.customization_id)
        if (assigned_customization.name === '1. Malli' && (value.name === 'P-380' || value.name === 'P-380XL')) {
          // Check if 'Kasviryhmä' is chosen
          const selected_plant_group = product.customizations.find(item => item.name === '4. Kasviryhmä')
          const plant_group_options = customizations.find(item => (item.id === selected_plant_group.customization_id)).options
          // Check if 'S' is not chosen
          if (plant_group_options.find(item => (item.id === selected_plant_group.selected_option_id)).name !== 'S') {
            // Need to change to default plant group selection
            const new_plant_group_option = plant_group_options[0]
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: {
                  selected_customization: value.id,
                  pSeries: 'plantGroup',
                  new_plant_group_option_id: new_plant_group_option.id,
                  current_plant_group_customization_id: selected_plant_group.id
                },
              })
            )
          } else {
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: { selected_customization: value.id },
              })
            )
          }
        } else if (assigned_customization.name === '1. Malli' && value.name === 'P-445') {
          const selected_plant_group = product.customizations.find(item => item.name === '4. Kasviryhmä')
          const plant_group_options = customizations.find(item => (item.id === selected_plant_group.customization_id)).options
          // Check if 'S' or 'M' is not chosen
          if (plant_group_options.find(item => (item.id === selected_plant_group.selected_option_id)).name !== 'M') {
            // Need to change to default plant group selection
            const new_plant_group_option = plant_group_options[1]
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: {
                  selected_customization: value.id,
                  pSeries: 'plantGroup',
                  new_plant_group_option_id: new_plant_group_option.id,
                  current_plant_group_customization_id: selected_plant_group.id
                },
              })
            )
          } else {
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: { selected_customization: value.id },
              })
            )
          }
        } else if (assigned_customization.name === '1. Malli' && value.name === 'P-550') {
          const selected_plant_group = product.customizations.find(item => item.name === '4. Kasviryhmä')
          const plant_group_options = customizations.find(item => (item.id === selected_plant_group.customization_id)).options
          // Check if 'L - Puuvartinen' or 'L - Pensanmainen' is not chosen
          if (plant_group_options.find(item => (item.id === selected_plant_group.selected_option_id)).name !== 'L - Puuvartinen' && plant_group_options.find(item => (item.id === selected_plant_group.selected_option_id)).name !== 'L - Pensanmainen') {
            // Need to change to default plant group selection
            const new_plant_group_option = plant_group_options[2]
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: {
                  selected_customization: value.id,
                  pSeries: 'plantGroup',
                  new_plant_group_option_id: new_plant_group_option.id,
                  current_plant_group_customization_id: selected_plant_group.id
                },
              })
            )
          } else {
            dispatch(
              edit_assigned_product_customization({
                product_id: product.id,
                project_id: project_id,
                customization_id: assigned_customization.id,
                payload: { selected_customization: value.id },
              })
            )
          }
        } else {
          dispatch(
            edit_assigned_product_customization({
              product_id: product.id,
              project_id: project_id,
              customization_id: assigned_customization.id,
              payload: { selected_customization: value.id },
            })
          )
        }
      }
      else {
        const assigned_customization = product.customizations.find(item => item.customization_id === value.customization_id)
        dispatch(
          edit_assigned_product_customization({
            product_id: product.id,
            project_id: project_id,
            customization_id: assigned_customization.id,
            payload: { selected_customization: value.id },
          })
        )
      }
    }
  }

  const get_selected_customization = (customization, selected_id, config=null, textIndex=null, is_editing=false, hide_value=false, with_variable=false) => {
    // for input type customizations
    if (config) {
      if (config.type === 'räätälöitävä_viherseinä') {
        if (with_variable) {
          if (config.configType === 'height') {
            return config.valueText.toUpperCase() + ' ' + (customization.value * config.value + 400) + ' MM'
          }
          return config.valueText.toUpperCase() + ' ' + (customization.value * config.value) + ' MM'
        } else if (config.configType === 'width') {
          return (customization.value * config.value) + ' MM'
        } else if (config.configType === 'height') {
          return (customization.value * config.value + 400) + ' MM'
        }
      } else if (config.type === 'räätälöitävä_sammalseinä') {
        if (config.configType === 'custom') {
          return (customization.value)
        } else {
          return (customization.value) + ' MM'
        }
      } else if (config.type === 'viherpysäkki') {
        if (hide_value) {
          return config.text[textIndex].toUpperCase()
        }
        return (customization.value) + ' X ' + config.text[textIndex].toUpperCase()
      } else if (config.name === 'viherseinä') {
        if (!is_editing) {
          let value = 0
          customization.selected.forEach((item, index) => {
            if (index === 0) {
              value += item.value * 1200
            } else {
              value += item.value * 1800
            }
          })
          return `${value} MM`
        }
      }
    }
    const selected_customization = customizations.find((c) => c.id === customization.customization_id)
    if (selected_customization) {
      const option = selected_customization.options.find((o) => o.id === selected_id)
      if (option) {
        if (get_decoded_string(selected_customization.name)=== 'korkeus') {
          return (t(`products.${get_decoded_string(selected_customization.name)}_options.k${get_decoded_string(option.name)}`)).toUpperCase()
        } 
        else if (get_decoded_string(selected_customization.name) === 'leveys') {
          return (t(`products.${get_decoded_string(selected_customization.name)}_options.l${get_decoded_string(option.name)}`)).toUpperCase()
        }
        return (t(`products.${get_decoded_string(selected_customization.name)}_options.${get_decoded_string(option.name)}`)).toUpperCase()
      }
    } 
    return ''
  }

  const goToProductPage = (product) => {
    dispatch(
      selectCustomizedProduct({
        id: product.product_id,
        name: product.name,
        product_materials: product.product_materials,
        customizations: product.customizations.map((c) => ({
          id: c.customization_id,
          name: c.name,
          selected_option_id: c.selected_option_id,
          value: c.value
        })),
      })
    )
    if (i18n.language === 'FI') 
      navigate(`/tuotteet/${product.slug}?preload_customizations=true`)
    else if (i18n.language === 'EN') 
      navigate(`/products/${product.en_slug}?preload_customizations=true`)
  }

  const quantity_is_zero = (product) => {
    return product.quantity === 0
  }

  const get_customization_options = (customization) => {
    if (product?.name === 'Koivu-vihertaulu') {
      const found_customization = customizations.find(item => item.id === customization.customization_id)
      if (customization.name === '2. Koko') {
        // Get selected Model
        const selected_model = product.customizations.find(item => item.name === '1. Muoto')
        // Get selected model index
        const selected_model_index = customizations.find(item => item.id === selected_model.customization_id).options.findIndex(item => item.id === selected_model.selected_option_id)
        // return based on selected_model_index
        return get_option_by_index(found_customization.options, selected_model_index, found_customization.id).map(option => {
          let options = {...option, customization_id: found_customization.id}
          options.name = (t(`products.${get_decoded_string(found_customization.name)}_options.${get_decoded_string(option.name)}`)).toUpperCase()
          return {...options, customization_id: found_customization.id}
        })
      } else if (customization.name === '4. Taulun sisältö') {
        // Get selected size
        const selected_size = product.customizations.find(item => item.name === '2. Koko')
        // Get selected size index
        const selected_size_index = customizations.find(item => item.id === selected_size.customization_id).options.findIndex(item => item.id === selected_size.selected_option_id)
        // return based on selected_size_index
        return get_option_by_index(found_customization.options, selected_size_index, found_customization.id).map(option => {
          let options = {...option, customization_id: found_customization.id}
          options.name = (t(`products.${get_decoded_string(found_customization.name)}_options.${get_decoded_string(option.name)}`)).toUpperCase()
          return {...options, customization_id: found_customization.id}
        })
      }
      if (found_customization) {
        return found_customization.options.map(option => {
          let options = {...option, customization_id: found_customization.id}
          options.name = (t(`products.${get_decoded_string(found_customization.name)}_options.${get_decoded_string(option.name)}`)).toUpperCase()
          return {...options, customization_id: found_customization.id}
        })
      } else {
        return []
      }
    }
    if (product?.name === 'Koivu T-sarja') {
      const found_customization = customizations.find(item => item.id === customization.customization_id)
      if (customization.name === '3. Jalat') {
        if (found_customization) {
          // Check if model is T-1020XL
          const selected_model = product?.customizations.find(item => (item.name === '1. Malli'))
          const model = customizations.find(item => (item.id === selected_model.customization_id)).options.find(item => (item.id === selected_model.selected_option_id))
          // T-1020XL selected hide 'korkeat jalat'
          if (model && model.name === 'T-1020XL') {
            return found_customization.options.map(option => {
              if (option.name === 'Korkeat jalat') {
                return null
              } else {
                return {...option, customization_id: found_customization.id}
              }
            }).filter(item => item !== null)
          } else {
            return found_customization.options.map(option => {
              return {...option, customization_id: found_customization.id}
            })
          }
        } else {
          return []
        }
      }
    }
    if (product?.name === 'Koivu P-sarja') {
      if (customization.name === '4. Kasviryhmä') {
        const found_customization = customizations.find(item => item.id === customization.customization_id)
        if (found_customization) {
          const selected_model = product?.customizations.find(item => (item.name === '1. Malli'))
          const model = customizations.find(item => (item.id === selected_model.customization_id)).options.find(item => (item.id === selected_model.selected_option_id))
          if (model && model.name === 'P-380' || model.name === 'P-380XL') {
            // return S only
            return found_customization.options.map(option => {
              if (option.name === 'S') {
                return {...option, customization_id: found_customization.id}
              } else {
                return null
              }
            }).filter(item => item !== null)
          } else if (model && model.name === 'P-445') {
            // return M only
            return found_customization.options.map(option => {
              if (option.name === 'M') {
                return {...option, customization_id: found_customization.id}
              } else {
                return null
              }
            }).filter(item => item !== null)
          } 
          else if (model && model.name === 'P-550') {
            // return L only
            return found_customization.options.map(option => {
              if (option.name === 'L - Puuvartinen' || option.name === 'L - Pensanmainen') {
                return {...option, customization_id: found_customization.id}
              } else {
                return null
              }
            }).filter(item => item !== null)
          }
        }
      }
    }
    if (customizations) {
      const found_customization = customizations.find(item => item.id === customization.customization_id)
      if (found_customization) {
        return found_customization.options.map(option => {
          let options = {...option, customization_id: found_customization.id}
          if (get_decoded_string(found_customization.name)=== 'korkeus') {
            options.name = (t(`products.${get_decoded_string(found_customization.name)}_options.k${get_decoded_string(option.name)}`)).toUpperCase()
          } 
          else if (get_decoded_string(found_customization.name) === 'leveys') {
            options.name = (t(`products.${get_decoded_string(found_customization.name)}_options.l${get_decoded_string(option.name)}`)).toUpperCase()
          } else {
            options.name = (t(`products.${get_decoded_string(found_customization.name)}_options.${get_decoded_string(option.name)}`)).toUpperCase()
          }
          return options
        })
      } else {
        return []
      }
    }
  }

  const get_selected_customization_option = (customization) => {
    if (customizations) {
      const found_customization = customizations.find(item => item.id === customization.customization_id)
      if (found_customization) {
        const selected_option = found_customization.options.find(item => item.id === customization.selected[0].selected_option_id)
        if (selected_option) {
          let name = selected_option.name
          if (get_decoded_string(found_customization.name)=== 'korkeus') {
            name = (t(`products.${get_decoded_string(found_customization.name)}_options.k${get_decoded_string(selected_option.name)}`)).toUpperCase()
          } 
          else if (get_decoded_string(found_customization.name) === 'leveys') {
            name = (t(`products.${get_decoded_string(found_customization.name)}_options.l${get_decoded_string(selected_option.name)}`)).toUpperCase()
          } else {
            name = (t(`products.${get_decoded_string(found_customization.name)}_options.${get_decoded_string(selected_option.name)}`)).toUpperCase()
          }
          return {...selected_option, customization_id: found_customization.id, name: name}
        }
      } 
    }
  }

  function group_by_customization_id(customizations) {
    const grouped = [];

    customizations.forEach((item) => {
      // Check if an entry for the current customization_id already exists in the grouped array
      let existingGroup = grouped.find(group => group.customization_id === item.customization_id)
  
      if (existingGroup) {
        // If a group exists, add the customization_id, selected_option_id, and value to its "selected" array
        existingGroup.selected.push({
          customization_id: item.customization_id,
          selected_option_id: item.selected_option_id,
          value: item.value
        })
      } else {
        // If no group exists, create a new one and include the "selected" array
        grouped.push({
          customization_id: item.customization_id,
          date_created: item.date_created,
          date_modified: item.date_modified,
          id: item.id,
          name: item.name,
          selected: item.selected_option_id
            ? [{
                customization_id: item.customization_id,
                selected_option_id: item.selected_option_id,
                value: item.value
              }]
            : []
        })
      }
    })
  
    return grouped;
  }

  return (
    <div
      className={`product-list-product-entry ${page === 'inquiry' ? 'inquiry' : ''}`}
      key={product.space_id + ' - ' + product.id}
    >
      {/* product entry left content */}
      <div className={`product-list-product-entry-left-content ${page === 'inquiry' ? 'inquiry' : ''}`}>
        <div className={`product-list-product-entry-left-content-card ${page === 'inquiry' ? 'inquiry' : ''}`}>
          {/* product overview */}
          <div className='product-list-product-entry-left-content-card-overview'>
            <div className='product-list-product-entry-left-content-card-details-header mobile'>
              <div className='product-list-product-entry-left-content-card-details-header-title'>
                {t(`${get_decoded_string(product.name)}.name`)}
              </div>
              <div className='product-list-product-entry-left-content-card-details-header-dropdown'>
                <CustomDropdown
                  customClass='space-dropdown'
                  options={spaces}
                  selected={get_selected_space(product)}
                  useUpperCase
                  onClickHandler={(val) =>
                    update_product_space(product, val)
                  }
                  disabled={current_project.can_edit === false}
                />
              </div>
            </div>
            {product.imgSrc ? (
              <img
                src={product.imgSrc}
                alt={`product - ${product.name}`}
                className='product-list-product-entry-left-content-card-overview-img'
              />
            ) : (
              <div className='product-list-product-entry-left-content-card-overview-img'></div>
            )}
            { showProductOverViewBtn && (
              <div className='product-list-product-entry-left-content-card-overview-button'>
                <CustomButton
                  transparent={true}
                  button_text={t('project.productOverview').toUpperCase()}
                  onClickHandler={() =>
                    goToProductDetails(product)
                  }
                  contentClass='product-overview'
                />
              </div>
              )
            }
          </div>

          {/* product details */}
          <div className='product-list-product-entry-left-content-card-details'>
            <div className='product-list-product-entry-left-content-card-details-header'>
              <div className='product-list-product-entry-left-content-card-details-header-title'>
                {t(`${get_decoded_string(product.name)}.name`)}
              </div>
              <div className='product-list-product-entry-left-content-card-details-header-dropdown'>
                <CustomDropdown
                  customClass='space-dropdown'
                  options={spaces}
                  selected={get_selected_space(product)}
                  useUpperCase
                  onClickHandler={(val) =>
                    update_product_space(product, val)
                  }
                  disabled={current_project.can_edit === false}
                />
              </div>
            </div>
            <div className={`product-list-product-entry-left-content-card-details-attributes-label ${page === 'inquiry' ? 'inquiry' : ''}`}>
              {t('project.yourSelection').toUpperCase()}
            </div>
            <div className='product-list-product-entry-left-content-card-details-attributes'>
              {product.customizations &&
                group_by_customization_id(product.customizations).map((customization, index) => {
                  if (product?.name === 'Viherpysäkki') {
                    let configuration = null
                    if (i18n.exists(`${get_decoded_string(product.name)}.configuration`)) {
                      configuration = i18n.t(`${get_decoded_string(product.name)}.configuration`, { returnObjects: true })
                    }
                    return (
                      <div
                        className={`product-list-product-entry-left-content-card-details-attributes-entry viherpysakki ${page === 'inquiry' ? 'inquiry' : ''}`}
                        key={customization.id}
                      >
                        <div className={`product-list-product-entry-left-content-card-details-attributes-entry-label ${page === 'inquiry' ? 'inquiry' : ''}`}>
                          {`${index+1}. ${t(`products.${get_decoded_string(customization.name)}`).toUpperCase()}:`}
                        </div>
                        <div className={`product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper ${isEditing ? 'gap' : ''} ${page === 'inquiry' ? 'inquiry' : ''}`}>
                          {isEditing ?
                          (
                            customization.selected.map((item, itemIndex) => {
                              return (
                                <div
                                  className='product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper-container'
                                  key={itemIndex}>
                                  <div className='product-list-product-entry-left-content-adjust-button no-select'>
                                    <div 
                                      className={`product-list-product-entry-left-content-adjust-button-icon`}
                                      onClick={() => update_product_customization(product, item, 'subtract')}
                                    >
                                      <MinusIcon />
                                    </div>
                                    <div className='product-list-product-entry-left-content-adjust-button-value'>
                                      {item.value}
                                    </div>
                                    <div
                                      className='product-list-product-entry-left-content-adjust-button-icon'
                                      onClick={() => update_product_customization(product, item, 'add')}
                                    >
                                      <PlusIcon />
                                    </div>
                                  </div>
                                  <div className='product-list-product-entry-left-content-card-details-attributes-entry-value'>
                                    {get_selected_customization(item, customization.selected_option_id, configuration[index], itemIndex, false, true)}
                                  </div>
                                </div>
                              )
                            })
                          ) : (
                            customization.selected.map((item, itemIndex) => {
                                return (
                                  <React.Fragment key={itemIndex}>
                                    <div className='product-list-product-entry-left-content-card-details-attributes-entry-value'>
                                      {get_selected_customization(item, customization.selected_option_id, configuration[index], itemIndex)}
                                    </div>
                                  </React.Fragment>
                                )
                              })
                          )
                          }
                          
                        </div>
                      </div>
                    )
                  } else if (product?.name === 'Viherseinä') {
                    let configuration = null
                      if (i18n.exists(`${get_decoded_string(product.name)}.configurationProject`)) {
                        configuration = i18n.t(`${get_decoded_string(product.name)}.configurationProject`, { returnObjects: true })
                      }
                      return (
                        <div
                          className={`product-list-product-entry-left-content-card-details-attributes-entry ${page === 'inquiry' ? 'inquiry' : ''}`}
                          key={customization.id}
                        >
                          <div className={`product-list-product-entry-left-content-card-details-attributes-entry-label ${page === 'inquiry' ? 'inquiry' : ''}`}>
                            {`${index+1}. ${t(`products.${get_decoded_string(customization.name)}`).toUpperCase()}:`}
                          </div>
                          <div className={`product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper ${isEditing ? 'gap' : ''} ${page === 'inquiry' ? 'inquiry' : ''}`}>
                            { isEditing ?
                              (
                                customization.name ===  "1. Leveys" ?
                                (
                                  customization.selected.map((item, itemIndex) => {
                                    return (
                                      <div
                                        className='product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper-container'
                                        key={itemIndex}>
                                        <div className='product-list-product-entry-left-content-adjust-button no-select'>
                                          <div 
                                            className={`product-list-product-entry-left-content-adjust-button-icon`}
                                            onClick={() => update_product_customization(product, item, 'subtract')}
                                          >
                                            <MinusIcon />
                                          </div>
                                          <div className='product-list-product-entry-left-content-adjust-button-value'>
                                            {item.value}
                                          </div>
                                          <div
                                            className='product-list-product-entry-left-content-adjust-button-icon'
                                            onClick={() => update_product_customization(product, item, 'add')}
                                          >
                                            <PlusIcon />
                                          </div>
                                        </div>
                                        <div className='product-list-product-entry-left-content-card-details-attributes-entry-value'>
                                          {get_selected_customization(item, item.selected_option_id)}
                                        </div>
                                      </div>
                                    )
                                  })
                                ) : (
                                  <CustomDropdown
                                    customClass='product-edit-dropdown'
                                    options={get_customization_options(customization)}
                                    selected={get_selected_customization_option(customization)}
                                    useUpperCase
                                    onClickHandler={(val) =>
                                      update_product_customization(product, val)
                                    }
                                  />
                                )
                              ) : (
                                customization.name ===  "1. Leveys" ?
                                (
                                  <div className='product-list-product-entry-left-content-card-details-attributes-entry-value'>
                                    {get_selected_customization(customization, null, configuration[0])}
                                  </div>
                                ) : (
                                  customization.selected.map((item, itemIndex) => {
                                    return (
                                      <React.Fragment key={itemIndex}>
                                        <div className='product-list-product-entry-left-content-card-details-attributes-entry-value'>
                                          {get_selected_customization(item, item.selected_option_id)}
                                        </div>
                                      </React.Fragment>
                                    )
                                  })
                                )
                              )
                            }
                          </div>
                        </div>
                      )
                  } else if (product?.name === 'Räätälöitävä viherseinä' || product?.name === 'Räätälöitävä sammalseinä') {
                      let configuration = null
                      if (i18n.exists(`${get_decoded_string(product.name)}.configuration`)) {
                        configuration = i18n.t(`${get_decoded_string(product.name)}.configuration`, { returnObjects: true })
                      }
                      return (
                        <div
                          className={`product-list-product-entry-left-content-card-details-attributes-entry ${page === 'inquiry' ? 'inquiry' : ''}`}
                          key={customization.id}
                        >
                          <div className={`product-list-product-entry-left-content-card-details-attributes-entry-label ${page === 'inquiry' ? 'inquiry' : ''}`}>
                            {`${index+1}. ${t(`products.${get_decoded_string(customization.name)}`).toUpperCase()}:`}
                          </div>
                          <div className={`product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper ${page === 'inquiry' ? 'inquiry' : ''}`}>
                            { isEditing ?
                              (
                                customization.selected.map((item, itemIndex) => {
                                  if (customization.name === '3. Kustomointi') {
                                    // setText(item.value)
                                    return (
                                      <React.Fragment key={itemIndex}>
                                        <div
                                          className='product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper-container'
                                          key={itemIndex}
                                        >
                                          <textarea
                                            onChange={(event) => handleOptionChangeText(event, product, item)}
                                            value={text}
                                          />
                                        </div>
                                      </React.Fragment>
                                    )
                                  }
                                  return (
                                    <React.Fragment key={itemIndex}>
                                      <div
                                        className='product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper-container'
                                        key={itemIndex}>
                                        <div className='product-list-product-entry-left-content-adjust-button no-select'>
                                          <div 
                                            className={`product-list-product-entry-left-content-adjust-button-icon`}
                                            onClick={() => update_product_customization(product, item, 'subtract')}
                                          >
                                            <MinusIcon />
                                          </div>
                                          <div className='product-list-product-entry-left-content-adjust-button-value'>
                                            {item.value}
                                          </div>
                                          <div
                                            className='product-list-product-entry-left-content-adjust-button-icon'
                                            onClick={() => update_product_customization(product, item, 'add')}
                                          >
                                            <PlusIcon />
                                          </div>
                                        </div>
                                        <div className='product-list-product-entry-left-content-card-details-attributes-entry-value wrap'>
                                          {configuration[index].text.toUpperCase()}
                                        </div>
                                      </div>
                                      <div className='product-list-product-entry-left-content-card-details-attributes-entry-value-calculated'>
                                        {get_selected_customization(item, item.selected_option_id, configuration[index], null, false, false, true)}
                                      </div>
                                    </React.Fragment>
                                  )
                                })
                              ) : (
                                customization.selected.map((item, itemIndex) => {
                                  return (
                                    <React.Fragment key={itemIndex}>
                                      <div className='product-list-product-entry-left-content-card-details-attributes-entry-value'>
                                        {configuration ? 
                                          get_selected_customization(item, item.selected_option_id, configuration[index])
                                        :
                                          get_selected_customization(item, item.selected_option_id)
                                        }
                                      </div>
                                    </React.Fragment>
                                  )
                                })
                              )
                            }
                          </div>
                        </div>
                      )
                  } else {
                    return (
                      <div
                        className={`product-list-product-entry-left-content-card-details-attributes-entry ${page === 'inquiry' ? 'inquiry' : ''}`}
                        key={customization.id}
                      >
                        <div className={`product-list-product-entry-left-content-card-details-attributes-entry-label ${page === 'inquiry' ? 'inquiry' : ''}`}>
                          {`${index+1}. ${t(`products.${get_decoded_string(customization.name)}`).toUpperCase()}:`}
                        </div>
                        <div className={`product-list-product-entry-left-content-card-details-attributes-entry-value-wrapper ${page === 'inquiry' ? 'inquiry' : ''}`}>
                          {isEditing ?
                            (
                              <CustomDropdown
                                customClass='product-edit-dropdown'
                                options={get_customization_options(customization)}
                                selected={get_selected_customization_option(customization)}
                                useUpperCase
                                onClickHandler={(val) =>
                                  update_product_customization(product, val)
                                }
                              />
                            ) : (
                              customization.selected.map((item, itemIndex) => {
                                return (
                                  <React.Fragment key={itemIndex}>
                                    <div className='product-list-product-entry-left-content-card-details-attributes-entry-value'>
                                      {get_selected_customization(item, item.selected_option_id)}
                                    </div>
                                  </React.Fragment>
                                )
                              })
                            )
                          }
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>
            <div className='product-list-product-entry-left-content-card-edit-btn-wrapper'>
              {isEditing ?
                (
                  <CustomButton
                    button_text={t('project.closeEditView').toUpperCase()}
                    purple={true}
                    contentClass='project-filter'
                    onClickHandler={toggleIsEditing}
                    disabled={current_project.can_edit === false}
                  />
                ) : (
                  <CustomButton
                    button_text={t('project.editYourSelection').toUpperCase()}
                    primary={true}
                    contentClass='project-filter'
                    onClickHandler={toggleIsEditing}
                    disabled={current_project.can_edit === false}
                  />
                )

              }
              
            </div>
          </div>
        </div>
      </div>

      {/* product entry right content */}
      <div className={`product-list-product-entry-right-content ${page === 'inquiry' ? 'inquiry' : ''}`}>
        <div className='product-list-product-entry-right-content-title mobile'>
        {t('project.quantity')}
        </div>
        <div className={`product-list-product-entry-right-content-adjust-button ${page === 'inquiry' ? 'inquiry' : ''} ${current_project.can_edit === false ? 'disabled' : ''}`}>
          <div
            className={`product-list-product-entry-right-content-adjust-button-icon ${
              quantity_is_zero(product) ? 'disabled' : ''
            }`}
            onClick={() => update_product_quantity(product, -1)}
          >
            <MinusIcon
              color={current_project.can_edit === false ? '#a3a3a3' : 'black'}
            />
          </div>
          <div className='product-list-product-entry-right-content-adjust-button-value'>
            {product.quantity}
          </div>
          <div
            className='product-list-product-entry-right-content-adjust-button-icon'
            onClick={() => update_product_quantity(product, 1)}
          >
            <PlusIcon
              color={current_project.can_edit === false ? '#a3a3a3' : 'black'}
            />
          </div>
        </div>
        <div className='product-list-product-entry-right-content-action'>
          <div
            className={`product-list-product-entry-right-content-action-link ${current_project.can_edit === false ? 'disabled' : ''}`}
            onClick={() =>
              toggleConfirmRemoveProductModal(product)
            }
          >
            {t('project.removeProduct')}
          </div>
          <div
            className={`product-list-product-entry-right-content-action-link ${current_project.can_edit === false ? 'disabled' : ''}`}
            onClick={() => goToProductPage(product)}
          >
            {t('project.createNewVersion')}
          </div>
          <div
            className={`product-list-product-entry-right-content-action-link copy ${current_project.can_edit === false ? 'disabled' : ''}`}
            onClick={() =>
              toggleAddProductToProjectModal(product)
            }
          >
            {t('project.copyToAnotherProject')}
          </div>
          <div
            className={`product-list-product-entry-right-content-action-link copy ${current_project.can_edit === false ? 'disabled' : ''}`}
            onClick={() =>
              toggleAddProductToProjectModal(product, true)
            }
          >
            {t('project.copyToAnotherSpace')}
          </div>
        </div>
      </div>
    </div>

  )
}


export default ProductEntry
