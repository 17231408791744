const management = {
  labelNewPassword: 'New password',
  labelConfirmPassword: 'Confirm password',
  changePassword: 'Change password',
  confirm: 'Confirm',
  cancel: 'Cancel',
  activate: 'Activate',
  deActivate: 'Deactivate',
  labelActive: 'Active',
  labelInactive: 'Inactive',
  labelUsers: 'Users',
  labelUserRequests: 'User Requests',
  open: 'Open',
  closed: 'Closed',
  approveNewUser: 'New user sign up',
  labelStatus: 'Status',
  labelRole: 'Role',
  labelName: 'Name',
  labelEmail: 'Email',
  signUp: 'Sign up',
  changePasswordSuccessMessage: 'Password changed!',
  genericChangePasswordError: 'Failed to change password, please try again!',
  closeBtn: 'Close',
  approveNewUserSuccessMessage: 'Handled new user sign up request!',
  genericNewUserError: 'Failed to send request, please try again!',
}

export default management
