import './styles.scss'
import { Link } from 'react-router-dom'

const FooterLink = (props) => {
  const { link, name, action } = props

  return (
    <>
      {link && (
        <Link
          className='footer-link-text'
          to={`/${link}`}
        >
          {name}
        </Link>
      )}
      {action && (
        <div className='footer-link-text' onClick={action}>
          {name}
        </div>
      )}
    </>
  )
}

export default FooterLink
