import { createSlice } from '@reduxjs/toolkit'

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    global_loading: true,
    snackbar: null,
  },
  reducers: {
    setGlobalLoading: (state, action) => {
      state.global_loading = action.payload
    },
    addSnackBar: (state, action) => {
      state.snackbar = action.payload
    },
    removeSnackBar: (state, action) => {
      state.snackbar = null
    },
  },
})

// Action creators are generated for each case reducer function
export const { setGlobalLoading, addSnackBar, removeSnackBar } = uiSlice.actions

export default uiSlice.reducer
