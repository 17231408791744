const login = {
  loginTitle: 'Login',
  loginBtn: 'LOGIN',
  labelEmail: 'Email',
  labelPassword: 'Password',
  forgotPassword: 'Forgot my password',
  newAccountTitle: 'Create an account',
  newAccountText: `Create an account and customise InnoGreen products to suit your needs. 
      With your account, you can design and save your products as complete solutions and 
      request a quote with just one click.`,
  newAccountBtn: 'CREATE ACCOUNT',
  createUserAccount: 'Create new user account',
  signUpTerms: 'We will process the requests manually and you will receive a confirmation of the creation of your account by email. By creating an account, you agree to the processing of your personal data in accordance with our ',
  signUpTermsLink: 'Privacy Policy.',
  requestNewAccountBtn: 'REQUEST',
  labelConfirmPassword: 'Confirm password',
  labelName: 'Name',
  labelCompany: 'Company',
  labelRole: 'Role',
  labelPhoneNumber: 'Phone number',
  labelSubscribeEmail: "I want to join InnoGreen's email list",
  errorEmailRequired: 'Email is required',
  errorPasswordRequired: 'Password is required',
  errorConfirmPasswordRequired: 'Confirm password is required',
  errorNameRequired: 'Name is required',
  errorCompanyRequired: 'Company is required',
  errorPasswordNotMatch: 'Password and confirm password do not match',
  genericSignUpError: 'Could not sign up, please try again!',
  logInCookiesWarning:
    'Login is disabled since you opted out the usage of cookies.',
  signUpCookiesWarning:
    'Signup is disabled since you opted out the usage of cookies.',
  cookiesChangeLink: 'Change your cookies setting here.',
  errorEmailInvalid: 'Email format is invalid',
  errorPasswordLength: 'Password length has to be at least 6 characters',
  forgotPasswordTitle: 'Request password reset',
  confirmBtn: 'Confirm',
  cancelBtn: 'Cancel',
  passwordResetRequestText:
    'Send password reset request to admin. Please confirm to proceed!',
  signUpSuccessMessage:
    'Thank you for registering! We will check the information and notify you by email when you can log in to our service.',
  closeBtn: 'Close',
  genericLogInError: 'Failed to log in, please try again!',
  genericPasswordResetError: 'Failed to send request, please try again!',
  passwordResetSuccessMessage:
    'Reset password request sent! We will notify you by email with the new password',
}

export default login
