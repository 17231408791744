import './styles.scss'
import React from 'react'
import { useNavigate, Link } from 'react-router-dom'

const TextCardGroup = (props) => {
  const {
    cardGroup,
    showText,
    setShowText,
  } = props

  const handleToggleShowText = (index) => {
    setShowText((prevState) =>
      prevState.map((value, i) => (i === index ? !value : value))
    )
  }
  
  return (
      <div className='text-card-group-container'>
        {cardGroup.map((item, index) => {
          if (item.type === 1) {
            return (
              <div className='text-card-group' key={index}>
                <div className='text-card-group-title'>{item.title}</div>
                <div
                  className={
                    showText[index] ? 
                      'text-card-group-text-container show'
                    : 
                      'text-card-group-text-container'
                  }
                >
                  <div 
                    className='text-card-group-text'
                  >
                    {item.text}
                  </div>
                </div>
                <br/>
                <div 
                  className='text-card-group-link'
                  onClick={() => handleToggleShowText(index)}
                >
                  {showText[index] ? item.hide : item.show}
                </div>
              </div>
            )
          }
          else if (item.type === 2) {
            return (
              <div className='text-card-group' key={index}>
                <div className='text-card-group-title'>{item.title}</div>
                <div
                  className={
                    showText[index] ? 
                      'text-card-group-text-container show'
                    : 
                      'text-card-group-text-container'
                  }
                >
                  <div className='text-card-group-text'>
                    {item.text.split('\\n').map((line, idx) => (
                      <React.Fragment key={idx}>
                        {line}
                        <br />
                        <br />
                      </React.Fragment>))
                    }
                  </div>
                </div>
                <br/>
                <div 
                  className='text-card-group-link'
                  onClick={() => handleToggleShowText(index)}
                >
                  {showText[index] ? item.hide : item.show}
                </div>
              </div>
            )
          }
        else if (item.type === 3) {
            const parts = item.text3.split('<Link>')
            return (
              <div className='text-card-group' key={index}>
                <div className='text-card-group-title'>{item.title}</div>
                <div 
                  className={
                    showText[index] ? 
                      'text-card-group-text-container show'
                    : 
                      'text-card-group-text-container'
                  }
                >
                  <div className='text-card-group-text'>
                    { item.text.map((obj, idx) => {
                      if (obj.link) {
                        const textParts = obj.text.split('<Link>')
                        if (obj.type === 1) {
                          return (
                            <React.Fragment key={idx}>
                              {textParts[0]}
                              <Link to={`/${obj.link}`}>{obj.linkText}</Link>
                              {textParts[1]}
                              <br />
                              <br />
                            </React.Fragment>
                          )
                        } else {
                          return (
                            <React.Fragment key={idx}>
                              {textParts[0]}
                              <a href={obj.link} target="_blank" rel="noopener noreferrer">
                                {obj.linkText}
                              </a>
                              {textParts[1]}
                              <br />
                              <br />
                            </React.Fragment>
                          )
                        }
                      } else {
                        return (
                          <React.Fragment key={idx}>
                            {obj.text}
                            <br />
                            <br />
                          </React.Fragment>
                        )
                      }
                    })

                    }
                  </div>
                  <div className='text-card-group-title'>{item.title2}</div>
                  <div className='text-card-group-text'>{item.text2}</div>
                  <div className='text-card-group-text'>
                    {parts[0]}
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      {item.linkText}
                    </a>
                    {parts[1]}
                  </div>
                </div>
                <br/>
                <div 
                  className='text-card-group-link'
                  onClick={() => handleToggleShowText(index)}
                >
                  {showText[index] ? item.hide : item.show}
                </div>
              </div>
            )
          }
        })}
      </div>
  )
}
export default TextCardGroup
