import { createSlice } from '@reduxjs/toolkit'
import { log_in, log_out, fetch_current_user, sign_up, update_user_information } from './asyncThunk'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    current_user: null,
    language: 'FI'
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.current_user = action.payload
    },
    setLanguage: (state, action) => {
      state.language = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(log_in.fulfilled, (state, action) => {
      state.current_user = action.payload.user
    })
    builder.addCase(log_out.fulfilled, (state, action) => {
      state.current_user = null
    })
    builder.addCase(fetch_current_user.fulfilled, (state, action) => {
      state.current_user = action.payload
    })
    builder.addCase(update_user_information.fulfilled, (state, action) => {
      state.current_user = action.payload.user
    })
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentUser, handleSignOut, setLanguage } = userSlice.actions

export default userSlice.reducer
