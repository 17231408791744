import './styles.scss'

import React, { useEffect, useState, useCallback, useRef } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import QuillEditor from '../../../../../components/QuillEditor'
import CustomTextInput from '../../../../../components/common/CustomTextInput'
import CustomHeaderImageInput from '../../../../../components/common/CustomHeaderImageInput'
import DynamicComponentManager from '../../../../../components/common/DynamicComponentManager'
import CustomSelectInput from '../../../../../components/common/CustomSelectInput'
import CustomButton from '../../../../../components/common/CustomButton'
import DeleteContentModal from '../../../../../components/DeleteContentModal'

import {
  IconButton,
  Paper,
  Popper,
  MenuItem,
  ClickAwayListener,
} from "@mui/material"
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import { setGlobalLoading } from '../../../../../store/uiSlice'

import { 
  add_reference,
  fetch_reference_groups,
  fetch_management_reference,
  edit_reference,
  fetch_products
} from '../../../../../store/asyncThunk'

const dropdownOptions = [
  {
    id: 1,
    name: 'SAVE AND PUBLISH',
    saveAs: 'publish'
  },
  {
    id: 2,
    name: 'SAVE AS DRAFT',
    saveAs: 'draft'
  }
]

const ReferenceEditor = (props) => {
  const {
    handleSetSelectedTab
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [components, setComponents] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [content, setContent]  = useState(
    {
      text: '',
      title: '',
      text_en: '',
      title_en: '',
      imgSrc: null, // For header/Card image
      referenceGroup: [],
      productGroup: [],
      body: [], // For components
      usedProducts: []
    }
  )
  const [searchParams] = useSearchParams();
  const reference_id = searchParams.get('reference');
  const selected_reference = useSelector((state) => state.management.selected_reference)
  const {referenceGroup, productGroup} = useSelector((state) => state.reference.referenceGroup)
  const products = useSelector((state) => state.product.products)
  const [errorList, setErrorList] = useState([])
  const [requested_success, setRequestedSuccess] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [disableDelete, setDisableDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const current_user = useSelector((state) => state.user.current_user)
  const global_loading = useSelector((state) => state.ui.global_loading)
  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('FI')
  const quillFIRef = useRef(null)
  const quillENRef = useRef(null)
  const titleFIRef = useRef(null)
  const titleENRef = useRef(null)
  const anchorRef = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleClose = () => {
    setOpenDropdown(false)
  }

  const handleClick = () => {
    setOpenDropdown(true)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!current_user) {
      if (i18n.language === 'FI')
        navigate('/kirjaudu_sisaan')
      else if (i18n.language === 'EN')
        navigate('/login')
    } else if (current_user && ![1, 5].includes(current_user.role)) {
      navigate('/account')
    }
  }, [current_user, navigate])
  
  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      dispatch(fetch_reference_groups())
      dispatch(fetch_products({small_images: true}))
      if (reference_id) {
        await dispatch(fetch_management_reference(reference_id))
        .then(res => {
          if (!res.error) {
            const { reference } = res.payload
            const { used_products, ...newReferenceObj } = reference;
            const selectedProducts = used_products.products.map((item) => ({
              id: item.id,
              tag: item.name,
            }))

            setContent({...newReferenceObj, usedProducts:selectedProducts})
            setComponents(newReferenceObj.body.map(({ type, ...rest }) => ({
              identifier: rest.index,
              type: rest.component,
              ...rest
            })))
          } else {
            setErrorList([t('error.noAccess')])
            
          }
        })
      }
      dispatch(setGlobalLoading(false))
    })()
  }, [reference_id])

  useEffect(() => {
    if (products) {
      const options = products.map((item) => ({
        id: item.id,
        tag: item.name,
      }))
      setProductOptions(options)
    }
  }, [products])

  const toggleDeleteContentModal = () => {
    setShowDeleteContentModal(!showDeleteContentModal)
  }

  const handleContentChange = useCallback((key, value, action = 'update', language='FI') => {
    if (action === 'headerImg') {
      setContent((prevContent) => {
        return { ...prevContent, [key]: value, img_name: value.name }
      })
    } 
    else if (language === 'EN') {
      setContent((prevContent) => {
        return { ...prevContent, [`${key}_en`]: value }
      })
    }
    else {
      setContent((prevContent) => {
        return { ...prevContent, [key]: value }
      })
    }
  }, [])

  const handleContentChangeDynamic = (identifier, object) => {
    setRequestedSuccess(false)
    setContent((prevContent) => {
      const updatedBody = prevContent.body.map((item) => {
        // Check if the item has the same identifier
        if (item.identifier === identifier) {
          // Overwrite the item with the new object data
          return { ...item, ...object }
        }
        return item
      })
  
      // If the identifier was not found, add a new entry to the body
      const bodyWithNewItem = prevContent.body.some((item) => item.identifier === identifier)
        ? updatedBody
        : [...prevContent.body, { identifier, ...object }]

      // Recalculate positions
      const bodyWithPositions = bodyWithNewItem
      .map((item, index) => ({ ...item, index: index + 1 }))
  
      return {
        ...prevContent,
        body: bodyWithPositions,
      }
    })
  }

  const handleAddComponent = (componentType) => {
    setRequestedSuccess(false)
    setComponents((prevComponents) => {
      // Find the highest Identifier currently in the array, and add 1 for the new component
      const newIdentifier = prevComponents.length > 0 
        ? Math.max(...prevComponents.map(component => component.identifier)) + 1
        : 1 // If no components, start with Identifier 1
  
      return [
        ...prevComponents,
        { identifier: newIdentifier, type: componentType },
      ]
    })
  }
  const handleDeleteComponent = (identifier) => {
    setRequestedSuccess(false)
    setComponents((prevComponents) => 
      prevComponents.filter((component) => component.identifier !== identifier) // Remove from components
    )
    
    setContent((prevContent) => {
      const updatedBody = prevContent.body
        .filter((component) => component.identifier !== identifier) // Remove from body by identifier
        .map((item, index) => ({ ...item, index: index + 1 })) // Recalculate positions
  
      return {
        ...prevContent,
        body: updatedBody,
      }
    })
  }

  const disableUploadButton = () => {
    if (content.title === '') return true
    if (content.imgSrc === null) return true
  }

  const disableSaveButton = () => {
    if (content.title === '') return true
    if (content.imgSrc === null) return true
  }

  function base64ToFile(base64String, filename) {
    // Check if the input is already a File object
    if (base64String instanceof File) {
      return base64String
    }

    const [_, base64Data] = base64String.split(',')
    const binaryData = atob(base64Data)
    const uint8Array = new Uint8Array(binaryData.length)

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i)
    }
  
    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'image/png' })
  
    // Create a File from the Blob
    const file = new File([blob], filename, { type: 'image/png' })
  
    return file
  }

  const handleUpload = async (saveAs) => {
    setErrorList([])
    setRequestedSuccess(false)
    const formData = new FormData()
    // Shallow copy the content object as we cant update the state directly anymore before the upload is done
    var updatedContent = { ...content}
    if (updatedContent.imgSrc) {
      const image = base64ToFile(updatedContent.imgSrc, updatedContent.img_name)
      if (image) {
        updatedContent = { ...updatedContent, imgSrc: image }
        formData.append(`header-img`, image)
      }
    }

    const header = {
      slug: updatedContent.slug,
      title: updatedContent.title,
      text: updatedContent.text,
      referenceGroup: updatedContent.referenceGroup,
      productGroup: updatedContent.productGroup,
      usedProducts: updatedContent.usedProducts,
      text_en: updatedContent.text_en,
      title_en: updatedContent.title_en,
      saveAs: saveAs
    }
    formData.append('header', JSON.stringify(header))
    var body = []
    if (updatedContent.body && updatedContent.body.length > 0) {
      updatedContent.body.forEach((item, index) => {
        body.push({
          component: item.component,
          id: item.id,
          index: item.index,
          length: item.length,
          images: [],
          identifier: index,
        })
        item.images.forEach((bodyImage, idx) => {
          if (bodyImage.img_name) {
            body[index].images.push({
              alt: bodyImage.alt,
              img_name: bodyImage?.img_name
            })
            var imageFile = base64ToFile(bodyImage.previewUrl, bodyImage.img_name)
            formData.append(`${index+1}-${idx+1}`, imageFile)
          } else {
            body[index].images.push({
              alt: bodyImage.alt,
              img_name: bodyImage.file.filename
            })
            formData.append(`${index+1}-${idx+1}`, bodyImage.file)
          }
        })
      })
    }
    formData.append('body', JSON.stringify(body))
    
    if (selected_reference.slug) {
      // Update existing reference
      setIsLoading(true)
      await dispatch(
        edit_reference({payload: formData, slug:selected_reference.slug})
      ).then((res) => {
        if (!res.error) {
          setErrorList([])
          // Check if slug has changed
          if (res.payload.reference.slug !== selected_reference.slug) {
            navigate(`/referenssit/${res.payload.reference.slug}/editor`, { replace: true });
          }
          setRequestedSuccess(true)
          setTimeout(() => {
            setRequestedSuccess(false)
          }, 2000);
        } else {
          setErrorList([t('error.noAccess')])
        }
      })
      setIsLoading(false)
    } else {
      // Create new reference
      setIsLoading(true)
      await dispatch(
        add_reference(formData)
      ).then((res) => {
        if (!res.error) {
          setErrorList([])
          setRequestedSuccess(true)
          setTimeout(() => {
            setRequestedSuccess(false)
          }, 2000);
        } else {
          if (res?.payload?.data?.error_code) {
            setErrorList([t(res?.payload?.data?.error_code)])
          } else if (res?.payload?.data?.message) {
            setErrorList([t(res?.payload?.data?.message)])
          } else {
            setErrorList([t('error.noAccess')])
          }
        }
      })
      setIsLoading(false)
    }
  }

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language)
    if (language === 'FI') {
      quillENRef.current.style.display = 'none'
      quillFIRef.current.style.display = 'block'
      titleENRef.current.style.display = 'none'
      titleFIRef.current.style.display = 'block'
    }
    if (language === 'EN') {
      quillFIRef.current.style.display = 'none'
      quillENRef.current.style.display = 'block'
      titleFIRef.current.style.display = 'none'
      titleENRef.current.style.display = 'block'
    }
  }

  const handleCancelClick = () => {
    handleSetSelectedTab('Reference')
  }

  const btnStyle = {
    padding: '0.5rem 2rem',
  }

  if (global_loading) {
    return (
      <div className='customize-reference-container'>
          <div className='customize-reference-body-container center'>
            <ClipLoader color='#1a73e8' />
          </div>
      </div>
    )
  }

  return (
    <>
      { isLoading ?
        (
          <div className='customize-reference-loading'>
            <ClipLoader color='#1a73e8' />
          </div>
        ) : (
          <></>
        )
      }
      <div className='customize-reference-container'>
        <div className='customize-reference-body-container'>
          {errorList.length > 0 && (
            <>
                {errorList.map((error) => {
                  return (
                    <div className='customize-blog-header-button-error' key={error}>
                      - {error}
                    </div>
                  )
                })}
            </>
          )}
          {requested_success && (
            <div className='customize-blog-header-button-success'>
              Saved!
            </div>
          )}
          {deleteSuccess && (
            <div className='customize-blog-header-button-success'>
              Deleted! Navigating to References...
            </div>
          )}
          <div className='customize-reference-header-language-wrapper'>
            <div className='customize-reference-header-language-selector'>
              <div 
                className={`customize-reference-header-language ${selectedLanguage === 'FI' ? 'active' : ''}`}
                onClick={() => handleSelectLanguage('FI')}
              >
                FI
              </div>
              <div 
                className={`customize-reference-header-language ${selectedLanguage === 'EN' ? 'active' : ''}`}
                onClick={() => handleSelectLanguage('EN')}
              >
                EN
              </div>
            </div>
          </div>
          <div className='customize-reference-header-container'>
            <div className='customize-reference-header-text-container'>
              <div className='customize-reference-header-left-container'>
                  <div className='customize-reference-header-left-button-wrapper'>

                    { selected_reference?.slug ?
                      (
                        <>
                          <CustomButton
                            button_text='Save'
                            onClickHandler={() => handleUpload()}
                            disabled={disableUploadButton()}
                            yellow={true}
                            customStyles={btnStyle}
                          />
                          <CustomButton
                            button_text='Delete'
                            onClickHandler={() => toggleDeleteContentModal()}
                            danger
                            disabled={disableDelete}
                            customStyles={btnStyle}
                          />
                        </>
                      ) : (
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                          <div ref={anchorRef} className={`customize-reference-header-left-save-button-container ${disableSaveButton() ? 'disabled' : ''}`}>
                            <div
                              className='customize-reference-header-left-save-button'
                              onClick={() => handleUpload('draft')}
                            >
                              SAVE AS DRAFT
                            </div>
                            <div>
                            |
                            </div>
                            <div>
                              <IconButton
                                onClick={handleClick}
                                disableRipple={true}
                                sx={{
                                  padding: '0',
                                }}
                              >
                                <KeyboardArrowDownOutlinedIcon
                                  sx={
                                    disableSaveButton() ? { color: 'grey' } : { color: 'black' }
                                  }
                                />
                                  <Popper
                                    open={openDropdown}
                                    anchorEl={anchorRef.current}
                                    placement="bottom"
                                  >
                                    <ClickAwayListener
                                      onClickAway={handleClose}
                                    >
                                      <Paper sx={{ p: 1 }}>
                                        { dropdownOptions.map((option) => (
                                            <MenuItem
                                              key={option.id}
                                              disableRipple
                                              onClick={() => handleUpload(option.saveAs)}
                                              sx={{ 
                                                '&.Mui-selected': {
                                                  backgroundColor: '#D6ACFC',
                                                },
                                              }}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          ))
                                      }
                                      </Paper>
                                    </ClickAwayListener>
                                  </Popper>
                              </IconButton>
                            </div>
                          </div>
                          <CustomButton
                            button_text='Cancel'
                            onClickHandler={() => handleCancelClick()}
                            danger
                          />
                        </div>
                      )
                    }
                  </div>
                  <CustomTextInput
                    id={1}
                    label='Reference url'
                    type='slug'
                    inputWidth={2}
                    onContentChange={handleContentChange}
                    value={content.slug ?? ''}
                  />
                  <div ref={titleFIRef}>
                    <CustomTextInput
                      id={1}
                      label='Reference title'
                      type='headerTitle'
                      inputWidth={2}
                      onContentChange={handleContentChange}
                      value={content.title}
                    />
                  </div>
                  <div
                    className='customize-reference-header-title-en'
                    ref={titleENRef}
                  >
                    <CustomTextInput
                      id={1}
                      label='Reference title'
                      type='headerTitle'
                      inputWidth={2}
                      onContentChange={handleContentChange}
                      value={content.title_en}
                      language='EN'
                    />
                  </div>
                  
                <div className='customize-reference-header-left-tag-container'>
                  <CustomSelectInput
                    options={referenceGroup}
                    onContentChange={handleContentChange}
                    label='Reference Group'
                    type='referenceGroup'
                    selected_options={content.referenceGroup}
                  />
                  <div>|</div>
                  <CustomSelectInput
                    options={productGroup}
                    onContentChange={handleContentChange}
                    label='Product Group'
                    type='productGroup'
                    selected_options={content.productGroup}
                  />
                </div>
                <div className='customize-reference-header-left-product'>
                  <CustomSelectInput
                    options={productOptions}
                    onContentChange={handleContentChange}
                    label='Used Products'
                    type='usedProducts'
                    selected_options={content.usedProducts}
                  />
                </div>
              </div>
              <div className='customize-reference-header-right-container'>
                <div className='customize-reference-header-body-text'>
                  <div ref={quillFIRef}>
                    <QuillEditor
                      id={1}
                      initialContent={content.text}
                      onContentChange={handleContentChange}
                    />
                  </div>
                  <div
                    className='customize-reference-header-body-en'
                    ref={quillENRef}
                  >
                    <QuillEditor
                      id={2}
                      initialContent={content.text_en}
                      onContentChange={handleContentChange}
                      language='EN'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='customize-reference-header-image-wrapper'>
              <CustomHeaderImageInput
                label='Add Header Image'
                id={1}
                onContentChange={handleContentChange}
                imageSrc={content.imgSrc}
              />
            </div>
          </div>

          <DynamicComponentManager
            components={components}
            setComponents={setComponents}
            type='reference'
            onContentChange={handleContentChangeDynamic}
            addComponent={handleAddComponent}
            deleteComponent={handleDeleteComponent}
          />
          {/* <h2>Content Data:</h2>
          <pre>{JSON.stringify(content, null, 2)}</pre>  */}
        </div>
      </div>
      {showDeleteContentModal && (
        <DeleteContentModal
          showing={showDeleteContentModal}
          closeModal={toggleDeleteContentModal}
          type='reference'
          setIsLoading={setIsLoading}
          setDisableDelete={setDisableDelete}
          setDeleteSuccess={setDeleteSuccess}
          setErrorList={setErrorList}
        />
      )}
    </>
  )
}

export default ReferenceEditor