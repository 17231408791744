import CustomModal from '../common/CustomModal'
import CustomButton from '../common/CustomButton'
import { PlusIcon } from '../Icons'
import './styles.scss'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { create_space, edit_space, delete_space } from '../../store/asyncThunk'
import { useTranslation } from 'react-i18next'

const newSpaceInitialState = {
  name: '',
}

const EditSpaceModal = ({ showing, closeModal, spaces }) => {
  const [showAddNewSpace, setShowAddNewSpace] = useState(false)
  const [newSpace, setNewSpace] = useState(newSpaceInitialState)
  const [showRenameSpaceModal, setShowRenameSpaceModal] = useState(false)
  const [selectedSpace, setSelectedSpace] = useState(null)
  const [showDeleteSpaceModal, setShowDeleteSpaceModal] = useState(false)
  const dispatch = useDispatch()
  const current_project = useSelector((state) => state.project.current_project)
  const { t } = useTranslation()
  const [addSpacerrorList, setAddSpaceErrorList] = useState([])
  const [renameSpacerrorList, setRenameSpaceErrorList] = useState([])
  const [deleteSpacerrorList, setDeleteSpaceErrorList] = useState([])

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const toggleAddNewSpace = () => {
    setAddSpaceErrorList([])
    if (!showAddNewSpace) {
      setNewSpace(newSpaceInitialState)
    }
    setShowAddNewSpace(!showAddNewSpace)
  }

  const updateNewSpace = ({ name }) => {
    if (name !== undefined) {
      setNewSpace({ ...newSpace, name: name })
    }
  }

  const handleCreateNewSpaceBtnClick = async () => {
    if (current_project) {
      const new_space = {
        ...newSpace,
        assigned_products: [],
      }

      await dispatch(
        create_space({ space: new_space, project_id: current_project.id })
      ).then((res) => {
        if (!res.error) {
          setNewSpace(newSpaceInitialState)
          toggleAddNewSpace()
        } else {
          setAddSpaceErrorList([t('project.genericAddSpaceError')])
        }
      })
    }
  }

  const toggleRenameSpaceModal = (space) => {
    setRenameSpaceErrorList([])
    if (space && !showRenameSpaceModal) {
      setSelectedSpace(space)
    } else {
      setSelectedSpace(null)
    }

    setShowRenameSpaceModal(!showRenameSpaceModal)
  }

  const updateSpace = ({ name }) => {
    if (name !== undefined) {
      setSelectedSpace({ ...selectedSpace, name: name })
    }
  }

  const confirmEditSpace = () => {
    dispatch(
      edit_space({
        space_id: selectedSpace.id,
        project_id: current_project.id,
        payload: { name: selectedSpace.name },
      })
    ).then((res) => {
      if (!res.error) {
        setSelectedSpace(null)
        toggleRenameSpaceModal()
      } else {
        setRenameSpaceErrorList([t('project.genericRenameSpaceError')])
      }
    })
  }

  const toggleDeleteSpaceModal = (space) => {
    setDeleteSpaceErrorList([])
    if (space && !showDeleteSpaceModal) {
      setSelectedSpace(space)
    } else {
      setSelectedSpace(null)
    }

    setShowDeleteSpaceModal(!showDeleteSpaceModal)
  }

  const confirmDeleteSpace = () => {
    dispatch(
      delete_space({
        space_id: selectedSpace.id,
        project_id: current_project.id,
      })
    ).then((res) => {
      if (!res.error) {
        setSelectedSpace(null)
        toggleDeleteSpaceModal()
      } else {
        setDeleteSpaceErrorList([t('project.genericDeleteSpaceError')])
      }
    })
  }

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  return (
    <>
      {showing && (
        <CustomModal showing={showing} closeModal={close}>
          <div className='edit-space-modal-content'>
            <div className='edit-space-modal-title'>
              {t('project.editSpaces')}
            </div>
            <div
              className='edit-space-modal-add-btn'
              onClick={() => toggleAddNewSpace()}
            >
              {/* <FontAwesomeIcon icon={faPlus} /> Create a new space */}
              <PlusIcon size={16} /> {t('project.createNewSpace')}
            </div>

            <div
              className={`edit-space-modal-new-space-container ${
                showAddNewSpace ? 'show' : ''
              }`}
            >
              <div className='edit-space-modal-new-space-content'>
                {addSpacerrorList.length > 0 && (
                  <div className='edit-space-error-container'>
                    {addSpacerrorList.map((error) => {
                      return (
                        <div className='edit-space-error-entry' key={error}>
                          - {error}
                        </div>
                      )
                    })}
                  </div>
                )}
                <div className='edit-space-modal-new-space-input-group'>
                  <label className='edit-space-modal-new-space-input-label'>
                    {t('project.nameOfSpace')}*
                  </label>
                  <input
                    type='text'
                    value={newSpace.name}
                    className='edit-space-modal-new-space-input-field'
                    onInput={(e) => updateNewSpace({ name: e.target.value })}
                  ></input>
                </div>
              </div>

              <div className='edit-space-modal-new-space-btn-group'>
                <CustomButton
                  button_text={t('project.add').toUpperCase()}
                  disabled={newSpace.name.trim().length === 0}
                  onClickHandler={() => handleCreateNewSpaceBtnClick()}
                />
                <CustomButton
                  button_text={t('project.cancel').toUpperCase()}
                  onClickHandler={() => toggleAddNewSpace()}
                />
              </div>
            </div>

            <div className='edit-space-modal-space-list'>
              {spaces.map((space) => (
                <div key={space.id} className='edit-space-modal-space-entry'>
                  <div className='edit-space-modal-space-entry-name'>
                    {space.is_default
                      ? t(space.translation).toUpperCase()
                      : space.name}
                  </div>
                  {!space.is_default && (
                    <div className='edit-space-modal-space-entry-btns'>
                      <div
                        className='edit-space-modal-space-entry-btn rename'
                        onClick={() => toggleRenameSpaceModal(space)}
                      >
                        {t('project.rename')}
                      </div>
                      <div
                        className='edit-space-modal-space-entry-btn delete'
                        onClick={() => toggleDeleteSpaceModal(space)}
                      >
                        {t('project.delete')}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </CustomModal>
      )}

      {/* Rename space modal */}
      {showRenameSpaceModal && selectedSpace && (
        <CustomModal
          showing={showRenameSpaceModal}
          closeModal={toggleRenameSpaceModal}
          size='sm'
          modal_title={t('project.editSpaceName')}
        >
          <div className='rename-space-modal-content'>
            {renameSpacerrorList.length > 0 && (
              <div className='edit-space-error-container'>
                {renameSpacerrorList.map((error) => {
                  return (
                    <div className='edit-space-error-entry' key={error}>
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='rename-space-modal-input-group'>
              <label className='rename-space-modal-input-label'>
                {t('project.editSpaceName')}
              </label>
              <input
                type='text'
                className='rename-space-modal-input-field'
                value={selectedSpace.name}
                onInput={(e) => updateSpace({ name: e.target.value })}
              ></input>
            </div>
            <div className='rename-space-modal-btns'>
              <CustomButton
                primary
                button_text={t('project.ok').toUpperCase()}
                disabled={selectedSpace.name.trim().length === 0}
                onClickHandler={confirmEditSpace}
              />
              <CustomButton
                button_text={t('project.cancel').toUpperCase()}
                onClickHandler={toggleRenameSpaceModal}
              />
            </div>
          </div>
        </CustomModal>
      )}

      {/* Delete space modal */}
      {showDeleteSpaceModal && selectedSpace && (
        <CustomModal
          showing={showDeleteSpaceModal}
          closeModal={toggleDeleteSpaceModal}
          size='sm'
          modal_title={t('project.deleteSpace')}
        >
          <div className='delete-space-modal-content'>
            {deleteSpacerrorList.length > 0 && (
              <div className='edit-space-error-container'>
                {deleteSpacerrorList.map((error) => {
                  return (
                    <div className='edit-space-error-entry' key={error}>
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='delete-space-modal-confirm-text'>
              {t('project.confirmDeleteSpace')} <b>{selectedSpace.name}</b>
            </div>

            <div className='delete-space-modal-btns'>
              <CustomButton
                primary
                button_text={t('project.delete').toUpperCase()}
                onClickHandler={confirmDeleteSpace}
              />
              <CustomButton
                button_text={t('project.cancel').toUpperCase()}
                onClickHandler={toggleDeleteSpaceModal}
              />
            </div>
          </div>
        </CustomModal>
      )}
    </>
  )
}

export default EditSpaceModal
