import './styles.scss'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CustomButton from '../../components/common/CustomButton'
import { SearchIcon } from '../../components/Icons'
import { useTranslation } from 'react-i18next'

const Search = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation()

  const updateSearchValue = (value) => {
    setSearchValue(value)
  }

  const handleSearch = () => {

  }

  // TODO Add search functionality

  return (
      <div className='search-page-container'>
        <div className='search-page-body-container'>
          <div className='search-page-title'>
            {t('search.title')}
          </div>
          <div className='search-page-input-container'>
            <div className='search-page-input-wrapper'>
              <div className='search-page-input-icon-wrapper'>
                <SearchIcon size={24}/>
              </div>
              <input
                type="text"
                placeholder={t('search.placeholder')}
                value={searchValue}
                onInput={(e) => updateSearchValue(e.target.value)}
              />
            </div>
            
            <CustomButton
              button_text={t('search.btnText')}
              purple={true}
              onClickHandler={() => handleSearch()}
              contentClass='search'
            />
          </div>
          <div className='search-page-divider'></div>
        </div>
      </div>
  )
}

export default Search
