import './styles.scss'
import wltImgSrc from '../../assets/images/logo-WLT.png'
import { useTranslation } from 'react-i18next'

const WLTCard = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className='wlt-card-container'>
        <div className='wlt-card-title header-text'>{t('greenWall.wltTitle')}</div>
        <div className='wlt-card-text'>
          {t('greenWall.wltText1')} 
          <a href="https://www.worldlandtrust.org/" target="_blank" rel="noopener noreferrer">&nbsp;{t('greenWall.wltLink')}&nbsp;</a>
          {t('greenWall.wltText2')}
        </div>
        <div className='wlt-card-logo'><img src={wltImgSrc} alt="WLT Logo" /></div>
      </div>
    </>
  )
}


export default WLTCard
