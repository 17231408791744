const greenService = {
  headerGroup: 'SE Vihreys palveluna',
  headerTitle: 'SE Vuokraa monipuolista vihreyttä ilman kompromisseja.',
  infoCard1Title: 'SE Helppo',
  infoCard1Text1: 'SE Kaikki suunnittelussa ylläpitoon yhdessä paketissa',
  infoCard1Text2: 'SE Runsasta vihreyttä ilman suuria kertainvestointeja',
  infoCard1Text3: 'SE Kiinteä kk-hinta, joka kattaa kaiken',
  infoCard2Title: 'SE Joustava',
  infoCard2Text1: 'SE Vuokraa vain tarpeeseen ilman pitkiä sitoutumisaikoja',
  infoCard2Text2: 'SE Muuta tai skaalaa kokonaisuutta myös sopimusaikana',
  infoCard2Text3: 'SE Sopimuksen päättyessä noudamma tuotteet pois',
  infoCard3Title: 'SE Kestävä',
  infoCard3Text1: 'SE Suosimme kotimaisia tuotteita kuten Kouvi-ruukuja',
  infoCard3Text2: 'SE Meiltä saat voin aitoja kasveja',
  infoCard3Text3: 'SE Kaikki kasvit ja tuotteet uuddelleenvuokrataan tai kierrätetään',
  infoBoxTitle1: 'SE Viihtyisiä tiloja kestävästi',
  infoBoxText1: `SE Haluamme tehdä viihtyisyydestä mahdollisimman helppoa ja kestävää, ja 
      siksi tarjoamme kaikki InnoGreenin ratkaisut myös joustavana palveluna. Palvelumme 
      kiinteällä kuukausimaksulla sisältää kaiken mitä tarvitaan viihtyisään ja vehreään 
      tilaan: suunnittelun, tuotteet, kasvit, asennuksen ja ylläpidon.
      \\n
      InnoGreenin omat, kotimaiset tuotteet, kuten viherseinät, Koivu-tuotteet ja viherkalusteet, 
      löydät suunnittelutyökalustamme, jossa voit helposti vertailla tuotteita ja suunnitella 
      oman näyttävän kokonaisuuden. Tarjoamme omille tuotteillemme kaikista joustavimman palvelumallin, 
      jossa voit valita vuokrakauden pituuden täysin vapaasti sekä muuttaa tuotekokonaisuutta milloin 
      tahansa. Vuokrakauden päättyessä kaikki tuotteet ja kasvit joko kierrätetään tai uudelleenvuokrataan.
      \\n
      Voit myös aina pyytää InnoGreenin asiantuntijan avuksesi suunnittelemaan runsaan vehreän 
      kokonaisuuden. Muut kuin InnoGreenin omat tuotteet ovat vuokrattavissa 36 kk määräaikaisella 
      sopimuksella.`,
  infoBoxTitle2: 'SE Miten vihreys palveluna toimii?',
  infoBoxBullet1: 'SE Valitse mieleisesi tuotteet monipuolisesta InnoGreenin omien tuotteiden valikoimasta tai pyydä suunnittelijamme avuksi.',
  infoBoxBullet2: 'SE Valitse vuokra-aika.',
  infoBoxBullet3: 'SE Saat tarjouksen, joka sisältää kaiken suunnittelusta ylläpitoon.',
  infoBoxBullet4: 'SE Kaikki ok? Me käärimme hihat ja toimitamme vihreyden valmiiksi istutettuna sovittuna ajankohtana.',
  infoBoxBullet5: 'SE Huollamme kasvit ja tuotteet säännöllisesti sekä istutamme ulkotuotteiden kausikasvit. Sopimuksen päätyttyä haemme tuotteet pois.',
  infoBoxBullet6: 'SE Huollamme ja puhdistamme vuokrasta palautuvat tuotteet ennen uudelleenvuokrausta. Kierrätämme elinkaarensa päähän tulleet kasvit ja tuotteet.',
  productLinkTitle: 'SE Aloita viihtyisän ja vehreän tilan suunnittelu',
  productLink: ' SE tästä!'
}

export default greenService