import './styles.scss'
import React, { useState, useEffect, useRef } from 'react'
import CustomTextInput from '../common/CustomTextInput'
import QuillEditor from '../QuillEditor'

const InfoBoxInput = (props) => {
  const {
    id,
    onContentChange,
    component = null
  } = props
  const ENRef = useRef(null)
  const FIRef = useRef(null)
  const [selectedLanguage, setSelectedLanguage] = useState('FI')
  const [object, setObject] = useState({
    title: '',
    text: '',
    btnText: '',
    title_en: '',
    text_en: '',
    btnText_en: '',
    img_src: '',
    btnLink: null,
    bgColor: '',
    previewUrl: '',
    imgName: ''
  })
  const bgColors = [
    {
      value: 'purple'
    },
    {
      value: 'purple-strong'
    },
    {
      value: 'beige'
    },
    {
      value: 'turquoise'
    }
  ]
  const fileInputRef = useRef(null)

  useEffect(() => {
    if (component != null) {
      setObject({previewUrl: component.imgSrc, ...component})
    }
  }, [component]);

  const handleChange = async (event) => {
    const selectedColor = event.target.value
    const color = bgColors.find((color) => color.value === selectedColor)
    if (!color) {
      if (object) {
        setObject((prevObject) => {
          const updatedObject = {
            ...prevObject,
            bgColor: ''
          }
          onContentChange(updatedObject, id)
          return updatedObject
        })
        return
      }
    }

    setObject((prevObject) => {
      const updatedObject = {
        ...prevObject,
        bgColor: color.value
      }
      onContentChange(updatedObject, id)
      return updatedObject
    })
  }

  const handleContentChange = (key, value, language) => {
    setObject((prevObject) => {
      if (language === 'EN') {
        var updatedObject = { ...prevObject, [`${key}_en`]: value }
      } else {
        var updatedObject = { ...prevObject, [key]: value }
      }
      onContentChange(updatedObject, id)
      return updatedObject
    })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const previewUrl = URL.createObjectURL(file)
      setObject((prevObject) => {
        const updatedObject = { ...prevObject, previewUrl: previewUrl, image: file, imgName: '' }
        onContentChange(updatedObject, id)
        return updatedObject
      })
    }
  }

  const handlePlaceholderClick = () => {
    fileInputRef.current.click()
  }

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language)
    if (language === 'FI') {
      ENRef.current.style.display = 'none'
      FIRef.current.style.display = 'block'

    }
    if (language === 'EN') {
      FIRef.current.style.display = 'none'
      ENRef.current.style.display = 'block'

    }
  }

  return (
    <>
      <div className='customize-blog-header-language-wrapper'>
                <div className='customize-blog-header-language-selector'>
                  <div 
                    className={`customize-blog-header-language ${selectedLanguage === 'FI' ? 'active' : ''}`}
                    onClick={() => handleSelectLanguage('FI')}
                  >
                    FI
                  </div>
                  <div 
                    className={`customize-blog-header-language ${selectedLanguage === 'EN' ? 'active' : ''}`}
                    onClick={() => handleSelectLanguage('EN')}
                  >
                    EN
                  </div>
                </div>
              </div>
        <div className='info-box-input-container'>
        <div className={`info-box-input-left-container ${object && object.bgColor ? `${object.bgColor}` : ''}`}>
          <select
            className='info-box-input-left-select'
            id="color-select"
            onChange={handleChange}
            value={object.bgColor}
          >
            <option value="">-- Bg color --</option>
            {bgColors.map((color) => (
              <option key={color.value} value={color.value}>
                {color.value}
              </option>
            ))}
          </select>
          <div ref={FIRef}>
            <CustomTextInput
              type='infoBoxTitle'
              onContentChange={handleContentChange}
              label='Title'
              value={object.title}
            />
            <CustomTextInput
              type='textArea'
              onContentChange={handleContentChange}
              label='Text'
              value={object.text}
            />
            <CustomTextInput
              type='btnText'
              onContentChange={handleContentChange}
              label='Button Text'
              value={object.btnText}
            />
          </div>
          <div className='en' ref={ENRef}>
            <CustomTextInput
              type='infoBoxTitle'
              onContentChange={handleContentChange}
              label='Title'
              value={object.title_en ?? ''}
              language='EN'
            />
            <CustomTextInput
              type='textArea'
              onContentChange={handleContentChange}
              label='Text'
              value={object.text_en ?? ''}
              language='EN'
            />
            <CustomTextInput
              type='btnText'
              onContentChange={handleContentChange}
              label='Button Text'
              value={object.btnText_en ?? ''}
              language='EN'
            />
          </div>
          <CustomTextInput
            type='btnLink'
            onContentChange={handleContentChange}
            label='Button Link'
            value={object.btnLink === null ? '' : object.btnLink}
          />
        </div>

        <div className='info-box-input-right-container'>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileChange(e)}
            ref={fileInputRef}
            id="infoboxImageInput"
            className="info-box-input-image-input"
          />
            
            {object && object?.previewUrl ? (
              <img onClick={handlePlaceholderClick} src={object.previewUrl} alt="Profile" className="info-box-input-image-preview" />
            ) : (
              <div
                className="info-box-input-image-placeholder"
                onClick={handlePlaceholderClick}
              >
                Add Image
              </div>
            )}
        </div>
      </div>
    </>
  )
}

export default InfoBoxInput