import './styles.scss'
import { useParams, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import ProductInquiryModal from '../../components/SendInquiryModal'
import CustomButton from '../../components/common/CustomButton'
import CustomModal from '../../components/common/CustomModal'
import CustomMultiCarousel from '../../components/common/CustomMultiCarousel'
import YourSelection from '../YourSelection'
import {
  CircleSelected,
  CircleUnselected,
  CheckMark,
  CirclePlusIcon,
  CircleMinusIcon,
  MinusIcon,
  PlusIcon,
  ArrowRight,
  ArrowLeft
} from '../../components/Icons'

import { useAppSelector } from '../../store'
import {
  updateCustomizedOption,
  selectCustomizedProduct,
  preselectCustomization,
  customized_product,
  removeCustomizedOption
} from '../../store/productSlice'

import { get_decoded_string } from '../../plugins/helper'


const customizationOptionResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 2000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1400 },
    items: 4,
  },
  smallDesktop: {
    breakpoint: { max: 1400, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 2,
  },
}

const CustomizeProduct = () => {
  const dispatch = useDispatch()
  const { slug } = useParams()
  let scrollRefs = useRef([])
  const [carouselKey, setCarouselKey] = useState(0)
  const { t, i18n } = useTranslation()
  const product = useAppSelector(customized_product)
  const filtered_customizations = useSelector((state) =>
    state.product.product_customization
  )
  let configuration = null
  if (product) {
    if(i18n.exists(`${get_decoded_string(product.name)}.configuration`)) {
      configuration = i18n.t(`${get_decoded_string(product.name)}.configuration`, { returnObjects: true })
    }
  }
  const [showReadMoreState, setReadMoreState] = useState(false)
  const [selectedCustomization, setSelectedCustomization] = useState(null)
  // const [selectedModel, setSelectedModel] = useState(null)
  const selected_customizations = useSelector(
    (state) => state.product.selected_customized_product?.customizations
  )
  const [showProductInquiryModalState, setProductInquiryModalState] = useState(false)

  const toggleProductInquiryModal = () => {
    setProductInquiryModalState(!showProductInquiryModalState)
  }

  useEffect(() => {
    if (selected_customizations && selected_customizations.length === 0) {
      if (product && product?.name === 'Räätälöitävä viherseinä' ) {
        // set default value for Custom green wall
        dispatch(preselectCustomization({width:2, height:9, isCustomGreenWall: true}))
      } else if (product?.name === 'Räätälöitävä sammalseinä') {
        // set default value for Custom moss wall
        dispatch(preselectCustomization({value:1000, isMossWall: true}))
      } else if (product && product?.name === 'Viherpysäkki'){
        // set default value for Green stop
        dispatch(preselectCustomization({value:1, isGreenStop: true}))
      } else if (product && product?.name === 'Viherseinä') {
        // set default value for Green walls
        dispatch(preselectCustomization({value:1, isGreenWall: true}))
      } else {
        dispatch(preselectCustomization({value:null}))
      }
    }
  }, [slug])

  const resetCarousel = () => {
    setCarouselKey(prevKey => prevKey + 1)
  }

  const toggleReadMoreModal = (customization) => {
    setReadMoreState(!showReadMoreState)
    setSelectedCustomization(customization)
  }

  const is_option_selected = (option, customization) => {
    return selected_customizations.some((c) => {
      if (c.id === customization.id) {
        return c.selected_option_id === option.id
      }
      return false
    })
  }

  const selected_value = (option) => {
    var selected = selected_customizations.find((item) => item.selected_option_id === option.id)
    if (selected) {
      return selected.value
    }
    return '1'
  }

  const has_pictures = (customization) => {
    return customization.options.some(option => Array.isArray(option.imgSrcs) && option.imgSrcs.length > 0)
  }

  const handleOptionClick = (option, customization) => {
    if (product && product?.name === 'Koivu T-sarja') {
      dispatch(updateCustomizedOption({ option, customization }))
      // Check if seelcted option is 'T-1020XL'
      if (option.name === 'T-1020XL') {
        // Check if selected Feet is the long ones as T-1020XL does not have that option
        const selected_feet_option = selected_customizations.find(item => (item.name === '3. Jalat'))
        const selected_feet_customization = filtered_customizations.find(item => (item.name === '3. Jalat')).options.find(item => (item.id === selected_feet_option.selected_option_id))
        // if long feet is selected change to default 'Tassut' instead
        if (selected_feet_customization && selected_feet_customization.name === 'Korkeat jalat') {
          const new_customization = filtered_customizations.find(item => (item.name === '3. Jalat'))
          const new_option = filtered_customizations.find(item => (item.name === '3. Jalat')).options.find(item => (item.name === 'Tassut'))
          dispatch(updateCustomizedOption({ option: new_option, customization: new_customization }))
        }
      }
    } else if (product && product?.name === 'Koivu-viherseinä') {
      dispatch(updateCustomizedOption({ option, customization }))
      if (option.name === 'Seinälle sijoitettava') {
        // If Seinälle sijoitettava is selected remove selected background option
        const selected_background_option = selected_customizations.find(item => (item.name === '3. Taustamateriaali'))
        dispatch(removeCustomizedOption({option: selected_background_option}))
      } else if (option.name === 'Tilanjakaja') {
        // If selectingmodel back to 'Tilanjakaja' from 'Seinälle sijoitettava' We need to add back default selection for 'Taustamateriaali'
        if (selected_customizations.length <= 2) {
          const new_customization = filtered_customizations.find(item => (item.name === '3. Taustamateriaali'))
          const new_option = filtered_customizations.find(item => (item.name === '3. Taustamateriaali')).options[0] // first option
          dispatch(updateCustomizedOption({ option: new_option, customization: new_customization }))
        }
      }
      // Plant Group Selector for Koivu Series
    } else if (product && product?.name === 'Koivu P-sarja') {
      dispatch(updateCustomizedOption({ option, customization }))
      // Check if selected option is 'P-380', 'P-380XL', 'P-445' or 'P-550'
      if (['P-380', 'P-380XL', 'P-445', 'P-550'].includes(option.name)) {
        const selected_plant_group = selected_customizations.find(item => (item.name === '4. Kasviryhmä'))
        const selected_plant_group_customization = filtered_customizations.find(item => (item.name === '4. Kasviryhmä')).options.find(item => (item.id === selected_plant_group.selected_option_id))
        if (option.name === 'P-380' || option.name === 'P-380XL') {
          const new_customization = filtered_customizations.find(item => (item.name === '4. Kasviryhmä'))
          const new_option = filtered_customizations.find(item => (item.name === '4. Kasviryhmä')).options.filter(item => (item.name === 'S'))
          if (selected_plant_group_customization && selected_plant_group_customization.name !== 'S') {
            dispatch(updateCustomizedOption({ option: new_option[0], customization: new_customization }))
          }
        }
        // Select Plant group M
        else if (option.name === 'P-445') {
          const new_customization = filtered_customizations.find(item => (item.name === '4. Kasviryhmä'))
          const new_option = filtered_customizations.find(item => (item.name === '4. Kasviryhmä')).options.filter(item => (item.name === 'M'))
          if (selected_plant_group_customization && selected_plant_group_customization.name !== 'M') {
            dispatch(updateCustomizedOption({ option: new_option[0], customization: new_customization }))
          }
        }
        // Select Plant group L
        else if (option.name === 'P-550' || option.name === 'P-550XL') {
          const new_customization = filtered_customizations.find(item => (item.name === '4. Kasviryhmä'))
          const new_option = filtered_customizations.find(item => (item.name === '4. Kasviryhmä')).options.filter(item => (item.name === 'L - Puuvartinen'))
          dispatch(updateCustomizedOption({ option: new_option[0], customization: new_customization }))
        }
      }
    } else {
      dispatch(updateCustomizedOption({ option, customization }))
    }
    if (customization.type === 1) {
      resetCarousel() // Go to first picture when selecting
    }
  }
  // Custom preselector for Koivu-vihertaulu
  const handleOptionClickWithPreselect = (option, customization, type) => {
    const sizeCustomization = filtered_customizations?.find(item => item.name === '2. Koko')
    const sizeOption = sizeCustomization?.options.filter(item => item.name.includes(option?.name))
    const contentCustomization = filtered_customizations?.find(item => item.name === '4. Taulun sisältö')
    const sizeIndex = sizeCustomization.options.findIndex(item => item.id === sizeOption[0].id)
    const contentOption = getOptionsByIndex(contentCustomization.options, sizeIndex)
    // Preselect first size and content option when changing model
    if (type !== 'size') {
      dispatch(updateCustomizedOption({ option, customization }))
      dispatch(updateCustomizedOption({ option:sizeOption[0], customization:sizeCustomization }))
      dispatch(updateCustomizedOption({ option:contentOption[0], customization:contentCustomization }))
    } else {
      // Preselect first content option when size changes
      dispatch(updateCustomizedOption({ option, customization }))
      dispatch(updateCustomizedOption({ option:contentOption[0], customization:contentCustomization }))
    }
    if (customization.type === 1) {
      resetCarousel() // Go to first picture when selecting
    }
  }

  const handleOptionChange = (event, option, customization) => {
    if (product && product?.name === 'Viherpysäkki') {
      const value = event.target.value
      const numericValue = value === '' ? 0 : parseInt(value, 10)
      dispatch(updateCustomizedOption({value:numericValue, option, customization, isGreenStop:true }))
    } else {
      const value = event.target.value
      const numericValue = value === '' ? 0 : parseInt(value, 10)
      dispatch(updateCustomizedOption({value:numericValue, option, customization }))
    }
  }

  const update_product_customization = (value, option, customization, type) => {
    // Custom for Koivu-vihertaulu
    if (type === 'add') {
      dispatch(updateCustomizedOption({value:parseInt(value) + 1, option, customization }))

    } else if (type === 'subtract') {
      dispatch(updateCustomizedOption({value:Math.max(0, parseInt(value) - 1), option, customization }))
    }
  }

  const optionPlus = (option, customization) => {
    const value = selected_value(option)
    dispatch(updateCustomizedOption({value:value + 1, option, customization, isGreenStop:true }))
  }

  const optionMinus = (option, customization) => {
    const value = selected_value(option)
    dispatch(updateCustomizedOption({value:Math.max(0, value - 1), option, customization, isGreenStop:true }))
  }

  const handleOptionChangeText = (event, option, customization) => {
    const value = event.target.value
    dispatch(updateCustomizedOption({value:value, option, customization }))
  }

  const calculate_configuration = (config, option, extra=false) => {
    if (config && config.type == 'viherseinä') {
      let value = 0
      selected_customizations.forEach(item => {
        if (item.name === '1. Leveys') {
          value += (item.calculation_value * item.value * 10)
        }
      })
      return config.valueText + ' = ' + value + ' mm'
    }
    const item = selected_customizations.find((item) => item.selected_option_id === option.id)
    if (config && item) {
      if (config.type === 'räätälöitävä_viherseinä') {
        if (!extra) {
          if (config.configType === 'width') {
            return config.valueText + " = " + (config.value * item.value) + " mm"
          } else if (config.configType === 'height') {
            return config.valueText + " = " + ((config.value * item.value) + 400) + " mm"
          }
        } else {
          return '(400mm + ' + (config.value * item.value) + " mm)"
        }
      } else if (config.type === 'räätälöitävä_sammalseinä') {
        if (config.configType === 'width') {
          return config.valueText + " = " + (item.value) + " mm"
        } else if (config.configType === 'height') {
          return config.valueText + " = " + (item.value) + " mm"
        }
      }
    }
  }

  const getOptionsByIndex = (array, index) => {
    const start = index * 3
    const end = start + 3
    return array.slice(start, end)
  }

  const customizedArrowPrev = (clickHandler, greenStop = false, containerClass = '', animationDuration = 325) => {
    const handleClick = () => {
      // Add a class to temporarily hide border radius
      const items = document.querySelectorAll('.customization-carousel-image')
      items.forEach(item => item.classList.add('hideBorderRadius'))

      // Trigger the click handler for carousel navigation
      clickHandler()

      // Remove the class after the animation duration
      setTimeout(() => {
        items.forEach(item => item.classList.remove('hideBorderRadius'))
      }, animationDuration)
    }

    if (greenStop) {
      containerClass += ' green-stop'
      return (
        <div className={`customArrowPrev-container ${containerClass}`} onClick={handleClick}>
          <div className='customArrowPrev'>
            <ArrowLeft size={40}/>
          </div>
        </div>
      )
    }
    return (
      <div className={`customArrowPrev-container ${containerClass}`}  onClick={handleClick}>
        <div className='customArrowPrev'>
          <ArrowLeft size={40}/>
        </div>
      </div>
    )
  }

  const customizedArrowNext = (clickHandler, greenStop = false, containerClass = '', animationDuration = 325) => {
    const handleClick = () => {
      // Add a class to temporarily hide border radius
      const items = document.querySelectorAll('.customization-carousel-image')
      items.forEach(item => item.classList.add('hideBorderRadius'))

      // Trigger the click handler for carousel navigation
      clickHandler()

      // Remove the class after the animation duration
      setTimeout(() => {
        items.forEach(item => item.classList.remove('hideBorderRadius'))
      }, animationDuration)
    }
    if (greenStop) {
      containerClass += ' green-stop'
      return (
        <div className={`customArrowNext-container ${containerClass}`} onClick={handleClick}>
          <div className='customArrowNext'>
            <ArrowRight size={40}/>
          </div>
        </div>
      )
    }
    return (
      <div className={`customArrowNext-container ${containerClass}`} onClick={handleClick}>
        <div className='customArrowNext'>
          <ArrowRight size={40}/>
        </div>
      </div>
    )
  }

  const renderCustomIndicator = (onClickHandler, isSelected, index, label) => {
    if (isSelected) {
      return (
        <div
          onClick={onClickHandler}
          key={index}
        >
          <CircleSelected/>
        </div>
      )
    } else {
      return (
        <div
          onClick={onClickHandler}
          key={index}
        >
          <CircleUnselected/>
        </div>
      )
    }
  }

  const get_translated_option_name = (product_name, customization_name, option_name) => {
    if (customization_name === 'korkeus') {
      return t(`${product_name}.${customization_name}_options.k${option_name}`)
    }
    else if (customization_name === 'leveys') {
      return t(`${(product_name)}.${(customization_name)}_options.l${(option_name)}`)
    }

    return t(`${(product_name)}.${(customization_name)}_options.${(option_name)}`)
  }

  const btnStyle = {
    padding: '1.25rem 2.75rem',
  }

  if (product) {
    if (product.name === 'Parkly palveluna' || product.name === 'Vertical Garden') {
      return (
        <>
          <div className='customization-page-inquiry-container'>
              <div className='customization-page-inquiry-body-container'>
                  <div className='customization-page-inquiry-title'>{t('products.interesedInProduct')}</div>
                  <div>
                    <CustomButton
                      button_text={t('products.sendInquiry')}
                      purple={true}
                      customStyles={btnStyle}
                      contentClass='send-btn'
                      onClickHandler={toggleProductInquiryModal}
                    />
                  </div>
              </div>
              
          </div>
          {showProductInquiryModalState && (
            <ProductInquiryModal
              product_id={product.id}
              showing={showProductInquiryModalState}
              closeModal={toggleProductInquiryModal}
            />
          )}
        </>
      )
    }
  }

  return (
    <>
      <div className='customization-page-container'>
        <div className='customization-page-body-container'>
          <div className='customization-page-title'>
            {t('common.configureProduct')}
          </div>
          <div className='customization-page-divider' ></div>
          {filtered_customizations.map((customization, idx) => {
            if (customization.type === 1) {
                let selectedOptionImages = customization.options.find((option) => {
                  if (selected_customizations && selected_customizations.length !== 0 ) {
                    const selectedOption = selected_customizations.find(item => item.name === customization.name)
                    if (selectedOption?.name === customization?.name && selectedOption?.selected_option_id === option.id) {
                      return option.imgSrcs
                    }
                  }
                })
                let contentOptions = null
                // Custom for Koivu-vihertaulu
                if (product && product?.name === 'Koivu-vihertaulu' && customization.name === '4. Taulun sisältö') {
                  const selectedSize = selected_customizations.find(item => item.name === '2. Koko')
                  const sizeIndex = filtered_customizations.find(item => item.name === '2. Koko').options?.findIndex(item => item.id === selectedSize?.selected_option_id)
                  contentOptions = getOptionsByIndex(customization.options, sizeIndex)
                }
                // Custom for Koivu P-Series Plant groups
                if (product && product?.name === 'Koivu P-sarja' && customization.name === '4. Kasviryhmä') {
                  // Get selected model
                  const selectedModel = selected_customizations.find(item => item.name === '1. Malli')
                  // Get selected option
                  const selectedOption = filtered_customizations.find(item => item.name === selectedModel?.name)?.options.find(item => item.id === selectedModel.selected_option_id)
                  // return options based on selected model
                  if (['P-380', 'P-380XL'].includes(selectedOption?.name)) {
                    contentOptions = customization.options.filter(item => item.name === 'S')
                  } else if (['P-445'].includes(selectedOption?.name)) {
                    contentOptions = customization.options.filter(item => item.name === 'M')
                  } else if (['P-550'].includes(selectedOption?.name)) {
                    contentOptions = customization.options.filter(item => item.name === 'L - Puuvartinen' || item.name === 'L - Pensanmainen')
                  }
                }

                return (
                  <React.Fragment key={idx}>
                    <div className='customization-entry-container'ref={ref => {scrollRefs.current[customization.name] = ref}}>
                      <div className='mobile'>
                        <div className='customization-title'>
                              {`${idx+1}. ${t(`products.${get_decoded_string(customization.name)}`)}`}
                        </div>
                        <div className='customization-description-container'>
                          <p className='customization-description'>
                            {`${t(`${get_decoded_string(product.name)}.${get_decoded_string(customization.name)}.description`)}`}
                          </p>
                          {/* <p
                            className='customization-description-link'
                            onClick={() => toggleReadMoreModal(customization)}
                          >
                            {t('common.readMore')}
                          </p> */}
                        </div>
                      </div>
                      <div className='customization-carousel'>
                        <Carousel
                          infiniteLoop
                          key={carouselKey}
                          showThumbs={false}
                          showStatus={false}
                          renderDotsOutside={true}
                          swipeable
                          emulateTouch
                          renderIndicator={renderCustomIndicator}
                          renderArrowPrev={(clickHandler) =>
                            customizedArrowPrev(clickHandler, false, selectedOptionImages?.imgSrcs?.length === 1 ? "single-item" : "")
                          }
                          renderArrowNext={(clickHandler) =>
                            customizedArrowNext(clickHandler, false, selectedOptionImages?.imgSrcs?.length === 1 ? "single-item" : "")
                          }
                        >
                          { selectedOptionImages ?
                            (selectedOptionImages.imgSrcs.map((img, index) => {
                                return (
                                  <div
                                    className='customization-carousel-image no-select'
                                    key={index}
                                  >
                                    <img src={img} alt='customization option'/>
                                  </div>
                                )
                              })
                            ) : (
                              <></>
                            )
                          }
                        </Carousel>
                      </div>
                      <div className='customization-content-container'>
                        <div className='customization-content'>
                          <div className='desktop'>
                            <div className='customization-title'>
                              {`${idx+1}. ${t(`products.${get_decoded_string(customization.name)}`)}`}
                            </div>
                            <div className='customization-description-container'>
                              <p className='customization-description'>
                                {`${t(`${get_decoded_string(product.name)}.${get_decoded_string(customization.name)}.description`)}`}
                              </p>
                              {/* <p
                                className='customization-description-link'
                                onClick={() => toggleReadMoreModal(customization)}
                              >
                                {t('common.readMore')}
                              </p> */}
                            </div>
                          </div>
                          <div className='customization-options'>
                            {/* Custom for Koivu-vihertaulu */}
                            { contentOptions ?
                              (
                                contentOptions.map((option) => {
                                  return (
                                    <div
                                      className={
                                        'customization-option' +
                                        `${
                                          is_option_selected(option, customization)
                                            ? ' selected'
                                            : ''
                                        }`
                                      }
                                      key={option.id}
                                      onClick={() =>
                                        handleOptionClick(option, customization)
                                      }
                                    >
                                      <p className='customization-option-text type-1'>
                                      {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                      </p>
                                      {option.value ? (
                                        <p className='customization-option-value'>
                                          {option.value}
                                        </p>
                                      ) : null}
                                    </div>
                                  )
                                })
                              ) : ( product && product?.name === 'Koivu-vihertaulu' ?
                                (
                                  customization.options.map((option) => {
                                  return (
                                    <div
                                      className={
                                        'customization-option' +
                                        `${
                                          is_option_selected(option, customization)
                                            ? ' selected'
                                            : ''
                                        }`
                                      }
                                      key={option.id}
                                      onClick={() =>
                                        handleOptionClickWithPreselect(option, customization, '')
                                      }
                                    >
                                      <p className='customization-option-text type-1'>
                                      {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                      </p>
                                      {option.value ? (
                                        <p className='customization-option-value'>
                                          {option.value}
                                        </p>
                                      ) : null}
                                    </div>
                                  )
                                })
                                ) : (
                                  customization.options.map((option) => {
                                    return (
                                      <div
                                        className={
                                          'customization-option' +
                                          `${
                                            is_option_selected(option, customization)
                                              ? ' selected'
                                              : ''
                                          }`
                                        }
                                        key={option.id}
                                        onClick={() =>
                                          handleOptionClick(option, customization)
                                        }
                                      >
                                        <p className='customization-option-text type-1'>
                                          {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                        </p>
                                        {option.value ? (
                                          <p className='customization-option-value'>
                                            {option.value}
                                          </p>
                                        ) : null}
                                      </div>
                                    )
                                  })
                                )

                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    { filtered_customizations.length - 1 !== idx ?
                      (
                        <div className='customization-page-divider' ></div>
                      ) : (
                        <></>
                      )
                    }
                  </React.Fragment>
                )
            }
            else if (customization.type === 2) {
              // Custom for Koivu-vihertaulu
              let selectedModel = null
              let selectedOption = null
              let selectedModelTSeries = null
              let selectedOptionTSeries = null
              let selectedModelKoivuGreenWall = null
              let selectedOptionKoivuGreenWall = null
              if (product && product?.name === 'Koivu-vihertaulu' && customization.name === '2. Koko') {
                selectedModel = selected_customizations.find(item => {
                  if (item.name === '1. Muoto') {
                    return item
                  }
                })
                selectedOption = filtered_customizations.find((item) => item.name === selectedModel?.name)?.options.find((item) => item.id === selectedModel.selected_option_id)
              }
              if (product && product?.name === 'Koivu T-sarja' && customization.name === '3. Jalat') {
                selectedModelTSeries = selected_customizations.find(item => {
                  if (item.name === '1. Malli') {
                    return item
                  }
                })
                selectedOptionTSeries = filtered_customizations.find((item) => item.name === selectedModelTSeries?.name)?.options.find((item) => item.id === selectedModelTSeries.selected_option_id)
              }
              if (product && product?.name === 'Koivu-viherseinä') {
                selectedModelKoivuGreenWall = selected_customizations.find(item => {
                  if (item.name === '1. Malli') {
                    return item
                  }
                })
                selectedOptionKoivuGreenWall = filtered_customizations.find((item) => item.name === selectedModelKoivuGreenWall?.name)?.options.find((item) => item.id === selectedModelKoivuGreenWall.selected_option_id)

              }
              if (selectedOptionKoivuGreenWall && selectedOptionKoivuGreenWall.name === 'Seinälle sijoitettava' && customization.name === '3. Taustamateriaali') {
                return
              } else {
                return (
                  <React.Fragment key={idx}>
                    <div className='customization-entry-container' ref={ref => {scrollRefs.current[customization.name] = ref}}>
                      <div className='customization-entry-left'>
                        <div className='customization-title'>
                          {`${idx+1}. ${t(`products.${get_decoded_string(customization.name)}`)}`}
                        </div>
                        <div className='customization-description-container'>
                          <p className='customization-description'>
                            {`${t(`${get_decoded_string(product.name)}.${get_decoded_string(customization.name)}.description`)}`}
                          </p>
                          {/* <p
                            className='customization-description-link'
                            onClick={() => toggleReadMoreModal(customization)}
                          >
                            {t('common.readMore')}
                          </p> */}
                        </div>
                      </div>

                      <div className='customization-entry-right mobile'>
                      {selectedOption ?
                          (
                            customization.options.map((option) => {
                              if (selectedOption && option?.name.includes(selectedOption.name)) {
                                return (
                                  <div
                                    className={
                                      'customization-option vertical' +
                                      `${
                                        is_option_selected(option, customization)
                                          ? ' selected'
                                          : ''
                                      }`
                                    }
                                    key={option.id}
                                    onClick={() =>
                                      handleOptionClickWithPreselect(option, customization, 'size')
                                    }
                                  >
                                  {
                                    Array.isArray(option.imgSrcs) && option.imgSrcs.length === 0 ?
                                    (
                                      <>
                                        <div
                                          className={'customization-option-button-container' + `${
                                          is_option_selected(option, customization)
                                            ? ' selected'
                                            : ''
                                            }`}
                                          >
                                          <div className='customization-option-button'>
                                            {option.name}
                                            {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className='customization-image-container'>
                                          {is_option_selected(option, customization) ?
                                            (
                                              <div className='customization-image-checkmark'>
                                                <CheckMark size={30}/>
                                              </div>
                                            ) : (
                                              <></>
                                            )
                                          }
                                          <img
                                            src={option.imgSrcs[0]}
                                            alt={`customization option - ${option.name}`}
                                          />

                                        </div>
                                        <div className='customization-option-text'>
                                          {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                        </div>
                                      </>
                                    )
                                  }
                                  </div>
                                )
                              } else {
                                return null
                              }
                            }).filter(item => item !== null)
                          ) : (
                            customization.options.map((option) => {
                              if (selectedOptionTSeries && selectedOptionTSeries.name === 'T-1020XL' && option.name === 'Korkeat jalat') {
                                return
                              } else {
                                return (
                                  <div
                                    className={
                                      'customization-option vertical' +
                                      `${
                                        is_option_selected(option, customization)
                                          ? ' selected'
                                          : ''
                                      }`
                                    }
                                    key={option.id}
                                    onClick={() =>
                                      handleOptionClick(option, customization)
                                    }
                                  >
                                  {
                                    Array.isArray(option.imgSrcs) && option.imgSrcs.length === 0 ?
                                    (
                                      <>
                                        <div
                                          className={'customization-option-button-container' + `${
                                          is_option_selected(option, customization)
                                            ? ' selected'
                                            : ''
                                            }`}
                                          >
                                          <div className='customization-option-button'>
                                            {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className='customization-image-container'>
                                          {is_option_selected(option, customization) ?
                                            (
                                              <div className='customization-image-checkmark'>
                                                <CheckMark size={30}/>
                                              </div>
                                            ) : (
                                              <></>
                                            )
                                          }
                                          <img
                                            src={option.imgSrcs[0]}
                                            alt={`customization option - ${option.name}`}
                                          />
                                        </div>
                                        <div className='customization-option-text'>
                                          {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                        </div>
                                      </>
                                    )
                                  }
                                  </div>
                                )
                              }
                            })
                          )
                        }
                      </div>

                      <div className={has_pictures(customization) ? 'customization-entry-right desktop' : 'customization-entry-right align-start desktop'}>
                        <CustomMultiCarousel
                          customResponsive={customizationOptionResponsive}
                        >
                          { selectedOption ?
                            (
                              customization.options.map((option) => {
                                if (selectedOption && option?.name.includes(selectedOption.name)) {
                                  return (
                                    <div
                                      className={
                                        'customization-option vertical' +
                                        `${
                                          is_option_selected(option, customization)
                                            ? ' selected'
                                            : ''
                                        }`
                                      }
                                      key={option.id}
                                      onClick={() =>
                                        handleOptionClickWithPreselect(option, customization, 'size')
                                      }
                                    >
                                    {
                                      Array.isArray(option.imgSrcs) && option.imgSrcs.length === 0 ?
                                      (
                                        <>
                                          <div
                                            className={'customization-option-button-container' + `${
                                            is_option_selected(option, customization)
                                              ? ' selected'
                                              : ''
                                              }`}
                                            >
                                            <div className='customization-option-button'>
                                              {option.name}
                                              {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className='customization-image-container'>
                                            {is_option_selected(option, customization) ?
                                              (
                                                <div className='customization-image-checkmark'>
                                                  <CheckMark size={30}/>
                                                </div>
                                              ) : (
                                                <></>
                                              )
                                            }
                                            <img
                                              src={option.imgSrcs[0]}
                                              alt={`customization option - ${option.name}`}
                                            />

                                          </div>
                                          <div className='customization-option-text'>
                                            {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                          </div>
                                        </>
                                      )
                                    }
                                    </div>
                                  )
                                } else {
                                  return null
                                }
                              }).filter(item => item !== null)
                            ) : (
                              customization.options.map((option) => {
                                if (selectedOptionTSeries && selectedOptionTSeries.name === 'T-1020XL' && option.name === 'Korkeat jalat') {
                                  return
                                } else {
                                  return (
                                    <div
                                      className={
                                        'customization-option vertical' +
                                        `${
                                          is_option_selected(option, customization)
                                            ? ' selected'
                                            : ''
                                        }`
                                      }
                                      key={option.id}
                                      onClick={() =>
                                        handleOptionClick(option, customization)
                                      }
                                    >
                                    {
                                      Array.isArray(option.imgSrcs) && option.imgSrcs.length === 0 ?
                                      (
                                        <>
                                          <div
                                            className={'customization-option-button-container' + `${
                                            is_option_selected(option, customization)
                                              ? ' selected'
                                              : ''
                                              }`}
                                            >
                                            <div className='customization-option-button'>
                                              {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className='customization-image-container'>
                                            {is_option_selected(option, customization) ?
                                              (
                                                <div className='customization-image-checkmark'>
                                                  <CheckMark size={30}/>
                                                </div>
                                              ) : (
                                                <></>
                                              )
                                            }
                                            <img
                                              src={option.imgSrcs[0]}
                                              alt={`customization option - ${option.name}`}
                                            />
                                          </div>
                                          <div className='customization-option-text'>
                                            {get_translated_option_name('products', get_decoded_string(customization.name), get_decoded_string(option.name))}
                                          </div>
                                        </>
                                      )
                                    }
                                    </div>
                                  )
                                }
                              })
                            )
                          }
                        </CustomMultiCarousel>
                      </div>

                    </div>
                    { filtered_customizations.length - 1 !== idx ?
                      (
                        <div className='customization-page-divider' ></div>
                      ) : (
                        <></>
                      )
                    }
                  </React.Fragment>
                )
              }
            } else if (customization.type === 3) {
              return (
                <React.Fragment key={idx}>
                  <div className='customization-entry-container' ref={ref => {scrollRefs.current[customization.name] = ref}}>
                    <div className='customization-entry-left'>
                      <div className='customization-title'>
                        {`${idx+1}. ${t(`products.${get_decoded_string(customization.name)}`)}`}
                      </div>
                      <div className='customization-description-container'>
                        <p className='customization-description'>
                          {`${t(`${get_decoded_string(product.name)}.${get_decoded_string(customization.name)}.description`)}`}
                        </p>
                        {/* <p
                          className='customization-description-link'
                          onClick={() => toggleReadMoreModal(customization)}
                        >
                          {t('common.readMore')}
                        </p> */}
                      </div>
                    </div>
                    <div className={has_pictures(customization) ? 'customization-entry-right' : 'customization-entry-right align-start'}>
                      { customization.options.map((option) => {
                          if (product?.name === 'Räätälöitävä sammalseinä') {
                            return (
                              <div
                                className='customization-option vertical no-cursor'
                                key={option.id}
                              >
                                <div className='customization-option-input-container'>
                                  {configuration?.length >= idx && configuration[idx]?.configType === 'custom' ?
                                    (
                                      <textarea
                                        onChange={(event) => handleOptionChangeText(event, option, customization)}
                                        value={selected_value(option) ?? 1}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        onChange={(event) => {const value = event.target.value
                                          if (/^\d*$/.test(value)) {
                                            handleOptionChange(event, option, customization)
                                          }}}
                                        value={selected_value(option) ?? 1}
                                      />
                                    )

                                  }
                                  <div className='customization-option-input-text'>{configuration !== null ? configuration[idx]?.text : ""}</div>
                                </div>
                                { Array.isArray(configuration) && configuration?.length >= idx && configuration[idx]?.showExtra ?
                                  (
                                    <div className='customization-option-input-info-container'>
                                      <div className='customization-option-input-info-container-text'>{calculate_configuration(configuration[idx], option)}</div>
                                      <div className='customization-option-input-info-container-text-small'>{calculate_configuration(configuration[idx], option, true)}</div>
                                    </div>
                                  ) : configuration?.length >= idx ?
                                  (
                                    configuration[idx]?.configType === 'custom' ?
                                    (
                                      <></>
                                    ) : (
                                      <div className='customization-option-input-info'>{calculate_configuration(configuration[idx], option)}</div>
                                    )
                                  ) : (
                                    <></>
                                  )
                                }
                              </div>
                            )
                          } else if (product?.name === 'Räätälöitävä viherseinä') {
                            return (
                              <div
                                className='customization-option vertical no-cursor'
                                key={option.id}
                              >
                                <div className='customization-option-input-container no-select'>
                                  <div className='customization-option-input-wrapper'>
                                    <div
                                      className={`product-list-product-entry-left-content-adjust-button-icon`}
                                      onClick={() => update_product_customization(selected_value(option), option, customization, 'subtract')}
                                    >
                                      <MinusIcon />
                                    </div>
                                    <input
                                      type="text"
                                      onChange={(event) => {const value = event.target.value
                                        if (/^\d*$/.test(value)) {
                                          handleOptionChange(event, option, customization)
                                        }}}
                                      value={selected_value(option) ?? 1}
                                    />
                                    <div
                                      className='product-list-product-entry-left-content-adjust-button-icon'
                                      onClick={() => update_product_customization(selected_value(option), option, customization, 'add')}
                                    >
                                      <PlusIcon />
                                    </div>
                                  </div>
                                  <div className='customization-option-input-text'>{configuration !== null ? configuration[idx]?.text : ""}</div>
                                </div>
                                { Array.isArray(configuration) && configuration?.length >= idx && configuration[idx]?.showExtra ?
                                  (
                                    <div className='customization-option-input-info-container'>
                                      <div className='customization-option-input-info-container-text'>{calculate_configuration(configuration[idx], option)}</div>
                                      <div className='customization-option-input-info-container-text-small'>{calculate_configuration(configuration[idx], option, true)}</div>
                                    </div>
                                  ) : (
                                    <div className='customization-option-input-info'>{calculate_configuration(configuration[idx], option)}</div>
                                  )
                                }
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                  </div>
                </React.Fragment>
              )
            } else if (customization.type === 4) {
              let imagesSrcs = customization.options[0]
              return (
                <React.Fragment key={idx}>
                  <div className='customization-entry-container'ref={ref => {scrollRefs.current[customization.name] = ref}}>
                      <div className='mobile'>
                        <div className='customization-title'>
                              {`${idx+1}. ${t(`products.${get_decoded_string(customization.name)}`)}`}
                        </div>
                        <div className='customization-description-container'>
                          <p className='customization-description'>
                            {`${t(`${get_decoded_string(product.name)}.${get_decoded_string(customization.name)}.description`)}`}
                          </p>
                          {/* <p
                            className='customization-description-link'
                            onClick={() => toggleReadMoreModal(customization)}
                          >
                            {t('common.readMore')}
                          </p> */}
                        </div>
                      </div>
                    <div className='customization-carousel green-stop'>
                      <Carousel
                        key={carouselKey}
                        showThumbs={false}
                        showStatus={false}
                        renderDotsOutside={true}
                        swipeable
                        emulateTouch
                        renderIndicator={renderCustomIndicator}
                        renderArrowPrev={(clickHandler) =>
                          customizedArrowPrev(clickHandler, true, imagesSrcs?.imgSrcs?.length === 1 ? "single-item" : "")
                        }
                        renderArrowNext={(clickHandler) =>
                          customizedArrowNext(clickHandler, true, imagesSrcs?.imgSrcs?.length === 1 ? "single-item" : "")
                        }
                      >
                        { imagesSrcs && imagesSrcs.imgSrcs.map((img, index) => {
                            return (
                              <div
                                className='customization-carousel-image green-stop'
                                key={index}
                              >
                                <img
                                  src={img}
                                  alt='customization option'
                                />
                              </div>
                            )
                          })
                        }
                      </Carousel>
                    </div>
                    <div className='customization-content-container'>
                      <div className='customization-content'>
                        <div className='desktop'>
                          <div className='customization-title'>
                            {`${idx+1}. ${t(`products.${get_decoded_string(customization.name)}`)}`}
                          </div>
                          <div className='customization-description-container'>
                            <p className='customization-description'>
                              {`${t(`${get_decoded_string(product.name)}.${get_decoded_string(customization.name)}.description`)}`}
                            </p>
                            {/* <p
                              className='customization-description-link'
                              onClick={() => toggleReadMoreModal(customization)}
                            >
                              {t('common.readMore')}
                            </p> */}
                          </div>
                        </div>
                        <div className='customization-options gap'>
                          { customization.options.map((option, optionIdx) => {
                              if (configuration && configuration.length >= idx && configuration[idx]?.text.length >= optionIdx) {
                                return (
                                  <React.Fragment key={optionIdx}>
                                    <div className='customization-option-container'>
                                      <div className='customization-option-wrapper'>
                                        <div className='customization-option-minus-icon no-select'><CircleMinusIcon onClick={() => optionMinus(option, customization)}/></div>
                                        <input
                                          type="text"
                                          value={selected_value(option) ?? 1}
                                          onChange={(event) => {const value = event.target.value
                                          if (/^\d*$/.test(value)) {
                                            handleOptionChange(event, option, customization)
                                          }}}
                                        />
                                        <span>kpl</span>
                                        <div className='customization-option-plus-icon no-select'><CirclePlusIcon onClick={() => optionPlus(option, customization)}/></div>
                                      </div>
                                      <div className='customization-option-container-text'>{configuration[idx].text[optionIdx]}</div>

                                    </div>
                                  </React.Fragment>
                                )
                              }
                            })
                          }
                          {product && product?.name === 'Viherseinä' ?
                            (
                              <div className='customization-option-input-info'>{calculate_configuration(configuration[0])}</div>
                            ) : (
                              <></>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  { filtered_customizations.length - 1 !== idx ?
                    (
                      <div className='customization-page-divider'></div>
                    ) : (
                      <></>
                    )
                  }
                </React.Fragment>
              )
            }
            return null
          })}
        </div>
      </div>

      <CustomModal
        customization={selectedCustomization}
        showing={showReadMoreState}
        closeModal={toggleReadMoreModal}
      >
        {selectedCustomization && (
          <div className='customization-modal-content'>
            <div className='customization-modal-title'>
              {selectedCustomization.name}
            </div>
            <div className='customization-modal-description'>
              {selectedCustomization.description}
            </div>

            {selectedCustomization.options.map((option) => {
              return (
                <div className='customization-modal-options-entry' key={`${option.id}`}>
                  <div className='customization-modal-options-entry-img-container'>
                    <img
                      src={option.imgSrcs[0]}
                      alt={`customization option - ${option.id}`}
                    />
                  </div>
                  <div className='customization-modal-options-entry-title'>
                    {option.name}
                  </div>
                  <div className='customization-modal-options-entry-description'>
                    {option.description}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </CustomModal>
      <YourSelection product={product} scrollRefs={scrollRefs} />
    </>
  )
}

export default CustomizeProduct
