const koivu = {
  headerGroup: 'Koivu',
  headerTitle: 'The Finnish and harmonious Koivu is a sustainable choice.',
  infoCard1Title: 'A sustainable choice',
  infoCard1Text1: 'Designed and manufactured in Finland',
  infoCard1Text2: 'Made of Finnish birch plywood',
  infoCard1Text3: 'Planting pot made of 100% recycled plastic',
  infoCard2Title: 'Versatile',
  infoCard2Text1: 'Suitable for a wide range of interiors',
  infoCard2Text2: 'Place individually or arrange in groups',
  infoCard2Text3: 'Four beautiful colour options',
  textCard1: `The pots, space dividers, green panels and green walls in the Koivu collection are 
      designed and manufactured in Finland. The timeless design makes Koivu products suitable 
      for a wide range of spaces and styles.`,
  infoBox1Title: 'Serene beauty',
  infoBox1Text: `Koivu represents a more sustainable approach to our industry and is the choice 
      of environmentally conscious decorators. The products are a masterpiece of Finnish wood 
      craftsmanship. 
      \\n
      In the Koivu collection you find pots, room dividers, green panels and green walls that 
      evoke the peace and harmony of nature. The timeless design makes Koivu products suitable 
      for various spaces and styles, and the vast collection offers a wide range of solutions 
      even for large spaces.
      \\n
      Birch plywood makes the pots light but durable and therefore easy to move. Structure your 
      space with room dividers, create spectacular ensembles by combining plants and pots of 
      different sizes, or hang nature on your wall in the form of a green panel or green wall.`,
  infoBox2Title: 'Finnish pioneering',
  infoBox2Text: `It all started with the Koivu plant pots, driven by a desire to create more 
      sustainable green design solutions. We demand more from our pots than just a beautiful 
      design - they have to be safe, stable, and manufactured and transported sustainably. 
      When we couldn’t find a partner that aligns with our values, the solution was clear: 
      we would make the pots ourselves. The first Koivu pots were completed in the spring of 2022. 
      \\n
      InnoGreen's Koivu products are designed and manufactured in Finland from Finnish birch 
      plywood in cooperation with local producers and wood processing professionals. The journey 
      of our Koivu pots from the factory to our clients is exceptionally short.`,
  productGroupLink: 'Read more about Koivu Products'
}

export default koivu