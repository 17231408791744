const vertical_garden = {
  name: 'Vertical Garden',
  longDescription: `Hulevettä hyödyntävä InnoGreenin ulkoviherseinä, Vertical Garden, 
      edustaa uudenlaista kaupunkimuotoilua, jolla pyritään lisäämään viihtyisyyttä ja 
      löytämään kestäviä ratkaisuja lisääntyvän huleveden käsittelyyn ja 
      kaupunkibiodiversiteetin tukemiseen. Lisäksi ulkoviherseinän avulla voidaan hillitä 
      lämpösaarekeilmiötä viilentämällä rakennuksia ja viherseinän välitöntä ympäristöä.
      \\n
      Vertical Garden integroidaan jo olemassa oleviin rakennuksiin ja rakenteisiin ja 
      sen ainutlaatuinen kasteluteknologia kerää ja hyödyntää sijoituskohteen hulevettä. 
      Huleveden hyödyntäminen kastelussa vähentää viemäriverkoston kuormitusta, estää 
      huleveteen kertyvän ympäristölle haitallisen aineksen päätymistä vesistöihin sekä 
      säästää juomakelpoista vettä, jota muutoin käytettäisiin kasteluun.
      \\n
      Suunnittelemme Vertical Gardenin kasvillisuuden yhdessä asiakkaan toiveiden ja 
      kohteen vaatimusten mukaisesti. Ulkoviherseinissä käytämme lähtökohtaisesti sekä 
      monivuotisia että kausikasveja, jolloin kokonaisuus on näyttävä. Tarjoamme aina 
      kokonaisvaltaisen huoltopalvelun toteutetun kokonaisuuden ylläpitämiseksi. 
      Huoltopalvelu sisältää myös talvi- ja kesäkauden vaatimat muutostyöt.
      `,
  measurements: [
    {
      type: 'Täysin räätälöitävä tuote. '
    },
  ],
  materials: [
    {
      type: 'Perustus:',
      value: 'Teräs ja betoni'
    },
    {
      type: 'Runko:',
      value: 'Kestopuu'
    },
    {
      type: 'Liitännät:',
      value: 'Metalli'
    },
    {
      type: 'Istutuslaatikot:',
      value: 'Polyeteeni'
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi'
    },
    {
      type: 'Käyttö:',
      value: 'Ulkotilat',
      icon: 'outdoor'
    },
  ],
  wlt: {},
}

export default vertical_garden