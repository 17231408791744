import './styles.scss'
import { useEffect } from 'react'
import CustomButton from '../CustomButton'
import { useTranslation } from 'react-i18next'

const CustomPrivacyModal = ({ showing, closeModal }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const multi_line_process = (text) => {
    const handle_new_line = text.replace(/(?:\r\n|\r|\n)/g, '<br />')

    return handle_new_line
  }

  return (
    showing && (
      <div className='custom-privacy-modal-root'>
        <div className='custom-privacy-modal-container'>
          <div className='custom-privacy-modal-title'>
            {t('privacy.privacyTitle')}
          </div>
          <div className='custom-privacy-modal-sub-title'>
            {t('privacy.privacySubTitle')}
          </div>
          <div className='custom-privacy-modal-content'>
            <div className='custom-privacy-modal-contact-entry'>
              <div className='custom-privacy-modal-contact-entry-label'>
                {t('privacy.labelController')}:
              </div>
              <div className='custom-privacy-modal-contact-entry-value'>
                InnoGreen / Green House Effect Oy
              </div>
            </div>
            <div className='custom-privacy-modal-contact-entry'>
              <div className='custom-privacy-modal-contact-entry-label'>
                {t('privacy.labelContactPerson')}:
              </div>
              <div className='custom-privacy-modal-contact-entry-value'>
                Oula Harjula
              </div>
            </div>
            <div className='custom-privacy-modal-contact-entry'>
              <div className='custom-privacy-modal-contact-entry-label'>
                {t('privacy.labelEmail')}:
              </div>
              <div className='custom-privacy-modal-contact-entry-value'>
                oula@innogreen.fi
              </div>
            </div>
            <div className='custom-privacy-modal-section-separator'></div>
            <div class='custom-privacy-modal-section-title'>
              {t('privacy.registerNameAndPurpose')}
            </div>
            <div className='custom-privacy-modal-contact-section-entry'>
              <div className='custom-privacy-modal-contact-section-entry-label'>
                {t('privacy.registerName')}:
              </div>
              <div className='custom-privacy-modal-contact-section-entry-value'>
                {t('privacy.registerNameDescription')}
              </div>
            </div>
            <div className='custom-privacy-modal-contact-section-entry'>
              <div className='custom-privacy-modal-contact-section-entry-label'>
                {t('privacy.purpose')}:
              </div>
              <div className='custom-privacy-modal-contact-section-entry-value'>
                {t('privacy.purposeDescription')}
              </div>
            </div>
            <ul>
              <li>{t('privacy.purposeItem1')}</li>
              <li>{t('privacy.purposeItem2')}</li>
              <li>{t('privacy.purposeItem3')}</li>
              <li>{t('privacy.purposeItem4')}</li>
            </ul>
            <div className='custom-privacy-modal-section-separator'></div>
            <div class='custom-privacy-modal-section-title'>
              {t('privacy.collectedData')}
            </div>
            <div>{t('privacy.collectedDataDescription')}</div>
            <ul>
              <li>{t('privacy.collectedDataItem1')}</li>
              <li>{t('privacy.collectedDataItem2')}</li>
              <li>{t('privacy.collectedDataItem3')}</li>
              <li>{t('privacy.collectedDataItem4')}</li>
              <li>{t('privacy.collectedDataItem5')}</li>
              <li>{t('privacy.collectedDataItem6')}</li>
            </ul>
            <div class='custom-privacy-modal-section-title'>
              {t('privacy.sourcesOfInformation')}
            </div>
            <ul>
              <li>{t('privacy.sourcesOfInformationItem1')}</li>
              <li>{t('privacy.sourcesOfInformationItem2')}</li>
              <li>{t('privacy.sourcesOfInformationItem3')}</li>
            </ul>
            <div className='custom-privacy-modal-section-separator'></div>
            <div class='custom-privacy-modal-section-title'>
              {t('privacy.useOfCookies')}
            </div>
            <div>{t('privacy.useOfCookiesDescription1')}</div>
            <ul>
              <li>{t('privacy.useOfCookiesItem1')}</li>
              <li>{t('privacy.useOfCookiesItem2')}</li>
              <li>{t('privacy.useOfCookiesItem3')}</li>
            </ul>
            <div>{t('privacy.useOfCookiesDescription2')}</div>
            <div className='custom-privacy-modal-section-separator'></div>
            <div class='custom-privacy-modal-section-title'>
              {t('privacy.dataDisclosureInternationalTransfers')}
            </div>
            <div>
              {t('privacy.dataDisclosureInternationalTransfersDescription')}
            </div>
            <ul>
              <li>{t('privacy.dataDisclosureInternationalTransfersItem1')}</li>
              <li>{t('privacy.dataDisclosureInternationalTransfersItem2')}</li>
              <li>{t('privacy.dataDisclosureInternationalTransfersItem3')}</li>
            </ul>
            <div className='custom-privacy-modal-contact-section-entry'>
              <div>
                <b>{t('privacy.transfersOutside')} </b>
                {t('privacy.transfersOutsideDescription')}
              </div>
            </div>
            <ul>
              <li>{t('privacy.transfersOutsideItem1')}</li>
              <li>{t('privacy.transfersOutsideItem2')}</li>
              <li>{t('privacy.transfersOutsideItem3')}</li>
            </ul>
            <div className='custom-privacy-modal-section-separator'></div>
            <div class='custom-privacy-modal-section-title'>
              {t('privacy.dataRetentionSecurity')}
            </div>
            <div>{t('privacy.dataRetentionSecurityDescription1')}</div>
            <div>{t('privacy.dataRetentionSecurityDescription2')}</div>
            <ul>
              <li>{t('privacy.dataRetentionSecurityItem1')}</li>
              <li>{t('privacy.dataRetentionSecurityItem2')}</li>
            </ul>
            <div className='custom-privacy-modal-section-separator'></div>
            <div class='custom-privacy-modal-section-title'>
              {t('privacy.rightsOfDataSubject')}
            </div>
            <div>{t('privacy.rightsOfDataSubjectDescription')}</div>
            <ul>
              <li>
                <b>{t('privacy.rightsOfDataSubjectItemLabel1')} </b>
                {t('privacy.rightsOfDataSubjectItem1')}
              </li>
              <li>
                <b>{t('privacy.rightsOfDataSubjectItemLabel2')} </b>
                {t('privacy.rightsOfDataSubjectItem2')}
              </li>
              <li>
                <b>{t('privacy.rightsOfDataSubjectItemLabel3')} </b>
                {t('privacy.rightsOfDataSubjectItem3')}
              </li>
              <li>
                <b>{t('privacy.rightsOfDataSubjectItemLabel4')} </b>
                {t('privacy.rightsOfDataSubjectItem4')}
              </li>
              <li>
                <b>{t('privacy.rightsOfDataSubjectItemLabel5')}</b>
              </li>
              <li>
                <b>{t('privacy.rightsOfDataSubjectItemLabel6')} </b>
                {t('privacy.rightsOfDataSubjectItem6')}
              </li>
            </ul>
            <div className='custom-privacy-modal-section-separator'></div>
            <div class='custom-privacy-modal-section-title'>
              {t('privacy.changesPrivacy')}
            </div>
            <div>{t('privacy.changesPrivacyDescription1')}</div>
            <div>{t('privacy.changesPrivacyDescription2')}</div>
          </div>

          <div className='custom-privacy-modal-btn-group'>
            <CustomButton
              button_text={t('privacy.closeBtn')}
              onClickHandler={() => close()}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default CustomPrivacyModal
