const parkly_palveluna = {
  name: 'Parkly as a service',
  longDescription: `When you choose Parkly as your service, Parkly's modular solutions are combined with 
      InnoGreen's high quality green expertise and service. You get a green unit with modules and green 
      plants suitable for your urban environment. InnoGreen's service is a flexible and cost-effective 
      solution, even for short-term needs. The fixed monthly fee includes everything from planning to 
      implementation and from maintenance to storage.`,
  linkText: 'See the Parkly catalogue',
  link: 'https://publuu.com/flip-book/14452/879141',
  title: 'InnoGreen x Parkly',
  longDescription2: `The green wall developed by InnoGreen and Parkly combines InnoGreen's unique green 
      wall structure with the versatility of Parkly's urban furniture.
      \\n
      The InnoGreen x Parkly green wall module brings lush greenery, coolness, shade and fragrant plants 
      that attract pollinators and other insects, even in the middle of a busy city. Not only does the 
      green wall module provide a cosy surrounding, it also brings peace of mind as it absorbs up to 7 db 
      of traffic noise.
      \\n
      The green wall module is available with or without a bench. It is easy to combine several modules 
      in a row to create a complete seating area and a larger, uniform green wall. What better place to 
      rest in the summer heat than in the shady embrace of a green wall?`,
  measurements: [
    {
      type: 'Green wall module:',
      value: '1200 x 500 x 1800 mm'
    },
    {
      type: 'Green wall module with seat:',
      value:'1200 x 1200 x 1800 mm'
    }
  ],
  materials: [
    {
      type: 'Frame:',
      value: 'Galvanised and powder coated metal'
    },
    {
      type: 'Seat parts:',
      value: 'Heat-treated ash tree'
    },
    {
      type: 'Green wall modules:',
      value: 'Abs plastic, made in Kaarina'
    },
    {
      type: 'Plants:',
      value: 'Finland and Europe'
    },
    {
      type: 'Country of manufacture:',
      value: 'Finland and Europe'
    },
    {
      type: 'Usage:',
      value: 'Indoor and outdoor',
      icon: 'both'
    },
  ],
  wlt: {},
}

export default parkly_palveluna