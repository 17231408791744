import LoadingScreen from './components/common/LoadingScreen'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import {
  fetch_product_groups,
  log_in,
  log_out,
  fetch_current_user,
  fetch_csrf_token,
} from './store/asyncThunk'
import router from './routes'
import { fetchAuthSession } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import CustomCookiesBanner from './components/common/CustomCookiesBanner'
import CustomPrivacyModal from './components/common/CustomPrivacyModal'

const App = () => {
  const dispatch = useDispatch()
  const [is_loading, setIsLoading] = useState(true)
  const current_user = useSelector((state) => state.user.current_user)

  useEffect(() => {
    //First get current user from backend to see if there are any active session
    fetchCurrentUser()
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('innogreen_cookies_approved')) {
      setShowCookiesBanner(true)
    }
    const hub_listener = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signedIn':
          findCurrentAuthSession()
          break
        case 'signInWithRedirect_failure':
          break
        default:
          break
      }
    })
    return hub_listener
  }, [])

  const findCurrentAuthSession = async () => {
    setIsLoading(true)
    try {
      // Check if there are any active Auth session (AWS Cognito via Google)
      const res = await fetchAuthSession()
      if (res && res.tokens && res.tokens.idToken) {
        //If there is, then sign in
        dispatch(
          log_in({
            id_token: res.tokens.idToken.payload,
          })
        )
          .then((res) => {
            fetchResources()
          })
          .catch((err) => {
            dispatch(log_out())
            setIsLoading(false)
          })
      }
    } catch (error) {
      dispatch(log_out())
      setIsLoading(false)
    }
  }

  const fetchResources = async () => {
    await dispatch(fetch_csrf_token())
    dispatch(fetch_product_groups())
  }

  const fetchCurrentUser = async () => {
    if (!current_user) {
      setIsLoading(true)
      await dispatch(fetch_current_user())
        .then((res) => {
          if (!res.error) {
            fetchResources()
            setIsLoading(false)
          } else {
            fetchResources()
            setIsLoading(false)
          }
        })
        .catch((err) => {
          setIsLoading(false)
        })
    }
  }

  const [showCookiesBanner, setShowCookiesBanner] = useState(false)
  const toggleCookiesBanner = () => {
    setShowCookiesBanner(!showCookiesBanner)
  }

  const [showCustomPrivacyModal, setShowCustomPrivacyModal] = useState(false)
  const toggleCustomPrivacyModal = () => {
    setShowCustomPrivacyModal(!showCustomPrivacyModal)
  }

  return (
    <div className='App' id='App'>
      {is_loading ? <LoadingScreen /> : <RouterProvider router={router} />}
      {showCookiesBanner && (
        <CustomCookiesBanner
          showing={showCookiesBanner}
          closeModal={toggleCookiesBanner}
          togglePrivacyModal={toggleCustomPrivacyModal}
        />
      )}
      {/* Privacy */}
      {showCustomPrivacyModal && (
        <CustomPrivacyModal
          showing={showCustomPrivacyModal}
          closeModal={toggleCustomPrivacyModal}
        />
      )}
    </div>
  )
}

export default App
