import './styles.scss'

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { Close } from '../../../../components/Icons'
import CustomButton from '../../../../components/common/CustomButton'

import { add_inquiry_note } from '../../../../store/asyncThunk'


const roleOptions = [
  {
    id: 1,
    name: 'Primary',
  },
  {
    id: 2,
    name: 'Secondary',
  },
  // {
  //   id: 3,
  //   name: 'Read Only',
  // },
]

const InquiryAddNoteModal = (props) => {
  const {
    inquiry,
    showing,
    closeModal,
  } = props;
  const dispatch = useDispatch()
  const [errorList, setErrorList] = useState([])
  const [note, setNote] = useState('')



  const close = () => {
    document.body.style.overflow = 'unset'
    setNote('')
    closeModal()
  }

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const handleSaveBtnClick = async () => {
    const payload = {
      inquiry_id: inquiry.id,
      note: note
    }
    await dispatch(add_inquiry_note(payload))
    .then((res) => {
      if (res.error) {
        setErrorList(['-Error saving note'])
      }
    })
    close()
  }

  const disableSaveButton = () => {
    if (note.trim() === '') {
      return true
    }
    return false
  }

  return (
    showing && (
      <div className='add-note-modal-root'>
        <div className='add-note-modal-container'>
          <div className='add-note-modal-header'>
            <div className='add-note-modal-header-title'>Add Note</div>
          </div>
          { errorList.length > 0 && <div className='add-note-modal-error'>{
              errorList.map((error, index) => (
                <div className='add-note-modal-error-entry' key={index}>{error}</div>
              ))
            }</div> }
          <div className='add-note-modal-content'>
           
            <div className='add-note-modal-content-input'>
              <textarea
                className='add-note-modal-content-input-textarea'
                rows={15}
                // cols={80}
                placeholder='Enter note here...'
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>

            <div className='add-note-modal-btn-group'>
              <CustomButton
                button_text='SAVE'
                primary={true}
                onClickHandler={() => handleSaveBtnClick()}
                disabled={disableSaveButton()}
              />
              <CustomButton transparent={true} button_text='CANCEL' onClickHandler={() => close()} />
            </div>
        </div>
      </div>
    )
  )
}

export default InquiryAddNoteModal
