import './styles.scss'

import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import CustomButton from '../../../../components/common/CustomButton'
import CustomDropdown from '../../../../components/common/CustomDropdown'
import ProductSummaryList from '../../../../components/ProductSummaryList'
import LoadingSpinner from '../../../../components/common/LoadingSpinner'

import {
  fetch_product_customizations,
} from '../../../../store/asyncThunk'

const view_filters = [
  {
    name: 'BY PRODUCTS',
    translation: 'project.byProducts',
    id: 1,
    customStyles: {
      backgroundColor: '#FCEA19',
      borderColor: '#FCEA19',
    },
  },
  {
    name: 'BY SPACE',
    translation: 'project.bySpace',
    id: 2,
    space_id: 1,
    customStyles: {
      backgroundColor: '#FCEA19',
      borderColor: '#FCEA19',
    },
  },
]

const InquiryProjectSummary = (props) => {
  const {
    projectView,
    current_project,
  } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [selectedViewFilter, setSelectedViewFilter] = useState(view_filters[0])
  const [productGroups, setProductGroups] = useState({})
  const contentRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (current_project) {
      (async () => {
        setIsLoading(true)
          await dispatch(fetch_product_customizations(true))
        setIsLoading(false)
      })()
    }
  }, [current_project])

  const handleViewFilterClick = (item) => {
    if (item && item.id !== 6) {
      setSelectedViewFilter(item)
    }
  }

  useEffect(() => {
    if (selectedViewFilter && current_project) {
      //GROUP BY PRODUCT
      if (selectedViewFilter.id === 1) {
        let product_list = current_project.products.map((p) => ({
          ...p,
          quantity: get_product_quantity(p),
          spaces: get_product_space(p),
        }))
        product_list = product_list.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            ? -1
            : 1
        })
        setProductGroups({ Product: product_list })
      }
      //GROUP BY space
      if (selectedViewFilter.id === 2) {
        let space_groups = {}
        current_project.spaces.forEach((space) => {
          space_groups[space.name] = []
          current_project.products.forEach((pro) => {
            if (space.assigned_products.some((p) => p.id === pro.id)) {
              space_groups[space.name].push({
                ...pro,
                quantity: space.assigned_products.find((p) => p.id === pro.id)
                  .quantity,
              })
            }
          })
        })

        setProductGroups(space_groups)
      }
    }
  }, [selectedViewFilter, current_project])

  const get_product_quantity = (product) => {
    let result = 0
    const assigned_spaces = current_project.spaces.filter((s) =>
      s.assigned_products.some((pro) => pro.id === product.id)
    )
    assigned_spaces.forEach((s) => {
      s.assigned_products.forEach((p) => {
        if (p.id === product.id) {
          result += p.quantity
        }
      })
    })
    return result
  }

  const get_product_space = (product) => {
    let list = []
    const assigned_spaces = current_project.spaces.filter((s) =>
      s.assigned_products.some((pro) => pro.id === product.id)
    )

    assigned_spaces.forEach((s) => {
      const assigned_product = s.assigned_products.find(
        (a) => a.id === product.id
      )

      if (assigned_product) {
        list.push({
          id: s.id,
          name: s.name,
          quantity: assigned_product.quantity,
        })
      }
    })
    return list
  }

  return (
    <div className={projectView === 'summary' ? 'inquiry-project-show' : 'inquiry-project-hide'}>
      {/* PRODUCT FILTER GROUP */}
      <div className='inquiry-filter-groups-container'>
        <div className='inquiry-filter-groups-wrapper'>
          <div className='inquiry-filter-container'>
            <div className='inquiry-filter-label'>
              {t('project.view').toUpperCase()}
            </div>
            <div className='inquiry-filter-selection'>
              <div className='inquiry-filter-selection-buttons'>
                {view_filters.map((filter) => {
                  return (
                    <CustomButton
                      key={filter.id}
                      button_text={t(filter.translation).toUpperCase()}
                      customStyles={filter.customStyles}
                      selected={selectedViewFilter.id === filter.id}
                      onClickHandler={() => handleViewFilterClick(filter)}
                    />
                  )
                })}
              </div>
              <div className='inquiry-filter-selection-dropdown mobile'>
                <CustomDropdown
                  options={view_filters}
                  selected={selectedViewFilter}
                  onClickHandler={(val) => handleViewFilterClick(val)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* PRODUCT LIST  */}
      <div className='inquiry-summary-list-container'>
        <div className='inquiry-summary-list-wrapper'>
          {isLoading ?
            (
              <LoadingSpinner noBackground/>
            ) : (
              <ProductSummaryList fetchProductCustomizations={false} productGroups={productGroups} selectedViewFilter={selectedViewFilter} ref={contentRef} />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default InquiryProjectSummary