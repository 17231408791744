import './styles.scss'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Close } from '../Icons'
import CustomButton from '../common/CustomButton'

import {
  MenuItem,
  Popper,
  ClickAwayListener,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Toolbar,
  Typography,
  FormControl,
  FilledInput,
  InputAdornment,
  Box,
  TableSortLabel,
  Tooltip,
  IconButton,
  CircularProgress
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import SearchIcon from '@mui/icons-material/Search'
import FilterListIcon from '@mui/icons-material/FilterList'
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';

import moment from 'moment'

import { setSelectedUser } from '../../store/managementSlice'

import { fetch_users, edit_user, count_inquiry } from '../../store/asyncThunk'

const role_options = [
  {
    name: 'Admin',
    id: 1,
  },
  {
    name: 'Sales Director',
    id: 3,
  },
  {
    name: 'Sales Person',
    id: 4,
  },
  {
    name: 'Editor',
    id: 5,
  },
  {
    name: 'User',
    id: 2,
  },
]

function descendingComparator(a, b, orderBy, order) {
  let valueA = a[orderBy]
  let valueB = b[orderBy]

  // Handle empty strings and null values by moving them to the bottom
  if (valueA === null || valueA === '') {
    valueA = undefined
  }
  if (valueB === null || valueB === '') {
    valueB = undefined
  }

  // For strings, convert to lowercase for case-insensitive comparison
  if (typeof valueA === 'string') {
    valueA = valueA.toLowerCase()
  }
  if (typeof valueB === 'string') {
    valueB = valueB.toLowerCase()
  }

  // Comparison logic for sorting null/empty values, always move them to the bottom
  if (order === 'desc') {
    if (valueB === undefined) return -1 // Move null/empty to the bottom
    if (valueA === undefined) return 1  // Move null/empty to the bottom
  } else {
    if (valueB === undefined) return 1  // Move null/empty to the bottom
    if (valueA === undefined) return -1 // Move null/empty to the bottom
  }

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, order)
    : (a, b) => -descendingComparator(a, b, orderBy, order)
}

const dropdownOptions = [
  { name: 'Active', id: 1 },
  { name: 'Inactive', id: 2 },
]

const UserTable = (props) => {
  const {
    setSelected,
    handleSetSelectedTab,
    handleSetSelectedSelection,
  } = props
  const [isLoading, setIsLoading] = useState(true)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [newPasswordPayload, setNewPasswordPayload] = useState(null)
  const dispatch = useDispatch()
  const users = useSelector((state) => state.management.users)
  const { t } = useTranslation()
  const [errorList, setErrorList] = useState([])
  const [requested_success, setRequestedSuccess] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false) // To track which dropdown is open
  const [openFilterDropdown, setOpenFilterDropdown] = useState(false)
  const anchorRefs = useRef({})
  const anchorRef = useRef(null)
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null)
  const [selectedRoleFilter, setSelectedRoleFilter] = useState(null)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('creation_date')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const inputRef = useRef(null)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search)
    }, 500)
  
    return () => clearTimeout(handler)
  }, [search])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      await dispatch(fetch_users({search: debouncedSearch, status: selectedStatusFilter?.name, role: selectedRoleFilter?.name}))
      setIsLoading(false)
    })()
  }, [debouncedSearch, selectedStatusFilter, selectedRoleFilter])

  useEffect(() => {
    (async () => {
      dispatch(count_inquiry())
    })()
  }, [users])

  const handleRoleClick = async (id, role_item) => {

    if (id && role_item) {
      setOpenDropdown(false)
      await dispatch(
        edit_user({
          id: id,
          role: role_item.id,
        })
      )
    }
  }

  const handleUserStatus = async (user) => {
    if (user) {
      setIsLoading(true)
      await dispatch(
        edit_user({
          id: user.id,
          status: user.status === 1 ? 2 : 1,
        })
      )
      setIsLoading(false)
    }
  }

  const toggleChangePasswordModal = (user) => {
    setErrorList([])
    setRequestedSuccess(false)
    if (user) {
      setNewPasswordPayload({
        id: user.id,
        password: '',
        confirm_password: '',
      })
    } else {
      setNewPasswordPayload(null)
    }
    setShowChangePasswordModal(!showChangePasswordModal)
  }

  const updateNewPasswordInput = ({ password, confirm_password }) => {
    if (password !== undefined) {
      setNewPasswordPayload({ ...newPasswordPayload, password: password })
    }
    if (confirm_password !== undefined) {
      setNewPasswordPayload({
        ...newPasswordPayload,
        confirm_password: confirm_password,
      })
    }
  }

  const handleConfirmNewPassword = async () => {
    const updated_error_list = []
    if (
      !newPasswordPayload.password ||
      newPasswordPayload.password.trim().length === 0
    ) {
      updated_error_list.push(t('login.errorPasswordRequired'))
    }
    if (
      newPasswordPayload.password &&
      newPasswordPayload.password.trim().length < 6
    ) {
      updated_error_list.push(t('login.errorPasswordLength'))
    }
    if (
      !newPasswordPayload.confirm_password ||
      newPasswordPayload.confirm_password.trim().length === 0
    ) {
      updated_error_list.push(t('login.errorConfirmPasswordRequired'))
    }
    if (
      newPasswordPayload.password &&
      newPasswordPayload.confirm_password &&
      newPasswordPayload.password.trim().length > 0 &&
      newPasswordPayload.confirm_password.trim().length > 0 &&
      newPasswordPayload.password !== newPasswordPayload.confirm_password
    ) {
      updated_error_list.push(t('login.errorPasswordNotMatch'))
    }
    setErrorList(updated_error_list)
    if (updated_error_list.length === 0) {
      dispatch(
        edit_user({
          id: newPasswordPayload.id,
          password: newPasswordPayload.password,
        })
      ).then((res) => {
        if (!res.error) {
          setErrorList([])
          setRequestedSuccess(true)
        } else {
          setErrorList([t('management.genericChangePasswordError')])
        }
      })
    }
  }

  const handleClick = (id) => {
    setOpenDropdown(openDropdown === id ? false : id) // Toggle dropdown visibility
  }

  const handleClose = () => {
    setOpenDropdown(false) // Close the dropdown
  }

  const handleClickFilter = () => {
    setOpenFilterDropdown(true) // Toggle dropdown visibility
  }

  const handleCloseFilter = () => {
    setOpenFilterDropdown(false) // Close the dropdown
  }

  const get_role = (role) => {
    switch (role) {
      case 1:
        return 'Admin'
      case 2:
        return 'User'
      case 3:
        return 'Sales Director'
      case 4:
        return 'Sales Person'
      case 5:
        return 'Editor'
      default:
        return 'Unknown'
    }
  }

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const visibleRows = useMemo(
    () =>
      [...users]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, users],
  )

  const handleSelectRole = (role) => {
    // check if role is already selected
    if (selectedRoleFilter?.id === role.id) {
      setSelectedRoleFilter(null)
    } else {
      setSelectedRoleFilter(role)
    }
  }

  const handleSelectStatus = (status) => {
    // check if status is already selected
    if (selectedStatusFilter?.id === status.id) {
      setSelectedStatusFilter(null)
    } else {
      setSelectedStatusFilter(status)
    }
  }

  const selectUserClick = (user) => {
    dispatch(setSelectedUser(user))
    handleSetSelectedSelection('User', user.id)
  }

    const format_date = (date) => {
      if (date) {
        return moment(date).format('DD.MM.YYYY HH:mm')
      }
    }

  return (
    <>
      <div className='user-table-container'>
        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0, position: 'relative' }}>
        
        <Toolbar>
          <Typography
            sx={{ flex: '1 1 10%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Users
          </Typography>
          {selectedStatusFilter && (
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 1,
                px: '10px',
                py: '5px',
                border: '1px solid #D6ACFC',
                borderRadius: '8px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '600',
                fontFamily: 'Libre Franklin',
                backgroundColor: '#F8F1FF',
                position: 'relative',
                pr: 4,
              }}
            >
              {selectedStatusFilter.name}
    
              <IconButton
                onClick={() => setSelectedStatusFilter(null)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  padding: 0,
                }}
              >
                <CloseIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Typography>
          )}
          {selectedRoleFilter && (
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 1,
                px: '10px',
                py: '5px',
                border: '1px solid #D6ACFC',
                borderRadius: '8px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '600',
                fontFamily: 'Libre Franklin',
                backgroundColor: '#F8F1FF',
                position: 'relative',
                pr: 4,
              }}
            >
              {selectedRoleFilter.name}
    
              <IconButton
                onClick={() => setSelectedRoleFilter(null)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  padding: 0,
                }}
              >
                <CloseIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Typography>
          )}
          <Tooltip>
            <IconButton
              ref={anchorRef}
              onClick={handleClickFilter}
              disableRipple={true}
            >
              <FilterListIcon
              
              />
                <Popper
                  open={openFilterDropdown}
                  anchorEl={anchorRef.current}
                  placement="bottom"
                >
                  <ClickAwayListener
                    onClickAway={handleCloseFilter}
                  >
                    <Paper sx={{ p: 1 }}>
                      { dropdownOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            disableRipple
                            onClick={() => handleSelectStatus(option)}
                            selected={option.id === selectedStatusFilter?.id}
                            sx={{ 
                              '&.Mui-selected': {
                                backgroundColor: '#D6ACFC',
                              },
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))
                      }
                      <Divider />
                      { role_options.map((option) => (
                          <MenuItem
                            key={option.id}
                            disableRipple
                            onClick={() => handleSelectRole(option)}
                            selected={option.id === selectedRoleFilter?.id}
                            sx={{ 
                              '&.Mui-selected': {
                                backgroundColor: '#D6ACFC',
                              },
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))
                      }
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Search by Name, Email, Company"
            >
              <FormControl sx={{ m: 1, width: '30ch' }}>
                <FilledInput
                  id='search'
                  placeholder='Search'
                  disableUnderline
                  inputRef={inputRef}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  startAdornment={
                    <InputAdornment
                      position="start"
                    >
                      <SearchIcon
                        sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                        onClick={handleIconClick}
                      />
                    </InputAdornment>
                  }
                  sx={{
                    paddingTop: 0, // Removes extra top/bottom padding
                  }}
                />
              </FormControl>
            </Tooltip>
          </Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                  {/* APPROVED DATE */}
                  <TableCell
                    sx={{
                      fontFamily: 'Libre Franklin',
                      fontWeight: 600,
                    }}
                    >
                      Approved
                    </TableCell>

                  {/* NAME COL */}
                  <TableCell sortDirection={orderBy === 'name' ? order : false}>
                    <TableSortLabel
                      active={orderBy ==='name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={(e) => handleRequestSort(e, 'name')}
                      sx={{
                        fontFamily: 'Libre Franklin',
                        fontWeight: 600,
                        '&.Mui-active': {
                          color: '#8E5CD9', // Color when active (sorted)
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#8E5CD9 !important', // Change arrow color
                        },
                      }}
                    >
                      Name
                      {orderBy === 'name' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  {/* EMAIL COL */}
                  <TableCell sortDirection={orderBy === 'email' ? order : false}>
                    <TableSortLabel
                      active={orderBy ==='email'}
                      direction={orderBy === 'email' ? order : 'asc'}
                      onClick={(e) => handleRequestSort(e, 'email')}
                      sx={{
                        fontFamily: 'Libre Franklin',
                        fontWeight: 600,
                        '&.Mui-active': {
                          color: '#8E5CD9', // Color when active (sorted)
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#8E5CD9 !important', // Change arrow color
                        },
                      }}
                    >
                      Email
                      {orderBy === 'email' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  {/* PHONE NUMBER COL */}
                  {/* <TableCell 
                    sx={{
                      fontFamily: 'Libre Franklin',
                      fontWeight: 600,
                    }}
                    >
                      Phone number
                    </TableCell> */}

                  {/* COMPANY COL */}
                  <TableCell sortDirection={orderBy === 'company' ? order : false}>
                    <TableSortLabel
                      active={orderBy ==='company'}
                      direction={orderBy === 'company' ? order : 'asc'}
                      onClick={(e) => handleRequestSort(e, 'company')}
                      sx={{
                        fontFamily: 'Libre Franklin',
                        fontWeight: 600,
                        '&.Mui-active': {
                          color: '#8E5CD9', // Color when active (sorted)
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#8E5CD9 !important', // Change arrow color
                        },
                      }}
                    >
                      Company
                      {orderBy === 'company' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  {/* STATUS COL */}
                  <TableCell sortDirection={orderBy === 'status' ? order : false}>
                    <TableSortLabel
                      active={orderBy ==='status'}
                      direction={orderBy === 'status' ? order : 'asc'}
                      onClick={(e) => handleRequestSort(e, 'status')}
                      sx={{
                        fontFamily: 'Libre Franklin',
                        fontWeight: 600,
                        '&.Mui-active': {
                          color: '#8E5CD9', // Color when active (sorted)
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#8E5CD9 !important', // Change arrow color
                        },
                      }}
                    >
                      Status
                      {orderBy === 'status' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  {/* ROLE COL */}
                  <TableCell 
                    sx={{
                      fontFamily: 'Libre Franklin',
                      fontWeight: 600,
                    }}
                    >
                      Role
                    </TableCell>

                  {/* ACTION COL */}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{ position: 'relative', height: visibleRows.length === 0 && isLoading ? '100px' : 'auto' }}
              >
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 1000, display: isLoading ? 'flex' : 'none' }}>
                  <CircularProgress />
                </div>
                {visibleRows &&
                  visibleRows.map((user, index) => (
                    <TableRow key={user.id}
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => selectUserClick(user)}
                    >
                      <TableCell>{format_date(user.approved_date) || 'N/A'}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.company}</TableCell>
                      <TableCell>
                        {user.status === 1
                          ? t('management.labelActive')
                          : t('management.labelInactive')}
                      </TableCell>
                      <TableCell sx={{ position: "relative", overflow: "visible" }}>
                        <Button
                          ref={(el) => (anchorRefs.current[index] = el)}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleClick(index)
                          }}
                          style={{ backgroundColor: 'transparent' }}
                        >
                          {get_role(user.role)}
                        </Button>
                        <Popper
                          open={openDropdown === index}
                          anchorEl={anchorRefs.current[index]}
                          placement="bottom"
                        >
                          <ClickAwayListener onClickAway={handleClose}>
                            <Paper sx={{ p: 1 }}>
                              { role_options.map((option) => (
                                  <MenuItem key={option.id} onClick={(e) => {
                                      e.stopPropagation()
                                      handleRoleClick(user.id, option)
                                    }}
                                  >{option.name}</MenuItem>
                                ))
                              }
                            </Paper>
                          </ClickAwayListener>
                        </Popper>
                        {/* <div className='user-table-role-dropdown'>
                          <CustomDropdown
                            options={role_options}
                            selected={get_selected_role(item.role)}
                            onClickHandler={(val) =>
                              handleRoleClick(item.id, val)
                            }
                          />
                        </div> */}
                      </TableCell>

                      <TableCell>
                        <div className='user-table-action'>
                          <div
                            className='user-table-action-btn'
                            onClick={(e) => {
                              e.stopPropagation()
                              handleUserStatus(user)
                            }}
                          >
                            {user.status === 1
                              ? t('management.deActivate')
                              : t('management.activate')}
                          </div>
                          <div
                            className='user-table-action-btn'
                            onClick={(e) => {
                              e.stopPropagation()
                              toggleChangePasswordModal(user)
                            }}
                          >
                            {t('management.changePassword')}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      {/* Change password modal */}
      {showChangePasswordModal && (
        <div className='change-password-modal-root'>
          <div className='change-password-modal-container'>
            <div className='change-password-modal-header'>
              <div className='change-password-modal-header-exit-btn-container'>
                <div
                  className='change-password-modal-header-exit-btn'
                  onClick={() => {
                    toggleChangePasswordModal()
                  }}
                >
                  <Close size={20} />
                </div>
              </div>
            </div>
            <div className='change-password-modal-content'>
              {!requested_success && (
                <div className='change-password-modal-header-title'>
                  {t('management.changePassword')}
                </div>
              )}
              {errorList.length > 0 && (
                <div className='change-password-modal-error-container'>
                  {errorList.map((error) => {
                    return (
                      <div
                        className='change-password-modal-error-entry'
                        key={error}
                      >
                        - {error}
                      </div>
                    )
                  })}
                </div>
              )}
              {requested_success && (
                <div>{t('management.changePasswordSuccessMessage')}</div>
              )}
              {!requested_success && (
                <>
                  <div className='change-password-modal-input-group'>
                    <label className='change-password-modal-input-label'>
                      {t('management.labelNewPassword')}
                    </label>
                    <input
                      type='password'
                      value={newPasswordPayload.password}
                      className='change-password-modal-input-field textarea'
                      onInput={(e) =>
                        updateNewPasswordInput({ password: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className='change-password-modal-input-group'>
                    <label className='change-password-modal-input-label'>
                      {t('management.labelConfirmPassword')}
                    </label>
                    <input
                      type='password'
                      value={newPasswordPayload.confirm_password}
                      className='change-password-modal-input-field textarea'
                      onInput={(e) =>
                        updateNewPasswordInput({
                          confirm_password: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                </>
              )}
            </div>
            <div className='change-password-modal-btn-group'>
              {requested_success ? (
                <CustomButton
                  button_text={t('management.closeBtn')}
                  onClickHandler={() => toggleChangePasswordModal()}
                />
              ) : (
                <>
                  <CustomButton
                    button_text={t('management.confirm').toUpperCase()}
                    primary
                    onClickHandler={() => handleConfirmNewPassword()}
                  />
                  <CustomButton
                    button_text={t('management.cancel').toUpperCase()}
                    onClickHandler={() => toggleChangePasswordModal()}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UserTable
