const login = {
  loginTitle: 'Kirjaudu sisään',
  loginBtn: 'KIRJAUDU',
  labelEmail: 'Sähköpostiosoite',
  labelPassword: 'Salasana',
  forgotPassword: 'Unohdin salasanan',
  newAccountTitle: 'Luo käyttäjätili',
  newAccountText: `Luo käyttäjätunnus ja räätälöi InnoGreenin tuotteet omiin tarpeisiisi. 
      Käyttäjätunnuksen avulla voit suunnitella ja tallentaa tuotteesi kokonaisuuksiksi 
      sekä pyytää tarjouksen yhdellä klikkauksella.`,
  newAccountBtn: 'LUO TILI',
  createUserAccount: 'Luo uusi tili',
  signUpTerms: 'Käsittelemme pyynnöt manuaalisesti ja saat vahvistuksen tilisi luonnista sähköpostiisi. Luomalla tilin, hyväksyt henkilötietojesi käsittelyn ',
  signUpTermsLink: 'tietosuojaselosteemme mukaisesti',
  requestNewAccountBtn: 'LÄHETÄ PYYNTÖ',
  labelConfirmPassword: 'Vahvista salasana',
  labelName: 'Nimi',
  labelCompany: 'Yritys',
  labelRole: 'Rooli',
  labelPhoneNumber: 'Puhelinnumero',
  labelSubscribeEmail: 'Haluan tilata InnoGreenin uutiskirjeen sähköpostiini',
  errorEmailRequired: 'Sähköposti vaaditaan',
  errorPasswordRequired: 'Salasana vaaditaan',
  errorConfirmPasswordRequired: 'Vahvista salasana vaaditaan',
  errorNameRequired: 'Nimi vaaditaan',
  errorCompanyRequired: 'Yritys vaaditaan',
  errorPasswordNotMatch: 'salasana ja vahvista salasana eivät täsmää',
  genericSignUpError: 'Kirjautuminen ei onnistunut, yritä uudelleen!',
  logInCookiesWarning:
    'Kirjautuminen on estetty, koska olet kieltänyt evästeiden käytön.',
  signUpCookiesWarning:
    'Kirjautuminen on estetty, koska olet kieltänyt evästeiden käytön.',
  cookiesChangeLink: 'Muuta evästeasetuksiasi tästä.',
  errorEmailInvalid: 'Sähköpostin muoto on virheellinen',
  errorPasswordLength: 'Salasanan pituuden on oltava vähintään 6 merkkiä',
  forgotPasswordTitle: 'Pyydä salasanan vaihtoa',
  confirmBtn: 'Vahvista',
  cancelBtn: 'Peruuta',
  passwordResetRequestText:
    'Lähetä salasanan palautuspyyntö järjestelmänvalvojalle. Vahvista jatkaaksesi!',
  signUpSuccessMessage:
    'Kiitos rekisteröitymisestä! Tarkistamme tiedot ja ilmoitamme sähköpostilla, kun voit kirjautua palveluumme.',
  closeBtn: 'Close',
  genericLogInError: 'Kirjautuminen epäonnistui, yritä uudelleen!',
  genericPasswordResetError: 'Pyynnön lähettäminen epäonnistui, yritä uudelleen!',
  passwordResetSuccessMessage:
    'Salasanan nollauspyyntö lähetetty! Ilmoitamme sinulle sähköpostitse uudella salasanalla',
}

export default login
