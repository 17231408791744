import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.scss'
import { Provider } from 'react-redux'
import store from './store'
import './assets/styles/index.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import './plugins/i18n'
import { Amplify } from "aws-amplify"; // AWS Amplify sdk


library.add(faArrowUp, faArrowDown)

Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          redirectSignIn: [process.env.REACT_APP_GOOGLE_REDIRECT_SIGNIN],
          redirectSignOut: [process.env.REACT_APP_GOOGLE_REDIRECT_SIGNOUT],
          domain: process.env.REACT_APP_SSO_DOMAIN,
          providers: ["Google"],
          scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
          responseType: "code",
        },
      },
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
)
