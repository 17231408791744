import './styles.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { get_decoded_string } from '../../plugins/helper'

import { setGlobalLoading } from '../../store/uiSlice'
import { selectCustomizedProduct } from '../../store/productSlice'

import { fetch_related_products } from '../../store/asyncThunk'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 1800 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const RelatedProducts = () => {
  const navigate = useNavigate()
  const { slug } = useParams()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  // Fetching related products
  useEffect(() => {
    dispatch(setGlobalLoading(true))
    dispatch(fetch_related_products({slug: slug, small_images:true}))
    dispatch(setGlobalLoading(false))
  }, [slug])

  const related_products = useSelector(
    (state) => state.product.related_products
  )

  const handleProductClick = (product) => {
    dispatch(selectCustomizedProduct({ ...product, customizations: [] }))
    if (i18n.language === 'FI') {
      navigate('/tuotteet/' + product.slug)
    } else if (i18n.language === 'EN') {
      navigate('/products/' + product.en_slug)
    }
  }

  return (
    <>
      <div className='related-products-container'>
        <div className='related-products-body-container'>
          <div className='related-products-title'>{t('common.relatedProducts')}</div>
          <Carousel
            responsive={responsive}
            containerClass='product-group-custom-carousel'
            itemClass='product-group-custom-carousel-entry-container'
          >
            {related_products && related_products.map((product) => {
              if (product.slug === slug) {
                return 
              }
              if (product.en_slug === slug) {
                return 
              }
              let descriptions = [];
              product.customizations.forEach(item => {
                  descriptions.push(t(`products.${get_decoded_string(item.name)}`));

              });
              let description = descriptions.join(' | ');

              return (
                <div
                  className='product-group-custom-carousel-entry'
                  key={product.id}
                  onClick={() => handleProductClick(product)}
                >
                  <div className='product-group-custom-carousel-entry-image-container'>
                    {product.imgSrc ? (
                      <img src={product.imgSrc} alt={`product - ${product.id}`} />
                    ) : null}
                  </div>
                  <div className='product-group-custom-carousel-entry-product-info'>
                    <div className='product-group-custom-carousel-entry-product-name'>
                      {t(`${get_decoded_string(product.name)}.name`)}
                    </div>
                   
                    {description ?
                      (
                        <>
                          <div style={{ fontSize: '0.8125rem', fontFamily: 'DM Mono', fontWeight: '500' }}>{t('common.customize')}</div>
                          <div className='related-product-customization-container'>
                            <div className='product-group-custom-carousel-entry-product-customize-entry'>
                              <p className='product-group-custom-carousel-entry-product-customize-entry-text'>
                                {description}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    }
                    
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </>
  )
}

export default RelatedProducts
