const account = {
  hello: 'Hei',
  updateUserInformation: 'Päivitä tietosi',
  logout: 'Kirjaudu ulos',
  yourProjects: 'Projektisi',
  createNewProject: 'Luo uusi projekti',
  searchProject: 'Etsi projekti',
  projectName: 'Projektin nimi',
  numberOfProducts: 'Tuotteiden määrä',
  dateModified: 'Muokattu pvm.',
  status: 'Tila',
  product: 'Tuote',
  products: 'Tuotteet',
  open: 'Avoin',
  pending: 'Odottaa',
  inquirySent: 'Tarjouspyyntö lähetetty',
  unknown: 'Tuntematon',
  projectName: 'Projektin nimi',
  projectDescription: 'Projektin kuvaus',
  create: 'Luo',
  cancel: 'Peruuta',
  save: 'Päivitä',
  updatedUserInfo: 'Käyttäjätiedot päivitetty!',
  emailList: 'InnoGreenin sähköpostilistalle',
  genericUserUpdateError: 'Failed to update user information, please try again!',
  genericCreateProjectError: 'Failed to create prject, please try again!',
  createProjectSuccessMessage: 'Uusi projekti luotu onnistuneesti!',
  close: 'Peruuta',
  userUpdateSuccessMessage: 'Päivitetty käyttäjätiedot!',
}

export default account