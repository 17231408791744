import './styles.scss'

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Close } from '../../../../../../components/Icons'
import moment from 'moment'

const InquiriesModal = (props) => {
  const {
    inquiry,
    showing,
    closeModal,
  } = props;
  const dispatch = useDispatch()
  const [errorList, setErrorList] = useState([])
  const [savedSuccess, setSavedSuccess] = useState(false)

  const getModifiedDate = (date) => {
    if (date) {
      return moment(date).format('DD.MMM.YYYY')
    }
  }

  const btnStyles = {
    'padding': '0.5rem 1rem'
  }

  const close = () => {
    document.body.style.overflow = 'unset'
    setSavedSuccess(false)
    closeModal()
  }

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const get_title = (type) => {
    switch (type) {
      case 1:
        return 'Contact Request'
      case 2:
        return 'Project Inquiry'
      case 3:
        return 'Product Inquiry'
      case 4:
        return 'Guide Download'
      default:
        return 'Unknown'
    }
  }

  const get_status = (status) => {
    switch (status) {
      case 1:
        return 'Open'
      case 2:
        return 'In Progress'
      case 3:
        return 'Offer Sent'
      case 4:
        return 'Offer Accepted'
      case 5:
        return 'Offer Rejected'
      case 6:
        return 'Guide Sent'
      case 7:
        return 'Closed'
      default:
        return 'Unknown'
    }
  }

  const get_status_color = (status) => {
    switch (status) {
      case 1:
        return 'red'
      case 2:
        return 'yellow'
      case 3:
        return 'yellow'
      case 4:
        return 'green'
      case 5:
        return 'red'
      case 6:
        return 'green'
      case 7:
        return 'green'
      default:
        return 'Unknown'
    }
  }

  return (
    showing && (
      <div className='inquiries-modal-root'>
        <div className='inquiries-modal-container'>
          <div className='inquiries-modal-header'>
          <div className='inquiries-modal-header-title'>{get_title(inquiry.type)}</div>
            <div className='inquiries-modal-header-exit-btn-container'>
              <div
                className='inquiries-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20}/>
              </div>
            </div>
          </div>
            <div className='inquiries-modal-content'>
              <div className='inquiries-modal-content-bar'>
                <div className='inquiries-modal-content-bar-text'>
                  Status:
                  <span className={`inquiries-modal-content-bar-status ${get_status_color(inquiry.state)}`}> {get_status(inquiry.state).toUpperCase()}</span>
                </div>
                <div className='inquiries-modal-content-bar-text'>
                  Assigned:
                  {/* <span className='inquiries-modal-content-bar-assigned'> {inquiry.assigned}</span> */}
                </div>
                <div className='inquiries-modal-content-bar-text'>
                  Deadline:
                  {/* <span className='inquiries-modal-content-bar-deadline'> {getModifiedDate(inquiry.deadline)}</span> */}
                </div>
              </div>
              { inquiry.type === 2 && (
                <div className='inquiries-modal-content-text'>
                  <div className='inquiries-modal-content-bar-text'>
                    Project Type:
                    {/* <span className='inquiries-modal-content-bar-project-type'> {inquiry.project_type}</span> */}
                  </div>
                  <div className='inquiries-modal-content-bar-text'>
                    Project Size:
                    {/* <span className='inquiries-modal-content-bar-project-size'> {inquiry.project_size}</span> */}
                  </div>
                  <div className='inquiries-modal-content-bar-text'>
                    Location:
                    {/* <span className='inquiries-modal-content-bar-location'> {inquiry.location}</span> */}
                  </div>
                  <div className='inquiries-modal-content-bar-text'>
                    Project Size:
                    {/* <span className='inquiries-modal-content-bar-project-size'> {inquiry.project_size}</span> */}
                  </div>
                  <div className='inquiries-modal-content-bar-text'>
                    Location:
                    {/* <span className='inquiries-modal-content-bar-location'> {inquiry.location}</span> */}
                  </div>
                  <div className='inquiries-modal-content-bar-text'>
                    Project Size:
                    {/* <span className='inquiries-modal-content-bar-project-size'> {inquiry.project_size}</span> */}
                  </div>
                  <div className='inquiries-modal-content-bar-text'>
                    Location:
                    {/* <span className='inquiries-modal-content-bar-location'> {inquiry.location}</span> */}
                  </div>
                </div>
                )
              }
                {/* { savedSuccess && 
                  <div className='inquiries-modal-saved'>Saved!</div>
                }
                {errorList.length > 0 && (
                  <div className='inquiries-information-error-container'>
                    {errorList.map((error) => {
                      return (
                        <div className='edit-meta-information-error-entry' key={error}>
                          - {error}
                        </div>
                      )
                    })}
                  </div>
                )} */}
              </div>
            {/* <div className='meta-edit-modal-btn-group'>
              <CustomButton
                button_text='SAVE'
                primary={true}
                // onClickHandler={() => handleSaveBtnClick()}
              />
              <CustomButton transparent={true} button_text='CANCEL' onClickHandler={() => close()} />
            </div> */}
        </div>
      </div>
    )
  )
}

export default InquiriesModal
