const koivu = {
  headerGroup: 'Koivu',
  headerTitle: 'Suomalainen ja harmoninen Koivu on tiedostavan valinta.',
  infoCard1Title: 'Kestävämpi vaihtoehto',
  infoCard1Text1: 'Suuniteltu ja valmistettu Suomessa',
  infoCard1Text2: 'Materiaalina suomalainen koivuviilu',
  infoCard1Text3: 'Sisäruukku 100 % kierrätysmuovia',
  infoCard2Title: 'Monipuolinen',
  infoCard2Text1: 'Sopii monenlaiseen sisustukseen',
  infoCard2Text2: 'Sijoita yksittäin tai asetelmina',
  infoCard2Text3: 'Neljä kaunista värivaihtoehtoa',
  textCard1: `Koivu-tuoteperheen ruukut, tilanjakajat, vihertaulut ja viherseinät on 
      suunniteltu ja valmistettu Suomessa. Ajaton muotoilu tekee Koivu-tuotteista 
      sopivia monenlaiseen tilaan ja tunnelmaan.`,
  infoBox1Title: 'Kepeää kauneutta',
  infoBox1Text: `Koivu edustaa kestävämpää viheralaa ja on tiedostavan sisustajan valinta. 
      Se on suomalaisen puumuotoilun taidonnäyte, joka kestää aikaa ja katseita.
      \\n
      Koivu-tuoteperheestä löydät luonnon rauhaa ja harmoniaa henkivät ruukut, tilanjakajat, 
      vihertaulut ja viherseinät. Ajaton muotoilu tekee Koivu-tuotteista sopivia monenlaiseen 
      tilaan ja tunnelmaan. Laaja tuoteperhe tarjoaa monenlaisia ratkaisuja myös suuriin tiloihin.
      \\n
      Koivuviiluiset tuotteet ovat rakenteeltaan kevyitä, mutta kestäviä, minkä vuoksi niitä on 
      myös tarvittaessa helppo siirrellä. Rytmitä tilaa tilanjakajilla, luo näyttäviä kokonaisuuksia 
      yhdistämällä erikokoisia kasveja ja ruukkuja tai nosta luonto seinälle vihertaulun tai -seinän muodossa.`,
  infoBox2Title: 'Kotimaista edelläkävijyyttä',
  infoBox2Text: `Kaikki lähti liikkeelle Koivu-ruukuista, joiden taustalla oli tahto luoda kestävämpiä 
      vihersisustusratkaisuja. Vaadimme ruukuiltamme muutakin kuin kauniin ulkonäön. Niiden tulee olla 
      turvallisia, vakaita sekä valmistettu ja kuljetettu kestävän kehityksen mukaisesti, ympäristö ja 
      sosioekonominen vastuullisuus huomioiden. Kun arvoihimme sopivaa kumppania ei tuntunut löytyvän, 
      jäi jäljelle vain yksi vaihtoehto: Ruukut täytyisi tehdä itse. Ensimmäiset Koivu-ruukut 
      valmistuivat keväällä 2022.
      \\n
      InnoGreenin Koivu-tuotteet on suunniteltu sekä valmistettu Suomessa suomalaisesta koivuvanerista 
      yhteistyössä paikallisten pienituottajien ja puunkäsittelyn ammattilaisten kanssa. Matka 
      kotimaiselta tehtaalta asiakkaamme tiloihin on varsin lyhyt.`,
  productGroupLink: 'Lue lisää Koivu-tuotteista'
}

export default koivu