const greenWall = {
  headerGroup: 'Gröna väggar',
  headerTitle: 'SE Monipuolisimmat aitojen kasvien viherseinät.',
  infoCard1Title: 'SE Näyttävä',
  infoCard1Text1: 'SE Luontoa, tunnelmaa ja viihtyisyyttä',
  infoCard1Text2: 'SE Runsaasti ja monipuolisesti eri kasvilajeja',
  infoCard1Text3: 'SE Hyvinvointia aidoista kasveista',
  infoCard2Title: 'SE Kestävä',
  infoCard2Text1: 'SE Suunniteltu ja valmistettu Suomessa',
  infoCard2Text2: 'SE Patentoitu teknologia',
  infoCard2Text3: 'SE Pitkäikäiset ja kierrätettävät osat',
  infoCard3Title: 'SE Helppo',
  infoCard3Text1: 'SE Valitse valmis koko tai räätälöi',
  infoCard3Text2: 'SE Turvallinen kasteluteknologia',
  infoCard3Text3: 'SE Asennus ja huolto hyvinvointitakuulla',
  infoBox1Title: 'SE Viherseinällä viihtyisyyttä ja hyvinvointia urbaaniin ympäristöön',
  infoBox1Text: `SE Monipuoliset viherseinämme tuovat runsasta vehreyttä sekä sisä- että 
      ulkotiloihin. Katseen kiinnittävät viherseinämme ovat moderni tapa osoittaa vihreitä arvoja, 
      pitää huolta luonnosta ja luoda viihtyisyyttä sekä hyvinvointia urbaaniin ympäristöön.`,
  infoBox2Text: `SE Sisätiloissa viherseinistämme on moneksi; tilanjakajaksi, kulunohjaajaksi ja tunnelman 
      luojaksi. Viherseinät kohentavat sekä tilaa että mielialaa. Kasvit vaikuttavat tutkitusti 
      hyvinvointiimme muun muassa lievittämällä stressiä ja tukemalla luovuutta. Viherseinä voi 
      olla taulun kokoinen luonnon piristysruiske tai koko seinän mittainen matka viidakkoon.
      \\n
      Ulkotiloissa viherseinien runsas kasvillisuus tukee kaupunkibiodiversiteettiä tarjoten 
      suojaa ja ravintoa hyönteisille, kuten pörriäisille ja perhosille. Viherseinät 
      vaimentavat liikenteen melua ja tarjoavat rauhallisen sekä varjoisan paikan 
      levähtää vehreyden keskellä. Parhaimmillaan koko julkisivun vehreyttävät, hulevettä 
      hyödyntävät viherseinämme viivyttävät hulevettä säästäen näin juomakelpoista kasteluvettä, 
      viemäristöä sekä luontoa.
      \\n
      Huoltamillamme viherseinillä on hyvinvointitakuu. Käytämme viherseinissämme ainoastaan 
      aitoja kasveja, ja siksi toimitamme myös tarvittaessa kasvivalot. Käyttämämme huoltovapaa 
      sammal ja jäkävä ovat stabiloituja, jotka säilyvät kauniin vihreänä jopa vuosia.`,
  infoBox3Title: 'SE Suomailainen innovaatio',
  infoBox3Text: `SE InnoGreenin viherseinät on suunniteltu ja valmistettu Suomessa. Ensimmäinen 
      modulaarinen viherseinämme valmistui jo vuonna 2010. Vuosien saatossa olemme kehittäneet 
      viherseinäämme edelleen sekä tutkineet viherseinän mahdollisuuksia muun muassa mikromuovin 
      suodattamisessa ja hulevesien viivyttämisessä.
      \\n
      Kestävä, modulaarinen viherseinärakenteemme yhdessä ammattitaitoisen huoltomme kanssa on 
      mahdollistanut sen, että pystymme tarjoamaan asiakkaillemme todella monipuolisen ja 
      korkealaatuisen viherseinien valikoiman hyvinvointitakuulla.`,
  infoBox4Text: `SE Viherseinämme kaikki osat ovat pitkäikäisiä ja kierrätettäviä minimoiden 
      näin jätteen määrää. Käyttäessämme samaa modulaarista perusrakennetta kaikissa 
      viherseinissämme pystymme hyödyntämään materiaaleja äärimmäisen tehokkaasti 
      sekä uudelleenkäyttämään käytöstä palautuvien viherseiniemme osat.
      \\n
      Viherseiniemme patentoitu kasteluteknologiamme on markkinoiden energiatehokkain sekä 
      vettä säästävin. Kastelujärjestelmämme on turvallinen tapa huolehtia viherseinän 
      kasvien riittävästä vedensaannista ilman, että pisaraakaan menee hukkaan. Pienimpiä 
      viherseiniämme kastellaan käsin huollon yhteydessä.`,
  wltTitle: 'SE Suojelemme mailmaan sademetsiä',
  wltLink: 'SE World Land Trustin',
  wltText1: `SE Valitsemalla InnoGreenin viherseinän suojelet kansamme maailman sademetsiä yhteistöyssä`,
  wltText2: `SE kanssa. Jokaista myytyä tai vuokrattua kasviseinäneliömetriä kohden suojelemme 1000 neliömetriä sademetsää. 
      Vuoden 2023 loppu menessä olemme suojelleet jo 2 000 000 neliötä sademetsää yhdessä asiakkaidemme kanssa.`,
  productGroupLink: 'Lue lisää viherseinästä'
}

export default greenWall