import './styles.scss'
import { useEffect } from 'react'
import { Close } from '../../Icons'

const CustomModal = (props) => {
  const {
    showing,
    closeModal,
    modal_title,
    show_footer = false,
    children,
    size = 'md',
  } = props

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    }
    else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  return (
    showing && (
      <div className='custom-modal-root'>
        <div className={`custom-modal-container ` + size}>
          <div className='custom-modal-header'>
            {modal_title ? (
              <div className='custom-modal-header-title'>{modal_title}</div>
            ) : null}
            <div
              className='custom-modal-header-exit-btn'
              onClick={() => {
                close()
              }}
            >
              {/* <FontAwesomeIcon icon={faX} /> asd */}
              <Close size={20}/>
            </div>
          </div>
          <div className='custom-modal-content-scrollable'>
            {children}
          </div>
          {show_footer ? <div className='custom-modal-footer'></div> : null}
        </div>
      </div>
    )
  )
}

export default CustomModal
