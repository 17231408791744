import './styles.scss'
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ArrowShortUp, ArrowShortDown, ArrowSimple } from '../../Icons'


const CustomNavigationDropdown = (props) => {
  const { 
    text,
    links,
    type,
    index,
    showMenu,
    setShowMenu,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    dropdownRef,
    menuRef,
    onToggle
  } = props
  const location = useLocation()
  let container_class_name = `custom-navigation-dropdown-container`
  let content_class_name = `custom-navigation-dropdown-content`
  let menuContainerClass = 'custom-navigation-dropdown-menu-container'
  const { t, i18n } = useTranslation()
  let pathname = ''

  if (location) {
    pathname = location.pathname.split('/').slice(0, 2).join('/');
  }

  const closeMenu = () => {
    setShowMenu((prevState) =>
      prevState.map((item, i) => (i === index ? !item : item))
    );
  }

  if (location && location.pathname === '/') {
    menuContainerClass += ' in-home-page'
  }
  if (type === 'services') {
    menuContainerClass = 'custom-navigation-dropdown-menu-container service'
    if (location && location.pathname === '/') {
      menuContainerClass += ' in-home-page'
    }
  }

  const handleDropDownClick = () => {
    setShowMenu((prevState) =>
      prevState.map((item, i) => (i === index ? !item : item))
    );
  }

  return (
    <div className={container_class_name}>
      <div
        className={content_class_name}
        onClick={() => onToggle(index)}
        onMouseEnter={() => onMouseEnter(index)}
        onMouseLeave={(e) => onMouseLeave(index, e, dropdownRef, menuRef)}
        onFocus={() => onFocus(index)}
        onBlur={(e) => onBlur(index, e)}
        ref={dropdownRef}
      >
        <div className='custom-navigation-dropdown-text'>{text}</div>
        <div className='custom-navigation-dropdown-expand-icon'>
          {showMenu ?
            (
              <ArrowShortUp size={14} color='black' />
            ) : (
              <ArrowShortDown size={14} color='black'/>
            )
          }
        </div>
      </div>
      {showMenu ? (
        <div
          className={menuContainerClass}
          onMouseEnter={() => onMouseEnter(index)}
          onMouseLeave={(e) => onMouseLeave(index, e, dropdownRef, menuRef)}
          ref={menuRef} // Set ref for the menu
        >
          {links &&
            links.length > 0 &&
            links.map((option, index) => {
              if (option.type === 'image') {
                //option with image
                return (
                  <Link
                    key={index}
                    className='custom-navigation-dropdown-menu-item with-image'
                    to={i18n.language === 'FI' ? option.href : option.href_en}
                    onClick={closeMenu}
                  >
                    <div className={`custom-navigation-dropdown-menu-item-title hover-underline ${option.href === pathname || option.href_en === pathname ? 'purple' : ''}`}>
                      {t(option.name)}
                    </div>
                    <div className='custom-navigation-dropdown-menu-item-image-wrapper'>
                      <img
                        className='custom-navigation-dropdown-menu-item-image'
                        src={option.imgSrc}
                        alt={`navigation dropdown item - ${option.name}`}
                      />
                    </div>
                  </Link>
                )
              }
              //option with sub links
              return (
                <div key={index} className='custom-navigation-dropdown-menu-item with-sub-link'>
                  {option.links.map((sub_link, index) => {
                    return (
                      <Link
                        key={index}
                        className={`custom-navigation-dropdown-menu-item-sub-link-wrapper ${sub_link.href === pathname || sub_link.href_en === pathname ? 'purple' : ''}`}
                        to={i18n.language === 'FI' ? sub_link.href : sub_link.href_en}
                        onClick={closeMenu}
                      >
                        {t(sub_link.name)}
                        <ArrowSimple size={12} className='custom-navigation-dropdown-menu-item-sub-link-icon' strokeWidth={0.35}/>
                      </Link>
                    )
                  })}
                </div>
              )
            })}
        </div>
      ) : null}
    </div>
  )
}

export default CustomNavigationDropdown
