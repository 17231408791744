const raataloitava_viherseina = {
  name: 'Customisable Green Wall',
  longDescription: `Our customisable green wall is the most versatile plant 
      wall on the market in terms of size, shape and plant selection. Our lush 
      green wall is a high quality, domestic and sustainable choice and can be 
      placed indoors or outdoors.
      \\n
      The structure of a green wall is not fixed, but consists of modules that 
      can be attached to the wall, which allows the green wall to be designed 
      in any shape. The modules can be stacked, placed side by side or set on 
      a frame to form different shapes, such as a tower. However, the most 
      typical green wall we have built is a rectangular one, following the 
      shape of the wall behind it.
      \\n
      We only use real plants in our green walls and typically have several 
      different plant species on one wall. A multi-species wall is spectacular, 
      and successful plant selections will create just the right atmosphere. 
      The wall can be playful, dramatic or even calming.
      \\n
      Indoors, it is also possible to combine moss and stabilised plants on 
      the green wall. For the outdoor green wall, you can choose colourful 
      flowers, fragrant herbs or conifers along with ambient lighting, 
      depending on your wishes and the season. Other elements can also be 
      integrated, such as a logo.
      \\n
      Small green walls can be watered by hand. For large green walls, 
      our patented irrigation system is used.`,
  measurements: [
    {
      type: 'Height of base:',
      value: '400 mm'
    },
    {
      type: 'Size of one module:',
      values: [
        {
          value: 'Width 600 mm'
        },
        {
          value: 'Height 165 mm'
        },
        {
          value: 'Depth 200 mm'
        }
      ]
    },
    {
      type: 'Amount of plants:',
      value: 'Around 40 plants per square metre of green wall'
    },
  ],
  materials: [
    {
      type: 'Edge coverings:',
      value: 'Wood, steel or other similar material according to agreement'
    },
    {
      type: 'Green wall modules:',
      value: 'Abs plastic, made in Kaarina'
    },
    {
      type: 'Plants:',
      value: 'Finland and EU'
    },
    {
      type: 'Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'Plant lights:',
      value: `We use the latest technology LED lights. 
          The spectrum of the bulb matches the spectrum of daylight, 
          highlighting the plants while giving them just the right 
          amount of light they need.`
    },
    {
      type: 'Usage:',
      value: 'Indoor and outdoor',
      icon: 'both'
    },
  ],
  wlt: {
    title: 'This green wall protects 1000 square metres of rainforest',
    text: `By choosing an InnoGreen green wall, you are joining us in 
      protecting the world's rainforests in partnership with the World 
      Land Trust. For every square metre of green wall sold or rented, 
      we protect 1000 square metres of rainforest. `
  },
  configuration: [
    {
      type: 'räätälöitävä_viherseinä',
      configType:'width',
      text: 'Number of plant modules across the width',
      valueText: 'Width',
      value: 600,
    },
    {
      type: 'räätälöitävä_viherseinä',
      configType:'height',
      showExtra: true,
      text: 'Number of plant modules across the height',
      valueText: 'Height',
      value: 165,
    }
  ],
  leveys: {
    description: `The width is determined by the number of plant modules to be selected next to each other, with a width of 600 mm.`
  },
  korkeus: {
    description: `The height is determined by the number of plant modules to be chosen on top of each other, with a height of 165 mm. (Height of the lower part/base part 400 mm)`
  },
}

export default raataloitava_viherseina