import './styles.scss'
import { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import { useTranslation } from 'react-i18next'
import { add_inquiry } from '../../store/asyncThunk'
import { useDispatch } from 'react-redux'
import LoadingSpinner from '../common/LoadingSpinner'
import { useSelector } from 'react-redux'

const inquiryInitialState = {
  name: '',
  email: '',
  phone_number: '',
  company: '',
  city: '',
  message: '',
  type: 'CONTACT'
}

const ContactInquiry = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [newInquiry, setNewInquiry] = useState(inquiryInitialState)
  const [inquirySent, setinquirySent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorList, setErrorList] = useState([])
  const language = useSelector((state) => state.user.language)

  const updateNewInquiry = ({
    name,
    email,
    phone_number,
    company,
    city,
    message,
  }) => {
    setErrorList([])
    if (name !== undefined) {
      setNewInquiry({ ...newInquiry, name: name })
    }
    if (email !== undefined) {
      setNewInquiry({ ...newInquiry, email: email })
    }
    if (phone_number !== undefined) {
      setNewInquiry({ ...newInquiry, phone_number: phone_number })
    }
    if (city !== undefined) {
      setNewInquiry({ ...newInquiry, city: city })
    }
    if (company !== undefined) {
      setNewInquiry({ ...newInquiry, company: company })
    }
    if (message !== undefined) {
      setNewInquiry({ ...newInquiry, message: message })
    }
  }

  const handleSendBtnClick = async () => {
    setErrorList([])
    setIsLoading(true)
    // check email input
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newInquiry.email)) {
      setErrorList([t('error.emailInvalid')])
      setIsLoading(false)
      return
    }
    await dispatch(add_inquiry({...newInquiry, language: language}))
      .then((res) => {
        if (res.error) {
          setErrorList([t('contact.genericContactRequestError')])
        }
        if (res.payload.success) {
          setinquirySent(true)
          setNewInquiry(inquiryInitialState)
        }
      })
      .catch((error) => {
        console.error("inquiry Error:", error)
      })
    setIsLoading(false)
  }

  return (
    <>
      { inquirySent ?
        (
          <div className='contact-inquiry-container'>
            <div className='contact-inquiry-title small'>{t('contact.inquirySentSuccess')}</div>
          </div>
        ) : (
          <div className='contact-inquiry-container'>
            { isLoading && (
              <LoadingSpinner
                border
              />
             )
            }
            <div className='contact-inquiry-title'>{t('contact.inquiryTitle')}</div>
            {errorList.length > 0 && (
              <div className='contact-inquiry-error-container'>
                {errorList.map((error) => {
                  return (
                    <div className='contact-inquiry-error-entry' key={error}>
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='contact-inquiry-input-container'>
              <label htmlFor="name">{t('contact.name')}*</label>
              <input
                type="text"
                name="name"
                id="name"
                value={newInquiry.name}
                onInput={(e) => updateNewInquiry({ name: e.target.value })}
              />
            </div>
            <div className='contact-inquiry-input-container'>
              <label htmlFor="email">{t('contact.email')}*</label>
              <input
                type="text"
                name="email"
                id="email"
                value={newInquiry.email}
                onInput={(e) => updateNewInquiry({ email: e.target.value })}
              />
            </div>
            <div className='contact-inquiry-input-container'>
              <label htmlFor="phone_number">{t('contact.phone')}</label>
              <input
                type="text"
                name="phone_number"
                id="phone_number"
                value={newInquiry.phone_number}
                onInput={(e) => updateNewInquiry({ phone_number: e.target.value })}
              />
            </div>
            <div className='contact-inquiry-input-container'>
              <label htmlFor="company">{t('contact.company')}*</label>
              <input
                type="text"
                name="company"
                id="company"
                value={newInquiry.company}
                onInput={(e) => updateNewInquiry({ company: e.target.value })}
              />
            </div>
            <div className='contact-inquiry-input-container'>
              <label htmlFor="city">{t('contact.city')}*</label>
              <input
                type="text"
                name="city"
                id="city"
                value={newInquiry.city}
                onInput={(e) => updateNewInquiry({ city: e.target.value })}
              />
            </div>
            <div className='contact-inquiry-input-container'>
              <label htmlFor="message">{t('contact.message')}*</label>
              <textarea
                type='text'
                name="message"
                id="message"
                value={newInquiry.message}
                onInput={(e) =>updateNewInquiry({ message: e.target.value })}
              ></textarea>
            </div>
    
            <CustomButton
              yellow
              button_text={t('contact.sendBtn')}
              onClickHandler={() => handleSendBtnClick()}
              contentClass='send-btn'
              disabled={
                newInquiry.email.trim().length === 0 ||
                newInquiry.city.trim().length === 0 ||
                newInquiry.message.trim().length === 0
              }
            />
          </div>
        )

      }
    </>
  )

}


export default ContactInquiry
