import './styles.scss'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatches } from 'react-router-dom'

import HeaderBox from '../../components/HeaderBox'
import InfoBox from '../../components/InfoBox'
import ImageCard from '../../components/ImageCard'

import headerImgSrc from '../../assets/images/CustomSolutions/raataloidut-ratkaisut-1.jpg'
import infoBox1ImgSrc from '../../assets/images/CustomSolutions/raataloidut-ratkaisut-2.jpg'
import imageCard1ImgSrc from '../../assets/images/CustomSolutions/raataloidut-ratkaisut-3.jpg'
import infoBox2ImgSrc from '../../assets/images/CustomSolutions/raataloidut-ratkaisut-4.jpg'
import imageCard2ImgSrc from '../../assets/images/CustomSolutions/raataloidut-ratkaisut-5.jpg'


const infoBoxText = [
  'customSolution.infoBoxBullet1',
  'customSolution.infoBoxBullet2',
  'customSolution.infoBoxBullet3',
  'customSolution.infoBoxBullet4',
  'customSolution.infoBoxBullet5',
  'customSolution.infoBoxBullet6',
]

const CustomSolution = () => {
  const { t, i18n } = useTranslation()

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='custom-solution-container'>
        <div className='custom-solution-body-container'>
          <HeaderBox
            titleText={t('customSolution.headerTitle')}
            pageText={t('customSolution.headerGroup').toUpperCase()}
            imgSrc={headerImgSrc}
            titleBackgroundColor='purple'
          />

          <div className='custom-solution-text-card'>{t('customSolution.textCard1')}</div>

          <InfoBox
            title={t('customSolution.infoBoxTitle2')}
            text={t('customSolution.infoBoxText2')}
            imgSrc={infoBox1ImgSrc}
            titleSize='sm'
          />

          <ImageCard imgSrc={imageCard1ImgSrc}/>

          <InfoBox
            title={t('customSolution.infoBoxTitle3')}
            text={infoBoxText}
            imgSrc={infoBox2ImgSrc}
            reverse={true}
            customLastBullet={true}
          />

          <ImageCard imgSrc={imageCard2ImgSrc}/>

          <InfoBox
            title={t('customSolution.infoBoxTitle1')}
            text={t('customSolution.infoBoxText1')}
            buttonText={t('customSolution.infoBoxButtonText1').toUpperCase()}
            link='https://ruukut.innogreen.fi/Ruukkukuvasto/'
            type='2'
            reversedMobile={true}
          />

        </div>
      </div>
    </>
  )
}

export default CustomSolution
