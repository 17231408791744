import './styles.scss'

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import InquiryStateHistory from './InquiryStateHistory'
import InquiryAssignedUser from './InquiryAssignedUser'
import InquiryNotes from './InquiryNotes'
import InquiryProjectSummary from './inquiryProjectSummary'
import InquiryProjectEdit from './InquiryProjectEdit'
import LoadingSpinner from '../../../../components/common/LoadingSpinner'
import { get_decoded_string } from '../../../../plugins/helper'
import { Download } from '../../../../components/Icons'

import {
  Paper,
  Button,
  Popper,
  MenuItem,
  ClickAwayListener,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material"
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';

import { 
  updateCount,
  setSelectedInquiry,
} from '../../../../store/managementSlice'

import {
  mark_inquiry_as_read,
  update_inquiry,
  fetch_inquiry,
  fetch_product_customizations,
  fetch_project,
  fetch_product_material,
  count_inquiry,
} from '../../../../store/asyncThunk'

const guideDropDown = [
  {
    id: 1,
    name: 'Open',
  },
  {
    id: 6,
    name: 'Guide Sent',
  },
]

const dropdownOptions = [
  {
    id: 1,
    name: 'Open',
  },
  {
    id: 2,
    name: 'In Progress',
  },
  {
    id: 3,
    name: 'Offer Sent',
  },
  {
    id: 4,
    name: 'Offer Accepted',
  },
  {
    id: 5,
    name: 'Offer Rejected',
  },
  {
    id: 7,
    name: 'Closed',
  },
]

const Inquiry = (props) => {
  const {
    assignableUsers,
    handleSetSelectedTab
  } = props
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const inquiry_id = searchParams.get('inquiry');
  const inquiry = useSelector((state) => state.management.selected_inquiry)
  const count = useSelector((state) => state.management.management_count)
  const anchorRef = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)
  const dateInputRef = useRef(null)
  const [projectView, setProjectView] = useState('summary')
  const [isLoading, setIsLoading] = useState(false)
  const [materials, setMaterials] = useState([])
  const [measurements, setMeasurements] = useState([])
  const language = useSelector((state) => state.user.language)
  const current_user = useSelector((state) => state.user.current_user)

  const [errorList, setErrorList] = useState([])
  const { t, i18n } = useTranslation()
 
  useEffect(() => {
    if (inquiry_id) {
      (async () => {
        setIsLoading(true)
          await Promise.all([
            
            dispatch(fetch_inquiry(parseInt(inquiry_id)))
              .then((res) => {
                if (!res.error) {
                  dispatch(setSelectedInquiry(res.payload))
                }
              }),
          ])
        setIsLoading(false)
      })()
    }
  }, [inquiry_id])

  useEffect(() => {
    if (inquiry && inquiry.type === 3 && inquiry.product_id) {
      setMeasurements(i18n.t(
        `${get_decoded_string(inquiry.product.name)}.measurements`, { returnObjects: true }
      ))
      setMaterials(i18n.t(
        `${get_decoded_string(inquiry.product.name)}.materials`, { returnObjects: true }
      ))
    }
  }, [inquiry])

  const handleDateClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.showPicker()
    }
  }

  const handleClick = () => {
    setOpenDropdown(true) // Toggle dropdown visibility
  }

  const handleClose = () => {
    setOpenDropdown(false) // Close the dropdown
  }

  useEffect(() => {
    // Update inquiry to mark as read
    if (inquiry && inquiry.is_new) {
      (async () => {
        await dispatch(mark_inquiry_as_read({ inquiry_id: inquiry.id, payload: { is_new: false } }))
              .then((res) => {
                if (res.error) {
                  console.log('Error marking inquiry as read')
                  return
                }
                dispatch(updateCount({
                  type: null,
                  old_type: 'isNew',
                }))
              })
      })()
    }
  }, [inquiry])


  const update_date = async (item, event) => {
    const payload = {
      deadline: event.target.value,
      page: 'inquiry'
    }

    await dispatch(update_inquiry({inquiry_id: item.id, payload: payload}))
  }

  const update_state = async (item, option) => {
    const payload = {
      state: option.id,
      page: 'inquiry'
    }

    setOpenDropdown(false)
    await dispatch(update_inquiry({inquiry_id: item.id, payload: payload}))
  }

  const get_title = (type) => {
    switch (type) {
      case 1:
        return 'Contact Request'
      case 2:
        return 'Project Inquiry'
      case 3:
        return 'Product Inquiry'
      case 4:
        return 'Guide Download'
      default:
        return 'Unknown'
    }
  }

  const get_status = (status) => {
    switch (status) {
      case 1:
        return 'Open'
      case 2:
        return 'In Progress'
      case 3:
        return 'Offer Sent'
      case 4:
        return 'Offer Accepted'
      case 5:
        return 'Offer Rejected'
      case 6:
        return 'Guide Sent'
      case 7:
        return 'Closed'
      default:
        return 'Unknown'
    }
  }

  const get_status_color = (status) => {
    switch (status) {
      case 1:
        return 'red'
      case 2:
        return 'yellow'
      case 3:
        return 'yellow'
      case 4:
        return 'green'
      case 5:
        return 'red'
      case 6:
        return 'green'
      case 7:
        return 'green'
      default:
        return 'Unknown'
    }
  }

  const format_date = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    }
  }

  const format_deadline_date_input = (date) => {
    if (date) {
      return moment(date).format('YYYY-MM-DD')
    }
  }
  const format_deadline = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY')
    }
  }

  const get_primary_assigned_user = () => {
    if (inquiry.assigned_users) {
      const primary_user = inquiry.assigned_users.find((user) => user.role === 'Primary')
      if (primary_user) {
        return primary_user.name
      }
    }
  }

  const get_product_material_by_language = () => {
    if (inquiry.product) {
      if (inquiry.product.product_materials) {
        if (language === 'FI') {
          const product_materials = inquiry.product.product_materials.filter(item => item.text_fi.includes("(FI)")).map(({ text_fi, ...rest }) => ({ ...rest, text: text_fi }))
          return product_materials
        } else if (language === 'EN') {
          const product_materials = inquiry.product.product_materials.filter(item => item.text_en.includes("(EN)")).map(({ text_en, ...rest }) => ({ ...rest, text: text_en }))
          return product_materials
        }
      }
    }
  }

  const fetchProductMaterial = async (material) => {
    dispatch(fetch_product_material({ material_id: material.id }))
      .then((res) => {
        if (res.error) {
          throw new Error(res.error.message)
        }
        const blob = res.payload

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url;
        a.download = material.file_name || "download.pdf"
        document.body.appendChild(a);
        a.click();
  
        // Cleanup
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Download failed:", error)
      })
  }

  const isOverdue = (row) => {
    if ([4, 6, 7].includes(row.state)) {
      return false
    }
    moment.locale('fi')
    return moment().isAfter(row.deadline)
  }

  const handleSwitch = () => {
    setProjectView(projectView === 'edit' ? 'summary' : 'edit')
  }

  return (
    <>
      { inquiry && (
          <div className='inquiry-container'>
            <div className='inquiry-header'>
              {get_title(inquiry.type)}
            </div>
            <div className='inquiry-bar'>
              <div className='inquiry-bar-text'>
                Status:
                <Button
                    ref={anchorRef}
                  disableRipple
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClick()
                  }}
                  sx={{ textAlign: 'left', padding: '0px', marginLeft: '5px' }}
                  className={`inquiry-bar-status ${get_status_color(inquiry.state)}`}
                >
                  {get_status(inquiry.state)}
                </Button>
                <Popper
                  open={openDropdown}
                  anchorEl={anchorRef.current}
                  placement="bottom"
                >
                  <ClickAwayListener
                    onClickAway={handleClose}
                  >
                    <Paper sx={{ p: 1 }}>
                      { inquiry.type === 4 ?
                        (
                          guideDropDown.map((option) => (
                            <MenuItem
                              key={option.id}
                              onClick={(e) => {
                                e.stopPropagation()
                                update_state(inquiry, option)
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ))
                        ) : (
                          dropdownOptions.map((option) => (
                            <MenuItem
                              key={option.id}
                              onClick={(e) => {
                                e.stopPropagation()
                                update_state(inquiry, option)
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ))
                        )
                      }
                    </Paper>
                  </ClickAwayListener>
                </Popper>
      
              </div>
              <div className='inquiry-bar-text'>
                Assigned: <span className='inquiry-bar-assigned'> {get_primary_assigned_user() || 'N/A'}</span>
              </div>
              <div 
                className='inquiry-bar-text deadline'
                onClick={handleDateClick}
              >
                Deadline: 
                <input
                  ref={dateInputRef}
                  className={`inquiry-bar-date-input ${isOverdue(inquiry) ? 'red' : 'green'}`}
                  type="date"
                  value={format_deadline_date_input(inquiry.deadline)}
                  onChange={(e) => update_date(inquiry, e)}
                />
                <span className={`inquiry-bar-date ${isOverdue(inquiry) ? 'red' : 'green'}`}>{format_deadline(inquiry.deadline)}</span>
              </div>
            </div>
            <div className='inquiry-content'>
              <div className='inquiry-content-left'>
                <div className='inquiry-content-left-text-wrapper'>
                  <div className='inquiry-content-left-text-item'>
                    <div className='inquiry-content-left-text-label'>Date received:</div>
                    <div className='inquiry-content-left-text'>
                      { format_date(inquiry.date_created) || 'N/A'}
                    </div>
                  </div>
                  <div className='inquiry-content-left-text-item'>
                    <div className='inquiry-content-left-text-label'>Priority:</div>
                    <div className='inquiry-content-left-text'>
                      {inquiry.priority || 'N/A'}
                    </div>
                  </div>
                </div>
                <div className='inquiry-content-left-text-wrapper'>
                  <div className='inquiry-content-left-text-item'>
                    <div className='inquiry-content-left-text-label'>Name:</div>
                    <div className='inquiry-content-left-text'>
                      {inquiry.name || 'N/A'}
                    </div>
                  </div>
                  <div className='inquiry-content-left-text-item'>
                    <div className='inquiry-content-left-text-label'>Email:</div>
                    <div className='inquiry-content-left-text'>
                      {inquiry.email || 'N/A'}
                    </div>
                  </div>
                </div>
                <div className='inquiry-content-left-text-wrapper'>
                  <div className='inquiry-content-left-text-item'>
                    <div className='inquiry-content-left-text-label'>Phone:</div>
                    <div className='inquiry-content-left-text'>
                      {inquiry.phone || 'N/A'}
                    </div>
                  </div>
                  <div className='inquiry-content-left-text-item'>
                    <div className='inquiry-content-left-text-label'>Company:</div>
                    <div className='inquiry-content-left-text'>
                      {inquiry.company || 'N/A'}
                    </div>
                  </div>
                </div>
                <div className='inquiry-content-left-text-wrapper'>
                  <div className='inquiry-content-left-text-item'>
                    <div className='inquiry-content-left-text-label'>City:</div>
                    <div className='inquiry-content-left-text'>
                      {inquiry.city || 'N/A'}
                    </div>
                  </div>
                </div>
                <div className='inquiry-content-left-text-item'>
                  <div className='inquiry-content-left-text-label'>Message:</div>
                  <div className='inquiry-content-left-text'>
                    {inquiry.message || 'N/A'}
                  </div>
                </div>
              </div>
      
              <div className='inquiry-content-right'>
                <InquiryAssignedUser 
                  assignableUsers={assignableUsers}
                />
                <InquiryStateHistory />
              </div>
      
            </div>
            <InquiryNotes />
      
            {inquiry.type === 2 && (
              <>
                { isLoading ?
                  (
                    <div className='inquiry-content column background no-gap mb-2'>
                      <LoadingSpinner noBackground/>
                    </div>
                  ) : (
                    <div className='inquiry-content column background no-gap mb-2'>
                      { [1, 3].includes(current_user.role) && (
                        <Box
                          sx={{position: 'absolute', right: '24px', top: '20px'}}
                        >
                          <Tooltip
                            title={projectView === 'edit' ? 'Switch to Project Summary' : 'Switch to Project Edit'}
                          >
                            <IconButton
                                aria-label={projectView === 'edit' ? 'Switch to Project Summary' : 'Switch to Project Edit'}
                                disableRipple
                                sx={{ padding: 0 }}
                                onClick={() => handleSwitch()}
                              >
                                <RepeatOutlinedIcon
                                  style={{ fontSize: '25px', color: 'black' }}
                                />
                              </IconButton>
                            
                          </Tooltip>
                        </Box>
                      )}
                      <InquiryProjectEdit current_project={inquiry.project} projectView={projectView} />
                      <InquiryProjectSummary project_id={inquiry.project_id} current_project={inquiry.project} projectView={projectView}/>
                    </div>
                  )
                }
              </>
            )}

            {inquiry.type === 3 && inquiry.product_id && (
              <>
                { isLoading ?
                  (
                    <div className='inquiry-content column background no-gap mb-2'>
                      <LoadingSpinner noBackground/>
                    </div>
                  ) : (
                    <div className='inquiry-content background column no-gap mb-2'>
                      <div>
                        <div className='inquiry-content-header'>
                          {t(`${get_decoded_string(inquiry.product.name)}.name`)}
                        </div>
                      </div>
                      <div className='inquiry-content-product'>
                        <div className='inquiry-content-product-left'>

                          <div className='product-page-measurements'>
                            <div className='product-page-measurements-title'>{t('common.measurements')}</div>
                            <div className='product-page-measurements-container'>
                              { Array.isArray(measurements) ? measurements.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div className='product-page-measurements-item'>
                                        <div className={'product-page-measurements-item-type' + `${item?.value === '' ? ' full-width' : ''}`}>{item.type}</div>
                                        { Array.isArray(item.values) ? 
                                          (
                                            <div className='product-page-measurements-item-value-container'>
                                              { item?.bullet ? 
                                                (
                                                  <>
                                                    {item.values.map((val, index) => {
                                                      return (
                                                        <div className='product-page-measurements-item-value-bullet-container' key={index}>
                                                          <div className='product-page-measurements-item-value-bullet'></div>
                                                          <div className='product-page-measurements-item-value-array' >{val.value}</div>
                                                        </div>
                                                      )
                                                    })}
                                                  </>
                                                ) : (
                                                  <>
                                                    {item.values.map((val, index) => {
                                                      return (
                                                      
                                                          <div className='product-page-measurements-item-value-array' key={index} >{val.value}</div>
                                                        
                                                      )
                                                    })}
                                                  </>
                                                ) 
                                              }
                                            </div>
                                          ) : ( item?.value === '' ? 
                                            (
                                              <></>
                                            ) : (
                                              <div className='product-page-measurements-item-value'>{item.value}</div>
                                            )
                                            
                                          )
                                        }
                                      </div>
                                      {index !== measurements.length - 1 ?
                                        (
                                          <div className='product-page-measurements-item-divider'></div>
                                        ) : (
                                          <></>
                                        )
                                      }
                                    </React.Fragment>
                                  )
                                }) : <></>
                              }
                            </div>
                          </div>
                        </div>
                        <div className='inquiry-content-product-right'>
                          <div className='product-page-material-container'>
                              { inquiry.product && inquiry.product.product_materials.length > 0 ?
                                (
                                  <div className='product-page-material-card'>
                                    <div className='product-page-material-title'>{t('common.downloadMaterials')}</div>
                                    <div className='product-page-material-entries'>
                                    {get_product_material_by_language().map((material) => (
                                      <div
                                        key={material.id} 
                                        className='product-page-material-entry'
                                        onClick={() => fetchProductMaterial(material)}
                                      >
                                        <Download size={14}/>
                                        {material.text}
                                      </div>
                                    ))}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )
                              }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </>
            )}
          </div>
        )
      }
    </>
  )
}

export default Inquiry