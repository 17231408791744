const koivu_viherseina = {
  name: 'Koivu Green Wall',
  longDescription: `The robust Koivu green wall can be placed on the wall as an eye-catcher, 
      or alternatively it can be used as a flexible double-sided room divider that can be 
      moved effortlessly from one space to another. Koivu is sturdy and stable to move, 
      but fresh and modern in its design and nature-inspired look.
      \\n
      The Koivu green wall contains up to 48 real plants and is safely irrigated with a 
      sub-irrigation system. On the other side of the green wall, it is possible to have a 
      green moss or lichen wall. Real plants need light to thrive and we also supply suitable 
      plant lights for low-light areas.`,
  measurements: [
    {
      type: 'Room divider:',
      values: [
        {
          value: 'Width 1255 mm'
        },
        {
          value: 'Height 1432 mm'
        },
        {
          value: 'Depth 540 mm (depth of the frame 350 mm)'
        }
      ]
    },
    {
      type: 'Wall mounted:',
      values: [
        {
          value:'Width 1255 mm'
        },
        {
          value:'Height 1255 mm'
        },
        {
          value:'Depth 250 mm'
        }
      ]
    },
  ],
  materials: [
    {
      type: 'Frame:',
      value: 'Finnish birch plywood'
    },
    {
      type: 'Green wall modules:',
      value: 'Abs plastic, made in Kaarina'
    },
    {
      type: 'Plants:',
      value: 'Finland and EU'
    },
    {
      type: 'Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'Backside of space divider model:',
      value: 'Maintenance-free stabilized moss or lichen (EU)'
    },
    {
      type: 'Usage:',
      value: 'Indoor',
      icon: 'indoor'
    },
  ],
  wlt: {
    title: 'This green wall protects 1000 square metres of rainforest',
    text: `By choosing an InnoGreen green wall, you are joining us in 
      protecting the world's rainforests in partnership with the World 
      Land Trust. For every square metre of green wall sold or rented, 
      we protect 1000 square metres of rainforest. `
  },
  malli: {
    description: `Choose a model`
  },
  kehyksen_vari: {
    description: `Colour of the wood finish`
  },
  taustamateriaali: {
    description: `Choose material for the backside of the wall`
  },
}

export default koivu_viherseina