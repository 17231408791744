import './styles.scss'

const ImageCard = (props) => {
  const { imgSrc, alt } = props

  return (
    <>
      <div className='image-card-container'>
        <img src={imgSrc} alt={alt} />
      </div>
    </>
  )
}


export default ImageCard
