const home = {
  videoText: 'Green is Good.',
  infoBoxTitle1: 'Lisää luontoa!',
  infoBoxText1: `InnoGreenin tuotteilla luot helposti vehreitä tiloja, jotka tukevat 
      viihtyisyyttä, yhteisöllisyyttä ja kaupunkiluontoa. Valikoimastamme löydät monipuoliset 
      ja kotimaiset ruukut, tilanjakajat, viherseinät, -taulut ja -kalusteet. Aloita 
      kokonaisuuden suunnittelu työkalumme avulla!`,
  infoBoxTitle2: 'Monipuolista vihreyttä helposti vuokraamalla',
  infoBoxText2: `Haluamme tehdä viihtyisyydestä mahdollisimman helppoa ja kestävää. 
      Vihreys palveluna sisältää kaiken mitä tarvitaan vehreään tilaan: suunnittelun, 
      tuotteet, kasvit, asennuksen ja ylläpidon.`,
  referenceTitle: 'Referenssit',
  infoBoxTitle3: 'InnoGreen ja vastuullisuus',
  infoBoxText3: `Missionamme on kehittää viherratkaisuja, jotka parantavat elämänlaatua 
      kaupunkiympäristössä. Siksi kestävän kehityksen mukaiset periaatteet ja luontoa 
      sekä inhimillisyyttä korostavat arvot ovat toimintamme lähtökohta.`,
  infoBoxTitle4: 'Persoonallisia ratkaisuja vaativiin kohteisiin.',
  infoBoxText4: `Vuosien saatossa olemme toteuttaneet satoja upean uniikkeja kohteita 
      ja tartumme mielellämme kaikenlaisiin luovuutta vaativiin haasteisiin. 
      Jokaisen erikoiskohteen vihertoteutus alkaa vihersuunnitelmasta.`,
  infoBoxTitle5: 'Voisimmeko olla avuksi?',
  infoBoxText5: `Meiltä saat asiantuntevaa apua viherratkaisujen suunnitteluun ja toteutukseen.`,
}

export default home