import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import {
  create_project,
  create_space,
  fetch_projects,
  fetch_project,
  edit_project,
  delete_project,
  add_product_to_space,
  remove_product_from_project,
  edit_assigned_product,
  edit_space,
  delete_space,
  edit_assigned_product_customization,
  add_inquiry,
  duplicate_project
} from './asyncThunk'

export const projectSlice = createSlice({
  name: 'project',
  initialState: {
    current_project: null,
    spaces: [],
    projects: [],
  },

  reducers: {
    selectCurrentProject: (state, action) => {
      state.current_project = state.projects.find(
        (p) => p.id === action.payload
      )
    },
    addProductToProject: (state, action) => {
      const { product, space, project_id, is_current_project } = action.payload

      const project_index = state.projects.findIndex((p) => p.id === project_id)
      if (project_index > -1) {
        const space_index = state.projects[project_index].spaces.findIndex(
          (s) => s.id === space.id
        )
        if (space_index > -1) {
          //check if product already exists
          const assigned_index = state.projects[project_index].spaces[
            space_index
          ].assigned_products.findIndex((p) => p.id === product.id)

          let assigned_products = [
            ...state.projects[project_index].spaces[space_index]
              .assigned_products,
          ]
          if (assigned_index > -1) {
            assigned_products.splice(assigned_index, 1, {
              ...assigned_products[assigned_index],
              quantity: assigned_products[assigned_index].quantity + 1,
            })
          } else {
            assigned_products.push({
              id:
                product.id ?? state.projects[project_index].products.length + 1,
              product_id: product.product_id,
              quantity: 1,
            })
          }

          state.projects[project_index].spaces.splice(space_index, 1, {
            ...space,
            assigned_products: [...assigned_products],
          })
        }

        const product_index = state.projects[project_index].products.findIndex(
          (p) => p.id === product.id
        )

        if (product_index === -1) {
          state.projects.splice(project_index, 1, {
            ...state.projects[project_index],
            spaces: [...state.projects[project_index].spaces],
            products: [
              ...state.projects[project_index].products,
              {
                id:
                  product.id ??
                  state.projects[project_index].products.length + 1,
                product_id: product.product_id,
                name: product.name,
                createdAt: moment().format('YYYY-DD-MM'),
                imgSrc: product.imgSrc,
                product_groups: product.product_groups,
                customizations: product.customizations,
              },
            ],
          })
        } else {
        }

        if (is_current_project) {
          state.current_project = state.projects[project_index]
        }
      }
    },
    createNewProject: (state, action) => {
      state.projects.push(action.payload)
    },

    createNewSpace: (state, action) => {
      const { space, project_id } = action.payload
      const index = state.projects.findIndex((p) => p.id === project_id)
      if (index > -1) {
        state.projects.splice(index, 1, {
          ...state.projects[index],
          spaces: [...state.projects[index].spaces, space],
        })
      }

      //Also update current project if its the same
      if (state.current_project && state.current_project.id === project_id) {
        const current_project_space_index =
          state.current_project.spaces.findIndex((s) => s.id === space.id)
        if (current_project_space_index === -1) {
          state.current_project.spaces.push(space)
        }
      }
    },

    editSpace: (state, action) => {
      const { space, project_id } = action.payload
      const index = state.projects.findIndex((p) => p.id === project_id)
      if (index > -1) {
        const space_index = state.projects[index].spaces.findIndex(
          (s) => s.id === space.id
        )
        if (space_index > -1) {
          state.projects[index].spaces.splice(space_index, 1, space)
        }
      }

      //Also update current project if its the same
      if (state.current_project && state.current_project.id === project_id) {
        const current_project_space_index =
          state.current_project.spaces.findIndex((s) => s.id === space.id)
        if (current_project_space_index > -1) {
          state.current_project.spaces.splice(
            current_project_space_index,
            1,
            space
          )
        }
      }
    },
    deleteSpace: (state, action) => {
      const { space, project_id } = action.payload
      const index = state.projects.findIndex((p) => p.id === project_id)
      if (index > -1) {
        const space_index = state.projects[index].spaces.findIndex(
          (s) => s.id === space.id
        )
        if (space_index > -1) {
          state.projects[index].spaces.splice(space_index, 1)
        }
      }

      //Also update current project if its the same
      if (state.current_project && state.current_project.id === project_id) {
        const current_project_space_index =
          state.current_project.spaces.findIndex((s) => s.id === space.id)
        if (current_project_space_index > -1) {
          state.current_project.spaces.splice(current_project_space_index, 1)
        }
      }
    },
    updateProductQuantity: (state, action) => {
      const { product, space_id, value } = action.payload

      let updated_current_project = { ...state.current_project }

      const space_index = updated_current_project.spaces.findIndex(
        (s) => s.id === space_id
      )

      if (space_index > -1) {
        const product_index = updated_current_project.spaces[
          space_index
        ].assigned_products.findIndex((p) => p.id === product.id)

        if (product_index > -1) {
          const new_quantity =
            updated_current_project.spaces[space_index].assigned_products[
              product_index
            ].quantity + value

          if (new_quantity < 0) {
            return
          }
          updated_current_project.spaces[space_index].assigned_products.splice(
            product_index,
            1,
            {
              ...updated_current_project.spaces[space_index].assigned_products[
                product_index
              ],
              quantity: new_quantity,
            }
          )

          state.current_project = { ...updated_current_project }

          const project_index = state.projects.findIndex(
            (p) => p.id === state.current_project.id
          )

          if (project_index > -1) {
            state.projects.splice(project_index, 1, {
              ...updated_current_project,
            })
          }
        }
      }
    },
    updateProductSpace: (state, action) => {
      const { product, old_space, new_space } = action.payload

      if (old_space.id === new_space.id) {
        return
      }

      let updated_current_project = { ...state.current_project }

      const old_space_index = updated_current_project.spaces.findIndex(
        (s) => s.id === old_space.id
      )

      if (old_space_index > -1) {
        const product_index = updated_current_project.spaces[
          old_space_index
        ].assigned_products.findIndex((p) => p.id === product.id)
        //remove from old space
        if (product_index > -1) {
          updated_current_project.spaces[
            old_space_index
          ].assigned_products.splice(product_index, 1)

          state.current_project = { ...updated_current_project }

          const project_index = state.projects.findIndex(
            (p) => p.id === state.current_project.id
          )

          if (project_index > -1) {
            state.projects.splice(project_index, 1, {
              ...updated_current_project,
            })
          }
        }
      }

      const new_space_index = updated_current_project.spaces.findIndex(
        (s) => s.id === new_space.id
      )

      if (new_space_index > -1) {
        const product_index = updated_current_project.spaces[
          new_space_index
        ].assigned_products.findIndex((p) => p.id === product.id)
        //find if it exists in the new place
        if (product_index === -1) {
          updated_current_project.spaces[
            new_space_index
          ].assigned_products.push(product)
        } else {
          updated_current_project.spaces[new_space_index].assigned_products[
            product_index
          ].quantity += product.quantity
        }

        state.current_project = { ...updated_current_project }

        const project_index = state.projects.findIndex(
          (p) => p.id === state.current_project.id
        )

        if (project_index > -1) {
          state.projects.splice(project_index, 1, {
            ...updated_current_project,
          })
        }
      }
    },
    removeProduct: (state, action) => {
      const { product_id, project_id } = action.payload
      const project_index = state.projects.findIndex((p) => p.id === project_id)
      if (project_index > -1) {
        const product_index = state.projects[project_index].products.findIndex(
          (p) => p.id === product_id
        )
        if (product_index > -1) {
          state.projects[project_index].products.splice(product_index, 1)
        }

        const new_space_list = [...state.projects[project_index].spaces]

        new_space_list.forEach((s) => {
          const assigned_index = s.assigned_products.findIndex(
            (p) => p.id === product_id
          )
          if (assigned_index > -1) {
            const space_index = state.projects[project_index].spaces.findIndex(
              (space) => space.id === s.id
            )
            state.projects[project_index].spaces[
              space_index
            ].assigned_products.splice(assigned_index, 1)
          }
        })
      }

      state.current_project = state.projects.find((p) => p.id === project_id)
    },
    deleteProject: (state, action) => {
      const { project_id } = action.payload
      const project_index = state.projects.findIndex((p) => p.id === project_id)
      if (project_index > -1) {
        state.projects.splice(project_index, 1)
      }

      if (state.current_project && state.current_project.id === project_id) {
        state.current_project = null
      }
    },
    editProject: (state, action) => {
      const { payload, project_id } = action.payload
      const project_index = state.projects.findIndex((p) => p.id === project_id)

      if (project_index > -1) {
        state.projects.splice(project_index, 1, {
          ...state.projects[project_index],
          ...payload,
        })
      }

      if (state.current_project && state.current_project.id === project_id) {
        state.current_project = { ...state.current_project, ...payload }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch_projects.fulfilled, (state, action) => {
      const { payload } = action
      state.projects = payload
    })
    builder.addCase(fetch_project.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex((p) => p.id === payload.id)
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload)
      } else {
        state.projects.push(payload)
      }
    })
    builder.addCase(create_project.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex((p) => p.id === payload.id)
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload)
      } else {
        state.projects.push(payload)
      }
    })
    builder.addCase(duplicate_project.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex((p) => p.id === payload.id)
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload)
      } else {
        state.projects.push(payload)
      }
    })
    builder.addCase(edit_project.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex((p) => p.id === payload.id)
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload)
      } else {
        state.projects.push(payload)
      }

      if (state.current_project && state.current_project.id === payload.id) {
        state.current_project = payload
      }
    })
    builder.addCase(delete_project.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex((p) => p.id === payload.id)
      if (project_index > -1) {
        state.projects.splice(project_index, 1)
      }

      if (state.current_project && state.current_project.id === payload.id) {
        state.current_project = null
      }
    })
    builder.addCase(create_space.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex(
        (p) => p.id === payload.project.id
      )
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload.project)
      } else {
        state.projects.push(payload.project)
      }

      if (
        state.current_project &&
        state.current_project.id === payload.project.id
      ) {
        state.current_project = payload.project
      }
    })
    builder.addCase(add_product_to_space.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex(
        (p) => p.id === payload.project.id
      )
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload.project)
      } else {
        state.projects.push(payload.project)
      }

      if (
        state.current_project &&
        state.current_project.id === payload.project.id
      ) {
        state.current_project = payload.project
      }
    })
    builder.addCase(remove_product_from_project.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex(
        (p) => p.id === payload.project.id
      )
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload.project)
      } else {
        state.projects.push(payload.project)
      }

      if (
        state.current_project &&
        state.current_project.id === payload.project.id
      ) {
        state.current_project = payload.project
      }
    })
    builder.addCase(edit_assigned_product.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex(
        (p) => p.id === payload.project.id
      )
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload.project)
      } else {
        state.projects.push(payload.project)
      }

      if (
        state.current_project &&
        state.current_project.id === payload.project.id
      ) {
        state.current_project = payload.project
      }
    })
    builder.addCase(edit_assigned_product_customization.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex(
        (p) => p.id === payload.project.id
      )
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload.project)
      } else {
        state.projects.push(payload.project)
      }

      if (
        state.current_project &&
        state.current_project.id === payload.project.id
      ) {
        state.current_project = payload.project
      }
    })
    builder.addCase(edit_space.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex(
        (p) => p.id === payload.project.id
      )
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload.project)
      } else {
        state.projects.push(payload.project)
      }

      if (
        state.current_project &&
        state.current_project.id === payload.project.id
      ) {
        state.current_project = payload.project
      }
    })
    builder.addCase(delete_space.fulfilled, (state, action) => {
      const { payload } = action
      const project_index = state.projects.findIndex(
        (p) => p.id === payload.project.id
      )
      if (project_index > -1) {
        state.projects.splice(project_index, 1, payload.project)
      } else {
        state.projects.push(payload.project)
      }

      if (
        state.current_project &&
        state.current_project.id === payload.project.id
      ) {
        state.current_project = payload.project
      }
    })
    builder.addCase(add_inquiry.fulfilled, (state, action) => {
      const { payload } = action
      //Update project status
      if(payload.inquiry.type === 2 && payload.inquiry.project_id) {
        const project_index = state.projects.findIndex(
          (p) => p.id === payload.inquiry.project_id
        )
        if (project_index > -1) {
          state.projects.splice(project_index, 1, {...state.projects[project_index], status: 3})
        }
        if (
          state.current_project &&
          state.current_project.id === payload.inquiry.project_id
        ) {
          state.current_project = {...state.current_project, status: 3}
        }
      }
     
     

     
    })
  },
})

// Action creators are generated for each case reducer function

export const {
  selectCurrentProject,
  addProductToProject,
  createNewProject,
  updateProductQuantity,
  createNewSpace,
  editSpace,
  removeProduct,
  deleteSpace,
  updateProductSpace,
  deleteProject,
  editProject,
} = projectSlice.actions

export default projectSlice.reducer
