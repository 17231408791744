const common = {
    innoGreen: 'InnoGreen',
    products: 'Produkter',
    greenService: 'SE Vihreys palveluna',
    contact: 'Kontakta oss',
    company: 'Om oss',
    blog: 'Blogg',
    references: 'Referenser',
    research: 'SE Tutkimus',
    wellBeing: 'SE Vihreydestä hyvinvointia',
    responsibility: 'SE Vastuulllisuus',
    innovation: 'SE Innovaatio',
    work: 'Jobba hos InnoGreen',
    myAccount: 'Mitt konto',
    logIn: 'Logga in',
    footerText: `SE InnoGreen parantaa viihtyisyyttä ja hyvinvointia tarjoamalla 
        innovatiivisia viherratkaisuja.`,
    koivu: 'Koivu',
    greenWall: 'SE Viherseinät',
    greenFurniture: 'SE Viherkalusteet',
    greenInterior: 'SE Vihersisustus',
    customSolutions: 'SE Räätälöidyt ratkaisut',
    outdoorSpaces: 'SE Ulkoalueet',
    services: 'SE Palvelut',
    privacy: 'Integritetspolicy',
    cookies: 'Använding av cookies',
    linkAllProducts: 'Se alla produkter',
    relatedProducts: 'Relaterade produkter',
    customize: 'SE Valitse:',
    showMorePictures: 'Visa fler bilder',
    measurements: 'Mått',
    materials: 'Material',
    downloadMaterials: 'Nedladdningsbart material',
    configureProduct: 'Konfigurera produkten',
    readMore: 'SE Läs mera',
    yourSelection: 'SE Valintasi',
    yourSelectionText: `SE Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
            ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
            consequat.`,
    username: 'Username',
    password: 'Password',
    logInWithGoogle: 'Log in with Google'
}

export default common