import './styles.scss'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import CustomCard from '../common/CustomCard'
import CustomButton from '../common/CustomButton'
import { ArrowSimple } from '../Icons'


const ReferenceGroup = (props) => {
  const { references, title, link, responsive } = props
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()


  const handleReferenceClick = (reference) => {
    if (i18n.language === 'FI')
      navigate('/referenssit/' + reference.slug)
    if (i18n.language === 'EN')
      navigate('/references/' + reference.slug)
  }

  const handleReferencesClick= () => {
    if (i18n.language === 'FI')
      navigate('/referenssit')
    if (i18n.language === 'EN')
      navigate('/references')
  }

  const btnStyle = {
    padding: '1.25rem 2.75rem',
    marginTop: '2rem'
  }

  return (
    <div className='reference-group-container'>
      <div className='reference-group-body-container'>
        <div className='reference-group-title'>{title}</div>
        <Carousel
          responsive={responsive}
          containerClass='reference-group-custom-carousel'
          itemClass='reference-group-card-entry-container'
        >
            {references && references.map((reference, index) =>  (
                <div key={reference.id} className='reference-group-card no-select' onClick={() => handleReferenceClick(reference)}>
                  <CustomCard
                    { ...reference }
                    type='reference'
                  />
                </div>
              )
            )}
        </Carousel>
        {link && (
          <CustomButton
            button_text={t('common.references').toUpperCase()}
            yellow={true}
            icon={<ArrowSimple strokeWidth={0.35}/>}
            customStyles={btnStyle}
            contentClass='send-btn'
            onClickHandler={handleReferencesClick}
          />
        )}
      </div>
    </div>
  )
}

export default ReferenceGroup
