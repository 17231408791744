import './styles.scss'
import React, { useState, useRef, useEffect } from 'react'

const CustomTextInput = (props) => {
  const {
    identifier,
    label,
    type,
    onContentChange,
    page,
    value = '',
    language = 'FI'
  } = props
  const [isEditing, setIsEditing] = useState(false)
  const [text, setText] = useState(value)
  const inputRef = useRef(null)
  var inputClass = 'custom-input-text'
  let customInputClass = 'custom-input'

  // Update value when it changes
  useEffect(() => {
    setText(value)
  }, [value])

  const handleClick = () => {
    setIsEditing(true)
  }

  const handleBlur = () => {
    if (text.trim() === "") {
      setText("")  // If text is empty, keep it as empty
    }
    setIsEditing(false)
  }

  const handleChange = (e) => {
    setText(e.target.value)
    if (type === 'headerTitle') {
      onContentChange('title', e.target.value, 'update', language)
    }
    else if (type == 'contactTitle') {
      onContentChange('title', e.target.value, language)
    }
    else if (type == 'Title') {
      onContentChange('title', e.target.value)
    }
    else if (type == 'infoBoxTitle') {
      onContentChange('title', e.target.value, language)
    }
    else if (type === 'slug') {
      onContentChange('slug', e.target.value)
    }
    else if (type === 'imgTitle') {
      onContentChange({key:'title', value: e.target.value, identifier:identifier, language:language})
    }
    else if (type === 'date') {
      onContentChange('date', e.target.value)
    }
    else if (type == 'alt') {
      onContentChange('alt', e.target.value, identifier)
    }
    else if (type == 'contactName') {
      onContentChange('name', e.target.value, identifier)
    }
    else if (type == 'contactEmail') {
      onContentChange('email', e.target.value, identifier)
    }
    else if (type == 'contactPhone') {
      onContentChange('phone', e.target.value, identifier)
    }
    else if (type == 'captionText') {
      onContentChange({key:'text', value: e.target.value, identifier:identifier, language:language})
    }
    else if (type == 'contactText') {
      onContentChange('text', e.target.value, language)
    }
    else if (type == 'Text') {
      onContentChange('text', e.target.value, identifier)
    }
    else if (type == 'textArea') {
      onContentChange('text', e.target.value, language )
    }
    else if (type == 'YoutubeUrl') {
      onContentChange(e.target.value)
    }
    else if (type == 'btnText') {
      onContentChange('btnText', e.target.value, language)
    }
    else if (type == 'btnLink') {
      onContentChange('btnLink', e.target.value)
    }
  }

  // Dynamically adjust input width based on text length
  const getInputWidth = () => {
    const inputLength = text.length
    if (type === 'infoBoxTitle') {
      return `${Math.max(100, inputLength * 33)}px`
    }
    if (type === 'headerTitle') {
      return `${Math.max(100, inputLength * 25)}px`
    }
    return `${Math.max(100, inputLength * 11)}px`
  }

  // Focus the input field when it is displayed
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  if (type === 'date') {
    inputClass = 'custom-input-date'
    if (page == 'blog') {
      inputClass += ' center'
    }
  } else if (type === 'headerTitle') {
    inputClass = 'custom-input-header-title'
    customInputClass += ' custom-input-header-title-input'
    if (page == 'blog') {
      inputClass += ' center'
    }
  } else if (type === 'CustomImageInput') {
    inputClass = 'custom-input-alt-img-text'
  }
  else if (type === 'captionText') {
    inputClass = 'custom-input-caption'
  }
  else if (type === 'imgTitle') {
    inputClass = 'custom-input-image-title'
  }
  else if (type === 'contactName') {
    inputClass = 'custom-input-contact-name'
  }
  else if (type === 'contactTitle') {
    inputClass = 'custom-input-contact-title'
  }
  else if (type === 'infoBoxTitle') {
    inputClass = 'custom-input-info-box-title'
    customInputClass = 'custom-input-info-box-title-input'
  }
  else if (type === 'textArea') {
    inputClass = 'custom-input-text-area'
  }
  else if (type === 'btnText') {
    inputClass = 'custom-input-btn-text'
    customInputClass = 'custom-input-btn-text-input'
  }
  else if (type === 'btnLink') {
    inputClass = 'custom-input-btn-link'
    customInputClass = 'custom-input-btn-link-input'
  }
  else if (type === 'slug') {
    inputClass = 'custom-input-slug'
    if (page == 'blog') {
      inputClass += ' center'
    }
    customInputClass = 'custom-input-slug'
  }

  return (
      <div className='custom-input-container'>
        {isEditing ? 
            type === 'textArea' ?
            (
              (
                <textarea
                  ref={inputRef}
                  type="text"
                  value={text}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={label}
                  className='custom-input-text-area'
                />
              ) 
            ) : (
              (
                <input
                  ref={inputRef}
                  type="text"
                  value={text}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={label}
                  className={customInputClass}
                  style={{ width: getInputWidth() }}
                />
              )
            )
            : type === 'textArea' ?
            (
              (
                <div
                  className={inputClass}
                  onClick={handleClick}
                  style={{ color: text ? 'black' : 'gray', width: getInputWidth()}}
                >
                  {text || label}
                </div>
              )
            ) : (
              (
                <div
                  className={inputClass}
                  onClick={handleClick}
                  style={{ color: text ? 'black' : 'gray' }}
                >
                  {text || label}
                </div>
              )
            )
        
      }
      </div>
  )
}

export default CustomTextInput