import { createSelector, createSlice } from '@reduxjs/toolkit'
import {
  fetch_blogs,
  fetch_blog,
  fetch_management_blog,
  fetch_blog_groups,
  fetch_blog_jobs,
  fetch_management_blogs,
  update_blog_state,
  edit_blog,
} from './asyncThunk'

export const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    blogs: [],
    blogGroup: [],
    recordsTotal: 0,
    recordsFiltered: 0,
    currentPage: 1,
    pages: 1,
    selectedFilters: [],
    blogGroupsFetched: false,
    openPositionsFetched: false,
    openPositions: [],
    innovationBlogs: [],
    readAlsoBlogs: [],
  },
  reducers: {
    setFilters(state, action) {
      state.selectedFilters = action.payload
    },
    setPage(state, action) {
      state.currentPage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch_blogs.fulfilled, (state, action) => {
      const { data, recordsTotal, recordsFiltered, forPage } = action.payload

      if (forPage) {
        if (forPage === 'innovatiot') {
          state.innovationBlogs = data
        } else if (forPage === 'blog') {
          state.readAlsoBlogs = data
        }
      } else {
        state.blogs = data
        state.recordsTotal = recordsTotal
        state.recordsFiltered = recordsFiltered
        state.pages  = Math.ceil(recordsFiltered / 9);
      }
    })
    builder.addCase(fetch_management_blogs.fulfilled, (state, action) => {
      const { data, recordsTotal, recordsFiltered, forPage } = action.payload

      if (forPage) {
        if (forPage === 'innovatiot') {
          state.innovationBlogs = data
        } else if (forPage === 'blog') {
          state.readAlsoBlogs = data
        }
      } else {
        state.blogs = data
        state.recordsTotal = recordsTotal
        state.recordsFiltered = recordsFiltered
        state.pages  = Math.ceil(recordsFiltered / 9);
      }
    })
    builder.addCase(fetch_blog.fulfilled, (state, action) => {
      const { payload } = action
      const index = state.blogs.findIndex(blog => blog.id === payload.blog.id)
      if (index !== -1) {
        state.blogs[index] = payload.blog
      } else {
        state.blogs.push(payload.blog)
      }
    })
    builder.addCase(update_blog_state.fulfilled, (state, action) => {
      const { payload } = action
      const index = state.blogs.findIndex(blog => blog.id === payload.id)
      if (index !== -1) {
        state.blogs[index] = payload
      } else {
        state.blogs.push(payload)
      }
    })
    builder.addCase(fetch_management_blog.fulfilled, (state, action) => {
      const { payload } = action
      const index = state.blogs.findIndex(blog => blog.id === payload.blog.id)
      if (index !== -1) {
        state.blogs[index] = payload.blog
      } else {
        state.blogs.push(payload.blog)
      }
    })
    builder.addCase(fetch_blog_groups.fulfilled, (state, action) => {
      state.blogGroup = action.payload
      state.blogGroupsFetched = true
    })
    builder.addCase(fetch_blog_jobs.fulfilled, (state, action) => {
      state.openPositions = action.payload
      state.openPositionsFetched = true
    })
  },
})

export const selectBlogs = (state) => state.blog.blogs
export const selectBlogByUrl = (url) => createSelector(
  [selectBlogs],
  (blogs) => {
    return blogs.find(blog => blog.url === url)
  }
)
export const { setFilters, setPage } = blogSlice.actions

// Create selector for random Blogs
export const createDemoBlogsSelector = (numReferences) =>
  createSelector(
    [(state) => state.blog.blogs],
    (blogs) => {
      let shuffledblogs = blogs.slice()

      // Shuffle blogs array
      for (let i = shuffledblogs.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * (i + 1))
        [shuffledblogs[i], shuffledblogs[randomIndex]] = [
          shuffledblogs[randomIndex],
          shuffledblogs[i]
        ]
      }

      const blogGroup = shuffledblogs.slice(0, numReferences).map(item => (item))
      
      return blogGroup
    }
  )

export default blogSlice.reducer
