import './styles.scss'
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import InquiriesTableToolbar from '../../Inquiries/components/InquiriesTableToolbar/InquiriesTableToolbar'
import InquiriesTableHeader from '../../Inquiries/components/InquiriesTableHeader/InquiriesTableHeader'
import InquiriesRow from '../../Inquiries/components/InquiriesRow/InquiriesRow'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress
} from "@mui/material"


function descendingComparator(a, b, orderBy, order) {
  let valueA = a[orderBy]
  let valueB = b[orderBy]

  // Handle empty strings and null values by moving them to the bottom
  if (valueA === null || valueA === '') {
    valueA = undefined
  }
  if (valueB === null || valueB === '') {
    valueB = undefined
  }

  // For strings, convert to lowercase for case-insensitive comparison
  if (typeof valueA === 'string') {
    valueA = valueA.toLowerCase()
  }
  if (typeof valueB === 'string') {
    valueB = valueB.toLowerCase()
  }

  // Comparison logic for sorting null/empty values, always move them to the bottom
  if (order === 'desc') {
    if (valueB === undefined) return -1 // Move null/empty to the bottom
    if (valueA === undefined) return 1  // Move null/empty to the bottom
  } else {
    if (valueB === undefined) return 1  // Move null/empty to the bottom
    if (valueA === undefined) return -1 // Move null/empty to the bottom
  }

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function descendingPriorityComparator(a, b, orderBy, order) {
  let valueA = a[orderBy]
  let valueB = b[orderBy]

  const isAOverdue = a['deadline'] && new Date(a['deadline']) < new Date()
  const isBOverdue = b['deadline'] && new Date(b['deadline']) < new Date()

  // Show overdue first always if sorting by Priority
  if (order === 'desc') {
    if (isAOverdue && isBOverdue) {
      if (valueA > valueB) {
        valueA = 12
        valueB = 11
      } else if (valueA < valueB) {
        valueA = 11
        valueB = 12
      } else {
        valueA = 11
        valueB = 11
      }
    } else if (isAOverdue && !isBOverdue) {
      valueA = 11
    } else if (!isAOverdue && isBOverdue) {
      valueB = 11
    }
  } else {
    if (isAOverdue && isBOverdue) {
      if (valueA > valueB) {
        valueA = 12
        valueB = 11
      } else if (valueA < valueB) {
        valueA = 11
        valueB = 12
      } else {
        valueA = 11
        valueB = 11
      }
    } else if (isAOverdue && !isBOverdue) {
      valueA = 0
    } else if (!isAOverdue && isBOverdue) {
      valueB = 0
    }
  }

  // if same value sort by date_created
  if (valueB === valueA) {
    valueA = new Date(a['date_created'])
    valueB = new Date(b['date_created'])
    // Always show oldest first date first
    if (order === 'desc') {
      if (valueB < valueA) {
        return 1
      }
      if (valueB > valueA) {
        return -1
      }
      return 0
    } else {
      if (valueB < valueA) {
        return -1
      }
      if (valueB > valueA) {
        return 1
      }
      return 0
    }
  }

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function descendingDateComparator(a, b, orderBy) {
  let valueA = new Date(a[orderBy]);
  let valueB = new Date(b[orderBy]);

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function descendingStateComparator(a, b, orderBy) {
  const valueA = new Date(a[orderBy])
  const valueB = new Date(b[orderBy])
  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  if (orderBy === 'priority') {
    return order === 'desc'
      ? (a, b) => descendingPriorityComparator(a, b, orderBy, order)
      : (a, b) => -descendingPriorityComparator(a, b, orderBy, order)
  } else if (orderBy === 'date_created') {
    return order === 'desc'
      ? (a, b) => descendingDateComparator(a, b, orderBy)
      : (a, b) => -descendingDateComparator(a, b, orderBy)
  } else if (orderBy === 'state') {
    return order === 'desc'
      ? (a, b) => descendingStateComparator(a, b, orderBy)
      : (a, b) => -descendingStateComparator(a, b, orderBy)
  } else {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy, order)
      : (a, b) => -descendingComparator(a, b, orderBy, order)
  }
}

const AssignedInquiry = (props) => {
  const {
    assignableUsers,
    date = true,
    priority = true,
    name = true,
    email = true,
    phone = true,
    company = true,
    city = true,
    message = true,
    state = true,
    rowsPerPageOptions = [10, 25, 50],
    rowsPerPageDefault = 10,
    size='medium',
    title,
    isLoading,
    handleSetSelectedSelection,
  } = props
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const dispatch = useDispatch()
  const user_assigned_inquiries = useSelector((state) => state.management.user_assigned_inquiries)
  const [openDropdown, setOpenDropdown] = useState(null) // To track which dropdown is open
  const [typeFilter, setTypeFilter] = useState('all')
  const [stateFilter, setStateFilter] = useState('all')
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('priority')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }


  const visibleRows = useMemo(
    () =>
      [...user_assigned_inquiries]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, user_assigned_inquiries],
  )

  return (
      <div className='inquiries-container'>
        <div className='inquiries-filter-container'>
        </div>

        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0  }}>
          {/* Enhanced Toolbar */}
          <InquiriesTableToolbar
            title={title}
          />

          <TableContainer
            elevation={0}
          >
            <Table
              size={size}
            >
              <InquiriesTableHeader
                order={order}
                orderBy={orderBy}
                handleRequestSort={handleRequestSort}
                date={date}
                priority={priority}
                name={name}
                email={email}
                phone={phone}
                company={company}
                city={city}
                message={message}
                state={state}
                
              />
              <TableBody
                className='contact-request-table-body'
              >
                {isLoading ?
                  (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <div style={{ height: rowsPerPage * 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    visibleRows.map((item, index) => (
                      <InquiriesRow
                        assignableUsers={assignableUsers}
                        key={item.id}
                        item={item}
                        index={index}
                        stateFilter={stateFilter}
                        handleSetSelectedSelection={handleSetSelectedSelection}
                        date={date}
                        priority={priority}
                        name={name}
                        email={email}
                        phone={phone}
                        company={company}
                        city={city}
                        message={message}
                        state={state}
                        collapsible={true}
                      />
                    ))
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={user_assigned_inquiries.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
  )
}

export default AssignedInquiry
