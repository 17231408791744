import { createSelector, createSlice } from '@reduxjs/toolkit'
import {
  fetch_inquiry,
  fetch_inquiries,
  count_inquiry,
  update_inquiry,
  assign_user_inquiry,
  unassign_user_inquiry,
  fetch_overdue_inquiries,
  fetch_new_inquiries,
  fetch_unassigned_inquiries,
  fetch_users,
  edit_user,
  deactivate_user,
  fetch_user_requests,
  edit_user_request,
  fetch_meta_tags,
  update_meta_tag,
  fetch_user_inquiry_chart,
  add_inquiry_note,
  fetch_inquiry_notes,
  fetch_user_assigned_inquiry,
  fetch_user,
  fetch_management_blog,
  fetch_management_reference,
} from './asyncThunk'

export const managementSlice = createSlice({
  name: 'management',
  initialState: {
    inquiries: [],
    overdue_inquiries: [],
    unassigned_inquiries: [],
    user_assigned_inquiries: [],
    new_inquiries: [],
    user_pie_chart: [],
    selected_inquiry: null,
    selected_blog: null,
    selected_reference: null,
    selected_user: null,
    contact_requests: [],
    project_inquiries: [],
    product_inquiries: [],
    download_guide_inquiries: [],
    inquiry_notes: [],
    meta_tags: [],
    meta_total_count: 0,
    meta_fetched_pages: {},
    users: [],
    user_requests: [],
    management_count: {
      all: 0,
      project: 0,
      product: 0,
      contact: 0,
      guide: 0,
      unassigned: 0,
      overdue: 0,
      isNew: 0,
      new: 0,
      open: 0,
      inProgress: 0,
      closed: 0,
      offerSent: 0,
      offerAccepted: 0,
      offerRejected: 0,
      user_requests_count: 0,
    },
  },
  reducers: {
    addInquiry: (state, action) => {
      const { inquiry } = action.payload;

      // Handle null or empty values in inquiry state
      const updateItem = (item) => {
        if (item === null || item === '') return Infinity;
        return item;
      };

      // Adding the inquiry to the inquiries state
      state.inquiries.push({
        ...inquiry,
        state: updateItem(inquiry.state), // Handle null/empty state here
      });
    },
    updateCount: (state, action) => {
      const { type, old_type } = action.payload;
      if (type)
        state.management_count[type] += 1;
      if (old_type)
        state.management_count[old_type] -= 1;
    },
    setSelectedInquiry: (state, action) => {
      state.selected_inquiry = action.payload
    },
    setSelectedBlog: (state, action) => {
      state.selected_blog = action.payload
    },
    setSelectedReference: (state, action) => {
      state.selected_reference = action.payload
    },
    setSelectedUser: (state, action) => {
      state.selected_user = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetch_inquiry.fulfilled, (state, action) => {
      state.selected_inquiry = action.payload
    })
    builder.addCase(fetch_inquiries.fulfilled, (state, action) => {
      const { data, type } = action.payload
        state.inquiries = data
    })
    builder.addCase(fetch_management_blog.fulfilled, (state, action) => {
      state.selected_blog = action.payload
    })
    builder.addCase(fetch_management_reference.fulfilled, (state, action) => {
      state.selected_reference = action.payload
    })
    builder.addCase(fetch_user.fulfilled, (state, action) => {
      state.selected_user = action.payload
    })
    builder.addCase(fetch_user_assigned_inquiry.fulfilled, (state, action) => {
      state.user_assigned_inquiries = action.payload
    })
    builder.addCase(count_inquiry.fulfilled, (state, action) => {
      state.management_count = action.payload
    })
    builder.addCase(add_inquiry_note.fulfilled, (state, action) => {
      state.inquiry_notes.unshift(action.payload)
    })
    builder.addCase(fetch_inquiry_notes.fulfilled, (state, action) => {
      state.inquiry_notes = action.payload
    })
    builder.addCase(fetch_overdue_inquiries.fulfilled, (state, action) => {
      state.overdue_inquiries = action.payload
    })
    builder.addCase(fetch_new_inquiries.fulfilled, (state, action) => {
      state.new_inquiries = action.payload
    })
    builder.addCase(fetch_unassigned_inquiries.fulfilled, (state, action) => {
      state.unassigned_inquiries = action.payload
    })
    builder.addCase(unassign_user_inquiry.fulfilled, (state, action) => {
      state.selected_inquiry = action.payload
    })
    builder.addCase(assign_user_inquiry.fulfilled, (state, action) => {
      state.selected_inquiry = action.payload
      // update the inquiry in the list
      const index = state.inquiries.findIndex(
        (c) => c.id === action.payload.id
      )
      if (index > -1) {
        state.inquiries.splice(index, 1, {
          ...action.payload,
        })
      }
      // remove the inquiry from the unassigned list
      const unassignedIndex = state.unassigned_inquiries.findIndex(
        (c) => c.id === action.payload.id
      )
      if (unassignedIndex > -1) {
        // also update count if found
        state.management_count.unassigned -= 1
        state.unassigned_inquiries.splice(unassignedIndex, 1)
      }
    })
    builder.addCase(fetch_user_inquiry_chart.fulfilled, (state, action) => {
      state.user_pie_chart = action.payload
    })
    builder.addCase(update_inquiry.fulfilled, (state, action) => {
      const { inquiry, type } = action.payload
      if (type === 'priority') {
        const index = state.inquiries.findIndex(
          (c) => c.id === inquiry.id
        )
        if (index > -1) {
          state.inquiries.splice(index, 1, {
            ...inquiry,
          })
        }
        const index_overdue = state.overdue_inquiries.findIndex(
          (c) => c.id === inquiry.id
        )
        if (index_overdue > -1) {
          state.overdue_inquiries.splice(index_overdue, 1, {
            ...inquiry,
          })
        }
        const index_unassigned = state.unassigned_inquiries.findIndex(
          (c) => c.id === inquiry.id
        )
        if (index_unassigned > -1) {
          state.unassigned_inquiries.splice(index_unassigned, 1, {
            ...inquiry,
          })
        }
      }
      else if (type === 'inquiry') {
        state.selected_inquiry = inquiry
      }
      else {
        const index = state.inquiries.findIndex(
          (c) => c.id === inquiry.id
        )
  
        if (index > -1) {
          state.inquiries.splice(index, 1)
        }
      }

      
    })
    builder.addCase(fetch_users.fulfilled, (state, action) => {
      state.users = action.payload
    })
    builder.addCase(fetch_meta_tags.fulfilled, (state, action) => {
      action.payload.metaTags.forEach((newTag) => {
        // Check if the meta tag with the same route already exists
        const exists = state.meta_tags.some((tag) => tag.id === newTag.id)
    
        if (!exists) {
          state.meta_tags.push(newTag); // Append only if it doesn't exist
        }
      })
      state.meta_fetched_pages[action.meta.arg.page] = action.meta.arg.per_page;
      state.meta_total_count = action.payload.totalCount
    })
    builder.addCase(update_meta_tag.fulfilled, (state, action) => {
      const index = state.meta_tags.findIndex((c) => c.id === action.payload.id)
      if (index > -1) {
        state.meta_tags.splice(index, 1, {
          ...action.payload,
        })
      }
    })
    builder.addCase(edit_user.fulfilled, (state, action) => {
      state.selected_user = action.payload

      const index = state.users.findIndex((c) => c.id === action.payload.id)
      if (index > -1) {
        state.users.splice(index, 1, {
          ...action.payload,
        })
      }
    })
    builder.addCase(deactivate_user.fulfilled, (state, action) => {
      const index = state.users.findIndex((c) => c.id === action.payload.id)
      if (index > -1) {
        state.users.splice(index, 1, {
          ...action.payload,
        })
      }
    })
    builder.addCase(fetch_user_requests.fulfilled, (state, action) => {
      state.user_requests = action.payload
    })
    builder.addCase(edit_user_request.fulfilled, (state, action) => {
      const index = state.user_requests.findIndex(
        (c) => c.id === action.payload.id
      )

      if (index > -1) {
        state.user_requests.splice(index, 1)
      }
    })
  },
})

export const selectInquiryById = (inquiry_id) => createSelector(
  [state => state.management.inquiries],
  (inquiries) => {
    return inquiries.find(inquiry => inquiry.id === parseInt(inquiry_id))
  }
)

export const {
  addInquiry,
  updateCount,
  setSelectedInquiry,
  setSelectedReference,
  setSelectedBlog,
  setSelectedUser
} = managementSlice.actions;
export default managementSlice.reducer
