import './styles.scss'
import { Close } from '../Icons'
import { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import { add_inquiry } from '../../store/asyncThunk'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LoadingSpinner from '../common/LoadingSpinner'
import { lang } from 'moment'

const newInquiryInitialState = {
  name: '',
  phone_number: '',
  email: '',
  message: '',
  city: '',
  company: '',
  type: 'PRODUCT',
}

const SendInquiryModal = ({ showing, closeModal, product_id }) => {
  const dispatch = useDispatch()
  const [newInquiry, setNewInquiry] = useState(newInquiryInitialState)
  const [inquirySent, setinquirySent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const language = useSelector((state) => state.user.language)

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const updateNewInquiry = ({
    name,
    email,
    phone_number,
    company,
    city,
    message,
  }) => {
    setErrorList([])
    if (name !== undefined) {
      setNewInquiry({ ...newInquiry, name: name })
    }
    if (email !== undefined) {
      setNewInquiry({ ...newInquiry, email: email })
    }
    if (phone_number !== undefined) {
      setNewInquiry({ ...newInquiry, phone_number: phone_number })
    }
    if (company !== undefined) {
      setNewInquiry({ ...newInquiry, company: company })
    }
    if (city !== undefined) {
      setNewInquiry({ ...newInquiry, city: city })
    }
    if (message !== undefined) {
      setNewInquiry({ ...newInquiry, message: message })
    }
  }

  const close = () => {
    document.body.style.overflow = 'unset'
    setinquirySent(false)
    closeModal()
  }

  const [errorList, setErrorList] = useState([])
  const handleSendBtnClick = async () => {
    setErrorList([])
    setIsLoading(true)
    // check email input
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newInquiry.email)) {
      setErrorList([t('error.emailInvalid')])
      setIsLoading(false)
      return
    }
    await dispatch(add_inquiry({...newInquiry, product_id: product_id, language: language}))
      .then((res) => {
        if (res.error) {
          setErrorList([t('project.genericInquiryError')])
        }
        if (res.payload.success) {
          setinquirySent(true)
        }
      })
      .catch((error) => {
        console.error("inquiry Error:", error)
      })
    setNewInquiry(newInquiryInitialState)
    setIsLoading(false)
  }

  return (
    showing && (
      <div className='inquiry-modal-root'>
        <div className='inquiry-modal-container'>
          {isLoading && <LoadingSpinner border2 />}
          <div className='inquiry-modal-header'>
            <div className='inquiry-modal-header-exit-btn-container'>
              <div
                className='inquiry-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20}/>
              </div>
            </div>
          </div>
          { inquirySent ?
            (
              <div className='inquiry-modal-content'>
                <div className='inquiry-modal-header-title small'>{t('project.inquirySentSuccess')}</div>
              </div>
            ) : (
              <div className='inquiry-modal-content'>
                <div className='inquiry-modal-header-title'>{t('project.newInquiry')}</div>
                {errorList.length > 0 && (
                  <div className='inquiry-modal-error-container'>
                    {errorList.map((error) => {
                      return (
                        <div className='inquiry-modal-error-entry' key={error}>
                          - {error}
                        </div>
                      )
                    })}
                  </div>
                )}
                <div className='inquiry-modal-input-group'>
                  <label htmlFor='name' className='inquiry-modal-input-label'>{t('project.yourName')}*</label>
                  <input
                    id='name'
                    type='text'
                    value={newInquiry.inquirer_name}
                    className='inquiry-modal-input-field'
                    onInput={(e) =>
                      updateNewInquiry({ name: e.target.value })
                    }
                  ></input>
                </div>
                <div className='inquiry-modal-input-group'>
                  <label htmlFor='email' className='inquiry-modal-input-label'>{t('project.email')}*</label>
                  <input
                    id='email'
                    type='text'
                    value={newInquiry.email}
                    className='inquiry-modal-input-field'
                    onInput={(e) => updateNewInquiry({ email: e.target.value })}
                  ></input>
                </div>
                <div className='inquiry-modal-input-group'>
                  <label htmlFor='phone_number' className='inquiry-modal-input-label'>{t('project.phoneNumber')}</label>
                  <input
                    id='phone_number'
                    type='text'
                    value={newInquiry.phone_number}
                    className='inquiry-modal-input-field'
                    onInput={(e) =>
                      updateNewInquiry({ phone_number: e.target.value })
                    }
                  ></input>
                </div>
                <div className='inquiry-modal-input-group'>
                  <label htmlFor='company' className='inquiry-modal-input-label'>{t('project.company')}</label>
                  <input
                    id='company'
                    type='text'
                    value={newInquiry.company}
                    className='inquiry-modal-input-field'
                    onInput={(e) =>
                      updateNewInquiry({ company: e.target.value })
                    }
                  ></input>
                </div>
                <div className='inquiry-modal-input-group'>
                  <label htmlFor='city' className='inquiry-modal-input-label'>{t('project.city')}</label>
                  <input
                    id='city'
                    type='text'
                    value={newInquiry.city}
                    className='inquiry-modal-input-field'
                    onInput={(e) =>
                      updateNewInquiry({ city: e.target.value })
                    }
                  ></input>
                </div>
                <div className='inquiry-modal-input-group'>
                  <label  htmlFor='message' className='inquiry-modal-input-label'>{t('project.message')}*</label>
                  <textarea
                    id='message'
                    type='text'
                    value={newInquiry.message}
                    className='inquiry-modal-input-field textarea'
                    onInput={(e) =>
                      updateNewInquiry({ message: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
            )
          }
          
          { !inquirySent && (
            <div className='inquiry-modal-btn-group'>
              <CustomButton
                button_text={t('project.send').toUpperCase()}
                primary={true}
                disabled={
                  newInquiry.name.trim().length === 0 ||
                  newInquiry.message.trim().length === 0 ||
                  newInquiry.email.trim().length === 0
                }
                onClickHandler={() => handleSendBtnClick()}
              />
              <CustomButton transparent={true} button_text={t('project.cancel').toUpperCase()} onClickHandler={() => close()} />
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default SendInquiryModal
