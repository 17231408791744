import './styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import SocialIconGroup from '../SocialIconGroup'

const ContactCard = (props) => {
  const {
    title = '',
    id,
    phone = '',
    email = '',
    buttonText = '',
    scrollRefs,
    type,
    address = [],
    identityCode = '',
    invoiceText = '',
    eInvoiceAddress = '',
    operator = '',
    pdfInvoice = '',
    pdf = '',
    link = '',
    identityText,
    eInvoiceAddressText,
    operatorText,
    pdfInvoiceText,
    downloadText
  } = props
  
    const scrollToCustomization = (id) => {
      if (scrollRefs && scrollRefs.current[id]) {
        scrollRefs.current[id].scrollIntoView({ behavior: 'smooth', block: 'start' })
        
        if (scrollRefs.current[id].dataset.visible === 'false') {
          scrollRefs.current[id].click()
        }
      }
    }

  if (type === 'companyInfo') {
    return (
      <>
        <div className='contact-card-title'>{title}</div>
        {address && address.map((item, index) => {
          return (
              <div className='contact-card-address' key={index}>{item}</div>
          )
        })}
        <div className='contact-card-identity-code'>{identityText}: {identityCode}</div>
        <SocialIconGroup size='2xl' color='black'/>
      </>
    )
  } else if (type === 'invoiceInfo') {
    return (
      <>
        <div className='contact-card-title'>{title}</div>
        <div className='contact-card-invoice-text'>{invoiceText}</div>
        <div className='contact-card-invoice-address'>{eInvoiceAddressText}: {eInvoiceAddress}</div>
        <div className='contact-card-operator'>{operatorText}: {operator}</div>
        <div className='contact-card-pdf-invoice'>{pdfInvoiceText}: {pdfInvoice}</div>
        <div className='contact-card-pdf'>{pdf} <a href={link} download>{downloadText}</a></div>
      </>
    )
  } else {
    return (
      <>
        <div className='contact-card-title'>{title}</div>
        <div className='contact-card-phone'>{phone}</div>
        <div className='contact-card-email'><a href={`mailto:${email}`}>{email}</a></div>
        <div
          className='contact-card-btn no-select'
          onClick={() => {scrollToCustomization(id)}}
        >
          <span>{buttonText.toUpperCase()}</span>&nbsp;<FontAwesomeIcon size='sm' icon={faArrowDown}/>
        </div>
      </>
    )
  }


}

export default ContactCard
