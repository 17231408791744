import './styles.scss'
import { useParams, useNavigate, useLocation, useSearchParams, useMatches } from 'react-router-dom'
import { ArrowLeftLong, Download } from '../../components/Icons'
import { fetch_product, fetch_product_material } from '../../store/asyncThunk'
import { setGlobalLoading } from '../../store/uiSlice'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect } from 'react'
import CustomButton from '../../components/common/CustomButton'
import LoadingScreen from '../../components/common/LoadingScreen'
import { useTranslation } from 'react-i18next'
import { get_decoded_string } from '../../plugins/helper'

const materials = [
  {
    name: 'Product material 1',
    id: 1,
  },
  {
    name: 'Product material 2',
    id: 2,
  },
  {
    name: 'Product material 3',
    id: 3,
  },
]

const ProductDetails = () => {
  const { slug } = useParams()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  let [searchParams] = useSearchParams()
  const current_project = useSelector((state) => state.project.current_project)
  const global_loading = useSelector((state) => state.ui.global_loading)
  const product = useSelector((state) =>state.product.products.find((p) => p.slug === slug || p.en_slug === slug))
  const navigate = useNavigate()
  const language = useSelector((state) => state.user.language)

    const matches = useMatches()
    const routeLanguage = matches.find(m => m.handle)?.handle?.language
    const storedLanguage = i18n.language
  
    useEffect(() => {
      // Change the language if the route language is different from the stored language
      if (storedLanguage !== routeLanguage) {
        i18n.changeLanguage(routeLanguage);
      }
    }, [routeLanguage])
  
  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      const preload_customizations =
        searchParams.get('preload_customizations') === 'true'
      await dispatch(fetch_product({ slug, preload_customizations, get_images: true }))
      dispatch(setGlobalLoading(false))
    })()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const goBackToProject = () => {
    if (current_project) {
      navigate('/project/' + current_project.id)
    } else {
      const project_id = searchParams.get('project_id')
      if (project_id) {
        navigate('/project/' + project_id)
      } else {
        navigate('/account')
      }
    }
  }

  const goToProduct = () => {
    if (i18n.language ===  'FI') {
      navigate('/tuotteet/' + product.slug)
    } else if (i18n.language === 'EN') {
      navigate('/products/' + product.en_slug)
    }
  }

  const getProductDescription = (product) => {
    if (product) {
      if (product.name === 'Parkly palveluna') {
        return (
          <>
            <div className='product-page-description-text'>
              {t(`${get_decoded_string(product.name)}.longDescription`).split('\\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                  <br />
                </React.Fragment>
              ))}
            </div>
            <a 
              href={t(`${get_decoded_string(product.name)}.link`)}
              target="_blank"
              rel="noopener noreferrer"
              className='product-page-description-link'
            >
            {t(`${get_decoded_string(product.name)}.linkText`)}
            </a>
            <br/>
            <br/>
            <div className='product-page-description-title'>
              {t(`${get_decoded_string(product.name)}.title`)}
            </div>
            <br/>
            <div className='product-page-description-text'>
              {t(`${get_decoded_string(product.name)}.longDescription2`).split('\\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                  <br />
                </React.Fragment>
              ))}
            </div>
          </>
        )

      } else {
        return (
          <div className='product-page-description-text'>
            {t(`${get_decoded_string(product.name)}.longDescription`).split('\\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
              <br />
            </React.Fragment>
          ))}
          </div>
        )
      }
    }
  }

  const getProductMaterialByLanguage = () => {
    if (product) {
      if (product.product_materials) {
        if (language === 'FI') {
          const product_materials = product.product_materials.filter(item => item.text_fi.includes("(FI)")).map(({ text_fi, ...rest }) => ({ ...rest, text: text_fi }))
          return product_materials
        } else if (language === 'EN') {
          // Viherpysäkki and Parkly Palveluna Only has Brochures in Finnish
          if (product.name === 'Viherpysäkki' || product.name === 'Parkly palveluna') {
            return product.product_materials.map(({ text_en, ...rest }) => ({ ...rest, text: text_en }))
          }
          const product_materials = product.product_materials.filter(item => item.text_en.includes("(EN)")).map(({ text_en, ...rest }) => ({ ...rest, text: text_en }))
          return product_materials
        }
      }
    }
  }

  const fetchProductMaterial = async (material) => {
    dispatch(fetch_product_material({ material_id: material.id }))
      .then((res) => {
        if (res.error) {
          throw new Error(res.error.message)
        }
        const blob = res.payload

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url;
        a.download = material.file_name || "download.pdf"
        document.body.appendChild(a);
        a.click();
  
        // Cleanup
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Download failed:", error)
      })
  }

  if (global_loading || !product) {
    return (
      <>
        <div className='product-details-page-container'></div>
        <LoadingScreen />
      </>
    )
  }

  return (
    <>
    { product && (
      <div className='product-details-page-container'>
        <div className='product-details-page-body-container'>
          <div
            className='product-details-page-back-navigation-link'
            onClick={() => goBackToProject()}
          >
            <ArrowLeftLong /> {t('project.backToProject').toUpperCase()}
          </div>
          <div className='product-details-page-product-name header-text'>
            {t(`${get_decoded_string(product.name)}.name`)}
          </div>
          <div className='product-details-page-info'>
            <div className='product-details-page-description'>
              {getProductDescription(product)}
            </div>
            <div className='product-details-page-material-container'>
              <div className='product-details-page-material-card'>
                <div className='product-details-page-material-title'>
                  {t('common.downloadMaterials')}
                </div>
                <div className='product-details-page-material-entries'>
                  <div className='product-page-material-entries'>
                    { product && product.product_materials ?
                      (
                        getProductMaterialByLanguage().map((material) => (
                          <div
                            key={material.id} 
                            className='product-page-material-entry'
                            onClick={() => fetchProductMaterial(material)}
                          >
                            <Download size={14}/>
                            {material.text}
                          </div>
                        ))
                      ) : (
                        <></>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className='product-details-page-configurate-product-btn'>
                <CustomButton
                  button_text={t('project.configurateThisProduct').toUpperCase()}
                  onClickHandler={goToProduct}
                />
              </div>
            </div>
          </div>
          {/* <div className='product-img-container'>
            {
              product.imgSrcs ? (
                product.imgSrcs.map((imgSrc, index) => (
                  <div key={index} className='product-img-boilerplate'>
                    <img
                      src={imgSrc}
                      alt={`product-${product.id} ${product.name} ${index + 1}`}
                    />
                  </div>
                ))
              ) : null
            }
          </div> */}
        </div>
      </div>
    )}
    </>
  )
}

export default ProductDetails
