const company = {
  headerGroup: 'About Us',
  headerTitle: 'Sustainable greenery',
  cardTitle: 'Green for Better Living',
  cards: [
    {
      title: '3,7 M€',
      text: 'turnover',
    },
    {
      title: '50',
      text: 'greenery professionals',
    },
    {
      title: '600',
      text: 'contract customers',
    },
    {
      title: '25+',
      text: 'own product innovations',
    },
    {
      title: '15 000',
      text: 'annual green maintenance visits'
    },
    {
      title: '2 000 000 m²',
      text: 'of protected rainforest together with <link>',
      linkText: 'World Land Trust',
      link: 'https://www.worldlandtrust.org/',
    },
  ],
  textTitle: `Hi from InnoGreen! We have big visions, but we’ll try to keep this brief.`,
  introText: `It all started with frogs - yes, you heard right. We developed our first 
      green wall when we noticed that frogs liked to live near green walls. We thought, 
      why not people? We developed and fine-tuned our green wall, and the greenery 
      began to spread from office to office and city to city, bringing enjoyment to 
      people one green wall at a time. But the green walls were just the beginning, 
      and as a natural next step, we joined forces with our long-time partner InnoGreen. 
      Since then we have continued under the name InnoGreen, keeping a piece of the 
      Green House Effect in the name.
      \\n
      A few years later, we were joined by top experts from Vihermaailma and Luvi, and 
      our professional team grew even more. A shared passion for nature and well-being 
      has kept us busy. It was clear that a simple green business wasn't enough for us, 
      and we wanted to think bigger, greener and more sustainable.
      \\n
      So what are we doing today? We are developing urban green solutions for a simple 
      but important reason: we want to improve the quality of life through greenery. 
      The green solutions we develop support the urban environment, contribute to 
      people's well-being - and look really great at the same time. We want to play 
      our part in building a more sustainable future and our aim is to be the most 
      responsible partner and employer in the green sector. We are a growing and 
      international company that dares to try new things and break boundaries.
      \\n
      Based on our values - humaneness, curiosity and awareness - we work together 
      with our clients and partners to build a more sustainable green sector in the 
      long term. For us, greenery is not just a visual element; it is a way to 
      promote a sense of community, well-being and biodiversity in cities where 
      the need for green spaces and connection with nature is growing.
      \\n
      Change for the better requires the courage to change. That's why, for example, 
      we have stopped selling plastic plants and developed a range of domestic 
      Koivu products so our customers no longer have to buy pots from far away 
      countries. We also want to play our part in promoting the circular economy: 
      none of our plants are single-use, and our quality material choices make 
      our products durable, long-lasting and reusable. That's why our own products 
      are also available for rent as a service. As our customer, you can enjoy 
      greenery without the hassle of ownership. 
      \\n
      We think that's about it. You might also be interested in our other content 
      - you can find more interesting information about us and our green mission 
      on our webpages.
      \\n
      Best regards,`,
  regardsText: 'all of us at InnoGreen'
}

export default company