import './styles.scss'
import { Close } from '../../Icons'
import { useEffect, useState } from 'react'
import CustomButton from '../CustomButton'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { log_out } from '../../../store/asyncThunk'

const CustomCookiesBanner = ({ showing, closeModal, togglePrivacyModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const handleCookiesApproval = (is_approved) => {
    localStorage.setItem('innogreen_cookies_approved', is_approved)
    if (!is_approved) {
      //remove all cookies and sign out immediately
      dispatch(log_out())
    }
    close()
    window.location.reload()
  }

  const multi_line_process = (text) => {
    const handle_new_line = text.replace(/(?:\r\n|\r|\n)/g, '<br />')

    return handle_new_line
  }

  const [showMore, setShowMore] = useState(false)

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  const goToPrivacy = () => {
    togglePrivacyModal()
    close()
  }

  return (
    showing && (
      <div className='custom-cookies-banner-root'>
        <div className='custom-cookies-banner-container'>
          <div className='custom-cookies-banner-title'>
            {t('privacy.cookiesTitle')}
          </div>
          <div className='custom-cookies-banner-content'>
            <div
              dangerouslySetInnerHTML={{
                __html: multi_line_process(
                  t('privacy.GDPRcookiesShortDescription')
                ),
              }}
            ></div>
            <p
              className='custom-cookies-banner-link'
              onClick={() => toggleShowMore()}
            >
              {showMore
                ? t('privacy.labelShowLess')
                : t('privacy.labelLearnMore')}
            </p>
            {showMore && (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: multi_line_process(
                      t('privacy.GDPRcookiesLongDescription')
                    ),
                  }}
                ></div>
                <div>
                  {/* {t('privacy.readOurPrivacy')}{' '} */}
                  <span className='custom-cookies-banner-link' onClick={() => goToPrivacy()}>
                    ({t('privacy.link')})
                  </span>
                </div>
              </>
            )}
          </div>

          <div className='custom-cookies-banner-btn-group'>
            <CustomButton
              button_text={t('privacy.acceptCookiesBtn')}
              onClickHandler={() => handleCookiesApproval(1)}
            />
            <CustomButton
              button_text={t('privacy.rejectBtn')}
              onClickHandler={() => handleCookiesApproval(0)}
            />
          </div>
        </div>
      </div>
    )
  )
}

export default CustomCookiesBanner
