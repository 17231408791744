import './styles.scss'
import { useEffect, useState } from 'react'
import CustomButton from '../CustomButton'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { passwordResetRequest } from '../../../store/asyncThunk'
import { Close } from '../../Icons'

const CustomForgotPasswordModal = ({ showing, closeModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [resetPasswordPayload, setResetPasswordPayload] = useState({
    email: '',
  })
  const [errorList, setErrorList] = useState([])
  const [requested_success, setRequestedSuccess] = useState(false)
  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const multi_line_process = (text) => {
    const handle_new_line = text.replace(/(?:\r\n|\r|\n)/g, '<br />')

    return handle_new_line
  }

  const handleConfirmClick = async () => {
    const updated_error_list = []
    if (
      !resetPasswordPayload.email ||
      resetPasswordPayload.email.trim().length === 0
    ) {
      updated_error_list.push(t('login.errorEmailRequired'))
    }
    if (
      resetPasswordPayload.email &&
      resetPasswordPayload.email.trim().length > 0 &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(resetPasswordPayload.email)
    ) {
      updated_error_list.push(t('login.errorEmailInvalid'))
    }
    setErrorList(updated_error_list)
    if (updated_error_list.length === 0) {
      await dispatch(passwordResetRequest(resetPasswordPayload)).then((res) => {
        if (!res.error) {
          setErrorList([])
          setRequestedSuccess(true)
        } else {
          if (res.payload && res.payload.data && res.payload.data.error) {
            setErrorList([res.payload.data.error.description])
          } else {
            setErrorList([t('login.genericPasswordResetError')])
          }
        }
      })
    }
  }

  const updateResetPasswordInput = ({ email }) => {
    if (email !== undefined) {
      setResetPasswordPayload({ ...resetPasswordPayload, email: email })
    }
  }

  return (
    showing && (
      <div className='custom-forgot-password-modal-root'>
        <div className='custom-forgot-password-modal-container'>
          <div className='custom-forgot-password-modal-header'>
            <div className='custom-forgot-password-modal-header-exit-btn-container'>
              <div
                className='custom-forgot-password-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20} />
              </div>
            </div>
          </div>
          {!requested_success && (
            <div className='custom-forgot-password-modal-title'>
              {t('login.forgotPasswordTitle')}
            </div>
          )}
          <div className='custom-forgot-password-modal-content'>
            {errorList.length > 0 && (
              <div className='custom-forgot-modal-error-container'>
                {errorList.map((error) => {
                  return (
                    <div
                      className='custom-forgot-modal-error-entry'
                      key={error}
                    >
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            {requested_success && (
              <div>{t('login.passwordResetSuccessMessage')}</div>
            )}
            {!requested_success && (
              <>
                <div className='custom-forgot-password-modal-input-group'>
                  <label className='custom-forgot-password-modal-input-label'>
                    {t('login.labelEmail')}
                  </label>
                  <input
                    type='text'
                    value={resetPasswordPayload.email}
                    className='custom-forgot-password-modal-input-field'
                    onInput={(e) =>
                      updateResetPasswordInput({ email: e.target.value })
                    }
                  ></input>
                </div>
                <p>{t('login.passwordResetRequestText')}</p>
              </>
            )}
          </div>

          <div className='custom-forgot-password-modal-btn-group'>
            {requested_success ? (
              <CustomButton
                button_text={t('login.closeBtn')}
                primary
                onClickHandler={() => close()}
              />
            ) : (
              <>
                <CustomButton
                  button_text={t('login.confirmBtn')}
                  primary
                  onClickHandler={() => handleConfirmClick()}
                />

                <CustomButton
                  button_text={t('login.cancelBtn')}
                  onClickHandler={() => close()}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default CustomForgotPasswordModal
