import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import logoEurope from '../../assets/images/Innovation/logo-Euroopan_unionin osarahoittama.png'
import logoForum from '../../assets/images/Innovation/logo-FORUM_VIRIUM.png'
import LogoPilotGreen from '../../assets/images/Innovation/logo-Pilot_Green.png'
import logoUudenmaanLiitto from '../../assets/images/Innovation/logo-Uudenmaan_liitto.png'
import { ArrowSimple } from '../Icons'


const InnovationMedia = (props) => {
  const { innovationItems } = props
  const { t } = useTranslation()
  // &>svg {
  //   stroke:  $app-purple-strong !important;
  // }
  return (
    <div className='innovation-media-container'>
      <div className='innovation-media-body-container'>
        <div className='innovation-media-left-container'>
          <div className='innovation-media-left-item-title'>{t('innovation.mediaTitle')}</div>
          {innovationItems && innovationItems.map((item, index) => {
            return(
              <React.Fragment key={index}>
                <div className='innovation-media-left-item'>
                  <div className='innovation-media-left-item-text'>{item.text}</div>
                    <a
                      className='innovation-media-left-item-link no-select'
                      href={item.link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {item.linkText} &nbsp;
                      <ArrowSimple className='innovation-media-left-link' size={16} strokeWidth={0.1}/>
                    </a>
                </div>
                {
                  innovationItems.length - 1 === index ? 
                  (
                    <></>
                  ) : (
                    <div className='innovation-media-left-item-divider'></div>
                  )
                  
                }
              </React.Fragment>
            )
          })}
        </div>

        <div className='innovation-media-right-container'>
          <div className='innovation-media-right-title'>{t('innovation.partnersTitle')}</div>
          <div className='innovation-media-right-image-container'>
            <img src={logoEurope} alt="Logo Europe" />
            <img src={logoUudenmaanLiitto} alt="Logo Uudenman Liitto" />
            <img src={LogoPilotGreen} alt="Logo Pilot Green" />
            <img src={logoForum} alt="Logo Forum Helsinki" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InnovationMedia