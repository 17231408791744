const common = {
    innoGreen: 'InnoGreen',
    products: 'Products',
    greenService: 'Green as a service',
    contact: 'Contact us',
    company: 'About us',
    blog: 'Blog',
    references: 'References',
    research: 'Research',
    wellBeing: 'Greenery for well-being',
    responsibility:'Sustainability',
    innovation: 'Innovations',
    work: 'Work at InnoGreen',
    myAccount: 'My account',
    logIn: 'Log in',
    footerText: `InnoGreen improves comfort and well-being by providing innovative green solutions.`,
    koivu: 'Koivu',
    greenWall: 'Green wall',
    greenFurniture: 'Green furniture',
    greenInterior: 'Green interior design',
    customSolutions: 'Tailored solutions',
    outdoorSpaces: 'Outdoor spaces',
    services: 'Services',
    privacy: 'Privacy policy',
    cookies: 'Cookies',
    linkAllProducts: 'See all products',
    relatedProducts: 'Related products',
    customize: 'Customise:',
    showMorePictures: 'Show more pictures',
    measurements: 'Measurements',
    materials: 'Materials',
    downloadMaterials: 'Download materials',
    configureProduct: 'Customise product',
    readMore: 'Read more',
    yourSelection: 'Your selection',
    username: 'Username',
    password: 'Password',
    logInWithGoogle: 'Log in with Google',
    notFound: 'Page not Found',
    toHome: 'To Homepage'
}

export default common