import './styles.scss'
import { useTranslation } from 'react-i18next'

const InfoCard = (props) => {
  const { title, body, type, text, linkText, link, linkSourceText, backgroundColor, icon } = props
  const { t} = useTranslation()


  if (type === 'text') {
    var parts = ''
    if (linkText) {
      parts = text.split('<link>')
      if (parts.length > 1) {
        var firstText = parts[0].trim()
        var lastText = parts[1].trim()
      }
    }
    return (
      <>
        <div className={'info-card-container align-left' + ' ' + backgroundColor}>
          <div className='info-card-title no-padding-left-top'>{title}</div>
          {linkText ? 
          ( parts.length > 1 ? 
            (
              <div className='info-card-text no-padding-left-bottom'>
                {firstText}
                &nbsp;
                <a
                  href={link}
                  className='info-card-link'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {linkText}
                </a>
                &nbsp;
                {lastText}
              </div>
            ) : (
              <div className='info-card-text no-padding-left-bottom'>
                {text}
                &nbsp;
                <div className='info-card-link-container'>
                  <div className='info-card-link-source'>{linkSourceText}</div>
                  &nbsp;
                  <a
                    href={link}
                    className='info-card-link'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {linkText}
                  </a>
                </div>
              </div>
            )
            
          ) : (
            <div className='info-card-text no-padding-left-bottom'>{text} </div>
          )
          }
          
        </div>
      </>
    )
  } else if (type === 'no-title') {
    return (
      <>
        <div className='info-card-container'>
        <div className='info-card-icon'>
            {icon}
          </div>
          <div className='info-card-text text-center'>{t(text)}</div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className='info-card-container'>
          <div className='info-card-icon'>
            {icon}
          </div>
          <div className='info-card-title'>{t(title)}</div>
          {
            body && body.map((item, index) => {
              return <div className='info-card-body' key={index} >{t(item.text)} </div>
            })
          }
        </div>
      </>
    )
  }
}

export default InfoCard
