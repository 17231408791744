import './styles.scss'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ArrowSimple } from '../Icons'
import CustomCard from '../common/CustomCard'

const BlogGroup = (props) => {
  const {
    blogs = [],
    title = '',
    linkText = '',
    noBgColor = false,
    type = 'blog'
  } = props
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  const handleBlogsClick = () => {
    if (i18n.language === 'FI')
      navigate('/blogi')
    if (i18n.language === 'EN')
      navigate('/blog')
  }
  let containerClass = 'blog-group-container default'
  if (noBgColor) {
    containerClass = 'blog-group-container'
  }

  if (type === 'work') {
    return (
      <div className={`${containerClass} mt-0`}>
        <div className='blog-group-body-container'>
          <div className='blog-group-cards-container center'>
            { blogs && blogs.map((blog) => {
                return (
                  <div key={blog.id} className='blogs-card' onClick={() => navigate(blog.url)}>
                    <CustomCard { ...blog } type='blog'/>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={containerClass}>
      <div className='blog-group-body-container'>
        <div className='blog-group-title-container'>
          <div className='blog-group-title'>{title}</div>
          <div
            className='blog-group-link no-select'
            onClick={() => handleBlogsClick()}
          >
            {linkText} &nbsp; <ArrowSimple size={14} strokeWidth={0.1}/>
          </div>
        </div>
        <div className='blog-group-cards-container'>
          { blogs && blogs.map((blog) => {
              return (
                <div key={blog.id} className='blogs-card' onClick={() => navigate(blog.url)}>
                  <CustomCard { ...blog } type='blog'/>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )

}

export default BlogGroup
