const koivu_t_sarja = {
  name: 'SE Koivu T-sarja',
  longDescription: `SE The beautiful Koivu pots and room dividers are suitable for 
      a wide range of interiors, bringing a sense of natural peace and harmony 
      to any space. For the most spectacular arrangement, combine different 
      shapes and sizes of Koivu products.
      \\n
      With the Koivu T-Series, you can define and divide your space, either 
      permanently or temporarily. The large room dividers are available with 
      or without legs. They add plenty of greenery and clarity to any space, 
      and make it easy to direct the flow of movement.
      \\n
      The flexibility of birch plywood makes the pots almost seamless, and the 
      natural wood grain gives each pot a unique surface. Birch plywood makes 
      the pots lightweight, but also stable and strong, resistant to long-term 
      use and transportation.
      \\n
      The Koivu pots are delivered together with Orthex inner pots made of 100% 
      recycled plastic. Our experts are happy to help you with the size, shape, 
      shade and leg options of your pots. Our local production in Finland allows 
      for fast delivery, which is approximately 1-2 months from order.`,
  measurements: [
    {
      type: 'SE T-1020:',
      values: [
        {
          value: 'Width 1020 mm'
        },
        {
          value: 'Depth 360 mm'
        },
        {
          value: 'Height 270 mm (without legs)'
        },
      ]
    },
    {
      type: 'SE T-1020XL:',
      values: [
        {
          value: 'Width 1020 mm'
        },
        {
          value: 'Depth 360 mm'
        },
        {
          value: 'Height 720 mm'
        },
      ]
    }
  ],
  materials: [
    {
      type: 'SE Body:',
      value: 'Finnish birch plywood'
    },
    {
      type: 'SE Inner pot:',
      value: 'Orthex, 100% recycled plastic'
    },
    {
      type: 'SE Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'SE Usage:',
      value: 'Indoor',
      icon: 'indoor'
    },
  ],
  wlt: {},
  malli: {
    description: `SE Valitse malli`
  },
  ruukun_vari: {
    description: `SE Näkyvien osien viimeistely`
  },
  jalat: {
    description: `SE Jalkavaihtoehdot`
  },
}

export default koivu_t_sarja