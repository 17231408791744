import './styles.scss'
import { Close } from '../Icons'
import { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import { useDispatch } from 'react-redux'
import { duplicate_project } from '../../store/asyncThunk'
import { useTranslation } from 'react-i18next'

const projectInitialState = {
  name: '',
  description: '',
}

const DuplicateProjectModal = ({ showing, closeModal, project }) => {
  const dispatch = useDispatch()
  const [duplicateProject, setduplicateProject] = useState(projectInitialState)
  const { t } = useTranslation()
  const [errorList, setErrorList] = useState([])
  const [requested_success, setRequestedSuccess] = useState(false)
  const updateProjectInput = ({ name, description }) => {
    if (name !== undefined) {
      setduplicateProject({ ...duplicateProject, name: name })
    }
    if (description !== undefined) {
      setduplicateProject({ ...duplicateProject, description: description })
    }
  }

  useEffect(() => {
    setRequestedSuccess(false)
    setErrorList([])
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const handleConfirmClick = async () => {
    dispatch(
      duplicate_project({ payload: duplicateProject, project_id: project.id })
    ).then((res) => {
      if (!res.error) {
        setErrorList([])
        setRequestedSuccess(true)
      } else {
        setErrorList([t('project.genericDuplicateProjectError')])
      }
    })
  }

  return (
    showing && (
      <div className='duplicate-project-modal-root'>
        <div className='duplicate-project-modal-container'>
          <div className='duplicate-project-modal-header'>
            <div className='duplicate-project-modal-header-exit-btn-container'>
              <div
                className='duplicate-project-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20} />
              </div>
            </div>
          </div>

          <div className='duplicate-project-modal-content'>
            {requested_success ? (
              <div>{t('project.duplicateProjectSuccessMessage')}</div>
            ) : (
              <>
                <div className='duplicate-project-modal-header-title'>
                  {t('project.duplicateProject')}
                </div>
                {errorList.length > 0 && (
                  <div className='duplicate-project-error-container'>
                    {errorList.map((error) => {
                      return (
                        <div className='duplicate-project-error-entry' key={error}>
                          - {error}
                        </div>
                      )
                    })}
                  </div>
                )}
                <div className='duplicate-project-modal-input-group'>
                  <label className='duplicate-project-modal-input-label'>
                    {t('project.duplicatedProjectName')}
                  </label>
                  <input
                    type='text'
                    value={duplicateProject.name}
                    className='duplicate-project-modal-input-field'
                    onInput={(e) =>
                      updateProjectInput({ name: e.target.value })
                    }
                  ></input>
                </div>

                <div className='duplicate-project-modal-input-group'>
                  <label className='duplicate-project-modal-input-label'>
                    {t('project.description')}
                  </label>
                  <textarea
                    type='text'
                    value={duplicateProject.description}
                    className='duplicate-project-modal-input-field textarea'
                    onInput={(e) =>
                      updateProjectInput({ description: e.target.value })
                    }
                  ></textarea>
                </div>
              </>
            )}
          </div>

          <div className='duplicate-project-modal-btn-group'>
            {requested_success ? (
              <CustomButton
                button_text={t('project.close').toUpperCase()}
                onClickHandler={() => close()}
              />
            ) : (
              <>
                <CustomButton
                  button_text={t('project.confirm').toUpperCase()}
                  disabled={duplicateProject.name.trim().length === 0}
                  onClickHandler={() => handleConfirmClick()}
                />
                <CustomButton
                  button_text={t('project.cancel').toUpperCase()}
                  onClickHandler={() => close()}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default DuplicateProjectModal
