import './styles.scss'
import { AccordionClose, AccordionOpen } from '../Icons'
import React, { useState } from 'react'
import EmployeeCard from '../EmployeeCard'
import { useTranslation } from 'react-i18next'
import ContactCardGroup from '../ContactCardGroup'
import kirsiHImgSrc from '../../assets/images/Contact/kirsi-hanninen.jpg'
import leenaKImgSrc from '../../assets/images/Contact/leena-kinnunen.jpg'
import liivikaKImgSrc from '../../assets/images/Contact/liivika-korkalo.jpg'
import anneOImgSrc from '../../assets/images/Contact/anne-ollila.jpg'
import katjaKImgSrc from '../../assets/images/Contact/katja-kankkunen.jpg'
import samiVImgSrc from '../../assets/images/Contact/sami-vainionpaa.jpg'
import miiaKImgSrc from '../../assets/images/Contact/miia-kujala.jpg'
import johannaMImgSrc from '../../assets/images/Contact/johanna-murto.jpg'
import kristinaHImgSrc from '../../assets/images/Contact/kristina-hulkkonen.jpg'
import sariRImgSrc from '../../assets/images/Contact/sari-raty.jpg'
import raisaKImgSrc from '../../assets/images/Contact/raisa-koiranen.jpg'
import teijoTImgSrc from '../../assets/images/Contact/teijo-tiainen.jpg'
import lauraTImgSrc from '../../assets/images/Contact/laura-tubal.jpg'
import piaPImgSrc from '../../assets/images/Contact/pia-paajanen.jpg'
import oulaHImgSrc from '../../assets/images/Contact/oula-harjula.jpg'
import mikkoSImgSrc from '../../assets/images/Contact/mikko-sonninen.jpg'
import jaakkoPImgSrc from '../../assets/images/Contact/jaakko-pesonen.jpg'
import noraTImgSrc from '../../assets/images/Contact/nora-tag.jpg'

const employeeImages = {
  kirsih: kirsiHImgSrc,
  leenak: leenaKImgSrc,
  liivikak: liivikaKImgSrc,
  anneo: anneOImgSrc,
  katjak: katjaKImgSrc,
  samiv: samiVImgSrc,
  miiak: miiaKImgSrc,
  johannam: johannaMImgSrc,
  kristinah: kristinaHImgSrc,
  sarir: sariRImgSrc,
  raisak: raisaKImgSrc,
  teijot: teijoTImgSrc,
  laurat: lauraTImgSrc,
  piap: piaPImgSrc,
  oulah: oulaHImgSrc,
  mikkos: mikkoSImgSrc,
  jaakkop: jaakkoPImgSrc,
  norat: noraTImgSrc,
}

const ContactDepartmentGroup = (props) => {
  const { scrollRefs } = props
  const { t, i18n } = useTranslation()
  const contactInfo = i18n.t('contact.contactInfo', { returnObjects: true })
  const [isVisible, setIsVisible] = useState(contactInfo.map(() => false))

  const toggleVisibility = (index) => {
    setIsVisible((prevIsVisible) =>
      prevIsVisible.map((isVisible, i) =>
        i === index ? !isVisible : isVisible
      )
    )
  }

  return (
    <>
      <div className='contact-department-group-container'>
        <div className='contact-department-group-body-container'>
          {
            contactInfo && contactInfo.map((item, index) => {
              return (
                <div className='contact-department-group-root' key={item.title}>
                  <div 
                    className='contact-department-group-title-container'
                    onClick={() => toggleVisibility(index)}
                    ref={ref => {scrollRefs.current[item.refId] = ref}}
                    data-visible={isVisible[index] ? true : false}
                  >
                    <div className='contact-department-group-title header-text no-select'>{item.title}</div>
                    <div className='contact-department-group-title-icon'>
                      { isVisible[index] ?
                        (
                          <AccordionClose size={44}/>
                        ):(
                          <AccordionOpen size={44}/>
                        )
                      }
                    </div>
                  </div>
                  <div className={isVisible[index] ? 'contact-department-group-contacts-container visible' : 'contact-department-group-contacts-container hidden'}>
                    <div className='contact-department-group-wrapper'>
                      <div className='contact-department-group-phone small-text'>{item.phoneNumber}</div>
                      <a className='contact-department-group-email small-text' href={`mailto:${item.email}`}>{item.email}</a>
                    </div>
                    <div className='contact-department-group-employee-container' key={index}>
                      {
                        item.employee && item.employee.map((employee, index) => {
                          return (
                              <div className='contact-department-group-employee-card-container' key={index}>
                                <EmployeeCard {...employee} imgSrc={employeeImages[employee.id]}/>
                              </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <ContactCardGroup scrollRefs={scrollRefs} type='invoice' />
      </div>
    </>
  )
}

export default ContactDepartmentGroup
