const greenService = {
  headerGroup: 'Vihreys palveluna',
  headerTitle: 'Vuokraa monipuolista vihreyttä helposti.',
  infoCard1Title: 'Helppo',
  infoCard1Text1: 'Kaikki suunnittelusta ylläpitoon yhdessä paketissa',
  infoCard1Text2: 'Runsasta vihreyttä ilman suuria kertainvestointeja',
  infoCard1Text3: ' Kiinteä kaikenkattava kuukausihinta',
  infoCard2Title: 'Joustava',
  infoCard2Text1: 'Vuokraa vain tarpeeseen ilman pitkiä sitoutumisaikoja',
  infoCard2Text2: 'Muuta tai skaalaa kokonaisuutta myös sopimusaikana',
  infoCard2Text3: 'Sopimuksen päättyessä noudamme tuotteet pois',
  infoCard3Title: 'Kestävä',
  infoCard3Text1: 'Suosimme kotimaisia tuotteita, kuten Koivu-ruukkuja',
  infoCard3Text2: 'Meiltä saat vain aitoja kasveja',
  infoCard3Text3: 'Pyrimme uudelleenvuokraamaan tai kierrättämään kasvit ja tuotteet',
  infoBoxTitle1: 'Viihtyisiä tiloja kestävästi',
  infoBoxText1: `Haluamme tehdä viihtyisyydestä mahdollisimman helppoa ja kestävää, ja 
      siksi tarjoamme kaikki InnoGreenin ratkaisut myös joustavana palveluna. Palvelumme kiinteä 
      kuukausimaksu sisältää kaiken mitä tarvitaan viihtyisään ja vehreään 
      tilaan: suunnittelun, tuotteet, kasvit, asennuksen ja ylläpidon.
      \\n
      InnoGreenin omat, kotimaiset tuotteet, kuten viherseinät, Koivu-tuotteet ja viherkalusteet, 
      löydät suunnittelutyökalustamme, jossa voit helposti vertailla tuotteita ja suunnitella 
      oman näyttävän kokonaisuuden. Tarjoamme omille tuotteillemme kaikista joustavimman palvelumallin, 
      jossa voit valita vuokrakauden pituuden täysin vapaasti sekä muuttaa tuotekokonaisuutta milloin 
      tahansa. Vuokrakauden päättyessä kierrätämme tai uudelleenvuokraamme kaikki tuotteet ja kasvit.
      \\n
      Voit myös aina pyytää InnoGreenin asiantuntijan avuksesi suunnittelemaan runsaan vehreän 
      kokonaisuuden. Muut kuin InnoGreenin omat tuotteet ovat vuokrattavissa 36 kk määräaikaisella 
      sopimuksella.`,
  infoBoxTitle2: 'Miten vihreys palveluna toimii?',
  infoBoxBullet1: 'Valitse mieleisesi tuotteet monipuolisesta InnoGreenin omien tuotteiden valikoimasta tai pyydä suunnittelijamme avuksi.',
  infoBoxBullet2: 'Valitse vuokra-aika.',
  infoBoxBullet3: 'Saat tarjouksen, joka sisältää kaiken suunnittelusta ylläpitoon.',
  infoBoxBullet4: 'Kaikki ok? Me käärimme hihat ja toimitamme vihreyden valmiiksi istutettuna sovittuna ajankohtana.',
  infoBoxBullet5: 'Huollamme kasvit ja tuotteet säännöllisesti sekä istutamme ulkotuotteiden kausikasvit. Sopimuksen päätyttyä haemme tuotteet pois.',
  infoBoxBullet6: 'Huollamme ja puhdistamme vuokrasta palautuvat tuotteet ennen uudelleenvuokrausta. Kierrätämme elinkaarensa päähän tulleet kasvit ja tuotteet.',
  productLinkTitle: 'Aloita viihtyisän ja vehreän tilan suunnittelu',
  productLink: ' tästä!'
}

export default greenService