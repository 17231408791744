const common = {
    innoGreen: 'InnoGreen',
    products: 'Tuotteet',
    greenService: 'Vihreys palveluna',
    contactTitle: 'Yhteystiedot',
    contact: 'Ota yhteyttä',
    company: 'Yritys',
    blog: 'Blogi',
    references: 'Referenssit',
    research: 'Tutkimus',
    wellBeing: 'Vihreydestä hyvinvointia',
    innovation: 'Innovaatiot',
    responsibility:'Vastuulllisuus',
    work: 'Töihin InnoGreenille',
    myAccount: 'Oma tili',
    logIn: 'Kirjaudu sisään',
    footerText: `InnoGreen parantaa viihtyisyyttä ja hyvinvointia tarjoamalla 
        innovatiivisia viherratkaisuja.`,
    koivu: 'Koivu',
    greenWall: 'Viherseinät',
    greenFurniture: 'Viherkalusteet',
    greenInterior: 'Vihersisustus',
    customSolutions: 'Räätälöidyt ratkaisut',
    outdoorSpaces: 'Ulkoalueet',
    services: 'Palvelut',
    privacy: 'Tietosuojaseloste',
    cookies: 'Evästeseloste',
    linkAllProducts: 'Katso kaikki tuotteet',
    relatedProducts: 'Aiheeseen liittyvät tuotteet',
    koivuProductGroupLink: 'Lue ',
    customize: 'Valitse:',
    showMorePictures: 'Katso lisää kuvia',
    measurements: 'Mitat',
    materials: 'Materiaalit',
    downloadMaterials: 'Ladattavat materiaalit',
    configureProduct: 'Räätälöi tuote',
    readMore: 'Lue lisää',
    yourSelection: 'Valintasi',
    username: 'Username',
    password: 'Password',
    logInWithGoogle: 'Log in with Google',
    notFound: 'Sivua ei löydy',
    toHome: 'Etusivulle'
}

export default common