import React from 'react'
import './styles.scss'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../store'
import { createDemoProductGroupSelector } from '../../store/productSlice'
import { createDemoReferencesSelector } from '../../store/referenceSlice'
import { useSelector, useDispatch } from 'react-redux'
import { fetch_products, fetch_references } from '../../store/asyncThunk'
import { setGlobalLoading } from '../../store/uiSlice'
import ProductGroup from '../ProductGroup'
import LoadingScreen from '../../components/common/LoadingScreen'
import InfoBox from '../../components/InfoBox'
import infoBox1ImgSrc from '../../assets/images/Home/etusivu-kuva-1.jpg'
import infoBox2ImgSrc from '../../assets/images/Home/etusivu-kuva-2.jpg'
import infoBox3ImgSrc from '../../assets/images/Home/etusivu-kuva-3.jpg'
import infoBox4ImgSrc from '../../assets/images/CustomSolutions/raataloidut-ratkaisut-1.jpg'
import contactUsImgSrc from '../../assets/images/Home/ota-yhteytta-johdatus.jpg'
import { useTranslation } from 'react-i18next'
import ReferenceGroup from '../../components/ReferenceGroup'
import ReactPlayer from 'react-player/vimeo'
import ClipLoader from 'react-spinners/ClipLoader'
import TrustMaryWidget from '../../components/TrustMaryWidget'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 1450 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1450, min: 1110 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1110, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const referenceResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const dispatch = useDispatch()
  const global_loading = useSelector((state) => state.ui.global_loading)
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const numProducts = 4
  const title = 'home_page_product_showcase'
  const demoProductGroupSelector = createDemoProductGroupSelector(numProducts, title)
  const demoProductGroup = useAppSelector(demoProductGroupSelector)
  const references = useSelector((state) => state.reference.homeReferences)
  const language = useSelector((state) => state.user.language)

  const handleVideoReady = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 650)
  }

  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      if (demoProductGroup && demoProductGroup?.products.length === 0) {
        await dispatch(fetch_products({small_images: true}))
      }
      await dispatch(fetch_references({ for_page: 'home', quantity: 3 }))
      dispatch(setGlobalLoading(false))
    })()
  }, [])

  if (global_loading) {
    return (
      <>
        <div className='home-container'></div>
        <LoadingScreen />
      </>
    )
  }

  return (
    <div className='home-container'>
      <div className="home-video-container">
        <div className='home-video'>
          {isLoading ?
              (
                <div className='home-video-loader'>
                  <ClipLoader color='#1a73e8' />
                </div>
              ) : (
                <></>
              )
            }
          <ReactPlayer
            className='home-video-player'
            url='https://vimeo.com/1046352910'
            loop={true}
            playing={true}
            muted={true}
            volume={0}
            width='100%'
            height='100%'
            playsinline
            onReady={handleVideoReady}
          />
        </div>
        <div className='home-title no-select'>{t('home.videoText')}</div>
      </div>
      <InfoBox
        title={t('home.infoBoxTitle1')}
        text={t('home.infoBoxText1')}
        imgSrc={infoBox1ImgSrc}
        link={i18n.language === 'FI' ? 'tuotteet' : 'products'}
        buttonText={t('common.products').toUpperCase()}
        containerClass='pt-3'
      />
      
      <ProductGroup
        group={demoProductGroup}
        key={demoProductGroup.id}
        responsive={responsive}
        link='products'
        linkText='homePageLinkText'
        background="home-page"
      />
    
      <InfoBox
        title={t('home.infoBoxTitle2')}
        text={t('home.infoBoxText2')}
        imgSrc={infoBox2ImgSrc}
        link={i18n.language === 'FI' ? 'vihreys_palveluna' : 'green-as-a-service'}
        buttonText={t('common.greenService').toUpperCase()}
      />

      <ReferenceGroup
        references={references}
        responsive={referenceResponsive}
        title={t('home.referenceTitle')}
        link={i18n.language === 'FI' ? 'referenssit' : 'references'}
      />

      <InfoBox
        title={t('home.infoBoxTitle3')}
        text={t('home.infoBoxText3')}
        imgSrc={infoBox3ImgSrc}
        link={i18n.language === 'FI' ? 'vastuullisuus' : 'sustainability'}
        reverse={true}
        buttonText={t('common.responsibility').toUpperCase()}
        containerClass='pb-2'
      />

    <InfoBox
        title={t('home.infoBoxTitle4')}
        text={t('home.infoBoxText4')}
        imgSrc={infoBox4ImgSrc}
        link={i18n.language === 'FI' ? 'raataloidyt_ratkaisut' : 'tailored-solutions'}
        buttonText={t('common.customSolutions').toUpperCase()}
        containerClass='pb-2 pt-2'
      />

      <InfoBox
        title={t('home.infoBoxTitle5')}
        text={t('home.infoBoxText5')}
        imgSrc={contactUsImgSrc}
        link={i18n.language === 'FI' ? 'yhteystiedot' : 'contact-us'}
        buttonText={t('common.contact').toUpperCase()}
        type='2'
        backgroundColor='purple'
        containerClass='pt-2 pb-3'
      />

      <TrustMaryWidget
        language={language}
      />
    </div>
  )
}

export default Home
