import React from 'react'
import './styles.scss'
import { useEffect } from 'react'
import { useParams, useNavigate, useMatches } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setGlobalLoading } from '../../store/uiSlice'
import { fetch_reference, fetch_products } from '../../store/asyncThunk'
import LoadingScreen from '../../components/common/LoadingScreen'
import { useAppSelector } from '../../store'
import { createDemoProductGroupSelector } from '../../store/productSlice'
import ProductGroup from '../ProductGroup'
import CustomHeader from '../../components/common/CustomHeader'
import CustomImage from '../../components/common/CustomImage'
import { useTranslation } from 'react-i18next'
import { ArrowLeftLong } from '../../components/Icons'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 1450 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1450, min: 1110 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1110, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const Reference = () => {
  const { slug } = useParams()
  const dispatch = useDispatch()
  const global_loading = useSelector((state) => state.ui.global_loading)
  const { t, i18n } = useTranslation()
  const reference = useSelector(state => state.reference.reference)

  const numProducts = 3
  const title = 'reference_page_related_products'
  const demoProductGroupSelector = createDemoProductGroupSelector(numProducts, title)
  const demoProductGroup = useAppSelector(demoProductGroupSelector)
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setGlobalLoading(true))
      await dispatch(fetch_reference(slug))
      if (demoProductGroup && demoProductGroup?.products.length === 0) {
        await dispatch(fetch_products({ small_images: true }))
      }
      dispatch(setGlobalLoading(false))
    }

    fetchData()
  }, [slug])

  const handleReferencesClick = () => {
    if (i18n.language === 'FI')
      navigate('/referenssit')
    if (i18n.language === 'EN')
      navigate('/references')
  }

  if (global_loading) {
  
    return (
      <>
        <div className='reference-container'></div>
        <LoadingScreen />
      </>
    )
  }

  return (
    <div className='reference-container'>
      <div className='reference-body-container'>
        <div className='reference-header-link no-select'>
          <div onClick={() => handleReferencesClick()}>
            <ArrowLeftLong /> {t('reference.headerTitle').toUpperCase()}
          </div>
        </div>
        <div>
          {reference && <CustomHeader { ...reference } type='reference' />}
        </div>
        <div className='reference-image-container'>
          {reference && [...reference.body]
            .sort((a, b) => a.index - b.index)
            .map((component, idx) =>
              component.component === "CustomImageInput" && (
                <React.Fragment key={idx}>
                  <CustomImage {...component} />
                </React.Fragment>
              )
            )}
        </div>

        {reference && reference.used_products.products.length > 0 &&
          <ProductGroup
            group={reference.used_products}
            key={reference.used_products.id}
            responsive={responsive}
            link='products'
            linkText='referencePageLinkText'
          />
        }

      </div>
    </div>
  )
}

export default Reference
