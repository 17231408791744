const greenFurniture = {
  productGroupLink: 'Lue lisää viherkalusteet',
  headerGroup: 'Viherkalusteet',
  headerTitle: 'Vehreyttä, viihtyisyyttä ja pörriäisiä keskellä kaupunkia.',
  infoCard1Title: 'Iso muutos, pieni vaiva',
  infoCard1Text1: 'Elämyksellisiä ulkotiloja palveluna',
  infoCard1Text2: 'Runsasta vehreyttä keskellä kaupunkia',
  infoCard1Text3: 'Viihtyisyyttä, varjoa ja rauhaa hengähtää',
  infoCard2Title: 'Huoletonta asiointia',
  infoCard2Text1: 'Muunneltavat moduulit erilaisiin tarpeisiin',
  infoCard2Text2: 'Kasvit, kalusteet, asennus, ylläpito ja varastointi yhdessä paketissa',
  infoCard2Text3: 'Palvelemme kaikkialla Suomessa',
  infoCard3Title: 'Ihmeteltävää ihmisille ja pörriäisille',
  infoCard3Text1: 'Tue kaupunkibiodiversiteettiä vehreillä viherkalusteilla',
  infoCard3Text2: 'Tarkkaile pörriäisten ja perhosten puuhia',
  infoCard3Text3: 'Ihastele, haistele ja maistele yrttejä sekä marjoja',
  textCard1: `Viherkalusteissa runsas vihreys yhdistyy kalusteisiin, kuten penkkeihin, tilanjakajiin 
      ja pöytiin. Modulaarisilla viherkalusteilla voidaan luoda varjoisia ja virkistäviä levähdyspaikkoja 
      ja samalla tukea kaupunkibiodiversiteettiä.`,
  infoBox1Title: 'Kasvit ja kalusteet yhdessä',
  infoBox1Text: `InnoGreenin modulaarisilla viherkalusteratkaisuilla luot helposti viihtyisiä 
      ulkotiloja, jotka tarjoavat levähdyspaikan, varjoa ja virkistystä urbaanissa ympäristössä. 
      Viherkalusteemme on suunniteltu sijoitettaviksi kaupunkiympäristöön jo rakennetulle pinnalle. 
      Ne ovat helppo ratkaisu väliaikaiseen tai pidempään tarpeeseen. Perustuksia tai muutakaan 
      kallista muutostyötä ei tarvita.
      \\n
      Modulaarisuuden ansiosta voit yhdistellä viherkalusteista, kuten penkeistä ja viherseinistä, 
      käyttäjien ja tilan näköisen sekä kokoisen. Asiantuntijamme auttavat juuri sopivan 
      kokonaisuuden sekä sijoituspaikan valinnassa. Luonnonmukaiset kasvivalinnat tuovat 
      paikalle perhoset ja pörriäiset, joita kaupunkilaiset tai asukkaat voivat seurata puuhissaan.
      \\n
      Kun moduulit ja kasvit on valittu, toimitamme kalusteet paikoilleen kasveineen. Luonnonmukaiset 
      kasvivalinnat tuovat paikalle perhoset ja pörriäiset, joita kaupunkilaiset tai asukkaat 
      voivat seurata puuhissaan. Huollamme ja vaihdamme kohteeseen toiveiden mukaiset kausikasvit 
      ja talven tullen viemme moduulit talvisäilöön odottamaan seuraavaa kautta. Kaikki 
      viherkalusteemme ovat vuokrattavissa.`,
  infoBox2Title: 'Kutsuva viherpysäkki',
  infoBox2Text: `Monipuolinen viherpysäkkimme tukee aidosti kaupunkiympäristön biodiversiteettiä 
      tarjoten hyönteisille, kuten kimalaisille ja perhosille, runsaasti ruokaa ja suojaa sekä 
      elintärkeitä luontokäytäviä viheralueiden välille. Samalla viherpysäkki on lepopaikka 
      meille ihmisille. Viherpysäkillä sopii hengailla tai viettää aikaa vaikka shakkia pelaten. 
      Pysäkille on mahdollista istuttaa runsaasti kukkivia kasveja tai esimerkiksi syötäväksi 
      kelpaavia herkullisia yrttejä ja marjoja. Pihamyyntimme auttaa tarpeisiisi ja ulkotilaasi 
      sopivan pysäkin suunnittelussa.`,
  infoBox3Title: 'InnoGreen x Parkly -viherseinämoduuli',
  infoBox3Text: `Istahda viherseinän syleilyyn, nuuhki kukkien huumaavaa tuoksua tai ihmettele 
      pörriäisten touhuja. InnoGreen x Parkly -viherseinämoduulissa yhdistyvät mukavuus ja 
      viherseinän runsaus. Viherseinämoduuli ei ainoastaan lisää viihtyisyyttä, vaan tuo myös 
      rauhaa sillä se vaimentaa liikenteen ääntä jopa 7 db.`,
  infoBox4Title: 'Ulkoalueet',
  infoBox4Text: `Vehreitä ratkaisuja haastavaan kaupunkiympäristöön.`,
}

export default greenFurniture