const greenInterior = {
  headerGroup: 'Vihersisustus',
  headerTitle: 'Luovia ratkaisuja sekä näyttävää vihreyttä vuosien kokemuksella.',
  infoCard1Title: 'Luova vihersuunnittelu',
  infoCard1Text1: 'Kestävät, kauniit ja näyttävät kokonaisuudet',
  infoCard1Text2: 'Selkeä toteutussuunnitelma',
  infoCard1Text3: 'Tilan ja käyttäjiensä näköinen lopputulos',
  infoCard2Title: 'Huolellinen asennus',
  infoCard2Text1: 'Vesitiiviit ja kestävät ruukut, tilanjakajat ja viherseinät',
  infoCard2Text2: 'Tarkkaan suunniteltu ja turvallinen toimitus',
  infoCard2Text3: 'Viimeistelty ja siisti lopputulos',
  infoCard3Title: 'Ammattitaitoinen huolto',
  infoCard3Text1: 'Loistavaa vehreyttä ympäri vuoden hyvinvointitakuulla',
  infoCard3Text2: 'Asiakaskohtainen huoltaja',
  infoCard3Text3: 'Käyntikohtainen raportointi',
  infoBoxTitle1: 'Ideasta suunnitelmaksi',
  infoBoxText1: `Mahdollisimman näyttävä ja viimeistelty vihersisustus saadaan, kun toteutusta 
      varten laaditaan vihersuunnitelma. Hyvissä ajoin tehdystä vihersuunnitelmasta hyötyvät 
      myös arkkitehdit sekä rakennuttajat.
      \\n
      Ensin vihersuunnittelijamme tutustuvat tilan olosuhteisiin sekä sen käyttötarkoitukseen, 
      toiveisiin ja mahdollisiin rajoituksiin. Suunnitelma sisältää kattavasti kasvi-, tuote- 
      ja ruukkuvalinnat. Suunnittelemme tuotteiden sijoittelun brändi, ekologisuus ja 
      viihtyisyys huomioiden ja lisäksi hahmottelemme asennuksen sekä huollon toteutuksen. 
      Suunnittelun yhteydessä saat meiltä ammattitaitoista tukea ja sparrausta visiollesi.
      \\n
      Vihersuunnitteluun sisältyy alkukartoitus, luonnosehdotus, lopullinen toteutussuunnitelma 
      sekä työselostus toteutettavalle kokonaisuudelle. Kokonaisvaltaisen suunnitelman avulla 
      saat pyydettyä tarkan kustannuslaskelman vihersisustuksen toteuttamiselle.
      \\n
      Saat aina toiveidesi ja tarpeidesi mukaisen suunnitelman, jossa priorisoimme kestäviä 
      ja kotimaisia vaihtoehtoja. InnoGreenin omille tuotteille tarjoamme joustavan 
      palvelumallin kiinteällä kuukausihinnalla.`,
  infoBoxTitle2: 'Vain aitoja kasveja',
  infoBoxText2: `Käytämme toteutuksissamme vain aitoja kasveja. Toivotun tunnelman lisäksi 
      kasvivalintoihin vaikuttavat tilan lämpö-, valo- ja käyttöolosuhteet. Vihersuunnittelijamme 
      auttavat kasvien valinnassa oli kyse sitten yhdestä tilasta tai laajasta, usean tilan 
      kokonaisuudesta. Yhdistelemme toteutuksissamme erilajisia ja -kokoisia kasveja, jotta 
      lopputulos on runsas. Olemme luoneet myös valmiita kasviryhmiä, jotka tekevät suunnittelusta 
      sujuvampaa ja laajoistakin kokonaisuuksista yhtenäisiä.
      \\n
      Lisäksi kiinnitämme huomiota kasvien huollettavuuteen, muotoon ja kasvutapaan. Huomioimme 
      esimerkiksi hyllyihin sijoitettavien kasvien kasvuraamit tai suurten kasvien painon 
      ruukkuineen, jotta lopputulos olisi pitkäikäinen ja näyttävä kasvien kasvaessa ja muuttuessa 
      ajan myötä. Tuomme tiloihin tarvittavat kasvivalot, jotta olosuhteet olisivat kasveille 
      mahdollisimman hyvät.
      \\n
      Stabiloituja, eli glyseriinikäsittelyllä säilöttyjä kasveja käyttämällä vihreydestä voi 
      nauttia myös olosuhteiltaan haastavissa tiloissa. Stabiloidut kasvit säilyttävät kauniin 
      vihreän värinsä ja muotonsa jopa vuosia ilman kastelua tai muuta huoltoa ja käyttöikänsä 
      päässä ne ovat kierrätettäviä ja biohajoavia. Tällä hetkellä InnoGreenin kasvivalikoimasta 
      löydät Euroopassa tuotettuja stabiloituja kasveja.`,
  infoBoxTitle3: 'Tiesitkö?',
  infoBoxText3: `Tutkimukset osoittavat, että vihersisustus ei ainoastaan lisää tilojen 
      viihtyisyyttä, vaan parantaa merkittävästi hyvinvointiamme. Se madaltaa stressiä, 
      kohentaa mielialaamme ja ylläpitää luontoyhteyttä. Yhä useammat panostavat nimenomaan 
      toimitilojen viihtyisyyteen, sillä vehreät tilat tukevat keskittymistä sekä 
      tuottavuutta. Lue lisää vihreyden hyödyistä!`,
  infoBoxLink3: 'Vihreydestä hyvinvointia',
  infoBoxTitle4: 'Huolellinen asennus tekee suunnitelmista totta',
  infoBoxText4: `Asennuksen työ alkaa, kun asiakkaallemme tilatut kasvit ja tuotteet 
      saapuvat varastolle. Tuotteet tarkistetaan, vesieristetään, puhdistetaan ja valmistellaan 
      toimitukseen. Kasvit pussitetaan ja istutetaan ja sammalinstallaatiot valmistellaan 
      niin pitkälle kuin mahdollista. Vuokrasta palautuvat hyväkuntoiset tuotteet puhdistetaan 
      ja huolletaan uutta käyttöä varten ja huonokuntoiset, elinkaarensa päähän tulleet osat 
      ja kasvit kierrätetään.
      \\n
      Asennustiimi kerää, pakkaa ja toimittaa kaikki kasvit, sammaleet, ruukut, seinät ja 
      tilanjakajat kohteisiinsa sovittuna ajankohtana. Jokainen toimitus on tarkkaan 
      suunniteltu ja aikataulutettu, jotta asennus ja tuotteiden sijoittelu sujuu turvallisesti 
      ilman yllätyksiä ja mahdollisimman pienellä häiriöllä asiakkaan tiloissa.`,
  infoBoxTitle5: 'Pitkäkestoista vihreyttä',
  infoBoxText5: `Viherkasvihuollostamme vastaa ammattitaitoinen henkilökuntamme tai 
      tarkkaan valikoitu alan ammattilainen alihankkijana. Huoltamiemme kohteiden 
      kasvit voivat hyvin, ovat pitkäikäisiä ja ilahduttavat lukuisia ihmisiä joka päivä. 
      Kasviesi huollosta vastaa aina nimetty tuttu huoltaja sovittuina ajankohtina. Osana 
      huoltosopimusta hoidamme myös kausikasvien istutukset ja vuosittaiset perushuollot. 
      Huoleton vuokrapalvelumme sisältää aina huollon.`,
  infoBoxTitle6: 'Vihreys palveluna',
  infoBoxText6: `Vuokraa monipuolista vihreyttä helposti.`,
}

export default greenInterior