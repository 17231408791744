const innovation = {
  headerGroup: 'Innovaatiot',
  headerTitle: 'Parannamme elämänlaatua luonnon avulla.',
  textCard: 'Innovaatio\u00ADtoimintamme tavoitteena on ratkoa kaupunki\u00ADympäristöjen haasteita vehreyden avulla.',
  infoBoxTitle1: `Me InnoGreenillä näemme maailman täynnä mahdollisuuksia. Haluamme yhdessä asiakkaidemme 
      kanssa rakentaa yhä kestävämpiä, viihtyisämpiä ja vehreämpiä kaupunkeja, ja siksi kehitämme 
      jatkuvasti uusia, materiaaliviisaita ja kiertotaloutta tukevia viherratkaisuja.`,
  infoBoxText1: `Kehitämme ratkaisuja, joiden tehtävänä on muun muassa viilentää helteisiä katuja, 
      hallita hulevesiä, tukea kaupunkiluonnon monimuotoisuutta ja tuoda lisää luontoa sinne missä 
      sitä eniten tarvitaan. Haluamme luoda ympäristöjä, joissa vehreys sekä näyttää hyvältä että 
      tukee hyvinvointia, yhteisöllisyyttä ja palautumista.
      \\n
      Uteliaisuus on yksi tärkeimmistä arvoistamme. Vaalimme ideointia ja luovuutta, ja etsimme 
      jatkuvasti uusia tapoja yhdistää tutkimustietoa, ihmislähtöistä suunnittelua ja ketteriä 
      kokeiluja. Tiivis yhteistyö monialaisten asiantuntijoiden, yliopistojen, muiden yritysten 
      ja kansalaisjärjestöjen kanssa antaa meille vahvan pohjan, jolle voimme rakentaa entistä 
      toimivampia, kestävämpiä ja vaikuttavampia viherratkaisuja.`,
  partnersTitle: 'Yhteistyö-kumppaneitamme',
  moreAbout: 'Lisää innovaatio\u00ADtoiminnasta',
  seeAllArticles: 'Katso kaikki artikkelit',
  mediaTitle: 'Innovaatio\u00ADistamme mediassa',
  innovationItems: [
    {
      link: 'https://www.hs.fi/helsinki/art-2000009637533.html',
      linkText: 'Helsingin Sanomat',
      text: 'Helsinki haluaa hiljentää ääniä keskustassa: ratkaisuna erikoinen ”viherseinäke”',
    },
    {
      link: 'https://yle.fi/a/74-20102344',
      linkText: 'YLE',
      text: 'Raitiovaunupysäkki puettiin luontoon - viherrakenteet viilentävät ja tuovat pölyttäjiä',
    },
    {
      link: 'https://www.mtvuutiset.fi/artikkeli/voiko-viherseina-estaa-mikromuovien-paatymisen-itamereen-pilottiprojekti-kaynnistyy/7939240#gs.i1hjcf',
      linkText: 'MTV',
      text: 'Voiko viherseinä estää mikromuovien päätymisen Itämereen? Pilottiprojekti käynnistyy',
    },
    {
      link: 'https://www.hbl.fi/2024-08-02/vaxter-ger-svalka-pa-hallplatser-i-fiskehamnen/',
      linkText: 'HBL',
      text: 'Växter ger svalka på hållplatser i Fiskehamnen',
    },
  ]
}

export default innovation