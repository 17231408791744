import React, { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import "./styles.scss" // Ensure you add appropriate styles here
import { isEqual } from "lodash";

const CustomSelect = (props) => {
  const {
    label,
    options,
    onContentChange,
    type,
    page,
    selected_options = []
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const dropdownRef = useRef(null)

  useEffect(() => {
    if (!isEqual(selectedOptions, selected_options)) {
      setSelectedOptions(selected_options);
    }
  }, [selected_options]);
  
  useEffect(() => {
    if (!isEqual(selectedOptions, selected_options)) {
      onContentChange(type, selectedOptions);
    }
  }, [selectedOptions, type, onContentChange]);

  var inputClass = ''
  if (page === 'blog') {
    inputClass = 'blog'
  }

  const handleDropdownToggle = () => {
    setIsOpen((prev) => !prev)
  }
  const handleSelectOption = (option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const isAlreadySelected = prevSelectedOptions.some(
        (selected) => selected.id === option.id
      )
  
      let updatedOptions
  
      if (isAlreadySelected) {
        // Remove the option
        updatedOptions = prevSelectedOptions.filter(
          (selected) => selected.id !== option.id
        )
      } else {
        // Add the option
        updatedOptions = [...prevSelectedOptions, option]
      }
      return updatedOptions
    })
  }

  const handleRemoveOption = (option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = prevSelectedOptions.filter(
        (selected) => selected.id !== option.id
      )
      return updatedOptions
    })
  }

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  return (
    <div className={"custom-select-container " + inputClass}>
      {/* Selected tags */}
      <div className={"custom-select-tags " + inputClass}>
        {selectedOptions.map((option) => (
          <div key={option.id} className={"custom-select-tag " + inputClass}>
            <div>
              {option.tag.toUpperCase()}
            </div>
            <FontAwesomeIcon
              className="custom-select-tag-delete"
              icon={faXmarkCircle}
              onClick={() => handleRemoveOption(option)}
            />
          </div>
        ))}
      </div>

      {/* Label or dropdown trigger */}
      <div
        className={"custom-select-label " + inputClass}
        onClick={handleDropdownToggle}
      >
        {label}
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div className={"custom-select-dropdown " + inputClass} ref={dropdownRef}>
          {options.slice().sort((a, b) => a.tag.localeCompare(b.tag)).map((option) => (
            <div
              key={option.id}
              className={`custom-select-option ${
                selectedOptions.some((selected) => selected.id === option.id)
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSelectOption(option)}
            >
              {option.tag.toUpperCase()}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomSelect
