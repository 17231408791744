const greenInterior = {
  headerGroup: 'Green interior design',
  headerTitle: 'Creative solutions and spectacular greenery with years of experience.',
  infoCard1Title: 'Creative green design',
  infoCard1Text1: 'Durable, beautiful and eye-catching solutions',
  infoCard1Text2: 'Clear implementation plan',
  infoCard1Text3: 'A result that reflects the space and its users',
  infoCard2Title: 'Careful installation',
  infoCard2Text1: 'Watertight and durable pots, dividers and green walls',
  infoCard2Text2: 'Precisely planned and safe delivery',
  infoCard2Text3: 'Impeccable and tidy result',
  infoCard3Title: 'Professional maintenance',
  infoCard3Text1: 'Year-round greenery with plant health guarantee',
  infoCard3Text2: 'Customer-specific caretaker',
  infoCard3Text3: 'Reports from each visit',
  infoBoxTitle1: 'From idea to plan',
  infoBoxText1: `For the most stunning and polished green interior, a well-designed green 
      plan is essential. Architects and contractors also benefit from a green plan that 
      is drawn up well in advance. 
      \\n
      First, our green designers will get to know the conditions of the space, its use, wishes 
      of the users and possible constraints. The plan will include the selection of pots and 
      other products, plant choices and placement tailored to the space's function, brand 
      identity, ecological considerations, and well-being. It will also include a detailed 
      implementation strategy for installation and ongoing maintenance. In 
      addition, you will always receive professional feedback and support for your vision.
      \\n
      The green design includes an initial survey, a draft proposal, a final implementation 
      plan and a work report for the project to be carried out. With a comprehensive plan, 
      you will be able to ask for an accurate cost estimate for the implementation of your 
      green interior.
      \\n
      You will always receive a plan tailored to your wishes and needs, where we prioritise 
      sustainable and domestic options. When using InnoGreen's own products, we offer a 
      flexible service model with a fixed monthly price.`,
  infoBoxTitle2: 'Only real plants',
  infoBoxText2: `We only use real plants in our implementations. In addition to the desired 
      atmosphere, the choice of plants is influenced by the thermal, lighting and operating 
      characteristics of the space. Our green designers can help you choose the right plants 
      for your space, whether it's a single room or a large, multi-space complex. We combine 
      plants of different species and sizes to achieve an ample result. We've also created 
      ready-made plant groups that make the design process smoother and make even large 
      compositions coherent.
      \\n
      We also pay attention to the plants' maintainability, shape and growth habits. For 
      example, we take into account the weight of plants on shelves or the weight of large 
      plants in pots, so that the end result is long-lasting and attractive even as plants 
      grow and change over time. We also bring in the necessary plant lighting to ensure 
      the best possible conditions for the plants.
      \\n
      Even in the most challenging spaces, greenery can be enjoyed by using stabilised, 
      e.g. glycerine-treated, plants where necessary. Stabilised plants retain their 
      beautiful green colour and shape for years without watering or other maintenance, 
      and at the end of their life they are recyclable and biodegradable. Currently, 
      InnoGreen's plant selection includes stabilised plants produced in Europe.`,
  infoBoxTitle3: 'Did you know?',
  infoBoxText3: `Studies show that green interiors not only make spaces more comfortable, 
      but also significantly improves our well-being. It lowers stress, improves our 
      mood and maintains our connection with nature. More and more people are investing 
      in the well-being of their offices, as green spaces help them to concentrate and 
      be more productive. Read more about the benefits of greenery!`,
  infoBoxLink3: 'Greenery for well-being',
  infoBoxTitle4: 'Careful installation brings the plans to life',
  infoBoxText4: `The installation work begins when the plants and products ordered by our 
      customers arrive at the warehouse. The products are checked, waterproofed, cleaned 
      and prepared for delivery. Plants are bagged and planted and moss installations 
      are prepared as far as possible. Products returned from rent in good condition are 
      cleaned and maintained for reuse, while deteriorated parts and plants that have 
      reached the end of their lifecycle, are recycled.
      \\n
      The installation team collects, packs and delivers all plants, moss, pots, walls 
      and space dividers to their destination at the agreed time. Each delivery is 
      carefully planned and scheduled to ensure that the installation and product 
      placement is carried out safely, without surprises and with minimal disruption 
      to the customer's premises.`,
  infoBoxTitle5: 'Long-lasting greenery',
  infoBoxText5: `Our greenery is cared for by our skilled staff or by a carefully 
      selected professional working as our subcontractor. The plants at the sites 
      we maintain are thriving, long-lived and delight countless people every day. 
      Your plants are always looked after by a named, familiar professional at 
      agreed times. As part of the maintenance contract, we also take care of 
      seasonal planting and annual basic maintenance. Our carefree rental service 
      always includes maintenance.`,
  infoBoxTitle6: 'Greenery as a service',
  infoBoxText6: `Learn more:`,
}

export default greenInterior