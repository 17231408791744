import './styles.scss'

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import CustomButton from '../../../../components/common/CustomButton'


const BlogFilters = (props) => {
  const {
    stateFilter,
    setStateFilter,
    filterOptions = [],
  } = props

  const btnStyle = {
    padding: '0.25rem 1rem',
  }

  return (
    <>
      <div className='inquiries-filter-sub'>
        { filterOptions.map((option) => {
          return (
            <React.Fragment key={option.id}>
              <CustomButton
                purple
                onClickHandler={() => setStateFilter(option.tag)}
                button_text={`${option.tag.toUpperCase()}`}
                selected={stateFilter === option.tag}
                customStyles={btnStyle}
              />
            </React.Fragment>
          )
          })
        }
        { stateFilter && (
            <CustomButton
              transparent
              onClickHandler={() => setStateFilter(null)}
              button_text='CLEAR'
              customStyles={btnStyle}
            />
          )
        }
      </div>
    </>
  )
}

export default BlogFilters