import './styles.scss'
import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HeaderBox from '../../components/HeaderBox'
import InfoCardGroup from '../../components/InfoCardGroup'
import InfoBox from '../../components/InfoBox'
import TextCardGroup from '../../components/TextCardGroup'
import { Sustainable, City, Nature, Drop, Circular } from '../../components/CardIcon'
import headerImgSrc from '../../assets/images/Sustainability/vastuullisuus-1.jpg'
import infoBox1ImgSrc from '../../assets/images/Sustainability/vastuullisuus-2.jpg'
import infoBox2ImgSrc from '../../assets/images/Sustainability/vastuullisuus-3.jpg'
import infoBox3ImgSrc from '../../assets/images/Sustainability/vastuullisuus-4.jpg'

const infoBoxBullet = [
  {
    title: 'sustainability.infoBoxBullet1Title',
    text: 'sustainability.infoBoxBullet1Text',
  },
  {
    title: 'sustainability.infoBoxBullet2Title',
    text: 'sustainability.infoBoxBullet2Text',
  },
  {
    title: 'sustainability.infoBoxBullet3Title',
    text: 'sustainability.infoBoxBullet3Text',
  },
]

const icons = [
  <Nature/>,
  <Drop/>,
  <Circular/>
]

const infoCard = [
  {
    icon: <Sustainable/>,
    text: 'sustainability.infoCard1Text'
  },
  {
    icon: <City/>,
    text: 'sustainability.infoCard2Text'
  }
]

const Sustainability = () => {
  const { t, i18n } = useTranslation()
  const cards = i18n.t('sustainability.cards', { returnObjects: true })
  const oursustainabilityCards = i18n.t('sustainability.ourSustainabilityCards', { returnObjects: true })
  const cardGroup = i18n.t('sustainability.cardGroup', { returnObjects: true })
  const [showText, setShowText] = useState(cardGroup.map(() => (false)))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='sustainability-container'>
        <div className='sustainability-body-container'>
          <HeaderBox
            titleText={t('sustainability.headerTitle')}
            pageText={t('sustainability.headerGroup').toUpperCase()}
            imgSrc={headerImgSrc}
            titleBackgroundColor='yellow'
          />

          <div className='sustainability-text-card'>{t('sustainability.textCard')}</div>

          <InfoBox
            title={t('sustainability.infoBoxTitle1')}
            text={t('sustainability.infoBoxText1')}
            imgSrc={infoBox1ImgSrc}
            titleSize='sm'
          />

          <InfoCardGroup cards={infoCard} type='no-title'/>

          <InfoBox
            title={t('sustainability.infoBoxTitle2')}
            text={t('sustainability.infoBoxText2')}
            imgSrc={infoBox2ImgSrc}
            titleSize='sm'
          />

          <InfoBox
            title={t('sustainability.infoBoxTitle3')}
            text={infoBoxBullet}
            imgSrc={infoBox3ImgSrc}
            reverse={true}
            bulletTitle={true}
            link={t('sustainability.infoBoxLink3')}
            linkText={t('sustainability.infoBoxLinkText3')}
          />

          <InfoCardGroup cards={cards} type='text'/>

          <div className='sustainability-text-card'>{t('sustainability.ourSustainabilityText')}</div>

          <div className='sustainability-card-container'>
            { oursustainabilityCards.map((item, index) => (
                <React.Fragment key={index}>
                  <div className={oursustainabilityCards.length - 1 === index ? 'sustainability-card-item-container last-item' : 'sustainability-card-item-container'}>
                    <div className='sustainability-card-item-left-container'>
                      <div className='sustainability-card-item-left-icon-wrapper'>
                        {icons[index]}
                      </div>
                      <div className='sustainability-card-item-left-title'>{item.title}</div>
                    </div>
                    <div className='sustainability-card-item-right-container'>
                      {item.text.map((line, idx) => (
                        <React.Fragment key={idx}>
                          <div className='sustainability-card-item-right-bullet-container'>
                            <div className='sustainability-card-item-right-bullet'>
                              <div className='sustainability-card-item-right-bullet-dot'></div>
                            </div>
                            <div className='sustainability-card-item-right-bullet-text'>{line}</div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
                )
              )
            }
          </div>

          <TextCardGroup cardGroup={cardGroup} showText={showText} setShowText={setShowText}/>

        </div>
      </div>
    </>
  )
}

export default Sustainability
