const koivu_vihertaulu = {
  name: 'Koivu-vihertaulu',
  longDescription: `Koivu-vihertaulut henkivät metsän rauhaa ja ovat 
      vuosia vehreinä kestäviä sisustuselementtejä. Taulut ovat kevyitä 
      ja nopeita ripustaa, ja huoltovapaus mahdollistaa niiden luovankin 
      sijoittelun tilassa. Taulut toimivat yksittäisinä 
      katseenvangitsijoina, mutta niitä voi myös yhdistellä upeiksi 
      kokonaisuuksiksi ja asetelmiksi.  
      \\n
      Suomalaisesta koivusta valmistettuihin raameihin voi valita 
      sammalta, jäkälää tai yhdistelmän näistä kahdesta. Tauluja on 
      saatavilla kolmessa eri muodossa, pyöreä, neliö ja suorakulmio.
      \\n
      Koivu-vihertauluissa käytetään huolto- ja kasteluvapaata 
      stabiloitua sammalta ja jäkälää. Stabiloiduilla kasveilla 
      tarkoitetaan preservoituja, eli ikään kuin säilöttyjä, kasveja. 
      Stabiloinnissa hyödynnetään glyseriiniä, ja käsittelystä 
      huolimatta kasvit ovat kierrätettäviä ja biohajoavia. Sen avulla 
      voidaan säilöä aitoja kasveja ja tuoda ne vehreyttämään 
      olosuhteiltaan haastavampia tiloja. Stabiloidut sammaleet, 
      jäkälät ja kasvit säilyvät muodossaan ja kauniin värisinä jopa 
      vuosia.`,
  measurements: [
    {
      type: 'Pyöreä (halkaisija)',
      values: [
        {
          value: 'S 600 mm'
        },
        {
          value: 'M 900 mm'
        },
        {
          value: 'L 1200 mm'
        }
      ]
    },
    {
      type: 'Neliö:',
      values: [
        {
          value:'S 600x600 mm'
        },
        {
          value:'M 900x900 mm'
        },
        {
          value:'L 1200x1200 mm'
        }
      ]
    },
    {
      type: 'Suorakaide:',
      values: [
        {
          value:'L 1200x600 mm'
        },
        {
          value:'XL 1800x900 mm'
        }
      ]
    },
    {
      type: 'Kehyksen syvyys:',
      value: '60 mm kaikissa malleissa'
    },
  ],
  materials: [
    {
      type: 'Runko:',
      value: 'Suomalainen koivuviilu '
    },
    {
      type: 'Taulun sisältö:',
      value: 'Huoltovapaa stabiloitu sammal tai jäkälä (EU) '
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi'
    },
    {
      type: 'Käyttö:',
      value: 'Sisätilat',
      icon: 'indoor'
    },
  ],
  wlt: {},
  muoto: {
    description: `Taulun muoto`,
    pyorea: 'Pyöreä',
    nelio: 'Neliö',
    suorakaide: 'Suorakaide'
  },
  koko: {
    description: `Taulun koko`
  }, 
  kehyksen_vari: {
    description: `Näkyvien osien viimeistely`
  },
  taulun_sisalto: {
    description: `Valitse materiaali taulun sisälle`,
    sammal: 'Sammal',
    jakala: 'Jäkälä',
    mix: 'Mix'
  },
}

export default koivu_vihertaulu