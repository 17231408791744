const home = {
  videoText: 'Green is Good.',
  infoBoxTitle1: 'Productintroduktion lorem ipsum dolor sit',
  infoBoxText1: 'SE Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.',
  infoBoxTitle2: 'SE Vuokraa monipuolista vihreyttä ilman kompromisseja',
  infoBoxText2: `SE Haluamme tehdä viihtyisyydestä mahdollisimman helppoa ja kestävää. 
      Vihreys palveluna sisältää kaiken mitä tarvitaan vehreään tilaan: suunnittelun, tuotteet, 
      kasvit, asennuksen ja ylläpidon.`,
  referenceTitle: 'SE Tähän joku napakka otsikko',
  infoBoxTitle3: 'SE InnoGreen ja vastuullisuus',
  infoBoxText3: `SE Missionamme on kehittää viherratkaisuja, jotka parantavat elämänlaatua 
      kaupunkiympäristössä. Siksi kestävän kehityksen mukaiset periaatteet sekä luontoa että 
      inhimillisyyttä korostavat arvot ovat toimintamme lähtökohta.`,
  infoBoxTitle4: 'SE Persoonallisia ratkaisuja vaativiin kohteisiin.',
  infoBoxText4: `SE Vuosien saatossa olemme toteuttaneet satoja upean uniikkeja kohteita 
      ja tartumme mielellämme kaikenlaisiin luovuutta vaativiin haasteisiin. 
      Jokaisen erikoiskohteen vihertoteutus alkaa vihersuunnitelmasta.`,
  infoBoxTitle5: 'SE Tähän joku napakka otsikko',
  infoBoxText5: `SE Lorem ipsum dolor sit amet`,
}

export default home