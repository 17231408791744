import './styles.scss'
import React from 'react'
import { useEffect } from 'react'
import LoadingScreen from '../../components/common/LoadingScreen'
import { setGlobalLoading } from '../../store/uiSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CustomHeader from '../../components/common/CustomHeader'
import { fetch_blog, fetch_blogs } from '../../store/asyncThunk'
import { selectBlogByUrl } from '../../store/blogSlice'
import { useTranslation } from 'react-i18next'
import BlogGroup from '../../components/BlogGroup'
import CustomImage from '../../components/common/CustomImage'
import ReactPlayer from 'react-player/youtube'
import CustomContactCard from '../../components/common/CustomContactCard'
import { ArrowLeftLong } from '../../components/Icons'
import InfoBox from '../../components/InfoBox'

const Blog = () => {
  const { year, month, slug } = useParams()
  const dispatch = useDispatch()
  const global_loading = useSelector((state) => state.ui.global_loading)
  const blog = useSelector(state => selectBlogByUrl(`/${year}/${month}/${slug}`)(state))
  const readAlsoBlogs = useSelector((state) => state.blog.readAlsoBlogs)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const language = useSelector((state) => state.user.language)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [year, month, slug])

  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      await dispatch(fetch_blog({year:year, month:month, slug:slug}))
      // Fetch 3 latest blogs except current reading blog if it is in the latest blog
      await dispatch(fetch_blogs({for_page: 'blog', quantity: 3, excluded_id: blog?.id, language: language }))
      dispatch(setGlobalLoading(false))
    })()
  }, [year, month, slug, language])

  const handleBlogsClick = () => {
    if (i18n.language === 'FI')
      navigate('/blogi')
    else if (i18n.language === 'EN')
      navigate('/blog')
  }

  if (global_loading) {
    return (
      <>
        <div className='blog-container'></div>
        <LoadingScreen />
      </>
    )
  }

  const getTranslatedText = (component) => {
    if (language) {
      if (language === 'FI') {
        return component.text
      } else if (language === 'EN') {
        if (component.text_en) {
          return component.text_en
        } else {
          return component.text
        }
      }
    } else {
      return component.text
    }
  }

  const getTranslatedTitle = (component) => {
    if (language) {
      if (language === 'FI') {
        return component.title
      } else if (language === 'EN') {
        if (component.title_en) {
          return component.title_en
        } else {
          return component.title
        }
      }
    } else {
      return component.title
    }
  }

  const getTranslatedBtnText = (component) => {
    if (language) {
      if (language === 'FI') {
        return component.btnText
      } else if (language === 'EN') {
        if (component.btnText_en) {
          return component.btnText_en
        } else {
          return component.btnText
        }
      }
    } else {
      return component.btnText
    }
  }

  const getTranslatedName = (component) => {
    if (language) {
      if (language === 'FI') {
        return component.name
      } else if (language === 'EN') {
        if (component.name_en) {
          return component.name_en
        } else {
          return component.name
        }
      }
    } else {
      return component.name
    }
  }

  return (
    <div className='blog-container'>
      <div className='blog-body-container'>
        <div className='blog-header-link no-select'>
          <div onClick={() => handleBlogsClick()}>
            <ArrowLeftLong /> {t('blog.headerTitle').toUpperCase()}
          </div>
        </div>
          { blog ? <CustomHeader {...blog} type='blog'/> : <div>{'Not found'}</div>}
          {
            blog && 
            (<div className='blog-text-container card'>
              <div className="ql-editor" dangerouslySetInnerHTML={{ __html: getTranslatedText(blog) }}></div>
            </div>)
          }
        {blog && blog.body.map((component, index) => {
          if (component.component === 'CustomTextInput') {
            return (
              <div className='blog-text-container' key={index}>
                <div className="ql-editor" dangerouslySetInnerHTML={{ __html: getTranslatedText(component) }}></div>
              </div>
            )
          }
          else if (component.component === 'CustomImageInput') {
            return (
              <div className='blog-image-container' key={index}>
                <CustomImage {...component} type='blog'/>
              </div>
            )
          }
          else if (component.component === 'ContactInput') {
            return (
              <div className='blog-contact-container' key={index}>
                <CustomContactCard 
                  name={getTranslatedName(component)}
                  email={component.email}
                  phone={component.phone}
                  imgSrc={component.imgSrc}
                  text={getTranslatedText(component)}
                  title={getTranslatedTitle(component)}
                />
              </div>
            )
          }
          else if (component.component === 'YoutubeVideo') {
            return (
              <div className='blog-video-container' key={index}>
                <div className='blog-video-wrapper'>
                  <ReactPlayer
                    className='blog-video-player'
                    url={component.videoUrl}
                    width='100%'
                    height='100%'
                  />
                </div>
              </div>
            )
          }
          else if (component.component === 'InfoBoxInput') {
            return (
              <React.Fragment key={index}>
                  <InfoBox
                    type='2'
                    title={getTranslatedTitle(component)}
                    text={getTranslatedText(component)}
                    buttonText={getTranslatedBtnText(component)}
                    link={component.btnLink}
                    imgSrc={component.imgSrc}
                    backgroundColor={component.bgColor}
                  />
              </React.Fragment>
            )
          }
        })}
      </div>
      <BlogGroup blogs={readAlsoBlogs} title={t('blog.readAlso')} linkText={t('blog.readAlsoLinkText')}/>
    </div>
  )
}

export default Blog
