const privacy = {
  GDPRcookiesShortDescription:
    'Our website uses cookies. We store personal information when you register and the information may be used, among other things, to subscribe to products and send newsletters. If you wish, you can refuse the use of cookies.',
  GDPRcookiesLongDescription:
    "Our website uses cookies. If you wish, you can reject the use of cookies.\n\nA cookie is a small text file that is sent to the user and stored on the user's device. Cookies are not harmful to the device or its other files.\n\nOur website uses both persistent and session cookies. Session cookies remain in the web browser's memory as long as the web browser is open, but are automatically destroyed when the browser is closed. Cookies that are used to detect returning registered users remain.\n\nWe also store personal information, such as email address, name, phone number and company name, from registered users. This information may be used to subscribe to products and send newsletters.",
  readOurPrivacy: 'Read our privacy policy.',
  labelLearnMore: 'Read more',
  labelShowLess: 'Show less',
  acceptCookiesBtn: 'Accept cookies',
  rejectBtn: 'Reject',
  cookiesTitle: 'Cookies',
  privacyTitle: 'Privacy and Personal Data Register Description',
  closeBtn: 'Close',
  privacyDescription: 'Privacy description here',
  link: 'link',
  privacySubTitle: 'Data Controller and Contact Information',
  labelController: 'Controller',
  labelContactPerson: 'Contact Person',
  labelEmail: 'Email',
  registerNameAndPurpose: 'Register Name and Purpose',
  registerName: 'Register Name',
  purpose: 'Purpose',
  registerNameDescription: 'InnoGreen Customer and Marketing Register.',
  purposeDescription:
    'Personal data is collected and processed for the following purposes:',
  purposeItem1: 'Providing services and managing customer relationships.',
  purposeItem2:
    'Marketing, including social media advertising, sending newsletters, and event invitations.',
  purposeItem3: 'Service development.',
  purposeItem4:
    'Analyzing website usage and targeting marketing through cookies.',
  collectedData: 'Collected Data',
  collectedDataDescription:
    'The following information may be stored in the register:',
  collectedDataItem1: 'First and last name.',
  collectedDataItem2:
    'Contact details (phone number, email address, postal address).',
  collectedDataItem3: 'Employer/company.',
  collectedDataItem4: 'Job title/position within the company.',
  collectedDataItem5:
    'Other information provided by the user (e.g., through contact forms).',
  collectedDataItem6:
    'Information collected from website usage: browsing history, IP address, and information collected through cookies (e.g., user interests and behavior on the website).',
  sourcesOfInformation: 'Sources of Information:',
  sourcesOfInformationItem1:
    'Information is primarily collected from the individual (e.g., via contact forms).',
  sourcesOfInformationItem2:
    'Information may be collected from public sources (e.g., company websites or social media profiles).',
  sourcesOfInformationItem3:
    'When visiting the website, browsing history and user behavior data are stored via cookies, which are notified through a separate cookie banner.',
  useOfCookies: 'Use of Cookies',
  useOfCookiesDescription1: 'Cookies are used for the following purposes:',
  useOfCookiesItem1: 'To improve the website experience.',
  useOfCookiesItem2: 'To analyze website usage and improve its functionality.',
  useOfCookiesItem3:
    "To target marketing and advertising based on user interests. Users can manage cookie settings through the website's cookie banner. Information collected through cookies is only linked to personal data if the user has given explicit consent.",
  useOfCookiesDescription2:
    "The user can manage cookie settings through the website's cookie window. Information regarding cookie collection will only be combined with personal data if the user gives separate consent.",
  dataDisclosureInternationalTransfers:
    'Data Disclosure and International Transfers',
  dataDisclosureInternationalTransfersDescription:
    'Personal data may be disclosed to third parties when necessary for the execution of services or for marketing purposes. Examples include:',
  dataDisclosureInternationalTransfersItem1:
    'Sending newsletters (e.g., through Mailchimp).',
  dataDisclosureInternationalTransfersItem2: 'Customer service.',
  dataDisclosureInternationalTransfersItem3: 'Targeted marketing.',
  transfersOutside: 'Transfers outside the European Economic Area (EEA):',
  transfersOutsideDescription:
    'In some cases, personal data may be transferred outside the EEA, for example, to the United States (e.g., when using Mailchimp services). In such cases, we ensure compliance with GDPR requirements by:',
  transfersOutsideItem1:
    'Using European Commission-approved Standard Contractual Clauses (SCC).',
  transfersOutsideItem2:
    'Implementing additional safeguards such as data encryption and access control.',
  transfersOutsideItem3:
    'Only necessary information is disclosed: Personal data shared with third parties is limited to what is necessary for the service or marketing purposes.',
  dataRetentionSecurity: 'Data Retention and Security',
  dataRetentionSecurityDescription1:
    'Personal data is retained only as long as necessary for the purpose of use or to fulfill legal obligations.',
  dataRetentionSecurityDescription2:
    'InnoGreen uses technical and organizational measures to protect personal data, including:',
  dataRetentionSecurityItem1: 'Data encryption and access control.',
  dataRetentionSecurityItem2:
    'Security procedures to prevent data loss, misuse, and alteration.',
  rightsOfDataSubject: 'Rights of the Data Subject',
  rightsOfDataSubjectDescription: 'The data subject has the following rights:',
  rightsOfDataSubjectItem1: 'You can request a copy of your personal data.',
  rightsOfDataSubjectItemLabel1: 'Right of access:',
  rightsOfDataSubjectItem2:
    'You can correct any inaccurate or incomplete data.',
  rightsOfDataSubjectItemLabel2: 'Right to rectification:',
  rightsOfDataSubjectItem3:
    'You can request the deletion of your data unless required by law or legal obligations to retain it.',
  rightsOfDataSubjectItemLabel3: 'Right to erasure:',
  rightsOfDataSubjectItem4:
    'You can object to the processing of your data, particularly for direct marketing purposes.',
  rightsOfDataSubjectItemLabel4: 'Right to object:',
  rightsOfDataSubjectItemLabel5:
    'Right to restrict processing and to data portability.',
  rightsOfDataSubjectItem6:
    'If you believe your data protection rights have been violated, you can file a complaint with the Data Protection Ombudsman.',
  rightsOfDataSubjectItemLabel6: 'Right to lodge a complaint:',
  changesPrivacy: 'Changes to the Privacy Policy',
  changesPrivacyDescription1:
    'This privacy policy may be updated due to changes in legislation or business operations.',
  changesPrivacyDescription2:
    'The most current version is available on our website at https://innogreen.fi.',
}

export default privacy
