const work = {
  headerTitle: 'Töihin InnoGreenille',
  introTitle: `Oletko haaveillut urasta viheralalla? InnoGreen tarjoaa 
      mahdollisuuden olla osa ainutlaatuista tiimiä, joka rakentaa 
      vihreämpää tulevaisuutta. Työllistämme noin 50 viheralan 
      ammattilaista toimipisteissämme Helsingissä, Turussa ja Tampereella. 
      Tiimimme koostuu vakituisista työntekijöistä, kausityöntekijöistä 
      ja viheralan uransa alussa olevista harjoittelijoista.`,
  introText: `Teemme päivittäin töitä sen eteen, että työntekijämme viihtyisivät, 
      kehittyisivät ja voisivat hyvin meillä työskennellessään. Haluamme, että 
      työntekijämme voivat olla ylpeitä työpaikastaan ja yhtenä tavoitteenamme 
      on olla alan vastuullisin työnantaja.
      \\n
      Tarjoamme turvallisen, tasavertaisen ja innostavan työympäristön, jossa 
      jokaisella on mahdollisuus kehittyä ja rakentaa uraa mielenkiintoisten 
      työtehtävien parissa. Tavoitteenamme on, että meille työllistyttyään 
      ei haluaisi koskaan lähteä.`,
  cards: [
    {
      title: '50',
      text: 'viheralan ammattilaista',

    },
    {
      title: '6 v.',
      text: 'nykyisten työntekijöidemme keskimääräinen työura',
    },
  ],
  introTitle2: `Miten pyrimme olemaan alan vastuullisin työnantaja?`,
  workCards: [
    {
      title: 'Työntekijämme ovat tärkein voimavaramme',
      text: `Tarjoamme kilpailukykyiset palkat, jotka perustuvat kokemukseen, 
          osaamiseen ja vastuuseen. Jokaiselle työntekijälle tarjotaan myös 
          modernit ja joustavat työsuhde-edut, jotka tukevat hyvinvointia, 
          jaksamista sekä työn ja yksityiselämän yhteensovittamista. 
          Rekrytoidessamme uusia työntekijöitä kerromme palkkahaitarin 
          avoimesti jo ilmoituksessa.
          \\n
          Vaikka toimimme kausiluontoisella alalla, pyrimme tarjoamaan 
          ympärivuotisia työsuhteita. Tämä tarkoittaa työntekijöillemme 
          varmuutta ja mahdollisuutta antoisaan ja pitkäjänteiseen työskentelyyn.`
    },
    {
      title: 'Olemme erilaisia ja siksi niin hyviä yhdessä',
      text: `Jokaisella työntekijällämme on mahdollisuus kehittyä oman osaamisensa 
          ja kiinnostuksensa pohjalta. Tarjoamme jatkuvasti koulutusta ja 
          kehittymismahdollisuuksia, jotta ammatillinen kasvu voi jatkua koko uran ajan.
          \\n
          Meille on tärkeää ylläpitää inhimillistä työkulttuuria, jossa jokainen kokee 
          tulevansa kuulluksi, nähdyksi ja arvostetuksi. Olemme matalahierarkinen 
          yritys, jossa tiimihenki, vuorovaikutus, tasavertaisuus ja yhdessä 
          tekeminen ovat avainasemassa työssä viihtymisessä ja yhteisessä menestyksessä. 
          Olemme sitoutuneita tukemaan monimuotoisuutta, tasa-arvoa sekä inklusiivisuutta. 
          Meillä jokainen saa olla oma itsensä.`
    },
  ],
  introTitle3: `Avoimet työpaikat`,
  introText3: `Mikäli meillä ei ole juuri nyt sinulle sopivaa paikkaa avoinna, laitathan rohkeasti avoimen hakemuksen osoitteeseen`,
  introEmail: 'rekry@innogreen.fi.',
  noAvailableJobs: 'Voi hitsi! Tällä hetkellä meillä ei ole avoimia työpaikkoja.',
  introTitle4: `Mutta millaista meillä on työskennellä? Annetaan tulevien kollegoidesi kertoa!`,
  employeeCard: [
    {
      employeeName: 'Kukka',
      title: 'viherkasvi\u00ADhuoltaja ja työsuojelu\u00ADvaltuutettu',
      text: `”InnoGreen on yksi parhaista työpaikoista, joissa olen työskennellyt. 
          Viherkasvihuollossa saan miltei päivittäin kokea onnistumisen tunteita 
          ja hyödyntää ongelmanratkaisutaitojani. On esimerkiksi hyvin palkitsevaa 
          huomata, kuinka sinnikäs hoito saa huonovointisenkin kasvin jälleen kukoistamaan!”`,
      yearText: 'InnoGreenillä vuodesta',
      year: '2016',
    },
    {
      employeeName: 'Leena',
      title: 'viher\u00ADsuunnittelija',
      text: `“Koen, että InnoGreen seisoo aidosti arvojensa takana ja on työpaikkana ollut 
          juuri sitä, mitä olin kuvitellut. Emme myy vain kasveja vaan myymme palvelukokonaisuutta 
          ja viherelämyksiä. Onnistunut toteutus vaatii meiltä jokaiselta huolellisuutta ja arvostan 
          suunnattomasti jokaisen InnoGreeniläisen osaamista. Täällä pidetään huolta työntekijöistä 
          ja kaikki ovat samalla viivalla. Kunnioitamme ja autamme toisiamme ja on aidosti hyvä meininki.”`,
      yearText: 'InnoGreenillä vuodesta',
      year: '2022',
    },
    {
      employeeName: 'Kristina',
      title: 'pihan huolto\u00ADpäällikkö',
      text: `”Nautin siitä, että työni on vaihtelevaa ja monipuolista - päivät voivat sisältää
           kaikkea istutusten suunnittelusta tilausten tekoon ja kasvien huoltoon sekä pihojen 
           ylläpitoon. Olen päässyt itse vaikuttamaan omaan työnkuvaani ja saanut toimia itsenäisesti. 
           Lempipuuhaani on kausikasvien suunnittelu, jossa saan käyttää luovuuttani ja soveltaa vuosien 
           kokemustani puutarha-alalta. InnoGreen on kannustava työnantaja ja meillä vallitsee ihana yhteishenki.”`,
      yearText: 'InnoGreenillä vuodesta',
      year: '2019',
    },
    {
      employeeName: 'Teijo',
      title: 'varaston esihenkilö',
      text: `”Aloin opiskelemaan viheralaa vuonna 1998 ja kasvien sielunelämä vei minut heti mennessään. 
          Kasvien psykologinen vaikutus ihmisiin on kiistaton - tunnemme itsemme rentoutuneemmaksi vehreyden 
          keskellä, oli se sitten töissä, kotona, metsässä tai vaikka kauppakeskuksessa. Varastoteknikkona 
          ja asentajana työskentelyssä parasta on työn monipuolisuus. Jokainen työviikko on erilainen - 
          hektistä ja liikkuvaa työtä tasapainottaa kasvihuoneellamme vietetyt päivät, jolloin saan keskittyä 
          kasvien istuttamiseen ja muihin valmisteleviin töihin.”`,
      yearText: 'InnoGreenillä vuodesta',
      year: '2018',
    },
    {
      employeeName: 'Kirsi',
      title: 'vihersisustaja',
      text: `”Työni on vauhdikasta ja vaihtelevaa - se sisältää sopivassa suhteessa luovaa ideointia sekä 
          säntillistä rutiinityötä. Koen suurta onnistumisen tunnetta kun saan asiakkaat innostumaan 
          vihersisustuksesta ja havahtumaan siihen, kuinka suuri vaikutus kasveilla on tilan viihtyvyyteen. 
          Palkitsevinta työssäni on yhteistyö asiakkaan, työkavereiden ja yhteistyökumppaneiden kanssa - 
          parhaat ideat syntyvät ryhmässä ja lopputulos on aina parempi kuin yksin puurtaessa.”`,
      yearText: 'InnoGreenillä vuodesta',
      year: '2014',
    }
  ]
}

export default work