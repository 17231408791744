import unidecode from "unidecode"

// Used for specific product translations
export function get_decoded_string(string) {
  let decoded_string = unidecode(string)
  if (decoded_string) {
    return decoded_string
      .toLowerCase()
      .replace(/^\d+\. /, "")           // Remove 1. 2. 3. from customization name
      .replace(/[^a-z0-9]+/g, '_')      // Replace non-alphanumeric characters with _
      .replace(/^_+|_+$/g, '')          // Remove any leading or trailing underscores
  }
}
