import './styles.scss'
import { useLocation } from 'react-router-dom'

const AppLogo = (props) => {
  const {
    color,
    type,
    height
  } = props
  const location = useLocation()
  let colorClass = 'uuid-2533be24-689e-4b2f-8f4d-a0ff065657fa'

  if (color === 'white') {
    colorClass += ' white'
  }
  if (color === 'black') {
    colorClass += ' black'
  }
  if (location && location.pathname !== '/') {
    colorClass += ' black'
  }
  if (type === 'footer') {
    colorClass = 'uuid-2533be24-689e-4b2f-8f4d-a0ff065657fa white'
  }

  return (
    <svg 
      id="uuid-e93ef246-e160-49b2-a421-c1bb712367c1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 488 77.52"
      height={height}
    >
      <path className={colorClass} d="M0,53.76V1.39h12.62v52.37H0Z"/>
      <path className={colorClass} d="M61.72,53.76v-27.25c0-5.02-1.2-8.73-3.61-11.14s-5.62-3.61-9.64-3.61-7.25,1.2-9.65,3.61-3.61,6.12-3.61,11.14v27.25h-12.62v-27.25c0-4.39.62-8.22,1.85-11.51,1.23-3.28,2.98-6.05,5.25-8.27,2.26-2.22,4.99-3.91,8.16-5.04C41.04.57,44.57.01,48.46.01s7.42.55,10.6,1.64c3.18,1.1,5.9,2.75,8.16,4.99,2.26,2.22,4.02,5,5.25,8.32s1.85,7.17,1.85,11.56v27.25h-12.62.01Z"/>
      <path className={colorClass} d="M122.57,53.76v-27.25c0-5.02-1.2-8.73-3.61-11.14-2.4-2.4-5.62-3.61-9.64-3.61s-7.25,1.2-9.65,3.61-3.61,6.12-3.61,11.14v27.25h-12.62v-27.25c0-4.39.62-8.22,1.85-11.51,1.24-3.28,2.98-6.05,5.25-8.27,2.26-2.22,4.99-3.91,8.16-5.04C101.89.57,105.43.01,109.31.01s7.42.55,10.6,1.64c3.18,1.1,5.9,2.75,8.16,4.99,2.26,2.22,4.02,5,5.25,8.32,1.23,3.32,1.85,7.17,1.85,11.56v27.25h-12.62.01Z"/>
      <path className={colorClass} d="M199.07,27.58c0,3.96-.69,7.63-2.06,11.03-1.38,3.39-3.32,6.31-5.83,8.74-2.51,2.44-5.5,4.35-8.96,5.73-3.46,1.38-7.25,2.06-11.35,2.06s-7.97-.69-11.4-2.06c-3.42-1.38-6.4-3.28-8.9-5.73-2.51-2.43-4.46-5.36-5.83-8.74-1.38-3.39-2.06-7.07-2.06-11.03s.69-7.63,2.06-11.03c1.38-3.39,3.32-6.31,5.83-8.75s5.48-4.35,8.9-5.73S166.7.01,170.87.01s7.88.69,11.35,2.06c3.46,1.38,6.45,3.28,8.96,5.73,2.5,2.44,4.46,5.36,5.83,8.75,1.38,3.39,2.06,7.07,2.06,11.03ZM170.86,43.38c4.67,0,8.39-1.46,11.19-4.4s4.19-6.73,4.19-11.4-1.39-8.46-4.19-11.4-6.52-4.4-11.19-4.4-8.41,1.47-11.24,4.4c-2.82,2.93-4.25,6.73-4.25,11.4s1.41,8.46,4.25,11.4c2.82,2.93,6.57,4.4,11.24,4.4Z"/>
      <path className={colorClass} d="M250.38,45.81h-.21c-1.69,2.68-4.05,4.81-7.05,6.36-3,1.55-6.49,2.33-10.44,2.33-3.11,0-6.22-.53-9.33-1.59-3.11-1.06-5.89-2.7-8.32-4.93-2.44-2.22-4.42-5.02-5.94-8.37s-2.28-7.37-2.28-12.04c0-3.96.69-7.63,2.07-11.03s3.32-6.31,5.83-8.75,5.48-4.35,8.9-5.73S230.83.01,235,.01s7.88.67,11.35,2.01,6.42,3.23,8.85,5.67c2.44,2.44,4.35,5.37,5.73,8.8s2.06,7.26,2.06,11.51v21.63c0,5.02-.73,9.29-2.17,12.83-1.45,3.53-3.43,6.41-5.94,8.64-2.51,2.22-5.46,3.86-8.85,4.88-3.39,1.02-7,1.54-10.82,1.54-3.25,0-6.31-.35-9.17-1.06-2.86-.71-5.46-1.8-7.79-3.28-2.33-1.48-4.32-3.35-5.94-5.62s-2.86-4.95-3.72-8.06h12.73c1.06,2.47,2.79,4.28,5.2,5.41,2.4,1.13,5.3,1.69,8.69,1.69,4.67,0,8.36-1.24,11.08-3.71,2.72-2.47,4.09-6.75,4.09-12.83v-4.24h0ZM250.38,27.58c0-4.67-1.39-8.46-4.19-11.4-2.79-2.93-6.52-4.4-11.19-4.4s-8.41,1.47-11.24,4.4c-2.82,2.93-4.25,6.73-4.25,11.4s1.41,8.36,4.25,11.08c2.82,2.72,6.58,4.09,11.24,4.09s8.39-1.36,11.19-4.09,4.19-6.41,4.19-11.08Z"/>
      <path className={colorClass} d="M384.68,32.03v.21c.85,3.53,2.56,6.33,5.14,8.37,2.58,2.05,5.85,3.07,9.8,3.07,5.66,0,9.79-1.66,12.41-4.99h13.47c-1.98,5.02-5.24,9.01-9.75,11.98-4.53,2.96-9.89,4.46-16.11,4.46-4.18,0-7.97-.69-11.4-2.06-3.42-1.38-6.4-3.28-8.9-5.73-2.51-2.43-4.46-5.36-5.83-8.74-1.38-3.39-2.06-7.07-2.06-11.03s.69-7.63,2.06-11.03c1.38-3.39,3.32-6.31,5.83-8.75s5.48-4.35,8.9-5.73S395.46.01,399.63.01s7.88.69,11.35,2.06c3.46,1.38,6.42,3.28,8.85,5.73,2.44,2.44,4.35,5.36,5.73,8.75s2.07,7.07,2.07,11.03v4.46h-42.95ZM399.63,11.47c-3.75,0-6.88.92-9.38,2.75-2.51,1.84-4.26,4.35-5.25,7.53v.21h29.26v-.21c-.99-3.18-2.75-5.69-5.3-7.53-2.54-1.83-5.66-2.75-9.33-2.75Z"/>
      <path className={colorClass} d="M475.4,53.76v-27.25c0-5.02-1.2-8.73-3.6-11.14-2.4-2.4-5.62-3.61-9.65-3.61s-7.25,1.2-9.65,3.61c-2.4,2.4-3.61,6.12-3.61,11.14v27.25h-12.62v-27.25c0-4.39.62-8.22,1.85-11.51s2.98-6.05,5.25-8.27c2.26-2.22,4.99-3.91,8.16-5.04,3.18-1.13,6.71-1.69,10.6-1.69s7.42.55,10.6,1.64c3.18,1.1,5.9,2.75,8.16,4.99,2.26,2.22,4.02,5,5.25,8.32s1.85,7.17,1.85,11.56v27.25h-12.62.02Z"/>
      <path className={colorClass} d="M321.81,32.03v.21c.85,3.53,2.56,6.33,5.15,8.37,2.58,2.05,5.85,3.07,9.8,3.07,5.66,0,9.79-1.66,12.41-4.99h13.47c-1.98,5.02-5.23,9.01-9.75,11.98-4.53,2.96-9.89,4.46-16.11,4.46-4.18,0-7.97-.69-11.4-2.06-3.43-1.38-6.4-3.28-8.9-5.73-2.51-2.43-4.46-5.36-5.83-8.74-1.38-3.39-2.06-7.07-2.06-11.03s.69-7.63,2.06-11.03c1.38-3.39,3.32-6.31,5.83-8.75s5.48-4.35,8.9-5.73,7.23-2.07,11.4-2.07,7.88.69,11.35,2.07,6.42,3.28,8.85,5.73c2.43,2.44,4.35,5.36,5.73,8.75s2.07,7.07,2.07,11.03v4.46h-42.96ZM336.76,11.47c-3.75,0-6.88.92-9.38,2.75-2.51,1.84-4.26,4.35-5.25,7.53v.21h29.26v-.21c-.99-3.18-2.75-5.69-5.3-7.53-2.54-1.83-5.66-2.75-9.33-2.75Z"/>
      <path className={colorClass} d="M302.14,11.78h-3.51c-4.03,0-7.25,1.2-9.65,3.61-2.4,2.4-3.61,6.12-3.61,11.14v27.25h-12.62v-27.25c0-4.39.62-8.22,1.85-11.51s2.98-6.05,5.25-8.27c2.26-2.22,4.99-3.91,8.16-5.04,3.18-1.13,6.71-1.69,10.6-1.69h3.51v11.77h.01Z"/>
    </svg>
  )
}

export default AppLogo
