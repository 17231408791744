import './styles.scss'

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import UserPieChart from '../Dashboard/UserPieChart'
import AssignedInquiry from './AssignedInquiry'

import {
  Paper,
  Button,
  Popper,
  MenuItem,
  ClickAwayListener,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material"
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';

import {
  edit_user,
  fetch_user_assigned_inquiry,
  fetch_user,
} from '../../../../store/asyncThunk'


const role_options = [
  {
    name: 'Admin',
    id: 1,
  },
  {
    name: 'Sales Director',
    id: 3,
  },
  {
    name: 'Sales Person',
    id: 4,
  },
  {
    name: 'Editor',
    id: 5,
  },
  {
    name: 'User',
    id: 2,
  },
]

const status_options = [
  {
    name: 'Active',
    id: 1
  },
  {
    name: 'Inactive',
    id: 2
  },
]

const User = (props) => {
  const {
    setSelected,
    assignableUsers,
    handleSetSelectedTab,
    handleSetSelectedSelection,
  } = props
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const user_id = searchParams.get('user');
  const user = useSelector((state) => state.management.selected_user)
  const current_user = useSelector((state) => state.user.current_user)
  const assigned_inquiry = useSelector((state) => state.management.user_assigned_inquiries)
  const anchorRefRole = useRef(null)
  const anchorRefStatus = useRef(null)
  const [openDropdownRole, setOpenDropdownRole] = useState(false)
  const [openDropdownStatus, setOpenDropdownStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (user === null) {
      (async () => {
        await dispatch(fetch_user(parseInt(user_id)))
      })()
    }
  }, [user_id, user]); 

  useEffect(() => {
    if (user) {
      if ([1, 3, 4].includes(user.role)) {
        (async () => {
          setIsLoading(true)
          await dispatch(fetch_user_assigned_inquiry(user.id))
          setIsLoading(false)
        })()
      }
    }
  }, [user])

  const handleClickRole = () => {
    setOpenDropdownRole(true) // Toggle dropdown visibility
  }

  const handleCloseRole = () => {
    setOpenDropdownRole(false) // Close the dropdown
  }

  const handleClickStatus = () => {
    setOpenDropdownStatus(true) // Toggle dropdown visibility
  }

  const handleCloseStatus = () => {
    setOpenDropdownStatus(false) // Close the dropdown
  }

  const handleUserStatus = async (user) => {
    if (user) {
      setOpenDropdownStatus(false)
      await dispatch(
        edit_user({
          id: user.id,
          status: user.status === 1 ? 2 : 1,
        })
      )
    }
  }

  const handleUserRole = async (user, role_item) => {
    if (user) {
      setOpenDropdownRole(false)
      await dispatch(
        edit_user({
          id: user.id,
          role: role_item.id,
        })
      )
    }
  }

  const get_role= (role) => {
    switch (role) {
      case 1:
        return 'Admin'
      case 2:
        return 'User'
      case 3:
        return 'Sales Director'
      case 4:
        return 'Sales Person'
      case 5:
        return 'Editor'
      default:
        return 'Unknown'
    }
  }

  const get_status = (status) => {
    switch (status) {
      case 1:
        return 'Active'
      case 2:
        return 'Inactive'
    }
  }

  const get_email_subscribe = (subscribe) => {
    switch (subscribe) {
      case true:
        return 'True'
      case false:
        return 'False'
    }
  }

  if (current_user && ![1, 3].includes(current_user.role)) {
    return (
      <div>You do not have access to this</div>
    )
  }

  return (
    <>
      { user && (
        <div className='user-container'>
          <div className='user-header'>
            User
          </div>
          <div className='user-bar'>
            <div className='user-bar-text'>
              {`Name: ${user.name}`}
            </div>
            <div className='user-bar-text'>
              Role:
              <Button
                  ref={anchorRefRole}
                disableRipple
                onClick={(e) => {
                  e.stopPropagation()
                  handleClickRole()
                }}
                sx={{ textAlign: 'left', padding: '0px', marginLeft: '5px' }}
                className='user-bar-role'
              >
    
                {get_role(user.role)}
              </Button>
              <Popper
                open={openDropdownRole}
                anchorEl={anchorRefRole.current}
                placement="bottom"
              >
                <ClickAwayListener
                  onClickAway={handleCloseRole}
                >
                  <Paper sx={{ p: 1 }}>
                    { 
                      role_options.map((option) => (
                        <MenuItem
                          key={option.id}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleUserRole(user, option)
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))
                    }
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
            <div 
              className='user-bar-status'
            >
              Status:
              <Button
                  ref={anchorRefStatus}
                disableRipple
                onClick={(e) => {
                  e.stopPropagation()
                  handleClickStatus()
                }}
                sx={{ textAlign: 'left', padding: '0px', marginLeft: '5px' }}
                className='user-bar-role'
              >
    
                {get_status(user.status)}
              </Button>
              <Popper
                open={openDropdownStatus}
                anchorEl={anchorRefStatus.current}
                placement="bottom"
              >
                <ClickAwayListener
                  onClickAway={handleCloseStatus}
                >
                  <Paper sx={{ p: 1 }}>
                    { 
                      status_options.map((option) => (
                        <MenuItem
                          key={option.id}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleUserStatus(user)
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))
                    }
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
          </div>
          <div className='user-content'>
            <div className='user-content-left'>
              <div className='user-content-left-text-wrapper'>
                <div className='inquiry-content-left-text-item'>
                  <div className='inquiry-content-left-text-label'>Email:</div>
                  <div className='inquiry-content-left-text'>
                    {user.email || 'N/A'}
                  </div>
                </div>
                <div className='inquiry-content-left-text-item'>
                  <div className='inquiry-content-left-text-label'>Company:</div>
                  <div className='inquiry-content-left-text'>
                    {user.company || 'N/A'}
                  </div>
                </div>
              </div>
              <div className='user-content-left-text-wrapper'>
                <div className='inquiry-content-left-text-item'>
                  <div className='inquiry-content-left-text-label'>Phone:</div>
                  <div className='inquiry-content-left-text'>
                    {user.phone_number || 'N/A'}
                  </div>
                </div>
                <div className='inquiry-content-left-text-item'>
                  <div className='inquiry-content-left-text-label'>Email Subscription:</div>
                  <div className='inquiry-content-left-text'>
                    {get_email_subscribe(user.email_subscribe) || 'N/A'}
                  </div>
                </div>
              </div>
            
          </div>
            {/* PICE CHART FOR ADMIN/SALES DIRECTOR/SALES PERSON */}
            <div className='user-content-right'>
              { user && [1, 3, 4].includes(user.role) && (
                  <UserPieChart user_id={user.id}/>
                )
              }
            </div>
          </div>
    
          {/* ASSIGNED INQUIRIES */}
          { user && [1, 3, 4].includes(user.role) && (
            <>
              { assigned_inquiry.length > 0 && (
                <AssignedInquiry
                  isLoading={isLoading}
                  assignableUsers={assignableUsers}
                  setSelected={setSelected}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                  phone={false}
                  company={true}
                  city={true}
                  name={true}
                  message={false}
                  size='small'
                  title={`Assigned Inquiries (${assigned_inquiry.length})`}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default User