import './styles.scss'
import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ArrowSimple } from '../Icons'
import CustomButton from '../common/CustomButton'

const InfoBox = (props) => {
  const { 
    title = '',
    text = '',
    imgSrc = '',
    link = '',
    linkText = '',
    buttonText = '',
    reverse = false,
    type = '1',
    containerClass = '',
    backgroundColor,
    titleSize,
    customLastBullet,
    bulletTitle,
    reversedMobile = false,
    onClickHandler
  } = props
  const { t } = useTranslation()
  const navigate = useNavigate()


  const handleLinkClick = () => {
  
    if (link.startsWith("http://") || link.startsWith("https://")) {
      window.open(link, "_blank")
    } else {
      navigate(`/${link}`)
    }
  }

  let infoBoxContainerClass = 'info-box-body-container'
  let infoBoxLeftClass = 'info-box-left'
  let infoBoxRightClass = 'info-box-right'
  let textClass = ''
  let backgroundColorClass = ''
  let titleSizeClass = ''

  if (reverse == true) {
    infoBoxContainerClass += ' reverse'
    infoBoxLeftClass += ' reverse'
    infoBoxRightClass += ' reverse'
  }

  if (type == '2') {
    infoBoxLeftClass += ' type-2'
    textClass += ' type-2'
    infoBoxContainerClass += ' type-2'
  }

  if (backgroundColor) {
    backgroundColorClass = ` ${backgroundColor}` 
  }

  if (titleSize) {
    titleSizeClass += ` ${titleSize}`
  }

  if (imgSrc) {
    if (bulletTitle) {
      return (
        <div className={`info-box-container ${containerClass}`}>
          <div className={infoBoxContainerClass}>
            <div className={infoBoxLeftClass + backgroundColorClass}>
              {title ? (
                <div className={'info-box-title' + titleSizeClass}>{title}</div>
              ) : (
                <></>
              )}
              <div className={ 'info-box-text' + textClass }>
                { Array.isArray(text) ?
                    (text.map((line, index) => {
                      return  (
                        <div className='info-box-text-container' key={index + 1}>
                          <div className='info-box-numbered-container'>
                            <div className='info-box-numbered'>{index + 1}</div>
                          </div>
                          <div className='info-box-numbered-title-container'>
                            <div className='info-box-numbered-title'>{t(line.title)}</div>
                            <div className='info-box-numbered-text'>{t(line.text)}</div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    text.split('\\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                      <br />
                    </React.Fragment>
                  )))
                }
              </div>
              {buttonText ?
                 <CustomButton
                  button_text={buttonText.toUpperCase()}
                  yellow={true}
                  icon={<ArrowSimple strokeWidth={0.35}/>}
                  contentClass='info-box'
                  onClickHandler={handleLinkClick}
                  type='info-box'
                />
              :
                <></>
              }
              { linkText ?
                (
                  <a 
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {linkText}
                  </a>
                ) : (
                  <></>
                )
              }
            </div>
            <div className={infoBoxRightClass}>
              <img className={type == '2' ? 'type-2' : ''} src={imgSrc} alt="InfoImg" />
            </div>
          </div>
        </div>
        
      )
    } else {
      return (
          <div className={`info-box-container ${containerClass}`}>
            <div className={infoBoxContainerClass}>
              <div className={infoBoxLeftClass + backgroundColorClass}>
                {title ? (
                  <div className={'info-box-title' + titleSizeClass}>{title}</div>
                ) : (
                  <></>
                )}
                {
                  text ?
                  (
                    <div className={'info-box-text' + textClass }>
                    { Array.isArray(text) ?
                        (text.map((line, index) => {
                          if (text.length == index + 1) {
                            if (customLastBullet) {
                              return  <div className='info-box-text-container custom' key={index + 1}>
                                        {t(line)}
                                      </div>
                            } 
                            return  <div className='info-box-text-container' key={index + 1}>
                                      <div className='info-box-numbered-container'>
                                        <div className='info-box-numbered'>+</div>
                                      </div>
                                      <div className='info-box-numbered-container'>
                                        <div className='info-box-numbered-text'>{t(line)}</div>
                                      </div>
                                    </div>
                          } else {
                            return  <div className='info-box-text-container' key={index + 1}>
                                      <div className='info-box-numbered-container'>
                                        <div className='info-box-numbered'>{index + 1}</div>
                                      </div>
                                      <div className='info-box-numbered-container'>
                                        <div className='info-box-numbered-text'>{t(line)}</div>
                                      </div>
                                    </div>
                          }
                        })
                      ) : (
                        text.split('\\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                          <br />
                        </React.Fragment>
                      )))
                    }
                  </div>
                  ) : (
                    <></>
                  )
                }
                {buttonText ?
                   <CustomButton
                    button_text={buttonText.toUpperCase()}
                    yellow={true}
                    icon={<ArrowSimple strokeWidth={0.35}/>}
                    contentClass='info-box'
                    onClickHandler={handleLinkClick}
                    type='info-box'
                  />
                :
                  <></>
                }
              </div>
              <div className={infoBoxRightClass}>
                <img className={type == '2' ? 'type-2' : ''} src={imgSrc} alt="InfoImg" />
              </div>
            </div>
          </div>
      )
    }
  } else {
    return (
        <div className={`info-box-container ${containerClass}`}>
          <div className={`${infoBoxContainerClass}${reversedMobile ? ' reversed' : ''}`}>
            <div className={`${infoBoxLeftClass} ${backgroundColorClass}${reversedMobile ? ' reversed' : ''}`}>
              {title ? (
                <div className={'info-box-title' + titleSizeClass}>{title}</div>
              ) : (
                <></>
              )}

              {buttonText ?
                 <CustomButton
                  button_text={buttonText.toUpperCase()}
                  yellow={true}
                  icon={<ArrowSimple strokeWidth={0.35}/>}
                  contentClass='info-box'
                  onClickHandler={handleLinkClick}
                  type='info-box'
                />
              :
                <></>
              }
            </div>
            <div className={infoBoxRightClass}>
            <div className={`info-box-text-no-image ${textClass}${reversedMobile ? ' reversed' : ''}`}>
                { Array.isArray(text) ?
                    (text.map((line, index) => {
                      if (text.length == index + 1) {
                        return  <div className='info-box-text-container' key={index + 1}>
                                  <div className='info-box-numbered-container'>
                                    <div className='info-box-numbered'>+</div>
                                  </div>
                                  <div className='info-box-numbered-container'>
                                    <div className='info-box-numbered-text'>{t(line)}</div>
                                  </div>
                                </div>
                      } else {
                        return  <div className='info-box-text-container' key={index + 1}>
                                  <div className='info-box-numbered-container'>
                                    <div className='info-box-numbered'>{index + 1}</div>
                                  </div>
                                  <div className='info-box-numbered-container'>
                                    <div className='info-box-numbered-text'>{t(line)}</div>
                                  </div>
                                </div>
                      }
                    })
                  ) : (
                    text.split('\\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                      <br />
                    </React.Fragment>
                  )))
                }
              </div>
            </div>
          </div>
        </div>
    )
  }


}


export default InfoBox
