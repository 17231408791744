const account = {
  hello: 'SE Hei',
  updateUserInformation: 'SE Päivitä tietosi',
  logout: 'SE Kirjaudu ulos',
  yourProjects: 'SE Projektisi',
  createNewProject: 'SE Luo uusi projekti',
  searchProject: 'SE Etsi projekti',
  projectName: 'SE Projektin nimi',
  numberOfProducts: 'SE Tuotteiden määrä',
  dateModified: 'SE Muokattu pvm.',
  status: 'SE Tila',
  product: 'SE Tuote',
  products: 'SE Tuotteet',
  open: 'SE Avoin',
  pending: 'SE Odottaa',
  inquirySent: 'SE Tarjouspyyntö lähetetty',
  unknown: 'SE Tuntematon',
  projectName: 'SE Projektin nimi',
  projectDescription: 'SE Projektin kuvaus',
  create: 'SE Luo',
  cancel: 'SE Peruuta',
  save: 'Save',
  emailList: 'InnoGreen email list',
  genericUserUpdateError: 'Failed to update user information, please try again!',
  genericCreateProjectError: 'Failed to create prject, please try again!',
  createProjectSuccessMessage: 'Created new project!',
  close: 'Close',
  userUpdateSuccessMessage: 'Updated user information!',
}

export default account