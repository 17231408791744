const products = {
  home_page_product_showcase: 'SE Esittelyssä',
  koivu_page_related_products: 'SE Related products',
  green_wall_page_related_products: 'SE Related products',
  green_furniture_page_related_products: 'SE Related products',
  reference_page_related_products: 'SE Projektiin liittyvät tuotteet',
  referencePageLinkText: 'SE Katso Kaikki tuotteet',
  greenFurniturePageLinkText: 'SE Katso Kaikki tuotteet',
  greenWallPageLinkText: 'SE Katso Kaikki tuotteet',
  homePageLinkText: 'SE Katso Kaikki tuotteet',
  koivuPageLinkText: 'SE Katso Kaikki tuotteet',
  noProductsFound: 'SE No Products Found',
  // Title for Card links
  ourSolutions: 'SE Tutustu ratkaisuihimme',
  // InfoBox
  infoBoxTitle: 'SE Etkö löytänyt etsimääsi?',
  infoBoxText: 'SE Oliko mielessäsi jokin tietynlainen ruukku? Vai kaipaako tilasi erikoisempaa ratkaisua? Vuosien saatossa olemme toteuttaneet satoja upean uniikkeja kohteita ja tartumme mielellämme kaikenlaisiin luovuutta vaativiin haasteisiin. Erikoiskohteiden toteutus alkaa ammattilaisen laatimalla vihersuunnitelmalla.',
  infoBoxBtnText: 'SE Räätälöidyt ratkaisut',
  // Filter Butttons
  filter: 'SE SUODATA',
  new: 'SE UUTUDET JA AJANKOHTAISET',
  pots: 'SE RUUKUT',
  greenWalls: 'SE VIHERSEINÄT',
  mossAndLichen: 'SE SAMMAL & JÄKÄLÄ',
  greenDecor: 'SE VIHERKALUSTEET',
  inDoor: 'SE SISÄLLE',
  outDoor: 'SE ULOS',
  clear: 'SE TYHJENNÄ',
  // Product groups
  koivu_ruukut: 'SE Koivu-ruukut',
  viherseinat: 'SE Viherseinät',
  sammal_jakala: 'SE Sammal & jäkälä',
  viherkalusteet: 'SE Viherkalusteet',
  uutuudet_ja_ajankohtaiset: 'SE Uutuudet ja ajankohtaiset',
  // Product Customizations
  customize: 'SE Customize:',
  malli: 'SE Malli',
  ruukun_vari: 'SE Ruukun väri',
  jalat: 'SE Jalat',
  kehyksen_vari: 'SE Kehyksen väri',
  taustamateriaali: 'SE Taustamateriaali',
  leveys: 'SE Leveys',
  korkeus: 'SE Korkeus',
  muoto: 'SE Muoto',
  koko: 'SE Koko',
  taulun_sisalto: 'SE Taulun sisältö',
  kustomointi: 'SE Kustomointi',
  perusmoduulit: 'SE Perusmoduulit',
  viherseinamoduulit: 'SE Viherseinämoduulit',
  viherseinamoduulin_lisaosat: 'SE Viherseinämoduulin lisäosat',
  // Product Customization Options
  ruukun_vari_options: {
    luonnollinen: 'SE Luonnollinen',
    valkoinen: 'SE Valkoinen',
    musta: 'SE Musta',
    erikoisvari: 'SE Erikoisväri'
  },
  jalat_options: {
    korkeat_jalat: 'SE Korkeat jalat',
    tassut: 'SE Tassut',
    pyorat: 'SE Pyörät'
  },
  kehyksen_vari_options: {
    luonnollinen: 'SE Luonnollinen',
    valkoinen: 'SE Valkoinen',
    musta: 'SE Musta',
    erikoisvari: 'SE Erikoisväri'
  },
  taustamateriaali_options: {
    sammal: 'SE Sammal',
    jakala: 'SE Jäkälä',
    sekoitus: 'SE Sekoitus',
  },
  koko_options: {
    pyorea_s: 'S',
    pyorea_m: 'M',
    pyorea_l: 'L',
    nelio_s: 'S',
    nelio_m: 'M',
    nelio_l: 'L',
    suorakaide_l: 'L',
    suorakaide_xl: 'XL',
  },
  korkeus_options: {
    k210_cm: '210 cm',
    k227_cm: '227 cm',
    k243_cm: '243 cm',
  },
  leveys_options: {
    l120_cm: 'SE 120 cm',
    l180_cm: 'SE 180 cm'
  },
  malli_options: {
    p_380: 'SE P-380',
    p_380xl: 'SE P-380XL',
    p_445: 'SE P-445',
    p_550: 'SE P-550',
    b_665: 'SE B-665',
    t_1020: 'SE T-1020',
    t_1020xl: 'SE T-1020XL',
    tilanjakaja: 'SE Tilanjakaja',
    seinalle_sijoitettava: 'SE Seinälle sijoitettava'
  }, 
  muoto_options: {
    pyorea: 'SE Pyöreä',
    nelio: 'SE Neliö',
    suorakaide: 'SE Suorakaide'
  }, 
  taulun_sisalto_options: {
    sammal: 'SE Sammal',
    jakala: 'SE Jäkälä',
    mix: 'SE Mix'
  },
  // Buttons
  addToProject: 'SE LISÄÄ PROJEKTIIN',
  sendInquiry: 'SE YHTEYDENOTTOPYYNTÖ'
}

export default products