const company = {
  headerGroup: 'SE Yritys',
  headerTitle: 'SE Kestävää vihreyttä',
  cardTitle: 'SE Vihreydestä hyvinvointia',
  cards: [
    {
      title: '3,7 M€',
      text: 'liikevaihto 2024 päättyneenä tilikautena',
    },
    {
      title: '50',
      text: 'SE viheralan ammattilaista',
    },
    {
      title: 'SE yli 600',
      text: 'SE sopimusasiakasta',
    },
    {
      title: '25+',
      text: 'SE omaa tuoteinnovaatiota',
    },
    {
      title: '15 000',
      text: 'SE vuosittaista viherkasvihuollon käyntiä'
    },
    {
      title: '2 000 000 m²',
      text: 'SE Suojeltua sademetsää yhteistyössä <link> kanssa.',
      linkText: 'SE World Land Trustin',
      link: 'https://www.worldlandtrust.org/',
    },
  ],
  textTitle: `SE InnoGreeniltä moi! Meillä on suuria visioita, mutta 
      yritetään pitää tämä ytimekkäänä.`,
  introText: `SE Kaikki alkoi aikanaan sammakoista – kyllä, kuulit oikein. 
      Kehitimme ensimmäisen viherseinämme, kun huomasimme, että sammakot 
      viihtyivät viherseinien läheisyydessä. Mietimme, että mikseivät 
      ihmisetkin viihtyisi. Viilasimme ja kehitimme viherseinäämme lisää, 
      ja vehreys alkoi levitä toimitilasta ja kaupungista toiseen tuoden 
      ihmisten luokse viihtyisyyttä yksi viherseinä kerrallaan. 
      Viherseinät olivat kuitenkin vasta alkua, ja luontevana seuraavana 
      askeleena yhdistimme voimat pitkäaikaisen yhteistyökumppanin 
      Innogreenin kanssa. Siitä lähtien olemme jatkaneet nimellä InnoGreen, 
      pitäen palasen Green House Effectiä nimessä mukana.
      \\n
      Muutama vuosi myöhemmin saimme joukkoomme myös Vihermaailman ja Luvin 
      huippuasiantuntijat, ja ammattitaitoinen porukkamme kasvoi entisestään. 
      Yhteinen intohimo luontoa ja hyvinvointia kohtaan on pitänyt meidät 
      kirjaimellisesti kädet mullassa. Oli selvää, että tavallinen 
      viherkasvibisnes ei riittäisi meille, vaan halusimme ajatella isommin, 
      vihreämmin ja kestävämmin.
      \\n
      Mitä me siis nykyään teemme? Kehitämme urbaanin vihreyden ratkaisuja 
      yksinkertaisesta mutta tärkeästä syystä: haluamme parantaa elämänlaatua 
      vihreyden avulla. Kehittämämme viherratkaisut tukevat kaupunkiympäristöä, 
      edistävät ihmisten hyvinvointia – ja näyttävät samalla todella upeilta. 
      Haluamme tehdä osamme kestävämmän tulevaisuuden rakentamiseksi ja 
      tavoitteenamme on olla viheralan vastuullisin kumppani ja työnantaja. 
      Olemme kasvava ja kansainvälistyvä yritys, joka uskaltaa kokeilla uutta 
      ja rikkoa rajoja.
      \\n
      Nojaten arvoihimme – inhimillisyys, uteliaisuus ja tiedostavuus – rakennamme 
      yhdessä asiakkaiden ja yhteistyökumppaneiden kanssa pitkäjänteisesti 
      kestävämpää viheralaa. Meille vihreys ei ole vain visuaalinen elementti; 
      se on tapa edistää yhteisöllisyyttä, hyvinvointia ja luonnon monimuotoisuutta 
      kaupungeissa, joissa vihreyden ja luontoyhteyden tarve kasvaa.
      \\n
      Muutos parempaan vaatii rohkeutta muuttua. Siksi olemme esimerkiksi 
      lopettaneet muovikasvien myynnin ja kehittäneet kotimaisen Koivu-tuotesarjan, 
      jotta asiakkaidemme ei enää tarvitse hankkia ruukkuja kaukomailta. 
      Olemme myös luopuneet tuotteiden myynnistä – siis mitä? Voit edelleen 
      ostaa meiltä viherkasveja, mutta asiakkaanamme vihreydestä voi nauttia 
      myös ilman omistamista, sillä kaikki omat tuotteemme kasveineen saa 
      meiltä helposti vuokrapalveluna. Yksikään kasvimme ei ole kertakäyttöinen 
      ja laadukkaiden materiaalivalintojen ansiosta pystymme hyödyntämään 
      kiertotaloutta ja vähentämään jätteen määrää merkittävästi.
      \\n
      Siinä taisi olla tärkeimmät. Saatat olla kiinnostunut muidenkin 
      sivujemme sisällöstä – niistä löytyy lisää kiinnostavaa tietoa meistä 
      ja vihreästä missiostamme.
      \\n
      Terveisin`,
  regardsText: 'SE InnoGreeniläiset'
}

export default company