import './styles.scss'

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { Close } from '../../../../components/Icons'
import CustomButton from '../../../../components/common/CustomButton'
import LoadingSpinner from '../../../../components/common/LoadingSpinner'

import moment from 'moment'
import {
  Paper,
  Button,
  Popper,
  MenuItem,
  ClickAwayListener,
} from "@mui/material"

import { assign_user_inquiry } from '../../../../store/asyncThunk'

const roleOptions = [
  {
    id: 1,
    name: 'Primary',
  },
  {
    id: 2,
    name: 'Secondary',
  },
  // {
  //   id: 3,
  //   name: 'Read Only',
  // },
]

const InquiryAssignUserModal = (props) => {
  const {
    assignableUsers,
    inquiry,
    showing,
    closeModal,
  } = props;
  const dispatch = useDispatch()
  const [errorList, setErrorList] = useState([])
  const [savedSuccess, setSavedSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedRole, setSelectedRole] = useState(null)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [openRoleDropdown, setOpenRoleDropdown] = useState(false)
  const anchorRef = useRef(null)
  const anchorRoleRef = useRef(null)

  const close = () => {
    document.body.style.overflow = 'unset'
    setSavedSuccess(false)
    setErrorList([])
    setSelectedRole(null)
    setSelectedUser(null)
    closeModal()
  }

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const handleClick = (setState) => {
    setState(true)
  }

  const handleClose = (setState) => {
    setState(false)
  }

  const assignUser = (option) => {
    setSelectedUser(option)
    setOpenDropdown(false)
  }

  const assignRole = (option) => {
    setSelectedRole(option)
    setOpenRoleDropdown(false)
  }

  const handleSaveBtnClick = async () => {
    if (!selectedUser || !selectedRole) {
      setErrorList(['-Please assign a user and role'])
      return
    }
    setErrorList([])
    setIsLoading(true)
    const payload = {
      user_id: selectedUser.id,
      role: selectedRole.id,
    }
    await dispatch(assign_user_inquiry({inquiry_id: inquiry.id, payload: payload}))
    .then((res) => {
      // check for error
      if (res.error) {
        if (res?.payload?.data?.message) {
          setErrorList([res?.payload?.data?.message])
        } else {
          setErrorList(['-Error assigning user'])
        }
        return
      } else {
        setSavedSuccess(true)
        setTimeout(() => {
          setSavedSuccess(false)
        }, 2000)
        setSelectedUser(null)
        setSelectedRole(null)
      }
    })
    .catch((err) => {
      console.log(err)
    })
    setIsLoading(false)
  }

  return (
    showing && (
      <div className='assign-user-modal-root'>
        <div className='assign-user-modal-container'>
          { isLoading && <LoadingSpinner border2/> }
          <div className='assign-user-modal-header'>
          <div className='assign-user-modal-header-title'>Assign User</div>
            <div className='assign-user-modal-header-exit-btn-container'>
              <div
                className='assign-user-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20}/>
              </div>
            </div>
          </div>
            { savedSuccess && <div className='assign-user-modal-saved'>Saved!</div> }
            { errorList.length > 0 && <div className='assign-user-modal-error'>{
              errorList.map((error, index) => (
                <div className='assign-user-modal-error-entry' key={index}>{error}</div>
              ))
            }</div> }
            <div className='assign-user-modal-content'>
              <div>
                <Button
                  ref={anchorRef}
                  onClick={() => handleClick(setOpenDropdown)}
                  sx={{ textAlign: 'left', color: 'black' }}
                  disableRipple={true}
                >
                  Assign User
                  {
                    selectedUser ? <span className='assign-user-modal-selected-user'>: {selectedUser.name}</span> : ''
                  }
                </Button>
                <Popper
                  open={openDropdown}
                  anchorEl={anchorRef.current}
                  placement="bottom"
                  sx={{ zIndex: 99999 }}
                  
                >
                  <ClickAwayListener
                    onClickAway={() => handleClose(setOpenDropdown)}
                  >
                    <Paper sx={{ p: 1 }}>
                      { 
                        assignableUsers.map((option) => (
                          <MenuItem
                            key={option.id}
                            onClick={() => assignUser(option)}
                          >
                            {option.name}
                          </MenuItem>
                        ))
                      }
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
              <div>
                <Button
                  ref={anchorRoleRef}
                  onClick={() => handleClick(setOpenRoleDropdown)}
                  sx={{ textAlign: 'left', color: 'black' }}
                  disableRipple={true}
                >
                  Assign Role
                  {
                    selectedRole ? <span className='assign-user-modal-selected-user'>: {selectedRole.name}</span> : ''
                  }
                </Button>
                <Popper
                  open={openRoleDropdown}
                  anchorEl={anchorRoleRef.current}
                  placement="bottom"
                  sx={{ zIndex: 99999 }}
                  
                >
                  <ClickAwayListener
                    onClickAway={() => handleClose(setOpenRoleDropdown)}
                  >
                    <Paper sx={{ p: 1 }}>
                      { 
                        roleOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            onClick={() => assignRole(option)}
                          >
                            {option.name}
                          </MenuItem>
                        ))
                      }
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
            </div>

            <div className='assign-user-modal-btn-group'>
              <CustomButton
                button_text='SAVE'
                primary={true}
                onClickHandler={() => handleSaveBtnClick()}
                disabled={!selectedUser || !selectedRole}
              />
              <CustomButton transparent={true} button_text='CLOSE' onClickHandler={() => close()} />
            </div>
        </div>
      </div>
    )
  )
}

export default InquiryAssignUserModal
