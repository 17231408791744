import './styles.scss'
import React from 'react'
import HeaderBox from '../../components/HeaderBox'
import InfoCardGroup from '../../components/InfoCardGroup'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import headerImgSrc from '../../assets/images/Company/yritys-1.jpg'
import { useMatches } from 'react-router-dom'


const Company = () => {
  const { t, i18n } = useTranslation()

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  const cards = i18n.t('company.cards', { returnObjects: true })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='company-container'>
        <div className='company-body-container'>
          <HeaderBox
            titleText={t('company.headerTitle')}
            pageText={t('company.headerGroup').toUpperCase()}
            imgSrc={headerImgSrc}
            titleBackgroundColor='yellow'
          />

          <div className='company-card-title'>{t('company.cardTitle')}</div>
          
          <InfoCardGroup cards={cards} type='text'/>
          <div className='company-text-container'>
            <div className='company-text-title'>{t('company.textTitle')}</div>
            <div className='company-text-intro'>
              {
                t('company.introText').split('\\n').map((line, index) => {
                    if (t('company.introText').split('\\n').length - 1 === index ) {
                      return (
                        <React.Fragment key={index}>
                          {line}
                        </React.Fragment>
                        )
                    }
                    return (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                        <br />
                      </React.Fragment>
                      )
                  }
                )
              }
            </div>
            <div className='company-text-regards'>{t('company.regardsText')}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Company
