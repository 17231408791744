import './styles.scss'

import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useMatches } from 'react-router-dom'

import HeaderBox from '../../components/HeaderBox'
import InfoCardGroup from '../../components/InfoCardGroup'
import InfoBox from '../../components/InfoBox'
import { Sustainable, Versatile } from '../../components/CardIcon'
import ImageCard from '../../components/ImageCard'
import LoadingScreen from '../../components/common/LoadingScreen'
import ProductGroup from '../ProductGroup'

import infoBox1ImgSrc from '../../assets/images/Koivu/koivu-1.jpg'
import infoBox2ImgSrc from '../../assets/images/Koivu/koivu-3.jpg'
import imageCardImgSrc from '../../assets/images/Koivu/koivu-2.jpg'

import { setGlobalLoading } from '../../store/uiSlice'

import { fetch_related_products_by_group } from '../../store/asyncThunk'

const cards = [
  {
    title: 'koivu.infoCard1Title',
    body: [
      { text: 'koivu.infoCard1Text1' },
      { text: 'koivu.infoCard1Text2' },
      { text: 'koivu.infoCard1Text3' },
    ],
    icon: <Sustainable/>
  },
  {
    title: 'koivu.infoCard2Title',
    body: [
      { text: 'koivu.infoCard2Text1' },
      { text: 'koivu.infoCard2Text2' },
      { text: 'koivu.infoCard2Text3' },
    ],
    icon: <Versatile/>
  },
]

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 1450 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1450, min: 1110 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1110, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const Koivu = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const dispatch = useDispatch()
  const global_loading = useSelector((state) => state.ui.global_loading)
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()

  const matches = useMatches()
  // Get the language from the route metadata
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  const related_products = {
    products: useSelector(
      (state) => state.product.related_products
    ),
    name: 'koivu_page_related_products'
  }

  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      await dispatch(fetch_related_products_by_group({group_name:'Koivu-ruukut'}))
      dispatch(setGlobalLoading(false))
    })()
  }, [pathname])

  if (global_loading) {
    return (
      <>
        <div className='koivu-container'></div>
        <LoadingScreen />
      </>
    )
  }

  return (
    <div className='koivu-container'>
      <div className='koivu-body-container'>
        <HeaderBox
          titleText={t('koivu.headerTitle')}
          pageText={t('koivu.headerGroup').toLocaleUpperCase()}
          videoSrc='https://vimeo.com/1046351609'
          titleBackgroundColor='turquoise'
        />

        <InfoCardGroup cards={cards} />

        <div className='koivu-text-card'>{t('koivu.textCard1')}</div>

        <InfoBox
          title={t('koivu.infoBox1Title')}
          imgSrc={infoBox1ImgSrc}
          text={t('koivu.infoBox1Text')}
        />

        <ImageCard imgSrc={imageCardImgSrc} alt='Koivu Series Image'/>

        <InfoBox
          title={t('koivu.infoBox2Title')}
          imgSrc={infoBox2ImgSrc}
          text={t('koivu.infoBox2Text')}
        />

        <ProductGroup
          group={related_products}
          responsive={responsive}
          link='products'
          linkText='koivuPageLinkText'
        />
      </div>
    </div>
  )
}

export default Koivu
