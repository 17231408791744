import './styles.scss'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next'

import CustomDropdown from '../common/CustomDropdown'
import { Close } from '../Icons'
import CustomButton from '../common/CustomButton'

import moment from 'moment'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Toolbar,
  Typography,
  FormControl,
  FilledInput,
  InputAdornment,
  Box,
  TableSortLabel,
  Tooltip
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import SearchIcon from '@mui/icons-material/Search'

import {
  fetch_user_requests,
  edit_user_request,
  count_inquiry
} from '../../store/asyncThunk'

const role_options = [
  {
    name: 'Admin',
    id: 1,
  },
  {
    name: 'Sales Director',
    id: 3,
  },
  {
    name: 'Sales Person',
    id: 4,
  },
  {
    name: 'Editor',
    id: 5,
  },
  {
    name: 'User',
    id: 2,
  },
]

const status_options = [
  {
    name: 'Active',
    id: 1,
  },
  {
    name: 'Inactive',
    id: 2,
  },
]

function descendingComparator(a, b, orderBy, order) {
  let valueA = a[orderBy]
  let valueB = b[orderBy]

  // Handle empty strings and null values by moving them to the bottom
  if (valueA === null || valueA === '') {
    valueA = undefined
  }
  if (valueB === null || valueB === '') {
    valueB = undefined
  }

  // For strings, convert to lowercase for case-insensitive comparison
  if (typeof valueA === 'string') {
    valueA = valueA.toLowerCase()
  }
  if (typeof valueB === 'string') {
    valueB = valueB.toLowerCase()
  }

  // Comparison logic for sorting null/empty values, always move them to the bottom
  if (order === 'desc') {
    if (valueB === undefined) return -1 // Move null/empty to the bottom
    if (valueA === undefined) return 1  // Move null/empty to the bottom
  } else {
    if (valueB === undefined) return 1  // Move null/empty to the bottom
    if (valueA === undefined) return -1 // Move null/empty to the bottom
  }

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function descendingPriorityComparator(a, b, orderBy, order) {
  let valueA = a[orderBy]
  let valueB = b[orderBy]

  const isAOverdue = a['deadline'] && new Date(a['deadline']) < new Date()
  const isBOverdue = b['deadline'] && new Date(b['deadline']) < new Date()

  // Show overdue first always if sorting by Priority
  if (order === 'desc') {
    if (isAOverdue && isBOverdue) {
      if (valueA > valueB) {
        valueA = 12
        valueB = 11
      } else if (valueA < valueB) {
        valueA = 11
        valueB = 12
      } else {
        valueA = 11
        valueB = 11
      }
    } else if (isAOverdue && !isBOverdue) {
      valueA = 11
    } else if (!isAOverdue && isBOverdue) {
      valueB = 11
    }
  } else {
    if (isAOverdue && isBOverdue) {
      if (valueA > valueB) {
        valueA = 12
        valueB = 11
      } else if (valueA < valueB) {
        valueA = 11
        valueB = 12
      } else {
        valueA = 11
        valueB = 11
      }
    } else if (isAOverdue && !isBOverdue) {
      valueA = 0
    } else if (!isAOverdue && isBOverdue) {
      valueB = 0
    }
  }

  // if same value sort by date_created
  if (valueB === valueA) {
    valueA = new Date(a['date_created'])
    valueB = new Date(b['date_created'])
    // Always show oldest first date first
    if (order === 'desc') {
      if (valueB < valueA) {
        return 1
      }
      if (valueB > valueA) {
        return -1
      }
      return 0
    } else {
      if (valueB < valueA) {
        return -1
      }
      if (valueB > valueA) {
        return 1
      }
      return 0
    }
  }

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function descendingDateComparator(a, b, orderBy) {
  let valueA = new Date(a[orderBy]);
  let valueB = new Date(b[orderBy]);

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function descendingStateComparator(a, b, orderBy) {
  const valueA = a[orderBy]
  const valueB = b[orderBy]

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  if (orderBy === 'priority') {
    return order === 'desc'
      ? (a, b) => descendingPriorityComparator(a, b, orderBy, order)
      : (a, b) => -descendingPriorityComparator(a, b, orderBy, order)
  } else if (orderBy === 'date_created') {
    return order === 'desc'
      ? (a, b) => descendingDateComparator(a, b, orderBy)
      : (a, b) => -descendingDateComparator(a, b, orderBy)
  } else if (orderBy === 'state') {
    return order === 'desc'
      ? (a, b) => descendingStateComparator(a, b, orderBy)
      : (a, b) => -descendingStateComparator(a, b, orderBy)
  } else {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy, order)
      : (a, b) => -descendingComparator(a, b, orderBy, order)
  }
}

const UserRequestTable = (props) => {
  const {
    count = '',
    showSearch = true,
  } = props
  const [isLoading, setIsLoading] = useState(true)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [newPasswordPayload, setNewPasswordPayload] = useState(null)
  const [showApproveNewuserModal, setShowApproveNewuserModal] = useState(false)
  const [approveNewUserPayload, setApproveNewUserPayload] = useState(null)
  const [errorList, setErrorList] = useState([])
  const dispatch = useDispatch()
  const user_requests = useSelector((state) => state.management.user_requests)
  const { t } = useTranslation()
  const [requested_success, setRequestedSuccess] = useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('creation_date')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const inputRef = useRef(null)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search)
    }, 500)
  
    return () => clearTimeout(handler)
  }, [search])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      await dispatch(fetch_user_requests({search: debouncedSearch}))
      setIsLoading(false)
    })()
  }, [debouncedSearch])

  // useEffect(() => {
  //   (async () => {
  //     dispatch(count_inquiry())
  //   })()
  // }, [user_requests])

  const toggleChangePasswordModal = (request) => {
    if (request) {
      setNewPasswordPayload({
        id: request.id,
        requested_by_id: request.requested_by_id,
        name: request.requested_by,
        email: request.email,
        password: '',
        confirm_password: '',
      })
    } else {
      setNewPasswordPayload(null)
    }
    setShowChangePasswordModal(!showChangePasswordModal)
  }

  const updateNewPasswordInput = ({ password, confirm_password }) => {
    if (password !== undefined) {
      setNewPasswordPayload({ ...newPasswordPayload, password: password })
    }
    if (confirm_password !== undefined) {
      setNewPasswordPayload({
        ...newPasswordPayload,
        confirm_password: confirm_password,
      })
    }
  }

  const updateApproveNewUserPayload = ({ role, status }) => {
    if (role !== undefined) {
      setApproveNewUserPayload({ ...approveNewUserPayload, role: role })
    }
    if (status !== undefined) {
      setApproveNewUserPayload({
        ...approveNewUserPayload,
        status: status,
      })
    }
  }

  const handleConfirmNewPassword = async () => {
    const updated_error_list = []
    if (
      !newPasswordPayload.password ||
      newPasswordPayload.password.trim().length === 0
    ) {
      updated_error_list.push(t('login.errorPasswordRequired'))
    }
    if (
      newPasswordPayload.password &&
      newPasswordPayload.password.trim().length < 6
    ) {
      updated_error_list.push(t('login.errorPasswordLength'))
    }
    if (
      !newPasswordPayload.confirm_password ||
      newPasswordPayload.confirm_password.trim().length === 0
    ) {
      updated_error_list.push(t('login.errorConfirmPasswordRequired'))
    }
    if (
      newPasswordPayload.password &&
      newPasswordPayload.confirm_password &&
      newPasswordPayload.password.trim().length > 0 &&
      newPasswordPayload.confirm_password.trim().length > 0 &&
      newPasswordPayload.password !== newPasswordPayload.confirm_password
    ) {
      updated_error_list.push(t('login.errorPasswordNotMatch'))
    }
    setErrorList(updated_error_list)
    if (updated_error_list.length === 0) {
      dispatch(
        edit_user_request({
          id: newPasswordPayload.id,
          password: newPasswordPayload.password,
          status: 2,
          user_request_id: newPasswordPayload.requested_by_id,
        })
      ).then((res) => {
        if (!res.error) {
          setErrorList([])
          setRequestedSuccess(true)
        } else {
          setErrorList([t('management.genericChangePasswordError')])
        }
      })
    }
  }

  const getDate = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY')
    }
  }

  const toggleApproveNewUserModal = (request) => {
    if (request) {
      setApproveNewUserPayload({
        id: request.id,
        requested_by_id: request.requested_by_id,
        name: request.requested_by,
        email: request.email,
        role: request.role,
        status: request.user_status,
      })
    } else {
      setApproveNewUserPayload(null)
    }
    setShowApproveNewuserModal(!showApproveNewuserModal)
  }

  const handleActionClick = (request) => {
    if (request) {
      setRequestedSuccess(false)
      setErrorList([])
      // New user sign up
      if (request.request_type === 2) {
        toggleApproveNewUserModal(request)
      }
      if (request.request_type === 3) {
        toggleChangePasswordModal(request)
      }
    }
  }

  const handleConfirmApproveNewUser = async () => {
    if (
      approveNewUserPayload &&
      approveNewUserPayload.role &&
      approveNewUserPayload.status
    ) {
      dispatch(
        edit_user_request({
          id: approveNewUserPayload.id,
          role: approveNewUserPayload.role,
          user_status: approveNewUserPayload.status,
          status: 2,
          requested_by_id: approveNewUserPayload.requested_by_id,
        })
      ).then((res) => {
        if (!res.error) {
          setErrorList([])
          dispatch(count_inquiry({type: null}))
          setRequestedSuccess(true)
        } else {
          setErrorList([t('management.genericNewUserError')])
        }
      })
    }
  }

  const get_selected_status = (status_id) => {
    return status_options.find((r) => r.id === status_id)
  }

  const get_selected_role = (role_id) => {
    return role_options.find((r) => r.id === role_id)
  }

  const getActionBtnText = (request) => {
    if (request.request_type === 2) {
      return t('management.signUp')
    }
    if (request.request_type === 3) {
      return t('management.changePassword')
    }
    return ''
  }

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const visibleRows = useMemo(
    () =>
      [...user_requests]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, user_requests],
  )
  
  return (
    <>
      <div className='user-request-table-container'>
        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0  }}>
          <Toolbar>
            <Typography
              sx={{ flex: '1 1 10%' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {count !== '' ? `User Requests (${count})` : `User Requests`}
            </Typography>
            { showSearch && (
                <Tooltip
                  title="Search by User, Email"
                >
                  <FormControl sx={{ m: 1, width: '30ch' }}>
                    <FilledInput
                      id='search'
                      placeholder='Search'
                      disableUnderline
                      inputRef={inputRef}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      startAdornment={
                        <InputAdornment
                          position="start"
                        >
                          <SearchIcon
                            sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                            onClick={handleIconClick}
                          />
                        </InputAdornment>
                      }
                      sx={{
                        paddingTop: 0, // Removes extra top/bottom padding
                      }}
                    />
                  </FormControl>
                </Tooltip>
              )
            }
          </Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#f5f5f5' }}>

                  {/* REQUESTED DATE COL */}
                  <TableCell sortDirection={orderBy === 'creation_date' ? order : false}>
                    <TableSortLabel
                      active={orderBy ==='creation_date'}
                      direction={orderBy === 'creation_date' ? order : 'asc'}
                      onClick={(e) => handleRequestSort(e, 'creation_date')}
                      sx={{
                        fontFamily: 'Libre Franklin',
                        fontWeight: 600,
                        '&.Mui-active': {
                          color: '#8E5CD9', // Color when active (sorted)
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#8E5CD9 !important', // Change arrow color
                        },
                      }}
                    >
                      Date Requested
                      {orderBy === 'creation_date' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  {/* DESCRIPTION COL */}
                  <TableCell sortDirection={orderBy === 'description' ? order : false}>
                    <TableSortLabel
                      active={orderBy ==='description'}
                      direction={orderBy === 'description' ? order : 'asc'}
                      onClick={(e) => handleRequestSort(e, 'description')}
                      sx={{
                        fontFamily: 'Libre Franklin',
                        fontWeight: 600,
                        '&.Mui-active': {
                          color: '#8E5CD9', // Color when active (sorted)
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#8E5CD9 !important', // Change arrow color
                        },
                      }}
                    >
                      Description
                      {orderBy === 'description' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  {/* USER COL */}
                  <TableCell sortDirection={orderBy === 'requested_by' ? order : false}>
                    <TableSortLabel
                      active={orderBy ==='requested_by'}
                      direction={orderBy === 'requested_by' ? order : 'asc'}
                      onClick={(e) => handleRequestSort(e, 'requested_by')}
                      sx={{
                        fontFamily: 'Libre Franklin',
                        fontWeight: 600,
                        '&.Mui-active': {
                          color: '#8E5CD9', // Color when active (sorted)
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#8E5CD9 !important', // Change arrow color
                        },
                      }}
                    >
                      User
                      {orderBy === 'requested_by' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  {/* EMAIL COl */}
                  <TableCell sortDirection={orderBy === 'email' ? order : false}>
                    <TableSortLabel
                      active={orderBy ==='email'}
                      direction={orderBy === 'email' ? order : 'asc'}
                      onClick={(e) => handleRequestSort(e, 'email')}
                      sx={{
                        fontFamily: 'Libre Franklin',
                        fontWeight: 600,
                        '&.Mui-active': {
                          color: '#8E5CD9', // Color when active (sorted)
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#8E5CD9 !important', // Change arrow color
                        },
                      }}
                    >
                      Email
                      {orderBy === 'email' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  {/* STATUS COL */}
                  <TableCell sortDirection={orderBy === 'status' ? order : false}>
                    <TableSortLabel
                      active={orderBy ==='status'}
                      direction={orderBy === 'status' ? order : 'asc'}
                      onClick={(e) => handleRequestSort(e, 'status')}
                      sx={{
                        fontFamily: 'Libre Franklin',
                        fontWeight: 600,
                        '&.Mui-active': {
                          color: '#8E5CD9', // Color when active (sorted)
                        },
                        '& .MuiTableSortLabel-icon': {
                          color: '#8E5CD9 !important', // Change arrow color
                        },
                      }}
                    >
                      Status
                      {orderBy === 'status' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  {/* ACTION COL */}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows &&
                  visibleRows.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{getDate(item.creation_date)}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.requested_by}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>
                        {item.status === 1
                          ? t('management.open')
                          : t('management.closed')}
                      </TableCell>
                      <TableCell>
                        <div className='user-request-table-action'>
                          <div
                            className='user-request-table-action-btn'
                            onClick={(e) => {
                              e.stopPropagation()
                              handleActionClick(item)
                            }}
                          >
                            {getActionBtnText(item)}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={user_requests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      {/* Change password modal */}
      {showChangePasswordModal && (
        <div className='change-password-modal-root'>
          <div className='change-password-modal-container'>
            <div className='change-password-modal-header'>
              <div className='change-password-modal-header-exit-btn-container'>
                <div
                  className='change-password-modal-header-exit-btn'
                  onClick={() => {
                    toggleChangePasswordModal()
                  }}
                >
                  <Close size={20} />
                </div>
              </div>
            </div>
            <div className='change-password-modal-content'>
              {!requested_success && (
                <div className='change-password-modal-header-title'>
                  {t('management.changePassword')}
                </div>
              )}
              {errorList.length > 0 && (
                <div className='change-password-error-container'>
                  {errorList.map((error) => {
                    return (
                      <div className='change-password-error-entry' key={error}>
                        - {error}
                      </div>
                    )
                  })}
                </div>
              )}
              {!requested_success && (
                <div className='change-password-modal-static-container'>
                  <div className='change-password-modal-static-entry'>
                    {t('management.labelName')}: {newPasswordPayload.name}
                  </div>
                  <div className='change-password-modal-static-entry'>
                    {t('management.labelEmail')}: {newPasswordPayload.email}
                  </div>
                </div>
              )}
              {requested_success && (
                <div>{t('management.changePasswordSuccessMessage')}</div>
              )}
              {!requested_success && (
                <>
                  <div className='change-password-modal-input-group'>
                    <label className='change-password-modal-input-label'>
                      {t('management.labelNewPassword')}
                    </label>
                    <input
                      type='password'
                      value={newPasswordPayload.password}
                      className='change-password-modal-input-field textarea'
                      onInput={(e) =>
                        updateNewPasswordInput({ password: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className='change-password-modal-input-group'>
                    <label className='change-password-modal-input-label'>
                      {t('management.labelConfirmPassword')}
                    </label>
                    <input
                      type='password'
                      value={newPasswordPayload.confirm_password}
                      className='change-password-modal-input-field textarea'
                      onInput={(e) =>
                        updateNewPasswordInput({
                          confirm_password: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                </>
              )}
            </div>
            <div className='change-password-modal-btn-group'>
              {requested_success ? (
                <CustomButton
                  button_text={t('management.closeBtn')}
                  onClickHandler={() => toggleChangePasswordModal()}
                />
              ) : (
                <>
                  <CustomButton
                    button_text={t('management.confirm').toUpperCase()}
                    onClickHandler={() => handleConfirmNewPassword()}
                  />
                  <CustomButton
                    button_text={t('management.cancel').toUpperCase()}
                    onClickHandler={() => toggleChangePasswordModal()}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* approve new user modal */}
      {showApproveNewuserModal && (
        <div className='approve-new-user-modal-root'>
          <div className='approve-new-user-modal-container'>
            <div className='approve-new-user-modal-header'>
              <div className='approve-new-user-modal-header-exit-btn-container'>
                <div
                  className='approve-new-user-modal-header-exit-btn'
                  onClick={() => {
                    toggleApproveNewUserModal()
                  }}
                >
                  <Close size={20} />
                </div>
              </div>
            </div>
            <div className='approve-new-user-modal-content'>
              {requested_success && (
                <div>{t('management.approveNewUserSuccessMessage')}</div>
              )}
              {!requested_success && (
                <>
                  <div className='approve-new-user-modal-header-title'>
                    {t('management.approveNewUser')}
                  </div>
                  {errorList.length > 0 && (
                    <div className='approve-new-user-error-container'>
                      {errorList.map((error) => {
                        return (
                          <div
                            className='approve-new-user-error-entry'
                            key={error}
                          >
                            - {error}
                          </div>
                        )
                      })}
                    </div>
                  )}
                  <div className='approve-new-user-modal-static-field'>
                    <div className='approve-new-user-modal-static-entry'>
                      {t('management.labelName')}: {approveNewUserPayload.name}
                    </div>
                    <div className='approve-new-user-modal-static-entry'>
                      {t('management.labelEmail')}:{' '}
                      {approveNewUserPayload.email}
                    </div>
                  </div>
                  <div className='approve-new-user-modal-input-group'>
                    <label className='approve-new-user-modal-input-label'>
                      {t('management.labelStatus')}
                    </label>
                    <CustomDropdown
                      options={status_options}
                      selected={get_selected_status(
                        approveNewUserPayload.status
                      )}
                      onClickHandler={(val) =>
                        updateApproveNewUserPayload({ status: val.id })
                      }
                    />
                  </div>
                  <div className='approve-new-user-modal-input-group'>
                    <label className='approve-new-user-modal-input-label'>
                      {t('management.labelRole')}
                    </label>
                    <CustomDropdown
                      options={role_options}
                      selected={get_selected_role(approveNewUserPayload.role)}
                      onClickHandler={(val) =>
                        updateApproveNewUserPayload({ role: val.id })
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div className='approve-new-user-modal-btn-group'>
              {requested_success ? (
                <CustomButton
                  button_text={t('management.closeBtn')}
                  onClickHandler={() => toggleApproveNewUserModal()}
                />
              ) : (
                <>
                  <CustomButton
                    button_text={t('management.confirm').toUpperCase()}
                    onClickHandler={() => handleConfirmApproveNewUser()}
                  />
                  <CustomButton
                    button_text={t('management.cancel').toUpperCase()}
                    onClickHandler={() => toggleApproveNewUserModal()}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UserRequestTable
