const error = {
  noAccessToProject: 'You do not have access to this project, please contact an admin if this is a mistake',
  noAccess: 'You do not have access to this!',
  timeoutError: 'Request timed out, please try again later',
  genericLogInError: 'Failed to log in, please try again!',
  metaUpdateError: 'Failed to update meta info, please try again!',
  // Reference
  referenceUrlExists: 'Reference with url already exists',
  // Blog
  blogMissingDate: 'Missing date',
  blogIncorrectDateFormat: 'Incorrect date format, should be DD.MM.YYYY',
  blogRouteExists: 'Blog with url already exist',

  // Login
  notActivated: 'Account not activated',

  emailInvalid: 'Sähköpostin muoto on virheellinen',
  }

export default error