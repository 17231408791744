const blog = {
  headerTitle: 'Blogi',
  filter: 'SUODATA',
  clear: 'TYHJENNÄ',
  continue: 'Jatka lukemista',
  readAlso: 'Lue myös',
  readAlsoLinkText: 'Katso kaikki artikkelit',
  biodiversiteetti: 'Biodiversiteetti',
  biofilia: 'Biofilia',
  innogreen: 'InnoGreen',
  innovaatiot: 'Innovaatiot',
  kaupunkisuunnittelu: 'Kaupunkisuunnittelu',
  koivu: 'Koivu',
  pihasuunnittelu: 'Pihasuunnittelu',
  rekrytointi: 'Rekrytointi',
  tyohyvinvointi: 'Työhyvinvointi',
  ulkoalueet: 'Ulkoalueet',
  vastuullisuus: 'Vastuullisuus',
  viherkalusteet: 'Viherkalusteet',
  vihersisustus: 'Vihersisustus',
  vinkit: 'Vinkit',
  viherseina: 'Viherseinä',
  page: 'Sivu',
  next: 'Seuraavat',
  previous: 'Edelliset',
}

export default blog