import './styles.scss'

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Unassigned from '../Unassigned'
import Overdue from './Overdue'
import NewInquiry from './NewInquiry'

import { PieChart } from '@mui/x-charts/PieChart';

import moment from 'moment'

import {
  fetch_user_inquiry_chart
} from '../../../../store/asyncThunk'


const UserPieChart = (props) => {
  const {
    user_id = false
  } = props
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const chart_data = useSelector((state) => state.management.user_pie_chart)

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      await dispatch(fetch_user_inquiry_chart(user_id))
      setIsLoading(false)
    })()
  }, [])

  const allValuesAreZero = chart_data.every(item => item.value === 0);

  return (
    <div className='pie-chart-container'>
      <div className='pie-chart-content'>
        {!allValuesAreZero ? (
          <PieChart
            loading={isLoading}
            series={[
              {
                data: chart_data,
                arcLabel: (item) => (item.value > 0 ? `${item.value}` : ""), // Hide labels with 0 value
              },
            ]}
            width={380}
            height={200}
            margin={{ right: 160, top: 0 }}
            slotProps={{
              legend: {
                labelStyle: {
                  tableLayout: 'fixed',
                  fontSize: 15,
                },
                itemGap: 20,
              },
            }}
          />
        ) : (
          <div style={{ textAlign: "center", color: "gray", fontSize: "16px" }}>
            No User inquiry data available
          </div>
        )}
      

      </div>
    </div>
  )
}

export default UserPieChart