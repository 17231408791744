import './styles.scss'
import { Close } from '../Icons'
import { useEffect, useState, useRef } from 'react'
import CustomButton from '../common/CustomButton'
import { update_meta_tag } from '../../store/asyncThunk'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const EditMetaTagsModal = (props) => {
  const { 
    showing,
    closeModal,
    row,
  } = props
  const dispatch = useDispatch()
  const [metaInfo, setMetaInfo] = useState({
    url: '',
    title: '',
    description: '',
    type: '',
    site_name: '',
    robots: '',
    imgSrc: '',
    imgFile: null
  })
  const [errorList, setErrorList] = useState([])
  const [savedSuccess, setSavedSuccess] = useState(false)
  const [changed, setChanged] = useState(false)
  const { t } = useTranslation()
  const fileInputRef = useRef(null)

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  useEffect(() => {
    if (row) {
      const { url, title, description, type, site_name, robots, imgSrc } = row
      setMetaInfo({url, title, description, type, site_name, robots, imgSrc })
    }
  }, [row, showing])

  const updateMetaInfo = ({
    url,
    title,
    description,
    type,
    site_name,
    robots,
  }) => {
    setSavedSuccess(false)
    if (url !== undefined) {
      setMetaInfo({ ...metaInfo, url: url })
    }
    if (title !== undefined) {
      setMetaInfo({ ...metaInfo, title: title })
    }
    if (description !== undefined) {
      setMetaInfo({ ...metaInfo, description: description })
    }
    if (type !== undefined) {
      setMetaInfo({ ...metaInfo, type: type })
    }
    if (site_name !== undefined) {
      setMetaInfo({ ...metaInfo, site_name: site_name })
    }
    if (robots !== undefined) {
      setMetaInfo({ ...metaInfo, robots: robots })
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    
    if (file) {
      const previewUrl = URL.createObjectURL(file)
      setMetaInfo({...metaInfo, imgFile: file, imgSrc: previewUrl})
    }
    // Reset input value to allow the same file to trigger changes
    e.target.value = ''
  }

  const handleImageClick = () => {
    fileInputRef.current.click()
  }

  const close = () => {
    document.body.style.overflow = 'unset'
    setSavedSuccess(false)
    closeModal()
  }

  const handleSaveBtnClick = async () => {
    setErrorList([])
    const formData = new FormData()

    // remove imgSrc as it is only used for displying the image on frontend
    const {imgSrc, imgFile, ...content} = metaInfo
    formData.append('payload', JSON.stringify(content))
    if (imgFile) {
      formData.append('image', imgFile)
    }

    await dispatch(update_meta_tag({meta_id: row.id, payload:formData}))
      .then((res) => {
        if (res.error) {
          setErrorList([t('error.metaUpdateError')])
        }
        setSavedSuccess(true)
        // Set a timer to reset savedSuccess after 3 seconds
        setTimeout(() => {
          setSavedSuccess(false)
        }, 3000)
      })
      .catch((error) => {
        console.error("Meta update error Error:", error)
      })
  }

  return (
    showing && (
      <div className='meta-edit-modal-root'>
        <div className='meta-edit-modal-container'>
          <div className='meta-edit-modal-header'>
            <div className='meta-edit-modal-header-exit-btn-container'>
              <div
                className='inquiry-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20}/>
              </div>
            </div>
          </div>
            <div className='meta-edit-modal-content'>
              <div className='meta-edit-modal-header-title'>Meta Tags</div>
              { savedSuccess && 
                <div className='meta-edit-modal-saved'>Saved!</div>
              }
              <div className='meta-edit-modal-input-group'>
                <label htmlFor="url" className='meta-edit-modal-input-label'>og:url</label>
                <input
                  id='url'
                  type='text'
                  value={metaInfo.url}
                  className='meta-edit-modal-input-field'
                  onInput={(e) =>
                    updateMetaInfo({ url: e.target.value })
                  }
                ></input>
              </div>
              <div className='meta-edit-modal-input-group'>
                <label htmlFor="title" className='meta-edit-modal-input-label'>og:title</label>
                <input
                  id='title'
                  type='text'
                  value={metaInfo.title}
                  className='meta-edit-modal-input-field'
                  onInput={(e) =>
                    updateMetaInfo({ title: e.target.value })
                  }
                ></input>
              </div>
              <div className='meta-edit-modal-input-group'>
                <label htmlFor="description" className='meta-edit-modal-input-label'>og:description</label>
                <textarea
                  id='description'
                  type='text'
                  row='4'
                  value={metaInfo.description}
                  className='meta-edit-modal-input-field'
                  onInput={(e) =>
                    updateMetaInfo({ description: e.target.value })
                  }
                ></textarea>
              </div>
              <div className='meta-edit-modal-input-group-container'>
                <div className='meta-edit-modal-input-group-left'>
                  <div className='meta-edit-modal-input-group'>
                    <label htmlFor="type" className='meta-edit-modal-input-label'>og:type</label>
                    <input
                      id='type'
                      type='text'
                      value={metaInfo.type}
                      className='meta-edit-modal-input-field'
                      onInput={(e) =>
                        updateMetaInfo({ type: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className='meta-edit-modal-input-group'>
                    <label htmlFor="site_name" className='meta-edit-modal-input-label'>og:site_name</label>
                    <input
                      id='site_name'
                      type='text'

                      value={metaInfo.site_name}
                      className='meta-edit-modal-input-field'
                      onInput={(e) =>
                        updateMetaInfo({ site_name: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className='meta-edit-modal-input-group'>
                    <label htmlFor="robots" className='meta-edit-modal-input-label'>robots</label>
                    <input
                      id='robots'
                      type='text'
                      value={metaInfo.robots}
                      className='meta-edit-modal-input-field'
                      onInput={(e) =>
                        updateMetaInfo({ robots: e.target.value })
                      }
                    ></input>
                  </div>
                </div>
                <div className='meta-edit-modal-input-group-right'>
                  <label htmlFor="image" className='meta-edit-modal-input-label'>og:image</label>
                  <div className='meta-edit-modal-input-group-right-image-wrapper' onClick={handleImageClick}>
                    {metaInfo.imgSrc ? 
                      (
                        <img src={metaInfo.imgSrc} alt="" draggable='false'/>
                      ) : (
                        <div className='meta-edit-modal-input-group-right-image-placeholder'> Click to add Image</div>
                      )
                    }
                    
                  </div>
                  <input
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileChange(e)}
                    accept='image/*'
                  />
                </div>
              </div>
              {errorList.length > 0 && (
                <div className='edit-meta-information-error-container'>
                  {errorList.map((error) => {
                    return (
                      <div className='edit-meta-information-error-entry' key={error}>
                        - {error}
                      </div>
                    )
                  })}
                </div>
              )}
              </div>
            <div className='meta-edit-modal-btn-group'>
              <CustomButton
                button_text='SAVE'
                primary={true}
                onClickHandler={() => handleSaveBtnClick()}
              />
              <CustomButton transparent={true} button_text='CANCEL' onClickHandler={() => close()} />
            </div>
        </div>
      </div>
    )
  )
}

export default EditMetaTagsModal
