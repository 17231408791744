import './styles.scss'
import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoadingScreen from '../../components/common/LoadingScreen'
import { setGlobalLoading } from '../../store/uiSlice'
import { useSelector, useDispatch } from 'react-redux'
import { fetch_blogs, fetch_blog_groups } from '../../store/asyncThunk'
import CustomButton from '../../components/common/CustomButton'
import CustomCard from '../../components/common/CustomCard'
import { useTranslation } from 'react-i18next'
import { setFilters, setPage } from '../../store/blogSlice'
import { ArrowLeft, ArrowRight, AccordionClose } from '../../components/Icons'


const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const dispatch = useDispatch()
  const global_loading = useSelector((state) => state.ui.global_loading)
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  const { blogs, currentPage, selectedFilters, blogGroupsFetched, pages } = useSelector((state) => state.blog)
  const blogGroup = useSelector((state) => state.blog.blogGroup)
  const navigate = useNavigate()
  const language = useSelector((state) => state.user.language)

  useEffect(() => {
    const loadData = async () => {
      if (!blogGroupsFetched) {
        dispatch(setGlobalLoading(true))
        await dispatch(fetch_blog_groups())
        dispatch(setGlobalLoading(false))
      } else {
        setIsLoading(true)
        const filterQuery = selectedFilters.length > 0 ? selectedFilters.join(',') : null;
        await dispatch(fetch_blogs({ page: currentPage, filters: filterQuery, language: language}))
        setIsLoading(false)
      }
    }
    loadData()
  }, [dispatch, currentPage, selectedFilters, blogGroupsFetched, language]) 

  const handlePageChange = (newPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // Wait for the scroll animation
    setTimeout(() => {
      dispatch(setPage(newPage));
    }, 600)
  }

  const handleFilterChange = (filter) => {
    const newFilters = selectedFilters.includes(filter)
      ? selectedFilters.filter((f) => f !== filter)
      : [...selectedFilters, filter]
    dispatch(setFilters(newFilters))
    dispatch(setPage(1))
  }

  const clearFilter = () => {
    dispatch(setFilters([]))
  }

  const handleBlogLinkClick = (url) => {
    navigate(url)
  }

  const btnStyle = {
    padding: '0.15rem 1rem',
  }

  if (global_loading) {
    return (
      <>
        <LoadingScreen/>
        <div className='blogs-container'></div>
      </>
    )
  }

  return (
    <div className='blogs-container'>
      <div className='blogs-title-container'>
        <div className='blogs-title-body-container'>
          <div className='blogs-title header-text'>{t('blog.headerTitle')}</div>
          <div className='blogs-filter-container'>
            <div className='blogs-filter-text'>{t('blog.filter').toUpperCase()}</div>
            <div className='blogs-filter-button-container'>
              <div className='blogs-filter-type-container'>
                { blogGroup && blogGroup.map((btn, index) => {
                    return (
                      <React.Fragment key={index}>
                        <CustomButton
                          button_text={t(btn.translate).toUpperCase()}
                          customStyles={btnStyle}
                          white={true}
                          contentClass='blogs-filter-btn'
                          onClickHandler={() => handleFilterChange(btn.tag)}
                          selected={selectedFilters.includes(btn.tag)}
                        />
                      </React.Fragment>
                    )
                  })
                }
                {
                  selectedFilters && selectedFilters.length !== 0 ?
                  (
                    <CustomButton
                      button_text={t('blog.clear').toUpperCase()}
                      customStyles={btnStyle}
                      transparent
                      icon={<AccordionClose/>}
                      contentClass='blogs-filter-btn'
                      onClickHandler={() => clearFilter()}
                    />
                  ) : (
                    <></>
                  )
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='blogs-card-container'>
        <div className='blogs-card-body-container'>
          { isLoading ?
          (
            <LoadingScreen />
          ) : (
            <>
              { blogs && blogs.length !== 0 ? 
                (
                  blogs.map((blog) => {
                    return (
                      <div key={blog.id} className='blogs-card'>
                        <CustomCard 
                          { ...blog } type='blog'
                          onClickHandler={() => handleBlogLinkClick(blog.url)}
                        />
                      </div>
                    )
                  })
                ) : (
                  <>
                  {
                    selectedFilters && selectedFilters.length === 0 ?
                    (
                      <div className='blogs-card-body-no-blogs'>
                        No Blogs found
                      </div>
                    ) : (
                      <div className='blogs-card-body-no-blogs'>
                       No Blogs found with selected filters
                      </div>
                    )
                  }
                  </>
                )
              }
            </>
          )}
          
        </div>
      </div>
      {isLoading ?
        (
          <></>
        ) : (
          <div className='blogs-pagination-container'>
            <div className='blogs-pagination-btn-container'>
              <div
                className={currentPage === 1 ? 'blogs-pagination-btn disabled no-select' : 'blogs-pagination-btn no-select'}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <ArrowLeft size={30}/> {t('blog.previous')}
              </div>
              <div className='blogs-pagination-btn-divider'></div>
              <div
                className={currentPage === pages ? 'blogs-pagination-btn disabled no-select' : 'blogs-pagination-btn no-select'}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                {t('blog.next')} <ArrowRight size={30}/>
              </div>
            </div>
            <div className='blogs-pagination-page'>
              {t('blog.page').toUpperCase()}&nbsp;{currentPage} / {pages}
            </div>
          </div>
        )
      }

    </div>
  )
}

export default Blogs
