import './styles.scss'
import React, { useState, useEffect } from 'react'
import CustomTextInput from '../common/CustomTextInput'
import ReactPlayer from 'react-player/youtube'

const YoutubeVideo = (props) => {
  const {
    id,
    onContentChange,
    video_url = null
  } = props
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (video_url != null) {
      setUrl(video_url)
    }
  }, [video_url]);


  const handleContentChange = (value) => {
    setUrl(value)
    onContentChange(value, id)
  }

  
  return (
    <div className='youtube-video-input-container'>
      <div className='youtube-video-input-video-url'>
        <CustomTextInput
          type='YoutubeUrl'
          onContentChange={handleContentChange}
          label='Youtube Url'
          value={url}
          />
      </div>
      <div className='youtube-video-input-video-preview'>
        {
          url ?
          (
            <ReactPlayer url={url}/>
          ) : (
            <></>
          )
        }
      </div>
    </div>
  )
}

export default YoutubeVideo