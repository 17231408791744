import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { signOut } from 'aws-amplify/auth'
import { addSnackBar } from './uiSlice'

const baseURL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api/'

const axios_instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  withXSRFToken: true
})

axios_instance.interceptors.request.use(
  function (config) {
    config.headers.withCredentials = true
    config.headers.withXSRFToken = true
    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)

// Intercept all responses to check for CSRF token expiration
axios_instance.interceptors.response.use(
  (response) => response, // Pass through successful responses
  async (error) => {
    const { config, response } = error

    if (
        response && 
        response?.data?.error_code === 'CSRF_TOKEN_EXPIRED' &&
        ['POST', 'PATCH', 'DELETE'].includes(config.method.toUpperCase())
      ) {
      try {
        // Check if the request has already been retried
        if (config._retry) {
          return Promise.reject(error) // Reject if already retried
        }

        config._retry = true // Mark request as retried

        // Fetch new CSRF token
        const tokenResponse = await axios_instance.get('/auth/token')

        // Update Axios with new CSRF token
        axios_instance.defaults.headers.common['X-CSRF-TOKEN'] =
          tokenResponse.data.token

        // Retry the failed request with the new CSRF token
        config.headers['X-CSRF-TOKEN'] = tokenResponse.data.token
        return axios_instance(config)
      } catch (csrfError) {
        return Promise.reject(csrfError)
      }
    }
    return Promise.reject(error)
  }
)

axios_instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = error.response.data.redirect  // Redirect to relative path
    }
    return Promise.reject(error)
  }
)

//----------------------------------UI-------------------------------------------------
const fetch_csrf_token = createAsyncThunk(
  'ui/fetch_csrf_token',
  async (_, thunkAPI) => {
    let url = '/auth/token'

    try {
      const response = await axios_instance.get(url)

      axios_instance.defaults.headers.common['X-CSRF-TOKEN'] =
        response.data.token

      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

//----------------------------------PRODUCT-------------------------------------------------
const fetch_products = createAsyncThunk(
  'product/fetch_products',
  async ({ small_images = false }, thunkAPI) => {
    let url = `/product?small_images=${small_images ?? false}`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_product = createAsyncThunk(
  'product/fetch_product',
  async ({ slug, preload_customizations, get_images }, thunkAPI) => {
    let url = `/product/${slug}?get_images=${get_images ?? false}`

    try {
      const response = await axios_instance.get(url)
      return { product: response.data, preload_customizations }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)
const fetch_related_products = createAsyncThunk(
  'product/fetch_related_products',
  async ({ slug, small_images = false }, thunkAPI) => {
    let url = `/product/${slug}/related?small_images=${
      small_images ?? false
    }`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_product_groups = createAsyncThunk(
  'product/fetch_product_groups',
  async (_, thunkAPI) => {
    let url = '/product/group'
    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)
const fetch_related_products_by_group = createAsyncThunk(
  'product/fetch_related_products_by_group',
  async ({ group_name = null }, thunkAPI) => {
    let url = '/product/group/related'
    if (group_name) {
      url += `?group_name=${group_name}`
    }

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_product_customizations = createAsyncThunk(
  'product/fetch_product_customizations',
  async (small_images = false, thunkAPI) => {
    let url = `/product/customization?small_images=${small_images ?? false}`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_product_material = createAsyncThunk(
  'product/fetch_product_material',
  async ({ material_id }, thunkAPI) => {
    let url = `/product/material/${material_id}`

    try {
      const response = await axios_instance.get(url, {
        responseType: 'blob', // <-- Important! Tells axios to return a Blob (binary data)
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_product_material_by_name = createAsyncThunk(
  'product/fetch_product_material_by_name',
  async ({ filename = null }, thunkAPI) => {
    let url = `/product/material`
    const queryParams = []

    if (filename !== null) {
      queryParams.push(`filename=${filename}`)
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url, {
        responseType: 'blob', // <-- Important! Tells axios to return a Blob (binary data)
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

//----------------------------------PROJECT-------------------------------------------------
const fetch_projects = createAsyncThunk(
  'project/fetch_projects',
  async ({selection = null, modal = null} = {}, thunkAPI) => {
    let url = '/project'

    const queryParams = []

    if (selection !== null) {
      queryParams.push(`selection=${selection}`)
    }
    if (modal !== null) {
      queryParams.push(`modal=${modal}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_project = createAsyncThunk(
  'project/fetch_project',
  async ({ project_id }, thunkAPI) => {
    let url = `/project/${project_id}`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.response.data })
    }
  }
)

const create_project = createAsyncThunk(
  'project/create_project',
  async (payload, thunkAPI) => {
    let url = '/project'

    try {
      const response = await axios_instance.post(url, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const edit_project = createAsyncThunk(
  'project/edit_project',
  async (payload, thunkAPI) => {
    let url = `/project/${payload.project_id}`

    try {
      const response = await axios_instance.patch(url, payload.payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)
const duplicate_project = createAsyncThunk(
  'project/duuplicate_project',
  async (payload, thunkAPI) => {
    let url = `/project/${payload.project_id}/duplicate`

    try {
      const response = await axios_instance.post(url, payload.payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const delete_project = createAsyncThunk(
  'project/delete_project',
  async (payload, thunkAPI) => {
    let url = `/project/${payload.project_id}`

    try {
      const response = await axios_instance.delete(url)
      return { id: payload.project_id }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const create_space = createAsyncThunk(
  'project/create_space',
  async (payload, thunkAPI) => {
    let url = `/project/${payload.project_id}/space`

    try {
      const response = await axios_instance.post(url, payload.space)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const edit_space = createAsyncThunk(
  'project/edit_space',
  async (payload, thunkAPI) => {
    let url = `/project/${payload.project_id}/space/${payload.space_id}`

    try {
      const response = await axios_instance.patch(url, payload.payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const delete_space = createAsyncThunk(
  'project/delete_space',
  async (payload, thunkAPI) => {
    let url = `/project/${payload.project_id}/space/${payload.space_id}`

    try {
      const response = await axios_instance.delete(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const add_product_to_space = createAsyncThunk(
  'project/add_product_to_space',
  async (payload, thunkAPI) => {
    let url = `/project/${payload.project_id}/product`

    try {
      const response = await axios_instance.post(url, payload.product)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const remove_product_from_project = createAsyncThunk(
  'project/remove_product_from_project',
  async ({ product_id, project_id }, thunkAPI) => {
    let url = `/project/${project_id}/product/${product_id}`

    try {
      const response = await axios_instance.delete(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const edit_assigned_product = createAsyncThunk(
  'project/edit_assigned_product',
  async ({ product_id, project_id, payload }, thunkAPI) => {
    let url = `/project/${project_id}/product/${product_id}`

    try {
      const response = await axios_instance.patch(url, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const edit_assigned_product_customization = createAsyncThunk(
  'project/edit_assigned_product_customization',
  async ({ product_id, project_id, customization_id, payload }, thunkAPI) => {
    let url = `/project/${project_id}/product/${product_id}/customization/${customization_id}`

    try {
      const response = await axios_instance.patch(url, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

//----------------------------------USER-------------------------------------------------

const log_in = createAsyncThunk('user/logIn', async (payload, thunkAPI) => {
  let url = `/auth/logIn`
  try {
    const response = await axios_instance.post(url, payload)
    axios_instance.defaults.headers.common['X-CSRF-TOKEN'] =
      response.data.csrf_token
    // thunkAPI.dispatch(
    //   addSnackBar({ message: 'Signed in successfully', success: true })
    // )
    return response.data
  } catch (err) {
    // thunkAPI.dispatch(
    //   addSnackBar({ message: 'Could not sign in', success: false })
    // )
    return thunkAPI.rejectWithValue({
      error: err.message,
      data: err.response ? err.response.data : null,
    })
  }
})

const log_out = createAsyncThunk('user/log_out', async (payload, thunkAPI) => {
  let url = `/auth/logOut`
  try {
    const response = await axios_instance.post(url, payload)
    // Does not work when logged in with email and password it does not run the fulfilled in userSlice for this function when below is uncommented
    // await signOut()
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue({ error: err.message })
  }
})

const fetch_current_user = createAsyncThunk(
  'user/fetch_current_user',
  async (payload, thunkAPI) => {
    let url = `/auth/current-user`
    try {
      const response = await axios_instance.get(url, payload)
      axios_instance.defaults.headers.common['X-CSRF-TOKEN'] =
        response.data.csrf_token
      return response.data.user
    } catch (err) {
      console.log(err)
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_assignable_users= createAsyncThunk(
  'user/fetch_assignable_users',
  async ( thunkAPI) => {
    let url = `/user/assignable-users`
    try {
      const response = await axios_instance.get(url, )
      return response.data
    } catch (err) {
      console.log(err)
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const sign_up = createAsyncThunk('user/signUp', async (payload, thunkAPI) => {
  let url = `/auth/signUp`
  try {
    const response = await axios_instance.post(url, payload)
    // thunkAPI.dispatch(
    //   addSnackBar({ message: 'Signed up successfully', success: true })
    // )
    return response.data
  } catch (err) {
    // thunkAPI.dispatch(
    //   addSnackBar({ message: 'Could not sign up', success: false })
    // )
    return thunkAPI.rejectWithValue({
      error: err.message,
      data: err.response ? err.response.data : null,
    })
  }
})

const fetch_users = createAsyncThunk(
  'user/fetchUsers',
  async ({search = null, status = null, role = null}, thunkAPI) => {
    let url = `/auth/user`

    const queryParams = []

    if (search !== null && search !== '') {
      queryParams.push(`search=${search}`)
    }
    if (status !== null && status !== '') {
      queryParams.push(`status=${status}`)
    }
    if (role !== null && role !== '') {
      queryParams.push(`role=${role}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url)

      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({
        error: err.message,
      })
    }
  }
)

const fetch_user = createAsyncThunk(
  'user/fetch_user',
  async (user_id, thunkAPI) => {
    let url = `/auth/user/${user_id}`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const edit_user = createAsyncThunk(
  'user/editUser',
  async (payload, thunkAPI) => {
    let url = `/auth/user/${payload.id}`
    try {
      const response = await axios_instance.patch(url, payload)
      return response.data
    } catch (err) {
      // thunkAPI.dispatch(
      //   addSnackBar({ message: 'Could not edit user', success: false })
      // )
      return thunkAPI.rejectWithValue({
        error: err.message,
      })
    }
  }
)
// Used for own user update
const update_user_information = createAsyncThunk(
  'user/update_user_information',
  async (payload, thunkAPI) => {
    let url = `/user/${payload.user_id}`
    try {
      const response = await axios_instance.patch(url, payload)
      return response.data
    } catch (err) {
      // thunkAPI.dispatch(
      //   addSnackBar({ message: 'Could not edit user', success: false })
      // )
      return thunkAPI.rejectWithValue({
        error: err.message,
      })
    }
  }
)

//Make user status to be inactive
const deactivate_user = createAsyncThunk(
  'user/deactivateUser',
  async (payload, thunkAPI) => {
    let url = `/auth/user/${payload.id}`
    try {
      const response = await axios_instance.patch(url, payload)
      // thunkAPI.dispatch(
      //   addSnackBar({ message: 'Deactivated user successfully', success: true })
      // )
      return response.data
    } catch (err) {
      // thunkAPI.dispatch(
      //   addSnackBar({ message: 'Could not deactivate user', success: false })
      // )
      return thunkAPI.rejectWithValue({
        error: err.message,
      })
    }
  }
)

const passwordResetRequest = createAsyncThunk(
  'user/passwordResetRequest',
  async (payload, thunkAPI) => {
    let url = `/auth/user/reset-password-request`
    try {
      const response = await axios_instance.post(url, payload)
      return response.data
    } catch (err) {
      // thunkAPI.dispatch(
      //   addSnackBar({ message: 'Failed to request', success: false })
      // )
      return thunkAPI.rejectWithValue({
        error: err.message,
        data: err.response ? err.response.data : null,
      })
    }
  }
)

const fetch_user_requests = createAsyncThunk(
  'user/fetchUserRequest',
  async ({search = null}, thunkAPI) => {
    let url = `/auth/user-request`

    const queryParams = []

    if (search !== null && search !== '') {
      queryParams.push(`search=${search}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url)

      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({
        error: err.message,
      })
    }
  }
)

const edit_user_request = createAsyncThunk(
  'user/editUserRequest',
  async (payload, thunkAPI) => {
    let url = `/auth/user-request/${payload.id}`
    try {
      const response = await axios_instance.patch(url, payload)
      return response.data
    } catch (err) {
      // thunkAPI.dispatch(
      //   addSnackBar({ message: 'Failed to handle request', success: false })
      // )
      return thunkAPI.rejectWithValue({
        error: err.message,
      })
    }
  }
)

//----------------------------------REFERENCE-------------------------------------------------

const fetch_references = createAsyncThunk(
  'reference/fetch_references',
  async (
    {
      page = null,
      filters = null,
      for_page = null,
      quantity = null,
    },
    thunkAPI
  ) => {
    let url = '/reference'

    const queryParams = []

    if (page !== null) {
      queryParams.push(`page=${page}`)
    }
    if (filters !== null) {
      queryParams.push(`filters=${filters}`)
    }
    if (for_page !== null) {
      queryParams.push(`for_page=${for_page}`)
    }
    if (quantity !== null) {
      queryParams.push(`quantity=${quantity}`)
    }
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url)
      return {
        data: response.data.references,
        recordsTotal: response.data.recordsTotal,
        recordsFiltered: response.data.recordsFiltered,
        forPage: response.data.forPage,
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_management_references = createAsyncThunk(
  'reference/fetch_management_references',
  async (
    {
      page = null,
      filters = null,
      for_page = null,
      quantity = null,
      per_page = 9,
      search = null,
    },
    thunkAPI
  ) => {
    let url = '/reference/management'
    const queryParams = []

    if (page !== null) {
      queryParams.push(`page=${page}`)
    }
    if (filters !== null) {
      queryParams.push(`filters=${filters}`)
    }
    if (for_page !== null) {
      queryParams.push(`for_page=${for_page}`)
    }
    if (quantity !== null) {
      queryParams.push(`quantity=${quantity}`)
    }
    if (per_page !== null) {
      queryParams.push(`per_page=${per_page}`)
    }
    if (search !== null && search !== '') {
      queryParams.push(`search=${search}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url)
      return {
        data: response.data.references,
        recordsTotal: response.data.recordsTotal,
        recordsFiltered: response.data.recordsFiltered,
        forPage: response.data.forPage,
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_reference = createAsyncThunk(
  'reference/fetch_reference',
  async (slug, thunkAPI) => {
    let url = `/reference/${slug}`

    try {
      const response = await axios_instance.get(url)
      return { reference: response.data }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_management_reference = createAsyncThunk(
  'reference/fetch_management_reference',
  async (reference_id, thunkAPI) => {
    let url = `/reference/management/${reference_id}`

    try {
      const response = await axios_instance.get(url)
      return { reference: response.data }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const update_reference_state = createAsyncThunk(
  'reference/update_reference_state',
  async ({reference_id=null, payload=null}, thunkAPI) => {
    let url = `/reference/management/${reference_id}/state`

    try {
      const response = await axios_instance.patch(url, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const add_reference = createAsyncThunk(
  'reference/add_reference',
  async (payload, thunkAPI) => {
    let url = '/reference'
    try {
      const response = await axios_instance.post(url, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({
        error: err.message,
        data: err.response ? err.response.data : null,
      })
    }
  }
)

const edit_reference = createAsyncThunk(
  'reference/edit_reference',
  async ({payload, slug}, thunkAPI) => {
    let url = `/reference/${slug}`
    try {
      const response = await axios_instance.patch(url, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const delete_reference = createAsyncThunk(
  'reference/delete_reference',
  async ({slug}, thunkAPI) => {
    let url = `/reference/${slug}`
    try {
      const response = await axios_instance.delete(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_reference_groups = createAsyncThunk(
  'reference/fetch_reference_groups',
  async (_, thunkAPI) => {
    let url = `/reference/group`

    try {
      const response = await axios_instance.get(url)

      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

//----------------------------------BLOG-------------------------------------------------

const fetch_blogs = createAsyncThunk(
  'blog/fetch_blogs',
  async (
    {
      page = null,
      filters = null,
      for_page = null,
      tags = null,
      quantity = null,
      excluded_id = null,
      language = 'FI',
      small_images = true,
      per_page = 9,
      search = null,
    },
    thunkAPI
  ) => {
    let url = '/blog'

    const queryParams = []

    if (page !== null) {
      queryParams.push(`page=${page}`)
    }
    if (filters !== null) {
      queryParams.push(`filters=${filters}`)
    }
    if (for_page !== null) {
      queryParams.push(`for_page=${for_page}`)
    }
    if (tags !== null) {
      queryParams.push(`tags=${tags}`)
    }
    if (quantity !== null) {
      queryParams.push(`quantity=${quantity}`)
    }
    if (excluded_id !== null) {
      queryParams.push(`excluded_id=${excluded_id}`)
    }
    if (language !== null) {
      queryParams.push(`language=${language}`)
    }
    if (small_images !== null) {
      queryParams.push(`small_images=${small_images}`)
    }
    if (per_page !== null) {
      queryParams.push(`per_page=${per_page}`)
    }
    if (search !== null && search !== '') {
      queryParams.push(`search=${search}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url)
      return {
        data: response.data.blogs,
        recordsTotal: response.data.recordsTotal,
        recordsFiltered: response.data.recordsFiltered,
        forPage: response.data.forPage,
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_management_blogs = createAsyncThunk(
  'blog/fetch_management_blogs',
  async (
    {
      page = null,
      filters = null,
      for_page = null,
      tags = null,
      quantity = null,
      excluded_id = null,
      language = 'FI',
      small_images = true,
      per_page = 9,
      search = null,
    },
    thunkAPI
  ) => {
    let url = '/blog/management'

    const queryParams = []

    if (page !== null) {
      queryParams.push(`page=${page}`)
    }
    if (filters !== null) {
      queryParams.push(`filters=${filters}`)
    }
    if (for_page !== null) {
      queryParams.push(`for_page=${for_page}`)
    }
    if (tags !== null) {
      queryParams.push(`tags=${tags}`)
    }
    if (quantity !== null) {
      queryParams.push(`quantity=${quantity}`)
    }
    if (excluded_id !== null) {
      queryParams.push(`excluded_id=${excluded_id}`)
    }
    if (language !== null) {
      queryParams.push(`language=${language}`)
    }
    if (small_images !== null) {
      queryParams.push(`small_images=${small_images}`)
    }
    if (per_page !== null) {
      queryParams.push(`per_page=${per_page}`)
    }
    if (search !== null && search !== '') {
      queryParams.push(`search=${search}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url)
      return {
        data: response.data.blogs,
        recordsTotal: response.data.recordsTotal,
        recordsFiltered: response.data.recordsFiltered,
        forPage: response.data.forPage,
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_management_blog = createAsyncThunk(
  'blog/fetch_management_blog',
  async (blog_id, thunkAPI) => {
    let url = `/blog/management/${blog_id}`

    try {
      const response = await axios_instance.get(url)
      return { blog: response.data }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_blog = createAsyncThunk(
  'blog/fetch_blog',
  async ({year=null, month=null, slug=null}, thunkAPI) => {
    let url = `/blog/${year}/${month}/${slug}`

    try {
      const response = await axios_instance.get(url)
      return { blog: response.data }
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_blog_jobs = createAsyncThunk(
  'blog/fetch_blog_jobs',
  async (_, thunkAPI) => {
    let url = `/blog/job`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_blog_groups = createAsyncThunk(
  'blog/fetch_blog_groups',
  async (_, thunkAPI) => {
    let url = `/blog/group`

    try {
      const response = await axios_instance.get(url)

      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const add_blog = createAsyncThunk(
  'blog/add_blog',
  async (payload, thunkAPI) => {
    let url = '/blog'
    try {
      const response = await axios_instance.post(url, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({
        error: err.message,
        data: err.response ? err.response.data : null,
      })
    }
  }
)

const edit_blog = createAsyncThunk(
  'blog/edit_blog',
  async ({year=null, month=null, slug=null, payload=null}, thunkAPI) => {
    let url = `/blog/${year}/${month}/${slug}`

    try {
      const response = await axios_instance.patch(url, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const update_blog_state = createAsyncThunk(
  'blog/update_blog_state',
  async ({blog_id=null, payload=null}, thunkAPI) => {
    let url = `/blog/management/${blog_id}/state`

    try {
      const response = await axios_instance.patch(url, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const delete_blog = createAsyncThunk(
  'blog/delete_blog',
  async ({year=null, month=null, slug=null}, thunkAPI) => {
    let url = `/blog/${year}/${month}/${slug}`

    try {
      const response = await axios_instance.delete(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

//----------------------------------INQUIRY-------------------------------------------------

const add_inquiry = createAsyncThunk(
  'inquiry/add_inquiry',
  async (payload, thunkAPI) => {
    let url = '/inquiry'

    try {
      const response = await axios_instance.post(url, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const assign_user_inquiry = createAsyncThunk(
  'inquiry/assign_user_inquiry',
  async ({inquiry_id, payload}, thunkAPI) => {
    let url = `/inquiry/${inquiry_id}/assign`

    try {
      const response = await axios_instance.post(url, payload)
      return response.data
    }  catch (err) {
      return thunkAPI.rejectWithValue({
        error: err.message,
        data: err.response ? err.response.data : null,
      })
    }
  }
)

const unassign_user_inquiry = createAsyncThunk(
  'inquiry/unassign_user_inquiry',
  async ({assign_id}, thunkAPI) => {
    let url = `/inquiry/unassign/${assign_id}`

    try {
      const response = await axios_instance.delete(url)
      return response.data
    }  catch (err) {
      return thunkAPI.rejectWithValue({
        error: err.message,
        data: err.response ? err.response.data : null,
      })
    }
  }
)

const fetch_inquiry = createAsyncThunk(
  'inquiry/fetch_inquiry',
  async (inquiry_id, thunkAPI) => {
    let url = `/inquiry/${inquiry_id}`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_overdue_inquiries = createAsyncThunk(
  'inquiry/fetch_overdue_inquiries',
  async (_, thunkAPI) => {
    let url = `/inquiry/overdue`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_new_inquiries = createAsyncThunk(
  'inquiry/fetch_new_inquiries',
  async (_, thunkAPI) => {
    let url = `/inquiry/new`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_unassigned_inquiries = createAsyncThunk(
  'inquiry/fetch_unassigned_inquiries',
  async (_, thunkAPI) => {
    let url = `/inquiry/unassigned`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_user_inquiry_chart = createAsyncThunk(
  'inquiry/fetch_user_inquiry_chart',
  async (user_id, thunkAPI) => {
    let url = `/inquiry/pie-chart/`
    if (user_id) {
      url += `${user_id}`
    }

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_user_assigned_inquiry = createAsyncThunk(
  'inquiry/fetch_user_assigned_inquiry',
  async (user_id, thunkAPI) => {
    let url = `/inquiry/assigned/${user_id}`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_inquiry_state_history = createAsyncThunk(
  'inquiry/fetch_inquiry_state_history',
  async (inquiry_id, thunkAPI) => {
    let url = `/inquiry/${inquiry_id}/history`

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const mark_inquiry_as_read = createAsyncThunk(
  'inquiry/mark_inquiry_as_read',
  async ({inquiry_id, payload}, thunkAPI) => {
    let url = `/inquiry/${inquiry_id}`

    try {
      const response = await axios_instance.patch(url, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const fetch_inquiries = createAsyncThunk(
  'inquiry/fetch_inquiries',
  async ({ type = null, state = null, date = null, filter = null}, thunkAPI) => {
    let url = '/inquiry'
    
    const queryParams = []

    if (type !== null) {
      queryParams.push(`type=${type}`)
    }
    if (state !== null) {
      queryParams.push(`state=${state}`)
    }
    if (date !== null) {
      queryParams.push(`date=${date}`)
    }
    if (filter !== null) {
      queryParams.push(`filter=${filter}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      console.log(err)
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const count_inquiry = createAsyncThunk(
  'inquiry/count_inquiry',
  async ({ type = null, filter = null} = {}, thunkAPI) => {
    let url = '/inquiry/count'

    const queryParams = []

    if (type !== null) {
      queryParams.push(`type=${type}`)
    }

    if (filter !== null) {
      queryParams.push(`filter=${filter}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const update_inquiry = createAsyncThunk(
  'inquiry/update_inquiry',
  async ({ inquiry_id, payload }, thunkAPI) => {
    let url = `/inquiry/${inquiry_id}`

    try {
      const response = await axios_instance.patch(url, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const add_inquiry_note = createAsyncThunk(
  'inquiry/add_inquiry_note',
  async (payload, thunkAPI) => {
    let url = '/inquiry/note'
    try {
      const response = await axios_instance.post(url, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({
        error: err.message,
        data: err.response ? err.response.data : null,
      })
    }
  }
)

const fetch_inquiry_notes = createAsyncThunk(
  'inquiry/fetch_inquiry_notes',
  async (inquiry_id, thunkAPI) => {
    let url = `/inquiry/${inquiry_id}/note`
    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({
        error: err.message,
        data: err.response ? err.response.data : null,
      })
    }
  }
)

//----------------------------------META-------------------------------------------------

const fetch_meta_tags = createAsyncThunk(
  'meta/fetch_meta_tags',
  async (
    {
      page = null,
      per_page = null
    },
    thunkAPI
  ) => {
  // async (_, thunkAPI) => {
    let url = '/meta'
    const queryParams = []

    if (page !== null) {
      queryParams.push(`page=${page}`)
    }
    if (per_page !== null) {
      queryParams.push(`per_page=${per_page}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }
    
    try {
      const response = await axios_instance.get(url)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const update_meta_tag = createAsyncThunk(
  'meta/update_meta_tag',
  async ({ meta_id, payload }, thunkAPI) => {
    let url = `/meta/${meta_id}`

    try {
      const response = await axios_instance.patch(url, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

const get_guide_download = createAsyncThunk(
  'guide/get_guide_download',
  async ({ download = null}, thunkAPI) => {
    let url = '/guide'

    const queryParams = []

    if (download !== null) {
      queryParams.push(`download=${download}`)
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    try {
      const response = await axios_instance.get(url, {
        responseType: 'blob', // <-- Important! Tells axios to return a Blob (binary data)
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message })
    }
  }
)

export {
  fetch_csrf_token,
  fetch_products,
  fetch_product,
  fetch_related_products,
  fetch_related_products_by_group,
  fetch_product_groups,
  fetch_product_customizations,
  remove_product_from_project,
  fetch_projects,
  fetch_project,
  create_project,
  edit_project,
  duplicate_project,
  delete_project,
  create_space,
  edit_space,
  delete_space,
  add_product_to_space,
  edit_assigned_product,
  edit_assigned_product_customization,
  log_in,
  log_out,
  fetch_current_user,
  fetch_references,
  fetch_management_references,
  fetch_management_reference,
  update_reference_state,
  fetch_reference,
  fetch_reference_groups,
  add_reference,
  edit_reference,
  delete_reference,
  fetch_blogs,
  fetch_management_blogs,
  fetch_blog,
  fetch_management_blog,
  fetch_blog_jobs,
  fetch_blog_groups,
  add_blog,
  edit_blog,
  update_blog_state,
  delete_blog,
  sign_up,
  add_inquiry,
  count_inquiry,
  fetch_overdue_inquiries,
  fetch_new_inquiries,
  fetch_unassigned_inquiries,
  mark_inquiry_as_read,
  fetch_user_inquiry_chart,
  fetch_inquiry,
  fetch_inquiry_state_history,
  fetch_inquiries,
  assign_user_inquiry,
  unassign_user_inquiry,
  update_inquiry,
  add_inquiry_note,
  fetch_inquiry_notes,
  fetch_assignable_users,
  fetch_users,
  edit_user,
  deactivate_user,
  fetch_product_material,
  fetch_product_material_by_name,
  passwordResetRequest,
  fetch_user_requests,
  edit_user_request,
  update_user_information,
  fetch_meta_tags,
  update_meta_tag,
  fetch_user_assigned_inquiry,
  fetch_user,
  get_guide_download,
}
