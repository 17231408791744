import './styles.scss'
import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ArrowShortDown, ArrowShortUp, ArrowShortCirleDown, ArrowShortCirleUp } from '../../Icons'
import { useTranslation } from 'react-i18next'

const CustomDropdown = (props) => {
  const {
    placeholder,
    selected,
    onClickHandler,
    multiple,
    disabled = false,
    customStyles,
    options,
    showDropdownIcon = true,
    useUpperCase = false,
    showBorder = true,
    iconColor,
    customClass,
    iconType = 1,
    showLanguageMenu,
    setShowLanguageMenu,
    isLanguageMenu = false
  } = props
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  let container_class_name = `custom-dropdown-container`
  let selected_container_class_name = `custom-dropdown-selected-container`

  if (!showBorder) {
    container_class_name += ' no-border'
  }

  if (disabled) {
    container_class_name += ' disabled'
  }

  if (customClass) {
    selected_container_class_name += ` ${customClass}`
  }

  const handleClickOutside = () => {
    setShowMenu(false)
  }

  const handleClickOutsideLanguage = () => {
    setShowLanguageMenu(false)
  }

  const ref = useOutsideClick(handleClickOutside)

  const handleOptionClick = (option) => {
    onClickHandler(option)
    if (isLanguageMenu) {
      setShowLanguageMenu(false)
    }
  }

  const handleDropDownClick = () => {
    setShowMenu(!showMenu)
  }

  const handleDropDownClickLanguage = () => {
    setShowLanguageMenu(!showLanguageMenu)
  }

  if (isLanguageMenu) {
    return (
      <div className={`${container_class_name} no-highlight`}>
        <div
          className={selected_container_class_name}
          style={customStyles}
          onClick={() => handleDropDownClickLanguage()}
          ref={ref}
        >
          <div className='custom-dropdown-selected-content-wrapper'>
            {placeholder && !selected && (
              <div className='custom-dropdown-selected-content'>{placeholder}</div>
            )}
            {selected && !multiple && (
              <div className='custom-dropdown-selected-content'>
                {useUpperCase ? 
                  (
                    selected.translation ? (t(selected.translation).toUpperCase()) : (selected.name.toUpperCase())
                  ) : (
                    selected.translation ? (t(selected.translation)) : (selected.name)
                  )
                }
                {selected.icon ? (
                  <FontAwesomeIcon
                    className='custom-dropdown-selected-content-icon'
                    icon={selected.icon}
                  />
                ) : null}
              </div>
            )}
            {showDropdownIcon ? (
              iconType === 2 ?
                (
                  <div className='custom-dropdown-expand-icon'>
                    {
                      showLanguageMenu ? 
                      (
                        <ArrowShortCirleUp />
                      ) : (
                        <ArrowShortCirleDown />
                      )
                    }
                  </div>
                ) : (
                  <div className='custom-dropdown-expand-icon'>
                    {
                      showLanguageMenu ? 
                      (
                        <ArrowShortUp color={disabled ? '#a3a3a3' : iconColor}/>
                      ) : (
                        <ArrowShortDown color={disabled ? '#a3a3a3' : iconColor}/>
                      )
                    }
                  </div>
                )
              
            ) : null}
          </div>
        </div>
        {showLanguageMenu ? (
          <div className='custom-dropdown-menu-container'>
            <div className='custom-dropdown-menu-scrollable'>
              {options &&
                options.length > 0 &&
                options.map((option) => {
                  return (
                    <div
                      className='custom-dropdown-menu-entry'
                      key={option.id}
                      onClick={() => handleOptionClick(option)}
                    >
                      <div className='custom-dropdown-menu-entry-text'>
                        {useUpperCase ? 
                          (
                            option.translation ? (t(option.translation).toUpperCase()) : (option.name.toUpperCase())
                          ) : (
                            option.translation ? (t(option.translation)) : (option.name)
                          )
                        }
                      </div>
                      {option.icon ? (
                        <FontAwesomeIcon
                          className='custom-dropdown-menu-entry-icon'
                          icon={option.icon}
                        />
                      ) : null}
                    </div>
                  )
                })}
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div className={`${container_class_name} no-highlight`}>
      <div
        className={selected_container_class_name}
        style={customStyles}
        onClick={() => handleDropDownClick()}
        ref={ref}
      >
        <div className='custom-dropdown-selected-content-wrapper'>
          {placeholder && !selected && (
            <div className='custom-dropdown-selected-content'>{placeholder}</div>
          )}
          {selected && !multiple && (
            <div className='custom-dropdown-selected-content'>
              {useUpperCase ? 
                (
                  selected.translation ? (t(selected.translation).toUpperCase()) : (selected.name.toUpperCase())
                ) : (
                  selected.translation ? (t(selected.translation)) : (selected.name)
                )
              }
              {selected.icon ? (
                <FontAwesomeIcon
                  className='custom-dropdown-selected-content-icon'
                  icon={selected.icon}
                />
              ) : null}
            </div>
          )}
          {showDropdownIcon ? (
            iconType === 2 ?
              (
                <div className='custom-dropdown-expand-icon'>
                  {
                    showMenu ? 
                    (
                      <ArrowShortCirleUp />
                    ) : (
                      <ArrowShortCirleDown />
                    )
                  }
                </div>
              ) : (
                <div className='custom-dropdown-expand-icon'>
                  {
                    showMenu ? 
                    (
                      <ArrowShortUp color={disabled ? '#a3a3a3' : iconColor}/>
                    ) : (
                      <ArrowShortDown color={disabled ? '#a3a3a3' : iconColor}/>
                    )
                  }
                </div>
              )
            
          ) : null}
        </div>
      </div>
      {showMenu ? (
        <div className='custom-dropdown-menu-container'>
          <div className='custom-dropdown-menu-scrollable'>
            {options &&
              options.length > 0 &&
              options.map((option) => {
                return (
                  <div
                    className='custom-dropdown-menu-entry'
                    key={option.id}
                    onClick={() => handleOptionClick(option)}
                  >
                    <div className='custom-dropdown-menu-entry-text'>
                      {useUpperCase ? 
                        (
                          option.translation ? (t(option.translation).toUpperCase()) : (option.name.toUpperCase())
                        ) : (
                          option.translation ? (t(option.translation)) : (option.name)
                        )
                      }
                    </div>
                    {option.icon ? (
                      <FontAwesomeIcon
                        className='custom-dropdown-menu-entry-icon'
                        icon={option.icon}
                      />
                    ) : null}
                  </div>
                )
              })}
          </div>
        </div>
      ) : null}
    </div>
  )
}

const useOutsideClick = (callback) => {
  const ref = useRef()

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [ref, callback])

  return ref
}

export default CustomDropdown
