import './styles.scss'

import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ManagementSideBar from './components/ManagementSideBar'
import Inquiry from './components/Inquiry'
import DynamicInquiry from './components/Inquiries'
import Dashboard from './components/Dashboard'
import MetaManagement from '../../components/MetaManagement'
import UserTable from '../../components/UserTable'
import UserRequestTable from '../../components/UserRequestTable'
import User from './components/User'
import Overdue from './components/Dashboard/Overdue'
import BlogTable from './components/Editor/BlogTable'
import BlogEditor from './components/Editor/BlogEditor'
import ReferenceTable from './components/Editor/ReferenceTable'
import ReferenceEditor from './components/Editor/ReferenceEditor'

import {
  fetch_assignable_users,
} from '../../store/asyncThunk'

const ManagementPage = () => {
  const current_user = useSelector((state) => state.user.current_user)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useTranslation()
  // Read query params on page load
  const selectedParam = searchParams.get('selected') || 'Dashboard'
  const inquiryParam = searchParams.get('inquiry') || ''
  const blogParam = searchParams.get('blog') || ''
  const userParam = searchParams.get('user') || ''
  const referenceParam = searchParams.get('reference') || ''
  const metaParam = searchParams.get('meta') || ''
  // State initialization based on query params
  const [selected, setSelected] = useState(selectedParam)
  const [inquiry, setInquiry] = useState(inquiryParam)
  const [blog, setBlog] = useState(blogParam)
  const [user, setUser] = useState(userParam)
  const [reference, setReference] = useState(referenceParam)
  const [meta, setMeta] = useState(metaParam)

  const wrapperRef = useRef(null)
  const containerRef = useRef(null)
  const pageContainerRef = useRef(null)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const user_requests = useSelector((state) => state.management.user_requests)
  const [assignableUsers, setAssignableUsers] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!current_user) {
      if (i18n.language === 'FI')
        navigate('/kirjaudu_sisaan')
      else if (i18n.language === 'EN')
        navigate('/login')
    } else if (current_user && ![1,3,4,5].includes(current_user.role)) {
      navigate('/account')
    }
  }, [current_user, navigate])

  // Function to set default tab based on role
  const getDefaultTab = () => {
    if (current_user?.role === 5) return 'Blog'
    if (current_user?.role === 4) return 'Contact'
    return 'Dashboard'
  }

  useEffect(() => {
    const applySearchParams = () => {
      setSelected(searchParams.get('selected') || getDefaultTab())
      setInquiry(searchParams.get('inquiry') || inquiry)
      setBlog(searchParams.get('blog') || blog)
      setUser(searchParams.get('user') || user)
      setReference(searchParams.get('reference') || reference)
    }

    // Run on page load (initial state setup)
    applySearchParams()

    // Handle back/forward button navigation
    const handlePopState = () => applySearchParams()
    window.addEventListener('popstate', handlePopState)
    
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [current_user, searchParams])

  const handleSetSelectedTab = (tab) => {
    setSelected(tab)
    setSearchParams({ selected: tab })
  }

  const handleSetSelectedSelection = (selected, selection) => {
    setSelected(selected)
    if (selected === 'Inquiry') {
      setInquiry(selection)
      setSearchParams({ selected: selected, inquiry: selection })
    } else if (selected === 'Blog') {
      setBlog(selection)
      setSearchParams({ selected: selected, blog: selection })
    } else if (selected === 'Blog Editor') {
      setBlog(selection)
      setSearchParams({ selected: selected, blog: selection })
    } else if (selected === 'Reference') {
      setReference(selection)
      setSearchParams({ selected: selected, reference: selection })
    } else if (selected === 'Reference Editor') {
      setReference(selection)
      setSearchParams({ selected: selected, reference: selection })
    } else if (selected === 'User') {
      setUser(selection)
      setSearchParams({ selected: selected, user: selection })
    } else if (selected === 'Meta') {
      setMeta(selection)
      setSearchParams({ selected: selected, meta: selection })
    }
  }

  useEffect(() => {
    (async () => {
      await dispatch(fetch_assignable_users())
      .then((res) => {
        setAssignableUsers(res.payload)
      })
      .catch((err) => {
        console.log(err)
      })
    })()
  }, [user_requests])
  

  // Content for each tab
  const renderContent = () => {
    switch (selected) {
      case 'Project':
        return  <DynamicInquiry
                  type='project'
                  title='Project Inquiries'
                  assignableUsers={assignableUsers}
                  handleSetSelectedTab={handleSetSelectedTab}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                  setSelected={setSelected}
                />
      case 'Contact':
        return  <DynamicInquiry
                  type='contact'
                  title='Contact Requests'
                  assignableUsers={assignableUsers}
                  handleSetSelectedTab={handleSetSelectedTab}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                  setSelected={setSelected}
                />
      case 'Product':
        return  <DynamicInquiry
                  type='product'
                  title='Product Inquiries'
                  assignableUsers={assignableUsers}
                  handleSetSelectedTab={handleSetSelectedTab}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                  setSelected={setSelected}
                />
      case 'Guide':
        return  <DynamicInquiry
                  type='guide'
                  title='Guide Downloads'
                  assignableUsers={assignableUsers}
                  handleSetSelectedTab={handleSetSelectedTab}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                  setSelected={setSelected}
                  deadline={false}
                />
      case 'Inquiry':
        return <Inquiry
                  assignableUsers={assignableUsers} 
                  setSelected={setSelected} 
                  handleSetSelectedTab={handleSetSelectedTab}
                />
      case 'Overdue':
        return  <Overdue
                  assignableUsers={assignableUsers}
                  handleSetSelectedTab={handleSetSelectedTab}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                  setSelected={setSelected}
                  phone={true}
                  company={true}
                  city={true}
                  name={true}
                  message={true}
                  priority={true}
                  deadline={true}
                  rowsPerPageOptions={[10, 25, 50]}
                  rowsPerPageDefault={10}
                  size='medium'
                  title='Overdue Inquiries'
                  defaultOrderBy='deadline'
                  defaultOrderDirection='asc'
                />
      // case 'Unassigned':
      //   return <Unassigned
      //             assignableUsers={assignableUsers}
      //             handleSetSelectedTab={handleSetSelectedTab}
      //             handleSetSelectedSelection={handleSetSelectedSelection}
      //             setSelected={setSelected}
      //             title='Unassigned Inquiries'
      //           />
      case 'Dashboard':
        return <Dashboard
                  assignableUsers={assignableUsers}
                  handleSetSelectedTab={handleSetSelectedTab}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                />
      case 'Meta':
        return <MetaManagement />
      case 'Users':
        return <UserTable
                setSelected={setSelected}
                handleSetSelectedTab={handleSetSelectedTab}
                handleSetSelectedSelection={handleSetSelectedSelection}
              />
      case 'User':
        return <User
                  setSelected={setSelected}
                  assignableUsers={assignableUsers}
                  handleSetSelectedTab={handleSetSelectedTab}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                />
      case 'Requests':
        return <UserRequestTable />
      case 'Blog':
        return <BlogTable
                  setSelected={setSelected}
                  handleSetSelectedTab={handleSetSelectedTab}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                />
      case 'Blog Editor':
        return <BlogEditor 
                handleSetSelectedTab={handleSetSelectedTab}
              />
      case 'Reference':
        return <ReferenceTable
                  setSelected={setSelected}
                  handleSetSelectedTab={handleSetSelectedTab}
                  handleSetSelectedSelection={handleSetSelectedSelection}
                />
      case 'Reference Editor':
        return <ReferenceEditor
                handleSetSelectedTab={handleSetSelectedTab}
              />
      default:
        return <div>Select a tab to view content</div>
    }
  }

  useEffect(() => {
    const updateMarginLeft = () => {
        if (wrapperRef.current && containerRef.current && pageContainerRef.current) {
          const pageContainerWidth = pageContainerRef.current.offsetWidth || 0
          let containerWidth = containerRef.current.offsetWidth || 0
          let sidebarWidth = isCollapsed ? -70 : -200
          let marginLeft = sidebarWidth
          
          // Adjust container width if pageContainerWidth is smaller than containerWidth
          if (containerWidth + (-sidebarWidth) > pageContainerWidth && !isCollapsed) {
            containerWidth = containerWidth - 170
          }
          
          if (!isCollapsed) {
            if (containerWidth < 1600) {
              marginLeft = 0
            } else if (containerWidth < 1800) {
              let diff = 1800 - containerWidth
              marginLeft = sidebarWidth + diff
            }
          } else {
            if (containerWidth < 1630) {
              marginLeft = 0
            } else if (containerWidth < 1700) {
              let diff = 1700 - containerWidth
              marginLeft = sidebarWidth + diff
            }
          }
          wrapperRef.current.style.marginLeft = `${marginLeft}px`
        }
    }

    updateMarginLeft()

    window.addEventListener('resize', updateMarginLeft)
    return () => window.removeEventListener('resize', updateMarginLeft)
  }, [isCollapsed])

  return (
    <div ref={pageContainerRef} className='admin-page-container'>
      <div style={{ display: 'flex'}}>
        { current_user && [1, 3, 4, 5].includes(current_user.role) && (
            <ManagementSideBar
              selected={selected}
              setSelected={setSelected}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              containerRef={pageContainerRef}
              handleSetSelectedTab={handleSetSelectedTab}
            />
          )
        }
      </div>
      <div ref={containerRef} className='admin-page-body-container'>
        <div className='admin-page-body-wrapper'>
          <div ref={wrapperRef} className="admin-page-content">{renderContent()}</div>
        </div>
      </div>
    </div>
  )
}

export default ManagementPage
