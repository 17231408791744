const koivu_b_sarja = {
  name: 'Koivu B-sarja',
  longDescription: `Kauniit Koivu-ruukut ja -tilanjakajat sulautuvat monenlaiseen 
      sisustukseen ja tuovat tilaan luonnon rauhaa ja harmonista tunnelmaa. 
      Näyttävimmän asetelman luot yhdistelemällä erimuotoisia ja -kokoisia 
      Koivu-tuotteita.
      \\n
      Koivu B-sarjan ruukku on täydellinen sijoitettavaksi jo olemassa olevien 
      kalusteiden päälle. Näppärä tilanjakajaruukku tuo vihreyttä esimerkiksi 
      työpisteiden läheisyyteen tai hyllyköihin ilman tarvetta vaihtaa olemassa 
      olevaa tilanjakajaratkaisua.
      \\n
      Koivuvanerin joustavuuden ansiosta ruukut ovat miltei saumattomia, ja 
      luonnollinen puunsyy tekee jokaiseen valmistettuun ruukkuun uniikin 
      pinnan. Koivuvaneri tekee ruukuista kevyitä, mutta myös vakaita ja lujia, 
      pitkäaikaista käyttöä ja siirtoa kestäviä.
      \\n
      Koivu-ruukut toimitetaan yhdessä 100 % kierrätysmuovista valmistettujen 
      Orthex-sisäruukkujen kanssa. Asiantuntijamme auttavat mielellään ruukkujen 
      koon, muodon, sävyn ja jalkavaihtoehtojen valinnan kanssa. Paikallinen 
      tuotantomme Suomessa mahdollistaa nopean toimituksen, joka on tilauksesta 
      n. 1-2 kuukautta.`,
  measurements: [
    {
      type: 'B-665:',
      values: [
        {
          value: 'Leveys 665 mm'
        },
        {
          value: 'Syvyys 215 mm'
        },
        {
          value: 'Korkeus 185 mm'
        },
      ]
    }
  ],
  materials: [
    {
      type: 'Runko:',
      value: 'Suomalainen koivuvaneri'
    },
    {
      type: 'Sisäruukku:',
      value: 'Orthex, 100 % kierrätysmuovi '
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi'
    },
    {
      type: 'Käyttö:',
      value: 'Sisätilat',
      icon: 'indoor'
    },
  ],
  wlt: {},
  malli: {
    description: `Valitse malli`
  },
  ruukun_vari: {
    description: `Näkyvien osien viimeistely`
  },
  kasviryhma: {
    description: `Ruukulle soveltuvat kasvit.
XS ≈ 250 - 500 mm korkeat kasvit.`
  },
}

export default koivu_b_sarja