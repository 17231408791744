import './styles.scss'

import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'

import InquiriesModal from '../InquiriesModal/InquiriesModal'
import InquiryAssignUserModal from '../../../Inquiry/InquiryAssignUserModal'

import {
  TableCell,
  TableRow,
  Paper,
  Button,
  Popper,
  MenuItem,
  ClickAwayListener,
  Tooltip,
  IconButton,
  Box,
  Collapse,
  Typography,
  TableHead,
  TableBody,
  Table,
} from "@mui/material"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';


import { 
  addInquiry,
  updateCount,
  setSelectedInquiry,
} from '../../../../../../store/managementSlice'

import {
  update_inquiry,
} from '../../../../../../store/asyncThunk'

const dropdownOptions = [
  {
    id: 1,
    name: 'Open',
  },
  {
    id: 2,
    name: 'In Progress',
  },
  {
    id: 3,
    name: 'Offer Sent',
  },
  {
    id: 4,
    name: 'Offer Accepted',
  },
  {
    id: 5,
    name: 'Offer Rejected',
  },
  {
    id: 7,
    name: 'Closed',
  },
]

const dropdownOptionsPriority = [
  {
    priority: 1,
  },
  {
    priority: 2,
  },
  {
    priority: 3,
  },
]

const guideDropDown = [
  {
    id: 1,
    name: 'Open',
  },
  {
    id: 6,
    name: 'Guide Sent',
  },
]

const stateOptions = [
  {
    id: 1,
    count: 'open',
  },
  {
    id: 2,
    count: 'inProgress',
  },
  {
    id: 3,
    count: 'offerSent',
  },
  {
    id: 4,
    count: 'offerAccepted',
  },
  {
    id: 5,
    count: 'offerRejected',
  },
  {
    id: 6,
    count: 'guideSent',
  },
  {
    id: 7,
    count: 'closed',
  },
]

const InquiriesRow = (props) => {
  const {
    assignableUsers,
    item,
    index,
    stateFilter,
    date,
    priority,
    name,
    email,
    phone,
    company,
    city,
    message,
    state,
    deadline,
    collapsible = false,
    handleSetSelectedSelection,
  } = props;
  const [openDropdown, setOpenDropdown] = useState(null) // To track which dropdown is open
  const anchorRefs = useRef({})
  const anchorRefsPriority = useRef({})
  const [openDropdownPriority, setOpenDropdownPriority] = useState(null) // To track which dropdown is open
  const [copiedRow, setCopiedRow] = useState(null)
  const dispatch = useDispatch()
  const [showInquiriesModal, setShowInquiriesModalModal] = useState(false)
  const current_user = useSelector((state) => state.user.current_user)
  const [open, setOpen] = useState(false) // Track Row expansion
  const [showInquiryAssignUser, setShowInquiryAssignUser] = useState(false)

  const toggleShowInquiryAssignUser = () => {
    setShowInquiryAssignUser(!showInquiryAssignUser)
  }

  const toggleShowInquiriesModalModal = () => {
    setShowInquiriesModalModal(!showInquiriesModal)
  }

  const update_state = async (item, option) => {
    const payload = {
      state: option.id
    }
    
    setOpenDropdown(null)
    await dispatch(update_inquiry({inquiry_id: item.id, payload: payload}))
    .then((res) => {
      // check for error
      if (res.error) {
        console.log('Error updating inquiry state')
        return
      }
      // if stateFilter is 'all', we need to add the inquiry back to the list
      if (stateFilter === 'all') {
        dispatch(addInquiry({
          inquiry: res.payload.inquiry,
        }))
      }
      // update the count
      const old_state = stateOptions.find(e => e.id === item.state)
      const new_state = stateOptions.find(e => e.id === option.id)
      dispatch(updateCount({
        type: new_state.count,
        old_type: old_state.count,
      }))
    })
  }

  const update_priority = async (item, option) => {
    const payload = {
      priority: option.priority
    }
    setOpenDropdownPriority(null)
    dispatch(update_inquiry({inquiry_id: item.id, payload: payload}))
  }

  const get_status = (status) => {
    switch (status) {
      case 1:
        return 'Open'
      case 2:
        return 'In Progress'
      case 3:
        return 'Offer Sent'
      case 4:
        return 'Offer Accepted'
      case 5:
        return 'Offer Rejected'
      case 6:
        return 'Guide Sent'
      case 7:
        return 'Closed'
      default:
        return 'Unknown'
    }
  }

  const copyToClipboard = async (item) => {
    try {
      const type = item.type === 4 ? 'Guide Download' : item.type === 3 ? 'Product Inquiry' : item.type === 2 ? 'Project Inquiry' : 'Contact Request'
      const text =
        `Type: ${type || 'N/A'}\n
        State: ${get_status(item.state) || 'N/A'}
        Date: ${format_date(item.date_created) || 'N/A'}
        Name: ${item.name || 'N/A'}
        Email: ${item.email || 'N/A'}
        Phone: ${item.phone || 'N/A'}
        Company: ${item.company || 'N/A'}
        City: ${item.city || 'N/A'}
        Message: ${item.message || 'N/A'}
        ${item.project_id ? `Project ID: ${item.project_id}` : ''}
        `.replace(/^[ \t]+/gm, '')

      // ${item.project_id ? `Link: ${window.location.origin}/project/${item.project_id}` : ''}

      await navigator.clipboard.writeText(text)
      setCopiedRow(item.id) // Mark the row as copied
      // Reset after 2 seconds
      setTimeout(() => setCopiedRow(null), 2000);
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  const format_date = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    }
  }

  const format_date_deadline = (date) => {

    if (date) {
      return moment(date).format('DD.MM.YYYY')
    }
  }

  const isOverdue = (row) => {
    if ([4, 6, 7].includes(row.state)) {
      return false
    }
    moment.locale('fi')
    return moment().isAfter(row.deadline)
  }

  const handleClick = (id) => {
    setOpenDropdown(openDropdown === id ? null : id) // Toggle dropdown visibility
  }

  const handleClose = () => {
    setOpenDropdown(null) // Close the dropdown
  }

  const handleClickPriority = (id) => {
    setOpenDropdownPriority(openDropdownPriority === id ? null : id) // Toggle dropdown visibility
  }
  
  const handleClosePriority = () => {
    setOpenDropdownPriority(null) // Close the dropdown
  }

  const handelSelectInquiry = () => {
    // dispatch(setSelectedInquiry(item))
    handleSetSelectedSelection('Inquiry', item.id)
  }

  const getInquiryType = (type) => {
    switch (type) {
      case 1:
        return 'Contact'
      case 2:
        return 'Project'
      case 3:
        return 'Product'
      case 4:
        return 'Guide'
      default:
        return 'Unknown'
    }
  }

  return (
    <>
      <TableRow hover key={item.id}
        onClick = {() => handelSelectInquiry()}
        sx={
          isOverdue(item) ? { cursor: 'pointer', backgroundColor: '#FFEBEE' } : { cursor: 'pointer' }
        }
        className='inquiries-row'
      >
        { priority && (
            <TableCell sx={{ position: "relative", overflow: "visible" }}>
              <Button
                ref={(el) => (anchorRefsPriority.current[index] = el)}
                disableRipple
                onClick={(e) => {
                  e.stopPropagation()
                  handleClickPriority(index)
                }}
                sx={{ textAlign: 'left' }}
                className='inquiries-row-state-button'
                style={{ backgroundColor: 'transparent' }}
              >
                <Box
                  sx={{
                    color: 'black',
                    backgroundColor: item.priority === 1 ? '#ff924f' : item.priority === 2 ? '#20b9fa' : '#d9d9d9',
                    padding: '0.5rem',
                    borderRadius: '4rem',
                    fontWeight: 'bold',
                    width: '1rem',
                    height: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {item.priority}
                </Box>
              </Button>
              <Popper
                open={openDropdownPriority === index}
                anchorEl={anchorRefsPriority.current[index]}
                placement="bottom-end"
              >
                <ClickAwayListener
                  onClickAway={handleClosePriority}
                >
                  <Paper sx={{ p: 1 }}>
                    { 
                      dropdownOptionsPriority.map((option) => (
                        <MenuItem
                          key={option.priority}
                          onClick={(e) => {
                            e.stopPropagation()
                            update_priority(item, option)
                          }}
                        >
                          {option.priority}
                        </MenuItem>
                      ))
                    }
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </TableCell>
          )
        }
        { date && <TableCell>{format_date(item.date_created)}</TableCell>}
        { deadline && <TableCell>{format_date_deadline(item.deadline)}</TableCell>}
        
        { name && <TableCell>{item.name}</TableCell>}
        {/* { email && <TableCell>{item.email}</TableCell>} */}
        { message && <TableCell>{item.message || "N/A"}</TableCell>}
        {/* { phone && <TableCell>{item.phone || "N/A"}</TableCell>} */}
        { company && <TableCell>{item.company || "N/A"}</TableCell>}
        { city && <TableCell>{item.city || "N/A"}</TableCell>}
        { state && (
            <TableCell sx={{ position: "relative", overflow: "visible" }}>
              <Button
                ref={(el) => (anchorRefs.current[index] = el)}
                disableRipple
                onClick={(e) => {
                  e.stopPropagation()
                  handleClick(index)
                }}
                sx={{ textAlign: 'left' }}
                className='inquiries-row-state-button'
                style={{ backgroundColor: 'transparent' }}
              >
                {get_status(item.state)}
              </Button>
              <Popper
                open={openDropdown === index}
                anchorEl={anchorRefs.current[index]}
                placement="bottom-end"
              >
                <ClickAwayListener
                  onClickAway={handleClose}
                >
                  <Paper sx={{ p: 1 }}>
                    { item.type === 4 ?
                      (
                        guideDropDown.map((option) => (
                          <MenuItem
                            key={option.id}
                            onClick={(e) => {
                              e.stopPropagation()
                              update_state(item, option)
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))
                      ) : (
                        dropdownOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            onClick={(e) => {
                              e.stopPropagation()
                              update_state(item, option)
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))
                      )
                    }
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </TableCell>
          )
        }
        <TableCell>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Tooltip
              title='Copy to clipboard'
            >
                <ContentCopyIcon
                  fontSize='small'
                  color='action'
                  className='inquiries-clipboard-copy'
                  onClick={(e) => {
                    e.stopPropagation()
                    copyToClipboard(item)
                  }}
                  sx={{
                    cursor: 'pointer',
                    transition: 'color 0.2s ease-out',
                    color: copiedRow === item.id ? '#1da431' : 'action.active',
                    '&:hover': {
                      color: copiedRow === item.id ? '#1da431' : '#8E5CD9',
                    },
                  }}
                />
            </Tooltip>
            {collapsible && (
              <Tooltip
                title={open ? 'Collapse' : 'Expand'}
                // placement='left'
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  disableRipple
                  onClick={(e) => {
                    e.stopPropagation()
                    setOpen(!open)
                  }}
                >
                  {open ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell className='inquiries-row-inner-header' sx={{ width: '20%' }}>
                      <Box
                        sx={{display: 'flex', alignItems: 'center', gap: '1rem'}}
                      >
                        <Box>Assigned</Box>
                        { [1, 3].includes(current_user.role) && (
                            <Tooltip
                              title='Assign user'
                            >
                              <PersonAddAltOutlinedIcon
                                sx={{ 
                                  cursor: 'pointer',
                                  fontSize: 20,
                                }}
                                onClick={toggleShowInquiryAssignUser}
                              />
                            </Tooltip>
                          )
                        }
                      </Box>
                    </TableCell>
                    <TableCell className='inquiries-row-inner-header' sx={{ width: '15%' }}>Phone</TableCell>
                    <TableCell className='inquiries-row-inner-header' sx={{ width: '20%' }}>Email</TableCell>
                    <TableCell className='inquiries-row-inner-header' sx={{ width: '35%' }}>Message</TableCell>
                    <TableCell className='inquiries-row-inner-header' sx={{ width: '10%' }}>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                </TableBody>
                <TableBody>
                <TableRow>
                  <TableCell className='inquiries-row-cell-text'>
                    <Box
                      sx={
                        {
                          display: 'flex',
                          flexDirection: 'column'
                        }
                      }
                    >
                      {item.assigned_users ?
                        (
                          item.assigned_users.map((user) => (
                            <Box key={user.id}>
                              {`${user.role}: ${user.name}`}
                            </Box>
                          ))
                        ) : (
                          'N/A'
                        )
                      }
                    </Box>
                  </TableCell>
                  <TableCell>
                    {item.phone ? item.phone : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {item.email}
                  </TableCell>
                  <TableCell
                    className='inquiries-row-cell-text message'
                    sx={{ whiteSpace: 'pre-wrap' }}
                  >
                    {item.message ? item.message : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {getInquiryType(item.type)}
                  </TableCell>
                </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <InquiriesModal
        showing={showInquiriesModal}
        closeModal={toggleShowInquiriesModalModal}
        inquiry={item}
      />
      <InquiryAssignUserModal
        assignableUsers={assignableUsers}
        showing={showInquiryAssignUser}
        closeModal={toggleShowInquiryAssignUser}
        inquiry={item}
      />
    </>
  )
}

export default InquiriesRow
