import './styles.scss'

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Unassigned from '../Unassigned'
import Overdue from './Overdue'
import NewInquiry from './NewInquiry'
import UserPieChart from './UserPieChart'
import UserRequestTable from '../../../../components/UserRequestTable'
import moment from 'moment'


const Dashboard = (props) => {
  const {
    assignableUsers,
    handleSetSelectedSelection
  } = props
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const count = useSelector((state) => state.management.management_count)
  const user = useSelector((state) => state.user.current_user)

  if (user && [1, 3].includes(user.role)) {
    return (
      <div className='dashboard-container'>
        <div className='dashboard-content'>
          <div className='dashboard-content-left'>
            <Unassigned
              assignableUsers={assignableUsers}
              handleSetSelectedSelection={handleSetSelectedSelection}
              phone={false}
              company={true}
              city={true}
              name={true}
              message={false}
              rowsPerPageOptions={[5, 10, 25]}
              rowsPerPageDefault={5}
              size='small'
              title={`Unassigned Inquiries (${count.unassigned})`}
            />
            <Overdue
              assignableUsers={assignableUsers}
              handleSetSelectedSelection={handleSetSelectedSelection}
              phone={false}
              company={true}
              city={true}
              name={true}
              message={false}
              priority={true}
              deadline={true}
              rowsPerPageOptions={[5, 10, 25]}
              rowsPerPageDefault={5}
              size='small'
              title={`Overdue Inquiries (${count.overdue})`}
              defaultOrderBy='priority'
              defaultOrderDirection='asc'
            />
            <UserRequestTable
              count={count.userRequest}
              showSearch={false}
            />
          </div>
          {/* <div className='dashboard-content-right'>
            <UserPieChart />
          </div> */}
          
        </div>
  
      </div>
    )
  }

  // if (user && [4].includes(user.role)) {
  //   return (
  //     <div className='dashboard-container'>
  //       <div className='dashboard-content'>
  //         <div className='dashboard-content-left'>
  //           <NewInquiry
  //             setSelected={setSelected}
  //             phone={false}
  //             company={false}
  //             city={false}
  //             name={false}
  //             message={false}
  //             priority={true}
  //             rowsPerPageOptions={[5, 10, 25]}
  //             rowsPerPageDefault={5}
  //             size='small'
  //             title={`New Assigned Inquiries (${count.isNew})`}
  //             defaultOrderBy='priority'
  //             defaultOrderDirection='desc'
  //           />
  //           <Overdue
  //             setSelected={setSelected}
  //             phone={false}
  //             company={false}
  //             city={false}
  //             name={false}
  //             message={false}
  //             priority={false}
  //             deadline={true}
  //             rowsPerPageOptions={[5, 10, 25]}
  //             rowsPerPageDefault={5}
  //             size='small'
  //             title={`Overdue Inquiries (${count.overdue})`}
  //             defaultOrderBy='deadline'
  //             defaultOrderDirection='asc'
  //           />
  //         </div>
  //         <div className='dashboard-content-right'>
  //           <UserPieChart />
  //         </div>
          
  //       </div>
  
  //     </div>
  //   )
  // }

  return (
    <div className='dashboard-container'>
      <div className='dashboard-content'>
        <div className='dashboard-content-left'>
        </div>
        <div className='dashboard-content-right'>
        </div>
      </div>
    </div>
  )
}

export default Dashboard