const project = {
  backToMyAccount: 'Takaisin tiliini',
  dateCreated: 'Luotu pvm.',
  dateModified: 'Muokattu pvm.',
  status: 'Tila',
  editProjectInformation: 'Muokkaa projektin kuvausta',
  duplicateProject: 'Luo kopio projektista',
  deleteProject: 'Poista projekti',
  open: 'Avoin',
  pending: 'Odottaa',
  closed: 'Suljettu',
  inquirySent: 'Tarjouspyyntö lähetetty',
  cancelled: 'Peruutettu',
  unknown: 'Tuntematon',
  openProductSummary: 'Avaa projektin koonti',
  printProjectSummary: 'Voit tulostaa projektin koontisivulta',
  view: 'Katso',
  dateAdded: 'Lisätty pvm.',
  productName: 'Tuotteen nimi',
  allSpaces: 'Kaikki tilat',
  defaultSpace: 'Oletustila',
  editSpaces: 'Muokkaa tiloja',
  sort: 'Järjestä',
  product: 'Tuote',
  quantity: 'Määrä',
  addNewProduct: 'Lisää uusi tuote',
  noProductFound: 'Tuotetta ei löydy',
  sendInquiry: 'Pyydä tarjous',
  total: 'Yhteensä',
  products: 'Tuotteet',
  editProject: 'Muokkaa projektia',
  projectName: 'Projektin nimi',
  description: 'Kuvaus',
  confirm: 'Vahvista',
  cancel: 'Peruuta',
  duplicatedProjectName: 'Kopioi projektin nimi',
  deleteConfirmation: 'Poiston vahvistus',
  confirmDelete: 'Vahvista projektin poisto',
  confirmDeleteProduct:'Vahvista tuotteen poisto' ,
  delete: 'Poista',
  newInquiry: 'Uusi tarjouspyyntö',
  yourName: 'Nimi',
  phoneNumber: 'Puhelin',
  message: 'Viesti',
  email: 'Sähköposti',
  city: 'Paikkakunta',
  role: 'Rooli',
  company: 'Yritys',
  send: 'Lähetä',
  createNewSpace: 'Luo uusi tila',
  nameOfSpace: 'Tilan nimi',
  add: 'Lisää',
  rename: 'Nimeä uudelleen',
  editSpaceName: 'Muokkaa tilan nimeä',
  ok: 'Ok',
  deleteSpace: 'Poista tila',
  confirmDeleteSpace: 'Vahvista tilan poisto',
  addToProject: 'Lisää projektille',
  addToNewProject: 'Lisää uudelle projektille',
  searchForProject: 'Etsi projektia',
  yourSelection: 'Valintasi',
  productOverview: 'Tuotekatsaus',
  editYourSelection: 'Muokkaa valintaasi',
  closeEditView: 'Sulje muokkausnäkymä',
  quantity: 'Määrä',
  removeProduct: 'Poista tuote',
  createNewVersion: 'Luo uusi versio',
  copyToAnotherProject: 'Kopioi toiseen projektiin',
  copyToAnotherSpace: 'Kopioi toiseen tilaan',
  backToProject: 'Takaisin projektiin',
  configurateThisProduct: 'Muokkaa tuotetta',
  printProject: 'Tulosta projekti',
  byProducts: 'Tuotteen mukaan',
  bySpace: 'Tilan mukaan',
  inquirySentSuccess: `Kiitos, tarjouspyyntö vastaanotettu. Olemme yhteydessä sinuun mahdollisimman pian!`,
  offerInquired: 'Tarjous pyydetty',
  genericInquiryError: 'Failed to send inquiry, please try again',
  assignToSpace: 'Lisää tilaan',
  chooseSpace: 'Voit lisää tuotteen tiettyyn tilaan tai valita Oletustila',
  addToOtherProject: 'Lisää toiseen projektiin',
  productAddedTo: 'Tuotteet lisätty',
  selectedSpace: 'Valittu tila',
  goToProjectText: '-Paina "Siirry projektiin" jotta voit muokata, tulostaa tai lähettää projektin tarjouspyynnön ',
  continueText1: '-Paina "Jatka" jotta ikkuna sulkeutuu',
  continueText2: '-Paina "Jatka" jotta voit lisätä tuotteita projektiin',
  continue: 'Jatka',
  goToProject: 'Siirry projektiin',
  step: 'Vaihe',
  backtoStep: 'Palaa vaiheeseen',
  editProjectSuccessMessage: 'Edited project!',
  genericEditProjectError: 'Failed to edit project, please try again!',
  close: 'Close',
  genericDuplicateProjectError: 'Failed to duplicate project, please try again!',
  duplicateProjectSuccessMessage: 'Duplicated project!',
  genericDeleteProjectError: 'Failed to delete project, please try again!',
  genericAddSpaceError: 'Failed to create new space, please try again!',
  genericDeleteSpaceError: 'Failed to delete space, please try again!',
  genericRenameSpaceError: 'Failed to rename space, please try again!',
  genericAddProjectError: 'Failed to create new project, please try again!',
  genericDeleteProductError: 'Failed to delete product, please try again!',
}

export default project