const greenWall = {
  headerGroup: 'Green walls',
  headerTitle: 'The most versatile green walls with real plants.',
  infoCard1Title: 'Impressive',
  infoCard1Text1: 'Nature, atmosphere and charm',
  infoCard1Text2: 'Rich and varied plant species',
  infoCard1Text3: 'Well-being from real plants',
  infoCard2Title: 'Sustainable',
  infoCard2Text1: 'Designed and manufactured in Finland',
  infoCard2Text2: 'Patented technology',
  infoCard2Text3: 'Durable and recyclable parts',
  infoCard3Title: 'Easy',
  infoCard3Text1: 'Choose a ready-made size or customise',
  infoCard3Text2: 'Safe irrigation technology',
  infoCard3Text3: 'Installation and maintenance with guarantee for plant health',
  infoBox1Title: 'Green walls bring comfort and well-being to urban environments',
  infoBox1Text: `Our versatile green walls add a luscious greenery to both indoor and outdoor spaces. 
      Our eye-catching green walls are a modern way to demonstrate green values, care for nature and 
      create comfort and well-being in an urban environment.`,
  infoBox2Text: `Indoors, our green walls have many uses; dividing the space, directing the flow in 
      the space and creating a nice atmosphere. Green walls enhance both the space and mood. Plants 
      have been shown to contribute to our well-being by relieving stress and supporting creativity, 
      among other things. A green wall can be the size of a painting or the size of a whole wall, 
      taking the spectator on a jungle journey.
      \\n
      Outdoors, the abundant vegetation of green walls supports urban biodiversity, providing 
      shelter and food for various insects. Green walls absorb traffic noise and provide a quiet, 
      shady place to rest amidst the greenery. At their best, our whole-facade green walls, which 
      use rainwater for irrigation, delay stormwater runoff, thus saving potable water and easing 
      the strain on drains and nature.
      \\n
      We only use real plants in our green walls, which is why we also supply plant lights where 
      required. The maintenance-free mosses and lichens we use are stabilised and will remain 
      beautifully green for years. The green walls we care for always come with a health guarantee.`,
  infoBox3Title: 'A Finnish innovation',
  infoBox3Text: `InnoGreen’s green walls are designed and manufactured in Finland. Our first modular 
      green wall was completed back in 2010. Over the years, we have further developed our green wall 
      and explored its potential for micro-plastic filtration and stormwater retention, among other things.
      \\n
      Our durable, modular green wall construction, combined with our professional maintenance, has 
      enabled us to offer our customers a truly versatile and high-quality range of green walls 
      with a health guarantee.`,
  infoBox4Text: `All of our green wall components are long-lasting and recyclable, minimising waste. 
      By using the same basic modular design for all our green walls, we are able to make extremely 
      efficient use of materials and reuse the parts of green walls that have been returned to us.
      \\n
      Our patented irrigation technology is the most energy-efficient and water-saving on the market. 
      Our irrigation system is a safe way to ensure that your green wall plants get enough water 
      without wasting a drop. Our smallest green walls are watered by hand by our maintenance crew.`,
  wltTitle: "Protecting the world's rainforests",
  wltLink: 'World Land Trust.',
  wltText1: `By choosing a green wall from InnoGreen, you are joining us in protecting the world's 
      rainforests in partnership with the`,
  wltText2: `For every square metre of green wall sold or rented, we protect 1000 square metres of 
      rainforest. By the end of 2023, we had already protected 2,000,000 square metres of rainforest 
      together with our customers.`,
  productGroupLink: 'Read more about Green walls'
}

export default greenWall