const koivu_b_sarja = {
  name: 'SE Koivu B-sarja',
  longDescription: `SE The beautiful Koivu pots and room dividers are suitable for 
      a wide range of interiors, bringing a sense of natural peace and harmony 
      to any space. For the most spectacular arrangement, combine different 
      shapes and sizes of Koivu products.
      \\n
      The Koivu B-series pot is perfect for placing on top of existing 
      furniture. The handy divider pot brings greenery to areas such as 
      workstations or shelving units without having to replace existing divider 
      solutions.
      \\n
      The flexibility of birch plywood makes the pots almost seamless, and the 
      natural wood grain gives each pot a unique surface. Birch plywood makes 
      the pots lightweight, but also stable and strong, resistant to long-term 
      use and transportation.
      \\n
      The Koivu pots are delivered together with Orthex inner pots made of 100% 
      recycled plastic. Our experts are happy to help you with the size, shape, 
      shade and leg options of your pots. Our local production in Finland allows 
      for fast delivery, which is approximately 1-2 months from order.`,
  measurements: [
    {
      type: 'SE B-665:',
      values: [
        {
          value: 'Width 665 mm'
        },
        {
          value: 'Depth 215 mm'
        },
        {
          value: 'Height 185 mm'
        },
      ]
    }
  ],
  materials: [
    {
      type: 'SE Body:',
      value: 'Finnish birch plywood'
    },
    {
      type: 'SE Inner pot:',
      value: 'Orthex, 100% recycled plastic'
    },
    {
      type: 'SE Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'SE Usage:',
      value: 'Indoor',
      icon: 'indoor'
    },
  ],
  wlt: {},
  malli: {
    description: `SE Valitse malli`
  },
  ruukun_vari: {
    description: `SE Näkyvien osien viimeistely`
  }
}

export default koivu_b_sarja