import './styles.scss'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatches } from 'react-router-dom'

import HeaderBox from '../../components/HeaderBox'
import InfoCardGroup from '../../components/InfoCardGroup'
import InfoBox from '../../components/InfoBox'
import { HandShake, City } from '../../components/CardIcon'
import ImageCard from '../../components/ImageCard'

import infoBox1ImgSrc from '../../assets/images/Outdoor/ulkoalueet-1.jpg'
import imageCardImgSrc from '../../assets/images/Outdoor/ulkoalueet-2.jpg'
import infoBox2ImgSrc from '../../assets/images/Outdoor/ulkoalueet-3.jpg'
import infoBox3ImgSrc from '../../assets/images/GreenFurniture/viherkalusteet-1.jpg'

const cards = [
  {
    title: 'outDoor.infoCard1Title',
    body: [
      { text: 'outDoor.infoCard1Text1' },
      { text: 'outDoor.infoCard1Text2' },
      { text: 'outDoor.infoCard1Text3' },
    ],
    icon: <HandShake/>
  },
  {
    title: 'outDoor.infoCard2Title',
    body: [
      { text: 'outDoor.infoCard2Text1' },
      { text: 'outDoor.infoCard2Text2' },
      { text: 'outDoor.infoCard2Text3' },
    ],
    icon: <City/>
  },
]

const OutDoor = () => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    const matches = useMatches()
    const routeLanguage = matches.find(m => m.handle)?.handle?.language
    const storedLanguage = i18n.language
  
    useEffect(() => {
      // Change the language if the route language is different from the stored language
      if (storedLanguage !== routeLanguage) {
        i18n.changeLanguage(routeLanguage);
      }
    }, [routeLanguage])

  return (
    <>
      <div className='out-door-container'>
        <div className='out-door-body-container'>
          <HeaderBox
            titleText={t('outDoor.headerTitle')}
            pageText={t('outDoor.headerGroup').toUpperCase()}
            videoSrc='https://vimeo.com/1046353848'
            titleBackgroundColor='purple'
          />

          <InfoCardGroup cards={cards} />

          <InfoBox
            title={t('outDoor.infoBoxTitle1')}
            imgSrc={infoBox1ImgSrc}
            text={t('outDoor.infoBoxText1')}
          />
          
          <ImageCard imgSrc={imageCardImgSrc} alt='Koivu Series Image'/>

          <InfoBox
            title={t('outDoor.infoBoxTitle2')}
            imgSrc={infoBox2ImgSrc}
            text={t('outDoor.infoBoxText2')}
            reverse={true}
          />

          <InfoBox
            title={t('outDoor.infoBoxTitle3')}
            imgSrc={infoBox3ImgSrc}
            type='2'
            text={t('outDoor.infoBoxText3')}
            buttonText={t('common.greenFurniture').toUpperCase()}
            backgroundColor='teal'
            link={i18n.language === 'FI' ? '/viherkalusteet' : 'green-furniture'}
          />

        </div>
      </div>
    </>
  )
}

export default OutDoor
