const koivu = {
  headerGroup: 'SE Koivu',
  headerTitle: 'SE Suomalainen ja harmoninen Koivu on tiedostavan valinta.',
  infoCard1Title: 'SE Kestävämpi vaihtoehto',
  infoCard1Text1: 'SE Suuniteltu ja valmistettu Suomessa',
  infoCard1Text2: 'SE Materiaalina suomalainen koivuviilu',
  infoCard1Text3: 'SE Sisäruukku 100 % kierrätysmuovia',
  infoCard2Title: 'SE Monipuolinen',
  infoCard2Text1: 'SE Sopii monenlaiseen sisustukseen',
  infoCard2Text2: 'SE Sijoita yksittäin tai asetelmina',
  infoCard2Text3: 'SE Neljä kaunista värivaihtoehtoa' ,
  textCard1: `Koivu-tuoteperheen ruukut, tilanjakajat, vihertaulut ja viherseinät on 
      suunniteltu ja valmistettu Suomessa. Ajaton muotoilu tekee Koivu-tuotteista 
      sopivia monenlaiseen tilaan ja tunnelmaan.`,
  infoBox1Title: 'SE Kepeää kauneutta',
  infoBox1Text: `SE Koivu on suomalaisen puumuotoilun taidonnäyte, joka kestää aikaa ja katseita. 
      Se edustaa kestävämpää viheralaa ja on tiedostavan sisustajan valinta. Ajaton muotoilu tekee 
      Koivu-tuotteista sopivia monenlaiseen tilaan ja tunnelmaan, sekä erilaisten tuotteiden 
      kirjo tarjoaa monenlaisia ratkaisuja.
      \\n
      Koivuviiluiset tuotteet ovat rakenteeltaan kevyitä, mutta kestäviä, minkä vuoksi niitä on 
      myös tarvittaessa helppo siirrellä. Tuotteet lisäävät viihtyisyyttä ja toimivat tilanjakajina 
      sekä yksinään että asetelmina. Tuoteperheestä löydät luonnon rauhaa ja harmoniaa henkivät 
      ruukut, tilanjakajat, taulut ja viherseinät.`,
  infoBox2Title: 'SE Kotimaista edelläkävijyyttä',
  infoBox2Text: `SE Kaikki lähti liikkeelle Koivu-ruukuista, joiden taustalla oli tahto luoda kestävämpiä 
            vihersisustusratkaisuja. Vaadimme ruukuiltamme muutakin kuin kauniin ulkonäön . niiden tulee olla turvallisia, 
            vakaita sekä valmistettu ja kuljetettu kestävän kehityksen mukaisesti, ympäristö ja sosioekonominen 
            vastuullisuus huomioiden. Kun arvoihimme sopivaa kumppania ei tuntunut löytyvän, jäi jäljelle vain yksi 
            vaihtoehto: Ruukut täytyisi tehdä itse. Ensimmäiset Koivu-ruukut valmistuivat keväällä 2022.
            \\n
            InnoGreenin Koivu-tuotteet on suunniteltu sekä valmistettu Suomessa suomalaisesta koivuvanerista 
            yhteistyössä paikallisten pienituottajien ja puunkäsittelyn ammattilaisten kanssa. Matka kotimaiselta 
            tehtaalta asiakkaamme tiloihin on varson lyhyt.`,
  productGroupLink: 'SE Lue lisää Koivu-tuotteista'
}

export default koivu