import './styles.scss'

import { useMatches } from "react-router-dom";
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import HeaderBox from '../../components/HeaderBox'
import InfoCardGroup from '../../components/InfoCardGroup'
import InfoBox from '../../components/InfoBox'
import { CheckMark, HandShake, Sustainable } from '../../components/CardIcon'

import headerImgSrc from '../../assets/images/GreenService/vihreys-palveluna-1.jpg'
import infoBox1ImgSrc from '../../assets/images/GreenService/vihreys-palveluna-2.jpg'
import infoBox2ImgSrc from '../../assets/images/GreenService/vihreys-palveluna-3.jpg'
import infoBox3ImgSrc from '../../assets/images/GreenService/vihreys-palveluna-4.jpg'

const cards = [
  {
    title: 'greenService.infoCard1Title',
    body: [
      { text: 'greenService.infoCard1Text1' },
      { text: 'greenService.infoCard1Text2' },
      { text: 'greenService.infoCard1Text3' },
    ],
    icon: <CheckMark/>
  },
  {
    title: 'greenService.infoCard2Title',
    body: [
      { text: 'greenService.infoCard2Text1' },
      { text: 'greenService.infoCard2Text2' },
      { text: 'greenService.infoCard2Text3' },
    ],
    icon: <HandShake/>
  },
  {
    title: 'greenService.infoCard3Title',
    body: [
      { text: 'greenService.infoCard3Text1' },
      { text: 'greenService.infoCard3Text2' },
      { text: 'greenService.infoCard3Text3' },
    ],
    icon: <Sustainable/>
  },
]

const infoBoxText = [
  'greenService.infoBoxBullet1',
  'greenService.infoBoxBullet2',
  'greenService.infoBoxBullet3',
  'greenService.infoBoxBullet4',
  'greenService.infoBoxBullet5',
  'greenService.infoBoxBullet6',
]

const GreenService = () => {
  const { t, i18n } = useTranslation()
  const matches = useMatches()
  // Get the language from the route metadata
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='green-service-container'>
        <div className='green-service-body-container'>
          <HeaderBox
            titleText={t('greenService.headerTitle')}
            pageText={t('greenService.headerGroup').toUpperCase()}
            imgSrc={headerImgSrc}
            titleBackgroundColor='purple'
          />

          <InfoCardGroup cards={cards} />

          <InfoBox
            title={t('greenService.infoBoxTitle1')}
            imgSrc={infoBox1ImgSrc}
            text={t('greenService.infoBoxText1')}
          />

          <InfoBox
            title={t('greenService.infoBoxTitle2')}
            imgSrc={infoBox2ImgSrc}
            reverse={true}
            text={infoBoxText}
          />

          <InfoBox
            title={t('greenService.productLinkTitle')}
            imgSrc={infoBox3ImgSrc}
            type='2'
            buttonText={t('common.products').toUpperCase()}
            backgroundColor='turquise'
            link='products'
          />
        </div>
      </div>
    </>
  )
}

export default GreenService
