const greenService = {
  headerGroup: 'Green as a service',
  headerTitle: 'Rent versatile greenery without compromises.',
  infoCard1Title: 'Easy',
  infoCard1Text1: 'Everything from design to maintenance in one package',
  infoCard1Text2: 'Abundant greenery without big investments',
  infoCard1Text3: 'A fixed monthly price that covers everything',
  infoCard2Title: 'Flexible',
  infoCard2Text1: 'Rent only when needed, without long periods of commitment',
  infoCard2Text2: 'Change or scale the package during the contract period',
  infoCard2Text3: 'At the end of the contract, we will pick up the products',
  infoCard3Title: 'Sustainable',
  infoCard3Text1: 'We prefer domestic products, such as the Koivu pots',
  infoCard3Text2: 'We provide only real plants',
  infoCard3Text3: 'We aim to recycle or re-rent all plants and products',
  infoBoxTitle1: 'Welcoming and sustainable spaces',
  infoBoxText1: `We strive to offer greenery as easily and as sustainably as possible, which 
      is why we also offer all InnoGreen solutions as a flexible service. For a fixed 
      monthly fee, our service includes everything you need for a welcoming and green 
      space: design, products, plants, installation and maintenance.
      \\n
      InnoGreen's own domestic products, such as green walls, Koivu products and green 
      furniture, can be found in our design tool, where you can easily compare products 
      and design your own stunning solution. When using our own products, the rental 
      period is entirely flexible, allowing you to choose the duration that suits 
      you best and adjust the product mix at any time. At the end of the rental 
      period, all products and plants are either recycled or re-rented.
      \\n
      You can also always ask an InnoGreen expert to help you design a luscious green 
      environment. Products that are not InnoGreen's own can be rented with a 36-month 
      fixed-term contract.`,
  infoBoxTitle2: 'How does Green as a service work?',
  infoBoxBullet1: "Choose the products you like from InnoGreen's wide range of own products or ask our designer to help you.",
  infoBoxBullet2: 'Choose your rental period.',
  infoBoxBullet3: 'You will receive an offer that includes everything from design to maintenance.',
  infoBoxBullet4: "Everything ok? We'll roll up our sleeves and deliver the planted greenery at the agreed time.",
  infoBoxBullet5: 'We regularly care for your plants and products and plant seasonal outdoor plants. At the end of the contract, we pick up the products.',
  infoBoxBullet6: 'We service and clean the products returned from the rental before re-renting them. Plants and products that have reached the end of their life cycle will be recycled.',
  productLinkTitle: 'Sound good? Start planning your cosy green space',
  productLink: ' here!'
}

export default greenService

