import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import Layout from '../layouts'
import Home from '../layouts/Home'
import Contact from '../layouts/Contact'
import Product from '../layouts/Product'
import Products from '../layouts/Products'
import Project from '../layouts/Project'
import ProductDetails from '../layouts/ProductDetails'
import ProductSummary from '../layouts/ProductSummary'
import Account from '../layouts/Account'
import GreenService from '../layouts/GreenService'
import GreenInterior from '../layouts/GreenInterior'
import GreenWall from '../layouts/GreenWall'
import Koivu from '../layouts/Koivu'
import References from '../layouts/References'
import Reference from '../layouts/Reference'
import Blogs from '../layouts/Blogs'
import Blog from '../layouts/Blog'
import OutDoor from '../layouts/OutDoor'
import CustomSolution from '../layouts/CustomSolution'
import GreenFurniture from '../layouts/GreenFurniture'
import WellBeing from '../layouts/WellBeing'
import Company from '../layouts/Company'
import Sustainability from '../layouts/Sustainability'
import Work from '../layouts/Work'
// import ReferenceEditor from '../layouts/ReferenceEditor'
// import BlogEditor from '../layouts/BlogEditor'
import Innovation from '../layouts/Innovation'
import Search from '../layouts/Search'
import Login from '../layouts/Login'
import ManagementPage from '../layouts/ManagementPage'
import NotFound from '../layouts/404'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path:'vihreys_palveluna',
        element: <GreenService />,
        handle: { language: 'FI' },
      },
      {
        path:'green-as-a-service',
        element: <GreenService />,
        handle: { language: 'EN' },
      },
      {
        path:'vihersisustus',
        element: <GreenInterior />,
        handle: { language: 'FI' },
      },
      {
        path:'green-interior-design',
        element: <GreenInterior />,
        handle: { language: 'EN' },
      },
      {
        path:'viherseinat',
        element: <GreenWall />,
        handle: { language: 'FI' },
      },
      {
        path:'viherseina',
        element: <GreenWall />,
        handle: { language: 'FI' },
      },
      {
        path:'green-walls',
        element: <GreenWall />,
        handle: { language: 'EN' },
      },
      {
        path:'koivu',
        element: <Koivu />,
        handle: { language: 'FI' },
      },
      {
        path:'koivu/en',
        element: <Koivu />,
        handle: { language: 'EN' },
      },
      {
        path:'viherkalusteet',
        element: <GreenFurniture />,
        handle: { language: 'FI' },
      },
      {
        path:'green-furniture',
        element: <GreenFurniture />,
        handle: { language: 'EN' },
      },
      {
        path:'yritys',
        element: <Company />,
        handle: { language: 'FI' },
      },
      {
        path:'about-us',
        element: <Company />,
        handle: { language: 'EN' },
      },
      {
        path:'toihin-innoGreenille',
        element: <Work />,
        handle: { language: 'FI' },
      },
      {
        path:'work-for-innogreen',
        element: <Work />,
        handle: { language: 'EN' },
      },
      {
        path:'innovaatiot',
        element: <Innovation />,
        handle: { language: 'FI' },
      },
      {
        path:'innovations',
        element: <Innovation />,
        handle: { language: 'EN' },
      },
      {
        path:'hae',
        element: <Search />,
      },
      {
        path:'kirjaudu_sisaan',
        element: <Login />,
        handle: { language: 'FI' },
      },
      {
        path:'login',
        element: <Login />,
        handle: { language: 'EN' },
      },
      {
        path:'vastuullisuus',
        element: <Sustainability />,
        handle: { language: 'FI' },
      },
      {
        path:'sustainability',
        element: <Sustainability />,
        handle: { language: 'EN' },
      },
      {
        path:'vihreydesta_hyvinvointia',
        element: <WellBeing />,
        handle: { language: 'FI' },
      },
      {
        path:'greenery-for-well-being',
        element: <WellBeing />,
        handle: { language: 'EN' },
      },
      {
        path:'ulkoalueet',
        element: <OutDoor />,
        handle: { language: 'FI' },
      },
      {
        path:'outdoor-areas',
        element: <OutDoor />,
        handle: { language: 'EN' },
      },
      {
        path:'raataloidyt_ratkaisut',
        element: <CustomSolution />,
        handle: { language: 'FI' },
      },
      {
        path:'tailored-solutions',
        element: <CustomSolution />,
        handle: { language: 'EN' },
      },
      {
        path: 'yhteystiedot',
        element: <Contact />,
        handle: { language: 'FI' },
      },
      {
        path: 'contact-us',
        element: <Contact />,
        handle: { language: 'EN' },
      },
      {
        path: 'referenssit',
        element: <References />,
        handle: { language: 'FI' },
      },
      {
        path: 'references',
        element: <References />,
        handle: { language: 'EN' },
      },
      {
        path: 'referenssit/:slug',
        element: <Reference />,
      },
      {
        path: 'references/:slug',
        element: <Reference />,
        handle: { language: 'EN' },
      },
      // {
      //   path: 'referenssit/:slug/editor',
      //   element: <ReferenceEditor />,
      // },
      // {
      //   path: 'referenssit/editor',
      //   element: <ReferenceEditor />,
      // },
      {
        path: 'blogi',
        element: <Blogs />,
        handle: { language: 'FI' },
      },
      {
        path: 'blog',
        element: <Blogs />,
        handle: { language: 'EN' },
      },
      {
        path :'/:year/:month/:slug',
        element: <Blog />,
      },
      // {
      //   path :'/:year/:month/:slug/editor',
      //   element: <BlogEditor />,
      // },
      // {
      //   path: 'blogi/editor',
      //   element: <BlogEditor />,
      // },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'management',
        element: <ManagementPage />,
      },
      {
        path: 'tuotteet',
        element: <Products />,
        handle: { language: 'FI' },
      },
      {
        path: 'products',
        element: <Products />,
        handle: { language: 'EN' },
      },
      {
        path: 'tuotteet/:slug',
        element: <Product />,
        handle: { language: 'FI' },
      },
      {
        path: 'products/:slug',
        element: <Product />,
        handle: { language: 'EN' },
      },
      {
        path: 'tuotteet/:slug/details',
        element: <ProductDetails />,
        handle: { language: 'FI' },
      },
      {
        path: 'products/:slug/details',
        element: <ProductDetails />,
        handle: { language: 'EN' },
      },
      {
        path: 'project/:project_id',
        element: <Project />,
      },
      {
        path: 'project/:project_id/product-summary',
        element: <ProductSummary />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
])

export default router
