import './styles.scss'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { get_decoded_string } from '../../plugins/helper'

import { ArrowSimple } from '../../components/Icons'
import { InDoor, OutDoor } from '../../components/Icons'

import { selectCustomizedProduct } from '../../store/productSlice'

const ProductGroup = (props) => {
  const {
    group = null,
    link = '',
    background =' ',
    linkText =' ',
    responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 3000, min: 1800 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 1800, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
      }
    },
  } = props
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const handleProductClick = (product) => {
    dispatch(selectCustomizedProduct({ ...product, customizations: [] }))
    if (i18n.language === 'FI') {
      navigate('/tuotteet/' + product.slug)
    } else if (i18n.language === 'EN') {
      navigate('/products/' + product.en_slug)
    }
  }

  let containerClass = ''
  if (location && location.pathname.includes('/tuotteet') || location.pathname.includes('/products')) {
    containerClass += 'product-page'
  }

  const getLink = (name) => {
    if (name === 'Koivu-ruukut') {
      return (
        <>
          <div
            className='product-group-title-link-container no-select'
          >
            <Link
              className='product-group-title-link-text'
              to={i18n.language === 'FI' ? '/koivu' : '/koivu/en'}
            >
              {t('koivu.productGroupLink').toUpperCase()}
            </Link>
            <ArrowSimple size={12} strokeWidth={0.1}/>
          </div>
        </>
      )
    } else if (name === 'Viherseinät') {
      return (
        <>
          <div
            className='product-group-title-link-container no-select'
          >
            <Link
              className='product-group-title-link-text'
              to={i18n.language === 'FI' ? '/viherseina' : '/green-walls'}
            >
              {t('greenWall.productGroupLink').toUpperCase()}
            </Link>
            <ArrowSimple size={12} strokeWidth={0.1}/>
          </div>
        </>
      )
    } else if (name === 'Viherkalusteet') {
      return (
        <>
          <div
            className='product-group-title-link-container no-select'
          >
             <Link
              className='product-group-title-link-text'
              to={i18n.language === 'FI' ? '/viherkalusteet' : '/green-furniture'}
            >
              {t('greenFurniture.productGroupLink').toUpperCase()}
            </Link>
            <ArrowSimple size={12} strokeWidth={0.1}/>
          </div>
        </>
      )
    } else if (link) {
      return (
        <>
          <div
            className='product-group-title-link-wrapper no-select custom'
          >
            <Link
              className='product-group-title-link-text custom'
              to={i18n.language === 'FI' ? '/tuotteet' : '/products'}
            >
              {t(`products.${linkText}`)}
            </Link>
            <ArrowSimple size={12} strokeWidth={0.1}/>
          </div>
        </>
      )
    }
    else {
      return <></>
    }
  }

  const getIcon = (usage) => {
    if (usage.length === 2) {
      return <div className='product-group-custom-carousel-entry-product-icon'><OutDoor size={22}/>&nbsp;&nbsp;<InDoor size={22}/></div>
    }
    else if (usage[0].name === 'indoor') {
      return <div className='product-group-custom-carousel-entry-product-icon'><InDoor size={22}/></div>
    }
    else if (usage[0].name === 'outdoor') {
      return <div className='product-group-custom-carousel-entry-product-icon'><OutDoor size={22}/></div>
    }
    else {
      return <></>
    }
  }

  return (
    <>
      <div className={`product-group-container ${background} ${containerClass}`}>
        <div className='product-group-body-container'>
          <div className='product-group-title-container'>
            <div className='product-group-title-text'>
              {t(`products.${get_decoded_string(group.name)}`)}
            </div>
            {getLink(group.name)}
          </div>

          <Carousel
            responsive={responsive}
            containerClass='product-group-custom-carousel'
            itemClass='product-group-custom-carousel-entry-container'
          >
            {group.products &&
              group.products.map((product) => {
                let descriptions = [];
                product.customizations.forEach(item => {
                    descriptions.push(t(`products.${get_decoded_string(item.name)}`));

                });
                let description = descriptions.join(' | ');

                return (
                  <div
                    className='product-group-custom-carousel-entry'
                    key={product.id}
                    onClick={() => handleProductClick(product)}
                  >
                    <div className='product-group-custom-carousel-entry-image-container'>
                      {product.imgSrc ? (
                        <img
                          src={product.imgSrc}
                          alt={`product - ${product.id}`}
                        />
                      ) : <div className='product-group-custom-carousel-entry-no-image'></div>}
                    </div>
                    <div className='product-group-custom-carousel-entry-product-info'>
                      <div className='product-group-custom-carousel-entry-product-name-container'>
                        <div className='product-group-custom-carousel-entry-product-name hover-underline-product-group'>
                          {t(`${get_decoded_string(product.name)}.name`)}
                        </div>
                        <div className='product-group-custom-carousel-entry-product-icon-wrapper'>
                          {getIcon(product.product_usage)}
                        </div>
                      </div>
                      {description ?
                        (
                          <>
                            <div style={{ fontSize: '0.8125rem', fontFamily: 'DM Mono', fontWeight: '500' }}>{t('products.customize')}</div>
                            <div className='product-group-custom-carousel-entry-product-customize-entry-container'>
                              <div className='product-group-custom-carousel-entry-product-customize-entry'>
                                <p className='product-group-custom-carousel-entry-product-customize-entry-text'>
                                  {description}
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )
                      }
                      
                    </div>
                  </div>
                )
              })}
          </Carousel>
        </div>
      </div>
    </>
  )
}



export default ProductGroup
