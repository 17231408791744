const login = {
  loginTitle: 'Logga in',
  loginBtn: 'LOGGA IN',
  labelEmail: 'Epost',
  labelPassword: 'Lösenord',
  forgotPassword: 'Glömnt lösenord',
  newAccountTitle: 'Skapa ett konto',
  newAccountText: `SE Luo käyttäjätunnus ja räätälöi InnoGreenin tuotteet omiin tarpeisiisi. 
      Käyttäjätunnuksen avulla voit suunnitella ja tallentaa tuotteesi kokonaisuuksiksi 
      sekä pyytää tarjouksen yhdellä klikkauksella.`,
  newAccountBtn: 'SKAPA KONTO',
  createUserAccount: 'Create new user account',
  signUpTerms:
    "By filling out the form, you accept InnoGreen's terms of marketing",
  requestNewAccountBtn: 'REQUEST',
  labelConfirmPassword: 'Confirm password',
  labelName: 'Name',
  labelCompany: 'Company',
  labelRole: 'Role',
  labelPhoneNumber: 'Phone number',
  labelSubscribeEmail: "I want to join InnoGreen's email list",
  errorEmailRequired: 'Email is required',
  errorPasswordRequired: 'Password is required',
  errorConfirmPasswordRequired: 'Confirm password is required',
  errorNameRequired: 'Name is required',
  errorCompanyRequired: 'Company is required',
  errorPasswordNotMatch: 'Password and confirm password do not match',
  genericSignUpError: 'Could not sign up, please try again!',
  logInCookiesWarning:
    'Login is disabled since you opted out the usage of cookies.',
  signUpCookiesWarning:
    'Signup is disabled since you opted out the usage of cookies.',
  cookiesChangeLink: 'Change your cookies setting here.',
  errorEmailInvalid: 'Email format is invalid',
  errorPasswordLength: 'Password length has to be at least 6 characters',
  forgotPasswordTitle: 'Request password reset',
  confirmBtn: 'Confirm',
  cancelBtn: 'Cancel',
  passwordResetRequestText: 'Send password reset request to admin. Please confirm to proceed!',
  signUpSuccessMessage:
    'Thank you for registering! We will check the information and notify you by email when you can log in to our service.',
  closeBtn: 'Close',
  genericLogInError: 'Failed to log in, please try again!',
  genericPasswordResetError: 'Failed to send request, please try again!',
  passwordResetSuccessMessage:
    'Reset password request sent! We will notify you by email with the new password',
}

export default login
