import './styles.scss'
import { Close } from '../Icons'
import { useEffect, useState, useRef } from 'react'
import CustomButton from '../common/CustomButton'
import { log_in } from '../../store/asyncThunk'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CustomCookiesBanner from '../common/CustomCookiesBanner'
import CustomForgotPasswordModal from '../common/CustomForgotPasswordModal'
import SignUpModal from '../SignUpModal'
import LoadingScreen from '../common/LoadingScreen'
import ClipLoader from 'react-spinners/ClipLoader'

const LogInModal = ({ showing, closeModal, setLoginSuccessful }) => {
  const dispatch = useDispatch()
  const [errorList, setErrorList] = useState([])
  // const current_user = useSelector((state) => state.user.current_user)
  const { t } = useTranslation()
  const [showCookiesWarning, setShowCookiesWarning] = useState(false)
  const [showCookiesBanner, setShowCookiesBanner] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [logInPayload, setLogInPayload] = useState({
    email: '',
    password: '',
  })
  const modalRef = useRef()

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const updateLogInPayload = ({ email, password }) => {
    if (email !== undefined) {
      setLogInPayload({ ...logInPayload, email: email })
    }
    if (password !== undefined) {
      setLogInPayload({ ...logInPayload, password: password })
    }
  }

  const close = () => {
    document.body.style.overflow = 'unset'
    // setSavedSuccess(false)
    closeModal()
  }

  const handleLogInClick = async () => {
    if (
      logInPayload.email &&
      logInPayload.email.trim().length > 0 &&
      logInPayload.password &&
      logInPayload.password.trim().length > 0
    ) {
      setErrorList([]);
      setIsLoading(true)
      // Add timeout for login
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error(t('error.timeoutError'))), 10000) // 10s timeout
      )
      
      try {
        const res = await Promise.race([
          dispatch(log_in(logInPayload)),
          timeoutPromise
        ])
  
        if (res.error) {
          setErrorList([t('error.genericLogInError')])
        } else {
          setLoginSuccessful(true)
          close()
        }
        setIsLoading(false)
      } catch (error) {
        setErrorList([error.message])
        setIsLoading(false)
      }
    }
  }

  const disable_log_in = () => {
    // If cookies are opted out, disable login
    if (
      !localStorage.getItem('innogreen_cookies_approved') ||
      localStorage.getItem('innogreen_cookies_approved') === '0'
    ) {
      return true
    }
    if (logInPayload) {
      return !(
        logInPayload.email &&
        logInPayload.email.trim().length > 0 &&
        logInPayload.password &&
        logInPayload.password.trim().length > 0
      )
    }
    return true
  }

  const disable_sign_up = () => {
    // If cookies are opted out, disable login
    if (
      !localStorage.getItem('innogreen_cookies_approved') ||
      localStorage.getItem('innogreen_cookies_approved') === '0'
    ) {
      return true
    }
  }

  //For first refresh on page
  useEffect(() => {
    if (
      !localStorage.getItem('innogreen_cookies_approved') ||
      localStorage.getItem('innogreen_cookies_approved') === '0'
    ) {
      setShowCookiesWarning(true)
    }
  }, [])

  const toggleCookiesBanner = () => {
    setShowCookiesBanner(!showCookiesBanner)
  }

  const toggleForgotPasswordModal = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
  }

  const toggleSignUpModal = () => {
    setShowSignUpModal(!showSignUpModal)
    // Hide Login Modal if user clicks on signup
    if (modalRef.current) {
      modalRef.current.style.display = 'none'
    }
    // Close login modal if user closes signup modal
    if (showSignUpModal) {
      close()
    }
  }

  return (
    showing && (
      <div className={`login-modal-root ${showSignUpModal ? 'hide' : ''}`}>
        <div className='login-modal-container' ref={modalRef}>
        <div className='login-modal-header'>
          <div className='login-modal-header-exit-btn-container'>
            <div
              className='login-modal-header-exit-btn'
              onClick={() => {
                close()
              }}
            >
              <Close size={20}/>
            </div>
          </div>
        </div>

        <div className='login-modal-content-container'>
          <div className='login-modal-login-container'>
            <div className='login-modal-login-title'>
              {t('login.loginTitle')}
            </div>
            {errorList.length > 0 && (
              <div className='login-modal-error-container'>
                {errorList.map((error) => {
                  return (
                    <div className='login-modal-error-entry' key={error}>
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='login-modal-login-input-container'>
              <label htmlFor='email'>{t('login.labelEmail')}</label>
              <input
                type='text'
                id='email'
                value={logInPayload.email}
                onInput={(e) => updateLogInPayload({ email: e.target.value })}
              />
            </div>
            <div className='login-modal-login-input-container'>
              <label htmlFor='password'>{t('login.labelPassword')}</label>
              <input
                type='password'
                id='password'
                value={logInPayload.password}
                onInput={(e) =>
                  updateLogInPayload({ password: e.target.value })
                }
                onKeyDown={(e) => (e.key === 'Enter' ? handleLogInClick() : '')}
              />
            </div>
            <div className='login-modal-login-btn-container'>
              <CustomButton
                button_text={t('login.loginBtn')}
                primary={true}
                onClickHandler={() => handleLogInClick()}
                contentClass='login'
                disabled={disable_log_in()}
              />
              <Link
                onClick={() => toggleForgotPasswordModal()}
                className='login-modal-login-btn-link'
              >
                {t('login.forgotPassword')}
              </Link>
            </div>

            {showCookiesWarning && (
              <div className='login-modal-login-cookies-warning'>
                *{t('login.logInCookiesWarning')}
                <span
                  className='login-modal-login-cookies-warning-link'
                  onClick={() => toggleCookiesBanner()}
                >
                  {t('login.cookiesChangeLink')}
                </span>
              </div>
            )}
            {/* GOOGLE LOG IN  */}
            {/* <CustomButton
              turquoise
              button_text={t('common.logInWithGoogle')}
              onClickHandler={() => handleGoogleLogIn()}
            /> */}
          </div>
          <div className='login-modal-divider'></div>

          <div className='login-modal-signup-container'>
            <div className='login-modal-signup-title'>
              {t('login.newAccountTitle')}
            </div>
            <div className='login-modal-signup-text'>
              {t('login.newAccountText')}
            </div>
            <div className='login-modal-signup-button-wrapper'>
              <CustomButton
                button_text={t('login.newAccountBtn')}
                purple={true}
                onClickHandler={() => toggleSignUpModal()}
                contentClass='login'
                disabled={disable_sign_up()}
              />
            </div>
            {showCookiesWarning && (
              <div className='login-page-right-login-cookies-warning'>
                *{t('login.signUpCookiesWarning')}
                <span
                  className='login-page-right-login-cookies-warning-link'
                  onClick={() => toggleCookiesBanner()}
                >
                  {t('login.cookiesChangeLink')}
                </span>
              </div>
            )}
          </div>
        </div>
        {isLoading && (
          <div className='login-modal-loading-container'>
            <ClipLoader color='#1a73e8'/>
          </div>
        )}
        
        </div>
      {showSignUpModal && (
        <SignUpModal showing={showSignUpModal} closeModal={toggleSignUpModal} />
      )}

      {/* Cookies */}
      {showCookiesBanner && (
        <CustomCookiesBanner
          showing={showCookiesBanner}
          closeModal={toggleCookiesBanner}
        />
      )}

      {/* Forgot password modal */}
      {showForgotPasswordModal && (
        <CustomForgotPasswordModal
          showing={showForgotPasswordModal}
          closeModal={toggleForgotPasswordModal}
        />
      )}
      </div>
    )
  )
}

export default LogInModal
