const raataloitava_viherseina = {
  name: 'SE Räätälöitävä viherseinä',
  longDescription: `SE Our customizable green wall is the most versatile plant 
      wall on the market in terms of size, shape and plant selection. Our lush 
      green wall is a high quality, domestic and sustainable choice and can be 
      placed indoors or outdoors.
      \\n
      The structure of a green wall is not fixed, but consists of modules that 
      can be attached to the wall, which allows the green wall to be designed 
      in any shape. The modules can be stacked, placed side by side or set on 
      a frame to form different shapes, such as a tower. However, the most 
      typical green wall we have built is a rectangular one, following the 
      shape of the wall behind it.
      \\n
      We only use real plants in our green walls and typically have several 
      different plant species on one wall. A multi-species wall is spectacular, 
      and successful plant selections will create just the right atmosphere. 
      The wall can be playful, dramatic or even calming.
      \\n
      Indoors, it is also possible to combine moss and stabilised plants on 
      the green wall. For the outdoor green wall, you can choose colourful 
      flowers, fragrant herbs or conifers along with ambient lighting, 
      depending on your wishes and the season. Other elements can also be 
      integrated, such as a logo.
      \\n
      Small green walls can be watered by hand. For large green walls, 
      our patented irrigation system is used.`,
  measurements: [
    {
      type: 'SE Height of base:',
      value: '40 cm'
    },
    {
      type: 'SE Size of one module:',
      values: [
        {
          value: 'Width 60 cm'
        },
        {
          value: 'Height 16,5 cm'
        },
        {
          value: 'Depth 20 cm'
        }
      ]
    },
    {
      type: 'SE Amount of plants:',
      value: 'Around 40 plants per square metre of green wall'
    },
  ],
  materials: [
    {
      type: 'SE Edge coverings:',
      value: 'Wood, steel or other similar material according to agreement'
    },
    {
      type: 'SE Green wall modules:',
      value: 'Abs plastic, made in Kaarina'
    },
    {
      type: 'SE Plants:',
      value: 'Finland and EU'
    },
    {
      type: 'SE Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'SE Plant lights:',
      value: `We use the latest technology LED lights. 
          The spectrum of the bulb matches the spectrum of daylight, 
          highlighting the plants while giving them just the right 
          amount of light they need.`
    },
    {
      type: 'SE Usage:',
      value: 'Indoor and outdoor',
      icon: 'both'
    },
  ],
  wlt: {
    title: 'SE This green wall protects 1000 square metres of rainforest',
    text: `SE By choosing an InnoGreen green wall, you are joining us in 
      protecting the world's rainforests in partnership with the World 
      Land Trust. For every square metre of green wall sold or rented, 
      we protect 1000 square metres of rainforest. `
  },
  configuration: [
    {
      type: 'räätälöitävä_viherseinä',
      configType:'width',
      text: 'SE Laarien määrä leveyssuunnassa',
      valueText: 'SE Leveys',
      value: 60,
    },
    {
      type: 'räätälöitävä_viherseinä',
      configType:'height',
      showExtra: true,
      text: 'SE Laarien määrä korkeussuunnassa',
      valueText: 'SE Korkeus',
      value: 16.5,
    }
  ],
  leveys: {
    description: `SE Leveys määrittyy vierekkäin valittavien kasvilaarien määrästä, laarin leveys 60cm.`
  },
  korkeus: {
    description: `SE Korkeus määrittyy päällekkäin valittavien kasvilaarien määrästä, laarin korkeus 16,5cm. (Alaosan/perusosan korkeus 40 cm)`
  },
}

export default raataloitava_viherseina