
import './styles.scss'
import { forwardRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get_decoded_string } from '../../plugins/helper'
import { fetch_product_customizations } from '../../store/asyncThunk'
import { setGlobalLoading } from '../../store/uiSlice'

const ProductSummaryList = forwardRef((props, ref) => {
  const {
    productGroups,
    selectedViewFilter,
    fetchProductCustomizations = true
  } = props
  const customizations = useSelector((state) => state.product.customizations)
  const dispatch = useDispatch()
  const global_loading = useSelector((state) => state.ui.global_loading)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (customizations && customizations.length === 0 && fetchProductCustomizations) {
      dispatch(setGlobalLoading(true))
      dispatch(fetch_product_customizations(true))
      dispatch(setGlobalLoading(false))
    }
  }, [fetchProductCustomizations])

  function group_by_customization_id(customizations) {
    const grouped = [];

    customizations.forEach((item) => {
      // Check if an entry for the current customization_id already exists in the grouped array
      let existingGroup = grouped.find(group => group.customization_id === item.customization_id)
  
      if (existingGroup) {
        // If a group exists, add the customization_id, selected_option_id, and value to its "selected" array
        existingGroup.selected.push({
          customization_id: item.customization_id,
          selected_option_id: item.selected_option_id,
          value: item.value
        })
      } else {
        // If no group exists, create a new one and include the "selected" array
        grouped.push({
          customization_id: item.customization_id,
          date_created: item.date_created,
          date_modified: item.date_modified,
          id: item.id,
          name: item.name,
          selected: item.selected_option_id
            ? [{
                customization_id: item.customization_id,
                selected_option_id: item.selected_option_id,
                value: item.value
              }]
            : []
        })
      }
    })
    return grouped;
  }


  const get_selected_customization = (customization, selected_id, config=null, textIndex=null) => {
    if (config) {
      if (config.type === 'räätälöitävä_viherseinä') {
        if (config.configType === 'width') {
          return (customization.value * config.value) + ' MM'
        } else if (config.configType === 'height') {
          return (customization.value * config.value + 400) + ' MM'
        }
      } else if (config.type === 'räätälöitävä_sammalseinä') {
        if (config.configType === 'custom') {
          return (customization.value)
        } else {
          return (customization.value) + ' MM'
        }
      } else if (config.type === 'viherpysäkki') {
        return (customization.value) + ' X ' + config.text[textIndex].toUpperCase()
      } else if (config.name === 'viherseinä') {
        return (
          <>
            <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value viherseina'>
              {customization.selected[0].value}{' X 1200 MM'}
            </div>
            <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value viherseina'>
            {customization.selected[1].value}{' X 1800 MM'}
            </div>
          </>
        )

      }
    }
    const selected_customization = customizations.find((c) => c.id === customization.customization_id)
    if (selected_customization) {
      const option = selected_customization.options.find((o) => o.id === selected_id)
      if (option) {
        if (get_decoded_string(selected_customization.name)=== 'korkeus') {
          return (t(`products.${get_decoded_string(selected_customization.name)}_options.k${get_decoded_string(option.name)}`)).toUpperCase()
        } 
        else if (get_decoded_string(selected_customization.name) === 'leveys') {
          return (t(`products.${get_decoded_string(selected_customization.name)}_options.l${get_decoded_string(option.name)}`)).toUpperCase()
        }
        return (t(`products.${get_decoded_string(selected_customization.name)}_options.${get_decoded_string(option.name)}`)).toUpperCase()
      }
    } 
    return ''
  }

  const get_selected_customization_image = (customization_id, selected_id) => {
    
    const customization = customizations.find((c) => c.id === customization_id)
    if (customization) {
      const option = customization.options.find((o) => o.id === selected_id)
      if (option) {
        return option.imgSrc ? option.imgSrcs[0] : option.id
      }
    }
    return ''
  }

  return (
    <div className='product-summary-page-product-list' ref={ref}>
      {productGroups ? (
        Object.keys(productGroups).map((group_name) => (
          <div
            className='product-summary-page-product-list-container'
            key={group_name}
          >
            <div className='product-summary-page-product-list-header'>
              <div className='product-summary-page-product-list-header-cell'>
                {group_name === 'Product' ?
                (
                  t('project.product')
                ) : (
                  group_name === 'DEFAULT SPACE' ?
                  (
                    t('project.defaultSpace')
                  ) : (
                    group_name
                  )
                )
                
                }
              </div>
              <div className='product-summary-page-product-list-header-cell'>
                {t('project.quantity')}
              </div>
            </div>
            {productGroups[group_name] &&
              productGroups[group_name].map((product, index) => {
                var set_page_break = false
                if ((index + 1) % 3 == 0) {
                  set_page_break = true
                }
                return (
                  <div
                    className={'product-summary-page-product-list-product-entry' + `${set_page_break ? ' page-break' : ''}`}
                    key={product.id}
                  >
                    {/* product entry left content */}
                    <div className='product-summary-page-product-list-product-entry-left-content'>
                      {/* <div className='product-summary-page-product-list-product-entry-left-content-product-info-title mobile'>
                        {t(`${get_decoded_string(product.name)}.name`)}
                      </div> */}
                      {product.imgSrc ? (
                        <img
                          src={product.imgSrc}
                          alt={`product - ${product.name}`}
                          className='product-summary-page-product-list-product-entry-left-content-img'
                        />
                      ) : (
                        <div className='product-summary-page-product-list-product-entry-left-content-img'></div>
                      )}
                      <div className='product-summary-page-product-list-product-entry-left-content-product-info'>
                        <div className='product-summary-page-product-list-product-entry-left-content-product-info-title desktop'>
                          {t(`${get_decoded_string(product.name)}.name`)}
                        </div>
                        <div className={`product-summary-page-product-list-product-entry-left-content-product-info-customization-list ${product?.name === 'Viherpysäkki' ? 'grid-2' : ''}`}>
                          {product && group_by_customization_id(product.customizations).map(
                            (customization, index) => {
                              if (product?.name === 'Viherpysäkki') {
                                let configuration = null
                                if (i18n.exists(`${get_decoded_string(product.name)}.configuration`)) {
                                  configuration = i18n.t(`${get_decoded_string(product.name)}.configuration`, { returnObjects: true })
                                }
                                return (
                                  <div
                                    key={index}
                                    className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry'
                                  >
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-img'></div>
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description'>
                                      <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-key'>
                                        {t(`products.${get_decoded_string(customization.name)}`).toUpperCase()}
                                      </div>
                                      <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value-wrapper'>
                                        {customization.selected.map((item, itemIndex) => {
                                          return (
                                            <div key={itemIndex} className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value'>
                                              {get_selected_customization(item, customization.selected_option_id, configuration[index], itemIndex)}
                                            </div>
                                          )
                                        }) }
                                      </div>
                                    </div>
                                  </div>
                                )
                                
                            } else if (product?.name === 'Viherseinä') {
                              let configuration = null
                              if (i18n.exists(`${get_decoded_string(product.name)}.configurationProject`)) {
                                configuration = i18n.t(`${get_decoded_string(product.name)}.configurationProject`, { returnObjects: true })
                              }
                              return (
                                <div
                                  key={index}
                                  className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry'
                                >
                                  {get_selected_customization_image(
                                    customization.selected[0].customization_id,
                                    customization.selected[0].selected_option_id) ? (
                                    <img
                                      src={get_selected_customization_image(
                                        customization.selected[0].customization_id,
                                        customization.selected[0].selected_option_id)}
                                      alt={`customization - ${customization.name}`}
                                      className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-img'
                                    />
                                  ) : (
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-img'></div>
                                  )}
                                  <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description'>
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-key'>
                                      {t(`products.${get_decoded_string(customization.name)}`).toUpperCase()}
                                    </div>
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value-wrapper'>
                                      {customization.name ===  "1. Leveys" ?
                                        (
                                            get_selected_customization(customization, null, configuration[0])
                                        ) : (
                                          customization.selected.map((item, itemIndex) => {
                                            return (
                                              <div key={itemIndex} className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value'>
                                                {get_selected_customization(item, item.selected_option_id)}
                                              </div>
                                            )
                                          })
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                              )
                            } else if (product?.name === 'Räätälöitävä sammalseinä' || product?.name === 'Räätälöitävä viherseinä') {
                              let configuration = null
                              if (i18n.exists(`${get_decoded_string(product.name)}.configuration`)) {
                                configuration = i18n.t(`${get_decoded_string(product.name)}.configuration`, { returnObjects: true })
                              }

                              return (
                                <div
                                  key={index}
                                  className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry'
                                >
                                  {get_selected_customization_image(
                                    customization.selected[0].customization_id,
                                    customization.selected[0].selected_option_id) ? (
                                    <img
                                      src={get_selected_customization_image(
                                        customization.selected[0].customization_id,
                                        customization.selected[0].selected_option_id)}
                                      alt={`customization - ${customization.name}`}
                                      className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-img'
                                    />
                                  ) : (
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-img'></div>
                                  )}
                                  <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description'>
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-key'>
                                      {t(`products.${get_decoded_string(customization.name)}`).toUpperCase()}
                                    </div>
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value-wrapper'>
                                      {customization.selected.map((item, itemIndex) => {
                                          return (
                                              <div key={itemIndex} className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value'>
                                                {configuration ? 
                                                  get_selected_customization(item, item.selected_option_id, configuration[index])
                                                :
                                                  get_selected_customization(item, item.selected_option_id)
                                                }
                                              </div>
                                          )
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              )
                            } else {
                              return (
                                <div
                                  key={index}
                                  className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry'
                                >
                                  {get_selected_customization_image(
                                    customization.selected[0].customization_id,
                                    customization.selected[0].selected_option_id) ? (
                                    <img
                                      src={get_selected_customization_image(
                                        customization.selected[0].customization_id,
                                        customization.selected[0].selected_option_id)}
                                      alt={`customization - ${customization.name}`}
                                      className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-img'
                                    />
                                  ) : (
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-img'></div>
                                  )}
                                  <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description'>
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-key'>
                                      {t(`products.${get_decoded_string(customization.name)}`).toUpperCase()}
                                    </div>
                                    <div className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value-wrapper'>
                                      {customization.selected.map((item, itemIndex) => {
                                          return (
                                              <div key={itemIndex} className='product-summary-page-product-list-product-entry-left-content-product-info-customization-entry-description-value'>
                                                {get_selected_customization(item, item.selected_option_id)}
                                              </div>
                                          )
                                        })
                                      }
                                      {/* {get_selected_customization(
                                        customization,
                                        customization.selected_option_id
                                      )} */}
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            }
                          )}
                        </div>
                      </div>
                    </div>

                    {/* product entry right content */}
                    <div className='product-summary-page-product-list-product-entry-right-content'>
                      <div className='product-summary-page-product-list-product-entry-right-content-wrapper'>
                        <div className='product-summary-page-product-list-product-entry-right-content-title'>
                          {t('project.quantity')}:
                        </div>
                        <div className='product-summary-page-product-list-product-entry-right-content-total'>
                          {product.quantity}{' '}
                          {selectedViewFilter &&
                            selectedViewFilter.id === 1 && (
                              <span>{t('project.total')}</span>
                            )}
                        </div>
                      </div>
                      {selectedViewFilter &&
                        selectedViewFilter.id === 1 && (
                          <div className='product-summary-page-product-list-product-entry-right-content-space-quantity-list'>
                            {product.spaces &&
                              product.spaces.map((space) => {
                                return (
                                  <div
                                    key={space.id}
                                    className='product-summary-page-product-list-product-entry-right-content-space-quantity'
                                  >
                                      {space.quantity}&nbsp;&nbsp;{space.name === 'DEFAULT SPACE' ? t('project.defaultSpace').toUpperCase() : space.name}
                                  </div>
                                )
                              })}
                          </div>
                        )}
                    </div>
                  </div>
                )
              })}
          </div>
        ))
      ) : (
        <div className='product-summary-page-product-list-no-entry'>
          No entry
        </div>
      )}
      <div className='product-summary-page-product-list-sales-contact-container'>
        <div className='product-summary-page-product-list-sales-green-decor'>
          <div className='product-summary-page-product-list-sales-title'>{t('common.greenInterior')}</div>
          <div className='product-summary-page-product-list-sales-wrapper'>
            <div className='product-summary-page-product-list-sales-text'>010 583 3595</div>
            <div className='product-summary-page-product-list-sales-text'>myynti@innogreen.fi</div>
          </div>
        </div>
        <div className='product-summary-page-product-list-sales-outdoor'>
          <div className='product-summary-page-product-list-sales-title'>{t('common.outdoorSpaces')}</div>
          <div className='product-summary-page-product-list-sales-wrapper'>
            <div className='product-summary-page-product-list-sales-text'>010 583 3598</div>
            <div className='product-summary-page-product-list-sales-text'>piha@innogreen.fi</div>
          </div>
        </div>
      </div>
    </div>
  )

})

export default ProductSummaryList