import './styles.scss'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ClipLoader from 'react-spinners/ClipLoader'
import { useMatches } from 'react-router-dom'

import HeaderBox from '../../components/HeaderBox'
import InfoCardGroup from '../../components/InfoCardGroup'
import BlogGroup from '../../components/BlogGroup'

import headerImgSrc from '../../assets/images/Work/toihin-innogreenille-1.jpg'
import kukkaImgSrc from '../../assets/images/Work/Kukka.jpg'
import kristinaImgSrc from '../../assets/images/Work/Kristina.jpg'
import leenaImgSrc from '../../assets/images/Work/Leena.jpg'
import teijoImgSrc from '../../assets/images/Work/Teijo.jpg'
import kirsiImgSrc from '../../assets/images/Work/Kirsi.jpg'

import { fetch_blog_jobs } from '../../store/asyncThunk'

const images = {
  Kukka: kukkaImgSrc,
  Kristina: kristinaImgSrc,
  Leena: leenaImgSrc,
  Teijo: teijoImgSrc,
  Kirsi: kirsiImgSrc
}

const Work = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const openPositions = useSelector((state) => state.blog.openPositions)
  const cards = i18n.t('work.cards', { returnObjects: true })
  const workCards = i18n.t('work.workCards', { returnObjects: true })
  const employeeCard = i18n.t('work.employeeCard', { returnObjects: true })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      await dispatch(fetch_blog_jobs())
      setIsLoading(false)
    })()
    
  }, [])

  return (
    <>
      <div className='work-container'>
        <div className='work-body-container'>
          <HeaderBox
            titleText={t('work.headerTitle')}
            imgSrc={headerImgSrc}
            type='contactPage'
          />

          <div className='work-intro-container'>
            <div className='work-intro-title'>{t('work.introTitle')}</div>
            <div className='work-intro-text'>
              {
                t('work.introText').split('\\n').map((line, index) => {
                    if (t('work.introText').split('\\n').length - 1 === index ) {
                      return (
                        <React.Fragment key={index}>
                          {line}
                        </React.Fragment>
                        )
                    }
                    return (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                        <br />
                      </React.Fragment>
                      )
                  }
                )
              }
            </div>
          </div>
          
          <div className='work-card-wrapper'>
            <InfoCardGroup cards={cards} type='text' backgroundColor='yellow'/>
          </div>

          <div className='work-intro-container'>
            <div className='work-intro-title text-center big-font'>{t('work.introTitle2')}</div>
          </div>

          <div className='work-card-container'>
            { workCards.map((item, index) => (
                <React.Fragment key={index}>
                  <div className={workCards.length - 1 === index ? 'work-card-item-container last-item' : 'work-card-item-container'}>
                    <div className='work-card-item-left-container'>
                      <div className='work-card-item-left-title'>{item.title}</div>
                    </div>
                    <div className='work-card-item-right-container'>
                    <div className='work-card-item-right-text'>
                      {
                        item.text.split('\\n').map((line, index) => {
                            if (item.text.split('\\n').length - 1 === index ) {
                              return (
                                <React.Fragment key={index}>
                                  {line}
                                </React.Fragment>
                                )
                            }
                            return (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                                <br />
                              </React.Fragment>
                              )
                          }
                        )
                      }
                    </div>
                    </div>
                  </div>
                </React.Fragment>
                )
              )
            }
          </div>

          <div className='work-intro-container'>
            <div className='work-intro-title text-center big-font'>{t('work.introTitle3')}</div>
            <div className='work-intro-text text-center'>
              {t('work.introText3')}
              <span> </span>
              <span className="work-intro-highlight">
                <a className='work-intro-link' href={`mailto:${t('work.introEmail')}`}>{t('work.introEmail')}</a>
                </span>
            </div>
          </div>
        
          <div className='work-open-position-container'>
            <div className='work-open-position-wrapper'>
              { isLoading ?
                (
                  <ClipLoader color='#1a73e8' />
                ) : (
                  openPositions ?
                    (
                      <BlogGroup blogs={openPositions} type='work' noBgColor/>
                    ) : (
                      <div className='work-open-position-item'>
                        {t('work.noAvailableJobs')}
                      </div>
                    )
                  
                )
              }
              
            </div>
          </div>

          <div className='work-intro-container'>
            <div className='work-intro-title text-center big-font'>{t('work.introTitle4')}</div>
          </div>

          <div className='work-employee-card-container'>
            { employeeCard.map((item, index) => (
              <div className='work-employee-card-item-container' key={index}>
                <div className='work-employee-card-item-left-container'>
                  <div className='work-employee-card-item-left-image-wrapper'>
                    <img src={images[item.employeeName]} alt={`employee image ${item.employeeName}`} />
                  </div>
                </div>
                <div className='work-employee-card-item-right-container'>
                  <div className='work-employee-card-item-right-year'>{item.yearText.toUpperCase()}&nbsp;{item.year}</div>
                  <div className='work-employee-card-item-right-name'>{item.employeeName.toUpperCase()}, {item.title}</div>
                  <div className='work-employee-card-item-right-text'>{item.text}</div>
                </div>
              </div>
            ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Work
