import { Outlet } from 'react-router-dom'
import './styles.scss'

// import CustomToast from '../components/CustomToast'
import NavBar from '../components/Navbar'
import Footer from '../components/Footer'


const Layout = () => {

  

  return (
    <>
      <NavBar />

      <Outlet />

      <Footer />
      
      {/* <CustomToast /> */}
    </>
  )
}

export default Layout
