import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { ArrowSimple } from '../../components/Icons'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState, useCallback, useRef } from 'react'
import './styles.scss'
import {
  selectCurrentProject,
  updateProductQuantity,
} from '../../store/projectSlice'
import CustomButton from '../../components/common/CustomButton'
import CustomDropdown from '../../components/common/CustomDropdown'
import moment from 'moment'
import ProductInquiryModal from '../../components/SendInquiryModal'
import { fetch_project } from '../../store/asyncThunk'
import { useReactToPrint } from 'react-to-print'
import ProductSummaryList from '../../components/ProductSummaryList'
import { ArrowLeftLong } from '../../components/Icons'
import { useTranslation } from 'react-i18next'


const view_filters = [
  {
    name: 'BY PRODUCTS',
    translation: 'project.byProducts',
    id: 1,
    customStyles: {
      backgroundColor: '#FCEA19',
      borderColor: '#FCEA19',
    },
  },
  {
    name: 'BY SPACE',
    translation: 'project.bySpace',
    id: 2,
    space_id: 1,
    customStyles: {
      backgroundColor: '#FCEA19',
      borderColor: '#FCEA19',
    },
  },
]

const ProductSummary = () => {
  const { project_id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (project_id) {
      dispatch(selectCurrentProject(parseInt(project_id)))
    }
  }, [dispatch, project_id])

  const current_project = useSelector((state) => state.project.current_project)

  const get_resources = useCallback(async () => {
    if (project_id) {
      await dispatch(fetch_project({ project_id: parseInt(project_id) }))
      dispatch(selectCurrentProject(parseInt(project_id)))
    }
  }, [project_id, dispatch])

  useEffect(() => {
    if (!current_project) {
      get_resources()
    }
  }, [get_resources, project_id])

  const customizations = useSelector((state) => state.product.customizations)

  const [selectedViewFilter, setSelectedViewFilter] = useState(view_filters[0])

  const [productGroups, setProductGroups] = useState({})
  const [showProductInquiryModalState, setProductInquiryModalState] =
    useState(false)

  const handleViewFilterClick = (item) => {
    if (item && item.id !== 6) {
      setSelectedViewFilter(item)
    }
  }

  useEffect(() => {
    if (selectedViewFilter && current_project) {
      //GROUP BY PRODUCT
      if (selectedViewFilter.id === 1) {
        let product_list = current_project.products.map((p) => ({
          ...p,
          quantity: get_product_quantity(p),
          spaces: get_product_space(p),
        }))
        product_list = product_list.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            ? -1
            : 1
        })
        setProductGroups({ Product: product_list })
      }
      //GROUP BY space
      if (selectedViewFilter.id === 2) {
        let space_groups = {}
        current_project.spaces.forEach((space) => {
          space_groups[space.name] = []
          current_project.products.forEach((pro) => {
            if (space.assigned_products.some((p) => p.id === pro.id)) {
              space_groups[space.name].push({
                ...pro,
                quantity: space.assigned_products.find((p) => p.id === pro.id)
                  .quantity,
              })
            }
          })
        })

        setProductGroups(space_groups)
      }
    }
  }, [selectedViewFilter, current_project])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const goToAccount = () => {
    navigate('/account')
  }

  const get_total_product_quantity = () => {
    //Depends on the current filter
    let result = 0
    current_project?.spaces?.forEach((s) => {
      s.assigned_products.forEach((p) => {
        result += p.quantity
      })
    })

    return result
  }

  const get_product_quantity = (product) => {
    let result = 0
    const assigned_spaces = current_project.spaces.filter((s) =>
      s.assigned_products.some((pro) => pro.id === product.id)
    )
    assigned_spaces.forEach((s) => {
      s.assigned_products.forEach((p) => {
        if (p.id === product.id) {
          result += p.quantity
        }
      })
    })
    return result
  }

  const get_product_space = (product) => {
    let list = []
    const assigned_spaces = current_project.spaces.filter((s) =>
      s.assigned_products.some((pro) => pro.id === product.id)
    )

    assigned_spaces.forEach((s) => {
      const assigned_product = s.assigned_products.find(
        (a) => a.id === product.id
      )

      if (assigned_product) {
        list.push({
          id: s.id,
          name: s.name,
          quantity: assigned_product.quantity,
        })
      }
    })
    return list
  }

  const goToProject = () => {
    navigate(`/project/${current_project.id}`)
  }

  const get_project_status = (status) => {
    switch (status) {
      case 1:
        return t('project.open')
      case 2:
        return t('project.pending')
      case 3:
        return t('project.closed')
      default:
        return t('project.unknown')
    }
  }

  const get_selected_customization = (customization_id, selected_id) => {
    const customization = customizations.find((c) => c.id === customization_id)

    if (customization) {
      const option = customization.options.find((o) => o.id === selected_id)
      if (option) {
        return option.name ? option.name.toUpperCase() : ''
      }
    }
    return ''
  }

  const get_selected_customization_image = (customization_id, selected_id) => {
    const customization = customizations.find((c) => c.id === customization_id)

    if (customization) {
      const option = customization.options.find((o) => o.id === selected_id)
      if (option) {
        return option.imgSrc ? option.imgSrc : option.id
      }
    }
    return ''
  }

  const toggleProductInquiryModal = () => {
    setProductInquiryModalState(!showProductInquiryModalState)
  }

  const getModifiedDate = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY')
    }
  }
  const contentRef = useRef(null)
  const reactToPrintFn = useReactToPrint({
    contentRef,
    documentTitle: current_project?.name,
    pageStyle: `
      @page {
        size: A4; /* or 'letter' for US standard */
      }
      .product-summary-page-product-list-header-cell:first-child {
        margin-left: 50px;
      }
      .product-summary-page-product-list-header-cell:last-child {
        display: none;
      }
      .product-summary-page-product-list-product-entry-right-content-space-quantity {
        margin-right: 80px;
      }
      .product-summary-page-product-list-product-entry {
        border-radius: 0rem;
      }
      .product-summary-page-product-list-sales-contact-container {
        display: flex !important;
      }
    `,
  });



  return (
    <>
      <div className='product-summary-page-container'>
        <div className='product-summary-page-header-link-container'>
          <div className='product-summary-page-header-link-wrapper'>
            <div className='product-summary-page-header-link' onClick={() => goToAccount()}>
              <ArrowLeftLong /> <p>{t('project.backToMyAccount').toUpperCase()}</p>
            </div>
          </div>
        </div>

        {/* PAGE HEADER */}
        {current_project && (
          <>
            <div className='product-summary-page-header-container'>
              <div className='product-summary-page-header-wrapper'>
                <div className='product-summary-page-header'>
                  <div className='product-summary-page-header-left-content'>
                    <div className='product-summary-page-project-name'>
                      {current_project.name}
                    </div>
                    <div className='product-summary-page-project-description'>
                      {current_project.description}
                    </div>
                    <div className='product-summary-page-project-date-status'>
                      <div className='product-summary-page-project-date-status-item'>
                        <div className='product-summary-page-project-date-status-label'>
                          {t('project.dateCreated')}
                        </div>
                        <div className='product-summary-page-project-date-status-value'>
                          {getModifiedDate(current_project.date_created)}
                        </div>
                      </div>
                      <div className='product-summary-page-project-date-status-item'>
                        <div className='product-summary-page-project-date-status-label'>
                          {t('project.dateModified')}
                        </div>
                        <div className='product-summary-page-project-date-status-value'>
                          {getModifiedDate(current_project.date_modified)}
                        </div>
                      </div>
                      <div className='product-summary-page-project-date-status-item'>
                        <div className='product-summary-page-project-date-status-label'>
                          {t('project.status')}
                        </div>
                        <div className='product-summary-page-project-date-status-value'>
                          {get_project_status(current_project.status)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* SUMMARY LINK  */}

            <div className='product-summary-page-project-link-container'>
              <div className='product-summary-page-project-link-wrapper'>
                <div className='product-summary-page-project-link'>
                  <div
                    className='product-summary-page-project-link-text'
                    onClick={() => goToProject()}
                  >
                    {t('project.editProject')}
                    <ArrowSimple size={22} strokeWidth={0.1}/>
                  </div>
                </div>
                <div className='product-summary-page-project-link-print-button'>
                  <CustomButton button_text={t('project.printProject').toUpperCase()} onClickHandler={reactToPrintFn} purple={true}/>
                </div>
              </div>
            </div>

            {/* PRODUCT FILTER GROUP */}
            <div className='product-summary-page-product-filter-groups-container'>
              <div className='product-summary-page-product-filter-groups-wrapper'>
                <div className='product-summary-page-product-filter-container'>
                  <div className='product-summary-page-product-filter-label'>
                    {t('project.view').toUpperCase()}
                  </div>
                  <div className='product-summary-page-product-filter-selection'>
                    <div className='product-summary-page-product-filter-selection-buttons'>
                      {view_filters.map((filter) => {
                        return (
                          <CustomButton
                            key={filter.id}
                            button_text={t(filter.translation).toUpperCase()}
                            customStyles={filter.customStyles}
                            selected={selectedViewFilter.id === filter.id}
                            onClickHandler={() => handleViewFilterClick(filter)}
                          />
                        )
                      })}
                    </div>
                    <div className='product-summary-page-product-filter-selection-dropdown mobile'>
                      <CustomDropdown
                        options={view_filters}
                        selected={selectedViewFilter}
                        onClickHandler={(val) => handleViewFilterClick(val)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* PRODUCT LIST  */}
            <div className='product-summary-page-product-summary-list-container'>
              <div className='product-summary-page-product-summary-list-wrapper'>
                <ProductSummaryList productGroups={productGroups} selectedViewFilter={selectedViewFilter} ref={contentRef} />
              </div>
            </div>
            

            {/* PAGE FOOTER */}
            <div className='product-summary-page-footer-container'>
              <div className='product-summary-page-footer-wrapper'>
                  <div className='product-summary-page-footer-left-content'>
                    <CustomButton
                      button_text={current_project && current_project.status === 3 ? t('project.offerInquired').toUpperCase() : t('project.sendInquiry').toUpperCase()}
                      selected
                      onClickHandler={() => toggleProductInquiryModal()}
                      disabled={current_project && current_project.status === 3}
                    />
                    <CustomButton button_text={t('project.printProject').toUpperCase()}  onClickHandler={reactToPrintFn}/>
                    <div
                      className='product-summary-page-footer-left-content-link'
                      onClick={() => goToProject()}
                    >
                      {t('project.editProject')}
                      <ArrowSimple size={22} strokeWidth={0.1}/>
                    </div>
                  </div>
                  <div className='product-summary-page-footer-right-content'>
                    <div className='product-summary-page-footer-right-content-title'>
                      {t('project.total')}: {get_total_product_quantity()} {t('project.products').toLowerCase()}
                    </div>
                  </div>
              </div>
            </div>

            {showProductInquiryModalState && (
              <ProductInquiryModal
                showing={showProductInquiryModalState}
                closeModal={toggleProductInquiryModal}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ProductSummary
