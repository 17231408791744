import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const SocialIconGroup = (props) => {
  const { 
    size = 'lg',
    color
   } = props
  return (
    <>
      <div className='social-icon-group-container'>
        <a
          href="https://www.instagram.com/innogreenfi"
          target="_blank"
          rel="noopener noreferrer"
          className={"social-icon-group " + color}
        >
          <FontAwesomeIcon size={size} icon={faInstagram} />
        </a>
        <a
          href="https://www.facebook.com/innogreenfi"
          target="_blank"
          rel="noopener noreferrer"
          className={"social-icon-group " + color}
        >
          <FontAwesomeIcon size={size} icon={faFacebook} />
        </a>
        <a
          href="https://www.linkedin.com/company/innogreenfi"
          target="_blank"
          rel="noopener noreferrer"
          className={"social-icon-group " + color}
        >
          <FontAwesomeIcon size={size} icon={faLinkedin} />
        </a>
      </div>
    </>
  )
}

export default SocialIconGroup
