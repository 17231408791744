import './styles.scss'
import InfoCard from '../InfoCard'

const InfoCardGroup = (props) => {
  const { cards, type, backgroundColor } = props

  return (
    <>
      <div className='info-card-group-container'>
        {
          cards && cards.map((card, index) => {
            return <InfoCard {...card} type={type} key={index} backgroundColor={backgroundColor}/>
          })
        }
      </div>
    </>
  )
}

export default InfoCardGroup
