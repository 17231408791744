import './styles.scss'

const CustomContactCard = (props) => {
  const {
    name,
    email,
    phone,
    text,
    imgSrc,
    title,
  } = props

  return (
    <div className='custom-contact-card-container'>
      <div className='custom-contact-card-image-wrapper'>
        {
          imgSrc ?
          (
            <img src={imgSrc} alt="Profile" className="custom-contact-card-image" />
          ) : (
            <></>
          )
        }
      </div>
      <div className='custom-contact-card-text-container'>
        <div className='custom-contact-card-name'>{name}</div>
        {title ?
          (
            <div className='custom-contact-card-title'>{title.toUpperCase()}</div>
          ) : (
            <></>
          )
        }
        <div className='custom-contact-card-text-wrapper'>
          {
            email ?
            (
              <div className='custom-contact-card-email'>{email}</div>
            ) : (
              <></>
            )
          }
          {
            phone ?
            (
              <div className='custom-contact-card-phone'>{phone}</div>
            ) : (
              <></>
            )
          }
        </div>
        {
          text ?
          (
            <div className='custom-contact-card-text'>{text}</div>
          ) : (
            <></>
          )
        }
        <div className='custom-contact-card-text'></div>
      </div>
    </div>
  )
}


export default CustomContactCard
