import './styles.scss'
import React, { useEffect, useRef, useState } from 'react'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'
const FontAttributor = Quill.import('attributors/class/font')
const ListItemBlot = Quill.import('formats/list')

FontAttributor.whitelist = [
  'inter-tight',
  'libre-franklin',
  'dm-mono',
]
Quill.register(FontAttributor, true)

// Custom Quick Selection
const Inline = Quill.import('blots/inline')

class QuickSelectionBlot extends Inline {
  static create(value) {
    const node = super.create()
    node.classList.add(`ql-quickSelection-${value}`)
    return node
  }

  static formats(node) {
    const classMatch = Array.from(node.classList).find(cls =>
      cls.startsWith('ql-quickSelection-')
    )
    return classMatch ? classMatch.replace('ql-quickSelection-', '') : null
  }

  format(name, value) {
    if (name === QuickSelectionBlot.blotName) {
      // Preserve the current font class (check if it exists)
      const currentFontClass = Array.from(this.domNode.classList).find(cls =>
        cls.startsWith('ql-font-')
      ) || 'ql-font-libre-franklin';  // Default to a specific font if none is found

      if (value) {
        this.domNode.className = `ql-quickSelection-${value}`
      } else {
        this.domNode.className = ''
      }

      // Reapply the preserved font class after applying quickSelection
      this.domNode.classList.add(currentFontClass)
    } else {
      super.format(name, value)
    }
  }
}

QuickSelectionBlot.blotName = 'quickSelection'
QuickSelectionBlot.tagName = 'span'
Quill.register(QuickSelectionBlot)

// Used to format Bullet and index Lists based on Selected Font and font-size
class CustomListItem extends ListItemBlot {
  optimize(context) {
    super.optimize(context)
    
    if (this.children.length >= 1) {
      const child = this.children.head
      const attributes = child?.attributes?.attributes

      // Set default values for font and size
      const defaultFont = 'Inter Tight'
      const defaultSize = '20px'

      if (attributes) {
        let fontSet = false
        let sizeSet = false

        for (const key in attributes) {
          const element = attributes[key]
          const name = element.attrName
          const value = element.value(child.domNode)

          if (name === 'font') {
            let font = value === 'inter-tight' ? 'Inter Tight' : value === 'dm-mono' ? 'DM Mono' : 'Libre Franklin'
            this.domNode.style.fontFamily = font
            fontSet = true
          } else if (name === 'size') {
            this.domNode.style.fontSize = value
            sizeSet = true
          }
        }
        // Set default font
        if (!fontSet) {
          this.domNode.style.fontFamily = defaultFont
        }

        // Set default font-size
        if (!sizeSet) {
          this.domNode.style.fontSize = defaultSize
        }
      } else {
        // If no attributes set the default
        this.domNode.style.fontFamily = defaultFont
        this.domNode.style.fontSize = defaultSize
      }
    }
  }
}
Quill.register(CustomListItem, true)

const Clipboard = Quill.import('modules/clipboard')
const Delta = Quill.import('delta')

// Custom clipboard, strips style when on paste
class PlainClipboard extends Clipboard {
  onPaste(range, { text }) {
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text, { quickSelection: 'text' }); // Apply QuickSelectionBlot 'text' on paste

    this.quill.updateContents(delta, Quill.sources.USER);
    this.quill.setSelection(range.index + text.length, Quill.sources.SILENT);
    this.quill.scrollIntoView();
  }
}

Quill.register(
  {
    "modules/clipboard": PlainClipboard,
  },
  true
)

const QuillEditor = (props) => {
  const {
    id,
    onContentChange,
    onDynamicContentChange,
    initialContent = '',
    language = 'FI'
  } = props
  const editorRef = useRef(null)
  const quillRef = useRef(null)
  const [lastFont, setLastFont] = useState('libre-franklin')
  const [lastQuickSelection, setLastQuickSelection] = useState('text')
  const [initialContentSet, setInitialContentSet] = useState(false)
  const [isQuillReady, setIsQuillReady] = useState(false)

  const bindings = {
    enter: {
      key: 'Enter',
      handler: (range, context) => {
        if (quillRef.current) {

          const newLinePosition = range.index
          let quickSelection = lastQuickSelection || 'text' // Track the last quickSelection
          let font = lastFont || 'libre-franklin'
          const delta = quillRef.current.getContents() // Get delta of the document
          const lastOp = delta.ops[delta.ops.length - 2] // Get the last operation

          if (lastOp && lastOp.attributes) {
            if (lastOp.attributes.quickSelection) {
              quickSelection = lastOp.attributes.quickSelection
            }
            if (lastOp.attributes.font ) {
              font = lastOp.attributes.font
            }
          }

          // Insert the new line
          quillRef.current.insertText(newLinePosition, '\n')

          // Set lst quickSelection
          quillRef.current.format('quickSelection', quickSelection)
          quillRef.current.format('font', font)

          // Move the cursor to the new line
          quillRef.current.setSelection(newLinePosition + 1)

          return false // Return false to prevent the default Enter behavior
        }
        return false 
      }
    }
  }

  // Toolbar
  const modules = {
    toolbar: [
      [{font: ['libre-franklin', 'dm-mono', 'inter-tight'],},],
      // [{size: ['16px', '18px', '20px', '24px', '32px', '44px'],},],
      [{quickSelection: ['text', 'text-emphasized', 'card', 'heading'],},],
      ['blockquote'],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
    keyboard: {
      bindings,
    }
  }

  const handleDeleteLastChar = (delta, oldDelta, source) => {
    const selection = quillRef.current.getSelection()
    if (selection) {
      if (quillRef.current.getLength() !== 1) {
        const format = quillRef.current.getFormat(selection);
        setLastQuickSelection(format.quickSelection || 'text')
        setLastFont(format.font || 'libre-franklin')
      }
    }

    let qs = lastQuickSelection || 'text'
    let font = lastFont || 'libre-franklin'

    if (source === 'user' && quillRef.current.getLength() === 1) {
      quillRef.current.format('quickSelection', qs)
      quillRef.current.format('font', font)
    }
  }

    // Initialize Quill instance
    useEffect(() => {
      if (editorRef.current && !quillRef.current) {
        quillRef.current = new Quill(editorRef.current, {
          theme: 'snow',
          modules: modules
        })
  
        quillRef.current.format('quickSelection', 'text')
        quillRef.current.format('font', 'libre-franklin')
  
        quillRef.current.on('text-change', (delta, oldDelta, source) => {
          if (!onContentChange) return
          onContentChange('text', quillRef.current.root.innerHTML, 'update', language)
          handleDeleteLastChar(delta, oldDelta, source)
        })

        quillRef.current.on('text-change', (delta, oldDelta, source) => {
          if (!onDynamicContentChange) return
          onDynamicContentChange(quillRef.current.root.innerHTML, id, language)
          handleDeleteLastChar(delta, oldDelta, source)
        })
  
        setIsQuillReady(true); // Mark the editor as ready
      }
    }, [onContentChange, id])

      // Set Initial Content Once Quill is Ready
  useEffect(() => {
    if (quillRef.current && isQuillReady && !initialContentSet && initialContent) {
      quillRef.current.clipboard.dangerouslyPasteHTML(initialContent)
      setInitialContentSet(true)

      // Move cursor to the end
      const length = quillRef.current.getLength()
      quillRef.current.setSelection(length, 0)
    }
  }, [isQuillReady, initialContent, initialContentSet, id])

  return (
    <div ref={editorRef} style={{ height: '100%', border: '1px solid #ccc' }}></div>
  )
  
}

export default QuillEditor