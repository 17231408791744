const products = {
  home_page_product_showcase: 'Introducing',
  koivu_page_related_products: 'Related products',
  green_wall_page_related_products: 'Related products',
  green_furniture_page_related_products: 'Related products',
  reference_page_related_products: 'Products used in project',
  referencePageLinkText: 'See all products',
  greenFurniturePageLinkText: 'See all products',
  greenWallPageLinkText: 'See all products',
  homePageLinkText: 'See all products',
  koivuPageLinkText: 'See all products',
  noProductsFound: 'No Products Found',
  // Title for Card links
  ourSolutions: 'Our solutions',
  // InfoBox
  infoBoxTitle: 'Didn’t find what you were looking for?',
  infoBoxText: `Over the years, we have created hundreds of stunning, unique projects and are happy to take on any creative challenge. 
      The green implementation of every special project starts with a green design.`,
  infoBoxBtnText: 'Tailored solutions',
  // Filter Butttons
  filter: 'FILTER',
  new: 'NEWS AND UPDATES',
  pots: 'POTS',
  greenWalls: 'GREEN WALLS',
  mossAndLichen: 'MOSS AND LICHEN',
  greenFurniture: 'GREEN FURNITURES',
  inDoor: 'INDOOR',
  outDoor: 'OUTDOOR',
  clear: 'CLEAR',
  // Product groups
  koivu_ruukut: 'Koivu plant pots',
  viherseinat: 'Green walls',
  sammal_jakala: 'Moss and lichen',
  viherkalusteet: 'Green furnitures',
  uutuudet_ja_ajankohtaiset: 'News and updates',
  // Product Customizations
  customize: 'Customise:',
  malli: 'Model',
  ruukun_vari: 'Colour',
  jalat: 'Legs',
  kehyksen_vari: 'Colour of the frame',
  taustamateriaali: 'Backside material',
  leveys: 'Width',
  korkeus: 'Height',
  muoto: 'Shape',
  koko: 'Size',
  taulun_sisalto: 'Material on the inside',
  kustomointi: 'Customization',
  perusmoduulit: 'Basic module',
  viherseinamoduulit: 'Green wall module',
  viherseinamoduulin_lisaosat: 'Green wall module additional parts',
  kasviryhma: 'Plant group',
  // Product Customization Options
  ruukun_vari_options: {
    luonnollinen: 'Natural',
    valkoinen: 'White',
    musta: 'Black',
    erikoisvari: 'Special colour'
  },
  jalat_options: {
    korkeat_jalat: 'High legs',
    tassut: 'Pads',
    pyorat: 'Wheels'
  },
  kehyksen_vari_options: {
    luonnollinen: 'Natural',
    valkoinen: 'White',
    musta: 'Black',
    erikoisvari: 'Special colour'
  },
  taustamateriaali_options: {
    sammal: 'Moss',
    jakala: 'Lichen',
    sekoitus: 'Mix',
  },
  koko_options: {
    pyorea_s: 'S',
    pyorea_m: 'M',
    pyorea_l: 'L',
    nelio_s: 'S',
    nelio_m: 'M',
    nelio_l: 'L',
    suorakaide_l: 'L',
    suorakaide_xl: 'XL',
  },
  korkeus_options: {
    k210_cm: '2100 mm',
    k227_cm: '2270 mm',
    k243_cm: '2430 mm',
  },
  leveys_options: {
    l120_cm: '1200 mm',
    l180_cm: '1800 mm'
  },
  malli_options: {
    p_380: 'P-380',
    p_380xl: 'P-380XL',
    p_445: 'P-445',
    p_550: 'P-550',
    b_665: 'B-665',
    t_1020: 'T-1020',
    t_1020xl: 'T-1020XL',
    tilanjakaja: 'Room divider',
    seinalle_sijoitettava: 'Wall mounted'
  },
  muoto_options: {
    pyorea: 'Round',
    nelio: 'Square',
    suorakaide: 'Rectangular'
  },
  taulun_sisalto_options: {
    sammal: 'Moss',
    jakala: 'Lichen',
    mix: 'Mix',
  },
  kasviryhma_options: {
    s: 'S',
    m: 'M',
    l_puuvartinen: 'L - Woody',
    l_pensanmainen: 'L - Bushy',
    xs: 'XS',
  },
  // Buttons
  addToProject: 'ADD TO PROJECT',
  sendInquiry: 'SEND INQUIRY',
  inquirySentSuccess: `Thank you, form received. We will contact you as soon as possible!`,
  interesedInProduct: 'Interested in this product?',
}

export default products