const koivu_t_sarja = {
  name: 'Koivu T-sarja',
  longDescription: `Kauniit Koivu-ruukut ja -tilanjakajat sulautuvat monenlaiseen 
      sisustukseen ja tuovat tilaan luonnon rauhaa ja harmonista tunnelmaa. 
      Näyttävimmän asetelman luot yhdistelemällä erimuotoisia ja -kokoisia 
      Koivu-tuotteita.
      \\n
      Koivu T-sarjan tilanjakajilla rajaat ja rytmität tilaa joko pysyvästi 
      tai väliaikaisesti. Suuria tilanjakajia on saatavilla sekä jaloilla 
      että ilman. Ne tuovat tilaan runsaasti vehreyttä ja selkeyttä, ja niiden 
      avulla on helppo ohjata kulkua.
      \\n
      Koivuvanerin joustavuuden ansiosta ruukut ovat miltei saumattomia, ja 
      luonnollinen puunsyy tekee jokaiseen valmistettuun ruukkuun uniikin 
      pinnan. Koivuvaneri tekee ruukuista kevyitä, mutta myös vakaita ja lujia, 
      pitkäaikaista käyttöä ja siirtoa kestäviä.
      \\n
      Koivu-ruukut toimitetaan yhdessä 100 % kierrätysmuovista valmistettujen 
      Orthex-sisäruukkujen kanssa. Asiantuntijamme auttavat mielellään ruukkujen 
      koon, muodon, sävyn ja jalkavaihtoehtojen valinnan kanssa. Paikallinen 
      tuotantomme Suomessa mahdollistaa nopean toimituksen, joka on tilauksesta 
      n. 1-2 kuukautta.`,
  measurements: [
    {
      type: 'T-1020:',
      values: [
        {
          value: 'Leveys 1020 mm'
        },
        {
          value: 'Syvyys 360 mm'
        },
        {
          value: 'Korkeus 270 mm (ilman jalkoja)'
        },
      ]
    },
    {
      type: 'T-1020XL:',
      values: [
        {
          value: 'Leveys 1020 mm'
        },
        {
          value: 'Syvyys 360 mm'
        },
        {
          value: 'Korkeus 720 mm'
        },
      ]
    }
  ],
  materials: [
    {
      type: 'Runko:',
      value: 'Suomalainen koivuvaneri'
    },
    {
      type: 'Sisäruukku:',
      value: 'Orthex, 100 % kierrätysmuovi '
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi'
    },
    {
      type: 'Käyttö:',
      value: 'Sisätilat',
      icon: 'indoor'
    },
  ],
  wlt: {},
  malli: {
    description: `Valitse malli`
  },
  ruukun_vari: {
    description: `Näkyvien osien viimeistely`
  },
  jalat: {
    description: `Jalkavaihtoehdot`
  },
  kasviryhma: {
    description: `Ruukulle soveltuvat kasvit.
S ≈ 500 - 1000 mm korkeat kasvit.`
  },
}

export default koivu_t_sarja