const greenFurniture = {
  productGroupLink: 'Read more about Green furniture',
  headerGroup: 'Green furniture',
  headerTitle: 'Greenery, comfort and buzzing insects in the middle of the city.',
  infoCard1Title: 'Big change, small effort',
  infoCard1Text1: 'Inspiring outdoor spaces as a service',
  infoCard1Text2: 'Lush greenery in the middle of the city',
  infoCard1Text3: 'Well-being, shade and calm',
  infoCard2Title: 'Hassle-free shopping',
  infoCard2Text1: 'Adaptable modules for different needs',
  infoCard2Text2: 'Plants, furniture, installation, maintenance and storage in one package',
  infoCard2Text3: 'We deliver all across Finland',
  infoCard3Title: 'Enjoyment for humans and insects alike',
  infoCard3Text1: 'Support urban biodiversity with green furniture',
  infoCard3Text2: 'Observe the activities of insects and butterflies',
  infoCard3Text3: 'Marvel, smell and taste herbs and berries',
  textCard1: `Green furniture combines a lush greenery with furniture such as benches, dividers 
      and tables. Modular green furniture can create shady and refreshing resting places while 
      supporting urban biodiversity.`,
  infoBox1Title: 'Plants and furniture combined',
  infoBox1Text: `With InnoGreen's modular green furniture solutions, you can easily create 
      comfortable outdoor spaces that provide shadow, and a place to rest and refresh in urban 
      environments. Our green furniture is designed to be placed on an already built surface, 
      which makes it an easy solution for temporary or longer-term needs in urban environments. 
      No foundations or other expensive modifications are required.
      \\n
      Modularity allows you to combine green furniture, such as benches and green walls, to suit 
      the users, the space and the size. Our experts will help you choose the right combination 
      and location. Natural plant selections will bring butterflies and other insects to the 
      site, where they can be observed by urban dwellers or residents at their leisure.
      \\n
      Once the modules and plants have been selected, we deliver the furniture with the plants 
      already in place. Natural plant selections will attract butterflies and insects that can 
      be observed by the passers-by or residents. We maintain and replace the seasonal plants 
      as desired and, when winter comes, we move the modules into winter storage to wait for 
      the next season. All our green equipment is available for rent.`,
  infoBox2Title: 'An inviting green stop',
  infoBox2Text: `Our versatile greenstop is a true contribution to the biodiversity of the 
      urban environment, providing insects such as bumblebees and butterflies with ample food 
      and shelter, as well as vital natural corridors between green spaces. At the same time, 
      the green stop is a resting place for us humans. The green stop is a good place to
       hang out, for example playing chess. It is possible to plant plenty of flowering plants 
       or, for example, edible and delicious herbs and berries. Our outdoor sales team can 
       help you design the right stop for your needs and outdoor space.`,
  infoBox3Title: 'InnoGreen x Parkly green wall module',
  infoBox3Text: `Sit in the embrace of a green wall, enjoy the intoxicating scent of flowers 
  or marvel at the activities of insects. InnoGreen x Parkly combines comfort with the 
  abundance of a green wall. The green wall module not only adds a nice atmosphere, but also 
  brings peace of mind as it absorbs up to 7 db of traffic noise.`,
}

export default greenFurniture