import './styles.scss'
import React, { useState, useRef, useEffect } from 'react'
import CustomImageInput from '../CustomImageInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import QuillEditor from '../../QuillEditor'
import YoutubeVideo from '../../YoutubeVideoInput'
import ContactInput from '../../ContactCardInput'
import InfoBoxInput from '../../InfoBoxInput'
import CustomQuillInput from '../CustomQuillInput'

const DynamicComponentManager = (props) => {
  const {
    type,
    components,
    setComponents,
    onContentChange,
    addComponent,
    deleteComponent,
  } = props
  const [isDropdownOpen, setIsDropdownOpen] = useState(false) // To manage dropdown visibility
  const dropdownRef = useRef(null) // Reference to the dropdown container

  // Close the dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false) // Close dropdown if clicked outside
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside) // Clean up listener
    }
  }, [])

  const handleCustomImageInputChange = (images, identifier) => {
    const object = {
      identifier: identifier,
      type: 'image',
      component: 'CustomImageInput',
      images: images.map(img => img),
      length: images.length
    }
    onContentChange(identifier, object)
  }

  const handleCustomTextInputChange = (text, identifier, language) => {
    const object = {
      identifier: identifier,
      type: 'text',
      component: 'CustomTextInput',
    }

    if (language === 'FI') {
      object.text = text
    } else if (language === 'EN') {
      object.text_en = text
    }

    onContentChange(identifier, object)
  }

  const handleCustomContactInputChange = (contact, identifier) => {
    let object = {
      identifier: identifier,
      type: 'contact',
      component: 'ContactInput',
      ...contact,
    }
    onContentChange(identifier, object)
  }

  const handleCustomVideoInputChange = (url, identifier) => {
    const object = {
      identifier: identifier,
      type: 'video',
      component: 'YoutubeVideo',
      videoUrl: url
    }
    onContentChange(identifier, object)
  }

  const handleCustomInfoBoxInputChange = (obj, identifier) => {
    let object = {
      identifier: identifier,
      type: 'infobox',
      component: 'InfoBoxInput',
      ...obj,
    }
    onContentChange(identifier, object)
  }

  const handleAddComponent = (componentType) => {
    addComponent(componentType)
    setIsDropdownOpen(false) // Close dropdown after selection
  }

  const handleDeleteComponent = (identifier) => {
    deleteComponent(identifier)
    setComponents((prevComponents) => prevComponents.filter(component => component.identifier !== identifier))
  }

  const renderComponent = (component) => {
    switch (component.type) {
      case 'CustomImageInput':
        return (
          <div className="dynamic-component-manager-components-wrapper" key={component.identifier}>
            <CustomImageInput
              onContentChange={handleCustomImageInputChange}
              label="Add Image"
              id={component.identifier}
              componentImages={component.images}
              component={component}
              type={type}
            />
            <div
              className="dynamic-component-manager-components-delete-icon"
              onClick={() => handleDeleteComponent(component.identifier)}
              title="Delete Component"
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </div>
          </div>
        )
      case 'CustomTextInput':
        return (
          <div className="dynamic-component-manager-components-wrapper quill" key={component.identifier}>
            <CustomQuillInput
              identifier={component.identifier}
              initialContent={component.text}
              initialContentEN={component.text_en}
              handleCustomTextInputChange={handleCustomTextInputChange}
            />
            <div
              className="dynamic-component-manager-components-delete-icon"
              onClick={() => handleDeleteComponent(component.identifier)}
              title="Delete Component"
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </div>
          </div>
        )
      case 'ContactInput':
        return (
          <div className="dynamic-component-manager-components-wrapper" key={component.identifier}>
            <ContactInput
              id={component.identifier}
              component={component}
              onContentChange={handleCustomContactInputChange}
            />
            <div
              className="dynamic-component-manager-components-delete-icon"
              onClick={() => handleDeleteComponent(component.identifier)}
              title="Delete Component"
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </div>
          </div>
        )
      case 'YoutubeVideo':
        return (
          <div className="dynamic-component-manager-components-wrapper video" key={component.identifier}>
            <div className="dynamic-component-manager-components-wrapper-video-container">
              <YoutubeVideo
                id={component.identifier}
                onContentChange={handleCustomVideoInputChange}
                video_url={component.videoUrl}
              />
            </div>
            <div
              className="dynamic-component-manager-components-delete-icon"
              onClick={() => handleDeleteComponent(component.identifier)}
              title="Delete Component"
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </div>
          </div>
        )
        case 'InfoBoxInput':
          return (
            <div className="dynamic-component-manager-components-wrapper" key={component.identifier}>
                <InfoBoxInput
                  id={component.identifier}
                  component={component}
                  onContentChange={handleCustomInfoBoxInputChange}
                />
              
              <div
                className="dynamic-component-manager-components-delete-icon"
                onClick={() => handleDeleteComponent(component.identifier)}
                title="Delete Component"
              >
                <FontAwesomeIcon icon={faTrashAlt} size="lg" />
              </div>
            </div>
          )
      default:
        return null
    }
  }

  return (
    
    <div className='dynamic-component-manager-container'>
      <div className="dynamic-component-manager-components">
        {components.map((component) => renderComponent(component))}
      </div>
      <div className='dynamic-component-manager-dropdown-container'>
        <div
          className="dynamic-component-manager-add-component"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          Add Component
        </div>

        {/* Conditional rendering of dropdown */}
        {isDropdownOpen && type === 'reference' && (
          <div className="dynamic-component-manager-dropdown" ref={dropdownRef}>
            <div
              className="dynamic-component-manager-dropdown-option"
              onClick={() => handleAddComponent('CustomImageInput')}
            >
              Custom Image Input
            </div>
            {/* Add more options here if needed */}
          </div>
        )}
        {isDropdownOpen && type === 'blog' && (
          <div className="dynamic-component-manager-dropdown" ref={dropdownRef}>
            <div
              className="dynamic-component-manager-dropdown-option"
              onClick={() => handleAddComponent('CustomImageInput')}
            >
              Image Input
            </div>
            <div
              className="dynamic-component-manager-dropdown-option"
              onClick={() => handleAddComponent('CustomTextInput')}
            >
              Text Input
            </div>
            <div
              className="dynamic-component-manager-dropdown-option"
              onClick={() => handleAddComponent('ContactInput')}
            >
              Contact Card
            </div>
            <div
              className="dynamic-component-manager-dropdown-option"
              onClick={() => handleAddComponent('YoutubeVideo')}
            >
              Youtube video
            </div>
            <div
              className="dynamic-component-manager-dropdown-option"
              onClick={() => handleAddComponent('InfoBoxInput')}
            >
              InfoBox Input
            </div>
            {/* Add more options here if needed */}
          </div>
        )}
      </div>
    </div>
  )
}

export default DynamicComponentManager