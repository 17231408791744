import './styles.scss'

import React, { useState, useEffect } from 'react'
import { fetch_meta_tags } from '../../store/asyncThunk'
import { useSelector, useDispatch } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress
} from "@mui/material"
import moment from 'moment'
import MetaManagementRow from '../MetaManagementRow'

const MetaManagement = () => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const meta_tags = useSelector((state) => state.management.meta_tags)
  const meta_total_count = useSelector((state) => state.management.meta_total_count)
  const meta_fetched_pages = useSelector((state) => state.management.meta_fetched_pages);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0);
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      if (meta_fetched_pages[page + 1] !== rowsPerPage) {
        await dispatch(fetch_meta_tags({ page: page + 1, per_page: rowsPerPage }))
      }
      setIsLoading(false)
    })()
  }, [page, rowsPerPage])

  return (
      <div className='meta-management-container'>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer
            sx={{ width: '100%', borderRadius: 0 }}
            elevation={0}
          >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ width: '3%' }}/>
                  <TableCell className='meta-management-cell-header' align="left" sx={{ width: '15%' }}>Date Modified</TableCell>
                  <TableCell className='meta-management-cell-header' align="left" sx={{ width: '20%' }}>Route</TableCell>
                  <TableCell className='meta-management-cell-header' align="left" sx={{ width: '62%' }}>url</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ?
                  (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                      <div style={{ height: rowsPerPage * 58, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                      </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    meta_tags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                      <MetaManagementRow key={item.id} row={item}/>
                    ))
                  )
                }
                
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={meta_total_count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

  )
}

export default MetaManagement
// {isLoading ?
//   (

//   ) : (

//   )
// }