import './styles.scss'

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  CircularProgress,
  Toolbar,
  Typography,
} from "@mui/material"

import { fetch_inquiry_state_history } from '../../../../store/asyncThunk'

const InquiryStateHistory = (props) => {
  const {

  } = props
  const inquiry = useSelector((state) => state.management.selected_inquiry)
  const [isLoading, setIsLoading] = useState(false)
  const [stateHistory, setStateHistory] = useState([])
  const [tableHeight, setTableHeight] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('priority')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const dispatch = useDispatch()

  useEffect(() => {
    if (inquiry) {
      (async () => {
        setIsLoading(true)
        await dispatch(fetch_inquiry_state_history(inquiry.id))
        .then((res) => {
          setStateHistory(res.payload)
        })
        .catch((err) => {
          console.log(err)
        })
        setIsLoading(false)
      })()
    }
  }, [inquiry])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const get_status = (status) => {
    switch (status) {
      case 1:
        return 'Open'
      case 2:
        return 'In Progress'
      case 3:
        return 'Offer Sent'
      case 4:
        return 'Offer Accepted'
      case 5:
        return 'Offer Rejected'
      case 6:
        return 'Guide Sent'
      case 7:
        return 'Closed'
      default:
        return 'Unknown'
    }
  }

  const format_date = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    }
  }

  return (
    <Paper sx={{ width: '100%', borderRadius: 0 }}>
      <Toolbar>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          State History
        </Typography>
      </Toolbar>
      <TableContainer
        elevation={0}
        sx={{ height: "100%", overflow: "auto" }}
      >
        <Table
          size='small'

        >
          <TableHead>
            <TableRow
            sx={{ backgroundColor: '#f5f5f5' }}
            >
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                User
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ?
              (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <div style={{ height: rowsPerPage * 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                stateHistory.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell >
                      {format_date(item.date_created)}
                    </TableCell>
                    <TableCell >
                      {get_status(item.state)}
                    </TableCell>
                    <TableCell >
                      {item.name}
                    </TableCell>
                  </TableRow>
                ))
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={stateHistory.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default InquiryStateHistory