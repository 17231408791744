import './styles.scss'
import React from 'react'
import { useSelector } from 'react-redux'

const CustomImage = (props) => {
  const {
    images,
    length,
    type
  } = props
  const language = useSelector((state) => state.user.language)

  const getTranslatedTitle = (image) => {
    if (language) {
      if (language === 'FI') {
        return image.title
      } else if (language === 'EN') {
        if (image.title_en) {
          return image.title_en
        } else {
          return image.title
        }
      }
    } else {
      return image.title
    }
  }

  const getTranslatedText = (image) => {
    if (language) {
      if (language === 'FI') {
        return image.text
      } else if (language === 'EN') {
        if (image.text_en) {
          return image.text_en
        } else {
          return image.text
        }
      }
    } else {
      return image.text
    }
  }

  if (type === 'blog') {
    if (length === 1) {
      return (
        <div className='custom-image-blog-container'>
          {
            images[0].title ? 
            (
              <div className='custom-image-blog-title'>{getTranslatedTitle(images[0])}</div>
            ) : (
              <></>
            )
          }
          <div className='custom-image-blog-wrapper'>
            <img src={images[0].previewUrl} alt={images[0].alt} />
          </div>
          {
            images[0].text ?
            (
              <div className='custom-image-blog-text'>{getTranslatedText(images[0])}</div>
            ) : (
              <></>
            )
          }
        </div>
      )
    }
    else if (length == 2) {
      return (
        <div className='custom-image-blog-container-double'>
          {
            images.map((img, index) => {
              return (
                <div className='custom-image-wrapper-double' key={index}>
                  {
                    img.title ?
                    (
                      <div className='custom-image-blog-title'>{getTranslatedTitle(img)}</div>
                    ) : (
                      <></>
                    )
                  }
                  <div className='custom-image-blog-wrapper'>
                    <img src={img.previewUrl} alt={img.alt} />
                  </div>
                  {
                    img.text ?
                    (
                      <div className='custom-image-blog-text'>{getTranslatedText(img)}</div>
                    ) : (
                      <></>
                    )
                  }
                </div>
              )
            })
          }
        </div>
      )
    }
  }

  if (length === 1) {
    return (
      <div className='custom-image-container'>
        <div className='custom-image-wrapper'>
          <img src={images[0].previewUrl} alt={images[0].alt} />
        </div>
      </div>
    )
  }
  else if (length == 2) {
    return (
      <div className='custom-image-container'>
        {
          images.map((img, index) => {
            return (
              <div className='custom-image-wrapper-double' key={index}>
                <img src={img.previewUrl} alt={img.alt} />
              </div>
            )
          })
        }
      </div>
    )
  }

  return <></>
}

export default CustomImage