const koivu_p_sarja = {
  name: 'Koivu P-series',
  longDescription: `The beautiful Koivu pots and room dividers are suitable for 
      a wide range of interiors, bringing a sense of natural peace and harmony 
      to any space. For the most spectacular arrangement, combine different 
      shapes and sizes of Koivu products.
      \\n
      The medium and small plant pots in the Koivu P-series work perfectly in 
      the decor both individually and in arrangements. Choose from pots with 
      paws, wheels or high legs for airiness.
      \\n
      The flexibility of birch plywood makes the pots almost seamless, and the 
      natural wood grain gives each pot a unique surface. Birch plywood makes 
      the pots lightweight, but also stable and strong, resistant to long-term 
      use and transportation.
      \\n
      The Koivu pots are delivered together with Orthex inner pots made of 100% 
      recycled plastic. Our experts are happy to help you with the size, shape, 
      shade and leg options of your pots. Our local production in Finland allows 
      for fast delivery, which is approximately 1-2 months from order.`,
  measurements: [
    {
      type: 'P-550:',
      values: [
        {
          value: 'Diameter 550 mm'
        },
        {
          value: 'Height 400 mm (without legs)'
        },
      ]
    },
    {
      type: 'P-445:',
      values: [
        {
          value: 'Diameter 445 mm'
        },
        {
          value: 'Height 325 mm (without legs)'
        },
      ]
    },
    {
      type: 'P-380:',
      values: [
        {
          value: 'Diameter 380 mm'
        },
        {
          value: 'Height 270 mm (without legs)'
        },
      ]
    },
    {
      type: 'P-380XL:',
      values: [
        {
          value: 'Diameter 380 mm'
        },
        {
          value: 'Height 585 mm (without legs)'
        },
      ]
    }
  ],
  materials: [
    {
      type: 'Body:',
      value: 'Finnish birch plywood'
    },
    {
      type: 'Inner pot:',
      value: 'Orthex, 100% recycled plastic'
    },
    {
      type: 'Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'Usage:',
      value: 'Indoor',
      icon: 'indoor'
    },
  ],
  wlt: {},
  malli: {
    description: `Choose a model`
  },
  ruukun_vari: {
    description: `Colour of the wood finish`
  },
  jalat: {
    description: `Leg options`
  },
  kasviryhma: {
    description: `Plants suitable for selected pot.
S ≈ 500 - 1000 mm tall plants.
M ≈ 1100 - 1600 mm tall plants.
L ≈ 1300 - 2000 mm tall plants.`
  },
}

export default koivu_p_sarja