import './styles.scss'
import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import CustomTextInput from '../CustomTextInput'

const CustomImageInput = (props) => {
  const {
    label,
    id,
    onContentChange,
    type,
    component = null
  } = props
  const [images, setImages] = useState([]) // Array for multiple images
  const fileInputRef = useRef(null)
  const [replaceIndex, setReplaceIndex] = useState(null) // Track which image to replace
  const [initialImageLoaded, setInitialImageLoaded] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('FI')
  const textFIRef = useRef([])
  const textENRef = useRef([])
  const titleFIRef = useRef([])
  const titleENRef = useRef([])

  useEffect(() => {
      if (!initialImageLoaded && component.images) {
        setImages(component.images)
        setInitialImageLoaded(true)
      }
  }, [component]) // Depend on selected_options

  const handleClick = (index) => {
    setReplaceIndex(index) // Set index of image to replace or null to add a new image
    fileInputRef.current.click()
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    
    if (file) {
      const previewUrl = URL.createObjectURL(file)
      setImages((prevImages) => {
        let updatedImages;
        
        if (replaceIndex !== null) {
          // Replace existing image with the new file and previewUrl
          updatedImages = [...prevImages]
          updatedImages[replaceIndex] = { file, previewUrl }
        } else {
          // Add new image (file and previewUrl)
          updatedImages = [...prevImages, { file, previewUrl }]
        }
        onContentChange(updatedImages, id)
        return updatedImages
      })
    }

    // Reset input value to allow the same file to trigger changes
    e.target.value = ''
    setReplaceIndex(null)
  }

  const handleRemoveImage = (e, index) => {
    e.stopPropagation()
  
    // Remove the image from the array
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index)
  
      // Call onContentChange with the updated images array
      onContentChange(updatedImages, id)
  
      return updatedImages
    })
  }

  const handleContentChange = ({key, value, identifier, language ='FI'}) => {
    if (language === 'EN') {
      setImages((prevImages) => {
        // Create a new array to avoid mutating the existing state directly
        const updatedImages = [...prevImages]
    
        // Find the image with the matching id (index) and update its alt text
        updatedImages[identifier] = {
          ...updatedImages[identifier],  // Copy the existing image data
          [`${key}_en`]: value
        }
    
        // Pass the updated image array to the parent component
        onContentChange(updatedImages, id)
    
        return updatedImages;  // Return the new images array to update state
      })
    } else {
      setImages((prevImages) => {
        // Create a new array to avoid mutating the existing state directly
        const updatedImages = [...prevImages]
    
        // Find the image with the matching id (index) and update its alt text
        updatedImages[identifier] = {
          ...updatedImages[identifier],  // Copy the existing image data
          [key]: value
        }
    
        // Pass the updated image array to the parent component
        onContentChange(updatedImages, id)
    
        return updatedImages;  // Return the new images array to update state
      })
    }
  }

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language)
    if (language === 'FI') {
      if (textFIRef && textFIRef.current[0]) {
        textENRef.current[0].style.display = 'none'
        textFIRef.current[0].style.display = 'block'
        titleENRef.current[0].style.display = 'none'
        titleFIRef.current[0].style.display = 'block'
      }
      if (textFIRef && textFIRef.current[1]) {
        textENRef.current[1].style.display = 'none'
        textFIRef.current[1].style.display = 'block'
        titleENRef.current[1].style.display = 'none'
        titleFIRef.current[1].style.display = 'block'
      }
    }
    if (language === 'EN') {
      if (textFIRef && textFIRef.current[0]) {
        textFIRef.current[0].style.display = 'none'
        textENRef.current[0].style.display = 'block'
        titleFIRef.current[0].style.display = 'none'
        titleENRef.current[0].style.display = 'block'
      }
      if (textFIRef && textFIRef.current[1]) {
        textFIRef.current[1].style.display = 'none'
        textENRef.current[1].style.display = 'block'
        titleFIRef.current[1].style.display = 'none'
        titleENRef.current[1].style.display = 'block'
      }
    }
  }

  useEffect(() => {
    handleSelectLanguage(selectedLanguage)
  }, [images.length])
  
  if (type === 'blog') {
    return (
      <>
        <div className='customize-blog-header-language-wrapper'>
          <div className='customize-blog-header-language-selector'>
            <div 
              className={`customize-blog-header-language ${selectedLanguage === 'FI' ? 'active' : ''}`}
              onClick={() => handleSelectLanguage('FI')}
            >
              FI
            </div>
            <div 
              className={`customize-blog-header-language ${selectedLanguage === 'EN' ? 'active' : ''}`}
              onClick={() => handleSelectLanguage('EN')}
            >
              EN
            </div>
          </div>
        </div>
        <div className='custom-image-input-container'>
          <div className='custom-image-input-images'>
            {images.map((image, index) => (
              <div
                key={index}
                className='custom-image-input-image-preview'
                style={{
                  flex: images.length === 1 ? '1 1 100%' : '1 1 calc(50% - 0.5rem)',
                  position: 'relative',
                  borderRadius: '1rem',
                  overflow: 'hidden'
                }}
              >
                <div className='custom-image-input-title-wrapper blog'>
                  <div 
                    ref={(el) => (titleFIRef.current[index] = el)}
                  >
                    <CustomTextInput
                      identifier={index}
                      type='imgTitle'
                      onContentChange={handleContentChange}
                      label='Title FI'
                      value={image.title}
                    />
                  </div>
                  <div
                    className='customize-blog-header-title-en'
                    ref={(el) => (titleENRef.current[index] = el)}
                  >
                    <CustomTextInput
                      identifier={index}
                      type='imgTitle'
                      onContentChange={handleContentChange}
                      label='Title EN'
                      value={image.title_en ?? ''}
                      language='EN'
                    />
                  </div>
                </div>
                <img src={image.previewUrl} alt='Uploaded' className='custom-image-input-image-uploaded blog' onClick={() => handleClick(index)}/>
                <div className='custom-image-input-text-wrapper'>
                  <div 
                    ref={(el) => (textFIRef.current[index] = el)}
                  >
                    <CustomTextInput
                      identifier={index}
                      type='captionText'
                      onContentChange={handleContentChange}
                      label='Caption Text FI'
                      value={image.text}
                    />
                  </div>
                  <div
                    className='customize-blog-header-title-en'
                    ref={(el) => (textENRef.current[index] = el)}
                  >
                    <CustomTextInput
                      identifier={index}
                      type='captionText'
                      onContentChange={handleContentChange}
                      label='Caption Text EN'
                      value={image.text_en ?? ''}
                      language='EN'
                    />
                  </div>
                </div>
                <div className='custom-image-input-alt-text-wrapper blog'>
                  <CustomTextInput
                    identifier={index}
                    type='alt'
                    onContentChange={handleContentChange}
                    label='Alt text'
                    value={image.alt}
                  />
                </div>
                
                <div
                  className='custom-image-input-image-remove-btn fa-layers fa-fw blog'
                  onClick={(e) => handleRemoveImage(e, index)}
                >
                  <FontAwesomeIcon size='xl' icon={faCircle} className='custom-image-input-icon-circle'/>
                  <FontAwesomeIcon size='2xl' icon={faXmarkCircle} className='custom-image-input-icon-x' />
                </div>
              </div>
            ))}
    
            {images.length < 2 && (
              <div
                className='custom-image-input-image-placeholder'
                onClick={() => handleClick(null)}
              >
                { label }
              </div>
            )}
          </div>
          <input
            type='file'
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => handleFileChange(e)}
            accept='image/*'
          />
        </div>
      </>
    )
  }

  if (type === 'contact') {
    return (
      <></>
    )
  }

  return (
    <div className='custom-image-input-container'>
      <div className='custom-image-input-images'>
        {images.map((image, index) => (
          <div
            key={index}
            className='custom-image-input-image-preview'
            style={{
              flex: images.length === 1 ? '1 1 100%' : '1 1 calc(50% - 0.5rem)',
              position: 'relative',
              borderRadius: '1rem',
              overflow: 'hidden'
            }}
          >
            <img src={image.previewUrl} alt='Uploaded' className='custom-image-input-image-uploaded' onClick={() => handleClick(index)}/>
            <div className='custom-image-input-alt-text-wrapper'>
              <CustomTextInput
                identifier={index}
                type='alt'
                onContentChange={handleContentChange}
                label='Alt text'
                value={image.alt}
              />
            </div>
            <div
              className='custom-image-input-image-remove-btn fa-layers fa-fw'
              onClick={(e) => handleRemoveImage(e, index)}
              style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
            >
              <FontAwesomeIcon size='xl' icon={faCircle} className='custom-image-input-icon-circle'/>
              <FontAwesomeIcon size='2xl' icon={faXmarkCircle} className='custom-image-input-icon-x' />
            </div>
          </div>
        ))}

        {images.length < 2 && (
          <div
            className='custom-image-input-image-placeholder'
            onClick={() => handleClick(null)}
          >
            { label }
          </div>
        )}
      </div>
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => handleFileChange(e)}
        accept='image/*'
      />
    </div>
  )
}

export default CustomImageInput