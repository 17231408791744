import common from './common'
import home from './home'
import koivu from './koivu'
import greenWall from './green_wall'
import koivu_viherseina from './koivu_viherseina'
import koivu_p_sarja from './koivu_p_sarja'
import koivu_b_sarja from './koivu_b_sarja'
import koivu_t_sarja from './koivu_t_sarja'
import viherseina from './viherseina'
import raataloitava_viherseina from './raataloitava_viherseina'
import koivu_vihertaulu from './koivu_vihertaulu'
import raataloitava_sammalseina from './raataloitava_sammalseina'
import viherpysakki from './viherpysakki'
import parkly_palveluna from './parkly_palveluna'
import greenFurniture from './green_furniture'
import products from './products'
import greenService from './green_service'
import greenInterior from './green_interior'
import outDoor from './out_door'
import customSolution from './custom_solution'
import wellBeing from './well_being'
import company from './company'
import sustainability from './sustainability'
import work from './work'
import blog from './blog'
import reference from './reference'
import contact from './contact'
import innovation from './innovation'
import login from './login'
import search from './search'
import account from './account'
import project from './project'
import privacy from './privacy'
import management from './management'

const se = {
  common,
  home,
  koivu,
  greenWall,
  koivu_viherseina,
  koivu_p_sarja,
  koivu_b_sarja,
  koivu_t_sarja,
  viherseina,
  raataloitava_viherseina,
  koivu_vihertaulu,
  raataloitava_sammalseina,
  viherpysakki,
  parkly_palveluna,
  greenFurniture,
  products,
  greenService,
  greenInterior,
  outDoor,
  customSolution,
  wellBeing,
  company,
  sustainability,
  work,
  blog,
  reference,
  contact,
  innovation,
  login,
  search,
  account,
  project,
  privacy,
  management
}

export default se