const products = {
  home_page_product_showcase: 'Esittelyssä',
  koivu_page_related_products: 'Aiheeseen liittyvät tuotteet',
  green_wall_page_related_products: 'Aiheeseen liittyvät tuotteet',
  green_furniture_page_related_products: 'Aiheeseen liittyvät tuotteet',
  reference_page_related_products: 'Projektiin liittyvät tuotteet',
  referencePageLinkText: 'Katso kaikki tuotteet',
  greenFurniturePageLinkText: 'Katso kaikki tuotteet',
  greenWallPageLinkText: 'Katso kaikki tuotteet',
  homePageLinkText: 'Katso kaikki tuotteet',
  koivuPageLinkText: 'Katso kaikki tuotteet',
  noProductsFound: 'FI No Products Found',
  // Title for Card links
  ourSolutions: 'Tutustu ratkaisuihimme',
  // InfoBox
  infoBoxTitle: 'Etkö löytänyt etsimääsi?',
  infoBoxText: 'Oliko mielessäsi jokin tietynlainen ruukku? Vai kaipaako tilasi erikoisempaa ratkaisua? Vuosien saatossa olemme toteuttaneet satoja upean uniikkeja kohteita ja tartumme mielellämme kaikenlaisiin luovuutta vaativiin haasteisiin. Erikoiskohteiden toteutus alkaa ammattilaisen laatimalla vihersuunnitelmalla.',
  infoBoxBtnText: 'Räätälöidyt ratkaisut',
  // Filter Butttons
  filter: 'SUODATA',
  new: 'UUTUUDET JA AJANKOHTAISET',
  pots: 'RUUKUT',
  greenWalls: 'VIHERSEINÄT',
  mossAndLichen: 'SAMMAL & JÄKÄLÄ',
  greenFurniture: 'VIHERKALUSTEET',
  inDoor: 'SISÄLLE',
  outDoor: 'ULOS',
  clear: 'TYHJENNÄ',
  // Product groups
  koivu_ruukut: 'Koivu-ruukut',
  viherseinat: 'Viherseinät',
  sammal_jakala: 'Sammal & jäkälä',
  viherkalusteet: 'Viherkalusteet',
  uutuudet_ja_ajankohtaiset: 'Uutuudet ja ajankohtaiset',
  // Product Customizations
  customize: 'Valitse:',
  malli: 'Malli',
  ruukun_vari: 'Ruukun väri',
  jalat: 'Jalat',
  kehyksen_vari: 'Kehyksen väri',
  taustamateriaali: 'Taustamateriaali',
  leveys: 'Leveys',
  korkeus: 'Korkeus',
  muoto: 'Muoto',
  koko: 'Koko',
  taulun_sisalto: 'Taulun sisältö',
  kustomointi: 'Kustomointi',
  perusmoduulit: 'Perusmoduulit',
  viherseinamoduulit: 'Viherseinämoduulit',
  viherseinamoduulin_lisaosat: 'Viherseinämoduulin lisäosat',
  kasviryhma: 'Kasviryhmä',
  // Product Customization Options
  ruukun_vari_options: {
    luonnollinen: 'Luonnollinen',
    valkoinen: 'Valkoinen',
    musta: 'Musta',
    erikoisvari: 'Erikoisväri'
  },
  jalat_options: {
    korkeat_jalat: 'Korkeat jalat',
    tassut: 'Tassut',
    pyorat: 'Pyörät'
  },
  kehyksen_vari_options: {
    luonnollinen: 'Luonnollinen',
    valkoinen: 'Valkoinen',
    musta: 'Musta',
    erikoisvari: 'Erikoisväri'
  },
  taustamateriaali_options: {
    sammal: 'Sammal',
    jakala: 'Jäkälä',
    sekoitus: 'Sekoitus',
  },
  koko_options: {
    pyorea_s: 'S',
    pyorea_m: 'M',
    pyorea_l: 'L',
    nelio_s: 'S',
    nelio_m: 'M',
    nelio_l: 'L',
    suorakaide_l: 'L',
    suorakaide_xl: 'XL',
  },
  korkeus_options: {
    k210_cm: '2100 mm',
    k227_cm: '2270 mm',
    k243_cm: '2430 mm',
  },
  leveys_options: {
    l120_cm: '1200 mm',
    l180_cm: '1800 mm'
  },
  malli_options: {
    p_380: 'P-380',
    p_380xl: 'P-380XL',
    p_445: 'P-445',
    p_550: 'P-550',
    b_665: 'B-665',
    t_1020: 'T-1020',
    t_1020xl: 'T-1020XL',
    tilanjakaja: 'Tilanjakaja',
    seinalle_sijoitettava: 'Seinälle sijoitettava'
  }, 
  muoto_options: {
    pyorea: 'Pyöreä',
    nelio: 'Neliö',
    suorakaide: 'Suorakaide'
  }, 
  taulun_sisalto_options: {
    sammal: 'Sammal',
    jakala: 'Jäkälä',
    mix: 'Mix'
  },
  kasviryhma_options: {
    s: 'S',
    m: 'M',
    l_puuvartinen: 'L - Puuvartinen',
    l_pensanmainen: 'L - Pensanmainen',
    xs: 'XS',
  },
  // Buttons
  addToProject: 'LISÄÄ PROJEKTIIN',
  sendInquiry: 'YHTEYDENOTTOPYYNTÖ',
  inquirySentSuccess: `Kiitos, lomake vastaanotettu. Olemme yhteydessä sinuun mahdollisimman pian!`,
  interesedInProduct: 'Kiinnostuitko tuotteesta?',
}

export default products