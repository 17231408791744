import React, { useState, useEffect, useRef, useMemo } from 'react'

import {
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  FormControl,
  FilledInput,
  InputAdornment,
  Paper,
  Popper,
  MenuItem,
  ClickAwayListener,
} from "@mui/material"
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close';


const InquiriesTableToolbar = (props) => {
  const {
    title = '',
    search = false,
    filter = false,
    dropdownOptions,
    selectedFilter,
    setSelectedFilter,
  } = props
  const inputRef = useRef(null)
  const anchorRef = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)
  

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const update_state = (option) => {
    setSelectedFilter(option)
  }

  const handleClose = () => {
    setOpenDropdown(false)
  }

  const handleClick = () => {
    setOpenDropdown(true)
  }

  const removeFilter = () => {
    setSelectedFilter(null)
  }
  
  return (
    <Toolbar>
      <Typography
        sx={{ flex: '1 1 10%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
      {/* { search && (
          <FormControl sx={{ m: 1, width: '30ch' }}>
            <FilledInput
              id='search'
              placeholder='Search'
              disableUnderline
              inputRef={inputRef}
              startAdornment={
                <InputAdornment
                  position="start"
                >
                  <SearchIcon
                    sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                    onClick={handleIconClick}
                  />
                </InputAdornment>
              }
              sx={{
                paddingTop: 0, // Removes extra top/bottom padding
              }}
            />
          </FormControl>
        )
      } */}
      {selectedFilter && (
        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            mr: 1,
            px: 2,
            py: 1,
            border: '2px solid #D6ACFC',
            borderRadius: '8px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
            backgroundColor: '#F8F1FF',
            position: 'relative',
            pr: 4,
          }}
        >
          {selectedFilter.name}

          <IconButton
            onClick={() => removeFilter()}
            sx={{
              position: 'absolute',
              right: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              padding: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Typography>
      )}
      { filter && (
        <Tooltip>
          <IconButton
            ref={anchorRef}
            onClick={handleClick}
            disableRipple={true}
          >
            <FilterListIcon
            
            />
             <Popper
                open={openDropdown}
                anchorEl={anchorRef.current}
                placement="bottom-end"
              >
                <ClickAwayListener
                  onClickAway={handleClose}
                >
                  <Paper sx={{ p: 1 }}>
                    { dropdownOptions.map((option) => (
                        <MenuItem
                          key={option.id}
                          disableRipple
                          onClick={(e) => {
                            e.stopPropagation()
                            update_state(option)
                          }}
                          selected={option.id === selectedFilter?.id}
                          sx={{ 
                            '&.Mui-selected': {
                              backgroundColor: '#D6ACFC',
                            },
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))
                  }
                  </Paper>
                </ClickAwayListener>
              </Popper>
            
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

export default InquiriesTableToolbar