import './styles.scss'
import React, { useState, useEffect }from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { useNavigate, useLocation } from 'react-router-dom'

import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import BookOutlinedIcon from '@mui/icons-material/BookOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';

import { MenuClose, MenuOpen } from '../../../components/Icons';

import {
  count_inquiry,
} from '../../../store/asyncThunk'


const ManagementSideBar = (props) => {
  const {
    selected,
    setSelected,
    isCollapsed,
    setIsCollapsed,
    handleSetSelectedTab
  } = props
  const user = useSelector((state) => state.user.current_user)
  // const [isCollapsed, setIsCollapsed] = useState(false)
  const dispatch = useDispatch()
  const inquiry_count = useSelector((state) => state.management.management_count)
  const inquiries = useSelector((state) => state.management.inquiries)

  const menuItemStyles = {
    root: {
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'Libre Franklin',
    },

    button: ({ level, active,  }) => {
        return {
          backgroundColor: active ? '#85E8E5' : undefined,
          '&:hover': {
            backgroundColor: active ? '#85E8E5' : undefined,
            color: '#8E5CD9',
            fontWeight: 600,
          },
        }
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  }

  useEffect(() => {
    (async () => {
      if (selected === 'Dashboard') {
        await dispatch(count_inquiry({type: null}))
      } else if (selected !== 'Dashboard' && Object.values(inquiry_count).every((val) => val === 0)) {
        await dispatch(count_inquiry({type: selected.toLowerCase()}))
      }
    })()
  }, [inquiries, selected])

  return (
    <Sidebar
      collapsed={isCollapsed}
      style={{
        height: '100%',
        backgroundColor: '#F9F9F9',
      }}
      width='200px'
      collapsedWidth='70px'
    >
      <Menu
        menuItemStyles={menuItemStyles}
      >
        { !isCollapsed ?
          (
            <MenuItem
              icon={<MenuClose size={30} onClick={() => setIsCollapsed(!isCollapsed)} className='sidebar-menu-close'/>}
              style={{
                margin: "10px 0 20px 0",
                color: 'black',
                cursor: 'default',
                paddingLeft: '10px',
              }}
            >
            </MenuItem>
          ) : (
            <MenuItem
              icon={<MenuOpen size={30} onClick={() => setIsCollapsed(!isCollapsed)} className='sidebar-menu-open'/>}
              style={{
                margin: "10px 0 20px 0",
                color: 'black',
                cursor: 'default',
                paddingLeft: '10px',
              }}
            >
            </MenuItem>
          )
        }
        { user && [1, 3].includes(user.role) && (
            <MenuItem
              icon={<SpaceDashboardOutlinedIcon htmlColor='#8E5CD9'/>}
              className='sidebar-menu-item'
              active={selected === 'Dashboard'}
              onClick={() => handleSetSelectedTab('Dashboard')}
              style={{
                paddingLeft: '10px',
              }}
            >
              Dashboard
    
            </MenuItem>
          )
        }
        { user && [1, 3, 4, 5].includes(user.role) && (
            <SubMenu 
              icon={<InboxOutlinedIcon htmlColor='#8E5CD9'/>}
              label="Inquiry"
              className='sidebar-sub-menu-item'
              style={{
                paddingLeft: '10px',
              }}
              defaultOpen={user.role === 4}
            >
              {/* <MenuItem
                active={selected === 'Inquiries'}
                onClick={() => handleSetSelectedTab('Inquiries')}
                style={{
                  paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                }}
              > 
                {`Inquiries (${inquiry_count.all})`}
              </MenuItem> */}
              { user && [1, 3, 4].includes(user.role) && (
                  <MenuItem 
                    active={selected === 'Contact'}
                    onClick={() => handleSetSelectedTab('Contact')}
                    style={{
                      paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                    }}
                  >
                    {`Contact`}
                  </MenuItem>
                )
              }
              { user && [1, 3, 4].includes(user.role) && (
                  <MenuItem 
                    active={selected === 'Product'}
                    onClick={() => handleSetSelectedTab('Product')}
                    style={{
                      paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                    }}
                  >
                    {`Product`}
                  </MenuItem>
                )
              }
              { user && [1, 3, 4].includes(user.role) && (
                  <MenuItem 
                    active={selected === 'Project'}
                    onClick={() => handleSetSelectedTab('Project')}
                    style={{
                      paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                    }}
                  >
                    {`Project`}
                  </MenuItem>
                )
              }
              { user && [1, 3, 5].includes(user.role) && (
                  <MenuItem 
                    active={selected === 'Guide'}
                    onClick={() => handleSetSelectedTab('Guide')}
                    style={{
                      paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                    }}
                  >
                    {`Guide`}
                  </MenuItem>
                )
              }
              {/* { user && [1, 3].includes(user.role) && (
                  <MenuItem 
                    active={selected === 'Unassigned'}
                    onClick={() => handleSetSelectedTab('Unassigned')}
                    style={{
                      paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                    }}
                  >
                    {`Unassigned (${inquiry_count.unassigned})`}
                  </MenuItem>
                )
              } */}
              {/* { user && [1, 3].includes(user.role) && (
                  <MenuItem
                    active={selected === 'Overdue'}
                    onClick={() => handleSetSelectedTab('Overdue')}
                    style={{
                      paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                    }}
                  >
                    {`Overdue (${inquiry_count.overdue})`}
                  </MenuItem>
                )
              } */}
            </SubMenu>
          )
        }
        {/* { user && [1, 3].includes(user.role) && (
            <SubMenu 
              // defaultOpen={true}
              icon={<SupervisorAccountOutlinedIcon htmlColor='#8E5CD9'/>}
              label="Employee"
              className='sidebar-sub-menu-item'
              style={{
                paddingLeft: '10px',
              }}
            >
              <MenuItem
                active={selected === 'Employees'}
                onClick={() => setSelected('Employees')}
                style={{
                  paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                }}
              >
                Employees
              </MenuItem>
            </SubMenu>
          )
        } */}
        { user && [1, 3].includes(user.role) && (
            <SubMenu
              // defaultOpen={true}
              icon={<ManageAccountsOutlinedIcon htmlColor='#8E5CD9'/>}
              label="User"
              style={{
                paddingLeft: '10px',
              }}
            >
              <MenuItem
                active={selected === 'Users'}
                onClick={() => handleSetSelectedTab('Users')}
                style={{
                  paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                }}
              >
                Users
              </MenuItem>
              <MenuItem
                active={selected === 'Requests'}
                onClick={() => handleSetSelectedTab('Requests')}
                style={{
                  paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                }}
              >
                {`Requests (${inquiry_count.userRequest})`}
              </MenuItem>
            </SubMenu>
          )
        }
        { user && [1, 5].includes(user.role) && (
            <SubMenu
              // defaultOpen={true}
              icon={<BookOutlinedIcon htmlColor='#8E5CD9'/>}
              label="Editor"
              style={{
                paddingLeft: '10px',
              }}
              defaultOpen={user.role === 5}
            >
              <MenuItem
                active={selected === 'Blog'}
                onClick={() => handleSetSelectedTab('Blog')}
                style={{
                  paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                }}
              >
                Blog
              </MenuItem>
              <MenuItem
                active={selected === 'Reference'}
                onClick={() => handleSetSelectedTab('Reference')}
                style={{
                  paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                }}
              >
                Reference
              </MenuItem>
              <MenuItem
                active={selected === 'Meta'}
                onClick={() => handleSetSelectedTab('Meta')}
                style={{
                  paddingLeft: `${isCollapsed ? '20px' : '35px'}`,
                }}
              >
                Meta
              </MenuItem>
            </SubMenu>
          )
        }
      </Menu>
    </Sidebar>
  )
}

export default ManagementSideBar
