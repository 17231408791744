import './styles.scss'
import React, { useEffect, useRef } from 'react'


const TrustMaryWidget = (props) => {
  const {
    language
  } = props
  const containerRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) return

    containerRef.current.innerHTML = ""
    const script = document.createElement("script");
    script.src = language === "EN" ? "https://widget.trustmary.com/WvQaw8HiT" : "https://widget.trustmary.com/Pvb8c-0j0"
    script.async = true;
    containerRef.current.appendChild(script)

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = ""
      }
    }
  }, [language])

  return (
    <div className='trust-mary-widget-container'>
      <div className='trust-mary-widget-body' ref={containerRef}></div>
    </div>
  )
}
export default TrustMaryWidget
