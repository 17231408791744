const outDoor = {
  headerGroup: 'Ulkoalueet',
  headerTitle: 'Vehreitä ratkaisuja haastavaan kaupunkiympäristöön.',
  infoCard1Title: 'Joustavat ja monipuoliset ratkaisut',
  infoCard1Text1: 'Räätälöidyt monivuotiset tai kausiluontoiset ratkaisut',
  infoCard1Text2: 'Kokonaisvaltainen palvelu sisältää kaiken suunnittelusta ylläpitoon',
  infoCard1Text3: 'Helposti vuokrattavat kalusteet ja vihreys',
  infoCard2Title: 'Luonnonmukaista vehreyttä kaupunkiin',
  infoCard2Text1: 'Kotimaiset, luonnonvaraiset ja monivuotiset kasvilajit',
  infoCard2Text2: 'Viihtyisiä alueita myös pölyttäjille',
  infoCard2Text3: 'Luontokäytävät urbaanissa ympäristössä',
  infoBoxTitle1: 'Luonnonmukaisia ja toimivia ulkoalueita',
  infoBoxText1: `Luonnonmukaiset ulkoalueet, niityt, ulkoviherseinät sekä urbaanin ympäristön 
      vihreys ovat erikoisosaamistamme. Suunnittelemme ja ratkaisemme mahdolliset ulkotilan 
      pulmat ja toteutamme toiveet alusta loppuun. Huollamme ja ylläpidämme ulkoalueita 
      ympärivuotisesti, ja palveluumme kuuluu jokaisen käynnin yhteydessä toimitettava raportti.
      \\n
      Turvallisesti ja järkevästi rakennetusta pihasta on iloa pitkään, ja tavoitteenamme on 
      luoda toimivia ulkoalueita, jotka ovat näyttäviä, luonnonmukaisia ja ystävällisiä pölyttäjille. 
      Hyvin suunniteltu piha on vaivaton ylläpitää, ja se kestää aikaa säilyttäen loistonsa.
      \\n
      Ulkoalueille voidaan luoda erilaisia funktioita, kuten alueita rauhoittumiselle, 
      työskentelylle tai ajanviettoon. Otamme huomioon muun muassa materiaalit, kasvivalinnat, 
      kulun ohjauksen, pihan hoidon ja huollon sekä valaistuksen. Pyrimme tekemään 
      ulkoalueesta turvallisen, ympärivuotisen, viihtyisän ja helppohoitoisen, jotta se 
      palvelee käyttäjiään parhaalla mahdollisella tavalla. Kutsu meidät tutustumiskäynnille!`,
  infoBoxTitle2: 'Kutsuvaa vehreyttä',
  infoBoxText2: `Kausikasvit luovat näyttäviä ja kutsuvia sisäänkäyntejä sekä parantavat 
      ulkotilojen viihtyisyyttä. Suunnittelemme, asennamme ja huollamme räätälöidyt 
      kausikasvi-istutukset asiakkaiden toiveiden ja ympäristön edellytysten mukaan. 
      Tuomme vehreyttä ja kukkaloistoa muun muassa ulkoalueille, sisäänkäynneille, 
      taukotiloihin, terasseille ja sisäpihoille. Panostus ulko- ja piha-alueisiin 
      lisää julkisivujen lähestyttävyyttä ja viihtyisyyttä, oli kyseessä sitten 
      yritys tai taloyhtiö.
      \\n
      Kausikasvitoteutuksissa yhdistämme yksi- ja monivuotisia kasveja luodaksemme 
      kestävän ja luonnonmukaisen kokonaisuuden. Käytämme kotimaisia, lähellä 
      tuotettuja taimia ja huomioimme istutuksissamme pölyttäjien tarpeet. Luomme 
      kauniita ja elämyksellisiä ympäristöjä, jotka ovat viihtyisiä sekä ihmisille 
      että pölyttäjille. Toteutamme myös istutuksia, joissa yhdistyvät näköaistin 
      lisäksi tuoksut ja äänet luoden kokonaisvaltaisia aistikokemuksia.`,
  infoBoxTitle3: 'Viherkalusteet',
  infoBoxText3: 'Vehreyttä, viihtyisyyttä ja pörriäisiä keskellä kaupunkia.',
}

export default outDoor