const parkly_palveluna = {
  name: 'Parkly Palveluna',
  longDescription: `Kun valitset Parklyn palveluna, Parklyn modulaariset ratkaisut yhdistyvät InnoGreenin 
      korkealuokkaiseen viherosaamiseen ja palveluun. Saat vaivatta kaupunkiympäristöön soveltuvan 
      viherkalustekokonaisuuden moduuleineen ja kasveineen. InnoGreeniltä saatava palvelu on joustava 
      ja kustannustehokas ratkaisu myös lyhytaikaiseen tarpeeseen. Kiinteä kuukausimaksu sisältää kaiken 
      tarvittavan suunnittelusta toteutukseen sekä ylläpidosta varastointiin.`,
  linkText: 'Tutustu Parklyn katalogiin',
  link: 'https://publuu.com/flip-book/14452/879141',
  title: 'InnoGreen x Parkly',
  longDescription2: `InnoGreenin ja Parklyn kehittämässä viherseinässä yhdistyy InnoGreenin ainutlaatuinen 
      viherseinärakenne sekä Parklyn kaupunkikalusteiden muunneltavuus.
      \\n
      InnoGreen x Parkly -viherseinämoduuli tuo runsasta vehreyttä, viilennystä, varjoa ja pörriäisiä 
      houkuttelevia tuoksuvia kasveja vaikka keskelle kaupungin vilinää. Viherseinämoduuli ei ainoastaan 
      lisää viihtyisyyttä, vaan tuo myös rauhaa sillä se vaimentaa liikenteen ääntä jopa 7 dB. 
      \\n
      Viherseinämoduuli on saatavilla penkillä tai ilman. Moduuleja on helppo yhdistellä useampia peräkkäin 
      kokonaiseksi oleskelualueeksi ja suuremmaksi yhtenäiseksi viherseinäksi. Onko mukavampaa paikkaa 
      levähtää kesäkuumalla, kuin varjoisassa viherseinän syleilyssä?`,
  measurements: [
    {
      type: 'Viherseinämoduuli:',
      value: '1200 x 500 x 1800 mm'
    },
    {
      type: 'Viherseinämoduuli istuimella: ',
      value:'1200 x 1200 x 1800 mm'
    }
  ],
  materials: [
    {
      type: 'Runko:',
      value: 'Sinkitty ja pulverimaalattu metalli'
    },
    {
      type: 'Istuinosat:',
      value: 'Lämpökäsitelty saarni'
    },
    {
      type: 'Viherseinän moduulit:',
      value: 'Abs-muovi, valmistettu Kaarinassa'
    },
    {
      type: 'Kasvit:',
      value: 'Suomi ja Eurooppa'
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi ja Eurooppa'
    },
    {
      type: 'Käyttö:',
      value: 'Sisä- ja ulkotilat',
      icon: 'both'
    },
  ],
  wlt: {},
}

export default parkly_palveluna