import ClipLoader from 'react-spinners/ClipLoader'
import './styles.scss'

const LoadingScreen = () => {
  return (
    <div className='loading-screen-container'>
      <ClipLoader color='#1a73e8' />
    </div>
  )
}

export default LoadingScreen
