const koivu_vihertaulu = {
  name: 'SE Koivu-vihertaulu',
  longDescription: `SE Koivu green panels are decorative elements that 
      evoke the tranquillity of the forest and that will last for years. 
      They are lightweight and quick and easy to hang, and the 
      maintenance-free design allows them to be arranged creatively. 
      They work as individual eye-catchers, but can also be combined 
      to create stunning ensembles and still lifes. 
      \\n
      Inside the frames made of Finnish birch, you can choose moss, 
      lichen or a combination of the two. The panels are available 
      in three different shapes; round, square and rectangle.
      \\n
      The Koivu green panels use maintenance-free and irrigation-free 
      stabilised moss and lichen. Stabilised plants are plants that 
      have been preserved. The stabilisation process uses glycerine 
      and, despite the treatment, the plants are recyclable and 
      biodegradable. The process can be used to preserve real plants 
      and bring them into more challenging environments. Stabilised 
      mosses, lichens and plants will keep their shape and beautiful 
      colour for years.`,
  measurements: [
    {
      type: 'SE Round (diameter):',
      values: [
        {
          value: 'S 60 cm'
        },
        {
          value: 'M 90 cm'
        },
        {
          value: 'L 120 cm'
        }
      ]
    },
    {
      type: 'SE Square:',
      values: [
        {
          value:'S 60x60 cm'
        },
        {
          value:'M 90x90 cm'
        },
        {
          value:'L 120x120 cm'
        }
      ]
    },
    {
      type: 'SE Rectangular:',
      values: [
        {
          value:'L 120x60 cm'
        },
        {
          value:'XL 180x90 cm'
        }
      ]
    },
    {
      type: 'SE Depth of the frame:',
      value: '6 cm on all models'
    },
  ],
  materials: [
    {
      type: 'SE Frame:',
      value: 'Finnish birch plywood'
    },
    {
      type: 'SE Material inside the panel:',
      value: 'Maintenance-free stabilised moss or lichen (EU)'
    },
    {
      type: 'SE Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'SE Usage:',
      value: 'Indoor',
      icon: 'indoor'
    },
  ],
  wlt: {},
  muoto: {
    description: `SE Taulun muoto`,
    pyorea: 'SE Pyöreä',
    nelio: 'SE Neliö',
    suorakaide: 'SE Suorakaide'
  },
  koko: {
    description: `SE Taulun koko`
  }, 
  kehyksen_vari: {
    description: `SE Näkyvien osien viimeistely`
  },
  taulun_sisalto: {
    description: `SE Valitse materiaali taulun sisälle`,
    sammal: 'SE Sammal',
    jakala: 'SE Jäkälä',
    mix: 'SE Mix'
  },
}

export default koivu_vihertaulu