import './styles.scss'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatches } from 'react-router-dom'

import HeaderBox from '../../components/HeaderBox'
import ContactCardGroup from '../../components/ContactCardGroup'
import ContactInquiry from '../../components/ContactInquiry'
import ContactDepartmentGroup from '../../components/ContactDepartmentGroup'

import headerImgSrc from '../../assets/images/Contact/ota-yhteytta-1.jpg'

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let scrollRefs = useRef([])
  const { t, i18n } = useTranslation()
  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  return (
    <>
      <div className='contact-page-container'>
        <div className='contact-page-body-container'>
          <HeaderBox
            titleText={t('contact.headerTitle')}
            imgSrc={headerImgSrc}
            type='contactPage'
          />

          <ContactCardGroup scrollRefs={scrollRefs} />

          <ContactInquiry />
        </div>
        <ContactDepartmentGroup scrollRefs={scrollRefs} />
      </div>
    </>
  )
}

export default Contact
