const blog = {
  headerTitle: 'SE Blogi',
  filter: 'SE SUODATA',
  clear: 'SE TYHJENNÄ',
  continue: 'SE Jatka lukemista',
  readAlso: 'SE Lue myös',
  readAlsoLinkText: 'SE Katso kaikki artikkelit',
  biodiversiteetti: 'SE Biodiveriteetti',
  biofilia: 'SE Biofilia',
  innogreen: 'InnoGreen',
  innovaatiot: 'SE Innovaatiot',
  kaupunkisuunnittelu: 'SE Kaupunkisuunnittelu',
  koivu: 'Koivu',
  pihasuunnittelu: 'SE Pihasuunnittelu',
  rekrytointi: 'SE Rekrytointi',
  tyohyvinvointi: 'SE Työhyvinvointi',
  ulkoalueet: 'SE Ulkoalueet',
  vastuullisuus: 'SE Vastuullisuus',
  viherkalusteet: 'SE Viherkalusteet',
  vihersisustus: 'SE Vihersisustus',
  vinkit: 'SE Vinkit',
  viherseina: 'SE Viherseinä',
  page: 'SE Sivu',
  next: 'SE Seuraavat',
  previous: 'SE Edelliset',
}

export default blog