const wellBeing = {
  headerGroup: 'Greenery for well-being',
  headerTitle: 'Greener, more sustainable and more prosperous cities with the help of plants.',
  infoCard1Title: 'Better quality of life',
  infoCard1Text1: 'Less stress, more recovery',
  infoCard1Text2: 'More creativity and productivity',
  infoCard1Text3: 'Welcoming spaces and a stronger connection to nature',
  infoCard2Title: 'Solutions for the future',
  infoCard2Text1: 'Managing stormwater with vegetation',
  infoCard2Text2: 'Cooling greenery in the urban environment',
  infoCard2Text3: 'Flowers and nature corridors for pollinators',
  infoBox1Title: 'Greenery enhances our well-being',
  infoBox1Text: `Plants improve the aesthetic appeal of a space, whether it's a home, a 
      workplace or a city. Research shows that green environments, such as green outdoor 
      areas or the presence of plants indoors, improve mood and help maintain a connection 
      with nature. Plants give spaces a pleasant atmosphere and significantly improve 
      our well-being by reducing stress and increasing concentration and productivity.
      \\n
      Research shows that the presence of plants in workspaces increases creativity 
      and concentration in employees. This leads to greater job satisfaction and lower 
      employee turnover. Environments with natural elements create a pleasant atmosphere, 
      which has been shown to improve employee mood and motivation, and give customers 
      a positive first impression. The benefits of green spaces are therefore directly 
      reflected in the company's operations and results.`,
  infoBox2Text: `Urban greenery, such as indoor greenery or an inviting entrance 
      glowing with seasonal plants, can promote sales. Studies show that customers 
      are more comfortable and relaxed in environments with plants and natural elements. 
      This increases their willingness to stay longer and explore a wider range of 
      services or products, which can lead to more purchases. 
      \\n
      Green and attractive entrances and outdoor areas can increase the numbers of 
      visitors and extend the time customers spend in shopping centres, for example. 
      In addition, customers who find the environment of a store or shopping centre 
      pleasant and relaxing are more likely to return. Greenery can create positive 
      images and enhance brand loyalty. A well-maintained and pleasant environment 
      also sends a message about the values of the company, which builds trust 
      among employees and customers alike.`,
  textCard1Title: 'Download the Minä Viihdyn -booklet!',
  textCard1Text: 'Find out more about the benefits of going green with our free guide booklets',
  infoBox3Title: 'Urban greenness is a shared responsibility',
  infoBox3Text: `Green urban environments are not only aesthetically pleasing; 
      they are also important ecologically, socially and economically.
      \\n
      Well-designed urban green spaces provide natural habitats for many species, 
      including birds and pollinators. This supports urban biodiversity and 
      contributes to the equilibrium of ecosystems. Biodiversity can improve 
      the sustainability of green spaces and reduce the need for chemical 
      pesticides and fertilisers.
      \\n
      Plants also absorb carbon dioxide and other air pollutants, improving urban 
      air quality and reducing greenhouse gases in the atmosphere. In addition, 
      modern, ecologically designed green solutions, such as green walls that use 
      stormwater runoff, can improve stormwater management, which reduces the 
      risk of flooding and improves the quality of waters. Stormwater management 
      is particularly important in cities where hard surfaces prevent water from 
      naturally soaking into the soil. Vegetation and green spaces can help filter 
      and store rainwater, reducing the load on the sewerage system.`,
  infoBox4Text: `Green solutions can also help mitigate the urban heat island effect. 
      The heat island effect is mainly caused by the heat-trapping properties of 
      buildings, roads and other surfaces, which raise urban temperatures. Adding 
      vegetation to urban areas can help cool the air and reduce energy consumption, 
      as green spaces provide shade and cool their surroundings by evaporating water.
      \\n
     Companies, communities and cities that invest in green spaces send a strong 
     message about their environmental responsibility. Green spaces, whether indoors 
     or outdoors, are a visible sign of commitment to sustainability and environmental 
     protection. For example, companies that use modern green solutions such as 
     green roofs and stormwater walls can contribute to reducing environmental stress 
     and promoting biodiversity in the urban environment. These green solutions not 
     only improve the environment, but can also bring economic benefits such as saving 
     energy and potable water. In addition, green spaces can add value to real estate 
     and attract new customers and employees, making businesses more competitive. 
     Outdoor spaces with comfortable seating and shaded areas provide places to meet and 
     relax, improving overall well-being and creating a sense of community in the 
     urban environment.`,
  appTitle: 'Download the Minä Viihdyn -booklet!',
  appText: 'Find out more about the benefits of going green with our free guide booklets',
  appBodyTitle: 'Download the guides (in Finnish) by filling out the form!',
  appBodyText: 'The guide will be sent to the email you provided. By filling out the form, you accept InnoGreen´s marketing register',
  appBodyLink: 'terms.',
  appBodyBullet1Title: 'Part 1: Well-being at work adds power to everyday life:',
  appBodyBullet1Text1: 'Why you should invest in occupational well-being',
  appBodyBullet1Text2: 'What does employee experience mean',
  appBodyBullet1Text3: 'What effect does space planning and green interior have on employees',
  appBodyBullet1Text4: 'The first 5 steps towards better work comfort',
  appBodyBullet2Title: 'Part 2: Increase sales with greenery:',
  appBodyBullet2Text1: 'How well-being affects your company´s results',
  appBodyBullet2Text2: 'How do you plan a space that supports sales',
  appBodyBullet2Text3: 'How to get the most out of green interior design',
  appBodyBullet2Text4: 'What is the importance of comfort in purchasing behavior',
  inputName: 'Name',
  inputCompany: 'Company',
  inputEmail: 'Email',
  inputPhone: 'Phone',
  emailList: 'I want to join the InnoGreen email list',
  buttonText: 'Send',
  cardGroup: [
    {
      title: 'Stormwater',
      text: `Stormwater refers to rainwater and meltwater that flows from streets into drains. 
          Stormwater is a phenomenon particularly prevalent in urban areas where hard surfaces 
          predominate over absorbing surfaces. Depending on the age of the sewerage system, 
          stormwater is either connected to the wastewater network or has its own stormwater 
          network to reduce the load on wastewater treatment plants. Stormwater is not without 
          its problems, as it accumulates substances such as road salt, heavy metals and 
          microplastics. Attention needs to be paid to the management of stormwater run-off, 
          as some of the substances will end up in water bodies despite treatment of the 
          stormwater. However, stormwater has a huge potential for beneficial use. When used 
          to irrigate plants, it saves water, reduces the load on sewage systems and 
          prevents water pollution.`,
      show: 'Show more',
      hide: 'Show less',
      type: 1
    },
    {
      title: 'The heat island effect',
      text: `Urban buildings, roads and other hard surfaces store heat, raising urban 
          temperatures above those of surrounding rural areas. This phenomenon is known as 
          the heat island effect and is particularly severe during heat waves. Vegetation 
          in cities can help cool the air by evaporating water and providing shade, thus 
          reducing air conditioning costs. Green roofs, parks and other green solutions 
          are effective ways to mitigate the effects of the heat island phenomenon. 
          Increasing the greenness of urban areas can improve the quality of life of urban 
          residents and reduce the impact of climate change.`,
      show: 'Show more',
      hide: 'Show less',
      type: 1
    },
    {
      title: 'Urban biodiversity',
      text: `Urban biodiversity refers to the diversity of different species in urban areas, 
      such as parks, green roofs and other green areas. Diverse green solutions provide 
      habitats for pollinators, birds and other animals, thus supporting the balance of 
      ecosystems. Urban biodiversity also improves urban sustainability by reducing the 
      need for chemicals and improving stormwater management. Ecologically designed green 
      solutions increase residents' connection to nature and improve their well-being. 
      Urban biodiversity is an important component of sustainable urban development and 
      environmental protection.`,
      show: 'Show more',
      hide: 'Show less',
      type: 1
    },
    {
      title: 'Sources',
      text: `Berland, A., Shiflett, S. A., Shuster, W. D., Garmestani, A. S., Goddard, H. C., Herrmann, D. 
          L., & Hopton, M. E. (2017). The role of trees in urban stormwater management. 
          **Landscape and Urban Planning, 162**, 167-177.
          \\n
          Bianchini, F., & Hewage, K. (2012). How “green” are the green roofs? Lifecycle analysis of green 
          roof materials. **Building and Environment, 48**, 57-65.
          \\n
          Bringslimark, T., Hartig, T., & Patil, G. G. (2007). Psychological benefits of indoor plants in 
          workplaces: Putting experimental results into context. **HortScience, 42**(3), 581-587.
          \\n
          Elings, M. (2006). People-plant interaction: The physiological, psychological, and sociological 
          effects of plants on people. In **Hassink, J., & Van Dijk, M. (Eds.), Farming for Health** (pp. 43-55). Springer.
          \\n
          Elmqvist, T., et al. (2013). Urbanization, Biodiversity and Ecosystem Services: Challenges and Opportunities. Springer.
          \\n
          Getter, K. L., & Rowe, D. B. (2006). The role of extensive green roofs in sustainable development. **HortScience, 41**(5), 1276-1285.
          \\n
          Hall, C., & Dickson, M. (2011). Economic, environmental, and health/well-being benefits associated 
          with green industry products and services: A review. **Journal of Environmental Horticulture, 29**(2), 96-103.
          \\n
          Lohr, V. I., Pearson-Mims, C. H., & Goodwin, G. K. (1996). Interior plants may improve worker productivity and 
          reduce stress in a windowless environment. **Journal of Environmental Horticulture, 14**(2), 97-100.
          \\n
          McDonald, R. I., Marcotullio, P. J., & Güneralp, B. (2013). Urbanization and global trends in biodiversity and 
          ecosystem services. In **Elmqvist, T. et al. (Eds.), Urbanization, Biodiversity and Ecosystem Services: Challenges 
          and Opportunities** (pp. 31-52). Springer.
          \\n
          Nowak, D. J., Crane, D. E., & Stevens, J. C. (2010). Air pollution removal by urban trees and shrubs in the United 
          States. **Urban Forestry & Urban Greening, 4**(3-4), 115-123.
          \\n
          Ulrich, R. S. (1984). View through a window may influence recovery from surgery. **Science, 224**(4647), 420-421.
          \\n
          Wolf, K. L. (2005). Business district streetscapes, trees, and consumer response. **Journal of Forestry, 103**(8), 396-400.`,
      show: 'Show more',
      hide: 'Show less',
      type: 2
    },
  ],
  guideDownloadSuccessMessage: 'Thank you, form received. The guide download links can be found in your email. Please also check your spam folder.',
  genericDownloadRequestError: 'Failed to request, please try again!',
}

export default wellBeing