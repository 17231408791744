const sustainability = {
  headerGroup: 'SE Vastuullisuus',
  headerTitle: 'SE InnoGreen ja vastuullisuus',
  textCard: `SE Missionamme on kehittää viherratkaisuja, jotka parantavat elämänlaatua 
      kaupunkiympäristössä. Siksi kestävän kehityksen mukaiset periaatteet sekä 
      luontoa että inhimillisyyttä korostavat arvot ovat toimintamme lähtökohta.`,
  infoBoxTitle1: `SE Meille vastuullisuus ei ole vain valinta vaan se on tapa toteuttaa 
      missiotamme. Tavoitteenamme ei ole ainoastaan olla markkinoiden johtava 
      viherpalveluja tarjoava yritys, vaan myös vastuullisin kumppani ja työnantaja.`,
  infoBoxText1: `SE Emme tavoittele täydellisyyttä, vaan sitä, että menemme joka päivä 
      askeleen eteenpäin. Meille on tärkeää, että suuret tavoitteet näkyvät myös 
      konkreettisina tekoina, jotka sekä työntekijämme että asiakkaamme näkevät.`,
  infoCard1Text: `SE Vastuullisuus on toimintamme ydin, jonka pohjalta kehitämme tuotteita
      ja palveluita, jotka vastaavat sekä tämän päivän että tulevaisuuden haasteisiin.`,
  infoCard2Text: `SE Rakennamme yhdessä asiakkaidemme kanssa kestävämpiä, vehreämpiä ja 
      viihtyisämpiä kaupunkeja.`,
  infoBoxTitle2: `SE InnoGreenin innovatiiviset viherratkaisut tuovat luonnon osaksi 
      kaupunkimiljöötä ja luovat konkreettisia hyötyjä sekä ihmisille että ympäristölle. 
      Kestävä kehitys näkyy tuotteissamme ja palveluissamme – kehitämme ratkaisuja, 
      jotka auttavat kaupunkeja sopeutumaan ilmastonmuutokseen, parantamaan elämänlaatua 
      ja tukemaan luonnon monimuotoisuutta.`,
  infoBoxText2: `SE Pyrimme siihen, että toimintamme kokonaisvaikutus on positiivinen, eli 
      se tuo enemmän hyötyä kuin haittaa.
      \\n
      Käytämme Upright Projectin mittaristoa arvioidaksemme liiketoimintamme vaikutuksia 
      yhteiskuntaan, ympäristöön ja talouteen. Nettopositiivisuus merkitsee meille jatkuvaa 
      pyrkimystä vähentää ympäristökuormitusta ja innovoida uusia, kestävämpiä ratkaisuja.`,
  infoBoxTitle3: `SE Toimintaamme ohjaavat YK:n kestävän kehityksen tavoitteet:`,
  infoBoxLink3: `https://www.ykliitto.fi/kestava-kehitys`,
  infoBoxLinkText3: `SE Tutustu YK:n kestävän kehityksen tavoitteisiin.`,
  infoBoxBullet1Title: `SE Ihmisarvoinen työ ja talouskasvu (tavoite 8)`,
  infoBoxBullet1Text: `SE Tarjoamme reiluja ja merkityksellisiä työpaikkoja sekä luomme kestävää kasvua.`,
  infoBoxBullet2Title: `SE Kestävät kaupungit ja yhteisöt (tavoite 11)`,
  infoBoxBullet2Text: `SE Autamme kaupunkeja kehittymään kestävämmiksi tuomalla lisää vihreyttä.`,
  infoBoxBullet3Title: `SE Maanpäällinen elämä (tavoite 15)`,
  infoBoxBullet3Text: `SE Suojelemme sademetsiä ja edistämme luonnon monimuotoisuutta tuotteissamme ja palveluissamme.`,
  cards: [
    {
      title: 'SE 38%',
      text: 'SE InnoGreenin nettovaikutussuhde',
      linkSourceText: 'Lähde:',
      linkText: 'Upright Project',
      link: 'https://uprightplatform.com/company/8459bf39-ef8f-4875-bcce-161a950bda38/InnoGreen/?utm_source=profile_share_link&utm_medium=referral&utm_campaign=share',
    },
    {
      title: 'SE 2 000 000 m²',
      text: 'SE Suojeltua sademetsää yhteistyössä <link> kanssa.',
      linkText: 'World Land Trustin',
      link: 'https://www.worldlandtrust.org/',
    },
    {
      title: '25+',
      text: 'SE Omaa tuoteinnovaatiota',
    },
  ],
  ourSustainabilityText: 'SE Vastuullisuustekomme',
  ourSustainabilityCards: [
    {
      title: 'SE Kestäviä viherratkaisuja',
      text: [
        'SE Hillitsemme lämpösaarekeilmiötä, vähennämme melua ja hallitsemme hulevesiä viherseinien ja -kalusteiden avulla.',
        'SE Parannamme kaupunkien viihtyisyyttä ja tuemme yhteisöllisyyttä luomalla vehreitä kohtaamispaikkoja.',
        'SE Suunnittelemme ja valmistamme itse pääosan käyttämistämme ruukuista ja viherkalusteista, jotta voimme varmistua tuotteidemme alkuperästä ja vastuullisuudesta.'
      ]
    },
    {
      title: 'SE Vaalimme luontoa',
      text: [
        'SE Suojelemme maailman sademetsiä yhteistyössä World Land Trustin kanssa.',
        'SE Luomme vehreitä keitaita jo rakennettuun ympäristöön tukeaksemme pölyttäjien tärkeää työtä.',
        'SE Valitsemme käyttämämme materiaalit tarkkaan, jotta tuotteemme ovat kestäviä ja vältymme turhalta roskalta. Käytämme ainoastaan aitoja kasveja.'
      ]
    },
    {
      title: 'SE Edistämme kiertotaloutta',
      text: [
        'SE Huollamme kasvit säännöllisesti, jotta vihreydestä voi nauttia vuosikausia. Sijoitamme vuokrasta palautuvat kasvit uusiin kohteisiin.',
        'SE Kaikki tuotteemme, kuten ruukut ja tilanjakajat, ovat saatavilla vuokrapalveluna, ilman omistamisen tarvetta.',
        'SE Huolehdimme tuotteiden ja kalusteiden huollosta sekä uudelleenkäytöstä koko niiden elinkaaren ajan. Kierrätämme uusiokäyttöön kelpaamattomat osat.'
      ]
    }
  ],
  cardGroup: [
    {
      title: 'SE Hiilijalanjälki',
      text: [
        {
          text: `SE Hiilijalanjälki mittaa tuotteesta sen elinkaaren aikana syntyvät kasvihuonekaasupäästöt. Kyseessä on siis mittari, 
              joka kertoo tuotteen vaikutuksista ilmastoon ja ilmastonmuutokseen.`
        },
        {
          text: `Hiilijalanjälkilaskennan yhteydessä arvioidaan, mistä suurin osa tuotteiden päästöistä syntyy ja miten niitä voidaan 
              pienentää esimerkiksi materiaalivalinnoin tai erilaisin tuotantomenetelmin. Tietoa hyödynnetään tuotteiden jatkokehityksessä.`
        },
        {
          text: `InnoGreen on laskenut Koivu-tuotteiden hiilijalanjäljet. Löydät tuotekohtaiset tiedot ladattavista tuotekorteista. Katso lisää <Link>`,
          link: 'products',
          linkText: 'Tuotteet-sivulta.',
          type: 1
        },
        {
          text: `Tuotteiden hiilijalanjäljet arvioitiin <Link> koostamalla, Greenhouse Gas Protocol Product Standard -protokollan mukaisella laskentamenetelmällä.
              Laskennassa huomioitiin tuotteiden komponenttien ja materiaalien alkutuotannon, kokoonpanon ja asennuksen, kuljetusten sekä jätehuollon aikana syntyneet yleisimmät
              kasvihuonekaasut. Laskennasta rajattiin pois tuotteiden sisältämien kasvien, kasvualustan, ylläpidon sekä loppusijoituksen tai kierrätyksen tuottamat päästöt, 
              hiilensidonta ja mahdollinen vedenkäyttö.`,
          link: 'https://thirdrock.fi/',
          linkText: 'Third Rockin',
          type: 2
        },
      ],
      show: 'SE Näytä lisää',
      hide: 'SE Näytä vähemmän',
      type: 3,
      title2: 'SE Vertailukohtia:',
      text2: `SE Suomalaisen keskimääräinen hiilijalanjälki päivässä: 26,3 kgCO2e`,
      text3: 'SE Sitra: <Link>, Päivitetty 22.12.2023',
      link: 'https://www.sitra.fi/artikkelit/keskivertosuomalaisen-hiilijalanjalki/',
      linkText: 'SE Keskivertosuomalaisen hiilijalanjälki'
    },
  ]
}

export default sustainability