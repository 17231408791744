import './styles.scss'
import React, { useState, useEffect, useRef } from 'react'
import CustomTextInput from '../common/CustomTextInput'
import kirsiHImgSrc from '../../assets/images/Contact/kirsi-hanninen.jpg'
import leenaKImgSrc from '../../assets/images/Contact/leena-kinnunen.jpg'
import liivikaKImgSrc from '../../assets/images/Contact/liivika-korkalo.jpg'
import anneOImgSrc from '../../assets/images/Contact/anne-ollila.jpg'
import katjaKImgSrc from '../../assets/images/Contact/katja-kankkunen.jpg'
import samiVImgSrc from '../../assets/images/Contact/sami-vainionpaa.jpg'
import miiaKImgSrc from '../../assets/images/Contact/miia-kujala.jpg'
import johannaMImgSrc from '../../assets/images/Contact/johanna-murto.jpg'
import kristinaHImgSrc from '../../assets/images/Contact/kristina-hulkkonen.jpg'
import sariRImgSrc from '../../assets/images/Contact/sari-raty.jpg'
import raisaKImgSrc from '../../assets/images/Contact/raisa-koiranen.jpg'
import teijoTImgSrc from '../../assets/images/Contact/teijo-tiainen.jpg'
import lauraTImgSrc from '../../assets/images/Contact/laura-tubal.jpg'
import piaPImgSrc from '../../assets/images/Contact/pia-paajanen.jpg'
import oulaHImgSrc from '../../assets/images/Contact/oula-harjula.jpg'
import mikkoSImgSrc from '../../assets/images/Contact/mikko-sonninen.jpg'
import jaakkoPImgSrc from '../../assets/images/Contact/jaakko-pesonen.jpg'
import noraTImgSrc from '../../assets/images/Contact/nora-tag.jpg'
import unidecode from 'unidecode'

const employees = [
  {
    name: 'Kirsi Hänninen',
    imgSrc: kirsiHImgSrc,
    department: 'VIHERSISUSTUS',
    department_en: 'GREEN DECOR',
    email: 'kirsi.hanninen@innogreen.fi',
    phoneNumber: '040 451 6565',
  },
  {
    name: 'Leena Kinnunen',
    imgSrc: leenaKImgSrc,
    department: 'VIHERSUUNNITTELU, VIHERSISUSTUS',
    department_en: 'GREEN DESIGN, GREEN DECOR',
    email: 'leena.kinnunen@innogreen.fi',
    phoneNumber: '050 543 7035',
  },
  {
    name: 'Liivika Korkalo',
    imgSrc: liivikaKImgSrc,
    department: 'VIHERSUUNNITTELU, VIHERSISUSTUS',
    department_en: 'GREEN DESIGN, GREEN DECOR',
    email: 'liivika.korkalo@innogreen.fi',
    phoneNumber: '040 838 3223',
  },
  {
    name: 'Anne Ollila',
    imgSrc: anneOImgSrc,
    department: 'VIHERSISUSTUS, TAMPERE JA MUU SUOMI',
    department_en: 'GREEN DECOR, TAMPERE AND REST OF FINLAND',
    email: 'anne.ollila@innogreen.fi',
    phoneNumber: '050 569 5262',
  },
  {
    name: 'Katja Kankkunen',
    imgSrc: katjaKImgSrc,
    department: 'ASIAKASVASTAAVA, VIHERSISUSTUS, ITÄ-SUOMI',
    department_en: 'ACCOUNT MANAGER, GREEN DECOR, EASTERN FINLAND',
    email: 'katja.kankkunen@innogreen.fi',
    phoneNumber: '050 472 2986',
  },
  {
    name: 'Sami Vainionpää',
    imgSrc: samiVImgSrc,
    department: 'MYYNTIJOHTAJA',
    department_en: 'SALES MANAGER',
    email: 'sami.vainionpaa@innogreen.fi',
    phoneNumber: '050 566 6566',
  },
  {
    name: 'Miia Kujala',
    imgSrc: miiaKImgSrc,
    department: 'MYYNTI',
    department_en: 'SALES',
    email: 'miia.kujala@innogreen.fi',
    phoneNumber: '050 401 7891',
  },
  {
    name: 'Johanna Murto',
    imgSrc: johannaMImgSrc,
    department: 'MYYNTI & HALLINTO',
    department_en: 'SALES & ADMINISTRATION',
    email: 'johanna.murto@innogreen.fi',
    phoneNumber: '050 431 5422',
  },
  {
    name: 'Kristina Hulkkonen',
    imgSrc: kristinaHImgSrc,
    department: 'HUOLTOPÄÄLLIKKÖ',
    department_en: 'MAINTENANCE MANAGER',
    email: 'kristina.hulkkonen@innogreen.fi',
    phoneNumber: '050 463 1657',
  },
  {
    name: 'Sari Räty',
    imgSrc: sariRImgSrc,
    department: 'HUOLTOPÄÄLLIKKÖ (HELSINKI JA TURKU)',
    department_en: 'MAINTENANCE MANAGER (HELSINKI AND TURKU)',
    email: 'sari.raty@innogreen.fi',
  },
  {
    name: 'Raisa Koiranen',
    imgSrc: raisaKImgSrc,
    department: 'HUOLTO (TAMPERE), MYYNTILASKUT JA SOPIMUKSET',
    department_en: 'MAINTENANCE (TAMPERE)',
    email: 'raisa.koiranen@innogreen.fi',
  },
  {
    name: 'Teijo Tiainen',
    imgSrc: teijoTImgSrc,
    department: 'VARASTOPÄÄLLIKKÖ',
    department_en: 'WAREHOUSE MANAGER',
    email: 'teijo.tiainen@innogreen.fi',
    phoneNumber: '050 443 5561',
  },
  {
    name: 'Laura Tubal',
    imgSrc: lauraTImgSrc,
    department: 'OSTOT JA TILAUKSET',
    department_en: 'PURCHASES AND ORDERS',
    email: 'laura.tubal@innogreen.fi',
    phoneNumber: '050 472 2356',
  },
  {
    name: 'Pia Paajanen',
    imgSrc: piaPImgSrc,
    department: 'HENKILÖSTÖPÄÄLLIKKÖ',
    department_en: 'HUMAN RESOURCES MANAGER',
    email: 'pia.paajanen@innogreen.fi',
  },
  {
    name: 'Oula Harjula',
    imgSrc: oulaHImgSrc,
    department: 'KEHITYSJOHTAJA',
    department_en: 'DEVELOPMENT DIRECTOR',
    email: 'oula@innogreen.fi',
  },
  {
    name: 'Mikko Sonninen',
    imgSrc: mikkoSImgSrc,
    department: 'TOIMITUSJOHTAJA, TUOTEKEHITYS',
    department_en: 'MANAGING DIRECTOR, PRODUCT DEVELOPMENT',
    email: 'mikko@innogreen.fi',
  },
  {
    name: 'Jaakko Pesonen',
    imgSrc: jaakkoPImgSrc,
    department: 'TALOUSJOHTAJA',
    department_en: 'CFO',
    email: 'jaakko@innogreen.fi',
  },
  {
    name: 'Nora Tåg',
    imgSrc: noraTImgSrc,
    department: 'VIESTINTÄ',
    department_en: 'COMMUNICATIONS',
    email: 'nora.tag@innogreen.fi',
  },
]

const ContactInput = (props) => {
  const {
    id,
    onContentChange,
    component = null
  } = props

  const [contact, setContact] = useState({
    previewUrl: null,
    image: null,
    name: '',
    title: '',
    title_en: '',
    email: '',
    phone: '',
    text: '',
    text_en: '',
    img_name: '',
  })
  const titleFIRef = useRef(null)
  const titleENRef = useRef(null)
  const textFIRef = useRef(null)
  const textENRef = useRef(null)
  const [selectedLanguage, setSelectedLanguage] = useState('FI')

  useEffect(() => {
    (async () => {
      if (component != null) {
        const employee = employees.find(employee => employee.email === component.email)
        if (employee) {
          const response = await fetch(employee.imgSrc) // Fetch the image
          const blob = await response.blob() // Convert the image to a blob
          const fileName = unidecode(employee.name).replace(/\s/g, '-').toLowerCase() 
          const file = new File([blob], `${fileName}.jpg`, { type: blob.type }) // Create a file from the blob
          const previewUrl = URL.createObjectURL(file)
    
          setContact((prevContact) => {
            const updatedContact = {
              ...prevContact,
              previewUrl,
              image: file,
              name: employee.name,
              title: employee.department,
              title_en: employee.department_en,
              email: employee.email,
              phone: employee.phoneNumber,
              text: '',
              text_en: '',
              img_name: ''
            }
            onContentChange(updatedContact, id)
            return updatedContact
          })
        } else {
          setContact((prevContact) => {
            const updatedContact = {
              ...prevContact,
              previewUrl: component.imgSrc,
              name: component.name ?? '',
              title: component.title ?? '',
              title_en: component.title_en ?? '',
              email: component.email ?? '',
              phone: component.phone ?? '',
              text: component.text ?? '',
              text_en: component.text_en ?? '',
              img_name: component.imgName ?? ''
            }
            onContentChange(updatedContact, id)
            return updatedContact
          })
        }
      }
    })()
  }, [component, id]);

  const handleChange = async (event) => {
    const selectedName = event.target.value
    const employee = employees.find((emp) => emp.name === selectedName)
    if (!employee) {
      // If the selected employee is not found, reset the contact eg.
      // when selecting the -- Select an Employee -- option after selecting an employee
      if (contact) {
        setContact({})
        onContentChange({
          previewUrl: null,
          image: null,
          name: '',
          title: '',
          title_en: '',
          email: '',
          phone: '',
          text: '',
          text_en: '',
        }, id)
        return
      }
    }

    const response = await fetch(employee.imgSrc) // Fetch the image
    const blob = await response.blob() // Convert the image to a blob
    const fileName = unidecode(employee.name).replace(/\s/g, '-').toLowerCase() 
    const file = new File([blob], `${fileName}.jpg`, { type: blob.type }) // Create a file from the blob
    const previewUrl = URL.createObjectURL(file)

    setContact((prevContact) => {
      const updatedContact = {
        ...prevContact,
        previewUrl,
        image: file,
        name: employee.name,
        title: employee.department,
        title_en: employee.department_en,
        email: employee.email,
        phone: employee.phoneNumber,
        text: '',
        text_en: '',
        img_name: '',
      }
      onContentChange(updatedContact, id)
      return updatedContact
    })
  }

  const handleContentChange = (key, value, language) => {
    setContact((prevContact) => {
      if (language === 'EN') {
        var updatedContact = { ...prevContact, [`${key}_en`]: value }
      } else {
        var updatedContact = { ...prevContact, [key]: value }
      }
      onContentChange(updatedContact, id)
      return updatedContact
    })
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      // Convert the file to a data URL
      const previewUrl = URL.createObjectURL(file)
      setContact((prevContact) => {
        const updatedContact = { ...prevContact, previewUrl: previewUrl, image: file }
        onContentChange(updatedContact, id)
        return updatedContact
      })
    }
  }

  const handlePlaceholderClick = () => {
    document.getElementById('profileImageInput').click()
  }

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language)
    if (language === 'FI') {
      titleENRef.current.style.display = 'none'
      titleFIRef.current.style.display = 'block'
      textENRef.current.style.display = 'none'
      textFIRef.current.style.display = 'block'
    }
    if (language === 'EN') {
      titleFIRef.current.style.display = 'none'
      titleENRef.current.style.display = 'block'
      textFIRef.current.style.display = 'none'
      textENRef.current.style.display = 'block'
    }
  }

  return (
    <>
      <div className='customize-blog-header-language-wrapper'>
        <div className='customize-blog-header-language-selector'>
          <div 
            className={`customize-blog-header-language ${selectedLanguage === 'FI' ? 'active' : ''}`}
            onClick={() => handleSelectLanguage('FI')}
          >
            FI
          </div>
          <div 
            className={`customize-blog-header-language ${selectedLanguage === 'EN' ? 'active' : ''}`}
            onClick={() => handleSelectLanguage('EN')}
          >
            EN
          </div>
        </div>
      </div>
      <div className='contact-card-input-wrapper'>
        <div className='contact-card-input-container'>
          <div className='contact-card-input-left-container'>
              <select
                className='contact-card-input-left-select'
                id="employee-select"
                onChange={handleChange}
                value={contact.name}
              >
                <option value="">-- Select an Employee --</option>
                {employees.map((employee) => (
                  <option key={employee.name} value={employee.name}>
                    {employee.name}
                  </option>
                ))}
              </select>
          
            <div className='contact-card-input-image-wrapper'>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                id="profileImageInput"
                className="contact-card-input-image-input"
              />
              
              {contact && contact?.previewUrl ? (
                <img onClick={handlePlaceholderClick} src={contact.previewUrl} alt="Profile" className="contact-card-input-image-preview" />
              ) : (
                <div
                  className="contact-card-input-image-placeholder"
                  onClick={handlePlaceholderClick}
                >
                  Add Image
                </div>
              )}
            </div>
          </div>

          <div className='contact-card-input-text-container'>
            <CustomTextInput
              type='contactName'
              onContentChange={handleContentChange}
              label='Full Name'
              value={contact.name}
            />
            <div ref={titleFIRef}>
              <CustomTextInput
                type='contactTitle'
                onContentChange={handleContentChange}
                label='Title'
                value={contact.title}
              />
            </div>
            <div ref={titleENRef} className='en'>
              <CustomTextInput
                type='contactTitle'
                onContentChange={handleContentChange}
                label='Title'
                value={contact.title_en}
                language='EN'
              />
            </div>
            <div className='contact-card-input-text-wrapper'>
              <CustomTextInput
                type='contactEmail'
                onContentChange={handleContentChange}
                label='Email'
                value={contact.email}
              />
              <CustomTextInput
                type='contactPhone'
                onContentChange={handleContentChange}
                label='Phone'
                value={contact.phone}
              />
            </div>
            <div ref={textFIRef}>
              <CustomTextInput
                type='contactText'
                onContentChange={handleContentChange}
                label='Text'
                value={contact.text}
              />
            </div>
            <div className='en' ref={textENRef}>
              <CustomTextInput
                type='contactText'
                onContentChange={handleContentChange}
                label='Text'
                value={contact.text_en}
                language='EN'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactInput