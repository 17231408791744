const project = {
  backToMyAccount: 'Back to my account',
  dateCreated: 'Date created',
  dateModified: 'Date Modified',
  status: 'Status',
  editProjectInformation: 'Edit project information',
  duplicateProject: 'Duplicate Project',
  deleteProject: 'Delete project',
  open: 'Open',
  pending: 'Pending',
  closed: 'Closed',
  inquirySent: 'Inquiry sent',
  cancelled: 'Cancelled',
  unknown: 'Unknown',
  openProductSummary: 'Open product summary',
  printProjectSummary: 'You can print the project on summary page',
  view: 'View',
  dateAdded: 'Date added',
  productName: 'Product name',
  allSpaces: 'All spaces',
  defaultSpace: 'Default space',
  editSpaces: 'Edit spaces',
  sort: 'Sort',
  product: 'Product',
  quantity: 'Quantity',
  addNewProduct: 'Add a new product',
  noProductFound: 'No product found',
  sendInquiry: 'Send inquiry',
  total: 'Total',
  products: 'Products',
  editProject: 'Edit project',
  projectName: 'Project name',
  description: 'Description',
  confirm: 'Confirm',
  cancel: 'Cancel',
  duplicatedProjectName: 'Duplicated project name',
  deleteConfirmation: 'Delete Confirmation',
  confirmDelete: 'Confirm deleting project',
  confirmDeleteProduct:'Confirm deleting product' ,
  delete: 'Delete',
  newInquiry: 'New inquiry',
  yourName: 'Name',
  phoneNumber: 'Phone',
  message: 'Message',
  city: 'City',
  role: 'Role',
  company: 'Company',
  email: 'Email',
  send: 'Send',
  createNewSpace: 'Create new space',
  nameOfSpace: 'Name of the space',
  add: 'Add',
  rename: 'Rename',
  editSpaceName: 'Edit space name',
  ok: 'Ok',
  deleteSpace: 'Delete space',
  confirmDeleteSpace: 'Confirm deleting space',
  addToProject: 'Add to project',
  addToNewProject: 'Add to new project',
  searchForProject: 'Search for a project',
  yourSelection: 'Your selection',
  productOverview: 'Product overview',
  editYourSelection: 'Edit your selection',
  closeEditView: 'Close edit view',
  quantity: 'Quantity',
  removeProduct: 'Remove product',
  createNewVersion: 'Create a new version',
  copyToAnotherProject: 'Copy to another project',
  copyToAnotherSpace: 'Copy to another space',
  backToProject: 'Back to project',
  configurateThisProduct: 'Configurate this product',
  printProject: 'Print project',
  inquirySentSuccess: `Thank you, form received. We will contact you as soon as possible!`,
  offerInquired: 'Offer inquired',
  genericInquiryError: 'Failed to send inquiry, please try again',
  assignToSpace: 'Assign to a space',
  chooseSpace: '-You can assign a product to a speciﬁc space or choose default space...',
  addToOtherProject: 'Add to other project',
  productAddedTo: 'Product added to',
  selectedSpace: 'Selected space',
  goToProjectText: '-Press "Go to project" to make changes, print, or send us a project request.',
  continueText1: '-Press "Continue" to close this dialog.',
  continueText2: '-Press "Continue" to add more products to your project.',
  continue: 'Continue',
  goToProject: 'Go to project',
  step: 'Step',
  backtoStep: 'Back to step',
  editProjectSuccessMessage: 'Edited project!',
  genericEditProjectError: 'Failed to edit project, please try again!',
  close: 'Close',
  genericDuplicateProjectError: 'Failed to duplicate project, please try again!',
  duplicateProjectSuccessMessage: 'Duplicated project!',
  genericDeleteProjectError: 'Failed to delete project, please try again!',
  genericAddSpaceError: 'Failed to create new space, please try again!',
  genericDeleteSpaceError: 'Failed to delete space, please try again!',
  genericRenameSpaceError: 'Failed to rename space, please try again!',
  genericAddProjectError: 'Failed to create new project, please try again!',
  genericDeleteProductError: 'Failed to delete product, please try again!',
}

export default project