import './styles.scss'

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import InquiryAddNoteModal from './InquiryAddNoteModal'

import moment from 'moment'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  CircularProgress,
  Toolbar,
  Typography,
} from "@mui/material"

import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

import { fetch_inquiry_notes } from '../../../../store/asyncThunk'

const InquiryNotes = (props) => {
  const {

  } = props
  const inquiry = useSelector((state) => state.management.selected_inquiry)
  const notes = useSelector((state) => state.management.inquiry_notes)
  const [isLoading, setIsLoading] = useState(false)
  const [stateHistory, setStateHistory] = useState([])
  const [tableHeight, setTableHeight] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('priority')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const dispatch = useDispatch()
  const [showInquiryAddNoteModal, setShowInquiryAddNoteModal] = useState(false)

  const toggleShowInquiryAddNoteModal = () => {
    setShowInquiryAddNoteModal(!showInquiryAddNoteModal)
  }

  useEffect(() => {
    if (inquiry) {
      (async () => {
        setIsLoading(true)
        await dispatch(fetch_inquiry_notes(inquiry.id))
        .then((res) => {
          setStateHistory(res.payload)
        })
        .catch((err) => {
          console.log(err)
        })
        setIsLoading(false)
      })()
    }
  }, [inquiry])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const get_status = (status) => {
    switch (status) {
      case 1:
        return 'Open'
      case 2:
        return 'In Progress'
      case 3:
        return 'Offer Sent'
      case 4:
        return 'Offer Accepted'
      case 5:
        return 'Offer Rejected'
      case 6:
        return 'Guide Sent'
      case 7:
        return 'Closed'
      default:
        return 'Unknown'
    }
  }

  const format_date = (date) => {
    if (date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    }
  }

  return (
    <>
      <Paper sx={{ width: '100%', borderRadius: 0, marginBottom: '1rem' }}>
        <Toolbar>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Notes
          </Typography>
          <NoteAddOutlinedIcon
            sx={{ cursor: 'pointer' }}
            onClick={toggleShowInquiryAddNoteModal}
          />
        </Toolbar>
        <TableContainer
          elevation={0}
          sx={{ height: "100%", overflow: "auto" }}
        >
          <Table
            size='small'

          >
            <TableHead>
              <TableRow
                sx={{ backgroundColor: '#f5f5f5' }}
              >
                <TableCell
                  sx={{ width: '15%' }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{ width: '15%'}}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ width: '70%'}}
                >
                  Message
                </TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ?
                (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <div style={{ height: rowsPerPage * 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  notes.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell >
                        {format_date(item.date_created)}
                      </TableCell>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'pre-wrap' }}
                      >
                        {item.message}
                      </TableCell>
                    </TableRow>
                  ))
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={notes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <InquiryAddNoteModal
      inquiry={inquiry}
        showing={showInquiryAddNoteModal}
        closeModal={toggleShowInquiryAddNoteModal}
      />
    </>
  )
}

export default InquiryNotes