const contact = {
  headerTitle: 'Ota yhteyttä',
  inquirySentSuccess: `Kiitos, lomake vastaanotettu. Olemme yhteydessä sinuun mahdollisimman pian!`,
  cards: [
    {
      title: 'Vihersisustus',
      id: 'Vihersisustus',
      phone: '010 583 3595',
      email: 'myynti@innogreen.fi',
      buttonText: 'Näytä henkilöt',
    },
    {
      title: 'Ulkoalueet',
      id: 'Ulkoalueet',
      phone: '010 583 3598',
      email: 'piha@innogreen.fi',
      buttonText: 'Näytä henkilöt',
    },
    {
      title: 'Asennus ja huolto',
      id: 'Huolto',
      phone: '010 583 3590',
      email: 'huolto@innogreen.fi',
      buttonText: 'Näytä henkilöt',
    },
    {
      title: 'Hallinto',
      id: 'Hallinto',
      phone: '010 583 3594',
      email: 'laskut@innogreen.fi',
      buttonText: 'Näytä henkilöt',
    },
  ],
  invoiceCards: [
    {
      title: 'Green House Effect Oy',
      id: 'Placeholder',
      type: 'companyInfo',
      address: ['Yrityspiha 6, 00390 Helsinki', 'Mäntyhaantie 4 A 12, 33800 Tampere', 'Itäkaari 28, 20750 Turku (Halli 58)'],
      identityText: 'Y-tunnus',
      identityCode: '2413133-9',
    },
    {
      title: 'Laskutustiedot',
      id: 'Laskutustiedot',
      type: 'invoiceInfo',
      invoiceText: 'Pyydämme toimittamaan kaikki meille osoitetut laskut verkkolaskuina tai sähköpostilla.',
      eInvoiceAddressText: 'Verkkolaskuosoite',
      operatorText: 'Operaattori',
      pdfInvoiceText: 'PDF-laskut',
      eInvoiceAddress: '003724131339',
      operator: 'Maventa (003721291126), tai DABAFIHH',
      pdfInvoice: '24131339@scan.netvisor.fi',
      pdf: 'Laskutusohjeet pdf-tiedostossa:',
      link:'laskutustiedot_2017.pdf',
      downloadText: 'Lataa tästä',
    },
  ],
  inquiryTitle: 'Jätä yhteyde\u00ADnottopyyntö, niin otamme sinuun yhteyttä!',
  email: 'Sähköposti',
  phone: 'Puhelin',
  company: 'Yritys',
  city: 'Paikkakunta',
  message: 'Viesti',
  name: 'Nimi',
  sendBtn: 'LÄHETÄ LOMAKE',
  contactInfo: [
    {
      title: 'Viher\u00ADsisustus',
      refId: 'Vihersisustus',
      email: 'myynti@innogreen.fi',
      phoneNumber: '010 583 3595',
      employee: [
        {
          name: 'Kirsi Hänninen',
          id: 'kirsih',
          department: ['VIHERSISUSTUS'],
          email: 'kirsi.hanninen@innogreen.fi',
          phoneNumber: '040 451 6565',
        },
        {
          name: 'Liivika Korkalo',
          id: 'liivikak',
          department: ['VIHERSUUNNITTELU', 'VIHERSISUSTUS'],
          email: 'liivika.korkalo@innogreen.fi',
          phoneNumber: '040 838 3223',
        },
        {
          name: 'Anne Ollila',
          id: 'anneo',
          department: ['VIHERSISUSTUS', 'TAMPERE JA MUU SUOMI'],
          email: 'anne.ollila@innogreen.fi',
          phoneNumber: '050 569 5262',
        },
        {
          name: 'Katja Kankkunen',
          id: 'katjak',
          department: ['VIHERSISUSTUS', 'ITÄ-SUOMI'],
          email: 'katja.kankkunen@innogreen.fi',
          phoneNumber: '050 472 2986',
        },
        {
          name: 'Leena Kinnunen',
          id: 'leenak',
          department: ['VIHERSUUNNITTELU', 'VIHERSISUSTUS'],
        },
      ]
    },
    {
      title: 'Ulko\u00ADalueet',
      refId: 'Ulkoalueet',
      email: 'piha@innogreen.fi',
      phoneNumber: '010 583 3598',
      employee: [
        {
          name: 'Miia Kujala',
          id: 'miiak',
          department: ['MYYNTI'],
          email: 'miia.kujala@innogreen.fi',
          phoneNumber: '050 401 7891',
        },
        {
          name: 'Johanna Murto',
          id: 'johannam',
          department: ['MYYNTI & HALLINTO'],
          email: 'johanna.murto@innogreen.fi',
          phoneNumber: '050 431 5422',
        },
        {
          name: 'Kristina Hulkkonen',
          id: 'kristinah',
          department: ['HUOLTOPÄÄLLIKKÖ'],
          email: 'kristina.hulkkonen@innogreen.fi',
          phoneNumber: '050 463 1657',
        },
      ]
    },
    {
      title: 'Asen\u00ADnus ja huolto',
      refId: 'Huolto',
      phoneNumber: '010 583 3590',
      email: 'huolto@innogreen.fi',
      employee: [
        {
          name: 'Sari Räty',
          id: 'sarir',
          department: ['HUOLTOPÄÄLLIKKÖ (HELSINKI JA TURKU)'],
          email: 'sari.raty@innogreen.fi',
        },
        {
          name: 'Raisa Koiranen',
          id: 'raisak',
          department: ['HUOLTO (TAMPERE)'],
          email: 'raisa.koiranen@innogreen.fi',
        },
        {
          name: 'Teijo Tiainen',
          id: 'teijot',
          department: ['VARASTOPÄÄLLIKKÖ'],
          email: 'teijo.tiainen@innogreen.fi',
          phoneNumber: '050 443 5561',
        },
        {
          name: 'Laura Tubal',
          id: 'laurat',
          department: ['OSTOT JA TILAUKSET'],
          email: 'laura.tubal@innogreen.fi',
          phoneNumber: '050 472 2356',
        },
      ]
    },
    {
      title: 'Hallinto',
      refId: 'Hallinto',
      email: 'laskut@innogreen.fi',
      phoneNumber: '010 583 3594',
      employee: [
        {
          name: 'Raisa Koiranen',
          id: 'raisak',
          department: ['MYYNTILASKUT JA SOPIMUKSET'],
          email: 'raisa.koiranen@innogreen.fi',
        },
        {
          name: 'Pia Paajanen',
          id: 'piap',
          department: ['HENKILÖSTÖPÄÄLLIKKÖ'],
          email: 'pia.paajanen@innogreen.fi',
        },
        {
          name: 'Oula Harjula',
          id: 'oulah',
          department: ['KEHITYSJOHTAJA'],
          email: 'oula@innogreen.fi',
        },
        {
          name: 'Mikko Sonninen',
          id: 'mikkos',
          department: ['TOIMITUSJOHTAJA', 'TUOTEKEHITYS'],
          email: 'mikko@innogreen.fi',
        },
        {
          name: 'Jaakko Pesonen',
          id: 'jaakkop',
          department: ['TALOUSJOHTAJA'],
          email: 'jaakko@innogreen.fi',
        },
        {
          name: 'Nora Tåg',
          id: 'norat',
          department: ['VIESTINTÄ'],
          email: 'nora.tag@innogreen.fi',
        },
      ]
    },
  ],
  genericContactRequestError: 'Failed to request message, please try again!',
}

export default contact