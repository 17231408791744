import './styles.scss'

import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'

import QuillEditor from '../../../../../components/QuillEditor'
import CustomTextInput from '../../../../../components/common/CustomTextInput'
import CustomHeaderImageInput from '../../../../../components/common/CustomHeaderImageInput'
import DynamicComponentManager from '../../../../../components/common/DynamicComponentManager'
import CustomSelectInput from '../../../../..//components/common/CustomSelectInput'
import CustomButton from '../../../../../components/common/CustomButton'
import DeleteContentModal from '../../../../../components/DeleteContentModal'

import {
  IconButton,
  Paper,
  Popper,
  MenuItem,
  ClickAwayListener,
} from "@mui/material"
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import { setGlobalLoading } from '../../../../../store/uiSlice'

import { add_blog, fetch_blog_groups, fetch_management_blog, edit_blog } from '../../../../../store/asyncThunk'

const dropdownOptions = [
  {
    id: 1,
    name: 'SAVE AND PUBLISH',
    saveAs: 'publish'
  },
  {
    id: 2,
    name: 'SAVE AS DRAFT',
    saveAs: 'draft'
  }
]

const BlogEditor = (props) => {
  const {
    handleSetSelectedTab,
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const { year, month, slug } = useParams()
  const [components, setComponents] = useState([])
  const [content, setContent]  = useState(
    {
      date: '',
      text: '',
      title: '',
      text_en: '',
      title_en: '',
      slug: '',
      imgName:'',
      imgSrc: null, // For header/Card image
      blogGroup: [],
      body: [] // For components
    }
  )
  const [searchParams] = useSearchParams();
  const blog_id = searchParams.get('blog');
  const blogGroup = useSelector((state) => state.blog.blogGroup)
  const selected_blog = useSelector((state) => state.management.selected_blog)
  const [errorList, setErrorList] = useState([])
  const [requested_success, setRequestedSuccess] = useState(false)
  const [disableDelete, setDisableDelete] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const global_loading = useSelector((state) => state.ui.global_loading)
  const { t, i18n } = useTranslation()
  const current_user = useSelector((state) => state.user.current_user)
  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('FI')
  const quillFIRef = useRef(null)
  const quillENRef = useRef(null)
  const titleFIRef = useRef(null)
  const titleENRef = useRef(null)
  const anchorRef = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleClose = () => {
    setOpenDropdown(false)
  }

  const handleClick = () => {
    setOpenDropdown(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0)
      // setHasScrolled(true)
    }, 300) // Small delay ensures it runs after rendering
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!current_user) {
      if (i18n.language === 'FI')
        navigate('/kirjaudu_sisaan')
      else if (i18n.language === 'EN')
        navigate('/login')
    } else if (current_user && ![1, 5].includes(current_user.role)) {
      navigate('/account');
    }
  }, [current_user, navigate]);

  useEffect(() => {
    (async () => {
      dispatch(setGlobalLoading(true))
      dispatch(fetch_blog_groups())
      if (blog_id) {
        await dispatch(fetch_management_blog(blog_id))
        .then(res => {
          if (!res.error) {
            const { blog } = res.payload
            setContent(blog)
            setComponents(blog.body.map(({ type, ...rest }) => ({
              identifier: rest.index,
              type: rest.component,
              ...rest
            })))
          } else {
            setErrorList([t('error.noAccess')])
          }
        })
      }
      dispatch(setGlobalLoading(false))
    })()
  }, [blog_id])

  const toggleDeleteContentModal = () => {
    setShowDeleteContentModal(!showDeleteContentModal)
  }

  const handleContentChange = useCallback((key, value, action = 'update', language='FI') => {
    if (language === 'EN') {
      setContent((prevContent) => {
        return { ...prevContent, [`${key}_en`]: value }
      })
    } else {
      setContent((prevContent) => {
        return { ...prevContent, [key]: value }
      })
    }
  }, [])

  const handleContentChangeDynamic = (identifier, object) => {
    setRequestedSuccess(false)
    setContent((prevContent) => {
      const updatedBody = prevContent.body.map((item) => {
        // Check if the item has the same identifier
        if (item.identifier === identifier) {
          // Overwrite the item with the new object data
          return { ...item, ...object }
        }
        return item
      })
  
      // If the identifier was not found, add a new entry to the body
      const bodyWithNewItem = prevContent.body.some((item) => item.identifier === identifier)
        ? updatedBody
        : [...prevContent.body, { identifier, ...object }]

      // Recalculate positions
      const bodyWithPositions = bodyWithNewItem
      .map((item, index) => ({ ...item, index: index + 1 }))
  
      return {
        ...prevContent,
        body: bodyWithPositions,
      }
    })
  }

  const handleAddComponent = (componentType) => {
    setRequestedSuccess(false)
    setComponents((prevComponents) => {
      // Find the highest Identifier currently in the array, and add 1 for the new component
      const newIdentifier = prevComponents.length > 0 
        ? Math.max(...prevComponents.map(component => component.identifier)) + 1
        : 1 // If no components, start with Identifier 1
  
      return [
        ...prevComponents,
        { identifier: newIdentifier, type: componentType },
      ]
    })
  }

  const handleDeleteComponent = (identifier) => {
    setRequestedSuccess(false)
    setComponents((prevComponents) => 
      prevComponents.filter((component) => component.identifier !== identifier) // Remove from components
    )
  
    setContent((prevContent) => {
      const updatedBody = prevContent.body
        .filter((component) => component.identifier !== identifier) // Remove from body by identifier
        .map((item, index) => ({ ...item, index: index + 1 })) // Recalculate positions
  
      return {
        ...prevContent,
        body: updatedBody,
      }
    })
  }

  const disableUploadButton = () => {
    if (content.title === '') return true
    if (content.imgSrc === null) return true
  }

  const disableSaveButton = () => {
    if (content.title === '') return true
    if (content.imgSrc === null) return true
  }

  function base64ToFile(base64String, filename) {
    // Check if the input is already a File object
    if (base64String instanceof File) {
      return base64String
    }

    const [_, base64Data] = base64String.split(',')
    const binaryData = atob(base64Data)
    const uint8Array = new Uint8Array(binaryData.length)

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i)
    }
  
    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: 'image/png' })
  
    // Create a File from the Blob
    const file = new File([blob], filename, { type: 'image/png' })
  
    return file
  }

  const handleUpload = async (saveAs) => {
    setErrorList([])
    setRequestedSuccess(false)
    const formData = new FormData()

    if (content.imgSrc) {
      const image = base64ToFile(content.imgSrc, content.imgName)
      formData.append(`header-img`, image)
    }

    const header = {
      slug: content.slug,
      title: content.title,
      text: content.text,
      title_en: content.title_en,
      text_en: content.text_en,
      date: content.date,
      blogGroup: content.blogGroup,
      saveAs: saveAs
    }
    formData.append('header', JSON.stringify(header))

    const body = []
    if (content.body && content.body.length > 0) {
      content.body.forEach((item, index) => {
        if (item.component === 'CustomImageInput') {
          body.push({
            component: item.component,
            id: item.id,
            index: item.index,
            identifier: index,
            images:[]
          })
          item?.images?.forEach((bodyImage, idx) => {
            if (bodyImage.img_name) {
              body[index].images.push({
                alt: bodyImage.alt,
                title: bodyImage.title,
                text: bodyImage.text,
                title_en: bodyImage.title_en,
                text_en: bodyImage.text_en,
                img_name: bodyImage?.img_name
              })
              var imageFile = base64ToFile(bodyImage.previewUrl, bodyImage.img_name)
              formData.append(`${index+1}-${idx+1}`, imageFile)
            } else {
              body[index].images.push({
                alt: bodyImage.alt,
                title: bodyImage.title,
                text: bodyImage.text,
                title_en: bodyImage.title_en,
                text_en: bodyImage.text_en,
              })
              formData.append(`${index+1}-${idx+1}`, bodyImage.file)
            }
          })
        }
        else if (item.component === 'ContactInput') {
          body.push({
            component: item.component,
            id: item.id,
            index: item.index,
            identifier: index,
            name: item.name,
            email: item.email,
            phone: item.phone,
            text: item.text,
            title: item.title,
            text_en: item.text_en,
            title_en: item.title_en,
          })
          if (item.img_name) {
            var imageFile = base64ToFile(item.previewUrl, item.img_name)
            formData.append(`${index+1}-profile`, imageFile)
          } else {
            formData.append(`${index+1}-profile`, item.image)
          }
        }
        else if (item.component === 'InfoBoxInput') {
          body.push({
            component: item.component,
            id: item.id,
            index: item.index,
            identifier: index,
            title: item.title,
            text: item.text,
            btnText: item.btnText,
            title_en: item.title_en,
            text_en: item.text_en,
            btnText_en: item.btnText_en,
            btnLink: item.btnLink,
            bgColor: item.bgColor,
          })
          if (item.imgName) {
            var imageFile = base64ToFile(item.imgSrc, item.imgName)
            formData.append(`${index+1}-img`, imageFile)
          } else {
            formData.append(`${index+1}-img`, item.image)
          }
        }
        else if (item.component === 'CustomTextInput') {
          body.push({
            component: item.component,
            id: item.id,
            index: item.index,
            identifier: index,
            text: item.text,
            text_en: item.text_en
          })
        }
        else if (item.component === 'YoutubeVideo') {
          body.push({
            component: item.component,
            id: item.id,
            index: item.index,
            identifier: index,
            videoUrl: item.videoUrl,
          })
        }
      })
    }

    formData.append('body', JSON.stringify(body))

    if (selected_blog.year && selected_blog.month && selected_blog.slug) {
      // Update existing Blog
      setIsLoading(true)
      await dispatch(
        edit_blog({payload: formData, year: selected_blog.year, month: selected_blog.month, slug: selected_blog.slug})
      ).then((res) => {
        if (!res.error) {
          setErrorList([])
          // Check if slug has changed
          if (res.payload.blog.slug !== selected_blog.slug || res.payload.blog.year !== selected_blog.year || res.payload.blog.month !== selected_blog.month) {
            navigate(`${res.payload.blog.url}/editor`, { replace: true });
          }
          setRequestedSuccess(true)
        } else {
          setErrorList([t('error.noAccess')])
        }
      })
      setIsLoading(false)
    } else {
      // Add new blog
      setIsLoading(true)
      await dispatch(
        add_blog(formData)
      ).then((res) => {
        if (!res.error) {
          setErrorList([])
          setRequestedSuccess(true)
        } else {
          if (res.payload?.data?.error_code) {
            setErrorList([t(res.payload.data.error_code)])
          } else if (res.payload?.data?.message) {
            setErrorList([t(res.payload.data.message)])
          } else {
            setErrorList([t('error.noAccess')])
          }
        }
      })
      setIsLoading(false)
    }
  }

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language)
    if (language === 'FI') {
      quillENRef.current.style.display = 'none'
      quillFIRef.current.style.display = 'block'
      titleENRef.current.style.display = 'none'
      titleFIRef.current.style.display = 'block'
    }
    if (language === 'EN') {
      quillFIRef.current.style.display = 'none'
      quillENRef.current.style.display = 'block'
      titleFIRef.current.style.display = 'none'
      titleENRef.current.style.display = 'block'
    }
  }

  const handleCancelClick = () => {
    handleSetSelectedTab('Blog')
  }

  if (global_loading) {
    return (
        <div className='customize-blog-container'>
          <div className='customize-blog-body-container center'>
            <ClipLoader color='#1a73e8' />
          </div>
        </div>
    )
  }

  return (
    <>
      { isLoading ?
        (
          <div className='customize-blog-loading'>
            <ClipLoader color='#1a73e8' />
          </div>
        ) : (
          <></>
        )
      }
      { selected_blog && (
        <>
          <div className='customize-blog-container'>
            <div className='customize-blog-body-container'>
              <div className='customize-blog-header-container'>
                <div className='customize-blog-header-button-wrapper'>
                  { selected_blog.year && selected_blog.month && selected_blog.slug ?
                    (
                      <>
                        <CustomButton
                          button_text='SAVE'
                          onClickHandler={() => handleUpload()}
                          disabled={disableUploadButton()}
                          yellow
                        />
                        <CustomButton
                          button_text='DELETE'
                          onClickHandler={() => toggleDeleteContentModal()}
                          disabled={disableDelete}
                          danger
                        />
                      </>
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                        <div ref={anchorRef} className={`customize-blog-header-save-button-container ${disableSaveButton() ? 'disabled' : ''}`}>
                          <div
                            className='customize-blog-header-save-button'
                            onClick={() => handleUpload('draft')}
                          >
                            SAVE AS DRAFT
                          </div>
                          <div>
                          |
                          </div>
                          <div>
                            <IconButton
                              onClick={handleClick}
                              disableRipple={true}
                              sx={{
                                padding: '0',
                              }}
                            >
                              <KeyboardArrowDownOutlinedIcon
                                sx={
                                  disableSaveButton() ? { color: 'grey' } : { color: 'black' }
                                }
                              />
                                <Popper
                                  open={openDropdown}
                                  anchorEl={anchorRef.current}
                                  placement="bottom"
                                >
                                  <ClickAwayListener
                                    onClickAway={handleClose}
                                  >
                                    <Paper sx={{ p: 1 }}>
                                      { dropdownOptions.map((option) => (
                                          <MenuItem
                                            key={option.id}
                                            disableRipple
                                            onClick={() => handleUpload(option.saveAs)}
                                            sx={{ 
                                              '&.Mui-selected': {
                                                backgroundColor: '#D6ACFC',
                                              },
                                            }}
                                          >
                                            {option.name}
                                          </MenuItem>
                                        ))
                                    }
                                    </Paper>
                                  </ClickAwayListener>
                                </Popper>
                            </IconButton>
                          </div>
                        </div>
                        <CustomButton
                          button_text='Cancel'
                          onClickHandler={() => handleCancelClick()}
                          danger
                        />
                      </div>
                    )
                  }
                </div>
                {errorList.length > 0 && (
                  <>
                      {errorList.map((error) => {
                        return (
                          <div className='customize-blog-header-button-error' key={error}>
                            - {error}
                          </div>
                        )
                      })}
                  </>
                )}
                {requested_success && (
                  <div className='customize-blog-header-button-success'>
                  Saved!
                </div>
                )}
                {deleteSuccess && (
                  <div className='customize-blog-header-button-success'>
                    Deleted! Navigating to Blogs...
                  </div>
                )}
                <div className='customize-blog-header-language-wrapper'>
                  <div className='customize-blog-header-language-selector'>
                    <div 
                      className={`customize-blog-header-language ${selectedLanguage === 'FI' ? 'active' : ''}`}
                      onClick={() => handleSelectLanguage('FI')}
                    >
                      FI
                    </div>
                    <div 
                      className={`customize-blog-header-language ${selectedLanguage === 'EN' ? 'active' : ''}`}
                      onClick={() => handleSelectLanguage('EN')}
                    >
                      EN
                    </div>
                  </div>
                </div>
                <div className='customize-blog-header-title-container'>
                    <CustomTextInput
                      label='URL'
                      type='slug'
                      page='blog'
                      value={content.slug}
                      onContentChange={handleContentChange}
                    />
                    <CustomTextInput
                      label='Date'
                      type='date'
                      page='blog'
                      value={content.date}
                      onContentChange={handleContentChange}
                    />
                    <div ref={titleFIRef}>
                      <CustomTextInput
                        id={1}
                        label='Blog Title'
                        type='headerTitle'
                        page='blog'
                        inputWidth={2}
                        value={content.title}
                        onContentChange={handleContentChange}
                      />
                    </div>
                    <div
                      className='customize-blog-header-title-en'
                      ref={titleENRef}
                    >
                      <CustomTextInput
                        id={1}
                        label='Blog Title'
                        type='headerTitle'
                        page='blog'
                        inputWidth={2}
                        value={content.title_en ?? ''}
                        onContentChange={handleContentChange}
                        language='EN'
                      />
                    </div>

                    <CustomSelectInput
                      options={blogGroup}
                      onContentChange={handleContentChange}
                      selected_options={content.blogGroup}
                      label='Blog Tags'
                      type='blogGroup'
                      page='blog'
                    />
                </div>
                <div className='customize-blog-header-image-wrapper'>
                  <CustomHeaderImageInput
                    label='Add Header Image'
                    id={1}
                    imageSrc={content.imgSrc}
                    onContentChange={handleContentChange}
                  />
                </div>
              </div>

              
              <div className='customize-blog-card-text-container'>
                <div className='customize-blog-card-text'>Card Text</div>
                  <div ref={quillFIRef}>
                    <QuillEditor
                      id={998}
                      initialContent={content.text}
                      onContentChange={handleContentChange}
                    />
                  </div>
                  <div
                    className='customize-reference-header-body-en'
                    ref={quillENRef}
                  >
                    <QuillEditor
                      id={999}
                      initialContent={content.text_en}
                      onContentChange={handleContentChange}
                      language='EN'
                    />
                  </div>
              </div>

              <DynamicComponentManager
                components={components}
                setComponents={setComponents}
                type='blog'
                onContentChange={handleContentChangeDynamic}
                addComponent={handleAddComponent}
                deleteComponent={handleDeleteComponent}
              />
              {/* <h2>Content Data:</h2>
              <pre>{JSON.stringify(content, null, 2)}</pre> */}
            </div>
          </div>
          {showDeleteContentModal && (
            <DeleteContentModal
              showing={showDeleteContentModal}
              closeModal={toggleDeleteContentModal}
              type='blog'
              setIsLoading={setIsLoading}
              setDisableDelete={setDisableDelete}
              setDeleteSuccess={setDeleteSuccess}
              setErrorList={setErrorList}
            />
          )}
        </>
      )}
    </>
  )
}

export default BlogEditor
