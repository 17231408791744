const reference = {
  headerTitle: 'SE REFERENSSIT',
  headerTitleSingular: 'SE REFERENSSI',
  filter: 'SE SUODATA',
  clear: 'SE TYHJENNÄ',
  toimisto: 'SE Toimisto',
  julkinenTila: 'SE Julkinen tila',
  ulkoalue: 'SE Ulkoalue',
  koivuTuotteet: 'SE Koivu-tuotteet',
  viherseinat: 'SE Viherseinät',
  viherkalusteet: 'SE Viherkalusteet',
  vihersisustus: 'SE Vihersisustus',
  filterBtns: [
    {
      tag: 'TOIMISTO',
      text: 'SE TOIMISTO',
      type: 'referenceGroup',
    },
    {
      tag: 'JULKINEN TILA',
      text: 'SE JULKINEN TILA',
      type: 'referenceGroup',
    },
    {
      tag: 'ULKOALUE',
      text: 'SE ULKOALUE',
      type: 'referenceGroup',
    },
    {
      tag: 'KOIVU-TUOTTEET',
      text: 'SE KOIVU-TUOTTEET',
      type: 'productGroup',
    },
    {
      tag: 'VIHERSEINÄT',
      text: 'SE VIHERSEINÄT',
      type: 'productGroup',
    },
    {
      tag: 'VIHERSISUSTUS',
      text: 'SE VIHERSISUSTUS',
      type: 'productGroup',
    },
    {
      tag: 'VIHERKALUSTEET',
      text: 'SE VIHERKALUSTEET',
      type: 'productGroup',
    },
  ],
}

export default reference