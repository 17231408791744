import React from "react"
import './styles.scss'

export const CheckMark = ({size = 136}) => (
  <svg
    id="uuid-ddc84f53-4867-4d20-b231-1b5f55021af5"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-46b196e7-3953-4d7b-b490-0f35ecafec8c" data-name="Layer 1">
      <g>
        <circle className="uuid-9690ecce-4d5f-4c09-a175-e6b9556e94b2" cx="68" cy="68" r="68"/>
        <g>
          <rect className="uuid-cf6961e4-366e-4c2a-8831-71b5a05e38cd" x="41.15" y="41.15" width="53.71" height="53.71" rx="4.68" ry="4.68"/>
          <polyline className="uuid-cf6961e4-366e-4c2a-8831-71b5a05e38cd" points="54.09 66.62 64.82 77.35 83.18 55.99"/>
        </g>
      </g>
    </g>
  </svg>
)

export const HandShake = ({size = 136}) => (
  <svg
    id="uuid-a2e56b9b-a5ea-46c3-b030-c8c32e7b63a3"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-6c23e6d0-a724-447e-be1c-cfc16f5a10f5" data-name="Layer 1">
      <g>
        <circle className="uuid-5c5b8efa-22e4-4204-8d3c-daa77f503122" cx="68" cy="68" r="68"/>
        <g>
          <polyline className="uuid-3337cc53-73a5-484e-8851-159d89012936" points="32.12 51.19 41.79 51.19 41.79 84.38 32.12 84.38"/>
          <polyline className="uuid-3337cc53-73a5-484e-8851-159d89012936" points="103.88 84.38 94.21 84.38 94.21 51.19 103.88 51.19"/>
          <line className="uuid-3337cc53-73a5-484e-8851-159d89012936" x1="85.78" y1="82.19" x2="93.89" y2="80.01"/>
          <path className="uuid-3337cc53-73a5-484e-8851-159d89012936" d="M41.79,80.01h4.65l5.92,8.24c1.99,2.77,5.6,3.85,8.78,2.62l1.57-.6"/>
          <path className="uuid-3337cc53-73a5-484e-8851-159d89012936" d="M59.66,84.13l2.98,4.14c1.62,2.26,4.66,2.98,7.13,1.7l2.38-1.23"/>
          <path className="uuid-3337cc53-73a5-484e-8851-159d89012936" d="M66.17,80.43l4.57,6.36c1.59,2.22,4.66,2.77,6.92,1.25l2.12-1.42"/>
          <path className="uuid-3337cc53-73a5-484e-8851-159d89012936" d="M88.52,55.07l-5.38-3.4c-1.29-.69-2.73-1.05-4.19-1.05h-10.67c-2.64,0-5.13,1.24-6.72,3.35l-8.69,11.55,1.05.87c2.47,2.04,6.06,1.99,8.46-.13l4.56-4.01c1.05-.92,2.44-1.33,3.82-1.12l4.12.63,10.13,18.99c.86,1.62.46,3.62-.95,4.79h0c-1.72,1.41-4.27,1.08-5.57-.73l-5.81-8.09"/>
          <line className="uuid-3337cc53-73a5-484e-8851-159d89012936" x1="60.74" y1="55.07" x2="47.17" y2="55.07"/>
        </g>
      </g>
    </g>
  </svg>
)

export const Sustainable = ({size = 136}) => (
  <svg
    id="uuid-8a8e91ca-f269-43e4-ba9d-1d68f27814a9"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-1de79d7f-1429-40f7-9568-f5cf753628f0" data-name="Layer 1">
      <g>
        <circle className="uuid-5c04794c-beca-4e55-aaee-f027080e0547" cx="68" cy="68" r="68"/>
        <polyline className="uuid-f37601c5-5b57-442e-9568-5938646e1e2c" points="61.2 20.26 68 27.06 61.2 33.86"/>
        <polyline className="uuid-f37601c5-5b57-442e-9568-5938646e1e2c" points="74.8 115.74 68 108.94 74.8 102.14"/>
        <path className="uuid-f37601c5-5b57-442e-9568-5938646e1e2c" d="M57.54,107.59c-17.55-4.62-30.48-20.6-30.48-39.59,0-22.61,18.33-40.94,40.94-40.94"/>
        <path className="uuid-f37601c5-5b57-442e-9568-5938646e1e2c" d="M78.46,28.41c17.55,4.62,30.48,20.6,30.48,39.59,0,22.61-18.33,40.94-40.94,40.94"/>
        <g>
          <g>
            <path className="uuid-7c4eb489-9343-40c4-afd3-0db1165db875" d="M52.68,75.51c-2.48-5.52-1.83-12.61,5.36-17.78,7.07-5.08,27.23-7.11,27.23-7.11,0,0-1.86,19.39-7.23,26.98-7.29,10.3-16.78,7.98-22.1,2.63"/>
            <line className="uuid-f37601c5-5b57-442e-9568-5938646e1e2c" x1="61.74" y1="74.64" x2="72.33" y2="75.63"/>
            <line className="uuid-f37601c5-5b57-442e-9568-5938646e1e2c" x1="68.32" y1="67.71" x2="75.48" y2="68.08"/>
          </g>
          <line className="uuid-f37601c5-5b57-442e-9568-5938646e1e2c" x1="77.11" y1="59" x2="50.73" y2="85.37"/>
          <line className="uuid-f37601c5-5b57-442e-9568-5938646e1e2c" x1="68.32" y1="67.71" x2="67.72" y2="59.79"/>
          <line className="uuid-f37601c5-5b57-442e-9568-5938646e1e2c" x1="61.74" y1="74.64" x2="60.14" y2="62.9"/>
        </g>
      </g>
    </g>
  </svg>
)

export const Design = ({size = 136}) => (
  <svg
    id="uuid-bfa5ed9a-0f63-4c76-ad16-2f077304744b"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-33908b0f-2c2b-43ac-8598-ccd76e128357" data-name="Layer 1">
      <g>
        <circle className="uuid-184d74ad-f901-43ea-8f2a-da88e6aca27a" cx="68" cy="68" r="68"/>
        <g>
          <line className="uuid-6d6c6c7c-3f5d-4302-87b1-b0f474ba4022" x1="50.8" y1="90.89" x2="45.13" y2="85.23"/>
          <path className="uuid-d8caf123-1e06-4b68-97fc-9138cbb50ab1" d="M93.21,49.32l-6.5-6.5c-1.56-1.56-4.1-1.56-5.66,0l-3.26,3.26,12.16,12.16,3.26-3.26c1.56-1.56,1.56-4.1,0-5.66Z"/>
          <polyline className="uuid-6d6c6c7c-3f5d-4302-87b1-b0f474ba4022" points="86.17 61.96 60.64 87.48 41.62 94.35 48.54 75.4 74.24 49.69"/>
          <line className="uuid-6d6c6c7c-3f5d-4302-87b1-b0f474ba4022" x1="55.35" y1="80.68" x2="80.29" y2="55.74"/>
          <line className="uuid-6d6c6c7c-3f5d-4302-87b1-b0f474ba4022" x1="61.33" y1="86.66" x2="49.37" y2="74.7"/>
        </g>
      </g>
    </g>
  </svg>
)

export const Install = ({size = 136}) => (
  <svg
    id="uuid-8f1259cc-1047-4f5e-ad8b-ffe72b22d09d"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-77b422ab-e2a0-4903-8226-eee4d1023e5c" data-name="Layer 1">
      <g>
        <circle className="uuid-a6a2c7d5-952e-4c20-8a61-467713b78993" cx="68" cy="68" r="68"/>
        <g>
          <g>
            <path className="uuid-162b4176-da70-4053-b6d2-77163ef7677e" d="M81.96,79.54l11.67-5.96c2.26-1.21,4.9-.73,5.99,1.3,1.09,2.03.13,4.68-2.12,5.89l-24.21,12.98-1.28.32-22.58.61s-2.91.81-6.4,4.77"/>
            <path className="uuid-099ff49b-e641-4d67-94a6-3335d15d5b2c" d="M35,87.59l10.24-9.43c5.01-3.38,10.94-1.51,10.94-1.51,2.87.76,8.21.93,8.21.93h8.86c2.64,0,4.8,1.89,4.8,4.19s-2.16,4.19-4.8,4.19h-12.26"/>
          </g>
          <g>
            <path className="uuid-162b4176-da70-4053-b6d2-77163ef7677e" d="M60.83,52.55c1.89,3.51,4.23,9.83,5.13,20.42"/>
            <path className="uuid-099ff49b-e641-4d67-94a6-3335d15d5b2c" d="M60.71,52.61c-2.71,1.69-7.62-1.61-9.09-6.09-1.9-5.78-.58-9.97-.58-9.97,0,0,4.63,1.2,8.5,5.19,3.28,3.39,3.58,9.38,1.18,10.88Z"/>
            <path className="uuid-099ff49b-e641-4d67-94a6-3335d15d5b2c" d="M90.33,61.54c-7.75,6.85-14.88,3.04-17.99-2.28-3.11-5.32-2.61-13.48,5.97-17.02,6.49-2.68,22.69-.55,22.69-.55,0,0-5.05,14.88-10.67,19.85Z"/>
            <line className="uuid-76009115-f71f-4fd0-9597-17e23c3738eb" x1="78.27" y1="56.01" x2="84.4" y2="59.11"/>
            <line className="uuid-76009115-f71f-4fd0-9597-17e23c3738eb" x1="83.35" y1="52.22" x2="83.53" y2="47.57"/>
            <path className="uuid-76009115-f71f-4fd0-9597-17e23c3738eb" d="M91.58,47.46l-20.01,12.44s-4.41,3.24-6.12,6.59"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Care = ({size = 136}) => (
  <svg
    id="uuid-0b4fc9f9-ce08-41e0-9489-ac0086aaea98"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-584c5c3c-b040-475a-8f0e-891a5e705c93" data-name="Layer 1">
      <g>
        <circle className="uuid-3059102a-b048-44f7-b582-840e22b1521b" cx="68" cy="68" r="68"/>
        <g>
          <path className="uuid-91ed4204-7615-49f0-a39d-291a284019c1" d="M60.16,79.62s-4.77,1.32-7.25,5.91"/>
          <path className="uuid-f8e32ea0-82a1-43e0-bad4-4944fd23525d" d="M60.38,79.45c.95,3.14,6.98,3.9,11.14,1.44,5.38-3.18,7.19-7.31,7.19-7.31,0,0-4.39-2.21-10.07-1.74-4.83.4-9.11,4.83-8.27,7.61Z"/>
          <path className="uuid-91ed4204-7615-49f0-a39d-291a284019c1" d="M78.73,101.85c-.82-2.95-3.37-5.25-6.6-5.59-3.17-.35-6.12,1.4-7.58,4.03h.01c-2.01-3.84-6.2-6.26-10.76-5.79-4.48.46-8.03,3.75-9.27,7.81"/>
          <path className="uuid-91ed4204-7615-49f0-a39d-291a284019c1" d="M49.25,79.41c2.7,4.11,5.05,8.17,5.26,14.9"/>
          <polygon className="uuid-91ed4204-7615-49f0-a39d-291a284019c1" points="86.32 46.81 80.73 52.33 76.47 52.33 76.47 33.7 80.73 33.7 86.3 39.22 86.32 46.81"/>
          <path className="uuid-91ed4204-7615-49f0-a39d-291a284019c1" d="M70.18,35.83c-10.75,0-19.47,8.72-19.47,19.47v7.69"/>
          <path className="uuid-91ed4204-7615-49f0-a39d-291a284019c1" d="M70.17,43.29c-6.62,0-12,5.39-12,12v7.7"/>
          <path className="uuid-91ed4204-7615-49f0-a39d-291a284019c1" d="M70.21,50.76c-2.5,0-4.54,2.03-4.54,4.54v7.69"/>
          <g>
            <line className="uuid-91ed4204-7615-49f0-a39d-291a284019c1" x1="86.67" y1="39.52" x2="102" y2="39.52"/>
            <line className="uuid-91ed4204-7615-49f0-a39d-291a284019c1" x1="86.67" y1="46.51" x2="102" y2="46.51"/>
          </g>
          <path className="uuid-f8e32ea0-82a1-43e0-bad4-4944fd23525d" d="M49.42,78.65c-1.92,2.66-7.87,1.42-11-2.26-4.05-4.76-4.42-9.26-4.42-9.26,0,0,4.87-.66,10.09,1.63,4.43,1.95,7.05,7.53,5.34,9.88Z"/>
        </g>
      </g>
    </g>
  </svg>
)

export const City = ({size = 136}) => (
  <svg
    id="uuid-0b358cf4-556d-4a6f-ab6a-d236a3cc0247"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-9dfb0158-f803-4a74-97ed-6d2358e7503b" data-name="Layer 1">
      <g>
        <circle className="uuid-4921b3aa-bbeb-489b-a7fb-8a94ee495119" cx="68" cy="68" r="68"/>
        <g>
          <g>
            <path className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" d="M80.42,36c.01,2.64,2.17,4.78,4.81,4.76,2.64-.01,4.78-2.17,4.76-4.81-.01-2.64-2.17-4.77-4.81-4.76-2.64.01-4.77,2.17-4.76,4.81Z"/>
            <g>
              <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="80.44" y1="47.64" x2="81.48" y2="45.15"/>
              <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="88.97" y1="26.77" x2="90.01" y2="24.28"/>
            </g>
            <g>
              <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="73.58" y1="40.83" x2="76.08" y2="39.81"/>
              <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="94.37" y1="32.11" x2="96.87" y2="31.09"/>
            </g>
            <g>
              <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="73.55" y1="31.17" x2="76.04" y2="32.22"/>
              <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="94.41" y1="39.7" x2="96.9" y2="40.75"/>
            </g>
            <g>
              <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="90.1" y1="47.6" x2="89.08" y2="45.11"/>
              <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="81.38" y1="26.82" x2="80.35" y2="24.32"/>
            </g>
          </g>
          <path className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" d="M70.79,88.94c.85.23,1.75.36,2.68.36,2.51,0,4.8-.9,6.58-2.4,1.78,1.49,4.07,2.4,6.58,2.4,5.66,0,10.24-4.58,10.24-10.24,0-4.34-2.71-8.05-6.53-9.54-1.02-5.17-5.24-9.04-10.29-9.04-2.3,0-6.5.64-9.26,5.58"/>
          <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="80.03" y1="73.16" x2="80.03" y2="98.89"/>
          <g>
            <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="74.72" y1="79.86" x2="79.52" y2="82.97"/>
            <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="85.34" y1="77.07" x2="80.02" y2="79.22"/>
          </g>
          <path className="uuid-3d827b6d-e025-4e6d-b260-a7eb04451611" d="M39.1,105.72c5.74-4.52,16.21-7.54,28.19-7.54s22.38,3,28.13,7.5"/>
          <path className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" d="M49.96,49.8c0-4.59,3.72-8.31,8.31-8.31s8.31,3.72,8.31,8.31"/>
          <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="58.27" y1="41.48" x2="58.27" y2="33.61"/>
          <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="53.05" y1="57.55" x2="63.5" y2="57.55"/>
          <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="53.05" y1="65.48" x2="63.5" y2="65.48"/>
          <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="53.05" y1="73.4" x2="63.5" y2="73.4"/>
          <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="53.05" y1="81.32" x2="63.5" y2="81.32"/>
          <line className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" x1="53.05" y1="89.25" x2="63.5" y2="89.25"/>
          <polyline className="uuid-88842e6e-c6ae-4766-80e6-87dd1cf31e43" points="45.76 101.97 45.76 49.8 70.79 49.8 70.79 97.17"/>
        </g>
      </g>
    </g>
  </svg>
)

export const Dashing = ({size = 136}) => (
  <svg
    id="uuid-61bc8589-17ef-44b5-bff2-68628e16069f"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-71616500-4454-4c15-a00d-e72d36887124" data-name="Layer 1">
      <g>
        <circle className="uuid-d46dc7e9-af2b-4c85-b293-2d788dfdec89" cx="68" cy="68" r="68"/>
        <g>
          <g>
            <path className="uuid-07e4f85c-47c8-43bd-ae38-e237c5f36d73" d="M51.75,89.93c-3.74-8.33-2.76-19.03,8.08-26.82,10.67-7.66,41.08-10.73,41.08-10.73,0,0-2.81,29.25-10.92,40.71-10.99,15.55-25.31,12.04-33.34,3.97"/>
            <line className="uuid-ad7fc368-de48-4bab-adf2-fc3e3b37d850" x1="65.41" y1="88.6" x2="81.39" y2="90.1"/>
            <line className="uuid-ad7fc368-de48-4bab-adf2-fc3e3b37d850" x1="75.34" y1="78.16" x2="86.14" y2="78.71"/>
          </g>
          <line className="uuid-ad7fc368-de48-4bab-adf2-fc3e3b37d850" x1="88.59" y1="65" x2="48.8" y2="104.8"/>
          <line className="uuid-ad7fc368-de48-4bab-adf2-fc3e3b37d850" x1="75.34" y1="78.16" x2="74.43" y2="66.21"/>
          <line className="uuid-ad7fc368-de48-4bab-adf2-fc3e3b37d850" x1="65.41" y1="88.6" x2="63" y2="70.9"/>
        </g>
        <path className="uuid-ad7fc368-de48-4bab-adf2-fc3e3b37d850" d="M67.7,39.14c-8.33,0-15.08,6.75-15.08,15.08,0-8.33-6.75-15.08-15.08-15.08,8.33,0,15.08-6.75,15.08-15.08,0,8.33,6.75,15.08,15.08,15.08Z"/>
      </g>
    </g>
  </svg>
)

export const Versatile = ({size = 136}) => (
  <svg
    id="uuid-e3ee6363-fde5-4cee-9f5f-653b2aa5857c"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
    >
    <g id="uuid-fc2c453d-4d98-4b2e-aaf5-055d6ac502fa" data-name="Layer 1">
      <g>
        <circle className="uuid-c0d9e6bf-9770-4137-8772-fc9223c554c5" cx="68" cy="68" r="68"/>
        <line className="uuid-669b2756-041c-4bde-8c09-8f24ef4b2020" x1="63.47" y1="72.53" x2="38.43" y2="97.57"/>
        <line className="uuid-669b2756-041c-4bde-8c09-8f24ef4b2020" x1="97.57" y1="38.43" x2="72.53" y2="63.47"/>
        <line className="uuid-669b2756-041c-4bde-8c09-8f24ef4b2020" x1="38.43" y1="38.43" x2="97.57" y2="97.57"/>
        <polyline className="uuid-669b2756-041c-4bde-8c09-8f24ef4b2020" points="80.15 38.43 97.57 38.43 97.57 55.85"/>
        <polyline className="uuid-669b2756-041c-4bde-8c09-8f24ef4b2020" points="97.57 80.15 97.57 97.57 80.15 97.57"/>
        <polyline className="uuid-669b2756-041c-4bde-8c09-8f24ef4b2020" points="55.85 97.57 38.43 97.57 38.43 80.15"/>
        <polyline className="uuid-669b2756-041c-4bde-8c09-8f24ef4b2020" points="38.43 55.85 38.43 38.43 55.85 38.43"/>
      </g>
    </g>
  </svg>
)

export const Ugf = ({size = 136}) => (
  <svg
    id="uuid-3b7932bf-bc69-435b-a4fc-3b201294ad50"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-a6a039b3-20c3-4dcc-b62d-5e47b98456fe" data-name="Layer 1">
      <g>
        <circle className="uuid-a1bb8d06-fb1e-4157-b859-61631292023f" cx="68" cy="68" r="68"/>
        <rect className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" x="75.18" y="36.64" width="8.72" height="62.71"/>
        <path className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" d="M91.74,74.81s-3.49-3.57-7.47-1.55"/>
        <path className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" d="M97.03,70.2c3.36.44,7.5-1.95,9.32-6.52,2.83-7.07,1-13.02,1-13.02,0,0-6.67.16-12.28,4.25-4.47,3.26-5.22,10.35-2.47,13.47"/>
        <path className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" d="M91.53,74.93c-1.88,1.32-1.08,5.5,1.47,7.74,3.29,2.89,6.44,3.2,6.44,3.2,0,0,.51-3.41-1.03-7.09-1.32-3.13-5.2-5.02-6.87-3.85Z"/>
        <path className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" d="M102.23,57.69s-4.64,9.64-17.96,15.56"/>
        <line className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" x1="42.27" y1="36.64" x2="42.27" y2="99.36"/>
        <line className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" x1="58.73" y1="36.64" x2="58.73" y2="99.36"/>
        <line className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" x1="42.27" y1="52.32" x2="75.18" y2="52.32"/>
        <line className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" x1="42.27" y1="68" x2="75.18" y2="68"/>
        <line className="uuid-4249038b-64b0-46f8-9293-00a0e9f9b6ba" x1="42.27" y1="83.68" x2="75.18" y2="83.68"/>
      </g>
    </g>
  </svg>
)

export const CareFree = ({size = 136}) => (
    <svg
      id="uuid-653bcd9a-3c8a-41e6-b602-e94a4b7a11b3"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 136 136"
      width={size}
      height={size}
    >
      <g id="uuid-7656da43-2287-4988-8b52-abe2231f2714" data-name="Layer 1">
        <g>
          <circle className="uuid-2a27eca9-282d-47df-a9d5-5afde1943031" cx="68" cy="68" r="68"/>
          <g>
            <circle className="uuid-2c2a48a0-9047-4841-9671-c0df8512b57a" cx="68" cy="68" r="31"/>
            <path className="uuid-2c2a48a0-9047-4841-9671-c0df8512b57a" d="M83.73,76.08c-2.93,5.7-8.88,9.6-15.73,9.6-6.85,0-12.8-3.9-15.73-9.6"/>
            <path className="uuid-2c2a48a0-9047-4841-9671-c0df8512b57a" d="M53.59,60.86c.54-1.83,2.24-3.16,4.24-3.16s3.7,1.33,4.24,3.16"/>
            <path className="uuid-2c2a48a0-9047-4841-9671-c0df8512b57a" d="M82.41,60.86c-.54-1.83-2.24-3.16-4.24-3.16s-3.7,1.33-4.24,3.16"/>
          </g>
        </g>
      </g>
    </svg>
)

export const Bee = ({size = 136}) => (
  <svg
    id="uuid-66094a61-1b0d-4253-aaa1-ffc87bd62412"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 136 136"
    width={size}
    height={size}
  >
    <g id="uuid-4358d926-3778-494c-8b61-58de8d4ae96f" data-name="Layer 1">
      <g>
        <circle className="uuid-d53188ab-49fb-4451-ab19-8fd720c93ac2" cx="68" cy="68" r="68"/>
        <g>
          <path className="uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188" d="M60.48,51.9c-1.61-5.75,7.26-9.75,7.26-9.75,0,0,9.05,4.4,7.88,9.9"/>
          <path className="uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188" d="M55.82,74.4c-.21,1.08-.32,2.2-.32,3.36,0,8.17,8.71,22.44,12.45,22.44h.04c3.74,0,12.45-14.27,12.45-22.44,0-5.62-2.64-10.5-6.52-13.01"/>
          <g>
            <line className="uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188" x1="64.19" y1="43.4" x2="56.45" y2="35.97"/>
            <line className="uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188" x1="71.8" y1="43.22" x2="79.55" y2="35.8"/>
          </g>
          <line className="uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188" x1="61.5" y1="75.29" x2="79.94" y2="75.29"/>
          <line className="uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188" x1="62.73" y1="82.41" x2="79.35" y2="82.41"/>
          <line className="uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188" x1="66.09" y1="89.53" x2="76.61" y2="89.53"/>
          <g>
            <path className="uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188" d="M96.71,54.15c-5.72-.76-28.16,7.12-28.16,7.12,0,0,20.17,13.55,25.89,14.31,5.72.76,10.87-3.42,11.5-9.33.63-5.92-3.5-11.33-9.22-12.09Z"/>
            <path className="uuid-2eb733a2-3230-4d48-a5f7-7a0994c83188" d="M39.29,54.15c5.72-.76,28.16,7.12,28.16,7.12,0,0-20.17,13.55-25.89,14.31-5.72.76-10.87-3.42-11.5-9.33-.63-5.92,3.5-11.33,9.22-12.09Z"/>
          </g>
          <path className="uuid-b307960a-64cf-44bd-96a3-d19945846aa4" d="M56.82,57.79c1.43-5.69,6.31-7.66,11.13-7.66h.1c4.82,0,9.7,1.97,11.13,7.66"/>
        </g>
      </g>
    </g>
  </svg>
)

export const Heart = ({size = 136}) => (
  <svg
    id="uuid-a0566d7e-31b7-4993-84f0-6ad1d72d2f6f"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130 130"
    width={size}
    height={size}
    >
    <g id="uuid-f728aefd-7559-40c4-82c5-48d0e9b4c8f0" data-name="Layer 1">
      <g>
        <circle className="uuid-906b8852-d667-4614-aa54-da1b10471d8b" cx="65" cy="65" r="65"/>
        <g>
          <path className="uuid-d7c8f392-2e2d-4434-8473-aecd0b302238" d="M65,95.15c26.67-12.82,32.33-29.56,32.33-38.69,0-9.73-5.96-19.61-17.12-19.61-11.86,0-15.21,9.83-15.21,9.83,0,0-3.34-9.83-15.21-9.83-11.16,0-17.12,9.88-17.12,19.61,0,9.12,5.66,25.87,32.33,38.69Z"/>
          <path className="uuid-d7c8f392-2e2d-4434-8473-aecd0b302238" d="M44.44,68.4c-3.3-5.45-3.76-9.91-3.76-11.94,0-1.88.42-4.55,1.61-6.85"/>
        </g>
      </g>
    </g>
  </svg>
)

export const Future = ({size = 136}) => (
  <svg
    id="uuid-6725e4f6-c0ed-47ee-bfbd-1c8c564ac8ae"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130 130"
    width={size}
    height={size}
  >
    <g id="uuid-398e3eed-450c-47ae-9ba1-10aa60649669" data-name="Layer 1">
      <g>
        <circle className="uuid-8fcacf40-2362-4054-ad5b-43ae1e4e56f1" cx="65" cy="65" r="65"/>
        <path className="uuid-8fcf082b-f86f-4e3d-a9f7-9a9ec55cf102" d="M59.13,36.18c-6.71-7.89-7.34-10.74-7.4-11.14h0c-.06.39-.68,3.24-7.39,11.14-7.84,9.21-3.4,21.24,7.34,21.27h.12c10.67-.03,15.18-12.06,7.34-21.27Z"/>
        <path className="uuid-8fcf082b-f86f-4e3d-a9f7-9a9ec55cf102" d="M45.41,46.26s.05,3.46,3.15,5.21"/>
        <path className="uuid-8fcf082b-f86f-4e3d-a9f7-9a9ec55cf102" d="M48.14,80.71s14.13,3.6,15.49,14.59"/>
        <path className="uuid-8fcf082b-f86f-4e3d-a9f7-9a9ec55cf102" d="M65.21,77.38c-4.19-8.98,1.45-18.9,10.06-23.75,13.32-7.5,25.66-5.45,25.66-5.45,0,0,1.45,13.32-5.21,25.57-5.3,9.75-19.22,13.13-26.16,8.48"/>
        <path className="uuid-8fcf082b-f86f-4e3d-a9f7-9a9ec55cf102" d="M57.17,85.38c-1.86,4.22-10.41,4.26-15.81.1-6.97-5.36-8.8-11.44-8.8-11.44,0,0,6.51-2.33,14.37-.71,6.67,1.38,11.9,8.3,10.25,12.04Z"/>
        <path className="uuid-8fcf082b-f86f-4e3d-a9f7-9a9ec55cf102" d="M88.28,60.27s-19.58,12.86-27.26,43.69"/>
      </g>
    </g>
  </svg>
)

export const Nature = ({size = 94}) => (
  <svg
    id="uuid-4bc7c2fd-92fc-4e09-970d-10cba8033fa9"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 94 94"
    width={size}
    height={size}
    >
    <g id="uuid-046a3135-f78c-4387-ad9d-f0357a4d1179" data-name="Layer 1">
      <g>
        <circle className="uuid-4cf4e9d0-6d11-4648-909d-778776ed8c79" cx="47" cy="47" r="47"/>
        <g>
          <g>
            <path className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" d="M55.4,26.75c0,1.71,1.41,3.1,3.12,3.09,1.71,0,3.1-1.4,3.09-3.12,0-1.71-1.4-3.09-3.12-3.09-1.71,0-3.09,1.4-3.09,3.12Z"/>
            <g>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="55.42" y1="34.3" x2="56.09" y2="32.68"/>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="60.95" y1="20.77" x2="61.62" y2="19.16"/>
            </g>
            <g>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="50.97" y1="29.89" x2="52.59" y2="29.22"/>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="64.45" y1="24.23" x2="66.07" y2="23.57"/>
            </g>
            <g>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="50.95" y1="23.62" x2="52.56" y2="24.3"/>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="64.48" y1="29.15" x2="66.09" y2="29.83"/>
            </g>
            <g>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="61.68" y1="34.28" x2="61.02" y2="32.66"/>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="56.02" y1="20.8" x2="55.36" y2="19.18"/>
            </g>
          </g>
          <g>
            <path className="uuid-d299d9fc-3e07-46e2-855a-9d71101a3288" d="M46.93,59.65c.89.35,1.85.54,2.86.54,1.92,0,3.67-.69,5.03-1.83,1.36,1.14,3.11,1.83,5.03,1.83,4.32,0,7.82-3.5,7.82-7.82,0-3.32-2.07-6.15-4.99-7.29-.78-3.95-4-6.91-7.86-6.91-1.76,0-3.38.61-4.7,1.66"/>
            <line className="uuid-40eb4a3e-3fb8-4437-98a4-cd065021cc74" x1="54.8" y1="47.87" x2="54.8" y2="67.52"/>
            <g>
              <line className="uuid-40eb4a3e-3fb8-4437-98a4-cd065021cc74" x1="50.74" y1="52.98" x2="54.41" y2="55.36"/>
              <line className="uuid-40eb4a3e-3fb8-4437-98a4-cd065021cc74" x1="58.86" y1="50.85" x2="54.8" y2="52.49"/>
            </g>
            <path className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" d="M47.91,46c1.67,9.59-4.9,13.42-10.69,13.28-5.79-.13-12.08-4.55-10.69-13.17,1.05-6.52,4.98-18.44,10.74-18.44s9.43,11.38,10.64,18.32Z"/>
            <g>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="37.07" y1="53.07" x2="30.67" y2="47.8"/>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="36.96" y1="45.57" x2="33.1" y2="42.38"/>
            </g>
            <g>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="37.41" y1="53.07" x2="43.82" y2="47.8"/>
              <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="37.52" y1="45.57" x2="41.38" y2="42.38"/>
            </g>
            <line className="uuid-11cb1ab3-c236-46e0-89ac-f13a3efad92a" x1="37.25" y1="38.63" x2="37.25" y2="67.88"/>
            <path className="uuid-d299d9fc-3e07-46e2-855a-9d71101a3288" d="M28.61,71.95c3.72-2.93,10.51-4.89,18.27-4.89s14.51,1.95,18.24,4.86"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Drop = ({size = 94}) => (
  <svg
    id="uuid-6255d540-f525-43d1-bccc-bce8d6a1400d"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 94 94"
    width={size}
    height={size}
  >
    <g id="uuid-25aab89f-4df6-4dd8-a971-f5b729fead47" data-name="Layer 1">
      <g>
        <circle className="uuid-7fe3c2c9-1886-4f19-ae02-74be76715be9" cx="47" cy="47" r="47"/>
        <g>
          <g>
            <path className="uuid-b073d469-57e1-4830-bb9f-8bfd34ad769d" d="M50.05,45.89c-7.68-9.02-8.39-12.29-8.46-12.74h0c-.06.45-.78,3.71-8.46,12.74-8.97,10.54-3.89,24.29,8.39,24.33h.13c12.2-.04,17.36-13.79,8.39-24.33Z"/>
            <path className="uuid-b073d469-57e1-4830-bb9f-8bfd34ad769d" d="M34.36,57.42s.06,3.96,3.6,5.96"/>
          </g>
          <path className="uuid-b073d469-57e1-4830-bb9f-8bfd34ad769d" d="M61.68,27.35c-3.89-4.57-4.25-6.23-4.29-6.45h0c-.03.23-.39,1.88-4.29,6.45-4.55,5.34-1.97,12.31,4.25,12.33h.07c6.18-.02,8.8-6.99,4.25-12.33Z"/>
        </g>
      </g>
    </g>
  </svg>
)

export const Circular = ({size = 94}) => (
  <svg
    id="uuid-0510d451-b2db-4c6e-beea-da8fc3930318"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 94 94"
    width={size}
    height={size}
  >
    <g id="uuid-b5db7248-d11b-4f74-ad66-a0b6d06c457e" data-name="Layer 1">
      <g>
        <circle className="uuid-875bb3e9-1a0f-4064-b47a-a992016533da" cx="47" cy="47" r="47"/>
        <g>
          <polyline className="uuid-d8cadf39-2228-4078-be4c-43cddfe3013d" points="42.76 21.92 47 25.61 43.31 29.85"/>
          <polyline className="uuid-d8cadf39-2228-4078-be4c-43cddfe3013d" points="51.24 72.08 47 68.39 50.69 64.15"/>
          <path className="uuid-d8cadf39-2228-4078-be4c-43cddfe3013d" d="M39.76,67.14c-8.25-2.97-14.16-10.86-14.16-20.14,0-11.82,9.58-21.4,21.4-21.4"/>
          <path className="uuid-d8cadf39-2228-4078-be4c-43cddfe3013d" d="M54.24,26.86c8.25,2.97,14.16,10.86,14.16,20.14,0,11.82-9.58,21.4-21.4,21.4"/>
        </g>
      </g>
    </g>
  </svg>
)