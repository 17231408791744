const viherpysakki = {
  name: 'Viherpysäkki',
  longDescription: `InnoGreenin viherpysäkki tuo vehreyttä ja 
      viihtyisyyttä keskelle kaupunkia. Viherpysäkkimme syntyi halusta 
      kehittää kaupunkibiodiversiteettiä lisäävä urbaani vihertila, 
      jossa jokaisella kaupunkilaisella olisi mahdollisuus nauttia 
      runsaan vehreyden tuomasta viihtyisyydestä, rauhasta ja varjosta. 
      Kaupunkiympäristöön sijoitettuna viherpysäkki tarjoaa samassa 
      paketissa luontokosketuksen, levähdyspaikan ja yhteisöllisyyttä. 
      \\n
      Viherpysäkki tuo kodikkuutta ja suojaa suurille aukioille, mutta 
      myös kaivattua toimintaa ja vehreyttä pienille taloyhtiöpihoille. 
      Toisaalta se voidaan sijoittaa myös näyttäväksi elementiksi 
      vaikka suureen aulaan sisätiloihin, jossa sitä voidaan käyttää 
      kohtaamispaikkana tai odotusalueena. Runsaan vehreä viherpysäkki 
      tarjoaa suojaa ja ravintoa linnuille ja pölyttäjille. Lisäksi se 
      toimii pöyttäjien elintärkeänä kulkureittinä ja levähdyspaikkana 
      kiinteiden viheralueiden välillä.
      \\n
      Modulaarisen rakenteensa ansiosta jokainen viherpysäkki on 
      räätälöitävissä käyttäjiensä näköiseksi ja kokoiseksi. Toivotko 
      esimerkiksi penkkejä, joissa istuskella kesäpäivänä tai asukkaille 
      oman tuoksuvan yrttiviljelmän? Luonnonmukaiset kasvivalinnat 
      houkuttelevat paikalle perhosia ja pörriäisiä, joiden puuhia 
      kaupunkilaiset tai asukkaat voivat seurata. Valoa läpäisevä 
      viherpysäkki on raikkaan näköinen myös silloin, kun kasveja on 
      runsaasti. 
      \\n
      Viherpysäkki on nopea, puistomainen ratkaisu, joka ei vaadi 
      perustuksia tai kalliita muutostöitä. Erityisen siitä tekee 
      modulaarisuus sekä mahdollisuus sijoittaa pysäkki lähes mihin 
      tahansa päin kaupunkia jo rakennetulla pinnalle, kuten asfaltille 
      tai kivetykselle. Koska viherpysäkki on alunperinkin tarkoitettu 
      kovalle pinnalle, pysäkkiä on helppo siirtää ja liikutella. 
      \\n
      Viherpysäkki kasveineen ja huoltoineen on saatavilla palveluna. 
      Kaiken kattava vuokrapalvelumme sisältää pysäkin rakentamisen, 
      ylläpidon sekä talvikauden varastoinnin. Asiantuntijamme auttavat 
      juuri sopivan koon sekä sijoituspaikan valinnassa. Kaikki 
      viherpysäkin osat valmistetaan Suomessa, suomalaisesta puusta.`,
  measurements: [
    {
      type: 'Perusmoduuli:',
      values: [
        {
          value: 'Kaareva penkillä varustettu moduuli, joka läpäisee valoa'
        },
        {
          value: 'Korkeus 1950 mm'
        },
        {
          value: 'Leveys 1000 mm'
        },
        {
          value: 'Syvyys 950 mm'
        }
      ]
    },
    {
      type: 'Viherseinämoduuli:',
      values: [
        {
          value: 'Kaareva viherseinämoduuli, jonka voi toteuttaa myös kaksipuolisena viherseinänä.'
        },
        {
          value: 'Korkeus 1950 mm'
        },
        {
          value: 'Leveys 1900 mm'
        },
        {
          value: 'Syvyys 540 mm (ilman lisäosia)'
        }
      ]
    },
    {
      type: 'Viherseinämoduulin osat:',
      bullet: true,
      values: [
        {
          value: 'Viherseinään yhdistettävä hyönteishotelli'
        },
        {
          value: 'Viherseinään yhdistettävä linnunpönttö'
        },
        {
          value: 'Viherseinän vastapuolelle pelipiste'
        },
        {
          value: 'Viherseinän vastapuolelle liitutaulu'
        }
      ]
    },
  ],
  materials: [
    {
      type: 'Runko:',
      value: 'Suomalainen mänty, kuusi ja pinnoitettu vaneri '
    },
    {
      type: 'Viherseinän moduulit:',
      value: 'Abs-muovi, valmistettu Kaarinassa '
    },
    {
      type: 'Kasvit:',
      value: 'Suomi ja EU'
    },
    {
      type: 'Muut materiaalit:',
      value: 'Huopa, järviruoko, metalli'
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi'
    },
    {
      type: 'Käyttö:',
      value: 'Sisä- ja ulkotilat',
      icon: 'both'
    },
  ],
  wlt: {},
  configuration: [
    {
      type: 'viherpysäkki',
      text: ['Perusmoduuli'],
    },
    {
      type: 'viherpysäkki',
      text: ['Yksipuoleinen', 'Kaksipuoleinen'],
    },
    {
      type: 'viherpysäkki',
      text: ['Hyönteishotelli', 'Linnunpönttö', 'Pelit'],
    }
  ],
  perusmoduulit: {
    name: 'Perusmoduulit',
    description: `Kaareva penkillä varustettu moduuli, joka läpäisee valoa.`
  },
  viherseinamoduulit: {
    name: 'Viherseinämoduulit',
    description: `Viherseinämoduuli, jonka voi toteuttaa myös kaksipuolisena viherseinänä.`
  }, 
  viherseinamoduulin_lisaosat: {
    name: 'Viherseinämoduulit lisäosat',
    description: `Viherseinämoduuliin yhdistettävät lisäosat.`
  },
}

export default viherpysakki