import './styles.scss'
import React, { useState, useRef, useEffect } from 'react'
import QuillEditor from '../../QuillEditor'

const CustomQuillInput = (props) => {
  const {
    identifier,
    initialContent,
    initialContentEN,
    handleCustomTextInputChange,
  } = props
  // const [isEditing, setIsEditing] = useState(false)
  // const [text, setText] = useState('')
  const quillFIRef = useRef(null)
  const quillENRef = useRef(null)
  const [selectedLanguage, setSelectedLanguage] = useState('FI')

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language)
    if (language === 'FI') {
      quillENRef.current.style.display = 'none'
      quillFIRef.current.style.display = 'block'

    }
    if (language === 'EN') {
      quillFIRef.current.style.display = 'none'
      quillENRef.current.style.display = 'block'
    }
  }

  return (
    <>
      <div className='customize-blog-header-language-wrapper'>
        <div className='customize-blog-header-language-selector'>
          <div 
            className={`customize-blog-header-language ${selectedLanguage === 'FI' ? 'active' : ''}`}
            onClick={() => handleSelectLanguage('FI')}
          >
            FI
          </div>
          <div 
            className={`customize-blog-header-language ${selectedLanguage === 'EN' ? 'active' : ''}`}
            onClick={() => handleSelectLanguage('EN')}
          >
            EN
          </div>
        </div>
      </div>

      <div className='custom-quill-input-container'>
        <div className='custom-quill-input-wrapper' ref={quillFIRef}>
          <QuillEditor
            id={identifier}
            initialContent={initialContent}
            onDynamicContentChange={handleCustomTextInputChange}
          />
        </div>
        <div className='custom-quill-input-wrapper en' ref={quillENRef}>
          <QuillEditor
            id={identifier}
            initialContent={initialContentEN}
            onDynamicContentChange={handleCustomTextInputChange}
            language='EN'
          />
        </div>
      </div>
    </>
  )
}

export default CustomQuillInput