import './styles.scss'

const CustomButton = (props) => {
  const {
    button_text,
    customStyles,
    onClickHandler,
    selected,
    disabled,
    primary,
    danger,
    yellow,
    purple,
    white,
    turquoise,
    transparent,
    contentClass,
    icon,
    type,
  } = props
  let container_class_name = `custom-button-container`
  let contentClassName = 'custom-button-content no-select '

  if (primary) {
    container_class_name += ' primary'
  }
  if (selected) {
    container_class_name += ' selected'
  }
  if (disabled) {
    container_class_name += ' disabled'
  }
  if (danger) {
    container_class_name += ' danger'
  }
  if (yellow) {
    container_class_name += ' yellow'
  }
  if (turquoise) {
    container_class_name += ' turquoise'
  }
  if (transparent) {
    container_class_name += ' transparent'
  }
  if (purple) {
    container_class_name += ' purple'
  }
  if (white) {
    container_class_name += ' white'
  }
  if (type) {
    container_class_name += ` ${type}`
  }
  if (contentClass) {
    contentClassName += contentClass
  }

  return (
    <div
      className={`${container_class_name} no-highlight`}
      style={customStyles}
      onClick={onClickHandler}
    >
      <div
        className={contentClassName}
      >
        {button_text}
        {icon ?
          (
            icon
          ) : (
            <></>
          )
        }
      </div>
    </div>
  )
}

export default CustomButton

