import './styles.scss'
import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../components/common/CustomButton'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToHome = () => {
    navigate('/')
  }

  return (
    <>
      <div className='not-found-container'>
        <div className='not-found-body-container'>
          <div className='not-found-title'>
            {t('common.notFound')}
          </div>
          <CustomButton
            yellow
            button_text={t('common.toHome').toUpperCase()}
            onClickHandler={navigateToHome}
          />
        </div>
      </div>
      <div style={{ flexGrow: 1 }}></div>
    </>
  )
}

export default NotFound
