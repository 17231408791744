const koivu_viherseina = {
  name: 'Koivu-viherseinä',
  longDescription: `Raamikkaan Koivu-viherseinän voi sijoittaa katseenvangitsijaksi seinälle 
      tai vaihtoehtoisesti sitä voi käyttää ketteränä kaksipuolisena tilanjakajana, jota voi 
      siirtää vaivatta tilasta toiseen. Koivu on tukeva ja vakaa siirtää, mutta muotoilultaan 
      sekä luontoa hehkuvalta ilmeeltään raikas ja moderni sisustuselementti.
      \\n
      Koivu-viherseinässä on jopa 48 aitoa kasvia ja viherseinä kastellaan turvallisesti 
      altakastelujärjestelmällä. Toiselle puolelle viherseinää on mahdollista saada vehreä 
      sammal- tai jäkäläseinä. Aidot kasvit tarvitsevat viihtyäkseen valoa ja niukan valon 
      tilaan toimitamme myös sopivat kasvivalot.`,
  measurements: [
    {
      type: 'Tilanjakaja:',
      values: [
        {
          value: 'Leveys 1255 mm'
        },
        {
          value: 'Korkeus 1432 mm'
        },
        {
          value: 'Syvyys 540 mm (rungon syvyys 350 mm)'
        }
      ]
    },
    {
      type: 'Seinälle sijoitettava:',
      values: [
        {
          value: 'Leveys 1255 mm'
        },
        {
          value: 'Korkeus 1255 mm'
        },
        {
          value: 'Syvyys 250 mm'
        }
      ]
    }
  ],
  materials: [
    {
      type: 'Runko:',
      value: 'Suomalainen koivuviilu'
    },
    {
      type: 'Viherseinän moduulit:',
      value: 'Abs muovi, valmistettu Kaarinassa'
    },
    {
      type: 'Kasvit:',
      value: 'Suomi ja EU'
    },
    {
      type: 'Valmistusmaa:',
      value: 'Suomi'
    },
    {
      type: 'Tilanjakajana taustamateriaali:',
      value: 'Huoltovapaa stabiloitu sammal tai jäkälä (EU)'
    },
    {
      type: 'Käyttö:',
      value: 'Sisätilat',
      icon: 'indoor'
    },
  ],
  wlt: {
    title: 'Tämä viherseinä suojelee 1000 neliötä sademetsää',
    text: `Valitsemalla InnoGreenin viherseinän suojelet kanssamme maailman 
      sademetsiä yhteistyössä World Land Trustin kanssa. Jokaista myytyä tai 
      vuokrattua kasviseinäneliömetriä kohden suojelemme 1000 neliömetriä sademetsää.`
  },
  malli: {
    description: `Valitse malli`
  },
  kehyksen_vari: {
    description: `Näkyvien osien viimeistely`
  },
  taustamateriaali: {
    description: `Valitse materiaali seinän toiselle puolelle`
  },
}

export default koivu_viherseina