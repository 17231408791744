import './styles.scss'

const EmployeeCard = (props) => {
  const {
    imgSrc = '',
    name = '',
    department = '',
    phoneNumber = '',
    email = ''
  } = props
  
  
  return (
    <>
      <div className='employee-card-img-wrapper'>
        <img src={imgSrc} alt="Employee Image" />
      </div>
      <div className='employee-card-name header-text'>{name}</div>
      <div className='employee-card-department small-text'>{department.join(', ').toUpperCase()}</div>
      {
        email ? (
          <div className='employee-card-email body-text'>{email}</div>
        ) : (
          <></>
        )
      }
      {
        phoneNumber ? (
          <div className='employee-card-phone body-text'>{phoneNumber}</div>
        ) : (
          <></>
        )
      }
    </>
  )


}

export default EmployeeCard
