import ClipLoader from 'react-spinners/ClipLoader'
import './styles.scss'

const LoadingSpinner = (props) => {
  const { 
    border = false,
    border2 = false,
    noBackground = false
  } = props
  return (
    <div className={`loading-spinner-container ${border ? 'border' : ''} ${border2 ? 'border2' : ''} ${noBackground ? 'noBackground' : ''}`}>
      <ClipLoader color='#1a73e8' />
    </div>
  )
}

export default LoadingSpinner
