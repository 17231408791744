const viherpysakki = {
  name: 'SE Viherpysäkki',
  longDescription: `SE InnoGreen’s green stop brings greenery and comfort 
      to the heart of the city. Our green stop was born out of a desire 
      to develop an urban green space that enhances urban biodiversity, 
      and where every resident can enjoy the comfort, peace and shade of 
      lush greenery. In an urban environment, the green stop provides a 
      place to get in touch with nature, a place to rest and a sense of 
      community, all in one package.
      \\n
      The green stop can provide comfort and shelter in big open squares 
      or create much-needed activity and greenery in small courtyards. 
      It can also be placed indoors as an eye-catching element, for 
      example in a large lobby, where it can be used as a meeting point 
      or waiting area. The lush greenery provides shelter and food for 
      birds and pollinators. It also serves as a vital migration route 
      and as a resting place between green areas for pollinators.
      \\n
      Thanks to its modular design, each green stop can be customised 
      to its users. Would you like benches for lounging and enjoying 
      the summer, or your own fragrant herb garden? Natural plant 
      choices will attract butterflies and pollinators, and residents 
      can watch the hustle and bustle of bees and other insects. 
      The green stop lets the light through and looks fresh even when 
      there’s an abundance of plants.
      \\n
      The green stop is a quick, park-like solution that requires no 
      foundations or expensive modifications. What makes it special 
      is its modularity and the possibility of placing it almost 
      anywhere in the city on an already built surface, such as asphalt 
      or paving. As the green stop was originally designed for hard 
      surfaces, it is easy to move and manoeuvre.
      \\n
      The green stop along with plants and maintenance is available as a 
      service. Our all-inclusive rental service includes the 
      construction, maintenance and winter storage of the stop. Our 
      experts will help you choose the right size and location. All 
      parts of the green stop are made in Finland, from Finnish wood.`,
  measurements: [
    {
      type: 'SE Basic module:',
      values: [
        {
          value: 'Curved module with a bench that lets light through'
        },
        {
          value: 'Height 195 cm'
        },
        {
          value: 'Width 100 cm'
        },
        {
          value: 'Depth 95 cm'
        }
      ]
    },
    {
      type: 'SE Green wall module:',
      values: [
        {
          value: 'Curved green wall module that can also be used as a double-sided green wall'
        },
        {
          value: 'Height 195 cm'
        },
        {
          value: 'Width 190 cm'
        },
        {
          value: 'Depth 54 cm (without accessories)'
        }
      ]
    },
    {
      type: 'SE Additional parts of the green wall module:',
      bullet: true,
      values: [
        {
          value: 'Insect hotel integrated into the green wall'
        },
        {
          value: 'Birdhouse combined with the green wall'
        },
        {
          value: 'Games on the opposite side of the green wall'
        },
        {
          value: 'Chalkboard on the opposite side of the green wall'
        }
      ]
    },
  ],
  materials: [
    {
      type: 'SE Frame:',
      value: 'Finnish pine, spruce and coated plywood'
    },
    {
      type: 'SE Green wall modules:',
      value: 'Abs plastic, in Kaarina'
    },
    {
      type: 'SE Plants:',
      value: 'Finland and EU'
    },
    {
      type: 'SE Other materials:',
      value: 'Felt, lake reed, metal'
    },
    {
      type: 'SE Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'SE Usage:',
      value: 'Indoor and outdoor',
      icon: 'both'
    },
  ],
  wlt: {},
  configuration: [
    {
      type: 'viherpysäkki',
      text: ['SE Perusmoduuli'],
    },
    {
      type: 'viherpysäkki',
      text: ['SE Yksipuoleinen viherseinämoduuli', 'SE Kaksipuoleinen viherseinämoduuli'],
    },
    {
      type: 'viherpysäkki',
      text: ['SE Hyönteishotelli integroituna viherseinään', 'SE Linnunpöttö integroituna viherseinään', 'SE Pelit yksipuolisen viherseinän toiselle puolelle'],
    }
  ],
  perusmoduulit: {
    name: 'SE Perusmoduulit',
    description: `SE Kaareva penkillä varustettu moduuli, joka läpäisee valoa.`
  },
  viherseinamoduulit: {
    name: 'SE Viherseinämoduulit',
    description: `SE Viherseinämoduuli, jonka voi toteuttaa myös kaksipuolisena viherseinänä.`
  }, 
  viherseinamoduulin_lisaosat: {
    name: 'SE Viherseinämoduulit lisäosat',
    description: `SE Viherseinämoduuliin yhdistettävät lisäosat.`
  },
}

export default viherpysakki