import './styles.scss'
import { Close } from '../Icons'
import { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import { update_user_information } from '../../store/asyncThunk'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const EditUserInformationModal = ({ showing, closeModal }) => {
  const dispatch = useDispatch()
  const [userInfo, setUserInfo] = useState({
    name: '',
    phone_number: '',
    company: '',
    email_subscribe: false
  })
  const [errorList, setErrorList] = useState([])
  const [savedSuccess, setSavedSuccess] = useState(false)
  const [changed, setChanged] = useState(false)
  const current_user = useSelector((state) => state.user.current_user)
  const { t } = useTranslation()

  useEffect(() => {
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  useEffect(() => {
    if (current_user) {
      const { name, phone_number, company, email_subscribe } = current_user
      if (email_subscribe === null) {
        setUserInfo({name, phone_number, company})
      }
      else {
        setUserInfo({name, phone_number, company, email_subscribe})
      }
    }
  }, [showing, current_user])

  const updateUserInfo = ({
    name,
    phone_number,
    company,
    email_subscribe,
  }) => {
    setSavedSuccess(false)
    if (name !== undefined) {
      setUserInfo({ ...userInfo, name: name })
    }
    if (phone_number !== undefined) {
      setUserInfo({ ...userInfo, phone_number: phone_number })
    }
    if (company !== undefined) {
      setUserInfo({ ...userInfo, company: company })
    }
    if (email_subscribe !== undefined) {
      setUserInfo({ ...userInfo, email_subscribe: email_subscribe })
    }
  }

  const close = () => {
    document.body.style.overflow = 'unset'
    setSavedSuccess(false)
    closeModal()
  }

  const handleSaveBtnClick = async () => {
    setErrorList([])
    await dispatch(update_user_information({...userInfo, user_id: current_user.id}))
      .then((res) => {
        if (res.error) {
          setErrorList([t('account.genericUserUpdateError')])
        }
        setSavedSuccess(true)
      })
      .catch((error) => {
        console.error("User update error Error:", error)
      })
  }

  return (
    showing && (
      <div className='user-edit-modal-root'>
        <div className='user-edit-modal-container'>
          <div className='user-edit-modal-header'>
            <div className='user-edit-modal-header-exit-btn-container'>
              <div
                className='inquiry-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20}/>
              </div>
            </div>
          </div>
          {savedSuccess ?
            (
              <>
                 <div className='user-edit-modal-content'>
                  <div className='user-edit-modal-header-title small'>{t('account.updatedUserInfo')}</div>
                 </div>
              </>
            ) : (
              <>
                <div className='user-edit-modal-content'>
                  <div className='user-edit-modal-header-title'>{t('account.updateUserInformation')}</div>
                  <div className='user-edit-modal-input-group'>
                    <label htmlFor="name" className='user-edit-modal-input-label'>{t('project.yourName')}</label>
                    <input
                      id='name'
                      type='text'
                      value={userInfo.name}
                      className='user-edit-modal-input-field'
                      onInput={(e) =>
                        updateUserInfo({ name: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className='user-edit-modal-input-group'>
                    <label htmlFor="phone_number" className='user-edit-modal-input-label'>{t('project.phoneNumber')}</label>
                    <input
                      id='phone_number'
                      type='text'
                      value={userInfo.phone_number}
                      className='user-edit-modal-input-field'
                      onInput={(e) =>
                        updateUserInfo({ phone_number: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className='user-edit-modal-input-group'>
                    <label htmlFor="company" className='user-edit-modal-input-label'>{t('project.company')}</label>
                    <input
                      id='company'
                      type='text'
                      value={userInfo.company}
                      className='user-edit-modal-input-field'
                      onInput={(e) =>
                        updateUserInfo({ company: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className='user-edit-modal-input-group row'>
                    <label htmlFor="company" className='user-edit-modal-input-label row'>{t('account.emailList')}</label>
                    <input
                      type="checkBox"
                      id='email_list'
                      checked={userInfo.email_subscribe === true}
                      onChange={(e) => updateUserInfo({ email_subscribe: e.target.checked ? true : false })}
                    />
                  </div>
                  {errorList.length > 0 && (
                    <div className='edit-user-information-error-container'>
                      {errorList.map((error) => {
                        return (
                          <div className='edit-user-information-error-entry' key={error}>
                            - {error}
                          </div>
                        )
                      })}
                    </div>
                  )}
                  </div>
                <div className='user-edit-modal-btn-group'>
                  <CustomButton
                    button_text={t('account.save').toUpperCase()}
                    primary={true}
                    onClickHandler={() => handleSaveBtnClick()}
                  />
                  <CustomButton transparent={true} button_text={t('account.cancel').toUpperCase()} onClickHandler={() => close()} />
                </div>
              </>
            )
          }

            
        </div>
      </div>
    )
  )
}

export default EditUserInformationModal
