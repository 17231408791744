import './styles.scss'

import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import CustomButton from '../../../../../../components/common/CustomButton'
import { use } from 'react'

const assignmentFilterOptions = [
  {
    id: 'all',
    name: 'All',
    count: 'all',
  },
  {
    id: 1,
    name: 'Assigned',
    count: 'assigned',
  },
  {
    id: 2,
    name: 'Unassigned',
    count: 'unassigned',
  },
]

const stateFilterOptions = [
  {
    id: 'all',
    name: 'All',
    count: 'all',
  },
  {
    id: 1,
    name: 'Open',
    count: 'open',
  },
  {
    id: 2,
    name: 'In Progress',
    count: 'inProgress',
  },
  {
    id: 3,
    name: 'Offer Sent',
    count: 'offerSent',
  },
  {
    id: 4,
    name: 'Offer Accepted',
    count: 'offerAccepted',
  },
  {
    id: 5,
    name: 'Offer Rejected',
    count: 'offerRejected',
  },
  {
    id: 6,
    name: 'Guide Sent',
    count: 'guideSent',
  },
  {
    id: 7,
    name: 'Closed',
    count: 'closed',
  },
]

const InquiriesFilter = (props) => {
  const {
    filter,
    stateFilter,
    clickFilter,
    setStateFilter,
    count = 'all',
    type = 'contact',
  } = props

  useEffect(() => {
    // Default filter for guide open inquiries
    if (type === 'guide') {
      setStateFilter(1)
    }
  }, [type])

  const inquiry_count = useSelector((state) => state.management.management_count)
  const current_user = useSelector((state) => state.user.current_user)
  const btnStyle = {
    padding: '0.25rem 1rem',
  }

  const get_count_by_type = () => {
    if (filter === 'all') {
      return inquiry_count[count]
    }
    else if (filter === 'assigned') {
      return inquiry_count.assigned
    }
    else if (filter === 'unassigned') {
      return inquiry_count.unassigned
    }
  }

  return (
    <>
      {type !== 'guide' && [1, 3].includes(current_user.role) && ( 
        <div className='inquiries-filter-main'>
          { assignmentFilterOptions.map((option) => {
              if (option.count === 'all') {
                return (
                  <React.Fragment key={option.id}>
                    <CustomButton
                      transparent
                      onClickHandler={() => clickFilter(option.count)}
                      button_text={`${option.name.toUpperCase()} (${inquiry_count[count]})`}
                      selected={filter === option.id}
                      customStyles={btnStyle}
                    />
                  </React.Fragment>
                )
              } else {
                return (
                  <React.Fragment key={option.id}>
                    <CustomButton
                      transparent
                      onClickHandler={() => clickFilter(option.count)}
                      button_text={`${option.name.toUpperCase()} (${inquiry_count[option.count]})`}
                      selected={filter === option.count}
                      customStyles={btnStyle}
                    />
                  </React.Fragment>
                )
              }
            })
          }
        </div>
      )}

      <div className='inquiries-filter-sub'>
        { stateFilterOptions.map((option) => {
            if (option.id === 'all') {
              return (
                <React.Fragment key={option.id}>
                  <CustomButton
                    purple
                    onClickHandler={() => setStateFilter(option.id)}
                    button_text={`${option.name.toUpperCase()} (${get_count_by_type()})`}
                    selected={stateFilter === option.count}
                    customStyles={btnStyle}
                  />
                </React.Fragment>
              )
            } 
            if (type !== 'guide' && option.id === 6) {
              return null
            }
            else if (type === 'guide' && !['all', 'new', 1, 6].includes(option.id)) {
              return null
            }
            else {
              return (
                  <React.Fragment key={option.id}>
                    <CustomButton
                      purple
                      onClickHandler={() => setStateFilter(option.id)}
                      button_text={`${option.name.toUpperCase()} (${inquiry_count[option.count]})`}
                      selected={stateFilter === option.id}
                      customStyles={btnStyle}
                    />
                  </React.Fragment>
                )
            }
          })
        }
      </div>
    </>
  )
}

export default InquiriesFilter