const reference = {
  headerTitle: 'Referenssit',
  headerTitleSingular: 'REFERENSSI',
  filter: 'SUODATA',
  clear: 'TYHJENNÄ',
  toimisto: 'Toimisto',
  julkinenTila: 'Julkinen tila',
  ulkoalue: 'Ulkoalue',
  koivuTuotteet: 'Koivu-tuotteet',
  viherseinat: 'Viherseinät',
  viherkalusteet: 'Viherkalusteet',
  vihersisustus: 'Vihersisustus',
  huoltovapaat: 'Huoltovapaat',
  page: 'Sivu',
  next: 'Seuraavat',
  previous: 'Edelliset',
  noReferenceFound: 'Referenssit ei löytynyt',
  noReferenceFoundWithFilter: 'Referenssit ei löytynyt valituilla suodattimilla',
  filterBtns: [
    {
      tag: 'TOIMISTO',
      text: 'TOIMISTO',
      type: 'referenceGroup',
    },
    {
      tag: 'JULKINEN TILA',
      text: 'JULKINEN TILA',
      type: 'referenceGroup',
    },
    {
      tag: 'ULKOALUE',
      text: 'ULKOALUE',
      type: 'referenceGroup',
    },
    {
      tag: 'KOIVU-TUOTTEET',
      text: 'KOIVU-TUOTTEET',
      type: 'productGroup',
    },
    {
      tag: 'VIHERSEINÄT',
      text: 'VIHERSEINÄT',
      type: 'productGroup',
    },
    {
      tag: 'VIHERSISUSTUS',
      text: 'VIHERSISUSTUS',
      type: 'productGroup',
    },
    {
      tag: 'VIHERKALUSTEET',
      text: 'VIHERKALUSTEET',
      type: 'productGroup',
    },
  ],
}

export default reference