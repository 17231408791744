const work = {
  headerTitle: 'Work at InnoGreen',
  introTitle: `Have you dreamed of a career in the greenery industry? InnoGreen 
      offers a unique opportunity to be part of a remarkable team, working 
      together to create a greener future. We employ around 50 greenery 
      professionals on a permanent basis in our offices in Helsinki, Turku and 
      Tampere. Our team consists of permanent employees, seasonal workers and 
      trainees at the beginning of their careers.`,
  introText: `We work every day to ensure that our employees are happy, develop 
      and thrive while working for us. We want our employees to take pride in 
      their workplace and one of our goals is to be the most responsible 
      employer in the industry.
      \\n
      We offer a safe, equal and stimulating working environment where everyone 
      has the opportunity to develop and build a career in interesting jobs. 
      Our aim is that once you work for us, you will never want to leave.`,
  cards: [
    {
      title: '50',
      text: 'greenery professionals',

    },
    {
      title: '6 years',
      text: 'Our current employees’ average career with us',
    },
  ],
  introTitle2: `How do we strive to be the most responsible employer in the industry?`,
  workCards: [
    {
      title: 'Our employees are our most important asset',
      text: `We offer competitive salaries based on experience, skills and responsibility. 
          Every employee is also offered modern and flexible employment benefits that 
          support well-being, motivation and work-life balance. When recruiting new 
          employees, we are open about the salary range in the job advertisement.
          \\n
          Although we operate in a seasonal sector, we aim to offer year-round employment. 
          This gives our employees security and the opportunity for rewarding and long-term work.`
    },
    {
      title: 'We are different and therefore so good together',
      text: `Each of our employees has the opportunity to develop their own skills and interests. 
          We provide ongoing training and development opportunities so that professional growth 
          can continue throughout your career.
          \\n
          It is important to us to maintain a humane working culture where everyone feels heard, 
          seen and valued. We are a low-hierarchy company where team spirit, interaction, 
          equality and working together are key to job satisfaction and shared success. We are 
          committed to supporting diversity, equality and inclusiveness. We let everyone be themselves`
    },
  ],
  introTitle3: `Job vacancies`,
  introText3: `If we don't have a vacancy that suits you right now, please feel free to send us an open application to`,
  introEmail: 'rekry@innogreen.fi.',
  noAvailableJobs: 'Oh shoot! Currently we don’t have any vacancies.',
  introTitle4: `But what's it like to work for us? Let your future colleagues tell you!`,
  employeeCard: [
    {
      employeeName: 'Kukka',
      title: 'plant caretaker and Health and safety representative',
      text: `”InnoGreen is one of the best places I've ever worked. Caring for plants gives 
          me a sense of achievement almost every day and allows me to use my problem-solving 
          skills. For example, it's very rewarding to see how persistent care can make even 
          the most unhealthy plant flourish again!”`,
      yearText: 'At InnoGreen since',
      year: '2015',
    },
    {
      employeeName: 'Leena',
      title: 'green designer',
      text: `“I feel that InnoGreen truly stands behind its values and has been exactly what 
          I had imagined as a workplace. We don't just sell plants, we sell a service offering 
          and green experiences. Successful implementation requires thorough attention to 
          detail from everyone and I value the skills of each and every InnoGreen employee 
          immensely. Here, employees are taken care of and everyone is on the same page. We 
          respect and help each other and there is a really good atmosphere.”`,
      yearText: 'At InnoGreen since',
      year: '2022',
    },
    {
      employeeName: 'Kristina',
      title: 'yard maintenance manager',
      text: `”I enjoy that my work is varied and multifaceted - the days can include everything 
          from planning plantings to ordering, plant care as well as yard maintenance. I’m able 
          to influence my own work and to act independently. My favorite task is seasonal plant 
          design, where I get to use my creativity and apply my years of experience in the 
          gardening industry. InnoGreen is an encouraging employer, and we have a wonderful 
          team spirit.”`,
      yearText: 'At InnoGreen since',
      year: '2018',
    },
    {
      employeeName: 'Teijo',
      title: 'warehouse supervisor',
      text: `”I started studying greenery in 1998 and I was immediately fascinated by the plants’ 
          soul life. The psychological effect of plants on people is undeniable - we feel more 
          relaxed when surrounded by greenery, whether it is at work, at home, in the woods or even 
          in the mall. The best thing about working as a warehouse technician and installer is the 
          versatility of the work. Every work week is different - hectic work at different sites is 
          balanced by the days spent in our greenhouse, when I get to focus on planting and other 
          preparatory work.”`,
      yearText: 'At InnoGreen since',
      year: '2017',
    },
    {
      employeeName: 'Kirsi',
      title: 'green designer',
      text: `”My work is fast-paced and varied - it includes just the right balance of creative 
          brainstorming and routine work. I experience a great sense of success when I get 
          customers excited about green decor and they realize how big an impact plants have on 
          the space. The most rewarding part of my job is working with clients, co-workers and 
          partners - the best ideas are born in a group and the result is always better than 
          when working alone.”`,
      yearText: 'At InnoGreen since',
      year: '2012',
    }
  ]
}

export default work