import './styles.scss'
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import InquiriesFilter from './components/InquiriesFilter/InquiriesFilters'
import InquiriesTableToolbar from './components/InquiriesTableToolbar/InquiriesTableToolbar'
import InquiriesTableHeader from './components/InquiriesTableHeader/InquiriesTableHeader'
import InquiriesRow from './components/InquiriesRow/InquiriesRow'
import NewInquiry from '../Dashboard/NewInquiry'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress
} from "@mui/material"

import {
  fetch_inquiries,
  count_inquiry,
} from '../../../../store/asyncThunk'

function descendingComparator(a, b, orderBy, order) {
  let valueA = a[orderBy]
  let valueB = b[orderBy]

  // Handle empty strings and null values by moving them to the bottom
  if (valueA === null || valueA === '') {
    valueA = undefined
  }
  if (valueB === null || valueB === '') {
    valueB = undefined
  }

  // For strings, convert to lowercase for case-insensitive comparison
  if (typeof valueA === 'string') {
    valueA = valueA.toLowerCase()
  }
  if (typeof valueB === 'string') {
    valueB = valueB.toLowerCase()
  }

  // Comparison logic for sorting null/empty values, always move them to the bottom
  if (order === 'desc') {
    if (valueB === undefined) return -1 // Move null/empty to the bottom
    if (valueA === undefined) return 1  // Move null/empty to the bottom
  } else {
    if (valueB === undefined) return 1  // Move null/empty to the bottom
    if (valueA === undefined) return -1 // Move null/empty to the bottom
  }

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function descendingPriorityComparator(a, b, orderBy, order) {
  let valueA = a[orderBy]
  let valueB = b[orderBy]

  const isAOverdue = a['deadline'] && new Date(a['deadline']) < new Date()
  const isBOverdue = b['deadline'] && new Date(b['deadline']) < new Date()

  // Show overdue first always if sorting by Priority
  if (order === 'desc') {
    if (isAOverdue && isBOverdue) {
      if (valueA > valueB) {
        valueA = 12
        valueB = 11
      } else if (valueA < valueB) {
        valueA = 11
        valueB = 12
      } else {
        valueA = 11
        valueB = 11
      }
    } else if (isAOverdue && !isBOverdue) {
      valueA = 11
    } else if (!isAOverdue && isBOverdue) {
      valueB = 11
    }
  } else {
    if (isAOverdue && isBOverdue) {
      if (valueA > valueB) {
        valueA = 12
        valueB = 11
      } else if (valueA < valueB) {
        valueA = 11
        valueB = 12
      } else {
        valueA = 11
        valueB = 11
      }
    } else if (isAOverdue && !isBOverdue) {
      valueA = 0
    } else if (!isAOverdue && isBOverdue) {
      valueB = 0
    }
  }

  // if same value sort by date_created
  if (valueB === valueA) {
    valueA = new Date(a['date_created'])
    valueB = new Date(b['date_created'])
    // Always show oldest first date first
    if (order === 'desc') {
      if (valueB < valueA) {
        return 1
      }
      if (valueB > valueA) {
        return -1
      }
      return 0
    } else {
      if (valueB < valueA) {
        return -1
      }
      if (valueB > valueA) {
        return 1
      }
      return 0
    }
  }

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function descendingDateComparator(a, b, orderBy) {
  let valueA = new Date(a[orderBy]);
  let valueB = new Date(b[orderBy]);

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function descendingStateComparator(a, b, orderBy) {
  const valueA = a[orderBy]
  const valueB = b[orderBy]

  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  if (orderBy === 'priority') {
    return order === 'desc'
      ? (a, b) => descendingPriorityComparator(a, b, orderBy, order)
      : (a, b) => -descendingPriorityComparator(a, b, orderBy, order)
  } else if (orderBy === 'date_created') {
    return order === 'desc'
      ? (a, b) => descendingDateComparator(a, b, orderBy)
      : (a, b) => -descendingDateComparator(a, b, orderBy)
  } else if (orderBy === 'state') {
    return order === 'desc'
      ? (a, b) => descendingStateComparator(a, b, orderBy)
      : (a, b) => -descendingStateComparator(a, b, orderBy)
  } else {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy, order)
      : (a, b) => -descendingComparator(a, b, orderBy, order)
  }
}

const dropdownOptions = [
  {
    id: 1,
    name: 'Past month',
    short_name: 'month',
  },
  {
    id: 2,
    name: 'Past 3 months',
    short_name: '3months',
  },
  {
    id: 3,
    name: 'Past year',
    short_name: 'year',
  },

]

const DynamicInquiry = (props) => {
  const {
    assignableUsers,
    setSelected,
    handleSetSelectedTab,
    handleSetSelectedSelection,
    type = 'contact',
    title = 'Contact',
    deadline = true,
  } = props
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const inquiries = useSelector((state) => state.management.inquiries)
  const count = useSelector((state) => state.management.management_count)
  const [filter, setFilter] = useState('all')
  // const [typeFilter, setTypeFilter] = useState('project')
  const [stateFilter, setStateFilter] = useState('all')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('priority')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedFilter, setSelectedFilter] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      await Promise.all([
        dispatch(count_inquiry({type: type, filter: filter})),
        dispatch(fetch_inquiries({type: type, state: stateFilter, date: selectedFilter?.short_name, filter: filter}))
      ])
      setIsLoading(false)
    })()
  }, [filter, stateFilter, selectedFilter, type])

  const clickFilter = (type) => {
    setFilter(type)
  }

  const visibleRows = useMemo(
    () =>
      [...inquiries]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, inquiries],
  )

  return (
      <div className='inquiries-container'>
        { count && count.isNew > 0 && (
            <NewInquiry
              assignableUsers={assignableUsers}
              setSelected={setSelected}
              handleSetSelectedTab={handleSetSelectedTab}
              handleSetSelectedSelection={handleSetSelectedSelection}
              phone={true}
              company={true}
              city={true}
              name={true}
              message={false}
              priority={true}
              rowsPerPageOptions={[5, 10, 25]}
              rowsPerPageDefault={5}
              size='small'
              title={`New Assigned Inquiries (${count.isNew})`}
              defaultOrderBy='priority'
              defaultOrderDirection='desc'
            />
          )
        }
        <div className='inquiries-filter-container'>
          <InquiriesFilter
            type={type}
            filter={filter}
            stateFilter={stateFilter}
            clickFilter={clickFilter}
            setStateFilter={setStateFilter}
            count={type}
          />
        </div>

        <Paper sx={{ width: '100%', mb: 2, borderRadius: 0  }}>
          {/* Enhanced Toolbar */}
          <InquiriesTableToolbar
            title={title}
            search={true}
            filter={true}
            dropdownOptions={dropdownOptions}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />

          <TableContainer
            elevation={0}
          >
            <Table>
              <InquiriesTableHeader
                order={order}
                orderBy={orderBy}
                handleRequestSort={handleRequestSort}
                date={true}
                priority={true}
                name={true}
                email={true}
                phone={true}
                company={true}
                city={true}
                message={false}
                state={true}
                deadline={deadline}
              />
              <TableBody
                className='contact-request-table-body'
              >
                {isLoading ?
                  (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <div style={{ height: rowsPerPage * 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    visibleRows.map((item, index) => (
                      <InquiriesRow
                        assignableUsers={assignableUsers}
                        key={item.id}
                        item={item}
                        index={index}
                        stateFilter={stateFilter}
                        handleSetSelectedSelection={handleSetSelectedSelection}
                        date={true}
                        priority={true}
                        name={true}
                        email={true}
                        phone={true}
                        company={true}
                        city={true}
                        message={false}
                        state={true}
                        deadline={deadline}
                        collapsible={true}
                      />
                    ))
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={inquiries.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
  )
}

export default DynamicInquiry
