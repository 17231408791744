import './styles.scss'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatches } from 'react-router-dom'

import HeaderBox from '../../components/HeaderBox'
import InfoCardGroup from '../../components/InfoCardGroup'
import InfoBox from '../../components/InfoBox'
import { Design, Install, Care } from '../../components/CardIcon'

import infoBox1ImgSrc from '../../assets/images/GreenInterior/vihersisustus-1.jpg'
import infoBox2ImgSrc from '../../assets/images/GreenInterior/vihersisustus-2.jpg'
import infoBox3ImgSrc from '../../assets/images/GreenInterior/vihreydesta-hyvivointia-johdatus.jpg'
import infoBox4ImgSrc from '../../assets/images/GreenInterior/vihersisustus-3.jpg'
import infoBox5ImgSrc from '../../assets/images/GreenInterior/vihersisustus-4.jpg'
import infoBox6ImgSrc from '../../assets/images/GreenInterior/vihreys-palveluna-johdatus.jpg'

const cards = [
  {
    title: 'greenInterior.infoCard1Title',
    body: [
      { text: 'greenInterior.infoCard1Text1' },
      { text: 'greenInterior.infoCard1Text2' },
      { text: 'greenInterior.infoCard1Text3' },
    ],
    icon: <Design/>
  },
  {
    title: 'greenInterior.infoCard2Title',
    body: [
      { text: 'greenInterior.infoCard2Text1' },
      { text: 'greenInterior.infoCard2Text2' },
      { text: 'greenInterior.infoCard2Text3' },
    ],
    icon: <Install/>
  },
  {
    title: 'greenInterior.infoCard3Title',
    body: [
      { text: 'greenInterior.infoCard3Text1' },
      { text: 'greenInterior.infoCard3Text2' },
      { text: 'greenInterior.infoCard3Text3' },
    ],
    icon: <Care/>
  },
]

const GreenInterior = () => {
  const { t, i18n } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  return (
    <>
      <div className='green-interior-container'>
        <div className='green-interior-body-container'>
          <HeaderBox
            titleText={t('greenInterior.headerTitle')}
            pageText={t('greenInterior.headerGroup').toUpperCase()}
            videoSrc='https://vimeo.com/1046356084'
            titleBackgroundColor='purple'
          />
          <InfoCardGroup cards={cards} />
          <InfoBox
            title={t('greenInterior.infoBoxTitle1')}
            imgSrc={infoBox1ImgSrc}
            text={t('greenInterior.infoBoxText1')}
          />
          <InfoBox
            title={t('greenInterior.infoBoxTitle2')}
            imgSrc={infoBox2ImgSrc}
            reverse={true}
            text={t('greenInterior.infoBoxText2')}
          />
          <InfoBox
            title={t('greenInterior.infoBoxTitle3')}
            imgSrc={infoBox3ImgSrc}
            type='2'
            text={t('greenInterior.infoBoxText3')}
            link={i18n.language === 'FI' ? 'vihreydesta_hyvinvointia' : 'greenery-for-well-being'}
            buttonText={t('greenInterior.infoBoxLink3').toUpperCase()}
          />
          <InfoBox
            title={t('greenInterior.infoBoxTitle4')}
            imgSrc={infoBox4ImgSrc}
            text={t('greenInterior.infoBoxText4')}
          />
          <InfoBox
            title={t('greenInterior.infoBoxTitle5')}
            imgSrc={infoBox5ImgSrc}
            reverse={true}
            text={t('greenInterior.infoBoxText5')}
          />
          <InfoBox
            title={t('greenInterior.infoBoxTitle6')}
            imgSrc={infoBox6ImgSrc}
            type='2'
            text={t('greenInterior.infoBoxText6')}
            buttonText={t('common.greenService').toUpperCase()}
            backgroundColor='purple'
            link={i18n.language === 'FI' ? 'vihreys_palveluna' : 'green-as-a-service'}
          />
        </div>
      </div>
    </>
  )
}

export default GreenInterior
