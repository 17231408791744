import './styles.scss'
import { Close } from '../Icons'
import { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import { remove_product_from_project } from '../../store/asyncThunk'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const DeleteAssignedProductModal = ({ showing, closeModal, product }) => {
  const dispatch = useDispatch()
  const [errorList, setErrorList] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    setErrorList([])
    if (showing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showing])

  const close = () => {
    document.body.style.overflow = 'unset'
    closeModal()
  }

  const handleDeleteClick = async () => {
    await dispatch(
      remove_product_from_project({
        product_id: product.id,
        project_id: product.project_id,
      })
    ).then((res) => {
      if (!res.error) {
        setErrorList([])
        close()
      } else {
        setErrorList([t('project.genericDeleteProductError')])
      }
    })
  }

  return (
    showing &&
    product && (
      <div className='delete-assigned-product-modal-root'>
        <div className='delete-assigned-product-modal-container'>
          <div className='delete-assigned-product-modal-header'>
            <div className='delete-assigned-product-modal-header-exit-btn-container'>
              <div
                className='delete-assigned-product-modal-header-exit-btn'
                onClick={() => {
                  close()
                }}
              >
                <Close size={20} />
              </div>
            </div>
          </div>

          <div className='delete-assigned-product-modal-content'>
            <div className='delete-assigned-product-modal-header-title'>
              {t('project.deleteConfirmation')}
            </div>
            {errorList.length > 0 && (
              <div className='delete-assigned-product-modal-error-container'>
                {errorList.map((error) => {
                  return (
                    <div className='delete-assigned-product-modal-error-entry' key={error}>
                      - {error}
                    </div>
                  )
                })}
              </div>
            )}
            <div className='delete-assigned-product-modal-text'>
              {t('project.confirmDeleteProduct')} {product.name}!
            </div>
          </div>

          <div className='delete-assigned-product-modal-btn-group'>
            <CustomButton
              button_text={t('project.delete').toUpperCase()}
              danger
              onClickHandler={() => handleDeleteClick()}
            />
            <CustomButton button_text={t('project.cancel').toUpperCase()} onClickHandler={() => close()} />
          </div>
        </div>
      </div>
    )
  )
}

export default DeleteAssignedProductModal
