const project = {
  backToMyAccount: 'SE Takaisin tiliini',
  dateCreated: 'SE Luotu pvm.',
  dateModified: 'SE Muokattu pvm.',
  status: 'SE Tila',
  editProjectInformation: 'SE Muokkaa projektin kuvausta',
  duplicateProject: 'SE Luo kopio projektista',
  deleteProject: 'SE Poista projekti',
  open: 'SE Avoin',
  pending: 'SE Odottaa',
  closed: 'SE Suljettu',
  unknown: 'SE Tuntematon',
  openProductSummary: 'SE Avaa projektin koonti',
  printProjectSummary: 'SE Voit tulostaa projektin koontisivulta',
  view: 'SE Katso',
  dateAdded: 'SE Lisätty pvm.',
  productName: 'SE Tuotteen nimi',
  allSpaces: 'SE Kaikki tilat',
  defaultSpace: 'SE Oletustila',
  editSpaces: 'SE Muokkaa tiloja',
  sort: 'SE Järjestä',
  product: 'SE Tuote',
  quantity: 'SE Määrä',
  addNewProduct: 'SE Lisää uusi tuote',
  noProductFound: 'SE Tuotetta ei löydy',
  sendInquiry: 'SE Lähetä tarjouspyyntö',
  total: 'SE Yhteensä',
  products: 'SE Tuotteet',
  editProject: 'SE Muokkaa projektia',
  projectName: 'SE Projektin nimi',
  description: 'SE Kuvaus',
  confirm: 'SE Vahvista',
  cancel: 'SE Peruuta',
  duplicatedProjectName: 'SE Kopioi projektin nimi',
  deleteConfirmation: 'SE Poiston vahvistus',
  confirmDelete: 'SE Vahvista projektin poisto',
  delete: 'SE Poista',
  newInquiry: 'SE Uusi tarjouspyyntö',
  yourName: 'SE Nimi',
  phoneNumber: 'SE Puhelinnumero',
  email: 'SE Sähköposti',
  send: 'SE Lähetä',
  createNewSpace: 'SE Luo uusi tila',
  nameOfSpace: 'SE Tilan nimi',
  add: 'SE Lisää',
  rename: 'SE Nimeä uudelleen',
  editSpaceName: 'SE Muokkaa tilan nimeä',
  ok: 'SE Ok',
  deleteSpace: 'SE Poista tila',
  confirmDeleteSpace: 'SE Vahvista tilan poisto',
  addToProject: 'SE Lisää projektille',
  addToNewProject: 'SE Lisää uudelle projektille',
  searchForProject: 'SE Etsi projektia',
  yourSelection: 'SE Valintasi',
  productOverview: 'SE Tuotekatsaus',
  editYourSelection: 'SE Muokkaa valintaasi',
  closeEditView: 'SE Sulje muokkausnäkymä',
  quantity: 'SE Määrä',
  removeProduct: 'SE Poista tuote',
  createNewVersion: 'SE Luo uusi versio',
  copyToAnotherProject: 'SE Kopioi toiseen projektiin',
  copyToAnotherSpace: 'SE Kopioi toiseen tilaan',
  backToProject: 'SE Takaisin projektiin',
  configurateThisProduct: 'SE Muokkaa tuotetta',
  offerInquired: 'Offer inquired',
  genericInquiryError: 'Failed to send inquiry, please try again',
  editProjectSuccessMessage: 'Edited project!',
  genericEditProjectError: 'Failed to edit project, please try again!',
  close: 'Close',
  genericDuplicateProjectError: 'Failed to duplicate project, please try again!',
  duplicateProjectSuccessMessage: 'Duplicated project!',
  genericDeleteProjectError: 'Failed to delete project, please try again!',
  genericAddSpaceError: 'Failed to create new space, please try again!',
  genericDeleteSpaceError: 'Failed to delete space, please try again!',
  genericRenameSpaceError: 'Failed to rename space, please try again!',
  genericAddProjectError: 'Failed to create new project, please try again!',
  genericDeleteProductError: 'Failed to delete product, please try again!',
}

export default project