const raataloitava_sammalseina = {
  name: 'Customisable Moss Wall',
  longDescription: `Maintenance and irrigation free, moss and lichen are 
      a decorative element with the feel of nature and the forest, 
      suitable for demanding spaces. We use stabilised, biodegradable 
      moss and lichen in our panels, installations and other custom-made 
      designs.
      \\n
      Stabilised plants are plants that have been preserved. The 
      stabilisation process uses glycerine and, despite the treatment, 
      the plants are recyclable and biodegradable. The process can be 
      used to preserve real plants and bring them into more challenging 
      environments. Stabilised mosses, lichens and plants will keep 
      their shape and beautiful colour for years.
      \\n
      Stabilised plants or leaves and other natural elements can be 
      attached to the moss wall to add three-dimensionality. A base 
      of any shape can be cut out under the moss, allowing you to 
      draw letters, a logo or even a map of the world on the wall. 
      Moss and stabilised plants are a good option when you want 
      greenery on the ceiling or other places where it is difficult 
      to safely arrange maintenance of the greenery. Moss walls do not 
      necessarily require a light, but when properly lit they are a real 
      eye-catcher.`,
  measurements: [
    {
      type: 'Tailor-made walls can be made to the exact dimensions and shapes required by the customer',
      value: ''
    }
  ],
  materials: [
    {
      type: 'Material:',
      value: 'Maintenance-free stabilised moss or lichen (EU)'
    },
    {
      type: 'Base material:',
      value: 'Pressed fibreboard'
    },
    {
      type: 'Usage:',
      value: 'Indoor',
      icon: 'indoor'
    },
  ],
  wlt: {},
  configuration: [
    {
      type: 'räätälöitävä_sammalseinä',
      configType:'width',
      text: 'Moss wall width (mm)',
      valueText: 'Width',
    },
    {
      type: 'räätälöitävä_sammalseinä',
      configType:'height',
      text: 'Moss wall height (mm)',
      valueText: 'Height',
    },
    {
      type: 'räätälöitävä_sammalseinä',
      configType:'custom',
      text: '',
      valueText: '',
    }
  ],
  leveys: {
    description: `Width of the moss wall`
  },
  korkeus: {
    description: `Height of the moss wall`
  }, 
  kustomointi: {
    description: `A more detailed description of the shape and the materials used`
  },
}

export default raataloitava_sammalseina