import './styles.scss'

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useMatches } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { setFilters, setPage } from '../../store/referenceSlice'
import LoadingScreen from '../../components/common/LoadingScreen'
import CustomButton from '../../components/common/CustomButton'
import CustomCard from '../../components/common/CustomCard'
import { AccordionClose } from '../../components/Icons'
import { ArrowLeft, ArrowRight } from '../../components/Icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'

import { setGlobalLoading } from '../../store/uiSlice'

import { fetch_references, fetch_reference_groups } from '../../store/asyncThunk'

const References = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const global_loading = useSelector((state) => state.ui.global_loading)
  const [isLoading, setIsLoading] = useState(true)
  const { references, currentPage, selectedFilters, referenceGroupFetched, pages } = useSelector((state) => state.reference)
  const { t, i18n } = useTranslation()
  const referenceGroup = useSelector((state) => state.reference.referenceGroup)
  const mergedReferenceGroup = [...referenceGroup.productGroup, ...referenceGroup.referenceGroup];
  const current_user = useSelector((state) => state.user.current_user)

  const matches = useMatches()
  const routeLanguage = matches.find(m => m.handle)?.handle?.language
  const storedLanguage = i18n.language

  useEffect(() => {
    // Change the language if the route language is different from the stored language
    if (storedLanguage !== routeLanguage) {
      i18n.changeLanguage(routeLanguage);
    }
  }, [routeLanguage])

  useEffect(() => {
    const loadData = async () => {
      if (!referenceGroupFetched) {
        dispatch(setGlobalLoading(true))
        await dispatch(fetch_reference_groups())
        dispatch(setGlobalLoading(false))
      } else {
        setIsLoading(true)
        const filterQuery = selectedFilters.length > 0 ? selectedFilters.join(',') : null;
        await dispatch(fetch_references({ page: currentPage, filters: filterQuery }))
        setIsLoading(false)
      }
    }
    loadData()
  }, [dispatch, currentPage, selectedFilters, referenceGroupFetched]) 

  const handleFilterChange = (filter) => {
    const newFilters = selectedFilters.includes(filter)
      ? selectedFilters.filter((f) => f !== filter)
      : [...selectedFilters, filter]
    dispatch(setFilters(newFilters))
    dispatch(setPage(1))
  }

  const clearFilter = () => {
    dispatch(setFilters([]))
  }
  const handlePageChange = (newPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // Wait for the scroll animation
    setTimeout(() => {
      dispatch(setPage(newPage));
    }, 600)
  }

  const handleReferenceClick = (reference) => {
    if (i18n.language === 'FI')
      navigate('/referenssit/' + reference.slug)
    if (i18n.language === 'EN')
      navigate('/references/' + reference.slug)
  }

  const btnStyle = {
    padding: '0.15rem 1rem',
  }

  if (global_loading) {
    return (
      <>
        <LoadingScreen/>
        <div className='references-container'></div>
      </>
    )
  }

  return (
    <div className='references-container'>
      <div className='references-title-container'>
        <div className='references-title-body-container'>
          <div className='references-title'>{t('reference.headerTitle')}</div>
          <div className='references-filter-container'>
            {/* Category Filter */}
            <div className='references-filter-text'>{t('reference.filter').toUpperCase()}</div>
            <div className='references-filter-button-container'>
              <div className='references-filter-type-container'>
              { mergedReferenceGroup && mergedReferenceGroup.map((btn, index) => {
                  return (
                    <React.Fragment key={index}>
                      {index === 3 ? (
                        <div className='references-filter-divider'></div>
                      ) : null}
                      <CustomButton
                        button_text={t(btn.translate).toUpperCase()}
                        customStyles={btnStyle}
                        contentClass='references-filter-btn'
                        onClickHandler={() => handleFilterChange(btn.tag)}
                        white={true}
                        selected={selectedFilters.includes(btn.tag)}
                      />
                    </React.Fragment>
                  )
                })
              }
               {
                  selectedFilters && selectedFilters.length !== 0 ?
                  (
                    <CustomButton
                      button_text={t('reference.clear').toUpperCase()}
                      customStyles={btnStyle}
                      transparent={true}
                      icon={<AccordionClose/>}
                      contentClass='references-filter-btn'
                      onClickHandler={() => clearFilter()}
                    />
                  ) : (
                    <></>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='references-card-container'>
        <div className='references-card-body-container'>
          { isLoading ?
            (
              <LoadingScreen />
            ) : (
              <>
              {
                references && references.length !== 0 ?
                (
                  references && references.map((reference, index) => (
                    <div key={reference.id} className='references-card no-select'>
                      <CustomCard
                        { ...reference }
                        type='reference'
                        onClickHandler={() => handleReferenceClick(reference)}
                      />
                      {current_user && current_user.role === 1 ?
                        (
                          <div className='references-card-edit-icon' onClick={() => navigate(`/referenssit/${reference.slug}/editor`)}>
                            <FontAwesomeIcon icon={faPenToSquare} size='xl'/>
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    </div>
                  ))
                ) : (
                  <>
                  {
                    selectedFilters && selectedFilters.length === 0 ?
                    (
                      <div className='references-card-body-no-blogs'>
                        {t('reference.noReferenceFound')}
                      </div>
                    ) : (
                      <div className='references-card-body-no-blogs'>
                        {t('reference.noReferenceFoundWithFilter')}
                      </div>
                    )
                  }
                  </>
                )
              }
              </>
            )

          }
          
        </div>
      </div>

      {isLoading ?
        (
          <></>
        ) : (
          <>
            { references && references.length !== 0 ?
              (
                <div className='references-pagination-container'>
                  <div className='references-pagination-btn-container'>
                    <div
                      className={currentPage === 1 ? 'references-pagination-btn disabled no-select' : 'references-pagination-btn no-select'}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <ArrowLeft size={30}/> {t('reference.previous')}
                    </div>
                    <div className='references-pagination-btn-divider'></div>
                    <div
                      className={currentPage === pages ? 'references-pagination-btn disabled no-select' : 'references-pagination-btn no-select'}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      {t('reference.next')} <ArrowRight size={30}/>
                    </div>
                  </div>
                  <div className='references-pagination-page'>
                    {t('reference.page').toUpperCase()}&nbsp;{currentPage} / {pages}
                  </div>
                </div>
              ) : (
                <></>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default References
