const viherseina = {
  name: 'SE Viherseinä',
  longDescription: `SE Our lightweight, metal-framed green wall is suitable for 
      both indoor and outdoor use. The metal frame green wall is self-standing 
      and does not require a water connection or wall fixing, allowing it to 
      be placed anywhere. The metal frame green wall is watered by our 
      professional service team.
      \\n
      Our metal-framed green wall is a compact option that also fits into a 
      smaller space and can, for example, be rented for an event only. 
      Over the years, the metal frame green wall has been used as a partition 
      in lobbies, as a refreshing decorative element in office gyms and at 
      festivals with plants according to season.
      \\n
      For the outdoor green wall, perennial plants or seasonal plants are 
      selected according to your wishes, bringing enjoyment to your outdoor 
      space all year round. Typically, in spring and summer, floral 
      arrangements are made for entrances and courtyards, and an herb garden 
      for recreational areas. In the darker seasons, conifers and seasonal 
      lighting can be placed on the wall.`,
  measurements: [
    {
      type: 'SE Width:',
      value: '1200 mm eller 1800 mm'
    },
    {
      type: 'SE Height:',
      value: '2100 mm, 2270 mm eller 2430 mm'
    },
  ],
  materials: [
    {
      type: 'SE Frame:',
      value: 'Metal'
    },
    {
      type: 'SE Green wall modules:',
      value: 'Abs plastic, made in Kaarina'
    },
    {
      type: 'SE Plants:',
      value: 'Finland and EU'
    },
    {
      type: 'Country of manufacture:',
      value: 'Finland'
    },
    {
      type: 'SE Plant lights:',
      value: `We use the latest technology LED lights. 
          The spectrum of the bulb matches the spectrum of daylight, 
          highlighting the plants while giving them just the right 
          amount of light they need.`
    },
    {
      type: 'SE Usage:',
      value: 'Indoor and outdoor',
      icon: 'both'
    },
  ],
  wlt: {
    title: 'SE This green wall protects 1000 square metres of rainforest',
    text: `SE By choosing an InnoGreen green wall, you are joining us in 
      protecting the world's rainforests in partnership with the World 
      Land Trust. For every square metre of green wall sold or rented, 
      we protect 1000 square metres of rainforest. `
  },
  configuration: [
    {
      type: 'viherseinä',
      text: ['SE 1200 mm leveitä moduuleja', 'SE 1800 mm leveitä moduuleja'],
      valueText: 'SE Kokonaisleveys',
    },
  ],
  leveys: {
    description: `SE Valitse leveys`,
    l120_cm: '1200 mm',
    l180_cm: '1800 mm',
    name: '1. SE Leveys'
  },
  kehyksen_vari: {
    description: `SE Näkyvien osien viimeistely`
  },
  korkeus: {
    description: `SE Valitse korkeus`
  },
}

export default viherseina