import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useSelector, useDispatch } from 'react-redux'
import './styles.scss'

const CustomMultiCarousel = ({ children, customResponsive }) => {
  let responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 3000, min: 1800 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }
  if (customResponsive) {
    responsive = customResponsive
  }
  return (
    <>
      {children && (
        <Carousel
          responsive={responsive}
          containerClass='custom-carousel-container'
          itemClass='custom-carousel-entry-container'
        >
          {children}
        </Carousel>
      )}
    </>
  )
}

export default CustomMultiCarousel
